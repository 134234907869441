import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import orderBy from 'lodash/orderBy';

import theme from 'theme';
import { TaskNotesPhotos, TaskPhoto } from 'configs/propTypes';
import { queueTaskToggle } from 'configs/toggles';
import { getSelectedSite } from 'helpers/storage';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as unitsActions } from 'redux/units';
import { getUnitNameById } from 'redux/units/selectors';
import { actions as tasksActions } from 'redux/tasks';

import { actions as queueActions } from 'redux/queue';
import { actions as exportPdfReportActions } from 'redux/exportPdfReport';
import { getPdfReport } from 'http/queue';

import { AttentionIndicator, VerificationIndicator } from 'components/Layout/Indicators';
import { BackIcon, DeleteIcon, FlagIcon, ImportIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import UnitListTable from 'components/UnitListTable';
import EmptyList from 'components/EmptyList';
import Details from './Details';
import PdfReportDialog from './PdfReportDialog';
import TaskInfoDialog from './TaskInfoDialog';
import Toggle from 'components/Toggle';
import { ButtonIcon } from 'components/Layout/Buttons';
import BaseDialog from 'components/Dialogs/Base';
import TaskWithPartsDetails from './TaskWithPartsDetails';

import * as S from './styled';

const getItemName = item => item.ItemName;
const getItemText = item => item.OriginatingTaskName || item.RuleType;
const renderLeftControls = item => <VerificationIndicator isActive={item.RuleType === 'Verification'} />;
const renderRightText = item => (
  <S.ItemInfo>
    <S.Status>{!item.CompletedOn ? `Due on ${item.ExecutionDate}` : `Completed on ${item.CompletedOn}`}</S.Status>
    <AttentionIndicator isActive={item.Status === 'AttentionRequired'} />
    <S.InfoTooltip tooltip="Demo Task" tooltipPosition="bottom-left" isVisible={item.IsDemoStatus}>
      <FlagIcon />
    </S.InfoTooltip>
  </S.ItemInfo>
);

const calculateRanges = (date, period) => {
  const rangesMap = {
    day: {
      startPeriod: moment(date).format('MM/DD/YYYY'),
      finishPeriod: moment(date).format('MM/DD/YYYY'),
    },
    week: {
      startPeriod: moment(date).format('MM/DD/YYYY'),
      finishPeriod: moment(date).add(1, 'week').format('MM/DD/YYYY'),
    },
    past: {
      finishPeriod: moment().subtract(1, 'day').format('MM/DD/YYYY'),
    },
  };

  return rangesMap[period];
};

class UnitDetail extends Component {
  static propTypes = {
    clearStateError: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    fetchAssignedTasks: PropTypes.func.isRequired,
    fetchCreatePdfReport: PropTypes.func.isRequired,
    fetchDeletePdfReport: PropTypes.func.isRequired,
    fetchReportList: PropTypes.func.isRequired,
    fetchTasks: PropTypes.func.isRequired,
    fetchTasksPhotos: PropTypes.func.isRequired,
    fetchUnitsList: PropTypes.func.isRequired,
    resetAssignedTasks: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    notesPhotos: TaskNotesPhotos.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    photos: TaskPhoto.isRequired,
    reportList: PropTypes.array.isRequired,
    selectedSite: PropTypes.string.isRequired,
    tasksList: PropTypes.arrayOf(PropTypes.object).isRequired,
    unitName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    requestTaskDetails: PropTypes.func.isRequired,
    taskDetails: PropTypes.shape({}).isRequired,
    taskParts: PropTypes.array.isRequired,
    sendEmailWithTaskInfoRequest: PropTypes.func.isRequired,
    requestSaveTask: PropTypes.func.isRequired,
  };

  state = {
    isCompleted: false,
    pdfReportDialogOpened: false,
    requiresAttention: false,
    siteId: getSelectedSite(),
    TaskInfoDialogOpened: false,
    TaskPhotoDialogOpened: false,
    viewedTask: null,
    dateSortingValue: 'asc',
    tasks: [],
    isPartsViewDialogOpen: false,
    isDialogWithParts: false,
  };

  componentDidMount() {
    const {
      selectedSite,
      location: { pathname },
      fetchUnitsList,
      fetchAssignedTasks,
    } = this.props;
    const { assigneeId, category, date, period, unitId } = this.props.match.params;
    const isUnassigned = category === 'All Tasks' ? null : category === 'Pick-Up' || category === 'Team';
    const params = queryString.parse(pathname, { ignoreQueryPrefix: true });

    if (params.completed) {
      this.toggleOpenCompletedTask({ key: 'completed' });
      this.openPdfReportDialog();
    } else {
      fetchUnitsList({ filter: { SiteID: selectedSite } });
      fetchAssignedTasks({
        isUnassigned,
        period,
        rangeStart: date,
        unitId,
        userId: assigneeId === 'allusers' ? '' : assigneeId,
        includeTeam: category === 'Team' || category === 'All Tasks',
        isTeamOnly: category === 'Team',
        requiresAttention: period === 'all' || period === 'parts' || null,
        requiredAttetionParts: period === 'parts' || null,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      tasksList,
      match: {
        params: { period },
      },
    } = this.props;
    if ((period === 'all' || period === 'parts') && prevProps.tasksList !== tasksList) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ tasks: tasksList });
    }
  }

  componentWillUnmount() {
    this.setState({ tasks: [], dateSortingValue: 'asc' });
    this.props.resetAssignedTasks();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
      this.props.clearStateError();
    }
  }

  onTaskClick = item => {
    if (item.TaskPartsRequireAttention) {
      this.setState({ isDialogWithParts: true });
      this.props.requestTaskDetails({ taskId: item.Id });
      this.openPartsViewDialog();
      return;
    }

    this.setState({ isDialogWithParts: false });
    this.props.requestTaskDetails({ taskId: item.Id });
    this.props.fetchTasks(item.Id);
    this.openPartsViewDialog();
  };

  onToggle = () => {
    const { assigneeId, category, date, period, unitId } = this.props.match.params;
    const isUnassigned = category === 'All Tasks' ? null : category === 'Pick-Up' || category === 'Team';
    this.setState({ requiresAttention: !this.state.requiresAttention }, () =>
      this.props.fetchAssignedTasks({
        isUnassigned,
        period,
        rangeStart: date,
        unitId,
        userId: assigneeId === 'allusers' ? '' : assigneeId,
        includeTeam: category === 'Team' || category === 'All Tasks',
        isTeamOnly: category === 'Team',
        requiresAttention: this.state.requiresAttention || null,
      }),
    );
  };

  getPdf = item => {
    getPdfReport({
      id: item.Id,
    });
  };

  deletePdf = item => {
    this.props.fetchDeletePdfReport({
      id: item.Id,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  toggleOpenCompletedTask = task => {
    const { assigneeId, category, date, period, unitId } = this.props.match.params;
    const isUnassigned = category === 'All Tasks' ? null : category === 'Pick-Up' || category === 'Team';
    if (task.key === 'open') {
      this.props.fetchAssignedTasks({
        isUnassigned,
        period,
        rangeStart: date,
        unitId,
        includeTeam: category === 'Team' || category === 'All Tasks',
        isTeamOnly: category === 'Team',
        userId: assigneeId === 'allusers' ? '' : assigneeId,
      });
      this.setState({ isCompleted: false });
    } else {
      this.props.fetchAssignedTasks({
        isUnassigned: isUnassigned === true ? isUnassigned : false,
        period,
        rangeStart: date,
        unitId,
        userId: assigneeId === 'allusers' ? '' : assigneeId,
        includeAssigned: false,
        includeCompleted: true,
        includeTeam: category === 'Team' || category === 'All Tasks',
        isTeamOnly: category === 'Team',
        requiresAttention: false,
        requireOnlyCompleted: isUnassigned === true ? isUnassigned : false,
      });
      this.setState({ isCompleted: true });
    }
  };

  rightControlsForPdfList = item => (
    <div>
      <ButtonIcon onClick={() => this.deletePdf(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => this.getPdf(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  exportReport = () => {
    const { date, period } = this.props.match.params;
    this.props.fetchCreatePdfReport({
      dates: calculateRanges(date, period),
      unitId: this.props.match.params.unitId,
      userId: this.props.match.params.assigneeId,
    });
    this.props.openErrorDialog(
      'We are generating your report. When it will be ready you will get email notification',
      'Pdf reports',
    );
    this.setState({
      pdfReportDialogOpened: false,
    });
  };

  openPdfReportDialog = () => {
    this.props.fetchReportList({ unitId: this.props.match.params.unitId });
    this.setState({ pdfReportDialogOpened: true });
  };
  closePdfReportDialog = () => {
    this.setState({
      pdfReportDialogOpened: false,
    });
  };

  closeTaskInfoDialog = () => {
    this.setState({ TaskInfoDialogOpened: false, viewedTask: null });
  };
  openTaskInfoDialog = item => {
    this.props.fetchTasks(item.Id);
    this.setState({ TaskInfoDialogOpened: true, viewedTask: item, TaskPhotoDialogOpened: false });
  };

  closeTaskPhotoDialog = () => {
    this.setState({ TaskInfoDialogOpened: true, TaskPhotoDialogOpened: false });
  };
  openTaskPhotoDialog = item => {
    this.props.fetchTasksPhotos(item.ItemId);
    this.setState({ TaskPhotoDialogOpened: true });
  };

  onChangeDateSorting = dateSortingValue =>
    this.setState({
      dateSortingValue,
      tasks: orderBy(this.state.tasks, task => moment(task.ExecutionDate).format('MM/DD/YYYY'), [dateSortingValue]),
    });

  openPartsViewDialog = () => {
    this.setState({ isPartsViewDialogOpen: true });
  };

  closePartsViewDialog = () => {
    this.setState({ isPartsViewDialogOpen: false });
  };

  updateAssignedTaksList = () => {
    const { assigneeId, category, date, period, unitId } = this.props.match.params;
    const isUnassigned = category === 'All Tasks' ? null : category === 'Pick-Up' || category === 'Team';

    this.props.fetchAssignedTasks({
      isUnassigned,
      period,
      rangeStart: date,
      unitId,
      userId: assigneeId === 'allusers' ? '' : assigneeId,
      includeTeam: category === 'Team' || category === 'All Tasks',
      isTeamOnly: category === 'Team',
      requiresAttention: period === 'all' || period === 'parts' || null,
      requiredAttetionParts: period === 'parts' || null,
    });
    this.setState({ isPartsViewDialogOpen: false });
  };

  render() {
    const { loading, notesPhotos, photos, reportList, tasksList, unitName, taskDetails, taskParts } = this.props;
    const { category, date, period } = this.props.match.params;
    const {
      isCompleted,
      pdfReportDialogOpened,
      TaskInfoDialogOpened,
      TaskPhotoDialogOpened,
      siteId,
      viewedTask,
      dateSortingValue,
      tasks,
      isPartsViewDialogOpen,
      isDialogWithParts,
    } = this.state;

    const isRequireAttentionTasks = period === 'all' || period === 'parts';
    const title = isRequireAttentionTasks ? 'Attention details' : 'History Details';

    return [
      <Subheader
        key="UnitDetail Subheader"
        title={title}
        isSiteOnLeftSide
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: this.goBack,
            hint: 'Back',
          },
        ]}
        rightButtons={
          !isRequireAttentionTasks
            ? [
                {
                  isComponent: true,
                  component: <Toggle config={queueTaskToggle} selected="open" handler={this.toggleOpenCompletedTask} />,
                },
              ]
            : []
        }
      />,
      <Details
        key="Unit Details"
        data={{
          assigneeName: category,
          period,
          rangeStart: date,
          tasksTotal: isRequireAttentionTasks ? tasks.length : tasksList.length,
          dateSortingValue,
        }}
        isCompleted={isCompleted}
        onExport={this.openPdfReportDialog}
        onToggle={this.onToggle}
        onChangeDateSorting={this.onChangeDateSorting}
      />,
      <S.ListContainer key="Tasks list">
        {!loading && (!!tasks.length || !!tasksList.length) ? (
          <UnitListTable
            getItemName={getItemName}
            getItemText={getItemText}
            onUnitItemClick={this.onTaskClick}
            renderLeftControls={renderLeftControls}
            renderRightText={renderRightText}
            handleToggle={this.openTaskInfoDialog}
            unitColor={theme.primaryQueue}
            unitData={isRequireAttentionTasks ? tasks : tasksList}
            unitName={unitName}
          />
        ) : (
          <EmptyList text="There are no tasks" />
        )}
      </S.ListContainer>,
      <TaskInfoDialog
        key="TaskInfoDialog"
        title={viewedTask && `Task Result Details: ${viewedTask.RuleType}`}
        item={viewedTask}
        open={TaskInfoDialogOpened}
        handleClose={this.closeTaskInfoDialog}
        openDialog={TaskPhotoDialogOpened}
        openPhotoDialog={this.openTaskPhotoDialog}
        closePhotoDialog={this.closeTaskPhotoDialog}
        notesPhotos={notesPhotos}
        photos={photos}
        siteId={siteId}
      />,
      <PdfReportDialog
        key="Date Range Dialog"
        title="PDF Reports"
        handleClose={this.closePdfReportDialog}
        onSubmit={this.exportReport}
        open={pdfReportDialogOpened}
        pdfList={reportList}
        rightControls={this.rightControlsForPdfList}
      />,
      <BaseDialog
        key="items part preview"
        open={isPartsViewDialogOpen}
        onRequestClose={this.closePartsViewDialog}
        title={isDialogWithParts ? 'Item Parts View' : 'Item View'}
      >
        <TaskWithPartsDetails
          {...taskDetails}
          isDialogWithParts={isDialogWithParts}
          taskParts={taskParts}
          sendEmailWithTaskInfoRequest={this.props.sendEmailWithTaskInfoRequest}
          requestSaveTask={this.props.requestSaveTask}
          notesPhotos={notesPhotos}
          updateAssignedTaksList={this.updateAssignedTaksList}
        />
      </BaseDialog>,
    ];
  }
}

const mapStateToProps = ({ auth, queue, units, tasks, exportPdfReport }, { match }) => ({
  error: auth.error || queue.error || units.error,
  notesPhotos: tasks.taskNotes,
  photos: tasks.taskPhotos,
  reportList: exportPdfReport.reportList,
  selectedSite: auth.selectedSite,
  tasksList: queue.assignedTasks,
  loading: tasks.loading,
  unitName: getUnitNameById(units, match.params.unitId),
  userId: auth.user.userId,
  taskDetails: tasks.taskDetails,
  taskParts: tasks?.taskDetails?.AssignedTaskParts?.map(({ PartID }) => {
    const { MyPartID, MyPartName } = tasks?.taskDetails?.Parts.find(({ Id }) => Id === PartID);

    return { myPartId: MyPartID, myPartName: MyPartName };
  }),
});

const mapDispatchToProps = {
  clearStateError: queueActions.stateErrorClear,
  fetchAssignedTasks: queueActions.fetchAssignedTasksRequest,
  fetchCreatePdfReport: exportPdfReportActions.createPdfReportRequest,
  fetchDeletePdfReport: exportPdfReportActions.deletePdfReportFileRequest,
  fetchReportList: exportPdfReportActions.getPdfReportListRequest,
  fetchTasks: tasksActions.fetchNotesRequest,
  fetchTasksPhotos: tasksActions.fetchPhotosRequest,
  fetchUnitsList: unitsActions.unitsListRequest,
  resetAssignedTasks: queueActions.resetAssignedTasks,
  openErrorDialog,
  requestTaskDetails: tasksActions.fetchDetailsRequest,
  sendEmailWithTaskInfoRequest: tasksActions.sendTaskInfoEmailRequest,
  requestSaveTask: tasksActions.saveTaskRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetail);
