import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { OptionsType } from 'configs/propTypes';

import BaseDialog from 'components/Dialogs/Base';
import { EditIcon } from 'components/Layout/Icons';
import EditableListDialog from 'components/Dialogs/EditableListDialog';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as partOptionsActions } from 'redux/partOptions';
import { openConfirmationDialog } from 'redux/confirmationHandler';

import * as S from './styled';

const optionsTypes = {
  category: {
    name: 'Part Category Options',
    guidName: 'partCategoryId',
  },
  unitOfMeasure: {
    name: 'Part Unit Of Measure Options',
    guidName: 'partUnitOfMeasureId',
  },
  weightStandard: {
    name: 'Part Weight Standard Options',
    guidName: 'partWeightStandardId',
  },
  type: {
    name: 'Part Type Options',
    guidName: 'partTypeId',
  },
  sizingStandard: {
    name: 'Part Sizing Standard Options',
    guidName: 'partSizingStandardId',
  },
  materialType: {
    name: 'Part Material Type Options',
    guidName: 'partMaterialTypeId',
  },
};

class OptionsListDialog extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      addPartOptionRequest: PropTypes.func.isRequired,
      editPartOptionRequest: PropTypes.func.isRequired,
      deletePartOptionRequest: PropTypes.func.isRequired,
      getPartOptionsRequest: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
      openConfirmationDialog: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string,
    open: PropTypes.bool.isRequired,
    optionsList: OptionsType.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
  };

  state = {
    optionsToEditType: '',
    editOptionsDialogOpen: false,
  };

  componentDidMount() {
    this.props.actions.getPartOptionsRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error && this.props.error !== nextProps.error) {
      this.props.actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  openEditOptionsDialog = optionsType => this.setState({ optionsToEditType: optionsType, editOptionsDialogOpen: true });
  closeEditOptionsDialog = () => this.setState({ optionsToEditType: '', editOptionsDialogOpen: false });

  addOption = values => this.props.actions.addPartOptionRequest({ values, optionType: this.state.optionsToEditType });
  editOption = values => this.props.actions.editPartOptionRequest({ values, optionType: this.state.optionsToEditType });
  deleteOption = values =>
    this.props.actions.deletePartOptionRequest({ values, optionType: this.state.optionsToEditType });

  render() {
    const { actions, open, handleClose, optionsList } = this.props;
    const { optionsToEditType, editOptionsDialogOpen } = this.state;

    return (
      <>
        <BaseDialog open={open} title="Option Lists" onRequestClose={handleClose} modalStyles={{ maxWidth: '70%' }}>
          <S.DialogContainer>
            <S.DialogText>Option Lists apply to My Parts, OEM Parts and Supplier Parts</S.DialogText>
            <S.OptionsGroupsContainer>
              {Object.keys(optionsTypes).map(key => (
                <S.OptionCardContainer key={key}>
                  <S.OptionCardHeader>
                    {optionsTypes[key].name}
                    <S.EditOptionsButton onClick={() => this.openEditOptionsDialog(key)}>
                      <EditIcon />
                    </S.EditOptionsButton>
                  </S.OptionCardHeader>
                  <S.OptionsList>
                    {optionsList[key]?.length > 0 ? (
                      optionsList[key].map(({ Id, Name }) => <S.Option key={Id}>{Name}</S.Option>)
                    ) : (
                      <S.EmptyOptions>{`There are no ${optionsTypes[key].name}`}</S.EmptyOptions>
                    )}
                  </S.OptionsList>
                </S.OptionCardContainer>
              ))}
            </S.OptionsGroupsContainer>
          </S.DialogContainer>
        </BaseDialog>
        <EditableListDialog
          list={optionsList[optionsToEditType] || []}
          open={editOptionsDialogOpen}
          searchPlaceholder="filter options"
          title="Options"
          idTitle={optionsTypes[optionsToEditType]?.guidName || ''}
          handleClose={this.closeEditOptionsDialog}
          addItem={this.addOption}
          editItem={this.editOption}
          deleteItem={this.deleteOption}
          openConfirmationDialog={actions.openConfirmationDialog}
          error={this.props.error}
        />
      </>
    );
  }
}

const mapStateToProps = ({ partOptions }) => ({
  error: partOptions.error,
  optionsList: partOptions.options,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openErrorDialog,
      openConfirmationDialog,
      ...partOptionsActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionsListDialog);
