import React from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from 'components/Layout/Buttons';
import { Container, IconClose, TitleText } from './styled';

const Title = ({ handleClose, title, titleColor, isError }) => (
  <Container titleColor={titleColor}>
    <TitleText isError={isError}>{title}</TitleText>
    <ButtonIcon onClick={handleClose}>
      <IconClose isError={isError} />
    </ButtonIcon>
  </Container>
);

Title.defaultProps = {
  title: '',
  titleColor: '',
  titleTextColor: 'black',
  isError: false,
};

Title.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  isError: PropTypes.bool,
};

export default Title;
