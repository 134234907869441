import PropTypes from 'prop-types';

export const RuleType = PropTypes.shape({
  name: PropTypes.string,
  match: PropTypes.number,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      optionName: PropTypes.string,
      optionConstraint: PropTypes.number,
      optionValue: PropTypes.string,
      matchOperator: PropTypes.number,
    }),
  ),
  isRulesIgnored: false,
});

export const ManagerType = PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ Name: PropTypes.string })]);
