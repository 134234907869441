import React from 'react';
import styled from 'styled-components';
import Dialog from 'material-ui/Dialog';

import { isIE11 } from 'helpers';
import { ButtonFlat } from 'components/Layout/Buttons';
import { PersonIcon, PowerIcon, OfflineBoltRed, TeamsBlue } from 'components/Layout/Icons';

export const Modal = styled(Dialog).attrs({
  titleStyle: {
    padding: 0,
  },
})`
  z-index: 4000 !important;
  padding-top: 20px !important;

  & > div > div {
    width: 50px !important;
    margin: 0 10px auto auto !important;
    & > div > div {
      padding: 0 !important;
      max-height: 670px !important;
    }
  }
`;

export const Triangle = styled.div`
  position: absolute;
  top: -20px !important;
  left: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 20px 10px;
  border-color: transparent transparent #333333 transparent;
`;

export const Button = styled(ButtonFlat)`
  min-width: 0 !important;
  width: 100% !important;
  height: auto !important;
  line-height: ${isIE11 ? '12px' : 'normal'} !important;
  padding: 3px 0 !important;
  border-radius: 0 !important;
  text-align: center !important;

  & > div {
    display: ${isIE11 ? 'block' : 'flex'};
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${isIE11 ? '0 4px' : 0};
    max-width: ${isIE11 ? '83.6%' : 'auto'};
    & > svg {
      margin: 0 !important;
    }
    & > span {
      display: ${isIE11 ? 'block' : 'inline'} !important;
      padding: 0 !important;
      font-weight: 300 !important;
      font-size: 10px !important;
      text-transform: lowercase !important;
    }
  }
`;

export const ShortcutsIcon = styled(OfflineBoltRed)`
  margin-left: 0 !important;
`;

export const TeamsIcon = styled(TeamsBlue)`
  margin-left: 0 !important;
`;

export const ProfileButton = styled(Button).attrs({
  icon: <PersonIcon color="#ffffff" />,
  label: 'profile',
  backgroundColor: '#333333',
  hoverColor: props => props.theme.darkGrey,
})`
  color: white !important;
`;

export const LogOutButton = styled(ProfileButton).attrs({
  icon: <PowerIcon color="#ffffff" />,
  label: 'logout',
})``;

export const InstructionButton = styled(Button).attrs({
  icon: props => props.icon,
  label: props => props.label,
})``;
