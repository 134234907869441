import styled from 'styled-components';
import logoErrorPage from 'components/Layout/Images/wrench_404.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.lightGrey};
  height: 100vh;
  z-index: 1050;
`;

export const Title = styled.p`
  font-size: 50px;
  font-family: HelveticaNeueThin;
  width: 50%;
  margin: 35px;
`;

export const Text = styled.p`
  margin: 5px;
  font-size: 25px;
  font-family: HelveticaThin;
  width: 40%;
`;

export const Logo = styled.img.attrs({
  src: logoErrorPage,
  alt: 'InCheq logo',
})`
  height: 120px;
  padding-right: 34px;
`;

export const HomeButtonContainer = styled.div`
  margin-top: 20px;
  width: 250px;
`;
