import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const Gateway = styled(Flex).attrs({
  w: 1,
})``;

export const Info = styled(Flex).attrs({
  justify: 'space-between',
  align: 'center',
  w: [0.6, 0.6, 0.59, 0.55],
})``;

export const SerialNumber = styled(Box).attrs({
  w: [0.8, 0.7, 0.77, 0.82],
})`
  padding: 0 10px;
`;

export const SiteName = styled(Box).attrs({
  w: [0.4, 0.4, 0.41, 0.45],
})``;

export const Text = styled.div`
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const Sensors = styled(Flex).attrs({
  w: [0.2, 0.3, 0.23, 0.18],
  wrap: 'wrap',
  justify: 'flex-end',
})`
  @media only screen and (max-width: 39em) {
    justify-content: center;
  }
`;

export const SensorsTitle = styled.div`
  padding-right: 5px;
`;

export const SensorsCount = styled.div`
  min-width: 27px;
  text-align: center;
`;
