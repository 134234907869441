import { actions as settingsActions } from 'redux/settings';
import { openConfirmationDialog } from 'redux/confirmationHandler';

const mapStateToProps = ({ settings: { deleteLoading, demoTasksCount } }) => ({
  demoTasksCount,
  deleteLoading,
});

const mapDispatchToProps = {
  deleteDemoTasks: settingsActions.deleteDemoTasksRequest,
  openConfirmationDialog,
};

export { mapStateToProps, mapDispatchToProps };
