import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions as sitesActions } from 'redux/sites';
import BaseDialog from 'components/Dialogs/Base';
import SearchField from 'components/Layout/Search';
import CheckboxList from 'components/CheckboxList';
import { SearchIcon } from 'components/Layout/Icons';
import * as G from 'components/Dialogs/styled';
import { PastDueLimitSiteType } from 'configs/propTypes';

import PastDueLimitDialog from './PastDueLimitDialog';

import * as S from './styled';

class PastDueBacklogDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    updatePastDueLimit: PropTypes.func.isRequired,
    getPastDueLimitRequest: PropTypes.func.isRequired,
    getPastDueLimitListRequest: PropTypes.func.isRequired,
    pastDueLimitList: PropTypes.arrayOf(PastDueLimitSiteType).isRequired,
    pastDueLimitSite: PastDueLimitSiteType.isRequired,
    status: PropTypes.string.isRequired,
  };

  state = {
    sites: [],
    checkedSites: [],
    searchData: '',
    isPastDueLimitDialogOpen: false,
    currentSite: {},
    settings: {},
  };

  componentDidUpdate() {
    const { pastDueLimitList, status, pastDueLimitSite } = this.props;

    if (status === 'updated') {
      this.setState({ sites: pastDueLimitList });
      this.setState({ currentSite: pastDueLimitSite });
    }
  }

  componentDidMount() {
    this.props.getPastDueLimitListRequest();
  }

  openPastDueLimitDialog = () => this.setState({ isPastDueLimitDialogOpen: true });

  closePastDueLimitDialog = () => this.setState({ isPastDueLimitDialogOpen: false, currentSite: {} });

  clearCheckedSites = () => this.setState({ checkedSites: [] });

  fillCheckedSites = checkedSites => this.setState({ checkedSites });

  setNewSettings = settings => this.setState({ settings });

  setSearchedSites = (sites, searchData) => this.setState({ sites, searchData });

  onItemClick = settings => {
    this.props.getPastDueLimitRequest(settings.SiteId);
    this.openPastDueLimitDialog();
  };

  applyToAllSelections = (limit, periodType) => {
    const { checkedSites } = this.state;
    const { updatePastDueLimit } = this.props;

    updatePastDueLimit({
      periodType,
      limit,
      siteIds: checkedSites,
    });
    this.closePastDueLimitDialog();
    this.clearCheckedSites();
  };

  applyToCurrentSite = (limit, periodType) => {
    const { updatePastDueLimit, pastDueLimitSite } = this.props;
    const { checkedSites } = this.state;

    updatePastDueLimit({
      periodType,
      limit,
      siteIds: [pastDueLimitSite.SiteId] || checkedSites,
    });
    this.closePastDueLimitDialog();
    this.clearCheckedSites();
  };

  onSubmit = () => {
    const {
      checkedSites,
      settings: { limit, periodType },
    } = this.state;

    if (checkedSites.length === 1) {
      this.applyToCurrentSite(limit, periodType);
    } else {
      this.applyToAllSelections(limit, periodType);
    }
  };

  onModalClose = () => {
    this.clearCheckedSites();
    this.props.handleClose();
  };

  render() {
    const { open, pastDueLimitList, status } = this.props;
    const { sites, checkedSites, searchData, isPastDueLimitDialogOpen, currentSite, settings } = this.state;

    return (
      <>
        <BaseDialog
          open={open}
          onRequestClose={this.onModalClose}
          title="Set Past Due Limit"
          modalStyles={{ maxWidth: '550px' }}
        >
          <G.DialogContainer>
            <G.SearchContainer>
              <SearchField
                list={pastDueLimitList}
                filterBy="Name"
                searchData={searchData}
                placeholder="filter Sites"
                underlineShow={false}
                borderRadius="10px"
                icon={<SearchIcon />}
                setSearchedItems={this.setSearchedSites}
              />
            </G.SearchContainer>
            <G.ListContainer>
              <CheckboxList
                list={sites}
                checkedSites={checkedSites}
                onItemClick={this.onItemClick}
                clearCheckedSites={this.clearCheckedSites}
                fillCheckedSites={this.fillCheckedSites}
                displayValue="pastDueLimit"
                secondaryTitle="Past Due Limit"
              />
            </G.ListContainer>
            <S.ButtonContainer>
              <S.ButtonFlat
                label="Save"
                type="button"
                onClick={this.onSubmit}
                disabled={!checkedSites.length || isEmpty(settings)}
              />
            </S.ButtonContainer>
          </G.DialogContainer>
        </BaseDialog>
        <PastDueLimitDialog
          open={isPastDueLimitDialogOpen}
          status={status}
          handleClose={this.closePastDueLimitDialog}
          currentSite={currentSite}
          checkedSites={checkedSites}
          setNewSettings={this.setNewSettings}
          applyToAllSelections={this.applyToAllSelections}
          applyToCurrentSite={this.applyToCurrentSite}
        />
      </>
    );
  }
}

const mapStateToProps = ({ sites: { pastDueLimitList, pastDueLimitSite, status } }) => ({
  pastDueLimitList,
  pastDueLimitSite,
  status,
});

const mapDispatchToProps = {
  updatePastDueLimit: sitesActions.updatePastDueLimit,
  getPastDueLimitRequest: sitesActions.getPastDueLimitRequest,
  getPastDueLimitListRequest: sitesActions.getPastDueLimitListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastDueBacklogDialog);
