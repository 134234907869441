import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { FullHeightCenteredXYGreyContainer } from 'components/Layout/Containers';
import PageFooter from 'components/PageFooter';

import ForgotPage from './pages/ForgotPage';
import LoginPage from './pages/LoginPage';
import ActivatePage from './pages/ActivatePage';

import * as S from './styled';

const Login = () => (
  <FullHeightCenteredXYGreyContainer>
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/forgot" component={ForgotPage} />
      <Route path="/activate" component={ActivatePage} />
    </Switch>
    <S.BottomInfoContainer>
      <PageFooter />
    </S.BottomInfoContainer>
  </FullHeightCenteredXYGreyContainer>
);

export default Login;
