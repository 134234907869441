import { createSelector } from 'reselect';

export const getRangeStart = createSelector(
  state => state.tasks.rangeStart,
  rangeStart => rangeStart,
);

export const getSelectedPeriodUnits = createSelector(
  (tasks, period, tasksType) => tasksType.key,
  (tasks, period) => period.key,
  tasks => tasks.tasks,
  (tasksTypeKey, periodKey, tasks) => (tasks[periodKey][tasksTypeKey] ? tasks[periodKey][tasksTypeKey].tasks : {}),
);

export const getSelectedPeriodUnitsNames = createSelector(getSelectedPeriodUnits, units => Object.keys(units));

export const getTasksForSelectedPeriod = createSelector(
  state => state.tasks.tasks,
  state => state.tasks.periodSelected.key,
  state => state.tasks.tasksSelected.key,
  (tasks, periodSelectedKey, tasksSelectedKey) => tasks[periodSelectedKey][tasksSelectedKey],
);

export const getUsersSelector = createSelector(
  state => state.tasks.users,
  users => users,
);
