export const validator = values => {
  const errors = {};

  if (!values.IndustryID) {
    errors.IndustryID = 'Industry is Required!';
  }

  if (!values.RequestorName) {
    errors.RequestorName = 'Name is is Required!';
  }

  if (!values.RequestorEmail) {
    errors.RequestorEmail = 'E-Mail is Required!';
  }

  if (!values.RequestorCompany) {
    errors.RequestorCompany = 'Company is Required!';
  }

  return errors;
};

export default validator;
