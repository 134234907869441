import styled from 'styled-components';
import { Item, Group } from 'modules/globalsettings/pages/styled';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

export const PitchGroup = styled(Group)`
  background: ${({ theme }) => theme.demoTier};
  padding: 0 16px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
`;

export const FirstItem = styled.div`
  width: 100px;
`;

export const LastItem = styled.div`
  width: 400px;
`;

export const CustomItem = styled(Item)`
  width: 150px;
`;

export const EmptyItem = styled.div`
  visibility: hidden;
`;
