import React, { PureComponent } from 'react';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import * as S from './styled';
import { PropTypes } from 'prop-types';

class ChecklistInfo extends PureComponent {
  render() {
    const { checklistInfo, dates, getCountTasks, toEditShortcut, site, getManager, getTeamMember } = this.props;
    return (
      <S.Container>
        <S.Block />
        <S.Block>
          <S.Title>
            Site:&nbsp;
            <S.SecondaryTitle>{site}</S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Unit:&nbsp;
            <S.SecondaryTitle>{checklistInfo.UnitName}</S.SecondaryTitle>
          </S.Title>
        </S.Block>
        <S.Block>
          <S.BlockDate>
            <S.Column>
              <div>Repeat Cycle: {dates.repeatCycle}</div>
              <div>Start Date: {dates.startDate}</div>
              <div>End Date: {dates.endDate}</div>
            </S.Column>
            <S.Column>
              <div>Start Time: {dates.startTime}</div>
              <div>End Time: {dates.endTime}</div>
            </S.Column>
          </S.BlockDate>
          <S.RedText>Tasks:</S.RedText>
          <S.ItemsCircle>{getCountTasks()}</S.ItemsCircle>
          <S.RedText>Assigned to:</S.RedText>
        </S.Block>
        <S.Block>
          <S.Title>
            Manager:&nbsp;
            <S.SecondaryTitle>
              {getManager(checklistInfo.TeamId) || checklistInfo.SupervisorName || 'All'}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team:&nbsp;
            <S.SecondaryTitle>
              {checklistInfo.ChecklistAssignmentType === 1 || (checklistInfo.AssigneeID && checklistInfo.TeamId)
                ? checklistInfo.TeamName
                : 'All'}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team Member:&nbsp;
            <S.SecondaryTitle>{getTeamMember(checklistInfo.ChecklistAssignmentType)}</S.SecondaryTitle>
          </S.Title>
        </S.Block>
        <ActionsContainer>
          <ActionButton label="Open Shortcut" isNext onClick={() => toEditShortcut(checklistInfo.ShortcutID)} />
        </ActionsContainer>
      </S.Container>
    );
  }
}

ChecklistInfo.propTypes = {
  checklistInfo: PropTypes.object.isRequired,
  dates: PropTypes.object.isRequired,
  getCountTasks: PropTypes.func.isRequired,
  toEditShortcut: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  getManager: PropTypes.func.isRequired,
  getTeamMember: PropTypes.func.isRequired,
};

export default ChecklistInfo;
