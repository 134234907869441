import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DndIcon } from 'components/Layout/Icons';
import { ItemName, StyledReactTooltip } from './styled';

const getItemStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  userSelect: 'none',
  padding: 10,
  background: isDragging ? 'lightgrey' : 'white',
  cursor: 'move',
  ...draggableStyle,
});

class Item extends Component {
  render() {
    const { provided, tooltipData, id } = this.props;
    const { snapshot } = this.props;
    const { item } = this.props;

    const child = (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <DndIcon />
        <ItemName data-tip data-for={id}>
          {item.content}
        </ItemName>
        <StyledReactTooltip id={id} effect="solid" place="right" disable={!tooltipData} multiline>
          <span>{tooltipData}</span>
        </StyledReactTooltip>
      </div>
    );

    return child;
  }
}

Item.defaultProps = {
  provided: {},
  snapshot: {},
  item: {},
  tooltipData: '',
};

Item.propTypes = {
  provided: PropTypes.object,
  snapshot: PropTypes.object,
  item: PropTypes.object,
  tooltipData: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default Item;
