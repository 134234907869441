import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { PreparedForRenderTasksType, ToggleItem, UserType } from 'configs/propTypes';
import { periodToggle, taskToggle } from 'configs/toggles';
import { USER_TYPES } from 'configs/enums';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as tasksActions } from 'redux/tasks';

import { AttentionIndicator, VerificationIndicator } from 'components/Layout/Indicators';
import { BackIcon, FlagIcon } from 'components/Layout/Icons';
import Period from 'components/Period';
import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';
import Pagination from 'components/Pagination';

import Footer from './Footer';
import List from './List';

import * as S from './styled';

const renderLeftControls = (item, tasksType) => (
  <VerificationIndicator
    key="Verification indicator"
    isActive={item.RuleType === 'Verification' && tasksType.key === 'my'}
  />
);
const renderRightControls = (item, tasksType, periodType) => [
  <S.DateLabel key="Execution Date" isVisible={periodType.key === 'past'}>
    Due on {item.ExecutionDate}
  </S.DateLabel>,
  <S.InfoTooltip tooltip="Demo Task" isVisible={item.IsDemoStatus}>
    <FlagIcon />
  </S.InfoTooltip>,
  <AttentionIndicator
    key="Exception indicator"
    isActive={item.Status === 'AttentionRequired' && tasksType.key === 'my'}
  />,
];

class TasksList extends Component {
  static propTypes = {
    users: PropTypes.arrayOf(UserType).isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    periodSelected: ToggleItem.isRequired,
    rangeStart: PropTypes.instanceOf(moment).isRequired,
    requestReleaseTask: PropTypes.func.isRequired,
    requestTasks: PropTypes.func.isRequired,
    tasks: PreparedForRenderTasksType.isRequired,
    tasksSelected: ToggleItem.isRequired,
    updatePeriod: PropTypes.func.isRequired,
    updateRangeStart: PropTypes.func.isRequired,
    resetPeriod: PropTypes.func.isRequired,
    resetTasksType: PropTypes.func.isRequired,
    updateTasksType: PropTypes.func.isRequired,
    userType: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    taskCount: PropTypes.number.isRequired,
    updatePage: PropTypes.func.isRequired,
    updateRowsPerPage: PropTypes.func.isRequired,
  };

  state = {
    checked: [],
  };

  componentDidMount() {
    const { date } = this.props.match.params;
    const { id } = this.props.match.params;
    const { users, updateTasksType } = this.props;

    const { requestTasks, tasksSelected, periodSelected, page, rowsPerPage } = this.props;
    this.props.updateRangeStart(moment(date, 'YYYY-MM-DD'));
    const assignee = users.find(({ Id }) => Id === id) || {};
    const isPickUp = assignee.Name === 'Pick-Up';

    if (tasksSelected.key === 'my' && assignee.IsTeam) {
      updateTasksType(taskToggle[1]);
      requestTasks({ id, period: periodSelected.key, type: taskToggle[1].key, page, rowsPerPage });
    } else if (isPickUp) {
      updateTasksType(taskToggle[2]);
      requestTasks({ id, period: periodSelected.key, type: taskToggle[2].key, page, rowsPerPage });
    } else {
      requestTasks({ id, period: periodSelected.key, type: tasksSelected.key, page, rowsPerPage });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { date, id } = this.props.match.params;
    const { rangeStart, requestTasks, tasksSelected, periodSelected, page, rowsPerPage } = this.props;
    const { users, updateTasksType } = this.props;

    const assignee = users.find(({ Id }) => Id === id) || {};

    if (tasksSelected.key === 'my' && assignee.IsTeam) {
      updateTasksType(taskToggle[1]);
    }

    if (
      (date !== nextProps.match.params.date || rangeStart !== nextProps.rangeStart) &&
      (periodSelected !== nextProps.periodSelected || date !== nextProps.match.params.date)
    ) {
      requestTasks({ id, period: nextProps.periodSelected.key, type: nextProps.tasksSelected.key, page, rowsPerPage });
    }
  }

  onCenter = canAssignATaskToTheCurrentUser => {
    this.props.openConfirmationDialog(
      `Are you sure you want to ${canAssignATaskToTheCurrentUser ? 'claim' : 'release'} the selected task(s)?`,
      () => this.releaseTasks(canAssignATaskToTheCurrentUser),
      `${canAssignATaskToTheCurrentUser ? 'Claim' : 'Release'}  tasks?`,
    );
  };

  onCheck = (item, value) => {
    const newChecked = [...this.state.checked];

    if (value) {
      newChecked.push(item.Id);
    } else {
      newChecked.splice(newChecked.indexOf(item.Id), 1);
    }

    this.setState({ checked: newChecked });
  };

  onBackClick = () => {
    const { history } = this.props;

    history.push('/tasks');
  };

  onNext = () => {
    const { rangeStart, history, updateRangeStart, periodSelected } = this.props;
    const { id } = this.props.match.params;
    let newDate = moment(rangeStart);

    if (periodSelected.key === 'day') {
      newDate = moment(rangeStart).add(1, 'day');
    } else if (periodSelected.key === 'week') {
      newDate = moment(rangeStart).add(1, 'week');
    }

    updateRangeStart(newDate);
    history.push(`/tasks/${id}/${newDate.format('YYYY-MM-DD')}`);
  };

  onPrev = () => {
    const { rangeStart, history, updateRangeStart, periodSelected } = this.props;
    const { id } = this.props.match.params;
    let newDate = moment(rangeStart);

    if (periodSelected.key === 'day') {
      newDate = moment(rangeStart).subtract(1, 'day');
    } else if (periodSelected.key === 'week') {
      newDate = moment(rangeStart).subtract(1, 'week');
    }

    updateRangeStart(newDate);
    history.push(`/tasks/${id}/${newDate.format('YYYY-MM-DD')}`);
  };

  onRowClick = item => {
    this.props.history.push(`./task/${item.Id}`);
  };

  releaseTasks = canAssignATaskToTheCurrentUser => {
    const { id } = this.props.match.params;
    const { userId, requestReleaseTask, periodSelected, tasksSelected, page, rowsPerPage } = this.props;

    requestReleaseTask({
      taskIds: this.state.checked,
      id,
      userId: canAssignATaskToTheCurrentUser ? userId : null,
      period: periodSelected,
      type: tasksSelected,
      page,
      rowsPerPage,
    });
    this.setState({ checked: [] });
  };

  periodHandler = value => {
    const { match, history, updateRangeStart, updatePeriod } = this.props;
    const newDate = moment();
    updatePeriod(value);
    updateRangeStart(newDate);
    history.push(`/tasks/${match.params.id}/${newDate.format('YYYY-MM-DD')}`);
    this.setState({ checked: [] });
  };

  tasksTypeHandler = value => {
    const { id } = this.props.match.params;
    const { periodSelected, requestTasks, rowsPerPage, updateTasksType, page } = this.props;
    requestTasks({ id, period: periodSelected.key, type: value.key, page, rowsPerPage });
    updateTasksType(value);
    this.setState({ checked: [] });
  };

  renderLeftControls = item => renderLeftControls(item, this.props.tasksSelected);

  renderRightControls = item => renderRightControls(item, this.props.tasksSelected, this.props.periodSelected);

  componentWillUnmount() {
    const { resetPeriod, resetTasksType } = this.props;

    resetPeriod();
    resetTasksType();
  }

  changePage = value => {
    const { id } = this.props.match.params;
    const { periodSelected, tasksSelected, requestTasks, rowsPerPage, updatePage } = this.props;
    updatePage(value);
    requestTasks({ id, period: periodSelected.key, type: tasksSelected.key, page: value, rowsPerPage });
  };

  changePageRange = value => {
    const { id } = this.props.match.params;
    const { periodSelected, tasksSelected, requestTasks, updateRowsPerPage, page } = this.props;
    updateRowsPerPage(value);
    requestTasks({ id, period: periodSelected.key, type: tasksSelected.key, page, rowsPerPage: value });
  };

  render() {
    const { users, rangeStart, tasks, userType, periodSelected, tasksSelected, rowsPerPage, page, taskCount } =
      this.props;
    const { checked } = this.state;
    const { id } = this.props.match.params;

    const assignee = users.find(({ Id }) => Id === id) || {};
    const isPickUp = assignee.Name === 'Pick-Up';
    const isTeam = assignee.IsTeam && !isPickUp;

    const isSystemAdmin = userType === USER_TYPES.SystemAdministrator;
    const canAssignATaskToTheCurrentUser = (isPickUp || tasksSelected.key === 'pickUp') && !isSystemAdmin;
    const filteredTaskToggle = taskToggle.filter(({ key }) => (isTeam ? key !== 'my' : key !== 'team'));
    const defaultTasksType = filteredTaskToggle.filter(({ key }) => key === tasksSelected.key);
    const hasPermissionToRequests =
      canAssignATaskToTheCurrentUser || tasksSelected.key === 'my' || tasksSelected.key === 'team';

    if (isTeam) {
      periodToggle[2].total = tasks.past.team.amount ? tasks.past.team.amount : null;
      filteredTaskToggle[0].total = tasks[periodSelected.key].team.amount || null;
      filteredTaskToggle[1].total = tasks[periodSelected.key].pickUp.amount || null;
    } else {
      periodToggle[2].total = tasks.past.my.amount ? tasks.past.my.amount : null;
      filteredTaskToggle[0].total = tasks[periodSelected.key].my.amount || null;
      filteredTaskToggle[1].total = tasks[periodSelected.key].pickUp.amount || null;
    }

    return [
      <S.Container key="Tasks list">
        <Subheader
          title={assignee.Name ?? ''}
          isSiteOnLeftSide
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackClick,
              hint: 'Back',
            },
          ]}
        />
        <S.Toolbar>
          <Toggle config={periodToggle} selected={periodSelected.key} handler={this.periodHandler} />
          {!isPickUp && !!defaultTasksType.length && (
            <Toggle config={filteredTaskToggle} selected={tasksSelected.key} handler={this.tasksTypeHandler} />
          )}
        </S.Toolbar>
        <Period type={periodSelected.title} startRange={rangeStart} />
        <List
          checked={checked}
          onCheck={this.onCheck}
          onItemClick={this.onRowClick}
          period={periodSelected}
          renderLeftControls={this.renderLeftControls}
          renderRightControls={this.renderRightControls}
          tasksType={tasksSelected}
          showLeftCheckbox={hasPermissionToRequests}
        />
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={this.changePageRange}
          onPageChange={this.changePage}
          count={taskCount}
        />
      </S.Container>,
      <Footer
        key="Tasks footer"
        period={periodSelected.key}
        isChecked={!!checked.length}
        visible={hasPermissionToRequests}
        isCentralButtonVisible
        label={checked.length}
        labelName={canAssignATaskToTheCurrentUser ? 'Claim' : 'Release'}
        onPrev={this.onPrev}
        onNext={this.onNext}
        onCenter={() => this.onCenter(canAssignATaskToTheCurrentUser)}
      />,
    ];
  }
}

const mapStateToProps = ({
  tasks: { users, periodSelected, rangeStart, tasks, tasksSelected, taskCount, page, rowsPerPage },
  auth: {
    user: { userType, userId },
  },
}) => ({
  users,
  periodSelected,
  rangeStart,
  tasks,
  tasksSelected,
  taskCount,
  page,
  rowsPerPage,
  userType,
  userId,
});

const mapDispatchToProps = {
  openConfirmationDialog,
  requestReleaseTask: tasksActions.releaseTaskRequest,
  requestTasks: tasksActions.fetchTasksRequest,
  updatePeriod: tasksActions.updatePeriod,
  resetPeriod: tasksActions.resetPeriod,
  resetTasksType: tasksActions.resetTasksType,
  updateRangeStart: tasksActions.updateRangeStart,
  updateTasksType: tasksActions.updateTasksType,
  updatePage: tasksActions.updatePage,
  updateRowsPerPage: tasksActions.updateRowsPerPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksList);
