import styled from 'styled-components';

import { InputBlockLabel, BorderRadiusContainer } from 'components/Layout/Containers';

export const BoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const UpperInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items center;
  padding-right: 15px;
  margin-bottom: 15px;
  font-size: 12px;
`;

export const LimitCount = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.lightGrey} !important;
  border: ${({ theme }) => `2px solid ${theme.primaryBlack}`};
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListContainer = styled.div`
  padding: 30px 0 30px;
  overflow: auto;
  max-height: 25vh;
`;

export const SearchContainer = styled.div`
  padding-top: 20px;
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin-top: 20px;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
  text-align: left;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

export const TopInfo = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 14px;
`;

export const SubInfo = styled.div``;

export const Block = styled(BorderRadiusContainer)``;
