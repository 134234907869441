import React from 'react';
import PropTypes from 'prop-types';
import { FieldRadio, FieldText } from 'components/Layout/Inputs';
import { FieldSelect, SelectItem } from 'components/Layout/Selects';
import { matchOperator, constraints, optionNames } from './constants';

import * as S from './styled';

const check = item => item.ColumnName === 'ignore';

const SectionRules = ({ title, rules, ruleName }) => (
  <S.Section>
    <S.SectionHeader>{title}</S.SectionHeader>
    <S.SectionBody>
      <S.StyledCheckbox
        name={`${ruleName}-ignore-rules`}
        label={`Ignore rules: Include all ${ruleName.charAt(0).toUpperCase() + ruleName.slice(1)}s`}
        checked={!!rules.find(check)}
        disabled
      />
      <S.MatchSection>
        <span>Match</span>
        <S.RadioGroup name="match" valueSelected={rules[0].MatchOperator.toString()} disabled>
          {Object.keys(matchOperator).map(key => (
            <FieldRadio
              key={key}
              value={key}
              label={matchOperator[key]}
              style={{ width: 'auto', marginRight: '15px', flex: key }}
              disabled
            />
          ))}
        </S.RadioGroup>
      </S.MatchSection>
      {rules.map((rule, index) => (
        <S.RuleContainer key={`${rule.name}${index}`}>
          <FieldSelect name={`optionName-${index}`} value={rule.OptionName} disabled>
            {optionNames[ruleName].map(({ name, value }) => (
              <SelectItem key={name} value={value} primaryText={name} />
            ))}
          </FieldSelect>
          <FieldSelect name={`constraint-${index}`} value={rule.OptionConstraint.toString()} disabled>
            {Object.keys(constraints).map(key => (
              <SelectItem key={constraints[key]} value={key} primaryText={constraints[key]} />
            ))}
          </FieldSelect>
          <FieldText name={`optionValue-${index}`} value={rule.OptionValue} disabled />
        </S.RuleContainer>
      ))}
    </S.SectionBody>
  </S.Section>
);

SectionRules.propTypes = {
  title: PropTypes.string.isRequired,
  ruleName: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      OptionName: PropTypes.string,
      OptionConstraint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      OptionValue: PropTypes.string,
      MatchOperator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ColumnName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SectionRules;
