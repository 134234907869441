import styled from 'styled-components';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { SelectorContainer } from 'components/Dialogs/styled';

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RadioGroupContainer = styled(SelectorContainer)`
  padding: 20px 0 30px 50px;
`;

export const RadioGroup = styled(RadioButtonGroup)`
  & > div {
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    border-radius: unset;
    position: relative;
    &:first-child {
      padding-top: 30px;
      max-width: 95%;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
`;

export const FieldRadio = styled(RadioButton)``;
