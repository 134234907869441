export const clearDocumentPhotos = state => ({
  ...state,
  loading: false,
  backgroundImage: { id: '', name: '' },
  footerImage: { id: '', name: '' },
  headerImage: { id: '', name: '' },
});

export const updateDocumentPhotos = (state, { id, name, type }) => ({
  ...state,
  loading: false,
  [type]: { id: id || '', name: name || '' },
});

export const updateDocumentState = (state, documentItem) => ({
  ...state,
  documentEdited: false,
  documentItem,
  loading: false,
  backgroundImage: { id: documentItem.BackgroundImageId || '', name: documentItem.BackgroundImageFileName || '' },
  footerImage: { id: documentItem.FooterImageId || '', name: documentItem.FooterImageFileName || '' },
  headerImage: { id: documentItem.HeaderImageId || '', name: documentItem.HeaderImageFileName || '' },
});
