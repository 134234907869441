import styled from 'styled-components';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';
import { ProgressCircular } from 'components/Layout/Progresses';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding-top: 10px;
`;

export const SecondaryTitle = styled.div`
  padding: 30px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Title = styled.p`
  text-align: center;
  margin-bottom: 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Block = styled(BorderRadiusContainer)`
  margin: 40px 0 20px 0;
`;

export const InputContainer = styled(InputWrapper)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
  text-align: left;
  padding: 20px 15px 15px 15px;
`;

export const DialogContainer = styled.div`
  padding: 10px 10px 0;
  text-align: center;
`;

export const Overlay = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none !important')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.overlay};
  z-index: 2000;
`;

export const Loader = styled(ProgressCircular).attrs({
  size: 90,
})`
  circle {
    stroke: ${({ theme }) => `${theme.primary} !important`};
  }
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationWarning = styled.div`
  font-size: 18px;
  margin: 15px 0;
`;
