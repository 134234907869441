import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider, ReactReduxContext } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as teamsActions } from 'redux/teams';
import { actions as sitesActions } from 'redux/sites';

import { ShortCutType, SiteType } from 'configs/propTypes';

import theme from 'theme';

import { Dialog } from 'components/Dialogs/v1/Base';

import AddPeople from 'modules/activator/dialogs/NewSite/AddPeople';
import MissingResultsDialog from 'modules/activator/dialogs/NewSite/AddPeople/MissingResultsDialog';

import * as S from './styled';

class SiteSelection extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      getShortcutSitesRequest: PropTypes.func.isRequired,
      requestAssignedForSite: PropTypes.func.isRequired,
      getTeamsRequest: PropTypes.func.isRequired,
      getSiteScheduleRequest: PropTypes.func.isRequired,
      deleteSiteSchedule: PropTypes.func.isRequired,
    }).isRequired,
    shortcut: ShortCutType.isRequired,
    isActivator: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(SiteType).isRequired,
    sitesInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    shortcutToPreview: PropTypes.shape().isRequired,
    siteSchedule: PropTypes.shape().isRequired,
    listSchedule: PropTypes.shape().isRequired,
    onNext: PropTypes.func.isRequired,
    onSiteSelect: PropTypes.func.isRequired,
  };

  state = {
    missingDialogOpen: false,
    addPeopleDialogOpen: false,
    currentSite: {},
  };

  handleNext = item => {
    const { onSiteSelect, onNext } = this.props;

    onSiteSelect(item);
    onNext();
  };

  onSettingsClick = item => {
    const { siteSchedule } = this.props;

    this.setState({ currentSite: item, addPeopleDialogOpen: false });
    const diff = moment.duration(moment(siteSchedule.EndDate).diff(moment(new Date()))).asDays();

    if (diff >= 0) {
      this.props.actions.getTeamsRequest({ siteId: item.Id });
      this.props.actions.requestAssignedForSite({
        siteId: item.Id,
        onNext: sitePeople => this.checkIfSiteHasManagerOrTasksSpecialist(sitePeople, item),
      });
    }
  };

  componentDidUpdate(prevProps) {
    const {
      shortcut,
      actions: { getShortcutSitesRequest },
    } = this.props;
    if (
      shortcut.Name &&
      prevProps.shortcut.LocationRules !== shortcut.LocationRules &&
      prevProps.shortcut.ItemRules !== shortcut.ItemRules
    ) {
      getShortcutSitesRequest({ shortcut });
    }
    window.dispatchEvent(new Event('resize'));
  }

  componentDidMount() {
    const {
      sitesInfo,
      shortcut,
      isActivator,
      actions: { getShortcutSitesRequest, deleteSiteSchedule },
    } = this.props;

    if ((!sitesInfo?.length && shortcut.ItemRules) || isActivator) {
      getShortcutSitesRequest({ shortcut });
    }

    deleteSiteSchedule();
  }

  openMissingResultsDialog = () => {
    this.setState({ missingDialogOpen: true });
  };

  closeMissingResultsDialog = () => {
    this.setState({ missingDialogOpen: false });
  };

  openAddPeopleDialog = () => {
    this.setState({ missingDialogOpen: false, addPeopleDialogOpen: true });
  };

  closeAddPeopleDialog = () => {
    this.setState({ addPeopleDialogOpen: false, missingDialogOpen: false });
  };

  checkIfSiteHasManagerOrTasksSpecialist = (people, site) => {
    const hasManager = people.some(({ UserType }) => UserType === 'Manager');
    const hasTasksSpecialist = people.some(({ UserType }) => UserType === 'TaskSpecialist');

    if (!hasManager || !hasTasksSpecialist) {
      this.openMissingResultsDialog();
    } else {
      this.handleNext(site);
    }
  };

  findSite = (sitesInfo, id) => sitesInfo?.find(site => site?.SiteId === id);

  handleClick = item => {
    const { sitesInfo, listSchedule, shortcutToPreview, shortcut, actions, isActivator } = this.props;
    const isFindSite = this.findSite(shortcutToPreview.Id && !isActivator ? listSchedule : sitesInfo, item.Id);

    if (isFindSite && !isFindSite.IsScheduled) {
      this.onSettingsClick(item);
    }

    if (isFindSite && isFindSite.IsScheduled && !isActivator) {
      actions.getSiteScheduleRequest({
        shortcutId: shortcut.Id,
        siteId: isFindSite.SiteId,
        onNext: () => this.onSettingsClick(item),
      });
    }
  };

  render() {
    const { shortcut, list, sitesInfo, shortcutToPreview, listSchedule, isActivator } = this.props;
    const { missingDialogOpen, addPeopleDialogOpen, currentSite } = this.state;
    const info = shortcutToPreview?.Id && !isActivator ? listSchedule : sitesInfo;

    return (
      <>
        <S.Container>
          <S.Title>Shortcut Name</S.Title>
          <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
          <S.Block>
            <S.Label>Checklist Creation</S.Label>
            <S.TitleBlock>
              <S.LargeBox>Site Name</S.LargeBox>
              <S.MediumBox>Scheduled</S.MediumBox>
              <S.SmallBox>{shortcutToPreview.Id && !isActivator ? 'Edit' : 'Create'}</S.SmallBox>
            </S.TitleBlock>
            {Array.isArray(list) &&
              list.map(item => {
                const site = this.findSite(info, item.Id);
                const isScheduled = site && site.IsScheduled;

                return (
                  site && (
                    <S.ItemContainer key={item.Id}>
                      <S.LargeBox>
                        {item.AdditionalSiteID ? `${item.AdditionalSiteID.substring(0, 5)} ${item.Name}` : item.Name}
                      </S.LargeBox>
                      <S.MediumBox>{isScheduled ? <S.Scheduled /> : <S.NotScheduled />}</S.MediumBox>
                      <S.Button disabled={isScheduled} onClick={() => this.handleClick(item)}>
                        <S.Settings />
                      </S.Button>
                    </S.ItemContainer>
                  )
                );
              })}
          </S.Block>
        </S.Container>
        <MissingResultsDialog
          open={missingDialogOpen}
          label="ADD PEOPLE"
          handleClose={this.closeMissingResultsDialog}
          confirm={this.openAddPeopleDialog}
        />
        <ReactReduxContext.Consumer>
          {ctx => (
            <Dialog
              open={addPeopleDialogOpen}
              title="Site Setup"
              titleColor={theme.primarySites}
              onRequestClose={this.closeAddPeopleDialog}
            >
              <Provider store={ctx.store}>
                <S.AddPeopleDialogContainer>
                  <AddPeople
                    onExit={this.onSettingsClick}
                    addAnotherSite={this.closeAddPeopleDialog}
                    currentSite={currentSite}
                    isDemoMode={false}
                    isEditMode
                  />
                </S.AddPeopleDialogContainer>
              </Provider>
            </Dialog>
          )}
        </ReactReduxContext.Consumer>
      </>
    );
  }
}

const mapStateToProps = ({ shortcuts }) => ({
  list: shortcuts.listOfSites,
  sitesInfo: shortcuts.shortcutSitesInfo,
  shortcut: shortcuts.shortcut,
  shortcutToPreview: shortcuts.shortcutToPreview,
  listSchedule: shortcuts.listSchedule?.map(item => ({
    ...item,
    SiteId: item.SiteID,
  })),
  siteSchedule: shortcuts.siteSchedule,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
      ...teamsActions,
      requestAssignedForSite: sitesActions.assignedUsersRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelection);
