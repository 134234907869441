const initialState = {
  creatededitedTenantId: '',
  error: '',
  industryList: [],
  tiersList: [],
  industryId: '',
  list: [],
  loading: false,
  loginAsUsers: [],
  tenantRequestsStatistics: {},
  currentTenantsCount: {},
  currentTenantPricingValues: null,
  currentTenantRecentUpgrade: null,
  requests: [],
  upgradeRequestsList: [],
};

export const addTenantRequest = state => ({
  ...state,
  creatededitedTenantId: '',
  error: '',
  loading: true,
});
export const addTenantSuccess = (state, { payload }) => ({
  ...state,
  creatededitedTenantId: payload.Id,
});
export const addTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const deleteTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const editTenantRequest = state => ({
  ...state,
  creatededitedTenantId: '',
  error: '',
  loading: true,
});
export const editTenantSuccess = (state, { payload }) => ({
  ...state,
  creatededitedTenantId: payload.Id,
});
export const editTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const deactivateTenantRequest = state => ({
  ...state,
  TenantId: '',
  error: '',
  loading: true,
});
export const deactivateTenantSuccess = (state, { payload }) => ({
  ...state,
  TenantId: payload.Id,
});
export const deactivateTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const activateTenantRequest = state => ({
  ...state,
  TenantId: '',
  error: '',
  loading: true,
});
export const activateTenantSuccess = (state, { payload }) => ({
  ...state,
  TenantId: payload.Id,
});
export const activateTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const tenantsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const tenantsListSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  loading: false,
});
export const tenantsListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const usersLoginasListRequest = state => ({
  ...state,
  error: '',
  loading: false,
});
export const usersLoginasListSuccess = (state, { payload }) => ({
  ...state,
  loginAsUsers: payload,
  loading: false,
});
export const usersLoginasListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const tenantIndustryListRequest = state => ({
  ...state,
  error: '',
  loading: false,
});
export const tenantIndustryListSuccess = (state, { payload }) => ({
  ...state,
  industryList: payload,
  loading: false,
});
export const tenantIndustryListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const addTenantIndustryRequest = state => ({
  ...state,
  error: '',
  loading: false,
});
export const addTenantIndustrySuccess = (state, { payload }) => ({
  ...state,
  industryId: payload,
  loading: false,
});
export const addTenantIndustryFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const editTenantIndustryRequest = state => ({
  ...state,
  error: '',
  loading: false,
});
export const editTenantIndustrySuccess = (state, { payload }) => ({
  ...state,
  industryId: payload,
  loading: false,
});
export const editTenantIndustryFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const deleteTenantIndustryRequest = state => ({
  ...state,
  error: '',
  loading: false,
});
export const deleteTenantIndustryFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const tenantRequestsStatisticsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const tenantRequestsStatisticsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  loading: false,
  tenantRequestsStatistics: payload,
});
export const tenantRequestsStatisticsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const tenantRequestsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const tenantRequestsListSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  loading: false,
  requests: payload,
});
export const tenantRequestsListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const approveDemoTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const approveDemoTenantSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const approveDemoTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const removeDemoTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const removeDemoTenantSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const removeDemoTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const blockDemoTenantRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const blockDemoTenantSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const blockDemoTenantFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getCurrentTenantsCountRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getCurrentTenantsCountSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  loading: false,
  currentTenantsCount: payload,
});
export const getCurrentTenantsCountFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getUpgradeRequestsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getUpgradeRequestsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  upgradeRequestsList: payload,
  loading: false,
});
export const getUpgradeRequestsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const editUpgradeRequestRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const editUpgradeRequestSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const editUpgradeRequestFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const deleteUpgradeRequestRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteUpgradeRequestSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const deleteUpgradeRequestFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const getRecentUpgradeRequestRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getRecentUpgradeRequestSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  loading: false,
  currentTenantRecentUpgrade: payload,
});
export const getRecentUpgradeRequestFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const clearRecentUpgradeRequest = state => ({
  ...state,
  error: '',
  loading: false,
  currentTenantRecentUpgrade: null,
});
export const fetchTiersRequest = state => ({
  ...state,
  error: '',
  loading: false,
});
export const fetchTiersSuccess = (state, { payload }) => ({
  ...state,
  tiersList: payload,
  loading: false,
});
export const fetchTiersFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getTenantPricingValuesRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const getTenantPricingValuesSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  loading: false,
  currentTenantPricingValues: payload,
});
export const getTenantPricingValuesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const overwritePricingValuesRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const overwritePricingValuesSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const overwritePricingValuesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteSentRequestsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deleteSentRequestsSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});
export const deleteSentRequestsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const clearTiers = state => ({
  ...state,
  tiersList: [],
});

export default initialState;
