export const validator = values => {
  const errors = {};

  if (!values.VersionNumber) {
    errors.VersionNumber = 'Required';
  }

  if (!values.VersionNotes) {
    errors.VersionNotes = 'Required';
  }

  if (!values.CopyrightText) {
    errors.CopyrightText = 'Required';
  }

  if (!values.PrivacyUrl) {
    errors.PrivacyUrl = 'Required';
  }

  return errors;
};

export default validator;
