import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { SiteType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import validate from './validator';
import * as S from './styled';

const AddEditForm = () => (
  <S.FormContainer>
    <Field component={TextFieldAdapter} floatingLabelText="Name" name="Name" />
    <Field component={TextFieldAdapter} floatingLabelText="Site ID" name="AdditionalSiteID" />
    <Field component={TextFieldAdapter} floatingLabelText="Phone" name="Phone" />
    <Field disabled component={TextFieldAdapter} floatingLabelText="Beacon Id" name="BeaconId" />

    <S.Block>
      <S.Label>Administrator</S.Label>
      <S.InputContainer>
        <Field component={TextFieldAdapter} floatingLabelText="Name" name="AdministratorName" />
        <Field component={TextFieldAdapter} floatingLabelText="Title" name="AdministratorTitle" />
        <Field component={TextFieldAdapter} floatingLabelText="Email" name="AdministratorEmail" />
        <Field component={TextFieldAdapter} floatingLabelText="Phone" name="AdministratorPhone" />
      </S.InputContainer>
    </S.Block>

    <S.Block>
      <S.Label>Address</S.Label>
      <S.InputContainer>
        <Field component={TextFieldAdapter} floatingLabelText="Street" name="Address.Street" />
        <Field component={TextFieldAdapter} floatingLabelText="Street2" name="Address.Street2" />
        <Field component={TextFieldAdapter} floatingLabelText="City" name="Address.City" />
        <Field component={TextFieldAdapter} floatingLabelText="State" name="Address.State" />
        <Field component={TextFieldAdapter} floatingLabelText="Zip Code" name="Address.ZipCode" />
        <Field component={TextFieldAdapter} floatingLabelText="Country" name="Address.Country" />
      </S.InputContainer>
    </S.Block>
  </S.FormContainer>
);

const AddEditWithInput = withInputModal(AddEditForm);

const AddEditDialog = ({ site, changeDistance, distance, ...rest }) => (
  <AddEditWithInput
    {...rest}
    initialValues={site}
    validate={validate}
    changeDistance={changeDistance}
    distance={distance}
  />
);

AddEditDialog.defaultProps = {
  changeDistance: () => {},
  distance: 0,
  handleDelete: () => {},
  site: {
    Address: {
      City: '',
      Country: '',
      State: '',
      Street: '',
      Street2: '',
      ZipCode: '',
    },
    AdministratorEmail: '',
    AdministratorName: '',
    AdministratorPhone: '',
    AdministratorTitle: '',
    BeaconDistance: 0,
    BeaconDistanceItem: 0,
    BeaconDistanceUnit: 0,
    BeaconId: '',
    Id: '',
    Name: '',
    Phone: '',
    AdditionalSiteID: '',
  },
  withDelete: false,
};

AddEditDialog.propTypes = {
  changeDistance: PropTypes.func,
  distance: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  site: SiteType,
  withDelete: PropTypes.bool,
};

export default AddEditDialog;
