import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getTaskName } from 'helpers';
import { UnitItemTaskTemplateType } from 'configs/propTypes';
import { AddIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import Toggle from 'components/Toggle';
import { ToolbarContainer } from '../../styled';
import TableList from 'components/TableList';

import { itemDetailsToggle } from 'configs/toggles';
import { tableConfigs, tableHeads } from './configs';

import * as S from './styled';

class Tasks extends Component {
  static propTypes = {
    itemId: PropTypes.string.isRequired,
    onAddEditTaskTemplateClick: PropTypes.func.isRequired,
    tasks: PropTypes.arrayOf(UnitItemTaskTemplateType).isRequired,
    onCopyTask: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    typeHandler: PropTypes.func.isRequired,
    searchData: PropTypes.string.isRequired,
  };

  filterList = item => item?.Name.toLowerCase().includes(this.props.searchData.toLowerCase());

  mapList = item =>
    item.Id
      ? {
          ...item,
          Duplicate: (
            <ButtonIcon
              tooltip="Duplicate"
              onClick={e => {
                e.stopPropagation();
                this.props.onCopyTask(item);
              }}
            >
              <S.IconCopy />
            </ButtonIcon>
          ),
          TasksAndInstructions: (
            <>
              <S.BoldText>{getTaskName('', item.RuleType)}</S.BoldText> {item.Name}
            </>
          ),
          Specification: item.RuleData.Specification,
          Min: item.RuleData.MinAttachments || item.RuleData.ExpectedLowerBound,
          Max: item.RuleData.MaxAttachments || item.RuleData.ExpectedUpperBound,
          Notes: (
            <>
              <ButtonIcon>
                <S.NotesIcon data-tip data-for={item.Id} />
              </ButtonIcon>
              <S.Tooltip id={item.Id} place="bottom" effect="solid">
                <S.TooltipBlock>
                  <S.WhiteNotesIcon />
                  <S.NotesTooltipBlock>
                    <S.TooltipTitle>Task Notes/Additional Instructions</S.TooltipTitle>
                    <S.TooltipText>{item.InstructionalNotes}</S.TooltipText>
                  </S.NotesTooltipBlock>
                </S.TooltipBlock>
              </S.Tooltip>
            </>
          ),
        }
      : item;

  onRowClick = task => {
    const { itemId, onAddEditTaskTemplateClick } = this.props;
    onAddEditTaskTemplateClick(itemId, task);
  };

  render() {
    const { itemId, onAddEditTaskTemplateClick, tasks, type, typeHandler } = this.props;

    return (
      <>
        <ToolbarContainer>
          <Toggle config={itemDetailsToggle} selected={type.toLowerCase()} handler={typeHandler} minWidth />
          <ButtonIcon onClick={() => onAddEditTaskTemplateClick(itemId, {})}>
            <AddIcon />
          </ButtonIcon>
        </ToolbarContainer>
        <S.Container>
          {!tasks?.length ? (
            <S.EmptyList>
              <S.Message>This item has no tasks.</S.Message>
            </S.EmptyList>
          ) : (
            <S.TableContainer>
              <TableList
                isPointer
                list={tasks.map(this.mapList).filter(this.filterList)}
                tableHeads={tableHeads}
                tableColumns={tableConfigs}
                onRowClick={this.onRowClick}
              />
            </S.TableContainer>
          )}
        </S.Container>
      </>
    );
  }
}

Tasks.defaultProps = {
  itemId: '',
};

export default Tasks;
