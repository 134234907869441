import React from 'react';

import { UnitItemType } from 'configs/propTypes';
import { InformationContainer, Label, Text } from './styled';

const Information = ({ item }) => (
  <InformationContainer>
    <Text>{item.Description}</Text>
    <Label>Instruction</Label>
    <Text>{item.Instructions}</Text>
    <Label>Scan Code</Label>
    <Text>{item.Barcode && item.Barcode.Value}</Text>
    <Label>Internal Notes</Label>
    <Text>{item.Notes}</Text>
  </InformationContainer>
);

Information.propTypes = {
  item: UnitItemType.isRequired,
};

export default Information;
