import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';
import { BackIcon, DeleteIcon } from 'components/Layout/Icons';
import TableList from 'components/TableList';
import { FieldCheckbox } from 'components/Layout/Inputs';
import ConfirmRemovalText from './ConfirmRemovalText';
import { tableColumns, tableHeads } from './constants';

import { actions as partsActions } from 'redux/parts';
import { openErrorDialog } from 'redux/errorHandler';
import ConfirmDialog from 'components/Dialogs/Confirm';

import { partsToggle } from 'configs/toggles';
import { PartsListType, PartGroupType } from 'configs/propTypes';

import theme from 'theme';
import * as S from './styled';

class PartsList extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    partsList: PartsListType.isRequired,
    groupsList: PartGroupType.isRequired,
    actions: PropTypes.shape({
      getParts: PropTypes.func,
      getPartGroups: PropTypes.func,
      deleteParts: PropTypes.func,
      deletePartGroups: PropTypes.func,
      openErrorDialog: PropTypes.func,
    }).isRequired,
    error: PropTypes.string.isRequired,
  };

  state = {
    searchData: '',
    confirmDialog: {
      open: false,
      entityToRemove: [],
    },
  };

  componentDidUpdate(prevProps) {
    const { error, actions } = this.props;

    if (prevProps.error.length === 0 && error.length > 0) {
      actions.openErrorDialog(error, 'Error');
    }
  }

  modeHandler = mode => {
    this.setState({ confirmDialog: { open: false, entityToRemove: [] } }, () => {
      this.props.history.push(`/settings/purgeparts/${mode.key}`);
    });
  };

  goBack = () => this.props.history.push('/settings');

  searchInList = e => this.setState({ searchData: e.target.value });

  filterList = item => {
    const {
      match: {
        params: { type },
      },
    } = this.props;

    return tableColumns[type]
      .map(({ field }) => field)
      .some(
        field =>
          item[field] &&
          typeof item[field] === 'string' &&
          item[field].toLowerCase().includes(this.state.searchData.toLowerCase()),
      );
  };

  handleOpenConfirmModal = () => this.setState({ confirmDialog: { ...this.state.confirmDialog, open: true } });

  handleCheckAll = list => e =>
    this.setState({
      confirmDialog: { ...this.state.confirmDialog, entityToRemove: e.target.checked ? list : [] },
    });

  handleCheckItem = item => e => {
    const {
      confirmDialog: { entityToRemove },
    } = this.state;
    const currentEntityToRemove = e.target.checked
      ? [...entityToRemove, item]
      : entityToRemove.filter(entity => entity.Id !== item.Id);
    this.setState({ confirmDialog: { ...this.state.confirmDialog, entityToRemove: currentEntityToRemove } });
  };

  isCheckboxChecked = item => {
    const {
      confirmDialog: { entityToRemove },
    } = this.state;

    if (entityToRemove.find(entity => entity.Id === item.Id)) return true;
    return false;
  };

  renderLeftControllCell = item => (
    <td>
      <FieldCheckbox onCheck={this.handleCheckItem(item)} checked={this.isCheckboxChecked(item)} />
    </td>
  );

  renderLeftControllTableHead = list => {
    const { confirmDialog } = this.state;
    const isCheched = list.length && list.length === confirmDialog.entityToRemove.length;

    return (
      <th>
        All
        <FieldCheckbox onCheck={this.handleCheckAll(list)} checked={isCheched} />
      </th>
    );
  };

  handleRemoveApprove = () => {
    const {
      match: {
        params: { type },
      },
      actions: { deleteParts, deletePartGroups },
    } = this.props;
    const {
      confirmDialog: { entityToRemove },
    } = this.state;
    const ids = entityToRemove.map(entity => entity.Id);

    if (type === 'parts') deleteParts(ids);
    else deletePartGroups(ids);
    this.setState({ confirmDialog: { ...this.state.confirmDialog, entityToRemove: [], open: false } });
  };

  handleRemoveReject = () => {
    this.setState({ confirmDialog: { ...this.state.confirmDialog, open: false } });
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getParts();
    actions.getPartGroups();
  }

  render() {
    const {
      match: {
        params: { type },
      },
      partsList,
      groupsList,
    } = this.props;
    const { searchData, confirmDialog } = this.state;
    const list = type === 'parts' ? partsList : groupsList;
    return (
      <>
        <Subheader
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.goBack,
              hint: 'Back',
            },
          ]}
          title={
            <div>
              <h2>{`${type.charAt(0).toUpperCase()}${type.slice(1)}`}</h2>
              <Toggle config={partsToggle} selected={type} handler={this.modeHandler} />
            </div>
          }
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          rightButtons={[
            {
              icon: <DeleteIcon color={confirmDialog.entityToRemove.length ? theme.primaryRed : theme.primaryGrey} />,
              handler: this.handleOpenConfirmModal,
              hint: confirmDialog.entityToRemove.length ? 'Delete' : '',
              disabled: !confirmDialog.entityToRemove.length,
            },
          ]}
        />
        <S.TableContainer mode={type}>
          <TableList
            tableHeads={tableHeads[type]}
            list={list.filter(this.filterList)}
            tableColumns={tableColumns[type]}
            renderRightControllCell={this.renderRightControllCell}
            renderLeftControllCell={this.renderLeftControllCell}
            renderLeftControllTableHead={this.renderLeftControllTableHead}
            handlers={{
              onPartRemove: this.handlePartRemovePress,
              openDistributionDialog: this.handleOpenDistributionDialog,
              onGroupRemove: this.handleGroupRemovePress,
            }}
          />
        </S.TableContainer>
        <ConfirmDialog
          open={confirmDialog.open}
          title="Warning"
          text={
            <ConfirmRemovalText
              list={confirmDialog.entityToRemove.filter(entity => entity.LinksCount > 0)}
              type={type}
            />
          }
          confirmText="Delete"
          cancelText="Cancel"
          onApprove={this.handleRemoveApprove}
          onReject={this.handleRemoveReject}
          actionsContainerStyle={{ display: 'flex', 'justify-content': 'space-around' }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ parts }) => ({
  error: parts.error,
  partsList: parts.list,
  groupsList: parts.groupsList,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openErrorDialog,
      getParts: partsActions.getPartsRequest,
      getPartGroups: partsActions.getPartGroupsRequest,
      deleteParts: partsActions.deletePartsRequest,
      deletePartGroups: partsActions.deletePartGroupsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartsList);
