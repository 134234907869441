import styled from 'styled-components';
import { ProgressCircular } from 'components/Layout/Progresses';

export const Loader = styled(ProgressCircular).attrs({
  size: 90,
})`
  circle {
    stroke: ${({ theme }) => `${theme.primary} !important`};
  }
`;

export const DialogContainer = styled.div`
  padding: 40px 40px 0;
  text-align: center;
`;

export const Overlay = styled.div`
  display: ${({ show }) => !show && 'none !important'};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.overlay};
  z-index: 2000;
`;
