import { bindActionCreators } from 'redux';
import { actions as listTemplatesActions } from 'redux/listTemplates';

const mapStateToProps = ({ listTemplates: { assetCategoryList, assetCategory, assetTypeList } }) => ({
  assetCategoryList,
  assetCategory,
  assetTypeList,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...listTemplatesActions,
    },
    dispatch,
  ),
});

export { mapStateToProps, mapDispatchToProps };
