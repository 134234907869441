import styled from 'styled-components';
import { Box } from 'grid-styled';

import BaseDialog from 'components/Dialogs/Base';
import { ButtonFlat } from 'components/Layout/Buttons';

export const CancelButton = styled(ButtonFlat).attrs({
  label: 'Cancel',
})`
  min-width: 50% !important;

  span {
    color: ${({ theme }) => theme.modalCancelColor} !important;
  }
`;

export const DialogContainer = styled(BaseDialog).attrs({
  actionsContainerStyle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})`
  & > div > div {
    width: 95% !important;
  }

  @media only screen and (min-width: 32em) {
    & > div > div {
      width: 70% !important;
    }
  }

  @media only screen and (min-width: 48em) {
    & > div > div {
      width: 60% !important;
    }
  }

  @media only screen and (min-width: 64em) {
    & > div > div {
      width: 50% !important;
    }
  }

  @media only screen and (min-width: 80em) {
    & > div > div {
      width: 35% !important;
    }
  }
`;

export const PrimaryText = styled(Box).attrs({
  p: 24,
})`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const SecondaryText = styled(Box).attrs({
  p: 24,
  pt: 0,
})`
  font-size: 16px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const SubmitButton = styled(ButtonFlat)`
  min-width: 50% !important;
  border-left: 1px solid ${({ theme }) => theme.lightGrey} !important;

  span {
    color: ${({ theme }) => theme.greenButton} !important;
  }
`;
