import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  AUTHENTICATE_REQUEST: undefined,
  AUTHENTICATE_SUCCESS: undefined,
  AUTHENTICATE_FAILURE: undefined,

  AUTHENTICATION_CHECK_REQUEST: undefined,
  AUTHENTICATION_CHECK_SUCCESS: undefined,
  AUTHENTICATION_CHECK_FAILURE: undefined,

  FETCH_USER_SITES_REQUEST: undefined,
  FETCH_USER_SITES_SUCCESS: undefined,
  FETCH_USER_SITES_FAILURE: undefined,

  FORGOT_PASSWORD_REQUEST: undefined,
  FORGOT_PASSWORD_SUCCESS: undefined,
  FORGOT_PASSWORD_FAILURE: undefined,

  LOGOUT_REQUEST: undefined,
  LOGOUT_SUCCESS: undefined,
  LOGOUT_FAILURE: undefined,

  RESET_PASSWORD_REQUEST: undefined,
  RESET_PASSWORD_SUCCESS: undefined,
  RESET_PASSWORD_FAILURE: undefined,

  RESETED_UNSET: undefined,

  SELECTED_SITE_CHANGE: undefined,
  ON_SITE_CHANGE: undefined,

  USERS_LOGINAS_REQUEST: undefined,
  USERS_LOGINAS_SUCCESS: undefined,
  USERS_LOGINAS_FAILURE: undefined,

  CHECK_IS_DEMO_USER_NAME_EXISTS_REQUEST: undefined,

  CHECK_IS_DEMO_TENANT_NAME_EXISTS_REQUEST: undefined,

  CHECK_IS_DEMO_NAME_EXISTS_SUCCESS: undefined,
  CHECK_IS_DEMO_NAME_EXISTS_FAILURE: undefined,

  CREATE_DEMO_TENANT_REQUEST: undefined,
  CREATE_DEMO_TENANT_SUCCESS: undefined,
  CREATE_DEMO_TENANT_FAILURE: undefined,

  VERIFY_TENANT_REQUEST: undefined,
  VERIFY_TENANT_SUCCESS: undefined,
  VERIFY_TENANT_FAILURE: undefined,

  ACTIVATE_ACCOUNT_REQUEST: undefined,
  ACTIVATE_ACCOUNT_SUCCESS: undefined,
  ACTIVATE_ACCOUNT_FAILURE: undefined,

  SET_AUTHENTICATED_TO_FALSE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.authenticateRequest, handlers.authenticateRequest],
    [actions.authenticateSuccess, handlers.authenticateSuccess],
    [actions.authenticateFailure, handlers.authenticateFailure],

    [actions.authenticationCheckRequest, handlers.authenticationCheckRequest],
    [actions.authenticationCheckSuccess, handlers.authenticationCheckSuccess],
    [actions.authenticationCheckFailure, handlers.authenticationCheckFailure],

    [actions.fetchUserSitesRequest, handlers.fetchUserSitesRequest],
    [actions.fetchUserSitesSuccess, handlers.fetchUserSitesSuccess],
    [actions.fetchUserSitesFailure, handlers.fetchUserSitesFailure],

    [actions.forgotPasswordRequest, handlers.forgotPasswordRequest],
    [actions.forgotPasswordSuccess, handlers.forgotPasswordSuccess],
    [actions.forgotPasswordFailure, handlers.forgotPasswordFailure],

    [actions.logoutRequest, handlers.logoutRequest],
    [actions.logoutSuccess, handlers.logoutSuccess],
    [actions.logoutFailure, handlers.logoutFailure],

    [actions.resetPasswordRequest, handlers.resetPasswordRequest],
    [actions.resetPasswordSuccess, handlers.resetPasswordSuccess],
    [actions.resetPasswordFailure, handlers.resetPasswordFailure],

    [actions.resetedUnset, handlers.resetedUnset],

    [actions.selectedSiteChange, handlers.selectedSiteChange],
    [actions.onSiteChange, handlers.onSiteChange],

    [actions.usersLoginasRequest, handlers.usersLoginasRequest],
    [actions.usersLoginasSuccess, handlers.usersLoginasSuccess],
    [actions.usersLoginasFailure, handlers.usersLoginasFailure],

    [actions.checkIsDemoUserNameExistsRequest, handlers.checkIsDemoUserNameExistsRequest],

    [actions.checkIsDemoTenantNameExistsRequest, handlers.checkIsDemoTenantNameExistsRequest],

    [actions.checkIsDemoNameExistsSuccess, handlers.checkIsDemoNameExistsSuccess],
    [actions.checkIsDemoNameExistsFailure, handlers.checkIsDemoNameExistsFailure],

    [actions.createDemoTenantRequest, handlers.createDemoTenantRequest],
    [actions.createDemoTenantSuccess, handlers.createDemoTenantSuccess],
    [actions.createDemoTenantFailure, handlers.createDemoTenantFailure],

    [actions.verifyTenantRequest, handlers.verifyTenantRequest],
    [actions.verifyTenantSuccess, handlers.verifyTenantSuccess],
    [actions.verifyTenantFailure, handlers.verifyTenantFailure],

    [actions.activateAccountRequest, handlers.activateAccountRequest],
    [actions.activateAccountSuccess, handlers.activateAccountSuccess],
    [actions.activateAccountFailure, handlers.activateAccountFailure],

    [actions.setAuthenticatedToFalse, handlers.setAuthenticatedToFalse],
  ]),
  initialState,
);

export default reducer;
