import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import theme from 'theme';

import * as S from './styled';

import {
  REMOVAL_TYPES,
  CONFIRMATION_BUTTON_LABELS,
  ALERT_REQUIRING_TASKS_BUTTON_LABELS,
  TASK_SPECIALIST_TITLE_DELETE,
  ACTIONS,
} from '../config';

const styles = {
  radio: { marginBottom: '10px' },
  label: { color: '#000' },
  icon: isActive => ({ fill: isActive && theme.mainRed }),
};

class UserStatsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    goToAttention: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(REMOVAL_TYPES)).isRequired,
    data: PropTypes.shape({
      SitesCount: PropTypes.number.isRequired,
      TeamsCount: PropTypes.number.isRequired,
      CompletedTasksCount: PropTypes.number.isRequired,
      ActiveTasksCount: PropTypes.number.isRequired,
      RequiredAttentionCount: PropTypes.number.isRequired,
      RequireAttentionSites: PropTypes.array.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    isManagerTaskDialog: PropTypes.bool.isRequired,
  };

  state = {
    selectedAction: ACTIONS.open,
    isTaskRequiringAttentionDialogOpened: false,
    isReassignDeleteDialogOpened: false,
  };

  handleActionChange = (event, selectedAction) => {
    this.setState({ selectedAction });
  };

  handleConfirm = () => {
    const { selectedAction } = this.state;
    const { type, onSubmit } = this.props;

    this.setState({ isReassignDeleteDialogOpened: false }, () => {
      onSubmit(type, selectedAction);
    });
  };

  handleTaskRequiringReject = () => this.setState({ isTaskRequiringAttentionDialogOpened: false });

  handleReject = () => this.setState({ isReassignDeleteDialogOpened: false });

  handleTaskRequiringClick = () =>
    this.setState({
      isReassignDeleteDialogOpened: true,
      isTaskRequiringAttentionDialogOpened: false,
    });

  handleSubmit = () => {
    const { data } = this.props;
    if (data?.RequiredAttentionCount > 0) {
      this.setState({ isTaskRequiringAttentionDialogOpened: true });
    } else {
      this.setState({ isReassignDeleteDialogOpened: true });
    }
  };

  render() {
    const {
      open,
      handleClose,
      type,
      name,
      data: {
        SitesCount,
        TeamsCount,
        CompletedTasksCount,
        ActiveTasksCount,
        RequiredAttentionCount,
        RequireAttentionSites,
      },
      isManagerTaskDialog,
      goToAttention,
    } = this.props;
    const { selectedAction, isTaskRequiringAttentionDialogOpened, isReassignDeleteDialogOpened } = this.state;

    return (
      <>
        <Dialog open={open} onRequestClose={handleClose} title="Alert" titleColor={theme.mainRed}>
          <S.Container>
            <S.WarningsContainer>
              <S.UserNameText>{name}</S.UserNameText>
              <S.WarningText>
                {type === REMOVAL_TYPES.REMOVE ? 'Removing' : 'Deactivating'} this person may disrupt tasks in progress!
              </S.WarningText>
              <S.SmallWarningText>
                Completed tasks will be moved to the <strong>archive</strong>
              </S.SmallWarningText>
            </S.WarningsContainer>
            <S.CardsContainer>
              {!isManagerTaskDialog && (
                <S.Card>
                  <S.CardHeader>Team Status</S.CardHeader>
                  <S.CardContent>
                    <S.Row>
                      <S.Value>This user is part of:</S.Value>
                    </S.Row>
                    <S.Row>
                      <S.Value>{SitesCount}</S.Value>
                      <S.Label>Sites</S.Label>
                    </S.Row>
                    <S.Row>
                      <S.Value>{TeamsCount}</S.Value>
                      <S.Label>Teams</S.Label>
                    </S.Row>
                  </S.CardContent>
                </S.Card>
              )}
              <S.Card>
                <S.CardHeader>{isManagerTaskDialog ? 'Personal' : null} Tasks Status</S.CardHeader>
                <S.CardContent>
                  <S.Row>
                    <S.Value>{ActiveTasksCount}</S.Value>
                    <S.Label>Tasks are in progress</S.Label>
                  </S.Row>
                  <S.Row>
                    <S.Value>{CompletedTasksCount}</S.Value>
                    <S.Label>Tasks are completed but not archived</S.Label>
                  </S.Row>
                  <S.RedRow>
                    <S.Value>{RequiredAttentionCount}</S.Value>
                    <S.Label>Tasks require attention</S.Label>
                  </S.RedRow>
                </S.CardContent>
              </S.Card>
              <S.Card>
                <S.CardHeader>Requiring Attention</S.CardHeader>
                <S.CardContent>
                  {RequireAttentionSites &&
                    RequireAttentionSites.map(item => (
                      <S.SiteRow onClick={() => goToAttention(item.Name)}>
                        <S.IconButton>
                          <S.ExitIcon />
                        </S.IconButton>
                        <S.SiteText>{`${item?.AdditionalSiteID} ${item.Name}`}</S.SiteText>
                      </S.SiteRow>
                    ))}
                </S.CardContent>
              </S.Card>
            </S.CardsContainer>
            <S.ActionsContainer>
              <RadioButtonGroup name="action" valueSelected={selectedAction} onChange={this.handleActionChange}>
                <RadioButton
                  value={ACTIONS.open}
                  label="Change responsibility for all active tasks to: Open Assignment"
                  labelStyle={styles.label}
                  style={styles.radio}
                  iconStyle={styles.icon(selectedAction === ACTIONS.open)}
                />
                <RadioButton
                  value={ACTIONS.delete}
                  label="Delete all active (none completed and past due) tasks"
                  labelStyle={styles.label}
                  style={styles.radio}
                  iconStyle={styles.icon(selectedAction === ACTIONS.delete)}
                />
              </RadioButtonGroup>
            </S.ActionsContainer>
            <ActionsContainer>
              <ActionButton
                label={`${CONFIRMATION_BUTTON_LABELS[type]} ${isManagerTaskDialog ? 'Manager' : 'User'}`}
                onClick={this.handleSubmit}
              />
            </ActionsContainer>
          </S.Container>
        </Dialog>
        <ConfirmationDialog
          open={isTaskRequiringAttentionDialogOpened}
          onClick={this.handleTaskRequiringClick}
          handleClose={this.handleTaskRequiringReject}
          label={ALERT_REQUIRING_TASKS_BUTTON_LABELS[type]}
          width="70%"
          maxWidth="400px"
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationTitle>{RequiredAttentionCount} Tasks Requiring Attention</S.ConfirmationTitle>
              <S.ConfirmationText>
                Are you sure you want to {REMOVAL_TYPES[type?.toUpperCase()]} this person?
              </S.ConfirmationText>
            </S.ConfirmationContainer>
          }
        />
        <ConfirmationDialog
          open={isReassignDeleteDialogOpened}
          onClick={this.handleConfirm}
          handleClose={this.handleReject}
          isCancelNext
          width="70%"
          maxWidth="400px"
          labelCancel="Cancel"
          onCancelClick={this.handleReject}
          label={ALERT_REQUIRING_TASKS_BUTTON_LABELS[type]}
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationTitle>{TASK_SPECIALIST_TITLE_DELETE[selectedAction]}</S.ConfirmationTitle>
              <S.ConfirmationText>
                Are you sure you want to {REMOVAL_TYPES[type?.toUpperCase()]} this person?
                <br />
                This action cannot be undone!
              </S.ConfirmationText>
            </S.ConfirmationContainer>
          }
        />
      </>
    );
  }
}

export default UserStatsDialog;
