import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, ReactReduxContext, connect } from 'react-redux';

import { Dialog } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import InfoPage from './InfoPage';
import SiteName from './SiteName';
import AddPeople from './AddPeople';

import * as S from './styled';

const STEPS_NEW = {
  0: {
    Component: InfoPage,
  },
  1: {
    Component: SiteName,
  },
  2: {
    Component: AddPeople,
  },
};

const STEPS = {
  0: {
    Component: SiteName,
  },
  1: {
    Component: AddPeople,
  },
};

class NewSite extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    addAnotherSite: PropTypes.func.isRequired,
    firstTimeNewSite: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDemoMode: PropTypes.bool.isRequired,
  };

  state = {
    step: 0,
    currentSite: null,
  };

  setCurrentSite = site => {
    this.setState({ currentSite: site });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ step: 0, currentSite: null });
    }
  }

  handlePrev = () => {
    this.setState(({ step }) => ({ step: step - 1 }));
  };

  handleNext = () => {
    this.setState(({ step }) => ({ step: step + 1 }));
  };

  render() {
    const { step, currentSite } = this.state;
    const { open, handleClose, handleExit, firstTimeNewSite, isLoading, isDemoMode } = this.props;
    const Step = firstTimeNewSite ? STEPS_NEW[step].Component : STEPS[step].Component;

    return (
      <ReactReduxContext.Consumer>
        {
          ctx => (
            <Dialog
              open={open}
              onRequestClose={
                (firstTimeNewSite && step < 2) || (!firstTimeNewSite && step < 1) ? handleExit : handleClose
              }
              title="Site Setup"
              titleColor={theme.primarySites}
            >
              <S.DialogContainer>
                <S.Overlay show={isLoading}>
                  <S.Loader />
                </S.Overlay>
                <Provider store={ctx.store}>
                  <Step
                    onNext={this.handleNext}
                    onBack={this.handlePrev}
                    onClose={handleClose}
                    onExit={handleExit}
                    setCurrentSite={this.setCurrentSite}
                    currentSite={currentSite}
                    addAnotherSite={this.props.addAnotherSite}
                    isDemoMode={isDemoMode}
                  />
                </Provider>
              </S.DialogContainer>
            </Dialog>
          ) // Dont forget to bind this
        }
      </ReactReduxContext.Consumer>
    );
  }
}

const mapStateToProps = ({ newSites }) => ({
  isLoading: newSites.isLoading,
});

export default connect(mapStateToProps, null)(NewSite);
