import styled from 'styled-components';
import { ButtonFlat } from 'components/Layout/Buttons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const Info = styled.div`
  border-bottom: 1px solid grey;
  width: 100%;
  text-align: center;
  padding: 16px 0;
`;

export const Exports = styled.div`
  padding: 10px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid grey;
`;

export const Actions = styled.div``;

export const Details = styled.div`
  margin-right: 28px;
`;

export const NoExports = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  font-style: italic;
  margin-top: 26px;
`;

export const NewExport = styled.div`
  width: 100%;
  border-top: 1px solid grey;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
`;

export const SubmitButton = styled(ButtonFlat)`
  span {
    color: ${({ theme }) => theme.greenButton}!important;
  }
`;

export const Loader = styled.div`
  margin-top: 10px;
`;
