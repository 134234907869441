import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import TaskDetails from './pages/TaskDetails';
import TaskList from './pages/TasksList';
import UsersList from './pages/UsersList';

const Task = () => (
  <Switch>
    <Route exact path="/tasks" component={UsersList} />
    <Route exact path="/tasks/:id/:date" component={TaskList} />
    <Route exact path="/tasks/:id/task/:taskId" component={TaskDetails} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Task;
