import styled from 'styled-components';

export const Container = styled.div`
  & > div:last-child {
    padding: 20px 0px !important;

    & > button:first-child {
      margin-right: 10px !important;
    }

    & > button:last-child {
      margin-left: 10px !important;
    }
  }
`;

export const Block = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const ItemsCircle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  background-color: ${({ theme }) => theme.mainDarkBlue};
  font-size: 35px;
  margin-bottom: 15px;
`;

export const MainTitle = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0 auto;
`;

export const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 300;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
`;

export const SecondaryTitle = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
  max-width: 450px;
  margin: 0 auto;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 5px 0;
`;

export const RedText = styled(Text)`
  color: ${({ theme }) => theme.mainRed};
  margin-top: 20px;
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const ConfirmationWarning = styled.div`
  font-size: 18px;
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;
