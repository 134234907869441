import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { withRouter } from 'react-router-dom';

import { UnitItemType } from 'configs/propTypes';
import { ChevronRightIcon, CopyIcon, MoreVerticalIcon } from 'components/Layout/Icons';

import * as S from './styled';

class Item extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    item: UnitItemType.isRequired,
    onDuplicateItemClick: PropTypes.func.isRequired,
    openSensorsDialog: PropTypes.func.isRequired,
    userType: PropTypes.number.isRequired,
    onClickItem: PropTypes.func.isRequired,
  };

  duplicateItem = () => {
    this.props.onDuplicateItemClick(this.props.item);
  };

  navigateToItem = () => {
    const { item, location, history } = this.props;
    history.push(`${location.pathname}/details/${item.Id}`);
  };

  itemActions = () => {
    const { item, openSensorsDialog, userType } = this.props;

    return (
      <div>
        <S.ActionsContainer onClick={e => e.stopPropagation()}>
          {userType !== 32 && (
            <S.IconButton onClick={() => openSensorsDialog(item)} tooltip="Sensors">
              <S.IconAntenna isAssigned={item.HasAssignedSensors} />
            </S.IconButton>
          )}
          <S.IconButton onClick={this.duplicateItem} tooltip="Duplicate">
            <CopyIcon />
          </S.IconButton>
          <S.IconButton onClick={this.navigateToItem} tooltip="Item Details">
            <ChevronRightIcon />
          </S.IconButton>
        </S.ActionsContainer>
        <S.MenuActions
          iconButtonElement={
            <S.IconButton>
              <MoreVerticalIcon />
            </S.IconButton>
          }
        >
          <MenuItem onClick={this.duplicateItem} primaryText="Duplicate" />
          {userType !== 32 && <MenuItem onClick={() => openSensorsDialog(item)} primaryText="Sensors" />}
        </S.MenuActions>
      </div>
    );
  };

  render() {
    const { item, onClickItem } = this.props;

    return (
      <S.Container onClick={() => onClickItem(item)}>
        <S.Header
          showExpandableButton={false}
          subtitle={this.itemActions()}
          title={`(${item.Sequence}) ${item.Name}`}
        />
      </S.Container>
    );
  }
}

export default withRouter(Item);
