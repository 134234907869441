import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ScheduleDto, ScheduleInfo } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as scheduleActions } from 'redux/schedule';

import { actions as unitActions } from 'redux/unit';
import ScheduleData from './ScheduleData';
import EditCheqListModal from './EditCheqListModal';
import AddMissingTasksModal from './AddMissingTasksModal';

import { Container, IconAdd, Info, RedInfo } from './styled';
import theme from 'theme';

class AssignToolbar extends Component {
  static propTypes = {
    accessWarning: PropTypes.string.isRequired,
    checkedTasks: PropTypes.arrayOf(PropTypes.string).isRequired,
    match: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    requestAssignEmployee: PropTypes.func.isRequired,
    requestChangeSchedule: PropTypes.func.isRequired,
    requestTaskAssignableUsers: PropTypes.func.isRequired,
    requestMissingTasks: PropTypes.func.isRequired,
    requestUnitItemsTasks: PropTypes.func.isRequired,
    scheduleDto: ScheduleDto.isRequired,
    scheduleInfo: ScheduleInfo.isRequired,
    tasksIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    missingTasks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  state = {
    isEditCheqListVisible: false,
    isAddTaskVisible: false,
    isAddTasksOpened: false,
  };

  componentDidMount() {
    const { match } = this.props;
    this.props.requestMissingTasks({ checklistId: match.params.scheduleId, unitId: match.params.unitId });
    this.props.requestUnitItemsTasks({ Id: match.params.unitId });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.scheduleId !== match.params.scheduleId) {
      this.props.requestMissingTasks({ checklistId: match.params.scheduleId, unitId: match.params.unitId });
      this.props.requestUnitItemsTasks({ Id: match.params.unitId });
    }
  }

  openAddTasks = () => {
    this.setState({
      isAddTasksOpened: true,
    });
  };

  closeAddTasks = () => {
    this.setState({
      isAddTasksOpened: false,
    });
  };

  onOpenAssignableUsers = () => {
    this.props.requestTaskAssignableUsers();
  };

  closeModal = () => {
    this.setState({
      isEditCheqListVisible: false,
      isAddTaskVisible: false,
    });
  };

  openEditCheqListModal = () => {
    if (this.props.scheduleInfo.ActiveStatus === 'Active') {
      this.props.openErrorDialog(this.props.accessWarning, 'Warning');
    } else {
      this.setState({ isEditCheqListVisible: true });
    }
  };

  assignEmployee = (ids, userId, IsOpenStatus) => {
    const { scheduleId } = this.props.match.params;

    this.props.requestAssignEmployee({
      scheduleId,
      userId,
      ids,
      IsOpenStatus,
    });
  };

  assignTasks = (userId, userName) => {
    const { checkedTasks, tasksIds } = this.props;
    const taskIdsToAssignTo = checkedTasks.length ? checkedTasks : tasksIds;
    const IsOpenStatus = userName === 'Open Assignment';
    const text = `${IsOpenStatus ? 'Mark' : 'Assign the selected user to'} ${taskIdsToAssignTo.length} tasks${
      IsOpenStatus ? ' as open' : ''
    }?`;

    this.props.openConfirmationDialog(
      text,
      () => this.assignEmployee(taskIdsToAssignTo, IsOpenStatus ? null : userId, IsOpenStatus),
      IsOpenStatus ? 'Make tasks open?' : 'Assign user?',
    );
  };

  render() {
    const { scheduleDto, match, missingTasks, scheduleInfo } = this.props;
    let initialValues = {};
    const defaultDate = new Date();

    initialValues = {
      ScheduleType: scheduleDto.ScheduleType,
      ScheduleData: {
        ScheduledDate: defaultDate,
        DailyStartDate: defaultDate,
        DailyRepeatUntil: defaultDate,
        DailyRepeatInterval: 1,
        WeeklyStartDate: defaultDate,
        WeeklyRepeatUntil: defaultDate,
        WeeklyRepeatInterval: 1,
        MonthlyStartDate: defaultDate,
        MonthlyRepeatUntil: defaultDate,
        MonthlyRepeatInterval: 1,
        YearlyStartDate: defaultDate,
        YearlyRepeatUntil: defaultDate,
        YearlyRepeatInterval: 1,
      },
    };

    if (scheduleDto.ScheduleType === 'OneTime') {
      initialValues.ScheduleData.ScheduledDate = new Date(scheduleDto.ScheduleData.ScheduledDate);
    } else if (scheduleDto.ScheduleType === 'RecurringDaily') {
      initialValues.ScheduleData.DailyStartDate = new Date(scheduleDto.ScheduleData.DailyStartDate);
      initialValues.ScheduleData.DailyRepeatUntil = new Date(scheduleDto.ScheduleData.DailyRepeatUntil);
      initialValues.ScheduleData.DailyRepeatInterval = scheduleDto.ScheduleData.DailyRepeatInterval;
    } else if (scheduleDto.ScheduleType === 'RecurringWeekly') {
      initialValues.ScheduleData.WeeklyStartDate = new Date(scheduleDto.ScheduleData.WeeklyStartDate);
      initialValues.ScheduleData.WeeklyRepeatUntil = new Date(scheduleDto.ScheduleData.WeeklyRepeatUntil);
      initialValues.ScheduleData.WeeklyRepeatInterval = scheduleDto.ScheduleData.WeeklyRepeatInterval;
    } else if (scheduleDto.ScheduleType === 'RecurringMonthly') {
      initialValues.ScheduleData.MonthlyStartDate = new Date(scheduleDto.ScheduleData.MonthlyStartDate);
      initialValues.ScheduleData.MonthlyRepeatUntil = new Date(scheduleDto.ScheduleData.MonthlyRepeatUntil);
      initialValues.ScheduleData.MonthlyRepeatInterval = scheduleDto.ScheduleData.MonthlyRepeatInterval;
    } else if (scheduleDto.ScheduleType === 'RecurringYearly') {
      initialValues.ScheduleData.YearlyStartDate = new Date(scheduleDto.ScheduleData.YearlyStartDate);
      initialValues.ScheduleData.YearlyRepeatUntil = new Date(scheduleDto.ScheduleData.YearlyRepeatUntil);
      initialValues.ScheduleData.YearlyRepeatInterval = scheduleDto.ScheduleData.YearlyRepeatInterval;
    }

    return [
      <Container
        key="AssignToolbar"
        title={<ScheduleData scheduleDto={scheduleDto} />}
        zIndex={3}
        rightButtons={[
          {
            component: (
              <>
                <Info>Excluded Tasks:</Info>
                <RedInfo>&nbsp;{missingTasks.length}</RedInfo>
              </>
            ),
            isComponent: true,
          },
          scheduleInfo.ActiveStatus === 'Inactive' || missingTasks.length === 0
            ? {
                icon: <IconAdd color={theme.primaryGrey} />,
                tooltipStyles: { marginTop: -10 },
                hint: 'No possibility to add tasks',
              }
            : {
                icon: <IconAdd />,
                handler: this.openAddTasks,
                tooltipStyles: { marginTop: -10 },
                hint: 'Add',
              },
        ]}
      />,
      <EditCheqListModal
        key="EditCheqListModal"
        open={this.state.isEditCheqListVisible}
        handleClose={this.closeModal}
        unitId={match.params.unitId}
        scheduleId={match.params.scheduleId}
        scheduleDto={initialValues}
        changeSchedule={this.props.requestChangeSchedule}
      />,
      <AddMissingTasksModal
        open={this.state.isAddTasksOpened}
        handleClose={this.closeAddTasks}
        unitId={match.params.unitId}
        scheduleId={match.params.scheduleId}
        people={this.props.people}
        scheduleInfo={scheduleInfo}
      />,
    ];
  }
}

const mapStateToProps = ({ schedule }) => ({
  assignableUsers: schedule.taskAssignableUsers,
  tasksIds: schedule.taskTemplatesAmount,
  missingTasks: schedule.missingTasks,
});

const mapDispatchToProps = {
  requestTaskAssignableUsers: scheduleActions.fetchTaskAssignableUsersRequest,
  openConfirmationDialog,
  openErrorDialog,
  requestAssignEmployee: scheduleActions.assignEmployeeRequest,
  requestChangeSchedule: scheduleActions.changeScheduleRequest,
  requestMissingTasks: scheduleActions.missingTasksListRequest,
  requestUnitItemsTasks: unitActions.getUnitItemsTasksRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignToolbar));
