import React from 'react';
import PropTypes from 'prop-types';

import { ResourceGroupType } from 'configs/propTypes';

import * as S from 'modules/globalsettings/pages/styled';

const Item = ({
  item: {
    tenant: { name },
    resourceGroup: { name: resourceGroupName },
    gatewaysNumber,
    hubsNumber,
  },
}) => (
  <S.Group>
    <S.Item field="name">{name}</S.Item>
    <S.Item>{resourceGroupName}</S.Item>
    <S.Count>{`Hubs: ${hubsNumber}`}</S.Count>
    <S.Count>{`Gateways: ${gatewaysNumber}`}</S.Count>
  </S.Group>
);

Item.propTypes = {
  item: PropTypes.shape(ResourceGroupType).isRequired,
};

export default Item;
