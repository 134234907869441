import { call, put, takeEvery } from 'redux-saga/effects';

import { shortcutType } from 'configs/types';
import { formatServerError, EMPTY_GUID } from 'helpers';
import { ERROR_DIALOG_OPENED } from '../errorHandler';
import { parseServerError } from '../../helpers';

import { actions } from './index';

import {
  editSwitchAutorun,
  editRunNextPeriod,
  getShortcut,
  getShortcutList,
  getShortcutPreview,
  saveShortcutSchedule,
  getShortcutSchedule,
  editShortcutSchedule,
  createShortcutChecklist,
  editShortcutChecklist,
  createShortcut,
  getScheduleList,
  putShortcutData,
  getPersonPreview,
  getManagerPreview,
  getTeamPreview,
  getSitePreview,
  getUnitPreview,
  getItemPreview,
  getTeamListStatuses,
  removeShortcut,
} from 'http/shortcuts';

const EMPTY_RESULTS_ERROR = 'There are no positive results - please check entries and try again';

function* getSingleShortcut({ payload }) {
  try {
    const { data } = yield call(getShortcut, payload);
    yield put(actions.getSingleShortcutSuccess(data.entity));
    if (payload.onNext) {
      payload.onNext();
    }
  } catch (error) {
    yield put(actions.getSingleShortcutFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getShortcuts({ payload }) {
  try {
    const { data } = yield call(getShortcutList, payload);
    yield put(actions.getShortcutsSuccess(data.root));
  } catch (error) {
    yield put(actions.getShortcutsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getShortcutSites({ payload }) {
  try {
    const dto = payload.shortcut;
    const { data } = yield call(getShortcutPreview, dto);
    yield put(actions.getShortcutSitesSuccess(data.root));
  } catch (error) {
    yield put(actions.getShortcutSitesFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getSwitchAutorun({ payload }) {
  try {
    const { data } = yield call(editSwitchAutorun, payload);
    yield put(actions.getSwitchAutorunSuccess(data.entity));
  } catch (error) {
    yield put(actions.getSwitchAutorunFailure(error.response));
  }
}

function* getRunNextPeriod({ payload }) {
  try {
    const { data } = yield call(editRunNextPeriod, payload);
    yield put(actions.getRunNextPeriodSuccess(data.entity));
  } catch (error) {
    yield put(actions.getRunNextPeriodFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* sendSiteSchedule({ payload }) {
  try {
    const { data } = yield call(saveShortcutSchedule, payload);
    yield put(actions.sendSiteScheduleSuccess(data.entity));
  } catch (error) {
    yield put(actions.sendSiteScheduleFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getSiteSchedule({ payload }) {
  try {
    const { data } = yield call(getShortcutSchedule, payload);
    yield put(actions.getSiteScheduleSuccess(data.entity));
    payload.onNext();
  } catch (error) {
    yield put(actions.getSiteScheduleFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* editSiteSchedule({ payload }) {
  try {
    const { data } = yield call(editShortcutSchedule, payload);
    yield put(actions.editSiteScheduleSuccess(data.entity));
    payload.onNext();
  } catch (error) {
    yield put(actions.editSiteScheduleFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* createChecklists({ payload }) {
  try {
    const { data } = yield call(createShortcutChecklist, payload);
    yield put(
      actions.createChecklistsSuccess({ siteId: payload.siteId, isFinal: payload.isFinal, checklists: data.root }),
    );
    payload.onNext();
  } catch (error) {
    yield put(
      actions.createChecklistsFailure(formatServerError(error, 'An error occurred while creating checklists.')),
    );
  }
}

function* editChecklist({ payload }) {
  try {
    yield call(editShortcutChecklist, payload);
    yield put(actions.editChecklistSuccess());
    payload.onNext();
  } catch (error) {
    yield put(actions.editChecklistFailure(formatServerError(error, 'An error occurred while creating checklists.')));
  }
}

function* createShortcutFunction({ payload }) {
  try {
    const { data } = yield call(createShortcut, payload);
    yield put(actions.createShortcutSuccess(data.entity));
    if (payload.ItemRules) {
      yield put(actions.getShortcutsRequest({ filter: { type: shortcutType.all } }));
    }
    payload.onNext();
  } catch (error) {
    yield put(actions.createShortcutFailure(formatServerError(error, 'An error occurred while creating Shortcut.')));
  }
}

function* getListSchedule({ payload }) {
  try {
    const { data } = yield call(getScheduleList, payload);
    yield put(actions.getListScheduleSuccess(data.root));
  } catch (error) {
    yield put(actions.getListScheduleFailure(formatServerError(error, 'An error occurred while creating Shortcut.')));
  }
}

function* editShortcut({ payload }) {
  try {
    const { data } = yield call(putShortcutData, payload);
    yield put(actions.editShortcutSuccess(data.entity));
    yield put(actions.getShortcutsRequest({ filter: { type: shortcutType.all } }));
    payload.onNext();
  } catch (error) {
    yield put(actions.editShortcutFailure(formatServerError(error, 'An error occurred while creating Shortcut.')));
  }
}

function* saveShortcutOptions({ payload }) {
  try {
    const { data } = yield call(putShortcutData, payload);
    yield put(actions.saveShortcutOptionsSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.saveShortcutOptionsFailure(formatServerError(error, 'An error occurred while saving shortcut options.')),
    );
  }
}

function* sendManagementOptions({ payload }) {
  try {
    const { data } = yield call(getManagerPreview, payload);
    if (data.entity.Id === EMPTY_GUID) {
      yield put(actions.sendManagementOptionsFailure(EMPTY_RESULTS_ERROR));
    } else {
      if (payload.onNext) {
        payload.onNext();
      }
      yield put(actions.sendManagementOptionsSuccess(data.entity));
    }
  } catch (error) {
    yield put(actions.sendManagementOptionsFailure(formatServerError(error, 'An error occurred')));
  }
}

function* sendPersonOptions({ payload }) {
  try {
    const { data } = yield call(getPersonPreview, payload);
    if (data.entity.Id === EMPTY_GUID) {
      yield put(actions.sendPersonOptionsFailure(EMPTY_RESULTS_ERROR));
    } else {
      if (payload.onNext) {
        payload.onNext();
      }
      yield put(actions.sendPersonOptionsSuccess(data.entity));
    }
  } catch (error) {
    yield put(actions.sendPersonOptionsFailure(formatServerError(error, 'An error occurred')));
  }
}

function* sendTeamOptions({ payload }) {
  try {
    const { data } = yield call(getTeamPreview, payload);
    if (data.entity.Id === EMPTY_GUID) {
      yield put(actions.sendTeamOptionsFailure(EMPTY_RESULTS_ERROR));
    } else {
      if (payload.onNext) {
        payload.onNext();
      }
      yield put(actions.sendTeamOptionsSuccess(data.entity));
    }
  } catch (error) {
    yield put(actions.sendTeamOptionsFailure(EMPTY_RESULTS_ERROR));
  }
}

function* sendLocationOptions({ payload }) {
  try {
    const { data } = yield call(getSitePreview, payload);
    yield put(actions.sendLocationOptionsSuccess(data.root));
    if (data.root.length === 0) {
      yield put(actions.sendLocationOptionsFailure(EMPTY_RESULTS_ERROR));
    } else if (payload.onNext) {
      payload.onNext();
    }
  } catch (error) {
    yield put(actions.sendLocationOptionsFailure(formatServerError(error, 'An error occurred')));
  }
}

function* sendUnitOptions({ payload }) {
  try {
    const { data } = yield call(getUnitPreview, payload);
    yield put(actions.sendUnitOptionsSuccess(data.root));
    if (data.root.length === 0) {
      yield put(actions.sendUnitOptionsFailure(EMPTY_RESULTS_ERROR));
    } else if (payload.onNext) {
      payload.onNext();
    }
  } catch (error) {
    yield put(actions.sendUnitOptionsFailure(formatServerError(error, 'An error occurred')));
  }
}

function* sendItemOptions({ payload }) {
  try {
    const { data } = yield call(getItemPreview, payload);
    yield put(actions.sendItemOptionsSuccess(data.root));
    if (data.root.length === 0) {
      yield put(actions.sendItemOptionsFailure(EMPTY_RESULTS_ERROR));
    } else if (payload.onNext) {
      payload.onNext();
    }
  } catch (error) {
    yield put(actions.sendItemOptionsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getListStatus({ payload }) {
  try {
    const { data } = yield call(getTeamListStatuses, payload);
    yield put(actions.listStatusSuccess(data.root));
  } catch (error) {
    yield put(actions.listStatusFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* deleteShortcut({ payload }) {
  try {
    yield call(removeShortcut, payload);
    yield put(actions.deleteShortcutSuccess());
    yield put(actions.getShortcutsRequest({ filter: { type: shortcutType.all } }));
    if (payload.onNext) {
      payload.onNext();
    }
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    yield put(
      actions.deleteShortcutFailure(formatServerError(error, 'An error occurred while deleting the shortcut.')),
    );
  }
}

const shortcutSagas = [
  takeEvery(actions.editSiteScheduleRequest, editSiteSchedule),
  takeEvery(actions.getSiteScheduleRequest, getSiteSchedule),
  takeEvery(actions.sendSiteScheduleRequest, sendSiteSchedule),
  takeEvery(actions.getShortcutsRequest, getShortcuts),
  takeEvery(actions.getSingleShortcutRequest, getSingleShortcut),
  takeEvery(actions.getShortcutSitesRequest, getShortcutSites),
  takeEvery(actions.getSwitchAutorunRequest, getSwitchAutorun),
  takeEvery(actions.getRunNextPeriodRequest, getRunNextPeriod),
  takeEvery(actions.createShortcutRequest, createShortcutFunction),
  takeEvery(actions.editShortcutRequest, editShortcut),
  takeEvery(actions.getListScheduleRequest, getListSchedule),
  takeEvery(actions.createChecklistsRequest, createChecklists),
  takeEvery(actions.editChecklistRequest, editChecklist),
  takeEvery(actions.saveShortcutOptionsRequest, saveShortcutOptions),
  takeEvery(actions.sendManagementOptionsRequest, sendManagementOptions),
  takeEvery(actions.sendLocationOptionsRequest, sendLocationOptions),
  takeEvery(actions.sendUnitOptionsRequest, sendUnitOptions),
  takeEvery(actions.sendPersonOptionsRequest, sendPersonOptions),
  takeEvery(actions.sendTeamOptionsRequest, sendTeamOptions),
  takeEvery(actions.sendItemOptionsRequest, sendItemOptions),
  takeEvery(actions.listStatusRequest, getListStatus),
  takeEvery(actions.deleteShortcutRequest, deleteShortcut),
];

export default shortcutSagas;
