import http from '../index';

export const getLandingPageList = () => http.post('LandingPage/List');

export const putLandingPage = dto => http.post('LandingPage/Put', { dto });

export const updateLandingPageSequence = dtos => http.post('LandingPage/UpdateSequence', { dtos });

export const getLandingPageSectionList = dto => http.post('LandingPageSection/ListByPage', dto);

export const createLandingPageSection = dto => http.post('LandingPageSection/Post', { dto });

export const getLandingPageSection = id => http.post('LandingPageSection/Get', { id });

export const updateLandingPageSection = dto => http.post('LandingPageSection/Put', { dto });

export const deleteLandingPageSection = id => http.post('LandingPageSection/Delete', { id });

export const updateLandingPageSectionSequence = dtos => http.post('LandingPageSection/UpdateSequence', { dtos });

export const getVideosBySection = pageName => http.post('Vimeo/GetVideosBySection', { pageName });

export const getVideosCount = pageName => http.post('Vimeo/GetVideosCount', { pageName });

export const refreshVimeoCache = () => http.post('Vimeo/RefreshCache', {});
