import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as CS from 'components/Dialogs/styled';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

import theme from 'theme';

const BASE_INDEX = 1000;

class CheckboxList extends Component {
  static propTypes = {
    checkedMembers: PropTypes.arrayOf(PropTypes.string),
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fillCheckedMembers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checkedMembers: [],
  };

  onItemCheck = (Id, checked) => {
    const { checkedMembers, fillCheckedMembers } = this.props;

    if (checked) {
      fillCheckedMembers([...checkedMembers, Id]);
    } else {
      const updatedSites = checkedMembers.filter(memberId => memberId !== Id);
      fillCheckedMembers(updatedSites);
    }
  };

  onCheckboxClick = e => {
    e.stopPropagation();
  };

  render() {
    const { checkedMembers, list } = this.props;

    return (
      <S.Container>
        <S.SelectCheckobesContainer>
          <S.ItemValue>Name</S.ItemValue>
          <S.ItemValue>Job Function</S.ItemValue>
          <S.ItemValue>Multi Team</S.ItemValue>
        </S.SelectCheckobesContainer>
        <CS.DividerLine />
        <S.ListContainer>
          {list.length > 0 &&
            list.map(({ Id, Name, JobFunctionName, IsMultiGroupUser }, index) => (
              <S.ListItemContainer index={BASE_INDEX - index} key={Id}>
                <S.ListItem>
                  <S.ItemNameAndCheckbox>
                    <CS.ItemCheckbox
                      checked={checkedMembers.includes(Id)}
                      onCheck={(e, checked) => this.onItemCheck(Id, checked)}
                      onClick={this.onCheckboxClick}
                      iconStyle={{ fill: checkedMembers.find(item => item === Id) && theme.primaryRed }}
                    />
                    <CS.ItemName>{Name}</CS.ItemName>
                  </S.ItemNameAndCheckbox>
                  <S.ItemValue>{JobFunctionName}</S.ItemValue>
                  <S.ItemValue>
                    {IsMultiGroupUser && (
                      <ButtonIcon tooltip={'Already in another team'}>
                        <S.IconWarning />
                      </ButtonIcon>
                    )}
                  </S.ItemValue>
                </S.ListItem>
                <CS.DividerLine />
              </S.ListItemContainer>
            ))}
        </S.ListContainer>
      </S.Container>
    );
  }
}

export default CheckboxList;
