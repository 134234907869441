import React from 'react';

import { GatewayType } from 'configs/propTypes';

import * as S from './styled';

const Item = ({ item: { DeviceId, Notes, SensorsCount, SerialNumber, siteName } }) => (
  <S.Gateway>
    <S.SiteName>{`${siteName ? `Site: ${siteName}` : ''}`}</S.SiteName>
    <S.Info>
      <S.SerialNumber>
        <S.Text>
          Name: <b>{DeviceId}</b>
        </S.Text>
        <S.Text>
          Serial Number: <b>{SerialNumber}</b>
        </S.Text>
        {Notes && <S.Text>Notes: {Notes}</S.Text>}
      </S.SerialNumber>
      <S.Sensors>
        <S.SensorsTitle>Sensors:</S.SensorsTitle>
        <S.SensorsCount>{SensorsCount}</S.SensorsCount>
      </S.Sensors>
    </S.Info>
  </S.Gateway>
);

Item.propTypes = {
  item: GatewayType.isRequired,
};

export default Item;
