import styled from 'styled-components';
import { LocationIcon } from 'components/Layout/Icons';

export const ModuleLabel = styled.div`
  color: ${({ theme }) => theme.textGray};
  font-size: 30px;
  font-weight: 300;
  align-self: center;
  margin-left: 16px;
`;

export const SelectorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  border-radius: 8px;
  background: ${({ theme }) => theme.primaryHealth};
  font-weight: bold;
  min-height: 70px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 5px 2px -2px;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LocationAction = styled(LocationIcon).attrs({
  style: {
    width: '80px',
    height: '50px',
  },
})``;
