import styled from 'styled-components';
import { ShowPasswordButton } from '../../LoginPage/LoginForm/styled';

export const SignUpContainer = styled.div`
  width: fill-available;
  display: flex;
  flex-direction: column;
  height: ${({ name, password, authMode }) => ((name && password) || authMode === 'info' ? '480px' : 'none')};
  align-items: center;
  padding: 0px 30px 50px;
  background-color: ${({ theme }) => theme.mainGreen};
  z-index: ${({ authMode }) => (authMode === 'signup' ? 1 : 0)};
  border-radius: 30px;
`;

export const AccountsInfoContainer = styled.div`
  padding: 10px 40px;
  margin-bottom: 4px;
`;

export const AccountText = styled.p`
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 100;
`;

export const AccountTitle = styled.p`
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  font-size: 20px;
  margin: 5px;
  font-weight: 100;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: fill-available;
`;

export const FieldContainer = styled.div`
  position: relative;
  width: fill-available;
`;

export const Label = styled.label`
  display: flex;
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.primaryWhite};
  padding-left: 10px;
  margin: 10px 0px 5px;
`;

export const RequiredIcon = styled.span`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 6px;
  margin-left: 2px;
  padding-top: 14px;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: left;
`;

export const SubmitButton = styled.button`
  width: 190px;
  height: 50px;
  background-color: ${({ theme }) => theme.signUpColor};
  border: ${({ theme }) => `5px double ${theme.primaryWhite}`};
  color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  right: 30px;
  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (max-width: 600px) {
    width: 130px;
  }

  @media screen and (max-width: 385px) {
    width: 110px;
  }
`;
export const PasswordShowButton = styled(ShowPasswordButton)`
  display: ${({ isPassword }) => (isPassword ? 'block' : 'none')};
`;
