const isOnline = window.navigator && window.navigator.onLine;

const initialState = {
  online: isOnline || true,
  shouldRedirectToLogin: false,
  currentModule: '',
  globalSettingsModule: '',
  version: {},
  isVersionLoading: false,
};

export const offline = state => ({
  ...state,
  online: false,
});
export const online = state => ({
  ...state,
  online: true,
});

export const shouldRedirectToLoginSet = state => ({
  ...state,
  shouldRedirectToLogin: true,
});
export const shouldRedirectToLoginUnset = state => ({
  ...state,
  shouldRedirectToLogin: false,
});

export const updateCurrentModule = (state, { payload }) => ({
  ...state,
  currentModule: payload,
});
export const updateCurrentGlobalSettingsModule = (state, { payload }) => ({
  ...state,
  globalSettingsModule: payload || '',
});

export const fetchCurrentVersionRequest = state => ({
  ...state,
  error: '',
  isVersionLoading: false,
});
export const fetchCurrentVersionSuccess = (state, { payload }) => ({
  ...state,
  version: payload,
  isVersionLoading: false,
});
export const fetchCurrentVersionFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isVersionLoading: false,
});

export default initialState;
