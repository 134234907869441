import styled from 'styled-components';
import { BorderRadiusContainer, ScrollableContentContainer } from 'components/Layout/Containers';

import Dialog from 'components/Dialogs/Base';

export const ImageArea = styled(BorderRadiusContainer)``;

export const Block = styled.div``;

export const FullScreenDialog = styled(Dialog)``;

export const PhotoContent = styled(ScrollableContentContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
    height: calc(80vh - 130px);
  }
`;

export const TextValue = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;
