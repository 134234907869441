const validator = values => {
  const errors = {};

  if (!values.SensorID) {
    errors.SensorID = 'Required';
  }

  if (!values.SensorTypeID) {
    errors.SensorTypeID = 'Required';
  }

  return errors;
};

export default validator;
