import styled from 'styled-components';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { ProgressCircular } from 'components/Layout/Progresses';

import theme from 'theme';

export const radioButtonStyles = {
  labelStyle: {
    textAlign: 'left',
  },
  radioButton: {
    marginRight: '60px',
    width: 'unset',
  },
  style: {
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    width: 'unset',
    borderBottom: `1px solid ${theme.primaryGrey}`,
  },
};

export const FieldRadio = styled(RadioButton)``;

export const FieldRadioGroup = styled(RadioButtonGroup)`
  margin: 0;
  padding: 0;
  overflow-y: auto;
  height: 315px;
`;

export const ContentHeaderContainer = styled.div``;

export const ItemLabelContainer = styled.div`
  padding: 10px 20px 10px 80px;
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${() => `1px solid ${theme.primaryGrey}`};
`;

export const ItemLabel = styled.label`
  color: ${() => theme.primaryBlack};
  font-weight: bold;
`;

export const Loader = styled(ProgressCircular).attrs({
  size: 30,
})``;

export const EmptyListContainer = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;
