import styled from 'styled-components';

import { FieldText, FieldToggle, FieldRadio } from 'components/Layout/Inputs';

import {
  BorderRadiusContainer,
  Column,
  ContentContainer,
  ScrollableContentContainer,
  RadioGroupContainer,
} from 'components/Layout/Containers';

export const BlockTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 3px 0;
`;

export const GrayTitle = styled(BlockTitle)`
  background: ${({ theme }) => theme.lightGrey};
  font-size: 11px;
  font-weight: 500;
  padding: 10px;
  text-transform: uppercase;
`;

export const ScanCodeVerified = styled(BlockTitle)`
  background: ${({ theme }) => theme.lightGrey};
  font-size: 13px;
  font-weight: unset;
  padding: 8px 10px;
`;

export const AttentionToggle = styled(FieldToggle)`
  box-sizing: border-box;
  padding: 10px 5px;
`;

export const Breadcrumbs = styled.div`
  color: ${({ theme }) => theme.textGray};
  display: flex;
  font-size: 14px;
  padding-left: 10px;
`;

export const BorderRadiusBlock = styled(BorderRadiusContainer)`
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.2;
`;

export const NumericValue = styled.span`
  font-size: 16px;
  padding: 5px 10px;
`;

export const Container = styled(ContentContainer)``;

export const Content = styled(ScrollableContentContainer)``;

export const ColoredTitle = styled.div`
  background: ${({ theme }) => theme.primaryTasks};
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  padding: 10px;
  text-transform: uppercase;
`;

export const ColoredTitleNoCase = styled.div`
  background: ${({ theme }) => theme.primaryTasks};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
`;

export const TaskDescription = styled(Column)`
  background: ${({ theme }) => theme.primaryWhite};
  padding: 20px;
  word-break: break-all;
`;

export const TaskNotes = styled(TaskDescription)`
  background: ${({ theme }) => theme.lightGrey};
  word-break: break-all;
`;

export const InputContainer = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
`;

export const TextField = styled(FieldText).attrs({
  rowsMax: 5,
})``;

export const BrowseFileButton = styled.div`
  padding: 10px;
`;

export const RadioGroup = styled(RadioGroupContainer)``;

export const RadioField = styled(FieldRadio)``;
