import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Container, MenuTitle, MenuContainer, Item, EmptyItem, Popover } from './styled';

class PopoverMenu extends Component {
  static defaultProps = {
    buttonColor: '',
    icon: null,
    label: '',
    labelPosition: 'Before',
    emptyText: 'No an assigned user',
    onOpen: () => {},
  };

  static propTypes = {
    buttonColor: PropTypes.string,
    data: PropTypes.array.isRequired,
    icon: PropTypes.element,
    label: PropTypes.string,
    emptyText: PropTypes.string,
    labelPosition: PropTypes.string,
    onItemClick: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    keyName: PropTypes.string.isRequired,
    menuTitle: PropTypes.string.isRequired,
  };

  state = {
    anchorEl: null,
    isExpanded: false,
  };

  shouldComponentUpdate(nextProps) {
    return this.state.isExpanded || this.props.label !== nextProps.label;
  }

  handleClose = () => {
    this.setState({
      isExpanded: false,
    });
  };

  handleOnItemClick = index => {
    this.props.onItemClick(index);
    this.handleClose();
  };

  handleOpen = event => {
    this.setState(
      {
        anchorEl: event.currentTarget,
        isExpanded: true,
      },
      this.props.onOpen,
    );
  };

  render() {
    const { buttonColor, data, emptyText, icon, keyName, label, labelPosition, menuTitle } = this.props;

    return (
      <Container>
        <Button icon={icon} label={label} labelPosition={labelPosition} onClick={this.handleOpen} color={buttonColor} />
        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onRequestClose={this.handleClose}
          open={this.state.isExpanded}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <MenuTitle key={menuTitle}>{menuTitle}</MenuTitle>
          {data.length ? (
            <MenuContainer>
              {data.map((item, index) => (
                <Item key={index} primaryText={item[keyName]} onClick={() => this.handleOnItemClick(item)} />
              ))}
            </MenuContainer>
          ) : (
            <EmptyItem>{emptyText}</EmptyItem>
          )}
        </Popover>
      </Container>
    );
  }
}

export default PopoverMenu;
