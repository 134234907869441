import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import Checkbox from 'material-ui/Checkbox';
import MenuItem from 'material-ui/MenuItem';

import { FieldSelect } from 'components/Layout/Selects';

export const Container = styled(Flex).attrs({
  column: true,
  px: 3,
  py: 2,
})`
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
`;

export const CompletedContainer = styled(Flex).attrs({
  px: 3,
  py: 2,
})`
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
`;

export const TextBlock = styled(Flex).attrs({
  column: true,
  width: 49 / 50,
})``;

export const ButtonBlock = styled(Flex).attrs({
  column: true,
  width: 1 / 50,
})``;

export const InfoItem = styled(Box)``;

export const CheckBoxButton = styled(Checkbox).attrs({
  labelStyle: {
    color: 'red',
  },
})`
  width: 200px !important;
  align-self: flex-end;
`;

export const Select = styled(FieldSelect).attrs({
  style: { width: 180 },
  underlineStyle: { display: 'none' },
  labelStyle: { left: '20px' },
})`
  align-self: flex-end;
`;

export const Option = styled(MenuItem)``;
