import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { VersionLegalType } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as versionLegalsActions } from 'redux/versionLegals';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { AddIcon, BackIcon } from 'components/Layout/Icons';
import AddEditVersionLegalDialog from './pages/AddEditVersionLegalDialog';
import Item from './pages/Item';

class VersionLegalsList extends Component {
  static propTypes = {
    addVersionLegal: PropTypes.func.isRequired,
    createdEditedVersionLegalId: PropTypes.string.isRequired,
    deleteVersionLegal: PropTypes.func.isRequired,
    editVersionLegal: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    fetchVersionLegals: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    versionLegalsList: PropTypes.arrayOf(VersionLegalType).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    addEditVersionLegalDialogOpened: false,
    searchData: '',
    versionLegalToEdit: null,
  };

  componentDidMount() {
    this.props.fetchVersionLegals();
  }

  onBackButtonClick = () => this.props.history.push('/globalsettings');

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }

    if (nextProps.createdEditedVersionLegalId) {
      this.closeAddEditVersionLegalDialog();
    }
  }

  onDeleteApprove = () => {
    this.closeAddEditVersionLegalDialog();
    this.props.deleteVersionLegal(this.state.versionLegalToEdit.Id);
  };

  onDeleteVersionLegal = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to delete this version? This cannot be undone!',
      this.onDeleteApprove,
      'Delete?',
    );
  };

  closeAddEditVersionLegalDialog = () => {
    this.setState({ addEditVersionLegalDialogOpened: false });
  };

  openAddEditVersionLegalDialog = versionLegal => {
    if (versionLegal.Id) {
      this.setState({
        addEditVersionLegalDialogOpened: true,
        versionLegalToEdit: {
          ...versionLegal,
        },
      });
    } else {
      this.setState({ addEditVersionLegalDialogOpened: true, versionLegalToEdit: null });
    }
  };

  filterVersionLegalsList = ({ VersionNumber, VersionNotes }) => {
    const query = this.state.searchData.toLowerCase().trim();
    return (
      VersionNumber.toString().toLowerCase().includes(query) ||
      VersionNotes.toLowerCase()
        .replace(/(\r\n|\n|\r)/gm, ' ')
        .includes(query)
    );
  };

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  render() {
    const { addEditVersionLegalDialogOpened, searchData, versionLegalToEdit } = this.state;
    const { addVersionLegal, editVersionLegal, versionLegalsList } = this.props;

    return [
      <Subheader
        key="Version Legal list subheader"
        rightButtons={[
          {
            icon: <AddIcon />,
            handler: this.openAddEditVersionLegalDialog,
            hint: 'Add',
          },
        ]}
        title="Technical"
        isSearch
        searchData={searchData}
        searchInList={this.searchInList}
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: this.onBackButtonClick,
            hint: 'Back',
          },
        ]}
      />,
      <SimpleList
        key="Version Legals"
        data={versionLegalsList.filter(this.filterVersionLegalsList)}
        emptyListMessage="There are no Version Legals avaliable"
        onItemClick={this.openAddEditVersionLegalDialog}
        renderItemContent={Item}
      />,
      <AddEditVersionLegalDialog
        key="Add edit verson legal dialog"
        handleClose={this.closeAddEditVersionLegalDialog}
        handleDelete={this.onDeleteVersionLegal}
        onSubmit={versionLegalToEdit ? editVersionLegal : addVersionLegal}
        open={addEditVersionLegalDialogOpened}
        versionLegal={versionLegalToEdit || undefined}
        title={versionLegalToEdit ? 'Edit Version Legal' : 'Add Version Legal'}
        withDelete={!!versionLegalToEdit}
      />,
    ];
  }
}

const mapStateToProps = ({ versionLegals }) => ({
  createdEditedVersionLegalId: versionLegals.createdEditedVersionLegalId,
  error: versionLegals.error,
  versionLegalsList: versionLegals.list,
});

const mapDispatchToProps = {
  addVersionLegal: versionLegalsActions.addVersionlegalRequest,
  deleteVersionLegal: versionLegalsActions.deleteVersionlegalRequest,
  editVersionLegal: versionLegalsActions.editVersionlegalRequest,
  fetchVersionLegals: versionLegalsActions.versionlegalsListRequest,
  openConfirmationDialog,
  openErrorDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionLegalsList);
