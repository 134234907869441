import { sortBy } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';

import { formatServerError, parseServerError } from 'helpers';
import { getTenantId, getSelectedSite } from 'helpers/storage';

import { fetchUnitsList } from 'redux/units/sagas.js';

import { actions } from './index';
import {
  canImportUnit,
  copyUnitsToTenantFromMaster,
  createItemListTemplate,
  fetchAllUnits,
  fetchItems,
  fetchLimits,
  getListMasterUnits,
  makeListTemplate,
  fetchUnitTaskStatus,
  getAssetCategoryList,
  getAssetCategory,
  createAssetCategory,
  updateAssetCategory,
  deleteAssetCategory,
  deleteAssetType,
  updateAssetType,
  createAssetType,
  getAssetType,
  getAssetTypeList,
} from 'http/listTemplates';
import { ERROR_DIALOG_OPENED } from '../errorHandler';

function* getLimits() {
  try {
    const tenantId = yield call(getTenantId);
    const { data } = yield call(fetchLimits, tenantId);
    yield put(actions.getLimitsSuccess(data.entity));
  } catch (error) {
    yield put(actions.getLimitsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getItems({ payload }) {
  try {
    const { data } = yield call(fetchItems, payload);
    yield put(actions.getItemsSuccess(data.root));
  } catch (error) {
    yield put(actions.getItemsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* copyUnits({ payload }) {
  try {
    yield call(copyUnitsToTenantFromMaster, payload);
    yield put(
      actions.copyUnitsSuccess({
        unit: { ...payload.masterUnit, SiteName: payload.siteName },
        items: {
          ...payload.itemDtos
            .map(item => ({ [item.Id]: item }))
            .reduce((accum, value) => {
              const [id] = Object.keys(value);
              accum[id] = value[id];
              return accum;
            }, {}),
        },
      }),
    );
    yield put(actions.getLimitsRequest());
    payload.onSuccess();
  } catch (error) {
    yield put(actions.copyUnitsFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* createListTemplate({ payload }) {
  try {
    if (!payload.isClear) {
      const { data } = yield call(makeListTemplate, payload);
      yield put(actions.createListTemplateSuccess(data.entity));
      yield* fetchUnitsList({ payload: { filter: { SiteID: getSelectedSite() } } });
      yield put(
        actions.createListTemplateItemRequest({
          siteId: payload.siteId,
          unitId: data.entity.Id,
          tasks: payload.tasks,
          itemName: payload.itemName,
          onSuccess: payload.onSuccess,
        }),
      );
    } else {
      yield put(actions.createListTemplateClear());
    }
  } catch (error) {
    yield put(
      actions.createListTemplateFailure(formatServerError(error, 'An error occurred while creating List Template.')),
    );
  }
}

function* createListTemplateItem({ payload }) {
  try {
    const { data } = yield call(createItemListTemplate, payload);
    yield put(actions.createListTemplateItemSuccess(data.entity));
    yield put(actions.getLimitsRequest());
    payload.onSuccess();
  } catch (error) {
    yield put(
      actions.createListTemplateItemFailure(
        formatServerError(error, 'An error occurred while creating List Template.'),
      ),
    );
  }
}

function* getAllUnits() {
  try {
    const { data } = yield call(fetchAllUnits);
    yield put(actions.getAllUnitsSuccess(data.root));
  } catch (error) {
    yield put(actions.getAllUnitsFailure(formatServerError(error, 'An error occurred while creating List Template.')));
  }
}

function* searchListTemplate({ payload }) {
  try {
    const { data } = yield call(getListMasterUnits, payload);
    yield put(actions.searchListTemplateSuccess(data.root));
  } catch (error) {
    yield put(
      actions.searchListTemplateFailure(formatServerError(error, 'An error occurred while creating List Template.')),
    );
  }
}

function* checkMatchImportList({ payload }) {
  try {
    const { data } = yield call(canImportUnit, payload);
    yield put(actions.checkMatchImportListSuccess(data.entity));
    payload.onNext();
    if (!data.entity.IsPresent) {
      payload.onNext();
    }
  } catch (error) {
    yield put(actions.checkMatchImportListFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getUnitTaskStatus({ payload }) {
  try {
    const { data } = yield call(fetchUnitTaskStatus, payload);
    yield put(actions.getUnitTaskStatusSuccess(data.entity));
  } catch (error) {
    yield put(actions.getUnitTaskStatusFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* fetchAssetCategoryList() {
  try {
    const { data } = yield call(getAssetCategoryList);
    yield put(actions.fetchAssetCategoryListSuccess(sortBy(data.root, ({ Name }) => Name.toLowerCase())));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* fetchAssetCategory({ payload }) {
  try {
    const { data } = yield call(getAssetCategory, payload);
    yield put(actions.fetchAssetCategorySuccess(data.entity.Dto));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* addAssetCategory({ payload }) {
  try {
    yield call(createAssetCategory, payload);
    yield* fetchAssetCategoryList();
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* editAssetCategory({ payload }) {
  try {
    yield call(updateAssetCategory, payload);
    yield* fetchAssetCategoryList();
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* removeAssetCategory({ payload }) {
  try {
    yield call(deleteAssetCategory, payload);
    yield* fetchAssetCategoryList();
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* fetchAssetTypeList({ payload }) {
  try {
    const { data } = yield call(getAssetTypeList, { categoryID: payload });
    yield put(actions.fetchAssetTypeListSuccess(sortBy(data.root, ({ Name }) => Name.toLowerCase())));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* fetchAssetType({ payload }) {
  try {
    const { data } = yield call(getAssetType, payload);
    yield put(actions.fetchAssetTypeSuccess(data.entity));
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* addAssetType({ payload }) {
  try {
    yield call(createAssetType, payload);
    yield* fetchAssetTypeList({ payload: payload.masterAssetCategoryID });
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* editAssetType({ payload }) {
  try {
    yield call(updateAssetType, payload);
    yield* fetchAssetTypeList({ payload: payload.masterAssetCategoryID });
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

function* removeAssetType({ payload }) {
  try {
    yield call(deleteAssetType, payload.id);
    yield* fetchAssetTypeList({ payload: payload.masterAssetCategoryID });
  } catch (error) {
    yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
  }
}

const listTemplatesSagas = [
  takeEvery(actions.copyUnitsRequest, copyUnits),
  takeEvery(actions.getLimitsRequest, getLimits),
  takeEvery(actions.getItemsRequest, getItems),
  takeEvery(actions.getAllUnitsRequest, getAllUnits),
  takeEvery(actions.createListTemplateRequest, createListTemplate),
  takeEvery(actions.createListTemplateItemRequest, createListTemplateItem),
  takeEvery(actions.searchListTemplateRequest, searchListTemplate),
  takeEvery(actions.checkMatchImportListRequest, checkMatchImportList),
  takeEvery(actions.getUnitTaskStatusRequest, getUnitTaskStatus),

  takeEvery(actions.fetchAssetCategoryListRequest, fetchAssetCategoryList),
  takeEvery(actions.fetchAssetCategoryRequest, fetchAssetCategory),
  takeEvery(actions.createAssetCategoryRequest, addAssetCategory),
  takeEvery(actions.updateAssetCategoryRequest, editAssetCategory),
  takeEvery(actions.deleteAssetCategoryRequest, removeAssetCategory),

  takeEvery(actions.fetchAssetTypeListRequest, fetchAssetTypeList),
  takeEvery(actions.fetchAssetTypeRequest, fetchAssetType),
  takeEvery(actions.createAssetTypeRequest, addAssetType),
  takeEvery(actions.updateAssetTypeRequest, editAssetType),
  takeEvery(actions.deleteAssetTypeRequest, removeAssetType),
];

export default listTemplatesSagas;
