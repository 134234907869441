import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';

import { UnitItemTaskTemplateType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import validate from './validator';
import * as S from './styled';

const RULE_DATA = {
  '': [],
  CheckFlowRate: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'number' },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'number' },
  ],
  CheckQuantity: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'number' },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'number' },
  ],
  CheckTemperature: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'number' },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'number' },
  ],
  Feedback: [{ floatingLabelText: 'Specification', name: 'RuleData.Specification', type: 'text' }],
  PhotoConfirmation: [
    { floatingLabelText: 'Min. Attachments', name: 'RuleData.MinAttachments', type: 'number' },
    { floatingLabelText: 'Max. Attachments', name: 'RuleData.MaxAttachments', type: 'number' },
  ],
  ScanCode: [],
  Signature: [{ floatingLabelText: 'Specification', name: 'RuleData.Specification', type: 'text' }],
  Verification: [{ floatingLabelText: 'Specification', name: 'RuleData.Specification', type: 'text' }],
};

const decorator = form => {
  let previousValues = {};

  const unsubscribe = form.subscribe(
    state => {
      const values = state.values;

      form.batch(() => {
        if (Object.keys(previousValues).length !== 0 && values.RuleType !== previousValues.RuleType) {
          form.change('RuleData.ExpectedLowerBound', null);
          form.change('RuleData.ExpectedScanCode', null);
          form.change('RuleData.ExpectedUpperBound', null);
          form.change('RuleData.MaxAttachments', null);
          form.change('RuleData.MinAttachments', null);
          form.change('RuleData.Specification', '');
        }

        previousValues = values;
      });
    },
    { touched: true, values: true },
  );

  return unsubscribe;
};

const AddEditForm = () => (
  <S.AddEditForm>
    <Field component={SelectFieldAdapter} floatingLabelText="Task Type" name="RuleType">
      <MenuItem primaryText="Verification" value="Verification" />
      <MenuItem primaryText="Feedback" value="Feedback" />
      <MenuItem primaryText="Check Flow Rate" value="CheckFlowRate" />
      <MenuItem primaryText="Check Quantity" value="CheckQuantity" />
      <MenuItem primaryText="Check Temperature" value="CheckTemperature" />
      <MenuItem primaryText="Photo Confirmation" value="PhotoConfirmation" />
      <MenuItem primaryText="Scan Code" value="ScanCode" />
      <MenuItem primaryText="Signature Collection" value="Signature" />
    </Field>

    <S.Instructions>
      <S.InstructionsLabel>Optional Instructions</S.InstructionsLabel>
      <S.InputContainer>
        <Field
          component={TextFieldAdapter}
          floatingLabelText="Short Instruction or Question"
          name="Name"
          maxLength="255"
        />
        <Field
          component={TextFieldAdapter}
          floatingLabelText="Task Notes / Additional Instructions"
          name="InstructionalNotes"
        />
      </S.InputContainer>
    </S.Instructions>

    <S.RuleData>
      <S.RuleDataLabel>Rule Data</S.RuleDataLabel>
      <S.InputContainer>
        <FormSpy subscription={{ values: true }}>
          {({ values }) =>
            values.RuleType ? (
              RULE_DATA[values.RuleType].map(item => <Field key={item.name} component={TextFieldAdapter} {...item} />)
            ) : (
              <span>Choose a Rule Type.</span>
            )
          }
        </FormSpy>
      </S.InputContainer>
    </S.RuleData>
  </S.AddEditForm>
);

const AddEditWithInput = withInputModal(AddEditForm);

const AddEditTaskTemplateDialog = ({ task, ...rest }) => (
  <AddEditWithInput {...rest} decorators={[decorator]} initialValues={task} validate={validate} />
);

AddEditTaskTemplateDialog.defaultProps = {
  handleDelete: () => {},
  task: {
    InstructionalNotes: '',
    Name: '',
    RuleData: {
      ExpectedLowerBound: null,
      ExpectedScanCode: null,
      ExpectedUpperBound: null,
      MaxAttachments: null,
      MinAttachments: null,
      Specification: '',
    },
    RuleType: '',
  },
  withDelete: false,
};

AddEditTaskTemplateDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  task: UnitItemTaskTemplateType,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
};

export default AddEditTaskTemplateDialog;
