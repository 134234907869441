import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as globalSettingsActions } from 'redux/globalSettings';

import BaseDialog from 'components/Dialogs/Base';
import { FieldCheckbox } from 'components/Layout/Inputs';
import { SearchIcon } from 'components/Layout/Icons';

import { filterTenants } from './selectors';

import * as S from './styled';

class GeoLocationDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    tenants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        tenantName: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
      }),
    ).isRequired,
    title: PropTypes.string.isRequired,
    updateActiveAll: PropTypes.func.isRequired,
    setSelectedTask: PropTypes.func.isRequired,
    setNewData: PropTypes.func.isRequired,
    updateTenants: PropTypes.func.isRequired,
    isActiveAll: PropTypes.bool.isRequired,
    searchField: PropTypes.string.isRequired,
    updateSearchFieldValue: PropTypes.func.isRequired,
  };

  handleChange = index => () => {
    const { setNewData, tenants } = this.props;
    const data = [
      ...tenants.slice(0, index),
      {
        ...tenants[index],
        isActive: !tenants[index].isActive,
      },
      ...tenants.slice(index + 1),
    ];

    setNewData(data);
  };

  handleActiveAllChange = () => {
    const { updateActiveAll, isActiveAll, tenants } = this.props;

    const newSelectAllState = !isActiveAll;
    let newDataState = [];

    if (newSelectAllState) {
      newDataState = tenants.map(elem => ({
        ...elem,
        isActive: elem.isVisible ? true : elem.isActive,
      }));
    } else {
      newDataState = tenants.map(elem => ({
        ...elem,
        isActive: elem.isVisible ? false : elem.isActive,
      }));
    }

    updateActiveAll({
      isActiveAll: newSelectAllState,
      data: newDataState,
    });
  };

  handleClose = () => {
    const { handleClose, setSelectedTask, setNewData } = this.props;

    handleClose();
    setSelectedTask(null);
    setNewData([]);
  };

  handleTyping = event => {
    this.props.updateSearchFieldValue(event.target.value);
  };

  submit = () => this.props.updateTenants({ onCloseDialog: this.handleClose });

  render() {
    const { open, title, tenants, isActiveAll, searchField } = this.props;

    return (
      <BaseDialog onRequestClose={this.handleClose} open={open} title={title}>
        <S.Container>
          <S.Item modif>
            <S.CheckboxWrapper />
            <S.Input type="text" value={searchField} onChange={this.handleTyping} placeholder="Filter tenants" />
            <SearchIcon />
          </S.Item>
          {!searchField.length && (
            <S.Item>
              <S.CheckboxWrapper>
                <S.Label>Activate</S.Label>
                <FieldCheckbox onCheck={this.handleActiveAllChange} checked={isActiveAll} />
              </S.CheckboxWrapper>
              <S.Data modif>Select/Deselect All</S.Data>
            </S.Item>
          )}
          {tenants.map(
            (elem, i) =>
              elem.isVisible && (
                <S.Item key={elem.id}>
                  <S.CheckboxWrapper>
                    <FieldCheckbox onCheck={this.handleChange(i)} checked={elem.isActive} />
                  </S.CheckboxWrapper>
                  <S.Data>{elem.tenantName}</S.Data>
                </S.Item>
              ),
          )}
          <S.SaveButton onClick={this.submit} />
        </S.Container>
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ globalSettings }) => ({
  tenants: filterTenants(globalSettings),
  isActiveAll: globalSettings.isActiveAll,
  searchField: globalSettings.searchField,
});

const mapDispatchToProps = {
  getTenants: globalSettingsActions.getTenantsRequest,
  setSelectedTask: globalSettingsActions.setSelectedTaskTypes,
  updateActiveAll: globalSettingsActions.updateActiveAllFlag,
  setNewData: globalSettingsActions.editData,
  updateTenants: globalSettingsActions.updateTenantsRequest,
  updateSearchFieldValue: globalSettingsActions.updateSearchField,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoLocationDialog);
