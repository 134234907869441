import { call, put, takeEvery, select } from 'redux-saga/effects';

import { ERROR_DIALOG_OPENED } from 'redux/errorHandler';

import {
  addTeam,
  assignTeamMembers,
  fetchMembersForAssigment,
  fetchSiteManagers,
  fetchTeamMembers,
  fetchTeams,
  putTeam,
  removeTeam,
  removeTeamMember,
  fetchTeamListBySite,
  fetchFilteredTeamMembers,
} from 'http/teams';
import { formatServerError } from 'helpers';
import { getSiteId } from '../unit/selectors';
import { actions } from './index';

export function* addTeamMembers({ payload }) {
  try {
    const { teamId, userIds, siteId } = payload;
    let SiteID = '';
    if (siteId) {
      SiteID = siteId;
    } else {
      SiteID = yield select(getSiteId);
    }
    const { data } = yield call(assignTeamMembers, { teamId, userIds, SiteID });
    yield put(actions.addTeamMembersSuccess(data.root));
    yield put(actions.getTeamMembersRequest({ teamId: data.root[0].TeamID }));
    yield put(actions.getMembersForAssigmentRequest({ teamId: data.root[0].TeamID }));
  } catch (error) {
    yield put(actions.addTeamMembersFailure(formatServerError(error, 'An error occurred while adding Team Member.')));
  }
}

export function* getMembersForAssigment({ payload }) {
  try {
    const { data } = yield call(fetchMembersForAssigment, payload);
    yield put(actions.getMembersForAssigmentSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getMembersForAssigmentFailure(
        formatServerError(error, 'An error occurred while getting Members for assigment list.'),
      ),
    );
  }
}

export function* getTeamMembers({ payload }) {
  try {
    const { data } = yield call(fetchTeamMembers, payload);
    yield put(actions.getTeamMembersSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getTeamMembersFailure(formatServerError(error, 'An error occurred while getting Team Members list.')),
    );
  }
}

export function* getTeams({ payload }) {
  try {
    // const SiteID = yield select(getSiteId);
    const { data } = yield call(fetchTeams, payload);
    yield put(actions.getTeamsSuccess(data.root));
  } catch (error) {
    yield put(actions.getTeamsFailure(formatServerError(error, 'An error occurred while getting the Teams list.')));
  }
}

export function* getSiteManagers({ payload }) {
  try {
    const { siteId } = payload;
    let SiteID = '';
    if (siteId) {
      SiteID = siteId;
    } else {
      SiteID = yield select(getSiteId);
    }
    const { data } = yield call(fetchSiteManagers, SiteID);
    const managers = data.root.filter(({ UserType }) => UserType === 'Manager');
    yield put(actions.getSiteManagersSuccess(managers));
  } catch (error) {
    yield put(actions.getSiteManagersFailure(formatServerError(error, 'An error occurred while getting users list.')));
  }
}

export function* createTeam({ payload }) {
  try {
    const { item, cb } = payload;
    const { data } = yield call(addTeam, item);
    yield put(actions.createTeamSuccess(data.entity));
    yield put(actions.getTeamsRequest());
    yield put(actions.getTeamListBySiteRequest());
    if (cb) {
      cb();
    }
  } catch (error) {
    yield put(actions.createTeamFailure(formatServerError(error, 'An error occurred while creating the Team.')));
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: {
        title: 'Error',
        message: error.response.data.error,
      },
    });
  }
}

export function* updateTeam({ payload }) {
  try {
    const { team, ignoreSecurityRestriction, cb } = payload;
    const { data } = yield call(putTeam, team, ignoreSecurityRestriction);
    yield put(actions.updateTeamSuccess(data.entity));
    yield put(actions.getTeamsRequest());
    yield put(actions.getTeamListBySiteRequest());
    if (cb) {
      cb();
    }
  } catch (error) {
    yield put(actions.updateTeamFailure(formatServerError(error, 'An error occurred while updating the Team.')));
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: {
        title: 'Error',
        message: error.response.data.error,
      },
    });
  }
}

export function* getFilteredTeamMembers({ payload }) {
  try {
    const { teamId, filter } = payload;
    const { data } = yield call(fetchFilteredTeamMembers, teamId, filter);
    yield put(actions.getFilteredTeamMembersSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getFilteredTeamMembersFailure(formatServerError(error, 'An error occurred while updating the Team.')),
    );
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: {
        title: 'Error',
        message: error.response.data.error,
      },
    });
  }
}

export function* deleteTeam({ payload }) {
  try {
    yield call(removeTeam, payload);
    yield put(actions.deleteTeamSuccess());
    yield put(actions.getTeamsRequest());
    yield put(actions.getTeamListBySiteRequest());
  } catch (error) {
    yield put(actions.deleteTeamFailure(formatServerError(error, 'An error occurred while deleting the Team.')));
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: {
        title: 'Error',
        message: error.response.data.error,
      },
    });
  }
}

export function* deleteTeamMember({ payload }) {
  try {
    yield call(removeTeamMember, payload);
    yield put(actions.deleteTeamMemberSuccess());
    yield put(actions.getTeamMembersRequest({ teamId: payload.teamId }));
    yield put(actions.getMembersForAssigmentRequest({ teamId: payload.teamId }));
  } catch (error) {
    yield put(actions.deleteTeamMemberFailure(formatServerError(error, 'An error occurred while deleting the Team.')));
  }
}

export function* getTeamListBySite() {
  try {
    const { data } = yield call(fetchTeamListBySite);
    const teamList = data.root;
    let orderedTeamList = [];
    if (teamList.length > 0) {
      for (let i = 0; i < teamList.length; i += 1) {
        let isTrue = false;
        orderedTeamList.forEach(item => {
          if (item?.SiteId === teamList[i].SiteId) isTrue = true;
        });
        if (isTrue) {
          orderedTeamList = orderedTeamList.map(item => {
            if (item.SiteId === teamList[i].SiteId) {
              return { SiteName: item.SiteName, SiteId: item.SiteId, Teams: [...item.Teams, teamList[i].Team] };
            }
            return item;
          });
        } else {
          orderedTeamList.push({
            SiteName: teamList[i].SiteName,
            SiteId: teamList[i].SiteId,
            Teams: [teamList[i].Team],
          });
        }
      }
    }
    yield put(actions.getTeamListBySiteSuccess(orderedTeamList));
  } catch (error) {
    yield put(
      actions.getTeamListBySiteFailure(
        formatServerError(error, 'An error occurred while getting the Teams list by site.'),
      ),
    );
  }
}

const teamsSagas = [
  takeEvery(actions.getMembersForAssigmentRequest, getMembersForAssigment),
  takeEvery(actions.addTeamMembersRequest, addTeamMembers),
  takeEvery(actions.getTeamMembersRequest, getTeamMembers),
  takeEvery(actions.getTeamsRequest, getTeams),
  takeEvery(actions.createTeamRequest, createTeam),
  takeEvery(actions.updateTeamRequest, updateTeam),
  takeEvery(actions.deleteTeamRequest, deleteTeam),
  takeEvery(actions.deleteTeamMemberRequest, deleteTeamMember),
  takeEvery(actions.getSiteManagersRequest, getSiteManagers),
  takeEvery(actions.getTeamListBySiteRequest, getTeamListBySite),
  takeEvery(actions.getFilteredTeamMembersRequest, getFilteredTeamMembers),
];

export default teamsSagas;
