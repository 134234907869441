import React from 'react';
import PropTypes from 'prop-types';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const InfoPage = ({ onNext }) => (
  <S.DialogContainer>
    <S.SectionContainer>
      <S.Title>Purpose of this Action Card:</S.Title>
      <S.Text>Assist in quickly setting up new Sites</S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Preparation:</S.Title>
      <S.Text>
        It will be helpfull to add <S.BoldText>at least</S.BoldText> one other employee to the system for testing
        purposes. This means you should have the following information ready:
      </S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Required information:</S.Title>
      <S.Text>
        <S.BoldText>Employee Name and e-mail</S.BoldText> (and optional telephone number)
      </S.Text>
      <S.Text>
        <S.BoldText>Employee role</S.BoldText> (Manager or Task Specialist)
      </S.Text>
      <S.Text>
        <S.BoldText>Manager:</S.BoldText> Can assign and own tasks
      </S.Text>
      <S.Text>
        <S.BoldText>Task Specialist:</S.BoldText> Can only own tasks
      </S.Text>
    </S.SectionContainer>
    <S.SectionContainer>
      <S.Title>Result:</S.Title>
      <S.Text>You will have one or more Sites in the system and People assigned to those Sites</S.Text>
    </S.SectionContainer>
    <ActionsContainer>
      <ActionButton label="Next" onClick={onNext} isNext />
    </ActionsContainer>
  </S.DialogContainer>
);

InfoPage.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default InfoPage;
