import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as settingsActions } from 'redux/settings';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import withInputModal from 'components/Dialogs/withInputModal';
import NotificationBlock from './NotificationBlock';
import { ResetButton } from 'components/Dialogs/withInputModal/Controls/styled';
import { RestoreIcon } from 'components/Layout/Icons';
import validate from './validator';

class TextDialogForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    getMasterSetting: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    values: PropTypes.object,
    userType: PropTypes.number.isRequired,
  };

  static defaultProps = {
    initialValues: {},
    values: {},
  };

  openWarningModal = () => {
    this.props.openConfirmationDialog('This will delete all current data!', () => this.resetNotifications(), 'Warning');
  };

  resetNotifications = () => {
    const { form, getMasterSetting, initialValues } = this.props;

    getMasterSetting();
    form.reset(initialValues);
  };

  render() {
    const { form, values, userType } = this.props;

    return (
      <>
        <NotificationBlock
          disabled={
            !values.SalutationTaskSpecialist && !values.IntroTextTaskSpecialist && !values.ClosingTextTaskSpecialist
          }
          form={form}
          name={{
            0: 'SalutationTaskSpecialist',
            1: 'IntroTextTaskSpecialist',
            2: 'ClosingTextTaskSpecialist',
          }}
          title="Task Specialist"
          values={values}
        />
        <NotificationBlock
          disabled={!values.SalutationManagerAdmin && !values.IntroTextManagerAdmin && !values.ClosingTextManagerAdmin}
          form={form}
          name={{
            0: 'SalutationManagerAdmin',
            1: 'IntroTextManagerAdmin',
            2: 'ClosingTextManagerAdmin',
          }}
          title="Manager/Admin"
          values={values}
        />
        <NotificationBlock
          disabled={
            !values.SalutationOEMManagerAdmin && !values.IntroTextOEMManagerAdmin && !values.ClosingTextOEMManagerAdmin
          }
          form={form}
          name={{
            0: 'SalutationOEMManagerAdmin',
            1: 'IntroTextOEMManagerAdmin',
            2: 'ClosingTextOEMManagerAdmin',
          }}
          title="OEM Manager/Admin"
          values={values}
        />
        {userType === 4 && (
          <ResetButton type="button" label="Reset to System" position onClick={this.openWarningModal}>
            <RestoreIcon />
          </ResetButton>
        )}
      </>
    );
  }
}

const TextDialogWithInput = withInputModal(TextDialogForm);

const TextDialog = props => <TextDialogWithInput initialValues={props.notifications} validate={validate} {...props} />;

TextDialog.propTypes = {
  notifications: PropTypes.shape({
    SalutationTaskSpecialist: PropTypes.string,
    IntroTextTaskSpecialist: PropTypes.string,
    ClosingTextTaskSpecialist: PropTypes.string,
    SalutationManagerAdmin: PropTypes.string,
    IntroTextManagerAdmin: PropTypes.string,
    ClosingTextManagerAdmin: PropTypes.string,
    SalutationOEMManagerAdmin: PropTypes.string,
    IntroTextOEMManagerAdmin: PropTypes.string,
    ClosingTextOEMManagerAdmin: PropTypes.string,
  }).isRequired,
};

TextDialog.defaultProps = {
  notifications: {
    SalutationTaskSpecialist: '',
    IntroTextTaskSpecialist: '',
    ClosingTextTaskSpecialist: '',
    SalutationManagerAdmin: '',
    IntroTextManagerAdmin: '',
    ClosingTextManagerAdmin: '',
    SalutationOEMManagerAdmin: '',
    IntroTextOEMManagerAdmin: '',
    ClosingTextOEMManagerAdmin: '',
  },
};

const mapStateToProps = ({ settings: { list, masterSettings }, auth: { user } }) => ({
  notifications: Object.keys(masterSettings).length ? masterSettings : list,
  userType: user.userType,
});

const mapDispatchToProps = {
  getMasterSetting: settingsActions.getMasterSettingsForAdminRequest,
  openConfirmationDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextDialog);
