const placeholders = {
  userName: 'A cool name, email or phone number',
  password: 'Password: string, phrase or sentence',
  email: '(corporate) e-mail for notifications',
  name: 'Your Name',
  tenantName: 'Company Name',
  firstName: 'First Name',
  lastName: 'Last Name',
};

export const signUpInputFields = [
  { fieldName: 'userName', nextField: 'password', placeholder: placeholders.userName },
  { fieldName: 'password', nextField: 'email', placeholder: placeholders.password },
  { fieldName: 'email', nextField: 'firstName', placeholder: placeholders.email },
  { fieldName: 'firstName', nextField: 'lastName', placeholder: placeholders.firstName },
  { fieldName: 'lastName', nextField: 'tenantName', placeholder: placeholders.lastName },
  { fieldName: 'tenantName', nextField: 'industry', placeholder: placeholders.tenantName },
];

export const nothingAvailableText =
  'Nothing available?\n No worries! Just sign-up and we will notify you when there are more.';

export const signupText = 'Once you sign up - you can upgrade at anytime while keeping your data.';

export const entryExists = 'Sorry, this entry already exists! Please try again or contact us for more information';

export const activeFieldsObject = { password: false, email: false, name: false, company: false, industry: false };
