import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import { OptionsType } from 'configs/propTypes';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import EditableListDialog from 'components/Dialogs/EditableListDialog';

import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as partOptionsActions } from 'redux/partOptions';
import { actions as partsActions } from 'redux/parts';

import { newPartCards, editModalValues, fieldLabels, textFields, selectFields } from './constants';
import validate from './validator';
import * as S from './styled';

class NewPartForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      change: PropTypes.func.isRequired,
    }).isRequired,
    values: PropTypes.shape({}).isRequired,
    options: OptionsType.isRequired,
    fetchOptions: PropTypes.func.isRequired,
    fetchOEMs: PropTypes.func.isRequired,
    fetchSuppliers: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
  };

  state = {
    editableValue: 'oems',
    editModalOpen: false,
  };

  componentDidMount() {
    const { fetchOptions, fetchOEMs, fetchSuppliers } = this.props;

    fetchOptions();
    fetchOEMs();
    fetchSuppliers();
  }

  onEdit = editableValue => this.setState({ editableValue, editModalOpen: true });

  closeEditModal = () => this.setState({ editModalOpen: false });

  copyValues = (from, to) => {
    const { values, form } = this.props;
    const sourceValues = Object.entries(values).filter(([key]) => key.startsWith(from));

    sourceValues.forEach(([key, value]) => {
      const target = `${to}${key.replace(from, '')}`;

      if (target !== 'SupplierPartCategoryID' && target !== 'SupplierPartTypeID') {
        form.change(`${to}${key.replace(from, '')}`, value);
      }
    });
  };

  render() {
    const { options } = this.props;
    const { editModalOpen, editableValue } = this.state;

    return (
      <S.DialogContainer>
        {newPartCards.map(({ title, titleType, prefix, buttons, field }) => (
          <S.FormCardContainer key={title}>
            <S.CardHeader titleType={titleType}>
              {titleType === 'my' ? (
                title
              ) : (
                <>
                  <Field
                    name={field}
                    component={SelectFieldAdapter}
                    floatingLabelText={title}
                    underlineStyle={{ display: 'none' }}
                  >
                    {this.props[`${titleType}s`].length > 0
                      ? this.props[`${titleType}s`].map(({ Id, Name }) => (
                          <SelectItem key={Id} primaryText={Name} value={Id} />
                        ))
                      : []}
                  </Field>
                  <S.EditButton type="button" onClick={() => this.onEdit(`${titleType}s`)}>
                    Edit
                  </S.EditButton>
                </>
              )}
            </S.CardHeader>
            <S.CardContent>
              {textFields.map(name => (
                <Field
                  name={`${prefix}${name}`}
                  key={`${prefix}${name}`}
                  component={TextFieldAdapter}
                  floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                  floatingLabelFixed
                  placeholder={fieldLabels[`${prefix}${name}`].placeholder}
                />
              ))}
              {selectFields.map(({ name, list }) => (
                <Field
                  component={SelectFieldAdapter}
                  key={`${prefix}${name}`}
                  name={`${prefix}${name}`}
                  floatingLabelText={fieldLabels[`${prefix}${name}`].label}
                  floatingLabelFixed
                  disabled={!options[list].length}
                  hintText={fieldLabels[`${prefix}${name}`].placeholder}
                >
                  {options[list].length > 0
                    ? options[list].map(({ Id, Name }) => <SelectItem key={Id} primaryText={Name} value={Id} />)
                    : []}
                </Field>
              ))}
            </S.CardContent>
            <S.CardButtonContainer>
              {buttons.map(({ buttonTitle, copyValue }) => (
                <S.CardButton type="button" key={buttonTitle} onClick={() => this.copyValues(copyValue, prefix)}>
                  {buttonTitle}
                </S.CardButton>
              ))}
            </S.CardButtonContainer>
          </S.FormCardContainer>
        ))}
        <EditableListDialog
          list={this.props[editableValue]}
          open={editModalOpen}
          searchPlaceholder={editModalValues[editableValue].placeholder}
          title={editModalValues[editableValue].title}
          idTitle={editModalValues[editableValue].deleteId}
          handleClose={this.closeEditModal}
          addItem={this.props[editModalValues[editableValue].addItem]}
          editItem={this.props[editModalValues[editableValue].editItem]}
          deleteItem={this.props[editModalValues[editableValue].deleteItem]}
          openConfirmationDialog={this.props.openConfirmationDialog}
        />
      </S.DialogContainer>
    );
  }
}

const NewPart = withInputModal(NewPartForm);

const NewPartDialog = props => <NewPart initialValues={{}} validate={validate} withGreenButton {...props} />;

const mapStateToProps = ({ partOptions, parts }) => ({
  options: partOptions.options,
  oems: parts.oemList,
  suppliers: parts.suppliersList,
});

const mapDispatchToProps = {
  fetchOptions: partOptionsActions.getPartOptionsRequest,
  fetchOEMs: partsActions.getOemListRequest,
  fetchSuppliers: partsActions.getSuppliersListRequest,
  addOemRequest: partsActions.addOemRequest,
  editOemRequest: partsActions.editOemRequest,
  deleteOemRequest: partsActions.deleteOemRequest,
  addSupplierRequest: partsActions.addSupplierRequest,
  editSupplierRequest: partsActions.editSupplierRequest,
  deleteSupplierRequest: partsActions.deleteSupplierRequest,
  openConfirmationDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPartDialog);
