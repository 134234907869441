import styled from 'styled-components';

export const DialogContainer = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  margin-bottom: 30px;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  color: ${({ theme }) => theme.modalsContentColor};
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.primaryGrey};
`;

export const RedText = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.mainRed};
  display: flex;
  align-self: flex-end;
`;

export const BoldRedText = styled(RedText)`
  font-weight: bold;
  margin-left: 2px;
`;

export const Link = styled.a`
  cursor: pointer;
`;
