import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import withInputModal from 'components/Dialogs/withInputModal';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';
import { InfoIcon } from 'components/Layout/Icons';
import { isIE11 } from 'helpers';

import * as S from './styled';

class EmailContentDialogForm extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    values: {},
  };

  resetField = isChecked => {
    const { form, values } = this.props;

    if (isChecked) {
      form.reset({
        ...values,
        salutation: '',
        introText: '',
        closingText: '',
      });
    }
  };

  resetCheckbox = string => {
    const { form, values } = this.props;

    if (values.sendDefaultMessages && string.length) {
      form.reset({
        ...values,
        sendDefaultMessages: false,
      });
    }
  };

  render() {
    const { onClick } = this.props;

    return (
      <S.FormContainer>
        <S.CheckboxesContainer>
          <Field component={CheckboxAdapter} label="Send Location List" name="sendLocations" />
          <Field
            component={CheckboxAdapter}
            label="Send Standard Welcome Message"
            name="sendDefaultMessages"
            onChangeControl={this.resetField}
          />
          <Field component={CheckboxAdapter} label="Send Links to Apps" name="sendLinks" />
          <S.IconButton
            onClick={onClick}
            tooltip={'Preview Standart Message Text'}
            tooltipPosition="bottom-left"
            tooltipStyles={{
              width: `${isIE11 ? 'auto' : '100px'}`,
            }}
          >
            <InfoIcon />
          </S.IconButton>
        </S.CheckboxesContainer>
        <div>Personalized Welcome Message (not required)</div>
        <S.InputsContainer>
          <S.InputContainer>
            <Field
              component={S.TextField}
              name="salutation"
              onChangeControl={this.resetCheckbox}
              floatingLabelText="Salutation"
            />
            <Field
              component={S.TextField}
              name="introText"
              onChangeControl={this.resetCheckbox}
              floatingLabelText="Intro Text"
              rows={9}
            />
            <Field
              component={S.TextField}
              name="closingText"
              onChangeControl={this.resetCheckbox}
              floatingLabelText="Closing Text"
              rows={3}
            />
          </S.InputContainer>
        </S.InputsContainer>
      </S.FormContainer>
    );
  }
}

const EmailContentDialogWithInput = withInputModal(EmailContentDialogForm);

const EmailContentDialog = props => <EmailContentDialogWithInput initialValues={props.emailContent} {...props} />;

EmailContentDialog.propTypes = {
  emailContent: PropTypes.object.isRequired,
};

const mapStateToProps = ({ people: { emailContent } }) => ({
  emailContent,
});

export default connect(mapStateToProps)(EmailContentDialog);
