import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 10px 15px 0;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;
`;

export const TopInfo = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  margin-bottom: 14px;
`;

export const SubInfo = styled.div``;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0;
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.mainLightBlue};
  color: ${({ theme }) => theme.primaryWhite};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 15px;
`;

export const SectionBody = styled.div`
  padding: 0 30px 15px;
`;
