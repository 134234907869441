import React from 'react';
import { Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as scheduleActions } from 'redux/schedule';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import validate from './validator';
import * as S from './styled';

const EditScheduleForm = () => (
  <S.FormContainer>
    <Field component={TextFieldAdapter} type="text" name="Name" floatingLabelText="Name" />
  </S.FormContainer>
);

const EditScheduleWithInput = withInputModal(EditScheduleForm);

const deleteSchedule = props => {
  const { scheduleId, unitId } = props.match.params;

  props.requestDeleteSchedule({ scheduleId, unitId });
  props.handleClose();
};

const onDelete = props => {
  props.openConfirmationDialog(
    'Are you sure you want to permanently delete this Schedule? This cannot be undone!',
    () => deleteSchedule(props),
    'Delete?',
  );
};

const onSubmit = (values, props) => {
  const { scheduleId, unitId } = props.match.params;

  props.requestEditSchedule({
    scheduleId,
    unitId,
    name: values.Name,
  });
  props.handleClose();
};

const EditScheduleModal = props => (
  <EditScheduleWithInput
    {...props}
    title="Edit Checklist"
    onSubmit={values => onSubmit(values, props)}
    handleDelete={() => onDelete(props)}
    withDelete
    validate={validate}
  />
);

export default withRouter(
  connect(null, {
    openConfirmationDialog,
    requestEditSchedule: scheduleActions.editScheduleRequest,
    requestDeleteSchedule: scheduleActions.deleteScheduleRequest,
  })(EditScheduleModal),
);
