import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { InfoIcon } from 'components/Layout/Icons';
import {
  Container,
  ItemName,
  ItemText,
  TextBlock,
  ButtonsBlock,
  Line,
  CheckBox,
  InfoBlock,
  TextInfoBlock,
  Button,
} from './styled';

const BASE_INDEX = 1000;

class UnitItem extends PureComponent {
  static propTypes = {
    getItemName: PropTypes.func.isRequired,
    getItemText: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    islast: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    leftCheckbox: PropTypes.bool.isRequired,
    onUnitItemClick: PropTypes.func.isRequired,
    onCheck: PropTypes.func.isRequired,
    renderLeftControls: PropTypes.func.isRequired,
    renderRightControls: PropTypes.func.isRequired,
    renderRightText: PropTypes.func.isRequired,
    checked: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleToggle: PropTypes.func,
    withDelete: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    handleToggle: () => {},
  };

  onItemClick = () => {
    this.props.onUnitItemClick(this.props.item);
  };

  onCheck = (event, value, item) => {
    this.props.onCheck(item, value);
  };

  render() {
    const {
      getItemName,
      getItemText,
      item,
      islast,
      leftCheckbox,
      renderLeftControls,
      renderRightControls,
      renderRightText,
      checked,
      handleToggle,
      index,
      withDelete,
    } = this.props;

    return (
      <Container islast={islast} index={BASE_INDEX - index}>
        <Line>
          <ButtonsBlock>
            {renderLeftControls(item)}
            {leftCheckbox && (
              <CheckBox
                checked={checked.includes(item.Id)}
                onCheck={(event, value) => this.onCheck(event, value, item)}
              />
            )}
          </ButtonsBlock>
          <Line onClick={item.Status !== 'Completed' ? this.onItemClick : undefined}>
            <TextBlock>
              <ItemName withDelete={withDelete}>{getItemName(item)}</ItemName>
              <ItemText>{getItemText(item)}</ItemText>
            </TextBlock>
            {item.Status === 'Completed' ? (
              <InfoBlock>
                <TextInfoBlock>{renderRightText(item)}</TextInfoBlock>
                <Button tooltipPosition="bottom-left" tooltip="Details" onClick={() => handleToggle(item)}>
                  <InfoIcon />
                  {renderRightControls(item)}
                </Button>
              </InfoBlock>
            ) : (
              <ButtonsBlock>
                {renderRightText(item)} {renderRightControls(item)}
              </ButtonsBlock>
            )}
          </Line>
        </Line>
      </Container>
    );
  }
}

export default UnitItem;
