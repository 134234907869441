import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  DOCUMENTS_LIST_REQUEST: undefined,
  DOCUMENTS_LIST_SUCCESS: undefined,
  DOCUMENTS_LIST_FAILURE: undefined,

  ADD_DOCUMENT_REQUEST: undefined,
  ADD_DOCUMENT_SUCCESS: undefined,
  ADD_DOCUMENT_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.documentsListRequest, handlers.documentsListRequest],
    [actions.documentsListSuccess, handlers.documentsListSuccess],
    [actions.documentsListFailure, handlers.documentsListFailure],

    [actions.addDocumentRequest, handlers.addDocumentRequest],
    [actions.addDocumentSuccess, handlers.addDocumentSuccess],
    [actions.addDocumentFailure, handlers.addDocumentFailure],
  ]),
  initialState,
);

export default reducer;
