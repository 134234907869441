import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, Provider, ReactReduxContext } from 'react-redux';

import { Dialog } from 'components/Dialogs/v1/Base';
import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as scheduleActions } from 'redux/schedule';

import theme from 'theme';

import ChecklistSetup from './ShortcutSiteSetup';
import SelectTasks from './SelectTasks';

import * as S from './styled';

const STEPS = {
  0: {
    Component: ChecklistSetup,
  },
  1: {
    Component: SelectTasks,
  },
};

class ShortcutSetup extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    openTaskModal: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      getShortcutSitesRequest: PropTypes.func.isRequired,
      requestScheduleTemplates: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentUnit: PropTypes.shape().isRequired,
    checklistToEdit: PropTypes.shape().isRequired,
    dublicateChecklist: PropTypes.bool.isRequired,
    checkChecklistName: PropTypes.func.isRequired,
  };

  state = {
    step: 0,
    currentChecklist: {},
    overrideTime: false,
  };

  componentDidUpdate(prevProps) {
    const { actions, checklistToEdit } = this.props;
    if (!prevProps.checklistToEdit.Name && checklistToEdit.Name) {
      actions.requestScheduleTemplates({ scheduleId: checklistToEdit.Id });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ step: 0, currentChecklist: {} });
    }
  }

  handlePrev = () => {
    this.setState(({ step }) => ({ step: step - 1 }));
  };

  handleNext = () => {
    this.setState(({ step }) => ({ step: step + 1 }));
  };

  setOverride = value => {
    this.setState({ overrideTime: value });
  };

  setCurrentChecklist = checklist => {
    this.setState({ currentChecklist: checklist });
  };

  closeChecklist = () => {
    this.props.handleClose();
  };

  exitChecklist = () => {
    this.props.handleExit();
    this.setState({ currentChecklist: {} });
  };

  render() {
    const { step, overrideTime, currentChecklist } = this.state;
    const { open, isLoading, currentUnit, checklistToEdit, openTaskModal, dublicateChecklist, checkChecklistName } =
      this.props;
    const Step = STEPS[step].Component;

    return (
      <ReactReduxContext.Consumer>
        {
          (ctx => (
            <Dialog
              open={open}
              onRequestClose={step > 0 ? this.closeChecklist : this.exitChecklist}
              title={
                step > 0
                  ? `${checklistToEdit.Name ? 'Edit' : 'New'} Checklist Items and Tasks`
                  : `${checklistToEdit.Name ? 'Edit' : 'New'} Checklist`
              }
              titleColor={step > 0 ? theme.primaryBlue : theme.mainRed}
            >
              <S.DialogContainer>
                <S.Overlay show={isLoading}>
                  <S.Loader />
                </S.Overlay>
                <Provider store={ctx.store}>
                  <Step
                    onNext={this.handleNext}
                    onBack={this.handlePrev}
                    onSiteSelect={this.setCurrentSite}
                    currentUnit={currentUnit}
                    setCurrentChecklist={this.setCurrentChecklist}
                    currentChecklist={currentChecklist}
                    onClose={this.closeChecklist}
                    onExit={this.exitChecklist}
                    history={this.props.history}
                    setOverride={this.setOverride}
                    overrideTime={overrideTime}
                    checklistToEdit={checklistToEdit}
                    dublicateChecklist={dublicateChecklist}
                    openTaskModal={openTaskModal}
                    checkChecklistName={checkChecklistName}
                  />
                </Provider>
              </S.DialogContainer>
            </Dialog>
            // eslint-disable-next-line no-extra-bind
          )).bind(this) // Dont forget to bind this
        }
      </ReactReduxContext.Consumer>
    );
  }
}

const mapStateToProps = ({ schedule }) => ({
  isLoading: schedule.isLoading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
      requestScheduleTemplates: scheduleActions.fetchScheduleTemplatesRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutSetup);
