import styled from 'styled-components';

import { Row } from 'components/Layout/Containers';
import { GroupIcon } from 'components/Layout/Icons';

export const GroupIconColored = styled(GroupIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})``;

export const ControlsBlock = styled(Row)`
  height: 100%;
  color: ${({ theme }) => theme.darkGrey} !important;
`;
