const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validator = values => {
  const errors = {};
  if (!values.userName) {
    errors.userName = 'Username is required!';
  }
  if (!values.password) {
    errors.password = 'Password is required!';
  }
  if (!values.email) {
    errors.email = 'Email is required!';
  } else if (!values.email.match(EMAIL_REGEX)) {
    errors.email = 'Please enter a valid e-mail adress';
  }
  if (!values.firstName) {
    errors.fullName = 'First name is required!';
  }
  if (!values.lastName) {
    errors.fullName = 'Last name is required!';
  }
  if (!values.tenantName) {
    errors.tenantName = 'Company name is required!';
  }
  if (!values.industryId) {
    errors.industryId = 'Industry is required!';
  }

  return errors;
};

export default validator;
