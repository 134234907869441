import React from 'react';

import { TierFieldLabel } from './styled';

export const recentRequestFields = [
  {
    field: 'SitesCount',
    label: (
      <TierFieldLabel>
        Max <b>Sites</b> each
      </TierFieldLabel>
    ),
  },
  {
    field: 'ItemsCount',
    label: (
      <TierFieldLabel>
        Max <b>Items</b> each
      </TierFieldLabel>
    ),
  },
  {
    field: 'SensorsCount',
    label: (
      <TierFieldLabel>
        Max <b>Sensors</b> each
      </TierFieldLabel>
    ),
  },
];

export const recentRequestInfo = [
  { title: 'By: ', field: 'RequestorName' },
  { title: 'Date: ', field: 'CreatedOn' },
  { title: 'eMail: ', field: 'RequestorEmail' },
];

export const tierSettingsFields = [
  {
    field: 'TenantMaxSitesEach',
    label: (
      <TierFieldLabel>
        Max <b>Sites</b> each
      </TierFieldLabel>
    ),
  },
  {
    field: 'TenantMaxItemsEach',
    label: (
      <TierFieldLabel>
        Max <b>Items</b> each
      </TierFieldLabel>
    ),
  },
  {
    field: 'TenantMaxSensorsEach',
    label: (
      <TierFieldLabel>
        Max <b>Sensors</b> each
      </TierFieldLabel>
    ),
  },
  { field: 'TasksCharge', label: <TierFieldLabel>Tasks Charge</TierFieldLabel> },
  { field: 'IotCharge', label: <TierFieldLabel>IoT Charge</TierFieldLabel> },
  {
    field: 'TotalCharge',
    readOnly: true,
    label: (
      <TierFieldLabel>
        <b>Total Charge (mo)</b>
      </TierFieldLabel>
    ),
  },
];

export const additionalFields = [
  {
    field: 'CustomModulesCharges',
    label: (
      <TierFieldLabel>
        Custom Modules <b>Charges</b>
      </TierFieldLabel>
    ),
    textField: 'CustomModulesList',
    textFieldLabel: 'Custom modules list',
  },
  {
    field: 'OtherCharges',
    label: (
      <TierFieldLabel>
        Other <b>Charges</b>
      </TierFieldLabel>
    ),
    textField: 'OtherChargesSpecifications',
    textFieldLabel: 'Other charges specifications',
  },
];

export const defaultAdditionalValues = {
  CustomModulesCharges: 0,
  CustomModulesList: '',
  OtherCharges: 0,
  OtherChargesSpecifications: '',
  TotalAdditionalCharges: 0,
};
