import styled from 'styled-components';

import logoImage from 'components/Layout/Images/logo-dark.png';

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.lightGrey};
`;

export const Logo = styled.img.attrs({
  src: logoImage,
  alt: 'InCheq logo',
})`
  align-self: center;
  display: flex;
  margin-bottom: 1.5em;
  max-height: 100px;
`;

export const Message = styled.h1`
  color: ${({ theme }) => theme.tenantNameColor};
  text-align: center;
`;
