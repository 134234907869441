import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { InputWrapper, InputBlockLabel } from 'components/Layout/Containers';
import { DialogContainer } from 'components/Dialogs/styled';
import { DirectionRunsIcon } from 'components/Layout/Icons';

export const Container = styled(DialogContainer)``;

export const Section = styled(Flex).attrs({
  column: true,
  justify: 'space-between',
})`
  padding: 32px 32px 0 32px;
`;

export const SectionBody = styled(InputWrapper)`
  border: ${({ theme }) => `1px solid ${theme.primarySites}`};
`;

export const SectionHeader = styled(InputBlockLabel)`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: ${({ theme }) => theme.primarySites};
`;

export const SearchSection = styled.div`
  width: 100%;
  height: 50px;
  padding-top: 10px;
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
`;

export const TitleText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 24px;
  padding-top: 30px;
`;

export const RedRow = styled.div`
  padding-top: 15px;
  text-align: center;
  color: ${({ theme }) => theme.mainRed};
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
  cursor: pointer;
`;

export const TestRunIcon = styled(DirectionRunsIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px !important;
  background-color: ${({ theme }) => theme.mainRed};
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.span`
  margin-left: 10px;
`;
