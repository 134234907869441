import styled from 'styled-components';

import { ForwardIcon } from 'components/Layout/Icons';

export const Group = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Item = styled.div`
  width: calc(100% / 3);
  ${({ field }) => field === 'name' && { fontWeight: 'bold' }};
`;

export const Count = styled.div`
  width: 120px;
`;

export const IconForward = styled(ForwardIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;
