import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isIE11, resizeWindow } from 'helpers';

import { SensorType, SensorTypeType } from 'configs/propTypes';

import { actions as sensorsActions } from 'redux/sensors';
import { openErrorDialog } from 'redux/errorHandler';

import withInputModal from 'components/Dialogs/withInputModal';
import WarningModal from 'components/Dialogs/Warning';
import { ButtonIcon } from 'components/Layout/Buttons';
import { AddCircleIcon } from 'components/Layout/Icons';

import SensorsList from './SensorsList';
import { getSensorListForForm } from './helpers';
import * as S from './styled';

class SensorsDialogForm extends PureComponent {
  static propTypes = {
    action: PropTypes.shape({
      editSensorRequest: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
    }).isRequired,
    form: PropTypes.shape({}).isRequired,
    list: PropTypes.arrayOf(SensorType.isRequired).isRequired,
    openLevelSettingsDialog: PropTypes.func.isRequired,
    openLinkSensorDialog: PropTypes.func.isRequired,
    sensorsForSite: PropTypes.arrayOf(SensorType).isRequired,
    typesList: PropTypes.arrayOf(SensorTypeType).isRequired,
    values: PropTypes.shape({}).isRequired,
    error: PropTypes.string.isRequired,
  };

  state = {
    levelSettingsDialogOpened: false,
    sensor: '',
    warningModalOpened: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.action.openErrorDialog(nextProps.error, 'Error');
    }
  }

  closeWarningModal = () => {
    this.setState({ warningModalOpened: false });
  };

  getTooltip = () => {
    const { sensorsForSite, typesList } = this.props;

    if (!sensorsForSite.length) {
      return 'There are no available sensors for this site';
    } else if (!typesList.length) {
      return 'There are no available sensor types';
    }

    return 'Add Sensor';
  };

  getTooltipWidth = () => {
    const { sensorsForSite, typesList } = this.props;

    if (!sensorsForSite.length) {
      return '200px';
    } else if (!typesList.length) {
      return '170px';
    }

    return `${isIE11 ? 'auto' : '70px'}`;
  };

  openWarningModal = sensor => {
    this.setState({ warningModalOpened: true, sensor });
  };

  submitWarningModal = () => {
    const { action } = this.props;
    const { sensor } = this.state;
    action.editSensorRequest({
      itemId: sensor.ItemId,
      values: {
        dto: {
          ...sensor,
          IsAssigned: false,
          ItemId: null,
          SensorTypeID: null,
          UnitId: null,
        },
        id: sensor.Id,
      },
    });

    this.closeWarningModal();

    setTimeout(() => resizeWindow(), 0);
  };

  renderTextForWarningModal = (
    <S.WarningModalText>
      <div>Are you sure?</div>
      <div>This will delete the sensor and its settings</div>
    </S.WarningModalText>
  );

  render() {
    const { list, openLevelSettingsDialog, openLinkSensorDialog, sensorsForSite, typesList } = this.props;
    const { warningModalOpened } = this.state;

    return (
      <>
        <S.Container>
          <SensorsList
            deleteSensor={this.openWarningModal}
            list={list}
            openLevelSettingsDialog={openLevelSettingsDialog}
          />
          <ButtonIcon
            onClick={!sensorsForSite.length || !typesList.length ? () => {} : openLinkSensorDialog}
            tooltip={this.getTooltip()}
            tooltipPosition="top-center"
            tooltipStyles={{
              width: this.getTooltipWidth(),
            }}
          >
            <AddCircleIcon />
          </ButtonIcon>
        </S.Container>
        <WarningModal
          handleClose={this.closeWarningModal}
          onSubmit={this.submitWarningModal}
          open={warningModalOpened}
          text={this.renderTextForWarningModal}
          title="Delete Sensor"
        />
      </>
    );
  }
}

const SensorsDialogWithInput = withInputModal(SensorsDialogForm);

const SensorsDialog = props => (
  <SensorsDialogWithInput
    initialValues={{
      ...getSensorListForForm(props.list),
    }}
    {...props}
  />
);

SensorsDialog.propTypes = {
  list: PropTypes.arrayOf(SensorType).isRequired,
};

const mapStateToProps = ({ sensors: { list, sensorsForSite, typesList, error } }) => ({
  list,
  sensorsForSite,
  typesList,
  error,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      ...sensorsActions,
      openErrorDialog,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorsDialog);
