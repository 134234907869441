import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';

import { notificationInfo, notAvailableInfo } from './helpers';
import * as S from './styled';

const NotificationInfoDialog = ({ open, title, handleClose, notificationLabel, notAvailable }) => (
  <BaseDialog open={open} title={title} onRequestClose={handleClose}>
    <S.DialogContainer>
      <S.InfoText notAvailable={notAvailable}>
        {notAvailable ? notAvailableInfo : notificationInfo[notificationLabel]}
      </S.InfoText>
    </S.DialogContainer>
  </BaseDialog>
);

NotificationInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  notAvailable: PropTypes.bool.isRequired,
  notificationLabel: PropTypes.oneOf(['SendDailyMail', 'SendIotMail', 'SendIotTxt', 'SendIotPush']),
  handleClose: PropTypes.func.isRequired,
};

NotificationInfoDialog.defaultProps = {
  notificationLabel: '',
};

export default NotificationInfoDialog;
