import styled from 'styled-components';

export const ListItemWrapper = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemWrapper = styled.div`
  margin-left: 0px;
  padding: 16px 0;
  position: relative;
  color: #000000;
`;

export const textFieldStyle = {
  width: '100%',
  color: '#000000',
};
