import styled from 'styled-components';

import { FieldText } from 'components/Layout/Inputs';

import * as CS from '../styled';

export const ConnectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ConnectionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConnectionString = styled(FieldText).attrs({
  inputStyle: {
    textAlign: 'center',
    fontSize: '18px',
    color: ({ theme }) => theme.modalsContentColor,
  },
})`
  hr {
    border-bottom: ${({ theme }) => `1px solid ${theme.primaryGrey}`}!important;
  }

  margin: 0 20px;
`;

export const ConnectionButtonsLabels = styled(CS.NotificationText)`
  margin: 0;

  ${({ isCopied, theme }) => isCopied && { color: theme.primaryGreen }}
`;

export const ConnectionButtonsContainer = styled.div`
  svg {
    transform: scale(1.5);
    transform-origin: right;
  }
`;
