import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import PartsList from './pages';
import GroupParts from './pages/GroupParts';
import PartDetails from './pages/PartDetails';

const Parts = () => (
  <Switch>
    <Route exact path="/parts/parts/:partId" component={PartDetails} />
    <Route exact path="/parts/groups/:groupId" component={GroupParts} />
    <Route exact path="/parts/:type" component={PartsList} />
    <Redirect to="/parts/parts" />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Parts;
