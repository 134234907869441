import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';

import { UnitItemPictureType, UnitPictureType } from 'configs/propTypes';
import { Container, Image, ImageContainer, Message } from './styled';

const params = {
  grabCursor: true,
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets',
  },
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
};

const ImageSwiper = ({ getImageSource, noPicturesMessage, pictures }) => (
  <Container>
    {pictures.length ? (
      <Swiper {...params}>
        {pictures.map(picture => (
          <ImageContainer key={picture.Id}>
            <Image src={getImageSource(picture)} alt={picture.Name} />
          </ImageContainer>
        ))}
      </Swiper>
    ) : (
      <Message>{noPicturesMessage}</Message>
    )}
  </Container>
);

ImageSwiper.defaultProps = {
  noPicturesMessage: 'There are no photos.',
};

ImageSwiper.propTypes = {
  getImageSource: PropTypes.func.isRequired,
  noPicturesMessage: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.oneOfType([UnitItemPictureType, UnitPictureType])).isRequired,
};

export default ImageSwiper;
