import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import { actions as shortcutActions } from 'redux/shortcuts';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { countUnitsAndItems } from 'components/Layout/ShortcutRules/helpers';

import { ShortCutType, SiteType, ScheduleDto } from 'configs/propTypes';
import { ASSIGNMENT_TYPE } from 'modules/activator/dialogs/ShortcutSetup/constants';

import * as S from './styled';

class Overview extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      sendManagementOptionsRequest: PropTypes.func.isRequired,
      sendPersonOptionsRequest: PropTypes.func.isRequired,
      sendTeamOptionsRequest: PropTypes.func.isRequired,
      deleteShortcutRequest: PropTypes.func.isRequired,
      createChecklistsRequest: PropTypes.func.isRequired,
      editChecklistRequest: PropTypes.func.isRequired,
      deletePersonOptions: PropTypes.func.isRequired,
    }).isRequired,
    onNext: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    shortcut: ShortCutType.isRequired,
    currentSite: SiteType.isRequired,
    currentSchedule: ScheduleDto.isRequired,
    manager: PropTypes.shape({
      Name: PropTypes.string,
      FirstName: PropTypes.string,
      LastName: PropTypes.string,
    }).isRequired,
    team: PropTypes.arrayOf(PropTypes.shape()).isRequired, // need provide proptypes
    person: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    error: PropTypes.string.isRequired,
    checklistsList: PropTypes.shape().isRequired,
    siteSchedule: PropTypes.shape().isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    overrideTime: PropTypes.bool.isRequired,
    unitAndItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  state = {
    isConfirmationDialogOpened: false,
  };

  componentDidMount() {
    const { currentSchedule, manager, person, team, actions } = this.props;

    const updatedValuesToSend = {
      ManagementRules: currentSchedule.ManagementRules,
      PersonRules: currentSchedule.PersonRules,
      TeamRules: currentSchedule.TeamRules,
      id: currentSchedule.Id,
      siteId: currentSchedule.SiteID,
      AssignmentType: currentSchedule.AssignmentType,
    };

    if (
      manager === 'empty' &&
      currentSchedule.ManagementRules &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.MANAGER
    ) {
      actions.sendManagementOptionsRequest(updatedValuesToSend);
    }

    if (
      person === 'empty' &&
      currentSchedule.PersonRules &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.PERSON
    ) {
      actions.sendPersonOptionsRequest(updatedValuesToSend);
    }

    if (team === 'empty' && currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM) {
      actions.sendTeamOptionsRequest(updatedValuesToSend);
    }
  }

  componentDidUpdate() {
    const { currentSchedule, manager, person, team, actions } = this.props;

    const updatedValuesToSend = {
      ManagementRules: currentSchedule.ManagementRules,
      PersonRules: currentSchedule.PersonRules,
      TeamRules: currentSchedule.TeamRules,
      id: currentSchedule.Id,
      AssignmentType: currentSchedule.AssignmentType,
    };

    if (
      manager === 'empty' &&
      currentSchedule.ManagementRules &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.MANAGER
    ) {
      actions.sendManagementOptionsRequest(updatedValuesToSend);
    }

    if (
      person === 'empty' &&
      currentSchedule.PersonRules &&
      currentSchedule.AssignmentType === ASSIGNMENT_TYPE.PERSON
    ) {
      actions.sendPersonOptionsRequest(updatedValuesToSend);
    }

    if (team === 'empty' && currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM) {
      actions.sendTeamOptionsRequest(updatedValuesToSend);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.deletePersonOptions();
  }

  handleDeleteShortcutApproval = () => {
    const { shortcut, onExit, actions } = this.props;

    actions.deleteShortcutRequest({ shortcutId: shortcut.Id });
    this.setState({ isConfirmationDialogOpened: false });
    onExit();
  };

  handleDeleteShortcutReject = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  onDelete = () => {
    this.setState({ isConfirmationDialogOpened: true });
  };

  onContinue = () => {
    const {
      currentSite,
      shortcut,
      onNext,
      checklistsList,
      list,
      overrideTime,
      siteSchedule,
      actions: { createChecklistsRequest, editChecklistRequest },
    } = this.props;
    const isFinal = Object.keys(checklistsList).length === list.length - 1;
    if (siteSchedule?.IsScheduled) {
      editChecklistRequest({
        siteId: currentSite.Id,
        shortcutId: shortcut.Id,
        onNext,
      });
    } else {
      createChecklistsRequest({
        siteId: currentSite.Id,
        shortcutId: shortcut.Id,
        ignoreLeadTime: overrideTime,
        onNext,
        isFinal,
      });
    }
  };

  render() {
    const { currentSite, shortcut, manager, person, team, error, currentSchedule, unitAndItems, siteSchedule } =
      this.props;
    const { isConfirmationDialogOpened } = this.state;

    return (
      <S.Container>
        <S.Block>
          <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
          <S.RedText>Checklists will be created for: </S.RedText>
        </S.Block>
        <S.Block>
          <S.SecondaryTitle>
            {currentSite && `${currentSite.AdditionalSiteID.substring(0, 5)} ${currentSite.Name}`}
          </S.SecondaryTitle>
          <S.Title>{`${unitAndItems[0]} Units`}</S.Title>
        </S.Block>
        <S.Block>
          <S.RedText>Selected Items with tasks:</S.RedText>
          <S.ItemsCircle>{unitAndItems[1]}</S.ItemsCircle>
          <S.RedText>Tasks will be assigned to:</S.RedText>
        </S.Block>
        <S.Block>
          <S.Title>
            Manager:
            <S.SecondaryTitle>
              &nbsp;
              {(manager !== 'empty' && manager.Name && `${manager.FirstName} ${manager.LastName}`) ||
                (currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM &&
                  team !== 'empty' &&
                  team.Name &&
                  `${team?.Manager?.FirstName} ${team?.Manager?.LastName}`)}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team:
            <S.SecondaryTitle>
              &nbsp;
              {currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM && team !== 'empty' && team.Name
                ? team.Name
                : 'All'}
            </S.SecondaryTitle>
          </S.Title>
          <S.Title>
            Team Member:
            <S.SecondaryTitle>
              &nbsp;{person !== 'empty' && person.Name ? `${person.FirstName} ${person.LastName}` : 'Open Assignment'}
            </S.SecondaryTitle>
          </S.Title>
        </S.Block>
        {!!error.length && <S.ErrorContainer>{error}</S.ErrorContainer>}
        <ActionsContainer>
          <ActionButton label="Delete Shortcut" onClick={this.onDelete} />
          <ActionButton
            label={siteSchedule?.IsScheduled ? 'Edit Checklists' : 'Create Checklists'}
            isNext
            onClick={this.onContinue}
          />
        </ActionsContainer>
        <ConfirmationDialog
          label="Delete Shortcut"
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>
                Aborting the process will delete this shortcut and cannot be undone!
              </S.ConfirmationText>
              <S.ConfirmationWarning>Are you sure?</S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={isConfirmationDialogOpened}
          onClick={this.handleDeleteShortcutApproval}
          handleClose={this.handleDeleteShortcutReject}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts }, { currentSite }) => ({
  shortcut: shortcuts.shortcut,
  currentSchedule: shortcuts.currentSchedule,
  manager: shortcuts.managementOptions,
  team: shortcuts.teamOptions,
  person: shortcuts.personOptions,
  error: shortcuts.error,
  isLoading: shortcuts.isLoading,
  checklistsList: shortcuts.checklistsList,
  siteSchedule: shortcuts.siteSchedule,
  list: shortcuts.shortcutSitesInfo,
  unitAndItems: countUnitsAndItems(shortcuts.listOfItems, currentSite.Id),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
