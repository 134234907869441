import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import { connect } from 'react-redux';

import { SearchIcon, AddIcon, CheckIcon, DeleteIcon, EditIcon } from 'components/Layout/Icons';
import BaseDialog from 'components/Dialogs/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import SearchField from 'components/Layout/Search';

import { editJobOption, createJobOption, removeJobOption, getCanDeleteOption } from 'http/system';
import { actions as peopleActions } from 'redux/people';

import WarningDialog from './WarningDialog';
import * as S from './styled';

const INITIAL_STATE = {
  jobsList: [],
  jobsSelectList: [],
  isLoading: false,
  isConfirmationDialogOpened: false,
  isInformationDialogOpened: false,
  isWarningDialogOpened: false,
  itemIdToDelete: null,
  jobToReplace: {},
  newItemText: '',
  error: '',
  editError: '',
  globalError: '',
  editingItem: {},
  jobsForSearch: [],
  searchData: '',
  currentSite: {},
  isNewJobFunction: true,
  isButtonDisable: true,
};

class SetFunctionsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    requestJobsList: PropTypes.array.isRequired,
    fetchJobsOptions: PropTypes.func.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    changePersonToEdit: PropTypes.func,
    personToEdit: PropTypes.shape(),
  };

  state = { ...INITIAL_STATE };

  componentDidMount() {
    this.props.fetchJobsOptions();
  }

  componentDidUpdate(prevProps) {
    const { requestJobsList } = this.props;
    if (requestJobsList !== prevProps.requestJobsList) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ jobsList: requestJobsList, jobsForSearch: requestJobsList });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({
        ...INITIAL_STATE,
        jobsList: this.props.requestJobsList,
        jobsForSearch: this.props.requestJobsList,
      });
      this.props.fetchJobsOptions();
    }
  }

  fetchCanDeleteOption = async id => {
    this.setState({ isLoading: true });
    const result = await getCanDeleteOption(id);
    return result.entity;
  };

  handleConfirmationDialogOpen = async id => {
    this.setState({
      jobsSelectList: this.state.jobsList.filter(item => item.Id !== id),
    });
    const canDeleteOption = await this.fetchCanDeleteOption(id);
    if (canDeleteOption.CanDelete) {
      if (canDeleteOption.IsConfirmationRequired) {
        this.setState({ isWarningDialogOpened: true, jobToReplace: null, itemIdToDelete: id });
      } else this.setState({ isConfirmationDialogOpened: true, itemIdToDelete: id });
    } else this.setState({ isInformationDialogOpened: true });
  };

  handleConfirmationDialogClose = () =>
    this.setState({
      isConfirmationDialogOpened: false,
      isInformationDialogOpened: false,
    });

  handleWarningDialogClose = () =>
    this.setState({
      isWarningDialogOpened: false,
      jobToReplace: {},
      isNewJobFunction: true,
      isButtonDisable: true,
    });

  handleInputChange = (e, value) => {
    this.setState({ editingItem: { ...this.state.editingItem, Name: value } });
  };
  handleJobReplace = value => {
    this.setState({ jobToReplace: value, isNewJobFunction: false, isButtonDisable: false });
  };
  handleNewItemTextChange = (e, newItemText) => this.setState({ newItemText });

  handleOptionDeletion = () => {
    const { itemIdToDelete, jobToReplace } = this.state;
    this.setState({ isLoading: true, error: false, globalError: '' });

    return removeJobOption(itemIdToDelete, jobToReplace.Id)
      .then(() => {
        this.setState({
          itemIdToDelete: null,
          isConfirmationDialogOpened: false,
          isWarningDialogOpened: false,
          jobToReplace: {},
          isNewJobFunction: true,
          isButtonDisable: true,
        });
        this.props.fetchJobsOptions();
        this.props.fetchPeople();
        this.props.changePersonToEdit({ ...this.props.personToEdit, JobFunction: jobToReplace.Id });
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          this.setState({
            isLoading: false,
            globalError: error,
            isConfirmationDialogOpened: false,
            isWarningDialogOpened: false,
            jobToReplace: {},
            isNewJobFunction: true,
            isButtonDisable: true,
          });
        },
      );
  };

  // eslint-disable-next-line consistent-return
  handleEditItem = item => {
    const { editingItem } = this.state;

    if (!editingItem.Id) {
      return this.setState({ editingItem: { ...item } });
    }

    const optionInList = this.props.requestJobsList.find(({ Id }) => Id === editingItem.Id);

    if (optionInList.Name === editingItem.Name) {
      return this.setState({ editingItem: {} });
    }

    this.setState({ isLoading: true, error: '', globalError: '' });
    if (editingItem.Name.trim() === '') {
      this.setState({ editError: 'Please enter a correct name' });
    } else {
      return editJobOption(editingItem)
        .then(() => {
          this.setState({ editingItem: {}, editError: '' });
          this.props.fetchJobsOptions();
        })
        .catch(
          ({
            response: {
              data: { error },
            },
          }) => {
            this.setState({ isLoading: false, editError: error });
          },
        );
    }
  };

  handleNewItemSubmit = event => {
    event.preventDefault();
    const { newItemText } = this.state;

    if (!newItemText.trim()) {
      return this.setState({ error: 'Please enter a correct name' });
    }

    this.setState({ isLoading: true, error: '', globalError: '' });
    return createJobOption({ Name: newItemText })
      .then(() => {
        this.setState({ newItemText: '' });
        this.props.fetchJobsOptions();
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          this.setState({ error, isLoading: false });
        },
      );
  };

  setSearchedSites = (jobsList, searchData) => this.setState({ jobsList, searchData });

  closeModal = () => {
    this.setState({
      jobsForSearch: [],
      searchData: '',
    });
    this.props.handleClose();
  };

  render() {
    const {
      jobsList,
      searchData,
      jobsForSearch,
      jobsSelectList,
      isConfirmationDialogOpened,
      isInformationDialogOpened,
      isWarningDialogOpened,
      newItemText,
      error,
      editError,
      editingItem,
      globalError,
      jobToReplace,
      isNewJobFunction,
      isButtonDisable,
    } = this.state;
    const { open, title } = this.props;

    return (
      <BaseDialog open={open} onRequestClose={this.closeModal} title={title}>
        <S.SearchFieldContainer>
          <SearchField
            list={jobsForSearch}
            filterBy="Name"
            searchData={searchData}
            placeholder="filter functions"
            underlineShow={false}
            icon={<SearchIcon />}
            setSearchedItems={this.setSearchedSites}
          />
        </S.SearchFieldContainer>
        <S.Container>
          {globalError ? <S.Error>{globalError}</S.Error> : null}
          <S.List>
            {jobsList.map(({ Id, Name }) => (
              <S.ItemContainer key={Id}>
                <S.ItemName>
                  <TextField
                    id={Id}
                    value={editingItem.Id && editingItem.Id === Id ? editingItem.Name : Name}
                    fullWidth
                    onChange={this.handleInputChange}
                    disabled={editingItem.Id !== Id}
                    underlineShow={editingItem.Id === Id}
                    errorText={editingItem.Id === Id && editError}
                  />
                </S.ItemName>
                <S.ItemActions>
                  <IconButton
                    disabled={editingItem.Id && editingItem.Id !== Id}
                    onClick={() => this.handleEditItem({ Id, Name })}
                  >
                    {editingItem.Id === Id ? <CheckIcon /> : <EditIcon />}
                  </IconButton>
                  <IconButton onClick={() => this.handleConfirmationDialogOpen(Id)}>
                    <DeleteIcon />
                  </IconButton>
                </S.ItemActions>
              </S.ItemContainer>
            ))}
          </S.List>
          <S.CreateContainer onSubmit={this.handleNewItemSubmit}>
            <TextField
              hintText="New Job Function Name"
              floatingLabelText="New Job Function Name"
              fullWidth
              value={newItemText}
              onChange={this.handleNewItemTextChange}
              errorText={error}
            />
            <IconButton type="submit">
              <AddIcon />
            </IconButton>
          </S.CreateContainer>
        </S.Container>
        <ConfirmationDialog
          title="Warning"
          open={isConfirmationDialogOpened}
          handleClose={this.handleConfirmationDialogClose}
          headingText="Are you sure you want to remove this job function?"
          label="Remove"
          width={'60%'}
          maxWidth={'450px'}
          onClick={this.handleOptionDeletion}
        />
        <ConfirmationDialog
          title="Warning"
          open={isInformationDialogOpened}
          handleClose={this.handleConfirmationDialogClose}
          text={
            <>
              <S.ConfirmationText>At list one Job Function is required.</S.ConfirmationText>
              <S.ConfirmationText>Deleting this Function is not allowed.</S.ConfirmationText>
            </>
          }
          width={'60%'}
          maxWidth={'450px'}
        />
        <WarningDialog
          title="Warning"
          open={isWarningDialogOpened}
          handleClose={this.handleWarningDialogClose}
          labelCancel="Delete and Apply"
          onCancelClick={this.handleOptionDeletion}
          items={jobsSelectList}
          width={'60%'}
          maxWidth={'450px'}
          handleJobReplace={this.handleJobReplace}
          jobToReplace={jobToReplace}
          disabled={isNewJobFunction}
          isNext
          isDisable={isButtonDisable}
          text={
            <>
              <S.ConfirmationText>User(s) are assigned to this Function!</S.ConfirmationText>
              <S.ConfirmationText>
                Please select and assign them to another Function before deleting this one:
              </S.ConfirmationText>
            </>
          }
        />
      </BaseDialog>
    );
  }
}

SetFunctionsDialog.defaultProps = {
  changePersonToEdit: () => {},
  personToEdit: {},
};

const mapStateToProps = ({ people }) => ({
  requestJobsList: people.jobsOptions,
});

const mapDispatchToProps = {
  fetchJobsOptions: peopleActions.getJobsOptionsRequest,
  fetchPeople: peopleActions.peopleListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetFunctionsDialog);
