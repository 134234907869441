import styled from 'styled-components';

import { isSafari } from 'helpers';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${() => (isSafari() ? '50px 30px 175px' : '50px 30px 179px')};
  border-radius: 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: ${({ authMode }) => (authMode === 'signin' ? 1 : 0)};
`;

export const Label = styled.label`
  display: flex;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.primaryDark};
  margin-bottom: 10px;
`;

export const TextInput = styled.input`
  width: fill-available;
  padding: 15px 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: ${({ theme, meta }) => `1px solid ${meta.error && meta.touched ? theme.primaryRed : theme.primaryGrey}`};
  background: none;
  font-size: 16px;
  &::placeholder {
    color: ${({ theme, meta }) => (meta.error && meta.touched ? theme.primaryRed : theme.primaryGrey)};
    line-height: 18px;
    font-weight: 500;
  }
`;

export const FieldContainer = styled.div`
  position: relative;
`;

export const ShowPasswordButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: ${({ theme }) => theme.primaryGreen};
  font-size: 14px;
  position: absolute;
  top: 14px;
  right: 10px;

  /* 
 Hacks for detection only safari browser.
 Move 'show/hide' button left to prevent overlapping with default safari browser 'key icon'  
 */

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      right: 30px;
    }
  }

  /* Safari 10.1 */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
      right: 30px;
    }
  }

  /* Safari 6.1-10.0 (but not 10.1) */
  @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
    @media {
      right: 30px;
    }
  }
`;
