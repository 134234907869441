import styled from 'styled-components';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px;
`;

export const DialogHeader = styled.div`
  text-align: center;
  width: 100%;
  background-color: ${({ theme }) => theme.primaryWhite};
`;

export const GroupName = styled.h1`
  font-size: 18px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const SearchContainer = styled.div`
  margin: 30px 0;
`;

export const RadioGroup = styled(RadioButtonGroup)`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding-left: 50px;

  & > div {
    margin-bottom: 10px;
  }
`;

export const RadioField = styled(RadioButton)``;
