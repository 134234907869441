import styled from 'styled-components';
import FlatButton from 'material-ui/FlatButton';

import mainTheme from 'theme';

export const Button = styled(FlatButton).attrs({
  style: {
    color: mainTheme.primaryWhite,
    backgroundColor: mainTheme.greenButton,
    padding: '4px 16px',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})``;

export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid ${({ theme }) => theme.primaryGrey};
  margin-top: 20px;
  padding-top: 15px;
  & > div {
    width: 50%;
  }
`;

export const checkBoxStyle = {
  style: {
    marginTop: '10px',
  },
  iconStyle: {
    fill: mainTheme.primaryRed,
  },
};

export const textFieldStyles = {
  floatingLabelStyle: {
    color: mainTheme.primaryBlack,
    fontWeight: 'bold',
  },
  floatingLabelShrinkStyle: {
    transform: 'scale(1) translate(0px, -28px)',
  },
};
