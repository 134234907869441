import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'grid-styled';

import { PaperContainer } from 'components/Layout/Containers';

export const iconStyle = {
  color: '#ffffff',
  height: '50px',
  width: '50px',
};

export const Container = styled(Flex).attrs({
  direction: 'column',
  w: [1, 0.9, 0.8],
})`
  margin-bottom: ${({ type }) => (type === 'activator' ? '15px' : '30px')};
  padding: 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const ModulesForUser = styled(Flex).attrs({
  flex: 'none',
  justify: 'space-between',
})`
  flex-wrap: wrap;
  margin: 0 15px;
`;

export const LinksContainer = styled('div')`
  display: flex;
  gap: 20px;

  @media only screen and (max-width: 1045px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const HealthLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  text-align: center;
  margin-bottom: ${({ isAdmin }) => (isAdmin ? '25px' : 0)};
  flex: ${({ isAdmin }) => (isAdmin ? 1 : 'none')};
`;

export const CicleLinkWrapper = styled(Link)`
  margin-right: 10px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  :last-of-type {
    margin-right: 0;
  }

  @media only screen and (max-width: 1045px) {
    flex: 25%;
    margin: 10px;
  }
`;

export const HealthModule = styled(PaperContainer)`
  height: ${({ isAdmin }) => (isAdmin ? 'auto' : '66px')};
  padding: 10px 15px;
  border-radius: 8px !important;
  background-color: ${({ color }) => color} !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HealthLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HealthAlertsNumber = styled.div`
  width: 65px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.redButton};
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: bold;
`;

export const CicleModulePaper = styled(PaperContainer)`
  width: 66px;
  height: 66px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 50% !important;
  background-color: ${({ color }) => color} !important;
  flex-direction: column;
  justify-content: center;
`;

export const HealthLabel = styled(Box)`
  margin: 0 8px 0 30px;
  font-size: 30px;
  font-weight: 200;
  color: ${({ textColor }) => textColor} !important;
`;

export const ModuleLabel = styled(Box)`
  font-size: 20px;
  font-weight: 200;
  color: ${({ theme }) => theme.tenantNameColor};
`;

export const ActivatorLabel = styled(Box)`
  margin-left: 15px;
  font-size: 30px;
  font-weight: 100;
  color: ${({ theme }) => theme.textGray};
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  flex: ${({ isAdmin }) => (isAdmin ? 1 : 'none')};

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const ModulePaper = styled(PaperContainer)`
  padding: 10px 15px;
  border-radius: 8px !important;
  background-color: ${({ color }) => color} !important;
  position: relative;
`;
