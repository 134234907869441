import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { LockIcon, LockOpenIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import LockContainer from './styled';

class LockButton extends PureComponent {
  render() {
    const { item, toggleItemStrictMode } = this.props;
    const { isStrictOrder } = item;

    return (
      <LockContainer>
        {isStrictOrder ? (
          <ButtonIcon tooltip="Lock" onClick={() => toggleItemStrictMode({ ...item, isStrictOrder: !isStrictOrder })}>
            <LockIcon />
          </ButtonIcon>
        ) : (
          <ButtonIcon tooltip="UnLock" onClick={() => toggleItemStrictMode({ ...item, isStrictOrder: !isStrictOrder })}>
            <LockOpenIcon />
          </ButtonIcon>
        )}
      </LockContainer>
    );
  }
}

LockButton.propTypes = {
  item: PropTypes.object.isRequired,
  toggleItemStrictMode: PropTypes.func.isRequired,
};

export default LockButton;
