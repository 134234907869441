import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const InnerCard = ({ title, children }) => (
  <S.Card>
    <S.CardHeader>{title}</S.CardHeader>
    <S.CardContent>{children}</S.CardContent>
  </S.Card>
);

InnerCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default InnerCard;
