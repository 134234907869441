import styled from 'styled-components';
import { BorderRadiusContainer, Column, ScrollableContentContainer } from 'components/Layout/Containers';

export const Block = styled(BorderRadiusContainer)`
  margin: 20px 40px 30px;
`;

export const BlockImage = styled.span`
  display: flex;
  justify-content: flex-end;
  background: ${({ theme }) => theme.lightGrey};
  height: 100%;
  padding: 10px;
`;

export const PhotoContent = styled(ScrollableContentContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const DateBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
    height: calc(80vh - 400px);
    max-height: calc(80vh - 310px);
  }
`;

export const TaskNotes = styled(Column)`
  background: ${({ theme }) => theme.lightGrey};
  padding: 20px;
`;

export const TextValue = styled.span`
  font-size: 16px;
  padding: 5px 10px;
`;
