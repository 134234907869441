import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as tenantsActions } from 'redux/tenants';

import { tenantRequestsToggle } from 'configs/toggles';
import { deletePeriodOptions } from './RemovalDialog/constants';

import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';
import { BackIcon } from 'components/Layout/Icons';
import { ContentContainer, ToolbarContainer } from 'components/Layout/Containers';
import RemoveRequestsDialog from './RemovalDialog';

import NoteDialog from './NoteDialog';
import RequestDetailsDialog from './RequestDetailsDialog';
import RequestsList from './RequestsList';
import { getTenantRequests } from './selectors';
import * as S from './styled';

const requestsInfo = [
  { field: 'NewTenantRequestCount', text: 'Requests:' },
  { field: 'AvailableDemoTenantsCount', text: 'Available:' },
  { field: 'AvailableTenantsCount', text: 'Syst Available:', color: 'lightSteelBlue' },
];

class TenantRequests extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    requestApproveDemoTenant: PropTypes.func.isRequired,
    requestBlockDemoTenant: PropTypes.func.isRequired,
    requestTenantRequestsList: PropTypes.func.isRequired,
    requestTennantIndustryList: PropTypes.func.isRequired,
    requestRemoveDemoTenant: PropTypes.func.isRequired,
    requests: PropTypes.arrayOf(PropTypes.object).isRequired,
    industryList: PropTypes.arrayOf(PropTypes.object).isRequired,
    requestsStatistics: PropTypes.shape({
      NewTenantRequestCount: PropTypes.number,
      AvailableDemoTenantsCount: PropTypes.number,
    }).isRequired,
    requestDeleteSentRequests: PropTypes.func.isRequired,
  };

  state = {
    searchData: '',
    editedRequest: {},
    requestsType: 'new',
    currentRequestDetails: {},
    requestDetailsDialogOpen: false,
    noteDialogOpen: false,
    removeRequestDialogOpen: false,
    currentDeleteOption: deletePeriodOptions[0],
  };

  componentDidMount() {
    this.props.requestTenantRequestsList('new');
    this.props.requestTennantIndustryList();
  }

  handleBack = () => this.props.history.push('/home');

  filterRequests = ({ tenantName, fullName }) =>
    `${tenantName} ${fullName}`.toLowerCase().includes(this.state.searchData.toLowerCase());

  handleSearchRequest = e => this.setState({ searchData: e.target.value });

  requestsTypeHandler = status => {
    this.setState({ requestsType: status.key });
    this.props.requestTenantRequestsList(status.key);
  };

  handleClickRequest = request => {
    const { Name: industryName } = this.props.industryList.find(({ Id }) => Id === request.industryId);

    let verifiedIndustryName = null;
    if (request.verifiedIndustryId) {
      const { Name } = this.props.industryList.find(({ Id }) => Id === request.verifiedIndustryId);
      verifiedIndustryName = Name;
    }

    this.setState({
      currentRequestDetails: { ...request, industryName, verifiedIndustryName },
      requestDetailsDialogOpen: true,
    });
  };

  closeRequestDetailsDialog = () => this.setState({ requestDetailsDialogOpen: false });

  handleEditNote = (e, editedRequest) => {
    e.stopPropagation();
    this.setState({ editedRequest, noteDialogOpen: true });
  };

  onNoteChange = (e, value) => this.setState({ editedRequest: { ...this.state.editedRequest, notes: value } });

  closeNoteDialog = () => this.setState({ noteDialogOpen: false });

  handleUpdateNote = () => {
    this.props.requestBlockDemoTenant({ ...this.state.editedRequest, requestType: this.state.requestsType });
    this.closeNoteDialog();
  };

  openRemovalDialog = () => this.setState({ removeRequestDialogOpen: true });

  closeRemovalDialog = () => this.setState({ removeRequestDialogOpen: false });

  onDeleteOptionChange = e => {
    const { value } = e.target;

    this.setState({ currentDeleteOption: deletePeriodOptions.find(option => option.value === +value) });
  };

  onDeleteSubmit = () => {
    const {
      currentDeleteOption: { value },
    } = this.state;

    this.props.requestDeleteSentRequests(+value);
    this.closeRemovalDialog();
  };

  rightButtons = () => {
    const { requestsType } = this.state;

    if (requestsType === 'sent') {
      return [
        {
          icon: <S.DeleteIcon onClick={this.openRemovalDialog} />,
          disabled: false,
          hint: 'Remove requests',
        },
      ];
    }

    return [];
  };

  actions = [<S.SubmitButton label="Remove Entries" onClick={this.onDeleteSubmit} />];

  render() {
    const {
      searchData,
      requestsType,
      requestDetailsDialogOpen,
      currentRequestDetails,
      noteDialogOpen,
      editedRequest,
      currentDeleteOption,
      removeRequestDialogOpen,
    } = this.state;
    const { requests, requestsStatistics } = this.props;

    return (
      <>
        <ContentContainer>
          <Subheader
            title="New Requests"
            leftButtons={[
              {
                icon: <BackIcon />,
                handler: this.handleBack,
                hint: 'Back',
              },
            ]}
            rightButtons={this.rightButtons()}
            isSearch
            searchData={searchData}
            searchInList={this.handleSearchRequest}
          />
          <ToolbarContainer>
            <Toggle
              config={tenantRequestsToggle}
              selected={tenantRequestsToggle[0].key}
              handler={this.requestsTypeHandler}
            />
            <S.RequestsTopBarInfoContainer>
              {requestsInfo.map(({ field, text, color }) => (
                <Fragment key={text}>
                  {text}
                  <S.RequestsNumberContainer color={color}>{requestsStatistics[field]}</S.RequestsNumberContainer>
                </Fragment>
              ))}
            </S.RequestsTopBarInfoContainer>
          </ToolbarContainer>
          <RequestsList
            requests={requests.filter(this.filterRequests)}
            requestsType={requestsType}
            onItemClick={this.handleClickRequest}
            changeNote={this.handleEditNote}
          />
        </ContentContainer>
        <RequestDetailsDialog
          open={requestDetailsDialogOpen}
          onClose={this.closeRequestDetailsDialog}
          request={currentRequestDetails}
          requestsType={requestsType}
          approveDemoTenant={this.props.requestApproveDemoTenant}
          blockDemoTenant={this.props.requestBlockDemoTenant}
          onRemoveDemoTenant={this.props.requestRemoveDemoTenant}
        />
        <NoteDialog
          open={noteDialogOpen}
          onClose={this.closeNoteDialog}
          note={editedRequest.notes}
          onNoteChange={this.onNoteChange}
          onSaveNote={this.handleUpdateNote}
        />
        <RemoveRequestsDialog
          actions={this.actions}
          open={removeRequestDialogOpen}
          onClose={this.closeRemovalDialog}
          handleDeleteOptionChange={this.onDeleteOptionChange}
          currentDeleteOption={currentDeleteOption}
        />
      </>
    );
  }
}

const mapStateToProps = ({ tenants }) => ({
  requests: getTenantRequests(tenants.requests),
  industryList: tenants.industryList,
  requestsStatistics: tenants.tenantRequestsStatistics,
});

const mapDispatchToProps = {
  requestApproveDemoTenant: tenantsActions.approveDemoTenantRequest,
  requestRemoveDemoTenant: tenantsActions.removeDemoTenantRequest,
  requestBlockDemoTenant: tenantsActions.blockDemoTenantRequest,
  requestTenantRequestsList: tenantsActions.tenantRequestsListRequest,
  requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
  requestDeleteSentRequests: tenantsActions.deleteSentRequestsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantRequests);
