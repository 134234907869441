export const cards = [
  {
    cardTitle: 'Demo Request',
    date: [
      { title: 'Request Date:', field: 'requestDate' },
      { title: 'Time:', field: 'requestTime' },
    ],
    data: [
      { field: 'fullName', title: 'Contact Name:' },
      { field: 'tenantName', title: 'Company:' },
      { field: 'industryName', title: 'Industry:' },
      { field: 'email', title: 'Email:' },
    ],
  },
  {
    cardTitle: 'Verification',
    date: [
      { title: 'Verification Date:', field: 'verificationDate' },
      { title: 'Time:', field: 'verificationTime' },
    ],
    data: [
      { field: 'isVerificationReceived', title: 'Verification Received' },
      { field: 'verifiedIndustryName', title: 'Verified Industry:' },
      { field: 'companySitesNumber', title: 'Verified Site Quantity:' },
    ],
  },
];

export const initialEmail = `Thanks for verifying your sign-up request. You can now log
into the application as an administrator and setup your preferences.`;
