import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

const RadioGroup = ({ input, meta, fullWidth }) => (
  <RadioButtonGroup
    name="MasterUnitFilter"
    defaultSelected={input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={fullWidth}
  >
    <RadioButton value="OEMOrVendor" label="Search for OEM/Vendor" />
    <RadioButton value="UnitName" label="Search for List Template Name" />
    <RadioButton value="ModelIdOrSharp" label="Search for Model ID or #" />
    <RadioButton value="Year" label="Search for Year" />
    <RadioButton value="QROrBarCodeValue" label="Search for (QR/Bar) CodeID" />
    <RadioButton value="Description" label="Search for Description" />
    <RadioButton value="TenantName" label="Search for Tenant" />
    <RadioButton value="IndustryName" label="Search for Industry" />
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  fullWidth: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
