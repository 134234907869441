import moment from 'moment';

export const validator = values => {
  const LiveDate = moment(values.LiveDate).startOf('day');
  const EndDate = moment(values.EndDate);
  const LiveTime = moment(values.LiveTime);
  const EndTime = moment(values.EndTime);

  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (values.LiveDate && values.EndDate) {
    if (EndDate.diff(LiveDate, 'days') < 1) {
      errors.EndDate = 'End Date has to be +1 day or greater from Go Live Date';
    }
  }

  if (!values.EndDate && values.LiveTime && values.EndTime) {
    if (EndTime.diff(LiveTime, 'minutes') < 15) {
      errors.EndTime = 'End Time has to be + 15 min or greater from Go Live Time';
    }
  }

  return errors;
};

export default validator;
