import React from 'react';
import PropTypes from 'prop-types';

import { PickerDate } from 'components/Layout/Pickers';

const DatePickerAdapter = ({ input, meta, ...rest }) => (
  <PickerDate
    {...rest}
    {...input}
    onChange={(event, value) => input.onChange(value)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
  />
);

DatePickerAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default DatePickerAdapter;
