import styled from 'styled-components';

import { DirectionsIcon, InstructionsIcon } from 'components/Layout/Icons';

export const IconDirections = styled(DirectionsIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;

export const IconInstructions = styled(InstructionsIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
})``;
