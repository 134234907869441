import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';
import { TextareaField } from 'components/Layout/Textarea';

export const FormContainer = styled.div`
  margin: 0 20px;
`;

export const CheckboxesContainer = styled.div`
  position: relative;
  margin-bottom: 50px;

  & > div {
    &:nth-child(2) {
      max-width: 93%;
    }
  }
`;

export const InputsContainer = styled.div``;

export const IconButton = styled(ButtonIcon)`
  position: absolute !important;
  right: 0;
  top: 32px;
`;

export const InputContainer = styled.div`
  padding-top: 20px;
`;

export const TextField = styled(TextareaField)`
  margin-bottom: 10px;

  div > textarea {
    border: 1px solid ${({ theme }) => theme.primarySites} !important;
    background-color: ${({ theme }) => theme.lightGrey} !important;
  }
`;
