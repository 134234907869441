import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { SensorLevelColors } from 'theme';

export const Levels = styled(Flex).attrs({
  align: ({ isDown }) => `flex-${isDown ? 'start' : 'end'} !important`,
  justify: 'center !important',
})``;

export const Level = styled.div`
  width: 12px;
  margin-right: 1px;
`;

export const Normal = styled(Level)`
  height: 10px;
  background-color: ${SensorLevelColors.Normal};
`;

export const Elevated = styled(Level)`
  height: 20px;
  background-color: ${SensorLevelColors.Elevated};
`;

export const Watch = styled(Level)`
  height: 30px;
  background-color: ${SensorLevelColors.Watch};
`;

export const Warning = styled(Level)`
  height: 40px;
  background-color: ${SensorLevelColors.Warning};
`;

export const Alert = styled(Level)`
  height: 50px;
  background-color: ${SensorLevelColors.Alert};
`;
