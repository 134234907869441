import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { isIE11 } from 'helpers';
import { ListContainer, ListItemContainer, ContentContainer } from 'components/Layout/Containers';
import { CopyIcon, ExitToAppIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

export const Container = styled(ContentContainer)``;

export const Checklists = styled.div`
  color: ${({ theme }) => theme.primaryScheduler};
`;

export const IconCopy = styled(CopyIcon)`
  min-width: 24px;
`;

export const List = styled(ListContainer)``;

export const ListItem = styled(ListItemContainer)``;

export const RedText = styled.div`
  color: ${({ theme }) => theme.mainRed};
`;

export const StatusText = styled(Flex)`
  padding-right: 10px;
  margin-left: 4px;
`;

export const InfoText = styled(Flex)`
  font-weight: bold;
  font-size: 14px;
  padding-right: 10px;
  white-space: ${isIE11 ? 'pre-wrap' : 'nowrap'};
`;

export const StatusBlock = styled(Flex)`
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin: 0px;
`;

export const ConfirmationTaskTitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 600;
  margin: 0 0 8px 0;
`;

export const ConfirmationBoldText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 600;
`;

export const ConfirmationWarningText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ConfirmationWarning = styled.p`
  font-size: 18px;
`;

export const ConfirmationContainer = styled.div`
  & > div:last-child {
    padding: 0px !important;
    margin-top: 20px;
    & > button:first-child {
      margin-right: 5px !important;
    }

    & > button:last-child {
      margin-left: 5px !important;
    }
  }
`;

export const EmptyList = styled.div`
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  width: 220px;
  color: ${({ theme }) => theme.tenantNameColor};
`;

export const EmptyListText = styled.p``;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0;
  text-align: start;
  width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

export const SectionBody = styled.div`
  padding: 0px 25px;
`;

export const TaskStatusText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 14px;
  margin: 15px 0px;
`;

export const RedTaskStatusText = styled(TaskStatusText)`
  color: ${({ theme }) => theme.mainRed};
`;

export const SiteText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  padding-left: 15px;
`;

export const SiteRow = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 15px 0px;
`;

export const RedSubTitleText = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.mainRed};
  margin: 0px 0px 0px 5px;
`;

export const RedTitleText = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.mainRed};
  font-size: 20px;
  margin: 10px 0px 20px 5px;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const IconButton = styled(ButtonIcon)`
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  transform: translate3d(0, 0, 0) !important;
`;

export const ExitIcon = styled(ExitToAppIcon).attrs({
  color: ({ theme }) => theme.textGray,
})``;
