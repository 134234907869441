import React from 'react';
import { Field } from 'react-final-form';

import { UnitType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import validate from './validator';

import * as S from './styled';

const DuplicateForm = () => (
  <S.FormContainer>
    <Field key="CurrentNameField" component={TextFieldAdapter} disabled floatingLabelText="Current Name" name="Name" />
    <Field key="NewNameField" component={TextFieldAdapter} floatingLabelText="New Name" name="NewName" />
  </S.FormContainer>
);

const DuplicateWithInput = withInputModal(DuplicateForm);

const DuplicateDialog = ({ unitToDuplicate, ...rest }) => (
  <DuplicateWithInput
    {...rest}
    title="Duplicate this Template with all Items & Tasks"
    initialValues={unitToDuplicate}
    validate={validate}
  />
);

DuplicateDialog.defaultProps = {
  unitToDuplicate: { Name: '', NewName: '' },
};

DuplicateDialog.propTypes = {
  unitToDuplicate: UnitType,
};

export default DuplicateDialog;
