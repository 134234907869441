import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import RadioGroup from 'components/Forms/RadioButtonGroup/PdfReportRadioGroup';
import validate from './validator';
import { Block, fullWidth } from './styled';

const PdfReportDetailsForm = ({ settings }) => (
  <Block>
    <Field component={RadioGroup} name="PhotoLimit" fullWidth={fullWidth} settingsValue={settings} />
  </Block>
);

const PdfReportDetailsWithInput = withInputModal(PdfReportDetailsForm);

PdfReportDetailsForm.propTypes = {
  settings: PropTypes.object.isRequired,
};

const PdfReportDetailsDialog = ({ settings, ...rest }) => [
  <PdfReportDetailsWithInput key="PdfReportDetailsWithInput" settings={settings} {...rest} validate={validate} />,
];

PdfReportDetailsDialog.defaultProps = {
  handleDelete: () => {},
  withDelete: false,
  withSubmit: true,
};

PdfReportDetailsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

export default PdfReportDetailsDialog;
