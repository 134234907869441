import React from 'react';
import PropTypes from 'prop-types';
import { USER_TYPES } from 'configs/enums';

import { Container, Button } from './styled';

const Footer = ({ onNext, onPrev, isAssigned, isDisabled, userType }) => {
  const isSystemAdmin = userType === USER_TYPES.SystemAdministrator;
  const prev = isAssigned || isSystemAdmin ? null : 'Claim';
  const next = 'Save/Next';

  return (
    <Container>
      {next && <Button label={next} onClick={onNext} disabled={isDisabled} />}
      {prev && <Button label={prev} onClick={onPrev} />}
    </Container>
  );
};

Footer.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  userType: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default Footer;
