import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Container = styled(Flex).attrs({
  justify: 'center',
})`
  padding: 5px 20px 5px 20px;
  height: 292px;
`;

export const Image = styled.div`
  height: 252px;
  width: 100%;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
`;

export const Message = styled(Flex).attrs({
  align: 'start',
  justify: 'center',
})`
  padding-top: 31px;
  text-align: center;
`;

export const ImageContainer = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  text-align: center;
`;
