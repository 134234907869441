import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_TIER_REQUEST: undefined,
  ADD_TIER_SUCCESS: undefined,
  ADD_TIER_FAILURE: undefined,

  EDIT_TIER_REQUEST: undefined,
  EDIT_TIER_SUCCESS: undefined,
  EDIT_TIER_FAILURE: undefined,

  TIERS_LIST_REQUEST: undefined,
  TIERS_LIST_SUCCESS: undefined,
  TIERS_LIST_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addTierRequest, handlers.addTierRequest],
    [actions.addTierSuccess, handlers.addTierSuccess],
    [actions.addTierFailure, handlers.addTierFailure],

    [actions.editTierRequest, handlers.editTierRequest],
    [actions.editTierSuccess, handlers.editTierSuccess],
    [actions.editTierFailure, handlers.editTierFailure],

    [actions.tiersListRequest, handlers.tiersListRequest],
    [actions.tiersListSuccess, handlers.tiersListSuccess],
    [actions.tiersListFailure, handlers.tiersListFailure],
  ]),
  initialState,
);

export default reducer;
