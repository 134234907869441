import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding-top: 20px;
  margin: 0 20px 20px;
`;
