export const validator = values => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (values.BeaconMinor && (values.BeaconMinor < 0 || values.BeaconMinor > 32767)) {
    errors.BeaconMinor = 'Must be a number from 0 to 32.767';
  }

  return errors;
};

export default validator;
