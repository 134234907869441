import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

export const getSitesList = createSelector(
  user => user.sitesAvailableToUserFullInfo,
  sites => {
    const sitesSorted = sites.map(value => ({
      siteName: value.additionalId ? `${value.additionalId.substring(0, 5)} ${value.name}` : `${value.name}`,
      siteId: value.id,
    }));

    return sortBy(sitesSorted, [site => site.siteName.toLowerCase()]);
  },
);

export const selector = () => {};
