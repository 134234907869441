import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as partsActions } from 'redux/parts';
import { actions as partOptionsActions } from 'redux/partOptions';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { partDetailsToggle } from 'configs/toggles';
import { Form } from 'react-final-form';

import Header from './Header';
import Toggle from 'components/Toggle';
import Card from './Card';
import { ButtonFlat } from 'components/Layout/Buttons';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'components/Layout/Icons';
import EditableListDialog from 'components/Dialogs/EditableListDialog';

import { partCards, editModalValues } from './constants';

import { OptionsType } from 'configs/propTypes';
import validate from './validator';

import * as S from './styled';

class PartDetails extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        partId: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func,
    }).isRequired,
    currentPart: PropTypes.shape({
      OEMPartName: PropTypes.string,
      SupplierPartName: PropTypes.string,
      Id: PropTypes.string,
    }).isRequired,
    getPartById: PropTypes.func.isRequired,
    options: OptionsType.isRequired,
    fetchOptions: PropTypes.func.isRequired,
    fetchOEMs: PropTypes.func.isRequired,
    fetchSuppliers: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
  };

  state = {
    toggleSelected: 'all',
    needMore: false,
    editModalOpen: false,
    editableValue: 'oems',
    openConfirmationDialog: false,
  };

  componentDidMount() {
    const {
      match: {
        params: { partId },
      },
      getPartById,
      fetchSuppliers,
      fetchOEMs,
      fetchOptions,
    } = this.props;

    getPartById({ partId });
    fetchSuppliers();
    fetchOEMs();
    fetchOptions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error && nextProps.error !== this.props.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) return true;
    if (this.props.currentPart.Id === nextProps.currentPart.Id) return false;

    return true;
  }

  toggleHandler = ({ key }) => this.setState({ toggleSelected: key });
  moreHandler = () => this.setState({ needMore: !this.state.needMore });

  openEditModal = editableValue => this.setState({ editableValue, editModalOpen: true });
  closeEditModal = () => this.setState({ editModalOpen: false });

  render() {
    const {
      currentPart,
      history: { goBack },
    } = this.props;
    const { editModalOpen, editableValue, toggleSelected, needMore } = this.state;

    return (
      <>
        <S.Container>
          <Header part={currentPart} goBack={goBack} />
          <S.ToggleContainer>
            <Toggle config={partDetailsToggle} handler={this.toggleHandler} selected={toggleSelected} />
          </S.ToggleContainer>
          <Form
            initialValues={currentPart}
            validate={validate}
            onSubmit={e => e.preventDefault}
            render={({ form, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <S.Content>
                  {this.state.toggleSelected === 'all' ? (
                    <S.Block>
                      <S.BlockHeader>Info</S.BlockHeader>
                      <S.BlockBody>
                        <S.Row>
                          {partCards.map((card, index) => (
                            <Card
                              type={card.titleType}
                              {...card}
                              key={`part_${index}_${card.titleType}`}
                              fieldsType="part"
                              form={form}
                              openEditModal={this.openEditModal}
                            />
                          ))}
                        </S.Row>
                        <S.ToggleContent visible={this.state.needMore}>
                          <S.Row>
                            {partCards.map((card, index) => (
                              <Card
                                type={card.titleType}
                                {...card}
                                key={`morePart_${index}_${card.titleType}`}
                                fieldsType="morePart"
                                form={form}
                              />
                            ))}
                          </S.Row>
                        </S.ToggleContent>
                        <ButtonFlat
                          label={needMore ? 'Less' : 'More'}
                          labelPosition="before"
                          fullWidth
                          icon={needMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          onClick={this.moreHandler}
                        />
                      </S.BlockBody>
                    </S.Block>
                  ) : null}
                  <S.Block>
                    <S.BlockHeader>Attributes</S.BlockHeader>
                    <S.BlockBody>
                      <S.Row>
                        {partCards.map((card, index) => (
                          <Card
                            type={card.titleType}
                            {...card}
                            key={`attributes_${index}_${card.titleType}`}
                            fieldsType="attributes"
                            form={form}
                          />
                        ))}
                      </S.Row>
                    </S.BlockBody>
                  </S.Block>
                </S.Content>
              </form>
            )}
          />
        </S.Container>
        <EditableListDialog
          list={this.props[editableValue]}
          open={editModalOpen}
          searchPlaceholder={editModalValues[editableValue].placeholder}
          title={editModalValues[editableValue].title}
          idTitle={editModalValues[editableValue].deleteId}
          handleClose={this.closeEditModal}
          addItem={this.props[editModalValues[editableValue].addItem]}
          editItem={this.props[editModalValues[editableValue].editItem]}
          deleteItem={this.props[editModalValues[editableValue].deleteItem]}
          openConfirmationDialog={this.props.openConfirmationDialog}
        />
      </>
    );
  }
}

const mapStateToProps = ({ partOptions, parts }) => ({
  currentPart: parts.currentPart,
  options: partOptions.options,
  oems: parts.oemList,
  suppliers: parts.suppliersList,
  error: parts.error,
});

const mapDispatchToProps = {
  getPartById: partsActions.getPartByIdRequest,
  fetchOptions: partOptionsActions.getPartOptionsRequest,
  fetchOEMs: partsActions.getOemListRequest,
  fetchSuppliers: partsActions.getSuppliersListRequest,
  addOemRequest: partsActions.addOemRequest,
  editOemRequest: partsActions.editOemRequest,
  deleteOemRequest: partsActions.deleteOemRequest,
  addSupplierRequest: partsActions.addSupplierRequest,
  editSupplierRequest: partsActions.editSupplierRequest,
  deleteSupplierRequest: partsActions.deleteSupplierRequest,
  openConfirmationDialog,
  openErrorDialog,
};
export default connect(mapStateToProps, mapDispatchToProps)(PartDetails);
