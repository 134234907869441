import styled from 'styled-components';
import { RadioButtonGroup } from 'material-ui/RadioButton';

export const RadioGroup = styled(RadioButtonGroup)`
  margin: 20px;
  display: flex;

  & > div {
    width: 33% !important;
    box-sizing: border-box;
    border-radius: unset;
    position: relative;
    div {
      flex-direction: column;
      align-items: center;
      div {
        margin-right: 0 !important;
      }
      label {
        width: calc(100% - 30px) !important;
      }
    }
  }
`;

export const Styled = () => {};
