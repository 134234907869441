import { call, put, takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';
import { actions as authActions } from 'redux/auth';

import { ERROR_DIALOG_OPENED } from 'redux/errorHandler';

import {
  checkSiteRestrictionsRequest,
  createSiteBasicUser,
  createSiteUsers,
  fetchJobsList,
  fetchOptionsList,
} from 'http/newSites';
import { formatServerError } from 'helpers';

import { actions } from './index';

function* getOptionsList({ payload }) {
  try {
    const { data } = yield call(fetchOptionsList, payload);
    yield put(actions.getOptionsListSuccess(data.root));
  } catch (error) {
    yield put(actions.getOptionsListFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* getJobsList({ payload }) {
  try {
    const { data } = yield call(fetchJobsList, payload);
    yield put(actions.getJobsListSuccess(data.root));
  } catch (error) {
    yield put(actions.getJobsListListFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* checkSiteRestrictions({ payload }) {
  try {
    const { status } = yield call(checkSiteRestrictionsRequest, payload);
    yield put(actions.checkSiteSuccess(status));
  } catch (error) {
    yield put(actions.checkSiteFailure(formatServerError(error, 'An error occurred.')));
  }
}

function* createSite({ payload }) {
  try {
    yield call(createSiteUsers, payload);
    yield put(actions.createSiteSuccess());
    yield put(authActions.fetchUserSitesRequest());
    yield put(actions.checkSiteRequest());
    payload.onSuccess();
  } catch (error) {
    yield put(actions.createSiteFailure(formatServerError(error, 'An error occurred while creating the Site.')));
  }
}

function* addSiteMember({ payload }) {
  try {
    const params = queryString.stringify({
      'dto.Name': payload.valuesToSend.UserName,
      'dto.FirstName': payload.valuesToSend.FirstName,
      'dto.LastName': payload.valuesToSend.LastName,
      'dto.Email': payload.valuesToSend.Email,
      'dto.Phone': payload.valuesToSend.Phone,
      'dto.UserType': payload.valuesToSend.UserType,
      'dto.UserAvailabilityOptionId': payload.valuesToSend.OptionId,
      'dto.JobFunctionID': payload.valuesToSend.JobsId,
    });
    const { data } = yield call(createSiteBasicUser, params);
    yield put(actions.addSiteMemberSuccess(data.entity));
    payload.onAdd(data.entity);
  } catch (error) {
    yield put(actions.addSiteMemberFailure(formatServerError(error, 'An error occurred while adding Team Member.')));
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: {
        title: 'Error',
        message: error.response.data.error,
      },
    });
  }
}

const newSitesSagas = [
  takeEvery(actions.getJobsListRequest, getJobsList),
  takeEvery(actions.getOptionsListRequest, getOptionsList),
  takeEvery(actions.addSiteMemberRequest, addSiteMember),
  takeEvery(actions.createSiteRequest, createSite),
  takeEvery(actions.checkSiteRequest, checkSiteRestrictions),
];

export default newSitesSagas;
