export const initialRule = {
  optionName: '',
  optionConstraint: 0,
  optionValue: '',
  matchOperator: 0,
};

export const createId = (flag = '') => `${flag}${new Date().getTime()}`;

export const changeOptionNameControl = (values, rule) => {
  values[`optionValue-${rule.id}`] = '';
  values[`constraint-${rule.id}`] = '';
  return values;
};

const generateRulesFromArrayOfKeys = (localValues, globalValues) => {
  const optionNames = localValues.filter(item => item.includes('optionName'));
  const optionConstraints = localValues.filter(item => item.includes('constraint'));
  const optionValues = localValues.filter(item => item.includes('optionValue'));
  const matchOperator = localValues.find(item => item.includes('match'));
  const rules = optionNames.map((optionNameKey, index) => {
    const item = {};

    item.OptionName = globalValues[optionNameKey];
    item.OptionConstraint = parseInt(globalValues[optionConstraints[index]], 10);
    item.OptionValue = globalValues[optionValues[index]];
    item.MatchOperator = parseInt(globalValues[matchOperator], 10);

    return item;
  });

  return rules;
};

export const generateItemRules = (values, rules) => {
  const itemValues = Object.keys(values).filter(item => item.includes('item'));
  const updatedItemValues = itemValues
    .map(item => {
      if (item === 'match-item' || rules.find(rule => rule.id === item.split('-')[1])) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);
  updatedItemValues.forEach(item => delete values[item]);
  const fixedItemValues = itemValues.filter(item => !updatedItemValues.find(newItem => newItem === item));
  const data = {
    IndexItemRules: generateRulesFromArrayOfKeys(fixedItemValues, values),
  };
  return data;
};

export const checkOptions = options => {
  if (options.length === 0) {
    return false;
  }
  if (options[0].MatchOperator === -1) {
    return true;
  }
  const res = options.every(item => {
    if (
      !item.OptionName ||
      (!item.OptionValue && item.OptionValue !== 0) ||
      (!item.OptionConstraint && item.OptionConstraint !== 0)
    ) {
      return false;
    }
    return true;
  });
  return res;
};

export const generateInitialRules = ({ rules, flag }) => {
  if (!rules) {
    return {
      name: flag,
      match: 0,
      rules: [{ id: createId(flag), name: flag, ...initialRule }],
    };
  }

  return {
    name: flag,
    match: rules[0].MatchOperator,
    rules: rules.map((item, index) => {
      const tmpId = `${createId(flag)}${index}`;
      const fieldNames = [`optionName-${tmpId}`, `constraint-${tmpId}`, `optionValue-${tmpId}`];
      return {
        id: tmpId,
        name: flag,
        [fieldNames[0]]: item.OptionName,
        [fieldNames[1]]: item.OptionConstraint,
        [fieldNames[2]]: item.OptionValue,
        matchOperator: item.MatchOperator,
      };
    }),
  };
};

export const indexRules = ['item'];
