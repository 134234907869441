import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { RadioButtonGroup } from 'material-ui/RadioButton';

export const RadioGroup = styled(RadioButtonGroup)`
  padding: 20px 0;

  & > div {
    width: 400px !important;
    margin: 0 auto;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    border-radius: unset;
    position: relative;
    div {
      align-items: center;
    }
    label > div {
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 485px) {
    & > div {
      width: 100% !important;
      margin: 0;
    }
  }

  & > div:first-child {
    div {
      align-items: flex-end;
    }
    label > div > div {
      margin-bottom: 12px;
    }
  }

  & > div:nth-child(2) {
    div {
      align-items: flex-start;
    }
    label > div > div {
      margin-top: 12px;
    }
  }
`;

export const Container = styled(Flex)``;

export const LevelsImage = styled.div`
  margin-left: 20px;
`;

export const DashedLine = styled.div`
  width: 100px;
  border: 0.5px dashed ${({ theme }) => theme.primaryGrey};
`;
