import styled from 'styled-components';

export const Container = styled.div`
  min-width: 675px;

  form {
    & > div:last-child {
      padding: 15px !important;

      & > button:first-child {
        margin-right: 10px !important;
      }

      & > button:last-child {
        margin-left: 10px !important;
      }
    }
  }
`;

export const MainTitle = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  margin: 5px auto;
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationArchiveContainer = styled.div`
  & > div:last-child {
    padding: 0 !important;
    margin-top: 20px;
    & > button:first-child {
      margin-right: 5px !important;
    }

    & > button:last-child {
      margin-left: 5px !important;
    }
  }
`;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  padding: 0 20px;
`;

export const LabelsContainer = styled.div`
  display: flex;
  height: 35px;
`;
