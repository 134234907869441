import { Flex } from 'grid-styled';
import styled from 'styled-components';
import { ButtonFlat, ButtonRaised } from 'components/Layout/Buttons';

import { ShowOnTablets } from 'components/Layout/Media';

export const ContentContainer = styled.div``;

export const TaskType = styled.div`
  text-align: center;
  font-size: 20px;
  padding: 35px 0 10px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Notification = styled.div`
  text-align: center;
  font-size: 16px;
  padding: 10px 0 10px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Footer = styled(Flex).attrs({
  justify: 'center',
  p: 2,
})``;

export const Button = styled(ButtonRaised).attrs({
  labelStyle: ({ theme }) => ({
    color: theme.primaryGreen,
    textTransform: 'unset',
  }),
  primary: false,
})`
  width: 50px;
  height: 35px;
  button {
    span {
      font-size: 20px !important;
    }
  }
`;

export const CloseButton = styled(ButtonFlat).attrs({
  label: 'Close',
  primary: true,
})`
  ${ShowOnTablets}
`;
