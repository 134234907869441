import styled from 'styled-components';

export const DialogContainer = styled.div`
  padding: 40px;
  display: flex;
`;

export const FormCardContainer = styled.div`
  width: 32%;
  height: fit-content;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const CardHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 0 0;
  background-color: ${({ theme, titleType }) => (titleType === 'my' ? theme.partDetailsPink : theme.primaryHome)};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  padding: 0 10px 20px 20px;
`;

export const CardContent = styled.div`
  height: 450px;
  padding: 10px;
`;

export const EditButton = styled.button`
  color: ${({ theme }) => theme.modalsContentColor};
  border: 0;
  background: none;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
`;

export const CardButtonContainer = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.primaryGrey}`};
  height: 60px;
  display: flex;
`;

export const CardButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryRed};
  white-space: pre;
  font-size: 15px;
  line-height: 18px;
  width: 50%;

  &:first-child {
    border-right: ${({ theme }) => `1px solid ${theme.primaryGrey}`};
  }
`;
