const CONFIRMATION_DIALOG_OPENED = 'incheq/confirmation/CONFIRMATION_DIALOG_OPENED';
const CONFIRMATION_DIALOG_CLOSED = 'incheq/confirmation/CONFIRMATION_DIALOG_CLOSED';

const INITIAL_STATE = {
  confirmationDialogOpened: false,
  confirmationDialogTitle: null,
  confirmationMessage: '',
  cancelText: 'No',
  confirmText: 'Yes',
  onApprove: () => {},
  confirmButtonTextColor: '',
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case CONFIRMATION_DIALOG_OPENED:
      return {
        ...state,
        confirmationDialogOpened: true,
        confirmationDialogTitle: action.payload.title,
        confirmationMessage: action.payload.message,
        onApprove: action.payload.onApprove,
        cancelText: action.payload.cancelText,
        confirmText: action.payload.confirmText,
        confirmButtonTextColor: action.payload.confirmButtonTextColor,
      };
    case CONFIRMATION_DIALOG_CLOSED:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}

export const openConfirmationDialog = (
  message = '',
  onApprove = () => {},
  title = null,
  cancelText = 'No',
  confirmText = 'Yes',
  confirmButtonTextColor = '',
) => ({
  type: CONFIRMATION_DIALOG_OPENED,
  payload: { message, onApprove, title, cancelText, confirmText, confirmButtonTextColor },
});

export const closeConfirmationDialog = () => ({ type: CONFIRMATION_DIALOG_CLOSED });
