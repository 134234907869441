import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import SearchField from 'components/Layout/Search';
import { FieldText } from 'components/Layout/Inputs';
import { ButtonIcon } from 'components/Layout/Buttons';
import { AddIcon, CheckIcon, DeleteIcon, EditIcon, SearchIcon } from 'components/Layout/Icons';

import * as S from './styled';
import theme from 'theme';

class EditableListDialog extends PureComponent {
  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    open: PropTypes.bool.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    idTitle: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    editItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: '',
  };

  state = {
    fieldsToEdit: [],
    list: this.props.list,
    newItem: '',
    searchData: '',
  };

  componentDidUpdate(prevProps) {
    const { list } = this.props;
    const { searchData, list: stateList } = this.state;
    if (
      (prevProps.list.length === 0 && list.length > 0) ||
      (list.length !== stateList.length && searchData.length === 0) ||
      (this.props.error.length && prevProps.error !== this.props.error)
    ) {
      this.setState({ list });
    }
  }

  onInputChange = (e, id) => {
    this.setState({
      list: this.state.list.map(item => (item.Id === id ? { ...item, Name: e.target.value } : item)),
    });
  };

  editItem = (id, name) => {
    const { editItem } = this.props;
    const { fieldsToEdit, list } = this.state;

    if (fieldsToEdit.includes(id)) {
      const filteredFields = fieldsToEdit.filter(field => field !== id);
      const editedItem = list.find(({ Id }) => Id === id);

      const isValueNotChanged = this.props.list.some(({ Name, Id }) => Id === id && Name === name);
      if (isValueNotChanged) {
        this.setState({ fieldsToEdit: filteredFields });
        return;
      }

      if (editedItem) {
        editItem({ Id: id, Name: editedItem.Name });
      }

      this.setState({ fieldsToEdit: filteredFields });
    } else {
      this.setState({ fieldsToEdit: [...this.state.fieldsToEdit, id] });
    }
  };

  openConfirmationDeleteItem = id => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Item? This cannot be undone!',
      () => this.props.deleteItem({ [this.props.idTitle]: id }),
      'Delete?',
      'No',
      'Yes',
      theme.redButton,
    );
  };

  onNewItemChange = e => this.setState({ newItem: e.target.value });

  addNewItem = () => {
    this.props.addItem({ Name: this.state.newItem });
    this.setState({ newItem: '' });
  };

  onSearch = (list, searchData) => this.setState({ list, searchData });

  onClose = () => {
    this.setState({ searchData: '', list: [], fieldsToEdit: [], newItem: '' });
    this.props.handleClose();
  };

  render() {
    const { open, searchPlaceholder, title } = this.props;
    const { fieldsToEdit, list, newItem, searchData } = this.state;

    return (
      <BaseDialog open={open} title={`Edit ${title}`} onRequestClose={this.onClose}>
        <S.DialogContainer>
          <S.SearchContainer>
            <SearchField
              list={this.props.list}
              filterBy="Name"
              searchData={searchData}
              placeholder={searchPlaceholder}
              icon={<SearchIcon />}
              inputStyle={S.searchInputStyles}
              setSearchedItems={this.onSearch}
            />
          </S.SearchContainer>
          <S.List>
            {list.length > 0 ? (
              list.map(({ Name, Id }) => (
                <S.Item key={Id}>
                  <FieldText
                    value={Name}
                    id={Id}
                    onChange={e => this.onInputChange(e, Id)}
                    underlineShow={fieldsToEdit.includes(Id)}
                    disabled={!fieldsToEdit.includes(Id)}
                    errorText={!Name && 'Name is required!'}
                  />
                  <S.ItemControls>
                    <ButtonIcon disabled={!Name} onClick={() => this.editItem(Id, Name)}>
                      {fieldsToEdit.includes(Id) ? <CheckIcon /> : <EditIcon />}
                    </ButtonIcon>
                    <ButtonIcon onClick={() => this.openConfirmationDeleteItem(Id)}>
                      <DeleteIcon />
                    </ButtonIcon>
                  </S.ItemControls>
                </S.Item>
              ))
            ) : (
              <S.EmptyListContainer>{`There are no ${title}`}</S.EmptyListContainer>
            )}
          </S.List>
        </S.DialogContainer>
        <S.NewItemContainer>
          <FieldText value={newItem} onChange={this.onNewItemChange} hintText={`New ${title.split(' ')[0]} name`} />
          <ButtonIcon disabled={!newItem} onClick={this.addNewItem}>
            <AddIcon />
          </ButtonIcon>
        </S.NewItemContainer>
      </BaseDialog>
    );
  }
}

export default EditableListDialog;
