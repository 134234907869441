import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { UnitItemPictureType, UnitPictureType } from 'configs/propTypes';
import SimpleList from 'components/SimpleList';
import { DeleteIcon, EditIcon, VisibilityIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import ImageSwiper from 'components/ImageSwiper';

import * as S from './styled';

const renderItemContent = photo => photo.Name || 'No name image';

class PhotosBody extends PureComponent {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    getUnitImage: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(PropTypes.oneOfType([UnitItemPictureType, UnitPictureType])).isRequired,
  };

  state = {
    files: [],
    value: '',
    isEditImage: false,
  };

  onDrop = event => {
    this.setState({ files: event.target.files, value: event.target.value });
  };

  upload = () => {
    this.props.onUpload(this.state.files);
    this.setState({ files: [], value: '' });
  };

  goToEdit = () => {
    this.setState({ isEditImage: true });
  };

  goToImages = () => {
    this.setState({ isEditImage: false });
  };

  renderRightControls = photo => (
    <ButtonIcon onClick={() => this.props.onDelete(photo.Id, photo.Name)} tooltip="Delete">
      <DeleteIcon />
    </ButtonIcon>
  );

  render() {
    const { photos, getUnitImage } = this.props;
    const { files, value, isEditImage } = this.state;

    return (
      <>
        <S.FileSection>
          <S.FileSectionHeader>
            <S.IconContainer>
              <S.IconImage />
            </S.IconContainer>
            <S.IconButton>
              {!isEditImage ? <EditIcon onClick={this.goToEdit} /> : <VisibilityIcon onClick={this.goToImages} />}
            </S.IconButton>
          </S.FileSectionHeader>
          <>
            {isEditImage ? (
              <S.ListContainer>
                <SimpleList
                  emptyListMessage="There are no photos, yet."
                  data={photos}
                  renderItemContent={renderItemContent}
                  renderRightControls={this.renderRightControls}
                />
              </S.ListContainer>
            ) : (
              <ImageSwiper
                getImageSource={getUnitImage}
                noPicturesMessage="There are no photos, yet."
                pictures={photos}
              />
            )}
            <S.UploadImageRow>
              <S.InputButton
                accept="image/*"
                files={files}
                id="image"
                onChange={this.onDrop}
                type="file"
                value={value}
              />
              <S.LabelButton htmlFor="image">Choose File</S.LabelButton>
              <S.FileName>{files[0]?.name ? files[0].name : ''}</S.FileName>
              <S.UploadButton label="Upload New Photo" onClick={this.upload} disabled={!value} />
            </S.UploadImageRow>
          </>
        </S.FileSection>
      </>
    );
  }
}

export default PhotosBody;
