export const validator = values => {
  const errors = {};

  if (!values['published-name']) {
    errors['published-name'] = 'Required';
  }

  return errors;
};

export default validator;
