import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const UnsubcribePage = ({ location: { search } }) => {
  const params = new URLSearchParams(search);
  const message = params.get('message');

  return (
    <S.PageContainer>
      <S.Logo />
      <S.Message>{message}</S.Message>
    </S.PageContainer>
  );
};

UnsubcribePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default UnsubcribePage;
