import theme from 'theme';
import { USER_TYPES } from './enums';
import * as I from 'components/Layout/Icons';

export const HOME_INSTRUCTIONS = [
  {
    color: theme.primaryTenantRequests,
    icon: I.TenantsModuleIcon,
    title: 'Requests',
    purpose: 'List all new Tenant requests for evaluation',
    options: [
      {
        icon: I.ListIcon,
        title: 'List incoming requests by date and time',
      },
      {
        icon: I.ContactMailIcon,
        title: 'Show who has been approved and emailed',
      },
      {
        icon: I.VerifiedUserIcon,
        title: 'Show blocked requests / possible spam',
      },
    ],
    tooltipIcon: I.LockIcon,
    tooltip: 'System',
    path: '/tenantrequests',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    color: theme.upgradeRequestsColor,
    icon: I.TenantsModuleIcon,
    title: 'Upgrades',
    purpose: 'List all upgrade requests for evaluation',
    options: [
      {
        icon: I.ListIcon,
        title: 'List external new Tenant upgrade requests',
      },
      {
        icon: I.ListIcon,
        title: 'List current Tenant’s upgrade requests',
      },
      {
        icon: I.TrendingUpIcon,
        title: 'Respond to and approve account limit changes',
      },
    ],
    tooltipIcon: I.LockIcon,
    tooltip: 'System',
    path: '/upgraderequests',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    color: theme.primaryTenants,
    icon: I.TenantsModuleIcon,
    title: 'Tenants',
    purpose: 'Manage Tenant deployment',
    options: [
      {
        icon: I.AddIcon,
        title: 'Set up new Tenants',
      },
      {
        icon: I.PauseIcon,
        title: 'Pause Tenant access',
      },
      {
        icon: I.PeopleModuleIcon,
        title: 'Log in to assist a Tenant',
      },
    ],
    tooltipIcon: I.LockIcon,
    tooltip: 'System',
    path: '/tenants',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    color: theme.primaryExchange,
    icon: I.ExchangeModuleIcon,
    title: 'List X',
    purpose: 'Centralized access and management of checklists for all Tenant sites',
    options: [
      {
        icon: I.ThumbUpIcon,
        title: 'Approve new list submissions',
      },
      {
        icon: I.DeleteIcon,
        title: 'Delete unwanted list versions',
      },
      {
        icon: I.NotesIcon,
        title: 'Manage lists by adding notes',
      },
    ],
    tooltipIcon: I.LockIcon,
    tooltip: 'System',
    path: '/exchange',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    color: theme.primarySettings,
    icon: I.SettingsIcon,
    title: 'Global',
    purpose: 'Set defaults for new Tenant deployments',
    options: [
      {
        icon: I.ImageIcon,
        title: 'Set image and logo defaults',
      },
      {
        icon: I.TextIcon,
        title: 'Enter content defaults',
      },
      {
        icon: I.AssigmentIcon,
        title: 'Manage options defaults',
      },
    ],
    tooltipIcon: I.LockIcon,
    tooltip: 'System',
    path: '/globalsettings',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    color: theme.primarySettings,
    icon: I.TutorialsIcon,
    title: 'Tutorials',
    purpose: 'Setup tutorial videos for all tenants',
    options: [
      {
        icon: I.CreateSectionsIcon,
        title: 'Create Sections for groups of Videos',
      },
      {
        icon: I.LinkIcon,
        title: 'Link Vimeo content with Incheq',
      },
      {
        icon: I.PlayIconFilled,
        title: 'Publish available video’s',
      },
    ],
    tooltipIcon: I.LockIcon,
    tooltip: 'System',
    path: '/tutorials',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    color: theme.primaryHealth,
    icon: I.HealthIcon,
    title: 'Health',
    purpose: 'Global Status for all Locations, Units, Items and personnel',
    options: [
      {
        icon: I.WarningIcon,
        title: 'Show (IOT) active sensor alerts',
      },
      {
        icon: I.AttentionIcon,
        title: 'Show items requiring attention including replacement parts (planned)',
      },
      {
        icon: I.PersonIcon,
        title: 'Show active personnel data (planned)',
      },
    ],
    tooltipIcon: I.GlobalIcon,
    tooltip: 'Global',
    path: '/health',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.DashboardUser],
  },
  {
    color: theme.primarySites,
    icon: I.SitesModuleIcon,
    title: 'Sites',
    purpose: 'Add and edit locations your organization needs to manage',
    options: [
      {
        icon: I.PeopleModuleIcon,
        title: 'Add people',
      },
      {
        icon: I.ExportQRIcon,
        title: 'Print QR codes',
      },
      {
        icon: I.WifiIcon,
        title: 'Set geofence distances',
      },
    ],
    tooltipIcon: I.GlobalIcon,
    tooltip: 'Global',
    path: '/sites',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.OEMAdministrator],
  },
  {
    color: theme.primaryPeople,
    icon: I.PeopleModuleIcon,
    title: 'People',
    purpose: 'Add people who need to perform checklist functions',
    options: [
      {
        icon: I.ListsModuleIcon,
        title: 'View tasks assignments',
      },
      {
        icon: I.PinDropIcon,
        title: 'Provide site access',
      },
      {
        icon: I.SendIcon,
        title: 'Send e-mail access instructions',
      },
    ],
    tooltipIcon: I.GlobalIcon,
    tooltip: 'Global',
    path: '/people',
    userTypes: [
      USER_TYPES.Manager,
      USER_TYPES.SystemAdministrator,
      USER_TYPES.OEMAdministrator,
    ],
  },

  {
    color: theme.mainRed,
    icon: I.OfflineBoltWhite,
    title: 'Shortcuts',
    purpose: 'Automate creation of checklists and schedules',
    options: [
      {
        icon: I.DirectionRunsIcon,
        title: 'Testrun scenarios',
      },
      {
        icon: I.AccessTimeIcon,
        title: 'Set Checklist date range and frequency',
      },
      {
        icon: I.PersonIcon,
        title: 'Assign personnel to scheduled tasks',
      },
    ],
    tooltipIcon: I.GlobalIcon,
    tooltip: 'Global',
    path: '/shortcuts',
    userTypes: [
      USER_TYPES.Manager,
      USER_TYPES.SystemAdministrator,
    ],
  },
  {
    color: theme.primaryParts,
    icon: I.PartsIcon,
    title: 'Parts M',
    purpose: 'Manage physical assets and their replacements',
    options: [
      {
        icon: I.UpDownArrowIcon,
        title: 'Upload new parts through spreadsheets',
      },
      {
        icon: I.LinkIcon,
        title: 'Link parts to items',
      },
      {
        icon: I.QueueModuleIcon,
        title: 'Keep track of replacement requests',
      },
    ],
    tooltipIcon: I.GlobalIcon,
    tooltip: 'Global',
    path: '/parts',
    userTypes: [USER_TYPES.SystemAdministrator],
  },
  {
    color: theme.primarySettings,
    icon: I.SettingsIcon,
    title: 'Settings',
    purpose: 'System wide administration of all settings',
    options: [
      {
        icon: I.SettingsWhitesIcon,
        title: 'Set custom defaults for all sites',
      },
      {
        icon: I.RestoreIcon,
        title: 'Reset parameters to system settings',
      },
      {
        icon: I.TextIcon,
        title: 'Enter e-mail content defaults',
      },
    ],
    tooltipIcon: I.GlobalIcon,
    tooltip: 'Global',
    path: '/settings',
    userTypes: [USER_TYPES.SystemAdministrator],
  },
  {
    color: theme.primaryDocuments,
    icon: I.InstructionsIcon,
    title: 'Docs',
    purpose: ' Create customized instructional content for mobile TaskApp',
    options: [
      {
        icon: I.AddPagesIcon,
        title: 'Add pages to instruct users',
      },
      {
        icon: I.ImageIcon,
        title: 'Add drawings and photo’s',
      },
      {
        icon: I.CreditCardIcon,
        title: 'Customize backgrounds to create sections',
      },
    ],
    tooltipIcon: I.GlobalIcon,
    tooltip: 'Global',
    path: '/documents',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    color: theme.primaryBlue,
    icon: I.TeamsWhite,
    title: 'Teams',
    purpose: 'Group people together',
    options: [
      {
        icon: I.TeamsDark,
        title: 'Allow scheduling of tasks for a whole Team',
      },
      {
        icon: I.ResolvedTaskIcon,
        title: 'Allow Team members to view and pickup tasks for each other',
      },
      {
        icon: I.OfflineBoltDark,
        title: 'Create shortcuts for a whole team',
      },
    ],
    tooltipIcon: I.SitesModuleIcon,
    tooltip: 'Site Specific',
    path: '/teams',
    userTypes: [USER_TYPES.SystemAdministrator],
  },
  {
    color: theme.primaryLists,
    icon: I.ListsModuleIcon,
    title: 'Templates',
    purpose: 'Create or download reusable checklists',
    options: [
      {
        icon: I.CopyIcon,
        title: 'Duplicate and customize List Templates',
      },
      {
        icon: I.LowPriorityIcon,
        title: 'Set sequence for items on a List Template',
      },
      {
        icon: I.UploadInBrowserIcon,
        title: 'Upload List Template to List Exchange to be used at other locations',
      },
    ],
    tooltipIcon: I.SitesModuleIcon,
    tooltip: 'Site Specific',
    path: '/units',
    userTypes: [
      USER_TYPES.Manager,
      USER_TYPES.SystemAdministrator,
      USER_TYPES.OEMAdministrator,
      USER_TYPES.OEMManager,
    ],
  },
  {
    color: theme.primaryParts,
    icon: I.PartsIcon,
    title: 'Parts',
    purpose: 'Manage physical assets and their replacements',
    options: [
      {
        icon: I.LinkIcon,
        title: 'Link Parts to Items',
      },
      {
        icon: I.VisibilityIcon,
        title: 'View Parts and Groups of parts per List Template',
      },
      {
        icon: I.DeleteIcon,
        title: 'Remove Parts or Groups from Items',
      },
    ],
    tooltipIcon: I.SitesModuleIcon,
    tooltip: 'Site Specific',
    path: '/siteparts',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    color: theme.primaryScheduler,
    icon: I.SchedulerModuleIcon,
    title: 'Scheduler',
    purpose: 'Activate and use lists to schedule (recurring) tasks to be performed',
    options: [
      {
        icon: I.CopyIcon,
        title: 'Duplicate scheduled Checklist',
      },
      {
        icon: I.AccessTimeIcon,
        title: 'Set Checklist date range and frequency',
      },
      {
        icon: I.PersonIcon,
        title: 'Assign personnel to scheduled tasks',
      },
    ],
    tooltipIcon: I.SitesModuleIcon,
    tooltip: 'Site Specific',
    path: '/scheduler',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    color: theme.primaryTasks,
    icon: I.TasksModuleIcon,
    title: 'Tasks',
    purpose: 'View assigned tasks per user and resolve issues',
    options: [
      {
        icon: I.PersonIcon,
        title: 'View Checklists by individual assignee',
      },
      {
        icon: I.TasksModuleIcon,
        title: 'View “Today” and “Week” assignments',
      },
      {
        icon: I.ResolvedTaskIcon,
        title: 'Resolve, reassign or release tasks to other users',
      },
    ],
    tooltipIcon: I.SitesModuleIcon,
    tooltip: 'Site Specific',
    path: '/tasks',
    userTypes: [
      USER_TYPES.TaskSpecialist,
      USER_TYPES.Manager,
      USER_TYPES.SystemAdministrator,
    ],
  },
  {
    color: theme.primaryQueue,
    icon: I.QueueModuleIcon,
    title: 'Queue',
    purpose: 'Live progress monitoring and pdf exports of finished work',
    options: [
      {
        icon: I.AccessTimeIcon,
        title: 'View daily and weekly progress per user and per unit',
      },
      {
        icon: I.PdfIcon,
        title: 'Report on completed tasks',
      },
      {
        icon: I.CommitUnitIcon,
        title: 'Archive completed tasks for comprehensive reporting',
      },
    ],
    tooltipIcon: I.SitesModuleIcon,
    tooltip: 'Site Specific',
    path: '/queue',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
];

export const blank = () => {};
