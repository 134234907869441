import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ContentContainer, ListContainer, ListItemContainer } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';

export const Container = styled(ContentContainer)``;

export const List = styled(ListContainer)``;

export const ListItem = styled(ListItemContainer).attrs({
  disabled: true,
})`
  padding: 16px !important;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey} !important;
  justify-content: flex-start;

  & > div:first-child {
    order: 0;
  }
`;

export const ItemTitle = styled.div`
  width: ${({ type }) => (type === 'IoT' ? '93px' : '100px')};
`;

export const ButtonCaption = styled.p`
  font-size: 0.7rem;
  color: #88898c;
  margin-top: 2px;
  overflow-y: auto;
`;

export const IconButton = styled(ButtonIcon)`
  & > div {
    display: inline-block;
  }
`;

export const SecondButton = styled(IconButton)`
  min-width: 60px;
`;

export const RouterLink = styled(Link)`
  height: 48px;
  ${({ customMargin }) => customMargin && { marginRight: customMargin }};
`;
