export const emailValidator = values => {
  const errors = {};

  if (!values.username) {
    errors.username = 'Required';
  }

  return errors;
};

export const newPasswordValidator = values => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  }
  if (values.newPassword && !values.newPassword.match(/^[A-Za-z0-9!@#$%^&*()_+-=.?\s]*$/)) {
    errors.newPassword =
      'Password must contain only english alphabet characters, spaces, ' +
      'numbers or characters from this enum: "! @ # $ % ^ & * ( ) _ + - = . ?"';
  } else if (values.newPassword && values.newPassword.length < 8) {
    errors.newPassword = 'Minimum of 8 characters in length.';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required';
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Password doesn't match";
  }

  return errors;
};
