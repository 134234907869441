import React from 'react';
import PropTypes from 'prop-types';

import Controls from './Controls';
import { Container } from './styled';

const UnitName = ({
  onCommit,
  onExport,
  textColor,
  unitColor,
  unitName,
  withCommit,
  withControls,
  withDelete,
  onDelete,
}) => (
  <Container color={unitColor} textсolor={textColor}>
    {unitName}
    {withControls && (
      <Controls
        onCommit={onCommit}
        onExport={onExport}
        onDelete={onDelete}
        withCommit={withCommit}
        withDelete={withDelete}
      />
    )}
  </Container>
);

UnitName.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
  unitColor: PropTypes.string.isRequired,
  unitName: PropTypes.string.isRequired,
  withCommit: PropTypes.bool.isRequired,
  withControls: PropTypes.bool.isRequired,
  withDelete: PropTypes.bool.isRequired,
};

export default UnitName;
