export const validator = values => {
  const errors = {};
  if (!values.Username) {
    errors.Username = 'Username is required!';
  }
  if (!values.Password) {
    errors.Password = 'Password is Required!';
  }

  return errors;
};

export default validator;
