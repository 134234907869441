import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import { actions as settingsActions } from 'redux/settings';
import withInputModal from 'components/Dialogs/withInputModal';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';
import TextTooltipFieldAdapter from 'components/Forms/TextTooltipFieldAdapter';

import Tooltip from './Tooltip';
import { isIE11 } from 'helpers';
import RadioGroup from './RadioGroup';
import { ResetButton } from 'components/Dialogs/withInputModal/Controls/styled';
import { HelpIcon, RestoreIcon } from 'components/Layout/Icons';
import { defaultWebAddressSelector, defaultQrCodesSelector } from './selectors';

import * as S from './styled';

class ScanDialogForm extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    requestGetMasterSettingsForAdmin: PropTypes.func.isRequired,
    userType: PropTypes.number.isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    initialValues: {},
    values: {},
  };

  resetQR = () => {
    const { form, initialValues, values } = this.props;

    this.props.requestGetMasterSettingsForAdmin();

    form.reset({
      ...values,
      DefaultWebAddress: initialValues.DefaultWebAddress,
      RegenerateDefaultsBarcodes: initialValues.RegenerateDefaultsBarcodes,
      UseGroupKeyForQRCodes: initialValues.UseGroupKeyForQRCodes,
    });
  };

  render() {
    const { form, values, userType } = this.props;

    return (
      <S.FormContainer>
        <S.IconButton
          tooltip={Tooltip}
          tooltipPosition="bottom-left"
          tooltipStyles={{
            width: `${isIE11 ? 'auto' : '110px'}`,
          }}
        >
          <HelpIcon />
        </S.IconButton>
        <Field key="defaultQR" component={RadioGroup} name="UseGroupKeyForQRCodes" form={form} values={values} />
        <TextTooltipFieldAdapter
          name="DefaultWebAddress"
          labelText={values.UseGroupKeyForQRCodes === 'UseUniqueID' ? ' ' : 'Enter default web address'}
          isDisabled={values.UseGroupKeyForQRCodes === 'UseUniqueID'}
        />
        {userType === 4 ? (
          <>
            <ResetButton type="button" label="Reset to System Defaults" position onClick={this.resetQR}>
              <RestoreIcon />
            </ResetButton>
            <Field
              component={CheckboxAdapter}
              name="RegenerateDefaultsBarcodes"
              label="Regenerate All Default QR Codes"
            />
          </>
        ) : null}
      </S.FormContainer>
    );
  }
}

const ScanDialogWithInput = withInputModal(ScanDialogForm);

const ScanDialog = props => (
  <ScanDialogWithInput
    initialValues={{
      DefaultWebAddress: props.DefaultWebAddress,
      UseGroupKeyForQRCodes: props.UseGroupKeyForQRCodes,
    }}
    {...props}
  />
);

ScanDialog.defaultProps = {
  DefaultWebAddress: '',
  UseGroupKeyForQRCodes: 'UseUniqueID',
};

ScanDialog.propTypes = {
  DefaultWebAddress: PropTypes.string,
  UseGroupKeyForQRCodes: PropTypes.string.isRequired,
  userType: PropTypes.number.isRequired,
};

const mapStateToProps = ({ settings: { list, masterSettings }, auth: { user } }) => ({
  DefaultWebAddress: defaultWebAddressSelector(list, masterSettings),
  UseGroupKeyForQRCodes: defaultQrCodesSelector(list, masterSettings),
  userType: user.userType,
});

const mapDispatchToProps = {
  requestGetMasterSettingsForAdmin: settingsActions.getMasterSettingsForAdminRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanDialog);
