import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getLoadingState, getLongLoadingState } from 'redux/app/selectors';

import * as S from './styled';

const KEY_CODES = {
  f1: 112,
  f12: 123,
};

const blockKeyboardEvens = event => {
  if (!event.ctrlKey && !event.altKey && (event.keyCode < KEY_CODES.f1 || event.keyCode > KEY_CODES.f12)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

class AppLoading extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    longLoading: PropTypes.bool,
  };

  static defaultProps = {
    longLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading !== this.props.loading) {
      // next code blocks some keyboard events for document while app is loading data
      // and removes listeners after loading finishes
      if (nextProps.loading) {
        document.addEventListener('keypress', blockKeyboardEvens, true);
        document.addEventListener('keydown', blockKeyboardEvens, true);
      } else {
        document.removeEventListener('keypress', blockKeyboardEvens, true);
        document.removeEventListener('keydown', blockKeyboardEvens, true);
      }
    }
  }

  render() {
    return (
      this.props.loading && (
        <S.Container>
          <S.Progress />
          {this.props.longLoading && (
            <S.LongLoading>Please wait. This operation can take several minutes.</S.LongLoading>
          )}
        </S.Container>
      )
    );
  }
}

const mapStateToProps = state => ({
  loading: getLoadingState(state),
  longLoading: getLongLoadingState(state),
});

export default connect(mapStateToProps)(AppLoading);
