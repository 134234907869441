import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions as appActions } from 'redux/app';

import { VersionType, IndustryType } from 'configs/propTypes';
import { USER_TYPES } from 'configs/enums';

import { actions as tenantsActions } from 'redux/tenants';

import UpgradeInfoDialog from 'components/Dialogs/UpgradeInfoDialog';
import UpgradeInfoSuccessDialog from 'components/Dialogs/UpgradeInfoDialog/UpgradeInfoSuccessDialog';
import { UpgradeIcon } from 'components/Layout/Icons';

import { actions as settingsActions } from 'redux/settings';

import * as S from './styled';

class PageFooter extends Component {
  static propTypes = {
    version: VersionType.isRequired,
    industriesList: PropTypes.arrayOf(IndustryType).isRequired,
    userType: PropTypes.number.isRequired,
    requestFetchCurrentVersion: PropTypes.func.isRequired,
    requestUpgradeInfo: PropTypes.func.isRequired,
    requestTennantIndustryList: PropTypes.func.isRequired,
  };

  state = {
    upgradeInfoOpened: false,
    successDialogOpen: false,
  };

  componentDidMount() {
    this.props.requestFetchCurrentVersion();
    this.props.requestTennantIndustryList();
  }

  openSuccessDialog = () => this.setState({ successDialogOpen: true });
  closeSuccessDialog = () => this.setState({ successDialogOpen: false });

  openUpgradeInfoDialog = () => this.setState({ upgradeInfoOpened: true });
  closeUpgradeInfoDialog = () => this.setState({ upgradeInfoOpened: false });

  submitUpgradeInfoDialog = values => {
    this.props.requestUpgradeInfo({ values, openSuccessDialog: this.openSuccessDialog });
    this.closeUpgradeInfoDialog();
  };

  render() {
    const { VersionNumber, CopyrightText, PrivacyUrl, VersionNotes } = this.props.version;
    const { userType, industriesList } = this.props;
    const { upgradeInfoOpened, successDialogOpen } = this.state;

    const isUpgradeAccountAvalable =
      userType === USER_TYPES.SystemAdministrator || userType === USER_TYPES.OEMAdministrator;

    return (
      <>
        <S.Container>
          <S.Legal>
            <S.CopyrightText>{CopyrightText}</S.CopyrightText>
            <S.PrivacyLink href={PrivacyUrl} target="_blank">
              Privacy Policy
            </S.PrivacyLink>
            {isUpgradeAccountAvalable && (
              <S.UpgradeAccountContainer onClick={this.openUpgradeInfoDialog}>
                <UpgradeIcon />
                Upgrade account
              </S.UpgradeAccountContainer>
            )}
          </S.Legal>
          <S.Version>
            <S.VersionText data-tip data-for="version">
              V{VersionNumber}
            </S.VersionText>
            <S.Tooltip id="version" effect="solid">
              <S.VersionNotes>{VersionNotes}</S.VersionNotes>
            </S.Tooltip>
          </S.Version>
        </S.Container>
        {isUpgradeAccountAvalable && (
          <>
            <UpgradeInfoDialog
              handleClose={this.closeUpgradeInfoDialog}
              open={upgradeInfoOpened}
              title="Upgrade Info"
              onSubmit={this.submitUpgradeInfoDialog}
              BigButtons
              industryList={industriesList}
              submitLabel="SEND REQUEST"
            />
            <UpgradeInfoSuccessDialog open={successDialogOpen} onClose={this.closeSuccessDialog} />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ app, tenants, auth }) => ({
  version: app.version,
  industriesList: tenants.industryList,
  userType: auth.user.userType,
});

const mapDispatchToProps = {
  requestFetchCurrentVersion: appActions.fetchCurrentVersionRequest,
  requestUpgradeInfo: settingsActions.upgradeInfoRequest,
  requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageFooter);
