import styled from 'styled-components';
import Paper from 'material-ui/Paper';

export const Container = styled.div``;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0 40px;
`;

export const Content = styled.div`
  padding: 0 40px;
`;

export const Block = styled(Paper)`
  border-radius: 10px !important;
  overflow: hidden;
  margin-bottom: 40px;
`;

export const BlockHeader = styled.div`
  color: ${({ theme }) => theme.primaryWhite};
  background-color: ${({ theme }) => theme.darkGrey};
  padding: 20px 15px;
  font-weight: 500;
  font-size: 18px;
`;

export const BlockBody = styled.div`
  background-color: ${({ theme }) => theme.primaryWhite};
  min-height: 200px;
  padding: 20px 30px 10px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 30px;
  align-items: start;
`;

export const ToggleContent = styled.div`
  transition: all 0.3s;
  height: ${({ visible }) => (visible ? 'auto' : 0)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;
