import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const RadioGroup = ({ input, meta, style, isDisabledRadioButton }) => (
  <RadioButtonGroup
    name="taskFrequency"
    defaultSelected={input.value.length === 0 ? 1 : input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    <RadioButton
      value={0}
      iconStyle={{ fill: input.value.length === 0 || input.value === 0 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={1}
      disabled={isDisabledRadioButton}
      iconStyle={{ fill: input.value === 1 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={2}
      iconStyle={{ fill: input.value === 2 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  isDisabledRadioButton: PropTypes.bool.isRequired,
};

export default RadioGroup;
