import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'components/Layout/Modals';

import theme from 'theme';

import DialogTitle from './DialogTitle';

import { CancelButton } from './styled';

const BaseDialog = ({
  children,
  withDelete,
  withCloseButton,
  onRequestClose,
  handleDelete,
  defaultCancel,
  defaultCancelText,
  title,
  titleColor,
  modalStyles,
  ...rest
}) => {
  const actions = [];
  if (defaultCancel) {
    actions.push(<CancelButton label={defaultCancelText} onClick={onRequestClose} />);
  }

  return (
    <Dialog
      autoDetectWindowHeight
      autoScrollBodyContent
      onRequestClose={onRequestClose}
      withDelete={withDelete}
      actions={defaultCancel ? actions : undefined}
      modalStyles={modalStyles}
      title={
        <DialogTitle
          title={title}
          titleColor={titleColor}
          withDelete={withDelete}
          withCloseButton={withCloseButton}
          handleClose={onRequestClose}
          handleDelete={handleDelete}
        />
      }
      {...rest}
    >
      {children}
    </Dialog>
  );
};

BaseDialog.defaultProps = {
  defaultCancel: false,
  defaultCancelText: 'Cancel',
  handleDelete: () => {},
  onRequestClose: () => {},
  title: theme.headerColor,
  titleColor: theme.headerColor,
  withDelete: false,
  modalStyles: {},
  withCloseButton: true,
};

BaseDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  defaultCancel: PropTypes.bool,
  defaultCancelText: PropTypes.string,
  titleColor: PropTypes.string,
  handleDelete: PropTypes.func,
  onRequestClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withDelete: PropTypes.bool,
  modalStyles: PropTypes.shape({}),
  withCloseButton: PropTypes.bool,
};

export default BaseDialog;
