import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { isEmpty, isEqual } from 'lodash';
import { actions as statusIndexActions } from 'redux/statusIndex';

import { InfoIcon } from 'components/Layout/Icons';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import TestrunButton from 'components/Buttons/TestrunButton';

import TestrunDialog from './TestrunDialog';
import ItemRules from './ItemRules';
import { createId, initialRule, generateInitialRules, generateItemRules, checkOptions } from './ItemRules/helpers';

import validate from './validator';
import * as S from './styled';

class CharacteristicsRules extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      fetchIndexItemCharacteristicPreviewRequest: PropTypes.func.isRequired,
      fetchIndexItemConfigRequest: PropTypes.func.isRequired,
    }).isRequired,
    onExit: PropTypes.func.isRequired,
    itemRuleToEdit: PropTypes.shape().isRequired,
    setIndexRule: PropTypes.func.isRequired,
    indexRule: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    indexItemPreview: PropTypes.array.isRequired,
    listIndexRule: PropTypes.array.isRequired,
    openErrorNameDialog: PropTypes.func.isRequired,
    dataColumns: PropTypes.array.isRequired,
    indexItemCharacteristicPreview: PropTypes.array.isRequired,
    loadingConfig: PropTypes.bool.isRequired,
  };

  state = {
    archivedTaskTime: { isAllArchivedTasks: true },
    isErrorShown: false,
    isConfirmationDialogOpened: false,
    itemInPair: [],
    activePair: 0,
    previewOpen: false,
    dataCharacteristics: [],
    itemCharacteristicsDataColumns: [],
  };

  componentDidMount() {
    const { actions, indexItemPreview } = this.props;
    indexItemPreview.forEach(item => {
      actions.fetchIndexItemConfigRequest(item.Id);
    });
  }

  componentDidUpdate(prevProps) {
    const { dataColumns } = this.props;
    const { dataCharacteristics } = this.state;
    if (!isEqual(prevProps.dataColumns, dataColumns)) {
      const array = [];

      if (!isEmpty(dataColumns.DataColumns)) {
        this.setState({ dataCharacteristics: [...dataCharacteristics, dataColumns] });
        array.push(...dataCharacteristics, dataColumns);
        this.reloadState(array);
      }
    }
  }

  reloadState = array => {
    this.setState({
      IndexItemCharacteristicRules: generateInitialRules({
        rules:
          this.props.indexRule.IndexItemCharacteristicRules || this.props.itemRuleToEdit?.IndexItemCharacteristicRules,
        indexItemPreview: [...array],
      }),
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onKeyDownHandler = e => {
    const enterButton = 13;
    if (e.keyCode === enterButton) {
      e.preventDefault();
    }
  };

  onChangeHandler = (e, value, name, input) => {
    input.onChange(value);
    this.onChangeMatch(name, value);
  };

  addOption = flag => {
    const currentId = createId(flag);
    this.setState({
      IndexItemCharacteristicRules: [
        ...this.state.IndexItemCharacteristicRules.map(value => {
          if (value.name === flag) {
            value.rules.push({ id: currentId, name: flag, ...initialRule });
          }
          return value;
        }),
      ],
    });
  };

  removeOption = (flag, id) => {
    this.setState({
      IndexItemCharacteristicRules: [
        ...this.state.IndexItemCharacteristicRules.map(item => {
          const rules = item.rules.filter(rule => rule.id !== id);
          return { ...item, rules };
        }),
      ],
    });
  };

  onSubmitForm = values => {
    const { itemRuleToEdit, onNext, setIndexRule, openErrorNameDialog, listIndexRule } = this.props;
    const { IndexItemCharacteristicRules, activePair, dataCharacteristics } = this.state;
    const itemValues = generateItemRules(values, IndexItemCharacteristicRules, dataCharacteristics);
    const rulesToSend = {
      ...itemValues,
    };
    const valuesToSend = {
      Name: values.Name,
      ...rulesToSend,
    };
    let isError = false;
    listIndexRule.forEach(item => {
      if (item.Name === valuesToSend.Name) {
        if (itemRuleToEdit?.Name !== valuesToSend.Name) {
          openErrorNameDialog();
          isError = true;
        }
      }
    });
    if (activePair + 2 < dataCharacteristics.length) {
      if (!checkOptions(valuesToSend.IndexItemCharacteristicRules.slice(activePair, activePair + 2)) || isError) {
        this.setState({ isErrorShown: true });
      } else {
        this.setState({ activePair: activePair + 2, isErrorShown: false });
      }
    } else {
      setIndexRule(valuesToSend);
      if (!checkOptions(valuesToSend.IndexItemCharacteristicRules) || isError) {
        this.setState({ isErrorShown: true });
      } else {
        this.setState({ isErrorShown: false });
        onNext();
      }
    }
  };

  closeModal = () => {
    this.reloadState();
    this.props.onExit();
  };

  openDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: true });
  };

  closeDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  onClickDialogHandler = () => {
    const { onExit } = this.props;
    this.setState({ isConfirmationDialogOpened: false });
    this.reloadState();
    onExit();
  };

  getPreviewResults = values => {
    const { actions, setIndexRule } = this.props;
    const { IndexItemCharacteristicRules, dataCharacteristics, activePair } = this.state;
    const itemValues = generateItemRules(values, IndexItemCharacteristicRules, dataCharacteristics);
    const rulesToSend = {
      ...itemValues,
    };
    const valuesToSend = {
      Name: values.Name,
      ...rulesToSend,
    };
    setIndexRule(valuesToSend);
    if (!checkOptions(valuesToSend.IndexItemCharacteristicRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      actions.fetchIndexItemCharacteristicPreviewRequest({
        IndexItemCharacteristicRules: valuesToSend.IndexItemCharacteristicRules.slice(activePair, activePair + 2),
      });
      this.setState({ previewOpen: true });
    }
  };

  closePreview = () => {
    this.setState({ previewOpen: false });
  };

  previousModal = values => {
    const { onBack, setIndexRule } = this.props;
    const { IndexItemCharacteristicRules, activePair, dataCharacteristics } = this.state;
    if (activePair > 0) {
      this.setState({ activePair: activePair - 2 });
    } else {
      const itemValues = generateItemRules(values, IndexItemCharacteristicRules, dataCharacteristics);
      const rulesToSend = {
        ...itemValues,
      };
      const valuesToSend = {
        Name: values.Name,
        ...rulesToSend,
      };
      setIndexRule(valuesToSend);
      onBack();
    }
  };

  render() {
    const { itemRuleToEdit, indexRule, loadingConfig, indexItemCharacteristicPreview } = this.props;
    const { isErrorShown, isConfirmationDialogOpened, activePair, previewOpen, dataCharacteristics } = this.state;

    return (
      <S.DialogContainer>
        <S.Overlay show={loadingConfig}>
          <S.Loader />
        </S.Overlay>
        <S.FormContainer>
          <Form
            onSubmit={this.onSubmitForm}
            validate={validate}
            initialValues={itemRuleToEdit?.Name}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <S.FieldBlock>
                  <Field
                    component={S.TextField}
                    name="Name"
                    floatingLabelText="Index Rule Name"
                    initialValue={indexRule.Name || itemRuleToEdit?.Name}
                  />
                </S.FieldBlock>
                <S.Icon
                  tooltip="Selections will determine what will be true to trigger the creation of one or more tasks"
                  tooltipStyles={{ width: 120 }}
                  tooltipPosition="bottom-left"
                >
                  <InfoIcon />
                </S.Icon>
                <S.RedInfo> Set up rules to create tasks when something changes in the Index</S.RedInfo>
                {dataCharacteristics.length ? (
                  dataCharacteristics
                    .slice(activePair, activePair + 2)
                    .map((rule, index) => (
                      <ItemRules
                        key={rule.IndexItemId}
                        title={rule.IndexItemName}
                        rules={this.state.IndexItemCharacteristicRules[activePair + index]}
                        firstColumnOptions={Object.keys(dataCharacteristics[activePair + index].DataColumns)}
                        addOption={this.addOption}
                        removeOption={this.removeOption}
                        onChangeMatch={this.onChangeMatch}
                        isInitialValues={!!itemRuleToEdit.Name || !!indexRule.IndexItemCharacteristicRules}
                        values={values}
                        getOptionValue={this.getOptionValue}
                        onKeyDownHandler={this.onKeyDownHandler}
                        onChangeHandler={this.onChangeHandler}
                      />
                    ))
                ) : (
                  <S.EmptyText>This index item has no characteristics.</S.EmptyText>
                )}
                {isErrorShown && <S.ErrorContainer>Please add at least one rule or select</S.ErrorContainer>}
                <TestrunButton isCentered onClick={() => this.getPreviewResults(values)} />
                <ActionsContainer>
                  <ActionButton type="button" label={'Previous'} onClick={() => this.previousModal(values)} />
                  <ActionButton type="submit" isNext label={'Next'} />
                </ActionsContainer>
                <ConfirmationDialog
                  labelCancel="Return"
                  label="Delete"
                  title="Warning"
                  maxWidth={'80%'}
                  text={
                    <S.ConfirmationContainer>
                      <S.ConfirmationWarning>
                        Are you sure you want to delete?
                        <br /> All data will be removed
                      </S.ConfirmationWarning>
                    </S.ConfirmationContainer>
                  }
                  open={isConfirmationDialogOpened}
                  onClick={this.onClickDialogHandler}
                  handleClose={this.closeDeleteDialog}
                  onCancelClick={this.closeDeleteDialog}
                  isCancelNext
                />
                <TestrunDialog
                  open={previewOpen}
                  handleClose={this.closePreview}
                  data={indexItemCharacteristicPreview}
                />
              </form>
            )}
          />
        </S.FormContainer>
      </S.DialogContainer>
    );
  }
}

const mapStateToProps = ({
  statusIndex: { indexItemPreview, indexItemCharacteristicPreview, dataColumns, loadingConfig },
}) => ({
  indexItemPreview,
  indexItemCharacteristicPreview,
  dataColumns,
  loadingConfig,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CharacteristicsRules);
