import { actions as listTemplatesActions } from 'redux/listTemplates';

const mapStateToProps = ({ listTemplates: { assetCategory, assetTypeList } }) => ({
  assetCategory,
  assetTypeList,
});

const mapDispatchToProps = {
  ...listTemplatesActions,
};

export { mapStateToProps, mapDispatchToProps };
