import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SiteSelector from 'components/SiteSelector';
import SiteSelectionDialog from 'components/Dialogs/SiteSelectionDialog';

import { QUEUE_MODULES } from 'configs/routes';

import { actions as authActions } from 'redux/auth';
import { actions as queueActions } from 'redux/queue';

import theme from 'theme';

import { getSiteStatistics } from './selectors';
import { ATTENTION_MODULE } from './helpers';
import * as S from './styled';

class QueueDashboard extends PureComponent {
  static propTypes = {
    action: PropTypes.shape({
      fetchSites: PropTypes.func.isRequired,
      fetchStatistics: PropTypes.func.isRequired,
      fetchAttentionTasks: PropTypes.func.isRequired,
      changeSelectedSite: PropTypes.func.isRequired,
      changeSite: PropTypes.func.isRequired,
    }).isRequired,
    attentionTasks: PropTypes.number,
    tenantName: PropTypes.string,
    siteName: PropTypes.string.isRequired,
    siteStatistics: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    selectedSite: PropTypes.string.isRequired,
    sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
    userType: PropTypes.number.isRequired,
  };

  static defaultProps = {
    tenantName: '',
    siteStatistics: null,
    attentionTasks: null,
  };

  state = {
    isSiteSelectionDialogOpen: false,
  };

  componentDidMount() {
    const { action, selectedSite, location } = this.props;

    action.fetchSites();
    action.fetchStatistics(selectedSite);
    action.fetchAttentionTasks(selectedSite);

    const params = new URLSearchParams(location.search);
    const siteId = params.get('siteId');
    if (siteId) {
      action.changeSelectedSite({ siteId });
    }
  }

  componentDidUpdate(prevprops) {
    const { action, selectedSite } = this.props;
    if (prevprops.selectedSite !== selectedSite) {
      action.fetchStatistics(selectedSite);
      action.fetchAttentionTasks(selectedSite);
    }
  }

  changeQueueSite = (checkedSite, sitesCollection) => {
    const { action } = this.props;
    action.changeSite({ checkedSite, sitesCollection });
  };

  openSiteSelectionDialog = () => this.setState({ isSiteSelectionDialogOpen: true });

  closeSiteSelectionDialog = () => this.setState({ isSiteSelectionDialogOpen: false });

  checkCircleColor = (label, value) => {
    if (label === 'attention') {
      return theme.primaryRed;
    } else if (label === 'past due' && value > 0) {
      return theme.tertiaryOrange;
    }
    return theme.primaryQueue;
  };

  render() {
    const { attentionTasks, tenantName, siteName, siteStatistics, sitesAvailableToUserFullInfo, userType } = this.props;
    const { isSiteSelectionDialogOpen } = this.state;

    return (
      <S.Container>
        <S.DashboardContainer>
          <S.Header>{tenantName}</S.Header>
          <S.ColumnContainer>
            {!!sitesAvailableToUserFullInfo.length && (
              <SiteSelector onClick={this.openSiteSelectionDialog} siteName={siteName} />
            )}
            <SiteSelectionDialog isOpen={isSiteSelectionDialogOpen} onClose={this.closeSiteSelectionDialog} />
            <S.StatisticsContainer>
              <S.StaticticsHeader>What is happening today ?</S.StaticticsHeader>
              <S.StatisticsModules>
                {!!siteStatistics.length &&
                  siteStatistics.map(({ value, label }) => (
                    <S.StatisticsModuleContainer key={label}>
                      <S.StatisticsValue color={this.checkCircleColor(label, value)}>{value}</S.StatisticsValue>
                      <S.StatisticsLabel>{label}</S.StatisticsLabel>
                    </S.StatisticsModuleContainer>
                  ))}
              </S.StatisticsModules>
            </S.StatisticsContainer>
            {Object.values(QUEUE_MODULES[userType]).map(module => (
              <S.LinkWrapper key={module.name} to={module.path}>
                <S.ModulePaper>
                  {module.name === ATTENTION_MODULE && (
                    <S.TasksNumberContainer>{attentionTasks}</S.TasksNumberContainer>
                  )}
                  <S.ModuleIconContainer>
                    <module.icon />
                  </S.ModuleIconContainer>
                  <S.ModuleLabel>{module.name}</S.ModuleLabel>
                </S.ModulePaper>
              </S.LinkWrapper>
            ))}
          </S.ColumnContainer>
        </S.DashboardContainer>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ auth, queue }) => ({
  attentionTasks: queue.attentionTasks,
  tenantName: auth.user.tenantName,
  selectedSite: auth.selectedSite,
  siteName: auth.siteName,
  siteStatistics: getSiteStatistics(queue.currentSiteStatistics),
  sitesAvailableToUserFullInfo: auth.user.sitesAvailableToUserFullInfo,
  userType: auth.user.userType,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      fetchSites: authActions.fetchUserSitesRequest,
      fetchStatistics: queueActions.fetchSiteStatisticsRequest,
      fetchAttentionTasks: queueActions.fetchAttentionTasksRequest,
      changeSelectedSite: authActions.selectedSiteChange,
      changeSite: authActions.onSiteChange,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueueDashboard);
