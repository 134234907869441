import styled from 'styled-components';
import { Box } from 'grid-styled';

export const UnitList = styled(Box).attrs({
  column: true,
})`
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  button {
    height: 100% !important;
  }

  div {
    opacity: 0.99;
  }

  div:first-child {
    border-radius: 4px 4px 0 0;
  }

  div:last-child {
    span {
      border-bottom: none !important;
      border-radius: 0 0 4px 4px;
    }
  }
`;

export const List = styled(Box).attrs({
  p: 2,
  pb: 1,
})``;
