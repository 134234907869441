import styled from 'styled-components';

import { BorderRadiusContainer } from 'components/Layout/Containers';

export const Container = styled.div`
  padding: 20px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.primaryRed};
  font-weight: 300;
  font-size: 22px;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const RedText = styled.p`
  color: ${({ theme }) => theme.primaryRed};
`;

export const SectionContainer = styled(BorderRadiusContainer)`
  margin: 15px 0 20px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
`;

export const SectionContent = styled.div`
  padding: 15px;
`;

export const SiteContainer = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  & svg {
    margin-right: 15px;
    fill: ${({ theme }) => theme.primaryGrey} !important;
    width: 30px !important;
    height: 30px !important;
  }
`;

export const DeleteButton = styled.button`
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.secondaryRed};
  color: ${({ theme }) => theme.primaryRed};
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
  padding: 10px 0;
  cursor: pointer;
`;

export const ConfirmationTitle = styled.h1`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 17px;
  margin: 0 0 10px;
`;
