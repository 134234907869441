import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import CheckboxList from 'components/CheckboxList';
import * as S from 'components/Dialogs/styled';

import { actions as appActions } from 'redux/app';
import { actions as sitesActions } from 'redux/sites';

import SetTimezoneDialog from './SetTimezoneDialog';
import { addTimezonesToSites } from './helpers';
import { getSitesList } from './selectors';

class SetTimezonesDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    selectedSite: PropTypes.string.isRequired,
    sitesList: PropTypes.arrayOf(
      PropTypes.shape({
        SiteId: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
        TimeZoneIanaId: PropTypes.string,
        offset: PropTypes.string,
      }),
    ).isRequired,
    handleClose: PropTypes.func.isRequired,
    updateCurrentModule: PropTypes.func.isRequired,
    multipleEdit: PropTypes.func.isRequired,
  };

  state = {
    sites: [],
    sitesForSearch: [],
    checkedSites: [],
    searchData: '',
    searchTimezone: '',
    timezoneDialogOpen: false,
    selectedTimezoneValue: '',
    currentSite: {},
    siteTimeZoneSettings: [],
    isSelectTimezone: true,
  };

  componentDidMount() {
    this.props.updateCurrentModule('globalsettings');
  }

  componentDidUpdate(prevProps, prevState) {
    const { sitesList } = this.props;
    const { searchData } = this.state;

    if (sitesList && sitesList.length > 0 && prevState.sites.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sites: sitesList, sitesForSearch: sitesList });
    }
  }

  clearCheckedSites = () => this.setState({ checkedSites: [] });
  closeSetTimezoneDialog = () =>
    this.setState({
      timezoneDialogOpen: false,
      selectedTimezoneValue: '',
      isSelectTimezone: true,
      searchTimezone: '',
    });
  fillCheckedSites = checkedSites => this.setState({ checkedSites });
  openSetTimezoneDialog = currentSite =>
    this.setState({ timezoneDialogOpen: true, currentSite, selectedTimezoneValue: currentSite.TimeZoneIanaId });
  selectTimezone = selectedTimezoneValue => this.setState({ selectedTimezoneValue, isSelectTimezone: true });
  setSearchedSites = (sites, searchData) => this.setState({ sites, searchData });
  closeSelectTimezone = () => this.setState({ isSelectTimezone: false });
  setSearchTimezone = value => this.setState({ searchTimezone: value });

  closeModal = () => {
    this.setState({ siteTimeZoneSettings: [], sites: [], sitesForSearch: [], searchData: '', searchTimezone: '' });
    this.clearCheckedSites();
    this.props.handleClose();
  };

  applyToCheckedSelections = offset => {
    const {
      currentSite,
      checkedSites,
      sites,
      selectedTimezoneValue,
      sitesForSearch,
      siteTimeZoneSettings: oldIds,
    } = this.state;

    const timezone = selectedTimezoneValue || currentSite.TimeZoneIanaId;
    const updatedCurrentSites = addTimezonesToSites(sites, checkedSites, timezone, offset);
    const updatedSitesForSearch = addTimezonesToSites(sitesForSearch, checkedSites, timezone, offset);

    let applyData = [...oldIds];

    checkedSites.map(SiteId => {
      applyData = applyData.filter(item => item.SiteId !== SiteId);
      applyData.push({ SiteId, TimeZoneIanaId: timezone });

      return SiteId;
    });

    this.setState({
      siteTimeZoneSettings: applyData,
      checkedSites: [],
      isSelectTimezone: true,
      sites: updatedCurrentSites,
      sitesForSearch: updatedSitesForSearch,
    });
    this.closeSetTimezoneDialog();
  };

  applyToCurrentSite = offset => {
    const { currentSite, sites, selectedTimezoneValue, sitesForSearch, siteTimeZoneSettings: oldIds } = this.state;

    const updatedCurrentSites = addTimezonesToSites(sites, [currentSite.SiteId], selectedTimezoneValue, offset);
    const updatedSearchSites = addTimezonesToSites(sitesForSearch, [currentSite.SiteId], selectedTimezoneValue, offset);

    const applyData = { SiteId: currentSite.SiteId, TimeZoneIanaId: selectedTimezoneValue };
    const siteTimeZoneSettings = [...oldIds.filter(oldId => oldId.SiteId !== currentSite.SiteId), applyData];

    this.setState({
      siteTimeZoneSettings,
      checkedSites: [],
      isSelectTimezone: true,
      sites: updatedCurrentSites,
      sitesForSearch: updatedSearchSites,
    });
    this.closeSetTimezoneDialog();
  };

  saveTimezones = () => {
    const { siteTimeZoneSettings } = this.state;
    const { multipleEdit, selectedSite } = this.props;

    if (siteTimeZoneSettings.length === 0) return;

    multipleEdit({
      selectedSite,
      requestBody: { settings: siteTimeZoneSettings, setingsName: 'siteTimeZoneSettings' },
      requestName: 'SetTimeZone',
    });
    this.closeModal();
  };

  render() {
    const { open, title } = this.props;
    const {
      sites,
      checkedSites,
      searchData,
      timezoneDialogOpen,
      currentSite,
      selectedTimezoneValue,
      sitesForSearch,
      siteTimeZoneSettings,
      isSelectTimezone,
      searchTimezone,
    } = this.state;

    const disableSaving = siteTimeZoneSettings.length === 0;

    return (
      <>
        <BaseDialog open={open} onRequestClose={this.closeModal} title={title}>
          <S.DialogContainer>
            <S.SearchContainer>
              <SearchField
                list={sitesForSearch}
                filterBy="Name"
                searchData={searchData}
                placeholder="filter Sites"
                underlineShow={false}
                icon={<SearchIcon />}
                setSearchedItems={this.setSearchedSites}
              />
            </S.SearchContainer>
            <S.ListContainer>
              <CheckboxList
                list={sites}
                checkedSites={checkedSites}
                onItemClick={this.openSetTimezoneDialog}
                clearCheckedSites={this.clearCheckedSites}
                fillCheckedSites={this.fillCheckedSites}
                displayValue="offset"
              />
            </S.ListContainer>
            <S.SaveButtonContainer onClick={this.saveTimezones}>
              <S.SaveButton disabled={disableSaving} />
            </S.SaveButtonContainer>
          </S.DialogContainer>
        </BaseDialog>
        <SetTimezoneDialog
          open={timezoneDialogOpen}
          title="Set Time Zone"
          handleClose={this.closeSetTimezoneDialog}
          currentSite={currentSite}
          checkedSitesNumber={checkedSites.length}
          selectedTimezoneValue={selectedTimezoneValue}
          applyToCheckedSelections={this.applyToCheckedSelections}
          applyToCurrentSite={this.applyToCurrentSite}
          selectTimezone={this.selectTimezone}
          closeSelectTimezone={this.closeSelectTimezone}
          isSelectTimezone={isSelectTimezone}
          searchTimezone={searchTimezone}
          setSearchTimezone={this.setSearchTimezone}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, sites }) => ({
  selectedSite: auth.selectedSite,
  sitesList: getSitesList(sites.list),
});

const mapDispatchToProps = {
  updateCurrentModule: appActions.updateCurrentModule,
  multipleEdit: sitesActions.multipleSiteEditRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetTimezonesDialog);
