import styled from 'styled-components';
import { ProgressCircular } from 'components/Layout/Progresses';
import { InputBlockLabel } from 'components/Layout/Containers';
import { SitesModuleIcon } from 'components/Layout/Icons';
import { DividerLine } from 'components/Dialogs/styled';

export const DialogContainer = styled.div`
  padding: 40px 40px 0;
  text-align: center;
`;

export const Loader = styled(ProgressCircular)`
  circle {
    stroke: ${({ theme }) => `${theme.primary} !important`};
  }
`;

export const Overlay = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.overlay};
  z-index: 2000;
`;

export const SubInfo = styled.div`
  padding-bottom: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.mainRed};
`;

export const TopInfo = styled.div`
  text-align: center;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
  padding: 15px;
  display: flex;
`;

export const SitesIcon = styled(SitesModuleIcon)`
  color: ${({ theme }) => theme.primaryWhite} !important;
  flex: 1;
`;

export const ListContainer = styled.div`
  padding: 30px 0 30px;
`;

export const Hr = styled(DividerLine)`
  margin: 0 30px;
`;

export const SearchContainer = styled.div`
  padding-top: 20px;
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin-top: 20px;
`;

export const TitleContainer = styled.div`
  display: grid;
  margin: 0 60px 0 100px;
  grid-template-columns: 30% 70%;
  margin-bottom: 10px;
  justify-items: start;
`;

export const Title = styled.b`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryBlack};
`;
