import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const Legal = styled.div`
  display: flex;
  padding-left: 78px;
  width: 80%;

  @media only screen and (max-width: 425px) {
    padding-left: 40px;
  }
`;

export const Version = styled.div`
  padding: 0 78px;

  @media only screen and (max-width: 425px) {
    padding: 0 40px;
  }
`;

export const VersionText = styled.span``;

export const VersionNotes = styled.div`
  white-space: pre-line;
  width: 300px;
`;

export const CopyrightText = styled.span``;

export const PrivacyLink = styled.a`
  margin: 0 44px;
  color: ${({ theme }) => theme.primaryBlack};
  text-decoration: underline;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    margin: 0 16px;
  }
`;

export const UpgradeAccountContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 150px;
`;

export const Tooltip = styled(ReactTooltip)`
  &.type-dark.place-top {
    background-color: ${({ theme }) => theme.primaryWhite};
    color: ${({ theme }) => theme.primaryBlack};
    border-radius: 10px;
    padding: 20px 150px 30px 20px;

    &::after {
      border-top-color: ${({ theme }) => theme.primaryWhite} !important;
      left: 50% !important;
    }
  }
`;
