// set padding depending on buttons is Subheader
export const padding = (left, right) => {
  if (left && left.length && right && right.length) {
    // both buttons
    return 0;
  }
  if (left && left.length && !(right && right.length)) {
    // only left buttons
    return '170px';
  }
  if (!(left && left.length) && right && right.length) {
    // only right buttons
    return '30px';
  }
  // none buttons
  return '90px';
};

export const findSite = (sitesArray, selectedSite) => {
  const site = sitesArray.find(siteObject => siteObject.id === selectedSite);
  if (site.additionalId) {
    return `${site.additionalId.substring(0, 5)} ${site.name}`;
  }
  return `${site.name}`;
};
