import React from 'react';
import PropTypes from 'prop-types';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const NewVersion = ({ onNext, onBack }) => (
  <S.DialogContainer>
    <S.TopInfo>
      <S.Title>New Version!</S.Title>
    </S.TopInfo>
    <S.Text>An older version of this list is already present.</S.Text>
    <S.BoldText>Update or Abort?</S.BoldText>
    <ActionsContainer>
      <ActionButton
        label="Previous"
        isNext
        onClick={() => {
          onBack();
        }}
      />
      <S.Space />
      <ActionButton
        label="PROCEED"
        isNext
        onClick={() => {
          onNext();
        }}
      />
    </ActionsContainer>
  </S.DialogContainer>
);

NewVersion.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default NewVersion;
