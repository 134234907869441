import React from 'react';
import PropTypes from 'prop-types';

import { COLUMN_MODULES } from 'configs/routes';
import { rowButtons } from './constants';
import * as S from './styled';

const QUEUE_MODULE = 'Queue';
const NEW_TENANT_REQUEST_MODULE = 'New Tenant Requests';
const UPGRADE_REQUEST_MODULE = 'Upgrade Requests';

const RowModules = (userType, firstItemName, secondItemName) => (
  <S.RowContainer
    isParts={Object.values(COLUMN_MODULES[userType]).includes(
      Object.values(COLUMN_MODULES[userType]).find(el => el.name === 'Parts'),
    )}
  >
    {Object.values(COLUMN_MODULES[userType]).map(
      item =>
        (item.name === firstItemName || item.name === secondItemName) && (
          <S.RowLinkWrapper
            isGlobalSettings={item.name === 'Global Settings'}
            margin={item.name}
            key={item.name}
            to={item.path}
          >
            <S.ModulePaper color={item.color}>
              <item.icon style={S.iconStyle} />
              <S.ModuleLabel>{item.name}</S.ModuleLabel>
            </S.ModulePaper>
          </S.RowLinkWrapper>
        ),
    )}
  </S.RowContainer>
);

// eslint-disable-next-line consistent-return
const CheckColumnModules = (userType, attentionTasks, tenantRequestsStatistics, item) => {
  if (!rowButtons.includes(item.name)) {
    return (
      <S.LinkWrapper key={item.name} to={item.path}>
        <S.ModulePaper color={item.color}>
          {item.name === QUEUE_MODULE && <S.TasksNumberContainer>{attentionTasks}</S.TasksNumberContainer>}
          <item.icon style={S.iconStyle} />
          <S.ModuleLabel>{item.name}</S.ModuleLabel>
          {item.name === NEW_TENANT_REQUEST_MODULE && (
            <S.RequestsNumberContainer>{tenantRequestsStatistics.NewTenantRequestCount}</S.RequestsNumberContainer>
          )}
          {item.name === UPGRADE_REQUEST_MODULE && (
            <S.RequestsNumberContainer>{tenantRequestsStatistics.UpgradeTenantRequestCount}</S.RequestsNumberContainer>
          )}
        </S.ModulePaper>
      </S.LinkWrapper>
    );
  } else if (item.name === 'List Templates') {
    return RowModules(userType, 'List Templates', 'Parts');
  } else if (item.name === 'Global Settings') {
    return RowModules(userType, 'Global Settings', 'Tutorials');
  }
};

const ColumnModules = ({ userType, attentionTasks, tenantRequestsStatistics }) =>
  Object.values(COLUMN_MODULES[userType]).map(item => (
    <React.Fragment key={item.name}>
      {CheckColumnModules(userType, attentionTasks, tenantRequestsStatistics, item)}
    </React.Fragment>
  ));
ColumnModules.propTypes = {
  userType: PropTypes.number.isRequired,
  attentionTasks: PropTypes.number,
  tenantRequestsStatistics: PropTypes.shape({
    NewTenantRequestCount: PropTypes.number,
    UpgradeTenantRequestCount: PropTypes.number,
  }).isRequired,
};

ColumnModules.defaultProps = {
  attentionTasks: 0,
};

export default ColumnModules;
