import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';

export const DialogContainer = styled.div`
  padding: 20px;
`;

export const DialogText = styled.p`
  color: ${({ theme }) => theme.modalsContentColor};
  text-align: center;
  margin-bottom: 30px;
`;

export const OptionsGroupsContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  column-count: 2;
  text-align: center;
`;

export const OptionCardContainer = styled.li`
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  margin-bottom: 20px;
  display: inline-block;
  width: 95%;
  break-inside: avoid-column;
  text-align: start;
`;

export const OptionCardHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px 15px 0 0;
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  padding: 0 20px;
`;

export const EditOptionsButton = styled(ButtonIcon)`
  & svg {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const OptionsList = styled.ul`
  list-style: none;
  padding: 20px;
`;

export const Option = styled.li`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const EmptyOptions = styled.p`
  color: ${({ theme }) => theme.modalsContentColor};
  text-align: center;
`;
