const initialState = {
  error: '',
  isLoading: false,
  list: [],
  meeteringGroup: {},
};

export const getGroupsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getGroupsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: '',
  list: payload,
});

export const getGroupsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getGroupSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: '',
  meeteringGroup: payload,
});

export const getGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addGroupSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const addGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editGroupSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const editGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addIndustryToGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addIndustryToGroupSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const addIndustryToGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: true,
});

export const deleteGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteGroupSuccess = state => ({
  ...state,
  isLoading: false,
  error: '',
});

export const deleteGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
