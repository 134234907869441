import styled from 'styled-components';

import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

const getLabelFocusStyle = (meta, theme) => ({
  color: meta.error && meta.touched ? theme.primaryRed : 'rgba(0, 0, 0, 0.3)',
});

export const TextareaField = styled(TextFieldAdapter).attrs({
  multiLine: true,
  underlineShow: false,
  floatingLabelShrinkStyle: {
    top: 13,
    left: 15,
    backgroundColor: 'white',
    padding: '0 10px',
    borderRadius: '5px',
  },
  floatingLabelStyle: {
    top: 10,
    left: 10,
  },
  floatingLabelFocusStyle: ({ meta, theme }) => getLabelFocusStyle(meta, theme),
})`
  margin-bottom: 20px;
  height: auto !important;

  div > textarea {
    min-height: ${({ rows }) => `${rows * 24 + 20}px`} !important;
    margin: 0 !important;
    padding: 10px !important;
    border: 1px solid
      ${({ meta, theme }) =>
        meta.error && (meta.touched || meta.visited) ? theme.primaryRed : theme.fieldsBorderColor} !important;
    border-radius: 10px;
    overflow-y: hidden;
  }
`;
