import styled from 'styled-components';
import { Row, BorderRadiusContainer, ScrollableContentContainer } from 'components/Layout/Containers';

export const BorderRadiusBlock = styled(BorderRadiusContainer)`
  width: 99%;
  margin: 10px auto;
`;

export const ColoredTitle = styled.div`
  display: block;
  background: ${({ theme }) => theme.primaryTasks};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 11px;
  font-weight: 500;
  padding: 10px;
  text-transform: uppercase;
`;

export const Container = styled(ScrollableContentContainer)`
  display: flex;
  flex-direction: column;
  margin: 15px 20px 20px;
`;

export const Content = styled.div`
  height: calc(80vh - 280px);
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: calc(80vh - 310px);
  }
`;

export const InputContainer = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
`;

export const UnloadImageRow = styled(Row)`
  overflow: hidden;
  flex-wrap: wrap;
`;
