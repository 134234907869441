const initialState = {
  error: '',
  isLoading: false,
  list: [],
  sensorLevels: [],
  sensorsForSite: [],
  typesList: [],
  isEditSensorLoading: false,
};

export const getSensorsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSensorsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  list: payload,
});

export const getSensorsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const editSensorRequest = state => ({
  ...state,
  error: '',
  isEditSensorLoading: true,
});

export const editSensorSuccess = state => ({
  ...state,
  isEditSensorLoading: false,
});

export const editSensorFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isEditSensorLoading: false,
});

export const getSensorsForSiteRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSensorsForSiteSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  sensorsForSite: payload,
});

export const getSensorsForSiteFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const getItemSensorsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getItemSensorsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  list: payload,
});

export const getItemSensorsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const getSensorTypesRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSensorTypesSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  typesList: payload,
});

export const getSensorTypesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const setSensorTypeRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setSensorTypeSuccess = state => ({
  ...state,
  isLoading: false,
});

export const setSensorTypeFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const deleteSensorTypeRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteSensorTypeSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteSensorTypeFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getSensorLevelsSettingsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSensorLevelsSettingsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  sensorLevels: payload,
});

export const getSensorLevelsSettingsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const setSensorLevelsSettingsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setSensorLevelsSettingsSuccess = state => ({
  ...state,
  isLoading: false,
  sensorLevels: [],
});

export const setSensorLevelsSettingsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
  sensorLevels: [],
});

export const getLevelsForItemSensorRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getLevelsForItemSensorSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  sensorLevels: payload.Levels,
});

export const getLevelsForItemSensorFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const setLevelsForItemSensorRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setLevelsForItemSensorSuccess = state => ({
  ...state,
  isLoading: false,
});

export const setLevelsForItemSensorFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export default initialState;
