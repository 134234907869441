import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { SettingsListType, OEMType } from 'configs/propTypes';
import { white } from 'material-ui/styles/colors';

import { ButtonIcon } from 'components/Layout/Buttons';
import {
  DemoTasksIcon,
  ExportQRIcon,
  GateWayIcon,
  ImageIcon,
  LinkIcon,
  OEMIcon,
  OptionsIcon,
  QueueModuleIcon,
  SensorsIcon,
  SitesNotificationIcon,
  SuppliersIcon,
  TextIcon,
  TimezoneIcon,
  PersonIcon,
  WifiIcon,
  DistributionIcon,
  DeleteForeverIcon,
  AvailabilityOptionsIcon,
  SchedullingTriggers,
  ExportCSVIcon,
  AccountsIcon,
  AssigmentIcon,
  TaskIcon,
  PastDueBacklog,
} from 'components/Layout/Icons';
import LinksDialog from 'components/Dialogs/LinksDialog';
import LogoDialog from 'components/Dialogs/LogoDialog';
import BeaconDialog from 'components/Dialogs/BeaconDialog';
import ScanDialog from 'components/Dialogs/ScanDialog';
import TextDialog from 'components/Dialogs/TextDialog';
import WarningModal from 'components/Dialogs/Warning';
import EditableListDialog from 'components/Dialogs/EditableListDialog';

import DemoTasksDialog from './DemoTasksDialog';
import PdfReportDetailsDialog from './pdfReportDetailsDialog';
import SetTimezonesDialog from './SetTimezonesDialog';
import SetIoTNotificationsDialog from './SetIoTNotificationsDialog';
import SetNotificationsDialog from './SetNotificationsDialog';
import SelectUserRoleDialog from './PeopleNotificationDialog/SelectUserRoleDialog';
import OptionsListDialog from './OptionsListDialog';
import AvailabilityOptionsDialog from './AvailabilityOptionsDialog';
import GlobalScheduleTriggerDialog from './GlobalScheduleTriggerDialog';
import TaskArchivingSchedulesDialog from './TaskArchivingSchedulesDialog';
import SetFunctionsDialog from './SetFunctionsDialog/index';
import PastDueBacklogDialog from './PastDueBacklogDialog';
import { mapStateToProps, mapDispatchToProps } from './index.props';

import * as S from './styled';

class SettingsList extends Component {
  state = {
    BeaconOpened: false,
    changedValues: {},
    corporateLogoOpened: false,
    demoTasksOpened: false,
    distance: 1,
    distanceItem: 1,
    distanceUnit: 1,
    isResetLinks: false,
    LinksOpened: false,
    pdfReportDetailsOpened: false,
    resetDeleteButtonForDemoTasks: false,
    scanOpened: false,
    textOpened: false,
    warningOpened: false,
    timezonesDialogOpen: false,
    functionsDialogOpen: false,
    IoTNotificationDialogOpen: false,
    notificationDialogOpen: false,
    peopleNotificationDialogOpen: false,
    editOEMListDialogOpen: false,
    editSuppliersDialogOpen: false,
    optionsListDialogOpen: false,
    availabilityOptionsDialogOpen: false,
    scheduleTriggersDialogOpen: false,
    tasksArchivingSchedulesDialogOpen: false,
    isPastDueBacklogDialogOpen: false,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.resetSiteList();
    actions.requestGetSettings();
    actions.resetMasterSettingsForAdmin();
    actions.requestSitesList();
    actions.requestPeopleList();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.loading && nextState.scanOpened) {
      return false;
    }
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isUpdated) {
      this.closePdfReportDetailsDialog();
    }

    if (nextProps.error && this.props.error !== nextProps.error) {
      this.props.actions.openErrorDialog(nextProps.error, 'Error');
    }

    if (this.state.isResetLinks && nextProps.masterSystemLinks.length > 0) {
      const changedValues = {};

      nextProps.systemLinks.forEach((link, index) => {
        changedValues[link.Id] = nextProps.masterSystemLinks[index];
      });

      this.setState({ changedValues, isResetLinks: false });
    }
  }

  changeDistance = (event, index, value) => this.setState({ distance: value });

  changeDistanceItem = (event, index, value) => this.setState({ distanceItem: value });

  changeDistanceUnit = (event, index, value) => this.setState({ distanceUnit: value });

  closeBeaconDialog = () => this.setState({ BeaconOpened: false });
  openBeaconDialog = () => this.setState({ BeaconOpened: true });

  closePdfReportDetailsDialog = () => this.setState({ pdfReportDetailsOpened: false });

  closeCorporateLogoDialog = () => this.setState({ corporateLogoOpened: false });

  openPdfReportDetailsDialog = () => this.setState({ pdfReportDetailsOpened: true });

  openPastDueBacklogDialog = () => this.setState({ isPastDueBacklogDialogOpen: true });

  closePastDueBacklogDialog = () => this.setState({ isPastDueBacklogDialogOpen: false });

  openCorporateLogoDialog = () => {
    const { actions } = this.props;

    actions.requestGetPhoto();
    actions.requestGetPhotoEmail();
    this.setState({ corporateLogoOpened: true });
  };

  closeDemoTasksDialog = () => this.setState({ demoTasksOpened: false });

  openDemoTasksDialog = () => {
    this.props.actions.getDemoTasksCount();
    this.setState({ demoTasksOpened: true });
  };

  closeScanDialog = () => {
    this.props.actions.resetMasterSettingsForAdmin();
    this.setState({ scanOpened: false });
  };

  openScanDialog = () => this.setState({ scanOpened: true });

  closeTextDialog = () => {
    this.props.actions.resetMasterSettingsForAdmin();
    this.setState({ textOpened: false });
  };

  openTextDialog = () => this.setState({ textOpened: true });

  closeWarningDialog = () => this.setState({ warningOpened: false });

  submitBeaconDialog = values => {
    const { settings, actions } = this.props;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, ...values },
    });
    this.closeBeaconDialog();
  };

  submitPdfDialog = values => {
    const { settings, actions } = this.props;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, ...values },
    });
    this.closePdfReportDetailsDialog();
  };

  submitDemoTasksDialog = ({ IsDemoTasksMode, DeleteDemoTasks }) => {
    if (DeleteDemoTasks) {
      this.setState({ warningOpened: true });
    } else {
      const { settings, actions } = this.props;

      actions.requestSetSettings({
        isMaster: false,
        values: { ...settings, IsDemoTasksMode: IsDemoTasksMode === 'DemoTasks' },
      });
      this.closeDemoTasksDialog();
    }
  };

  submitScanDialog = values => {
    const { settings, actions } = this.props;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, ...values },
    });
    this.closeScanDialog();
  };

  submitSchedulingDialog = values => {
    const { settings, actions } = this.props;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, ...values },
    });
    this.closeSchedulingDialog();
  };

  submitTextDialog = values => {
    const { settings, actions } = this.props;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, ...values },
    });
    this.closeTextDialog();
  };

  closeLinksDialog = () => {
    const { actions } = this.props;

    actions.updateCurrentGlobalSettingsModule();
    actions.resetMasterSettingsForAdmin();
    this.setState({ changedValues: {}, LinksOpened: false });
  };

  submitLinksDialog = values => {
    const { settings, actions } = this.props;
    const { changedValues } = this.state;

    actions.requestSetSettings({
      isMaster: false,
      values: { ...settings, LinkIntroText: values.LinkIntroText },
    });

    actions.setDefaultLinksSettings({ requestValues: changedValues, isMaster: false, stateValues: values });
    this.closeLinksDialog();
  };

  getChangedValues = (value, rest) => {
    const { changedValues } = this.state;

    this.setState({
      changedValues: {
        ...changedValues,
        [rest.link.Id]: rest.reset
          ? rest.link
          : {
              ...(changedValues[rest.link.Id] ? changedValues[rest.link.Id] : rest.link),
              [rest.name.substring(1)]: value,
            },
      },
    });
  };

  resetLinks = () => this.setState({ isResetLinks: true });

  openLinksDialog = () => {
    const { actions } = this.props;

    actions.updateCurrentGlobalSettingsModule('systemLinks');
    actions.requestGetSettingsLinksList({ isMaster: false });
    this.setState({ LinksOpened: true });
  };

  openSetTimezonesDialog = () => this.setState({ timezonesDialogOpen: true });
  closeSetTimezonesDialog = () => this.setState({ timezonesDialogOpen: false });

  openFunctionsDialog = () => this.setState({ functionsDialogOpen: true });
  closeFunctionsDialog = () => this.setState({ functionsDialogOpen: false });

  openSetIoTNotificationsDialog = () => this.setState({ IoTNotificationDialogOpen: true });
  closeSetIoTNotificationsDialog = () => this.setState({ IoTNotificationDialogOpen: false });

  openSetNotificationDialog = () => this.setState({ notificationDialogOpen: true });
  closeSetNotificationDialog = () => this.setState({ notificationDialogOpen: false });

  openPeopleNotificationDialog = () => this.setState({ peopleNotificationDialogOpen: true });
  closePeopleNotificationDialog = () => this.setState({ peopleNotificationDialogOpen: false });

  openAvailabilityOptionsDialog = () => this.setState({ availabilityOptionsDialogOpen: true });
  closeAvailabilityOptionsDialog = () => this.setState({ availabilityOptionsDialogOpen: false });

  openScheduleTriggersDialog = () => this.setState({ scheduleTriggersDialogOpen: true });
  closeScheduleTriggersDialog = () => this.setState({ scheduleTriggersDialogOpen: false });

  openTasksArchivingSchedulesDialog = () => this.setState({ tasksArchivingSchedulesDialogOpen: true });
  closeTasksArchivingSchedulesDialog = () => this.setState({ tasksArchivingSchedulesDialogOpen: false });

  openEditOEMListDialog = () => {
    this.props.actions.getOemListRequest();
    this.setState({ editOEMListDialogOpen: true });
  };

  closeEditOEMListDialog = () => this.setState({ editOEMListDialogOpen: false });

  openEditSuppliersListDialog = () => {
    this.props.actions.getSuppliersListRequest();
    this.setState({ editSuppliersDialogOpen: true });
  };

  closeEditSuppliersListDialog = () => this.setState({ editSuppliersDialogOpen: false });

  openOptionsListDialog = () => this.setState({ optionsListDialogOpen: true });
  closeOptionsListDialog = () => this.setState({ optionsListDialogOpen: false });

  render() {
    const { actions, settings, oemList, suppliersList, error, isDemoTasksMode } = this.props;
    const {
      BeaconOpened,
      corporateLogoOpened,
      demoTasksOpened,
      distance,
      distanceItem,
      distanceUnit,
      LinksOpened,
      pdfReportDetailsOpened,
      resetDeleteButtonForDemoTasks,
      scanOpened,
      textOpened,
      warningOpened,
      timezonesDialogOpen,
      functionsDialogOpen,
      IoTNotificationDialogOpen,
      notificationDialogOpen,
      peopleNotificationDialogOpen,
      editOEMListDialogOpen,
      editSuppliersDialogOpen,
      optionsListDialogOpen,
      availabilityOptionsDialogOpen,
      scheduleTriggersDialogOpen,
      tasksArchivingSchedulesDialogOpen,
      isPastDueBacklogDialogOpen,
    } = this.state;

    return [
      <S.Container key="SettingsListContainer">
        <S.List>
          <S.ListItem index={8} key="General">
            <S.ItemTitle>General</S.ItemTitle>
            <ButtonIcon onClick={this.openCorporateLogoDialog} tooltip="Change logo">
              <ImageIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openDemoTasksDialog} tooltip="Demo">
              <DemoTasksIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openFunctionsDialog} tooltip="Functions">
              <AccountsIcon color={white} />
            </ButtonIcon>
          </S.ListItem>
          <S.ListItem index={7} key="IoT">
            <S.ItemTitle>IoT</S.ItemTitle>
            <Link to="/settings/gateways">
              <ButtonIcon tooltip="Gateways">
                <GateWayIcon />
              </ButtonIcon>
            </Link>
            <Link to="/settings/sensortypes">
              <ButtonIcon tooltip="Sensor Templates">
                <SensorsIcon />
              </ButtonIcon>
            </Link>
          </S.ListItem>
          <S.ListItem index={6} key="Reports">
            <S.ItemTitle>Reports</S.ItemTitle>
            <ButtonIcon onClick={this.openPdfReportDetailsDialog} tooltip="Report details">
              <QueueModuleIcon />
            </ButtonIcon>
          </S.ListItem>
          <S.ListItem index={5} key="Verification">
            <S.ItemTitle>Verification</S.ItemTitle>
            <ButtonIcon onClick={this.openBeaconDialog} tooltip="Beacons settings">
              <WifiIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openScanDialog} tooltip="QR code settings">
              <ExportQRIcon />
            </ButtonIcon>
          </S.ListItem>
          <S.ListItem index={4} key="Notifications">
            <S.ItemTitle>Notifications</S.ItemTitle>
            <ButtonIcon onClick={this.openTextDialog} tooltip="Text">
              <TextIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openLinksDialog} tooltip="Links">
              <LinkIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openSetNotificationDialog} tooltip="Sites">
              <SitesNotificationIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openPeopleNotificationDialog} tooltip="People">
              <PersonIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openSetIoTNotificationsDialog} tooltip="IoT">
              <S.IotNotificationIcon />
            </ButtonIcon>
          </S.ListItem>
          <S.ListItem index={3} key="Scheduling">
            <S.ItemTitle>Scheduling</S.ItemTitle>
            <ButtonIcon onClick={this.openSetTimezonesDialog} tooltip="Zones">
              <TimezoneIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openScheduleTriggersDialog} tooltip="Scheduling Triggers">
              <SchedullingTriggers />
            </ButtonIcon>
            <ButtonIcon onClick={this.openAvailabilityOptionsDialog} tooltip="Availability">
              <AvailabilityOptionsIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openTasksArchivingSchedulesDialog} tooltip="Task Archiving">
              <ExportCSVIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openPastDueBacklogDialog} tooltip="Past Due Backlog">
              <PastDueBacklog />
            </ButtonIcon>
          </S.ListItem>
          <S.ListItem index={2} key="Parts">
            <S.ItemTitle>Parts</S.ItemTitle>
            <ButtonIcon onClick={this.openEditOEMListDialog} tooltip="OEMs">
              <OEMIcon />
            </ButtonIcon>
            <ButtonIcon onClick={this.openEditSuppliersListDialog} tooltip="Suppliers">
              <SuppliersIcon />
            </ButtonIcon>
            <Link to="/settings/distribution/parts">
              <ButtonIcon tooltip="Parts Distro">
                <DistributionIcon />
              </ButtonIcon>
            </Link>
            <Link to="/settings/purgeparts/parts">
              <ButtonIcon tooltip="Purge Parts">
                <DeleteForeverIcon />
              </ButtonIcon>
            </Link>
            <ButtonIcon onClick={this.openOptionsListDialog} tooltip="Options Lists">
              <OptionsIcon />
            </ButtonIcon>
          </S.ListItem>
          <S.ListItem index={1} key="External">
            <S.ItemTitle>External</S.ItemTitle>
            <Link to="/settings/global-index/manual">
              <ButtonIcon tooltip="Global Index">
                <AssigmentIcon />
              </ButtonIcon>
            </Link>
            <Link to="/settings/index-triggers">
              <ButtonIcon tooltip="Index Triggers">
                <TaskIcon />
              </ButtonIcon>
            </Link>
          </S.ListItem>
        </S.List>
      </S.Container>,
      <LogoDialog
        key="CorporateLogoDialog"
        handleClose={this.closeCorporateLogoDialog}
        title="Corporate Logo"
        open={corporateLogoOpened}
      />,
      <DemoTasksDialog
        key="DemoTasksDialog"
        handleClose={this.closeDemoTasksDialog}
        title="Demo Mode Settings"
        open={demoTasksOpened}
        onSubmit={this.submitDemoTasksDialog}
        resetDeleteButtonForDemoTasks={resetDeleteButtonForDemoTasks}
        initialValues={{ IsDemoTasksMode: isDemoTasksMode }}
        withoutTopBorder
      />,
      <PdfReportDetailsDialog
        key="PdfReportDetailsDialog"
        handleClose={this.closePdfReportDetailsDialog}
        title="PDF Report Details"
        open={pdfReportDetailsOpened}
        settings={settings}
        onSubmit={this.submitPdfDialog}
      />,
      <BeaconDialog
        key="BeaconDialog"
        handleClose={this.closeBeaconDialog}
        open={BeaconOpened}
        title="Beacons"
        site={settings}
        changeDistance={this.changeDistance}
        changeDistanceItem={this.changeDistanceItem}
        changeDistanceUnit={this.changeDistanceUnit}
        distance={distance}
        distanceItem={distanceItem}
        distanceUnit={distanceUnit}
        onSubmit={this.submitBeaconDialog}
        withoutTopBorder
      />,
      <ScanDialog
        key="ScanDialog"
        handleClose={this.closeScanDialog}
        title="QR settings for Units and Items"
        open={scanOpened}
        onSubmit={this.submitScanDialog}
        withoutTopBorder
        BigButtons
      />,
      <TextDialog
        key="TextDialog"
        handleClose={this.closeTextDialog}
        title="Notifications Text"
        open={textOpened}
        onSubmit={this.submitTextDialog}
        BigButtons
      />,
      <LinksDialog
        key="LinksDialog"
        handleClose={this.closeLinksDialog}
        title="System Links"
        open={LinksOpened}
        BigButtons
        onSubmit={this.submitLinksDialog}
        getChangedValues={this.getChangedValues}
        resetLinks={this.resetLinks}
      />,
      <WarningModal
        key="WarningModal"
        handleClose={this.closeWarningDialog}
        onSubmit={this.closeWarningDialog}
        open={warningOpened}
        text="Please delete all demo tasks, or uncheck the option."
        title="This change can not be saved!"
      />,
      <SetTimezonesDialog
        key="SitesTimezones"
        open={timezonesDialogOpen}
        handleClose={this.closeSetTimezonesDialog}
        title="Set Time Zone"
      />,
      <SetFunctionsDialog
        key="Functions"
        open={functionsDialogOpen}
        handleClose={this.closeFunctionsDialog}
        title="Job functions"
      />,
      <SetIoTNotificationsDialog
        key="IoTNotifications"
        open={IoTNotificationDialogOpen}
        handleClose={this.closeSetIoTNotificationsDialog}
        title="Set Notification Time"
      />,
      <SetNotificationsDialog
        key="NotificationTime"
        open={notificationDialogOpen}
        handleClose={this.closeSetNotificationDialog}
        title="Set Notification Time"
      />,
      <SelectUserRoleDialog
        key="PeopleNotification"
        open={peopleNotificationDialogOpen}
        handleClose={this.closePeopleNotificationDialog}
        title="Select User Role"
      />,
      <EditableListDialog
        key="EditOEMs"
        open={editOEMListDialogOpen}
        handleClose={this.closeEditOEMListDialog}
        title="OEM Companies"
        searchPlaceholder="filter OEMs"
        idTitle="originalEquipmentManufacturerId"
        list={oemList}
        addItem={actions.addOemRequest}
        editItem={actions.editOemRequest}
        deleteItem={actions.deleteOemRequest}
        error={error}
        openConfirmationDialog={actions.openConfirmationDialog}
      />,
      <EditableListDialog
        key="EditSuppliers"
        open={editSuppliersDialogOpen}
        handleClose={this.closeEditSuppliersListDialog}
        title="Supplier Companies"
        searchPlaceholder="filter Suppliers"
        idTitle="supplierId"
        list={suppliersList}
        addItem={actions.addSupplierRequest}
        editItem={actions.editSupplierRequest}
        deleteItem={actions.deleteSupplierRequest}
        openConfirmationDialog={actions.openConfirmationDialog}
        error={error}
      />,
      <OptionsListDialog
        key="OptionsDialog"
        open={optionsListDialogOpen}
        handleClose={this.closeOptionsListDialog}
        error={error}
      />,
      <AvailabilityOptionsDialog
        key="AvailabilityOptionsDialog"
        open={availabilityOptionsDialogOpen}
        handleClose={this.closeAvailabilityOptionsDialog}
      />,
      <GlobalScheduleTriggerDialog
        key="GlobalScheduleTriggerDialog"
        open={scheduleTriggersDialogOpen}
        handleClose={this.closeScheduleTriggersDialog}
      />,
      <TaskArchivingSchedulesDialog
        key="TaskArchivingSchedulesDialog"
        open={tasksArchivingSchedulesDialogOpen}
        handleClose={this.closeTasksArchivingSchedulesDialog}
      />,
      <PastDueBacklogDialog open={isPastDueBacklogDialogOpen} handleClose={this.closePastDueBacklogDialog} />,
    ];
  }
}

SettingsList.propTypes = {
  actions: PropTypes.shape({
    openErrorDialog: PropTypes.func.isRequired,
    requestGetPhoto: PropTypes.func.isRequired,
    getDemoTasksCount: PropTypes.func.isRequired,
    requestGetPhotoEmail: PropTypes.func.isRequired,
    requestGetSettings: PropTypes.func.isRequired,
    requestSetSettings: PropTypes.func.isRequired,
    requestGetSettingsLinksList: PropTypes.func.isRequired,
    resetMasterSettingsForAdmin: PropTypes.func.isRequired,
    setDefaultLinksSettings: PropTypes.func.isRequired,
    updateCurrentGlobalSettingsModule: PropTypes.func.isRequired,
    requestSitesList: PropTypes.func.isRequired,
    requestPeopleList: PropTypes.func.isRequired,
    resetSiteList: PropTypes.func.isRequired,
    getOemListRequest: PropTypes.func.isRequired,
    addOemRequest: PropTypes.func.isRequired,
    editOemRequest: PropTypes.func.isRequired,
    deleteOemRequest: PropTypes.func.isRequired,
    getSuppliersListRequest: PropTypes.func.isRequired,
    addSupplierRequest: PropTypes.func.isRequired,
    editSupplierRequest: PropTypes.func.isRequired,
    deleteSupplierRequest: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.string.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  masterSystemLinks: PropTypes.array.isRequired,
  oemList: PropTypes.arrayOf(OEMType).isRequired,
  settings: SettingsListType.isRequired,
  suppliersList: PropTypes.arrayOf(OEMType).isRequired,
  systemLinks: PropTypes.array.isRequired,
  isDemoTasksMode: PropTypes.string,
};

SettingsList.defaultProps = {
  isDemoTasksMode: 'DemoTasks',
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsList);
