import styled from 'styled-components';

export const Container = styled.div`
  min-width: 675px;

  form {
    & > div:last-child {
      padding: 15px !important;

      & > button:first-child {
        margin-right: 10px !important;
      }

      & > button:last-child {
        margin-left: 10px !important;
      }
    }
  }
`;

export const Title = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.primaryGrey};
  font-weight: 300;
  max-width: 450px;
  margin: 0 auto;
`;

export const MainTitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  margin: 0 auto;
`;

export const SecondaryTitle = styled(MainTitle)`
  max-width: 450px;
`;

export const SiteBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  border-top: ${({ theme }) => `1px solid ${theme.textGray}`}!important;
  margin: 15px 50px;
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;
