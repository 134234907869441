import theme from 'theme';

export default class InstructionsHandler {
  static MODULES = {
    '': { backgroundColor: theme.primaryWhite, name: '' }, // used when path is '/'
    activator: { backgroundColor: theme.primaryActivator, name: 'Activator' },
    documents: { backgroundColor: theme.primaryDocuments, name: 'Documents' },
    exchange: { backgroundColor: theme.primaryExchange, name: 'List Exchange' },
    globalsettings: { backgroundColor: theme.primarySettings, name: 'Global Settings' },
    tutorials: { backgroundColor: theme.primarySettings, name: 'Tutorials' },
    health: { backgroundColor: theme.primaryHealth, name: 'Health' },
    home: { backgroundColor: theme.primaryHome, name: '' },
    parts: { backgroundColor: theme.primaryParts, name: 'Parts' },
    siteparts: { backgroundColor: theme.primaryParts, name: 'Parts Management' },
    people: { backgroundColor: theme.primaryPeople, name: 'People' },
    shortcuts: { backgroundColor: theme.mainRed, name: 'Shortcuts' },
    teams: { backgroundColor: theme.primaryBlue, name: 'Teams' },
    queue: { backgroundColor: theme.primaryQueue, name: 'Queue' },
    scheduler: { backgroundColor: theme.primaryScheduler, name: 'Scheduler' },
    settings: { backgroundColor: theme.primarySettings, name: 'Settings' },
    sites: { backgroundColor: theme.primarySites, name: 'Sites' },
    tasks: { backgroundColor: theme.primaryTasks, name: 'Tasks' },
    tenants: { backgroundColor: theme.primaryTenants, name: 'Tenant Management' },
    tenantrequests: { backgroundColor: theme.primaryTenantRequests, name: 'Tenant Management' },
    upgraderequests: { backgroundColor: theme.upgradeRequestsColor, name: 'Tenant Management' },
    units: { backgroundColor: theme.primaryLists, name: 'List Templates Setup' },
  };

  static getBackgroundColorForModule(location) {
    return InstructionsHandler.MODULES[InstructionsHandler.getModuleFromLocation(location)]?.backgroundColor;
  }

  static getModuleFromLocation(location) {
    const index = location.pathname.indexOf('/', 2);
    return location.pathname.slice(1, index !== -1 ? index : location.pathname.length);
  }

  static getModuleNameFromLocation(location) {
    return InstructionsHandler.MODULES[InstructionsHandler.getModuleFromLocation(location)]?.name;
  }

  static getStopFromShowingForModule(location) {
    return localStorage.getItem(`InCheq-${InstructionsHandler.getModuleFromLocation(location)}`) === 'true';
  }

  static setStopFromShowingForModule(location) {
    localStorage.setItem(`InCheq-${InstructionsHandler.getModuleFromLocation(location)}`, true);
  }

  static unsetStopFromShowingForModule(location) {
    localStorage.setItem(`InCheq-${InstructionsHandler.getModuleFromLocation(location)}`, false);
  }
}
