import { call, put, takeEvery } from 'redux-saga/effects';
import { createPdfReport, deletePdfReport, getPdfReportList } from 'http/exportPdfReport';

import { actions } from './index';

let payloadForUpdateList;

function* fetchCreatePdfReport({ payload }) {
  const filter = {
    scheduledDateRangeStart: payload.dates.startPeriod,
    scheduledDateRangeEnd: payload.dates.finishPeriod,
    unitId: payload.unitId,
    userId: payload.userId !== 'allusers' ? payload.userId : null,
    isArchived: payload.isArchived,
  };
  try {
    yield call(createPdfReport, filter);
    yield put(actions.createPdfReportSuccess());
    yield put(actions.getPdfReportListRequest(payloadForUpdateList));
  } catch (error) {
    yield put(actions.createPdfReportFailure(error));
  }
}

function* fetchDeletePdfReport({ payload }) {
  try {
    yield call(deletePdfReport, payload);
    yield put(actions.deletePdfReportFileSuccess());
    yield put(actions.getPdfReportListRequest(payloadForUpdateList));
  } catch (error) {
    yield put(actions.deletePdfReportFileFailure(error));
  }
}

function* fetchPdfReportList({ payload }) {
  if (payload) {
    payloadForUpdateList = payload;
  }
  try {
    const { data } = yield call(getPdfReportList, payloadForUpdateList);
    yield put(actions.getPdfReportListSuccess(data.root));
  } catch (error) {
    yield put(actions.getPdfReportListFailure(error));
  }
}

const exportPdfReportSagas = [
  takeEvery(actions.createPdfReportRequest, fetchCreatePdfReport),
  takeEvery(actions.deletePdfReportFileRequest, fetchDeletePdfReport),
  takeEvery(actions.getPdfReportListRequest, fetchPdfReportList),
];

export default exportPdfReportSagas;
