import styled from 'styled-components';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

export const FieldSelect = styled(SelectField).attrs({
  fullWidth: ({ fullWidth }) => fullWidth || true,
  autoWidth: ({ autoWidth }) => autoWidth || false,
  iconStyle: ({ theme }) => ({ fill: theme.modalsContentColor }),
})``;

export const SelectItem = styled(MenuItem)``;
