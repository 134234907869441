import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const DialogTitle = ({ handleClose, title, titleColor, noCapitalize, centeredTitle }) => (
  <S.Container noCapitalize={noCapitalize} titleColor={titleColor}>
    {title}
    {centeredTitle}
    <S.CloseButton onClick={handleClose}>
      <S.IconClose />
    </S.CloseButton>
  </S.Container>
);

DialogTitle.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleColor: PropTypes.string.isRequired,
  noCapitalize: PropTypes.bool,
  centeredTitle: PropTypes.func,
};

DialogTitle.defaultProps = {
  title: null,
  centeredTitle: null,
  noCapitalize: false,
};

export default DialogTitle;
