import React, { Component } from 'react';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import { actions as listTemplatesActions } from 'redux/listTemplates';

import CheckboxList from './CheckboxList';
import * as S from './styled';

class SelectSite extends Component {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    setIsNew: PropTypes.func.isRequired,
    setIsImport: PropTypes.func.isRequired,
    setCurrentSite: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      getLimitsRequest: PropTypes.func.isRequired,
      createListTemplateRequest: PropTypes.func.isRequired,
    }).isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    limit: PropTypes.number.isRequired,
  };

  state = {
    members: [],
    membersForSearch: [],
    checkedSite: this.props.people[0],
    searchData: '',
  };

  setCheckedSite = checkedSite => this.setState({ checkedSite });
  setSearchedMembers = (members, searchData) => this.setState({ members, searchData });

  onNextClick = () => {
    this.props.setIsNew();
    this.props.onNext();
    this.props.actions.createListTemplateRequest({ isClear: true });
  };

  onImportClick = () => {
    this.props.setIsImport();
    this.props.onNext();
    this.props.actions.createListTemplateRequest({ isClear: true });
  };

  componentDidMount() {
    this.props.setCurrentSite(this.state.checkedSite);
  }

  componentDidUpdate(prevProps, prevState) {
    const { people } = this.props;
    const { searchData } = this.state;

    if (people && people.length > 0 && prevState.members.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: people,
        membersForSearch: people,
      });
      // fixes material-ui dialog height CU610-2191
      window.dispatchEvent(new Event('resize'));
    }
  }

  render() {
    const { members, membersForSearch, checkedSite, searchData } = this.state;
    const additionalIdSorter = ({ additionalId }) => additionalId.toLowerCase();
    const nameSorter = ({ name }) => name.toLowerCase();
    const sortedMembers = sortBy(members, [additionalIdSorter, nameSorter]);
    const sortedPeople = sortBy(this.props.people, [additionalIdSorter, nameSorter]);

    return (
      <S.Container>
        <S.TopInfo>
          <S.SubInfo>
            <S.BoldText>Current Limit: </S.BoldText>
          </S.SubInfo>
          <S.LimitCount>{this.props.limit}</S.LimitCount>
          <S.SubInfo>Sites</S.SubInfo>
        </S.TopInfo>
        <S.Block>
          <S.Label>Select Site From List</S.Label>
          <S.SearchContainer>
            <SearchField
              list={membersForSearch.length > 0 || searchData.length > 0 ? membersForSearch : this.props.people}
              filterBy={['name']}
              searchData={searchData}
              placeholder="Filter Sites"
              underlineShow={false}
              icon={<SearchIcon />}
              setSearchedItems={this.setSearchedMembers}
            />
          </S.SearchContainer>
          <S.ListContainer>
            <CheckboxList
              list={members.length > 0 || searchData.length > 0 ? sortedMembers : sortedPeople}
              checkedSite={checkedSite}
              setCheckedSite={this.setCheckedSite}
              setCurrentSite={this.props.setCurrentSite}
            />
          </S.ListContainer>
        </S.Block>
        <S.TextBlock>
          <S.Text>
            <S.BoldText>Import</S.BoldText> a List Template
          </S.Text>
          <S.Text>or</S.Text>
          <S.Text>Create your own List Template?</S.Text>
        </S.TextBlock>
        <ActionsContainer isColumn paddingValue={14}>
          <ActionButton label="MY OWN" withBorder onClick={this.onNextClick} />
          <ActionButton label="IMPORT" isNext onClick={this.onImportClick} />
        </ActionsContainer>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ auth, listTemplates }) => ({
  people: auth.user.sitesAvailableToUserFullInfo,
  limit: listTemplates.limits.MaxSitesNumber,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...listTemplatesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSite);
