import http from '../index';

export const getTenantsList = () => http.post('/TenantManagement/List');

export const createTenant = dto => http.post('TenantManagement/Post', dto);

export const removeTenant = dto => http.post('TenantManagement/Delete', `id=${dto}`);

export const deactivateTenantRequest = dto => http.post('TenantManagement/DeactivateTenant', `id=${dto}`);

export const activateTenantRequest = dto => http.post('TenantManagement/ActivateTenant', `id=${dto}`);

export const putTenant = dto => http.post('TenantManagement/Put', dto);

export const getUsersList = dto => http.post('/TenantManagement/GetUsersList', `tenantId=${dto}`);

export const getIndustryList = () => http.post('/Industry/GetIndustryList');

export const createIndustry = dto => http.post('/Industry/Post', dto);

export const removeIndustry = dto => http.post('/Industry/Delete', `id=${dto}`);

export const putIndustry = dto => http.post('/Industry/Put', dto);

export const getTenantRequestsStatistics = () => http.post('TenantManagement/GetTenantStatsInfo');

export const getTenantRequestsList = (payload, requestsTypes) =>
  http.post('TenantManagement/ListTenantRequests', {
    filter: { TenantRequestType: requestsTypes[payload] },
  });

export const deleteTenantRequest = id => http.post('TenantManagement/DeleteTenantRequest', { tenantRequestId: id });

export const getApproveDemoTenant = dto => http.post('TenantManagement/ApproveTenantRequest', dto);

export const getBlockDemoTenant = dto => http.post('TenantManagement/BlockTenantRequest', dto);

export const getListMasterTiers = payload =>
  http.post('/MasterTier/ListMasterTiers', `meeteringGroupId=${payload}`, { sortType: 'asc' });

export const getMasterSystemLimits = () => http.post('MasterSystemLimits/GetMasterSystemLimits');

export const getCurrentTenantsCount = () => http.post('TenantManagement/GetCurrentTenantsCount');

export const getRecentUpgradeRequest = dto => http.post('UpgradeTenantRequest/GetLatestUpgradeRequest', dto);

export const getCurrentTenantPricingValues = tenantTierDto =>
  http.post('TenantManagement/EditPricingValues', { tenantTierDto });

export const getOverwritePricingValues = tenantTierDto =>
  http.post('TenantManagement/OverwritePricingValues', { tenantTierDto });

export const getUpgradeRequestsList = () =>
  http.post('UpgradeTenantRequest/ListUpgradeTenantRequests', { sortType: 'desc' });

export const editUpgradeRequest = upgradeTenantRequestDto =>
  http.post('UpgradeTenantRequest/EditUpgradeTenantRequest', { upgradeTenantRequestDto });

export const deleteUpgradeRequest = dto => http.post('UpgradeTenantRequest/DeleteUpgradeTenantRequest', dto);

export const deleteSentRequest = payload => http.post('TenantManagement/DeleteSentTenantRequests', { option: payload });

export const getTenants = () => [
  {
    id: 1,
    tenantName: 'Tenant Name 1',
    isActive: false,
  },
  {
    id: 2,
    tenantName: 'Tenant Name 2',
    isActive: true,
  },
  {
    id: 3,
    tenantName: 'Tenant Name 3',
    isActive: false,
  },
  {
    id: 4,
    tenantName: 'Tenant Name 4',
    isActive: false,
  },
  {
    id: 5,
    tenantName: 'Tenant Name 5',
    isActive: false,
  },
];

export const updateTenants = () => Promise.resolve('data');
