import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';
import CheckboxList from 'components/CheckboxList';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import * as S from 'components/Dialogs/styled';

import { actions as sitesActions } from 'redux/sites';
import { actions as appActions } from 'redux/app';

import SetNotificationTime from './SetNotificationTime';
import { addNotificationTimeToSites } from './helpers';
import { getSitesList } from './selectors';

const initialNotificationTime = {
  Hour: '',
  Minute: '',
  Period: '',
};

class SetNotificationsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    sitesList: PropTypes.arrayOf(
      PropTypes.shape({
        SiteId: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
        Hour: PropTypes.string,
        Minute: PropTypes.string,
        Period: PropTypes.string,
      }),
    ).isRequired,
    selectedSite: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    multipleEdit: PropTypes.func.isRequired,
    updateCurrentModule: PropTypes.func.isRequired,
  };

  state = {
    sites: [],
    sitesForSearch: [],
    checkedSites: [],
    searchData: '',
    notificationTimeOpen: false,
    notificationTime: initialNotificationTime,
    currentSite: {
      notificationTime: initialNotificationTime,
    },
    notificationTimeSettings: [],
  };

  componentDidMount() {
    this.props.updateCurrentModule('globalsettings');
  }

  componentDidUpdate(prevProps, prevState) {
    const { sitesList } = this.props;
    const { searchData } = this.state;

    if (sitesList && sitesList.length > 0 && prevState.sites.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sites: sitesList, sitesForSearch: sitesList });
    }
  }

  clearCheckedSites = () => this.setState({ checkedSites: [] });
  closeNotificationDialog = () => this.setState({ notificationTimeOpen: false, notificationTime: {} });
  fillCheckedSites = checkedSites => this.setState({ checkedSites });
  openSetTimezoneDialog = currentSite =>
    this.setState({ notificationTimeOpen: true, currentSite, notificationTime: currentSite.notificationTime });
  setNotificationTime = ({ value, fieldName }) =>
    this.setState(prevState => ({ notificationTime: { ...prevState.notificationTime, [fieldName]: value } }));
  setDefaultNotification = () => this.setState({ notificationTime: { Hour: '11', Minute: '00', Period: 'pm' } });
  setSearchedSites = (sites, searchData) => this.setState({ sites, searchData });

  closeModal = () => {
    this.setState({
      notificationTimeSettings: [],
      sites: [],
      sitesForSearch: [],
      searchData: '',
      notificationTime: initialNotificationTime,
    });
    this.clearCheckedSites();
    this.props.handleClose();
  };

  applyToCheckedSelections = () => {
    const { checkedSites, sites, notificationTime, sitesForSearch, notificationTimeSettings: oldIds } = this.state;

    const updatedCurrentSites = addNotificationTimeToSites(sites, checkedSites, notificationTime);
    const updatedSitesForSearch = addNotificationTimeToSites(sitesForSearch, checkedSites, notificationTime);
    const idsToSave = [...oldIds, ...checkedSites.map(SiteId => ({ SiteId, ...notificationTime }))];

    this.setState({
      notificationTimeSettings: idsToSave,
      checkedSites: [],
      sites: updatedCurrentSites,
      sitesForSearch: updatedSitesForSearch,
    });
    this.closeNotificationDialog();
  };

  applyToCurrentSite = () => {
    const { currentSite, sites, notificationTime, sitesForSearch, notificationTimeSettings: oldIds } = this.state;

    const updatedCurrentSites = addNotificationTimeToSites(sites, [currentSite.SiteId], notificationTime);
    const updatedSearchSites = addNotificationTimeToSites(sitesForSearch, [currentSite.SiteId], notificationTime);
    const notificationTimeSettings = [...oldIds, { SiteId: currentSite.SiteId, ...notificationTime }];

    this.setState({
      notificationTimeSettings,
      checkedSites: [],
      sites: updatedCurrentSites,
      sitesForSearch: updatedSearchSites,
    });
    this.closeNotificationDialog();
  };

  saveNotifications = () => {
    const { notificationTimeSettings } = this.state;
    const { multipleEdit, selectedSite } = this.props;

    if (notificationTimeSettings.length === 0) return;

    multipleEdit({
      selectedSite,
      requestBody: { settings: notificationTimeSettings, setingsName: 'notificationTimeSettings' },
      requestName: 'SetNotificationTime',
    });
    this.closeModal();
  };

  render() {
    const { open, title } = this.props;
    const {
      sites,
      checkedSites,
      searchData,
      notificationTimeOpen,
      currentSite,
      notificationTime,
      sitesForSearch,
      notificationTimeSettings,
    } = this.state;

    const disableSaving = notificationTimeSettings.length === 0;

    return (
      <>
        <BaseDialog open={open} onRequestClose={this.closeModal} title={title}>
          <S.DialogContainer>
            <S.SearchContainer>
              <SearchField
                list={sitesForSearch}
                filterBy="Name"
                searchData={searchData}
                placeholder="filter Sites"
                underlineShow={false}
                icon={<SearchIcon />}
                setSearchedItems={this.setSearchedSites}
              />
            </S.SearchContainer>
            <S.ListContainer>
              <CheckboxList
                list={sites}
                checkedSites={checkedSites}
                onItemClick={this.openSetTimezoneDialog}
                clearCheckedSites={this.clearCheckedSites}
                fillCheckedSites={this.fillCheckedSites}
                displayValue="notificationTime"
              />
            </S.ListContainer>
            <S.SaveButtonContainer onClick={this.saveNotifications}>
              <S.SaveButton disabled={disableSaving} />
            </S.SaveButtonContainer>
          </S.DialogContainer>
        </BaseDialog>
        <SetNotificationTime
          open={notificationTimeOpen}
          title={title}
          currentSite={currentSite}
          checkedSitesNumber={checkedSites.length}
          inputTime={notificationTime}
          applyToCheckedSelections={this.applyToCheckedSelections}
          applyToCurrentSite={this.applyToCurrentSite}
          handleClose={this.closeNotificationDialog}
          setNotificationTime={this.setNotificationTime}
          setDefaultNotification={this.setDefaultNotification}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, sites }) => ({
  selectedSite: auth.selectedSite,
  sitesList: getSitesList(sites.list),
});

const mapDispatchToProps = {
  updateCurrentModule: appActions.updateCurrentModule,
  multipleEdit: sitesActions.multipleSiteEditRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetNotificationsDialog);
