import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import validate from './validator';
import * as S from './styled';

const AddEditGroupForm = () => (
  <S.FormContainer>
    <Field component={TextFieldAdapter} name="Name" placeholder="Group Name" />
    <Field component={TextFieldAdapter} name="Notes" placeholder="Notes" />
  </S.FormContainer>
);

const AddEditGroup = withInputModal(AddEditGroupForm);

const AddEditGroupDialog = props => <AddEditGroup initialValues={props.group} validate={validate} {...props} />;

AddEditGroupDialog.propTypes = {
  group: PropTypes.shape({
    Name: PropTypes.string,
    Notes: PropTypes.string,
  }).isRequired,
};

AddEditGroupDialog.defaultProps = {
  group: {
    Name: '',
    Notes: '',
  },
};

export default AddEditGroupDialog;
