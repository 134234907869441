import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_DISTRIBUTIONS_REQUEST: undefined,
  GET_DISTRIBUTIONS_SUCCESS: undefined,
  GET_DISTRIBUTIONS_FAILURE: undefined,
  DELETE_DISTRIBUTIONS_REQUEST: undefined,
  DELETE_DISTRIBUTIONS_SUCCESS: undefined,
  DELETE_DISTRIBUTIONS_FAILURE: undefined,
  GET_DISTRIBUTION_LIST_PREVIEW_REQUEST: undefined,
  GET_DISTRIBUTION_LIST_PREVIEW_SUCCESS: undefined,
  GET_DISTRIBUTION_LIST_PREVIEW_FAILURE: undefined,
  GET_DISTRIBUTION_DETAILS_REQUEST: undefined,
  GET_DISTRIBUTION_DETAILS_SUCCESS: undefined,
  GET_DISTRIBUTION_DETAILS_FAILURE: undefined,
  GET_DISTRIBUTIONS_GROUPS_REQUEST: undefined,
  GET_DISTRIBUTIONS_GROUPS_SUCCESS: undefined,
  GET_DISTRIBUTIONS_GROUPS_FAILURE: undefined,
  DELETE_DISTRIBUTIONS_GROUPS_REQUEST: undefined,
  DELETE_DISTRIBUTIONS_GROUPS_SUCCESS: undefined,
  DELETE_DISTRIBUTIONS_GROUPS_FAILURE: undefined,
  GET_DISTRIBUTION_GROUP_DETAILS_REQUEST: undefined,
  GET_DISTRIBUTION_GROUP_DETAILS_SUCCESS: undefined,
  GET_DISTRIBUTION_GROUP_DETAILS_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getDistributionsRequest, handlers.getDistributionsRequest],
    [actions.getDistributionsSuccess, handlers.getDistributionsSuccess],
    [actions.getDistributionsFailure, handlers.getDistributionsFailure],
    [actions.deleteDistributionsRequest, handlers.deleteDistributionsRequest],
    [actions.deleteDistributionsSuccess, handlers.deleteDistributionsSuccess],
    [actions.deleteDistributionsFailure, handlers.deleteDistributionsFailure],
    [actions.getDistributionListPreviewRequest, handlers.getDistributionListPreviewRequest],
    [actions.getDistributionListPreviewSuccess, handlers.getDistributionListPreviewSuccess],
    [actions.getDistributionListPreviewFailure, handlers.getDistributionListPreviewFailure],
    [actions.getDistributionDetailsRequest, handlers.getDistributionDetailsRequest],
    [actions.getDistributionDetailsSuccess, handlers.getDistributionDetailsSuccess],
    [actions.getDistributionDetailsFailure, handlers.getDistributionDetailsFailure],
    [actions.getDistributionsGroupsRequest, handlers.getDistributionsGroupsRequest],
    [actions.getDistributionsGroupsSuccess, handlers.getDistributionsGroupsSuccess],
    [actions.getDistributionsGroupsFailure, handlers.getDistributionsGroupsFailure],
    [actions.getDistributionGroupDetailsRequest, handlers.getDistributionGroupDetailsRequest],
    [actions.getDistributionGroupDetailsSuccess, handlers.getDistributionGroupDetailsSuccess],
    [actions.getDistributionGroupDetailsFailure, handlers.getDistributionGroupDetailsFailure],
    [actions.deleteDistributionsGroupsRequest, handlers.deleteDistributionsGroupsRequest],
    [actions.deleteDistributionsGroupsSuccess, handlers.deleteDistributionsGroupsSuccess],
    [actions.deleteDistributionsGroupsFailure, handlers.deleteDistributionsGroupsFailure],
  ]),
  initialState,
);

export default reducer;
