import styled from 'styled-components';
import { Flex, Box } from 'grid-styled';

import AppBar from 'material-ui/AppBar';
import { ListItem } from 'material-ui/List';
import Paper from 'material-ui/Paper';
import Popover from 'material-ui/Popover';
import { Toolbar } from 'material-ui/Toolbar';
import { RadioButtonGroup } from 'material-ui/RadioButton';

export const Bar = styled(AppBar)`
  flex-shrink: 0 !important;
  z-index: 1 !important;
`;

export const BorderRadiusContainer = styled(Flex).attrs({
  column: true,
  justify: 'space-between',
})`
  background: ${({ theme }) => theme.primaryWhite};
  border-radius: 5px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  margin: 16px 16px 10px 16px;
  overflow: hidden;
`;

export const InputWrapper = styled.div`
  padding: 10px;
`;

export const BarcodeWrapper = styled.div`
  padding: 10px 0;
`;

export const InputBlockLabel = styled.div`
  background: ${({ theme }) => theme.headerColor};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
`;

export const CenteredXYGreyContainer = styled(Flex).attrs({
  column: true,
  align: 'center',
  justify: 'center',
})`
  background-color: ${({ theme }) => theme.lightGrey};
`;

export const FullHeightCenteredXYGreyContainer = styled(CenteredXYGreyContainer)`
  height: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentContainer = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  background-color: ${({ theme }) => theme.lightGrey} !important;
  overflow: hidden;
`;

export const FormItemContainer = styled.div``;

export const ListContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-bottom: 18px;
`;

export const ListItemContainer = styled(ListItem)`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey} !important;
  background: ${({ theme }) => theme.primaryWhite} !important;
  min-width: 0 !important;
  z-index: ${({ index }) => index} !important;

  /* not clickable item */
  padding: ${({ disabled }) => (disabled ? '0 0 0 16px !important' : 0)};
  display: ${({ disabled }) => (disabled ? 'flex !important' : '')};
  justify-content: ${({ disabled }) => (disabled ? 'space-between' : '')};
  align-items: ${({ disabled }) => (disabled ? 'center' : '')};

  & > div:first-child {
    position: ${({ disabled }) => (disabled ? 'static !important' : '')};
    order: ${({ disabled }) => (disabled ? '2' : '')};
    display: ${({ disabled }) => (disabled ? 'flex !important' : '')};
    & > button {
      min-width: 48px;
    }
  }
  & > div:last-child {
    padding: ${({ disabled }) => (disabled ? '16px 0 !important' : '')};
    word-break: ${({ disabled }) => (disabled ? 'break-all' : 'normal')};
  }

  /* clickable item */
  & > div > div {
    padding: ${({ leftCheckbox }) => (leftCheckbox ? '0' : '0 12px 0 16px !important')};
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:first-child {
      position: static !important;
      order: 2;
      display: ${({ leftCheckbox }) => (leftCheckbox ? 'block' : 'flex !important')};
      & > button {
        min-width: 48px;
      }
    }
    & > div:last-child {
      padding: ${({ leftCheckbox }) => (leftCheckbox ? '0' : '16px 0 !important')};
      display: flex;
      align-items: center;
      word-break: break-all;
    }
  }
`;

export const SensorItemContainer = styled(ListItem)`
  min-height: 24px !important;
  line-height: 12px !important;
  margin-bottom: 5px !important;
  border-radius: 10px !important;

  & > div {
    background-color: ${({ theme }) => theme.primaryWhite};
    border-radius: 10px !important;

    div {
      padding: 3px 5px 3px 0 !important;
      display: flex;
      align-items: center;

      div {
        font-size: 12px;
        white-space: normal;
        word-break: break-all;
      }
    }

    span {
      border-radius: 10px !important;
    }
  }
`;

export const PaperContainer = styled(Paper).attrs({
  rounded: false,
})`
  align-items: center;
  display: flex;
`;

export const PopoverContainer = styled(Popover)`
  overflow: hidden !important;
  max-height: 250px !important;
  width: 180px !important;
`;

export const Row = styled.div`
  align-items: center;
  display: flex !important;
  justify-content: space-between !important;
`;

export const ScrollableContentContainer = styled(Box)`
  overflow-y: auto;
`;

export const ToolbarContainer = styled(Toolbar)`
  align-items: center;
  background-color: ${({ theme }) => theme.lightGrey} !important;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey} !important;
  flex-shrink: 0 !important;
`;

export const RadioGroupContainer = styled(RadioButtonGroup)`
  & > div {
    padding: 10px 10px 5px 10px !important;
    box-sizing: border-box;
    border-radius: unset !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    &:last-child {
      padding: 10px 10px 10px 10px !important;
    }
  }
`;
