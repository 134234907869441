import { getTimezoneOffset } from 'helpers';

export const getSitesList = sites =>
  sites
    ? sites.map(({ Id, AdditionalSiteID, Name, TimeZoneIanaId }) => ({
        SiteId: Id,
        Name: `${AdditionalSiteID} ${Name}`,
        TimeZoneIanaId,
        offset: TimeZoneIanaId ? getTimezoneOffset(TimeZoneIanaId) : '',
      }))
    : [];

export const blank = () => {};
