import theme from 'theme';

import * as S from './styled';

const CARD_TYPES = {
  SYSTEM: 'System Action',
  GENERAL: 'General Action',
  ADMIN: 'Admin Action',
};

export const activatorsData = [
  {
    type: 'setup',
    primaryIcon: S.CardPrimaryIcon,
    secondaryIcon: S.CardSecondaryIcon,
    title: 'New Site Iot Setup',
    info: 'Setup new Sensor and Gateway hardware for the first time for a Site',
    instructionsDialogTitle: 'New Iot Setup',
    purpose: 'Setting up a new (gateway) IoT device with one or more sensors for the first time for a Tenant.',
    tooltipText: CARD_TYPES.SYSTEM,
  },
  {
    type: 'add',
    primaryIcon: S.CardPrimaryIcon,
    secondaryIcon: S.CardSecondaryIcon,
    title: 'Add Gateway',
    info: 'Add additional Gateway (and sensors) to a Site with existing Gateway(s) in place',
    instructionsDialogTitle: 'New Gateway',
    purpose: 'Adding an additional (gateway) IoT device with one or more sensors for a Tenant',
    tooltipText: CARD_TYPES.SYSTEM,
  },
  {
    type: 'remove',
    primaryIcon: S.CardPrimaryIcon,
    secondaryIcon: S.CardSecondaryIcon,
    title: 'Remove Gateway',
    info: 'Remove a Gateway (and its sensors) from a Site',
    instructionsDialogTitle: 'Remove Gateway',
    purpose: 'Remove (gateway) IoT device with one or more sensors for a Tenant.',
    tooltipText: CARD_TYPES.SYSTEM,
  },
];

const generalCards = [
  {
    type: 'addShortcut',
    primaryIcon: S.ShortCutIcon,
    secondaryIcon: S.GeneralIcon,
    color: theme.mainDarkBlue,
    title: 'New Shortcut',
    tooltipText: CARD_TYPES.GENERAL,
    info: 'Setup a new Shortcut to create checklist with dates, items, and tasks',
  },
  {
    type: 'addChecklist',
    primaryIcon: S.ChecklistPrimaryIcon,
    secondaryIcon: S.GeneralIcon,
    color: theme.mainRed,
    title: 'New Checklist',
    tooltipText: CARD_TYPES.GENERAL,
    info: 'Use an existing Shortcut to create checklist with dates, items, and tasks',
  },
  {
    type: 'addListTemplate',
    primaryIcon: S.ListsPrimaryIcon,
    secondaryIcon: S.GeneralIcon,
    color: theme.mainLightBlue,
    title: 'New List Template',
    tooltipText: CARD_TYPES.GENERAL,
    info: 'Adding new List Templates to a Site quickly',
  },
];

export const adminCards = [
  {
    type: 'firstSetup',
    primaryIcon: S.FirstTimeSetupIcon,
    secondaryIcon: S.SitesSecondaryIcon,
    color: theme.primarySites,
    title: 'First Time Setup',
    tooltipText: CARD_TYPES.ADMIN,
    info: 'Setup Site(s) users, lists and test schedule for the first time in demonstration mode',
  },
  {
    type: 'newSite',
    primaryIcon: S.SitesPrimaryIcon,
    secondaryIcon: S.SitesSecondaryIcon,
    color: theme.primarySites,
    title: 'New Sites',
    tooltipText: CARD_TYPES.ADMIN,
    info: 'Setting up one or more sites quickly and add people to them.',
  },
  ...generalCards,
];

export const managerCards = [...generalCards];

export const emptyValueLabel = { value: '', label: '' };
