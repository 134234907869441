import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_SITE_MEMBER_REQUEST: undefined,
  ADD_SITE_MEMBER_SUCCESS: undefined,
  ADD_SITE_MEMBER_FAILURE: undefined,

  CREATE_SITE_REQUEST: undefined,
  CREATE_SITE_SUCCESS: undefined,
  CREATE_SITE_FAILURE: undefined,

  GET_OPTIONS_LIST_REQUEST: undefined,
  GET_OPTIONS_LIST_SUCCESS: undefined,
  GET_OPTIONS_LIST_FAILURE: undefined,

  GET_JOBS_LIST_REQUEST: undefined,
  GET_JOBS_LIST_SUCCESS: undefined,
  GET_JOBS_LIST_FAILURE: undefined,

  CHECK_SITE_REQUEST: undefined,
  CHECK_SITE_SUCCESS: undefined,
  CHECK_SITE_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addSiteMemberRequest, handlers.addSiteMemberRequest],
    [actions.addSiteMemberSuccess, handlers.addSiteMemberSuccess],
    [actions.addSiteMemberFailure, handlers.addSiteMemberFailure],

    [actions.createSiteRequest, handlers.createSiteRequest],
    [actions.createSiteSuccess, handlers.createSiteSuccess],
    [actions.createSiteFailure, handlers.createSiteFailure],

    [actions.getOptionsListRequest, handlers.getOptionsListRequest],
    [actions.getOptionsListSuccess, handlers.getOptionsListSuccess],
    [actions.getOptionsListFailure, handlers.getOptionsListFailure],

    [actions.getJobsListRequest, handlers.getJobsListRequest],
    [actions.getJobsListSuccess, handlers.getJobsListSuccess],
    [actions.getJobsListFailure, handlers.getJobsListFailure],

    [actions.checkSiteRequest, handlers.checkSiteRequest],
    [actions.checkSiteSuccess, handlers.checkSiteSuccess],
    [actions.checkSiteFailure, handlers.checkSiteFailure],
  ]),
  initialState,
);

export default reducer;
