import styled from 'styled-components';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';

export const Container = styled.div`
  padding: 24px 0 0;
`;

export const WarningsContainer = styled.div`
  padding: 0 32px;
`;

export const CardsContainer = styled.div`
  padding: 0 16px;
`;

export const ActionsContainer = styled.div`
  padding: 12px 44px 0;
`;

export const WarningText = styled.div`
  color: ${({ theme }) => theme.mainRed};
  font-size: 20px;
  line-height: 28px;
`;

export const SmallWarningText = styled(WarningText)`
  font-size: 16px;
`;

export const Card = styled(BorderRadiusContainer)``;

export const CardHeader = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const CardContent = styled(InputWrapper)``;

export const Row = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  flex-direction: row;
  flex: 1;
  display: flex;
  margin: 16px 8px;
  font-size: 14px;
`;

export const RedRow = styled(Row)`
  color: ${({ theme }) => theme.mainRed};
`;

export const Value = styled.div`
  margin-right: 6px;
  font-weight: 600;
`;

export const Label = styled.div``;

export const UserNameText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
`;
