import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import * as S from './styled';

import theme from 'theme';

const styles = {
  radioButton: {
    padding: '5px 0px',
    display: 'flex',
    width: '100%',
  },
};

const RadioGroup = ({ input, meta, style }) => (
  <S.Container>
    <RadioButtonGroup
      name="taskFrequency"
      defaultSelected={input.value.length === 0 ? -1 : input.value}
      onChange={(event, data) => input.onChange(data)}
      errorText={meta.visited || meta.touched ? meta.error : ''}
      style={style}
    >
      <RadioButton
        value={-1}
        label="One Time (no repeat)"
        iconStyle={{
          fill: input.value.length === 0 || input.value === -1 ? theme.primaryScheduler : theme.darkGrey,
          marginRight: '-165px',
        }}
        style={styles.radioButton}
      />
      <RadioButton
        value={0}
        label="Daily"
        iconStyle={{
          fill: input.value.length === 0 || input.value === 0 ? theme.primaryScheduler : theme.darkGrey,
          marginRight: '10px',
        }}
        style={styles.radioButton}
      />
      <RadioButton
        value={1}
        label="Weekly"
        iconStyle={{ fill: input.value === 1 ? theme.primaryScheduler : theme.darkGrey, marginRight: '10px' }}
        style={styles.radioButton}
      />
      <RadioButton
        value={2}
        label="Monthly"
        iconStyle={{ fill: input.value === 2 ? theme.primaryScheduler : theme.darkGrey, marginRight: '10px' }}
        style={styles.radioButton}
      />
      <RadioButton
        value={4}
        label="Yearly"
        iconStyle={{ fill: input.value === 4 ? theme.primaryScheduler : theme.darkGrey, marginRight: '10px' }}
        style={styles.radioButton}
      />
    </RadioButtonGroup>
  </S.Container>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
