import { stringComparator } from 'helpers';

export const configTenantData = tenant => ({
  type: 'Tenant',
  fields: [
    { fieldName: 'Name', fieldData: tenant.name },
    { fieldName: 'Industry', fieldData: tenant.industry },
    { fieldName: 'Admin Name', fieldData: tenant.adminName },
  ],
});

export const configResourceGroupData = resourceGroup => ({
  type: 'Resource Group',
  fields: [
    { fieldName: 'Resource Group Name', fieldData: resourceGroup.name },
    { fieldName: 'Resource Group Location', fieldData: resourceGroup.location },
  ],
});

export const configSiteData = site => ({
  type: 'Site',
  fields: [
    { fieldName: 'Site Name', fieldData: site.name },
    { fieldName: 'City', fieldData: site.city },
  ],
});

export const configHubsData = hubs => ({
  type: 'Hubs',
  isRoot: !Array.isArray(hubs),
  fields: Array.isArray(hubs) ? hubs.sort((a, b) => stringComparator(a.Name, b.Name, true)) : hubs,
});

const hubDataEntitiesHandlers = [
  {
    type: 'tenant',
    handler: configTenantData,
  },
  {
    type: 'resourceGroup',
    handler: configResourceGroupData,
  },
  {
    type: 'site',
    handler: configSiteData,
  },
  {
    type: 'hubs',
    handler: configHubsData,
  },
];

export const groupHubsData = data => {
  const groupedData = [];

  hubDataEntitiesHandlers.forEach(entity => {
    if (entity.type in data) {
      const obj = entity.handler(data[entity.type]);
      groupedData.push(obj);
    }
  });

  return groupedData;
};
