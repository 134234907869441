import styled from 'styled-components';
import { BackIcon, EditIcon } from 'components/Layout/Icons';
import { fixedTableHeadStyles } from 'components/TableList/styled';

export const TableContainer = styled.div`
  ${fixedTableHeadStyles};
  background-color: ${({ theme, hasBackgroundColor }) => hasBackgroundColor && theme.primaryWhite};

  & thead {
    color: ${({ theme }) => theme.archiveLineColor};
  }

  thead > tr > th {
    font-weight: 600;
  }

  th,
  td {
    padding: 0 25px !important;
  }
`;

export const TableHeaderCell = styled.th`
  background-color: ${({ theme }) => theme.lightGrey};
  text-align: right;
`;

export const Back = styled(BackIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const Edit = styled(EditIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ConfirmationTitle = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;
