import styled from 'styled-components';
import { InputBlockLabel } from 'components/Layout/Containers';
import { LockIcon, LoyaltyIcon } from 'components/Layout/Icons';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

const FormContainer = styled.div`
  margin: 0 20px;
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme, isUnitInfo }) => (isUnitInfo ? theme.headerBlue : theme.primarySites)};
  background: ${({ theme, isListTemplateInfo }) => isListTemplateInfo && theme.mainLightBlue};
  padding: 16px;
  text-align: left;
`;

const InputWrapper = styled.div`
  padding: 0 30px 30px 30px;
`;

const Lock = styled(LockIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})``;

const Loyalty = styled(LoyaltyIcon)`
  color: ${({ theme }) => theme.darkGrey} !important;
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-bottom: 10px;
`;

const SecondaryBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 30px;
`;

const Block = styled.div`
  align-self: center;
  margin-left: 30px;
`;

const RadioButtonsWrapper = styled.div`
  flex: 1;
`;

const Content = styled.div`
  width: 130px;
  height: 130px;
  line-height: 130px;
  border-radius: 20px;
  text-align: center;
  border: ${({ theme }) => `1px dashed ${theme.primaryGrey}`};
`;

const Image = styled.img`
  vertical-align: middle;
  width: 85px;
  height: 85px;
`;

const TextField = styled(TextFieldAdapter)`
  height: 60px !important;
`;

export {
  FormContainer,
  Label,
  InputWrapper,
  Lock,
  Loyalty,
  Text,
  SecondaryBlock,
  Block,
  RadioButtonsWrapper,
  Content,
  Image,
  TextField,
};
