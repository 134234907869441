import { createSelector } from 'reselect';

const getIndustries = state => state.tenants.industryList;
const getMeteringGroups = state => state.meteringGroups.list;

export const addGroupName = createSelector([getIndustries, getMeteringGroups], (industries, groups) =>
  industries.map(({ MeeteringGroupID, ...rest }) => ({
    ...rest,
    MeeteringGroupID,
    MeeteringGroupName: groups.find(({ Id }) => Id === MeeteringGroupID)?.Name,
  })),
);

export const selector = () => {};
