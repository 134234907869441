import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ButtonIcon } from 'components/Layout/Buttons';
import { PdfIcon } from 'components/Layout/Icons';

import * as S from './styled';

const periodsMap = {
  day: 'Day',
  week: 'Week',
  past: 'Past Due',
};

const dateSortingValues = [
  { value: 'asc', label: 'Oldest to newest' },
  { value: 'desc', label: 'Newest to oldest' },
];

const calculateRanges = (date, period) => {
  const start = moment(date).format('MM/DD/YYYY');
  const yesterday = moment().subtract(1, 'day').format('MM/DD/YYYY');
  const weekAfterStart = moment(date).add(1, 'week').format('MM/DD/YYYY');

  const rangesMap = {
    day: `${start} - ${start}`,
    week: `${start} - ${weekAfterStart}`,
    past: `Before ${yesterday}`,
  };

  return rangesMap[period];
};

const Details = ({
  data: { assigneeName, period, rangeStart, tasksTotal, dateSortingValue },
  isCompleted,
  onExport,
  onToggle,
  onChangeDateSorting,
}) =>
  isCompleted ? (
    <S.CompletedContainer>
      <S.TextBlock>
        <S.InfoItem>
          Tasks for: {assigneeName} ({tasksTotal} total)
        </S.InfoItem>
        <S.InfoItem>Filtered by: {periodsMap[period]}</S.InfoItem>
        <S.InfoItem>Date Range: {calculateRanges(rangeStart, period)}</S.InfoItem>
      </S.TextBlock>
      <S.ButtonBlock>
        <ButtonIcon onClick={onExport} tooltip="Get report">
          <PdfIcon />
        </ButtonIcon>
      </S.ButtonBlock>
    </S.CompletedContainer>
  ) : (
    <S.Container>
      <S.InfoItem>
        Tasks for: {assigneeName} ({tasksTotal} total)
      </S.InfoItem>
      <S.InfoItem>Filtered by: {periodsMap[period]}</S.InfoItem>
      <S.InfoItem>Date Range: {calculateRanges(rangeStart, period)}</S.InfoItem>
      <>
        {period !== 'all' && period !== 'parts' ? (
          <S.CheckBoxButton onClick={onToggle} label="Attention View Only" />
        ) : (
          <S.Select value={dateSortingValue} onChange={(e, i, value) => onChangeDateSorting(value)}>
            {dateSortingValues.map(({ value, label }) => (
              <S.Option key={value} value={value} primaryText={label} />
            ))}
          </S.Select>
        )}
      </>
    </S.Container>
  );

Details.propTypes = {
  data: PropTypes.shape({
    assigneeName: PropTypes.string,
    period: PropTypes.string,
    rangeStart: PropTypes.string,
    tasksTotal: PropTypes.number,
    dateSortingValue: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  isCompleted: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onChangeDateSorting: PropTypes.func.isRequired,
};

export default Details;
