import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';

import theme from 'theme';

import * as CS from '../styled';

import * as S from './styled';

const labels = {
  resourcegroup: {
    cancelLabel: { label: 'EXIT', color: 'green' },
    nextLabel: { label: 'NEXT (HUB SETUP)', color: 'red' },
    title: 'Alert',
  },
  confirmation: {
    cancelLabel: { label: 'Change', color: 'red' },
    nextLabel: { label: 'Yes', color: 'green' },
    title: 'Confirmation',
  },
  gateway: {
    cancelLabel: { label: 'CANCEL', color: 'green' },
    nextLabel: { label: 'REMOVE', color: 'red' },
    title: 'Alert',
  },
};

const ConfirmationDialog = ({ open, handleClose, onNext, type, selectedSite, gateway }) => (
  <BaseDialog
    open={open}
    onRequestClose={handleClose}
    title={labels[type].title}
    titleColor={type === 'confirmation' ? theme.primaryGreen : theme.primaryRed}
  >
    {type === 'resourcegroup' && (
      <S.ConfirmationText>This Resource Group already exists! You can continue to setup.</S.ConfirmationText>
    )}
    {type === 'confirmation' && (
      <>
        <S.ConfirmationText>
          Is
          <br />
          <S.BoldText>{selectedSite}</S.BoldText>
          <br />
          the correct Site for this new IoT
          <br />
          Gateway setup?
        </S.ConfirmationText>
      </>
    )}
    {type === 'gateway' && (
      <S.ConfirmationText>
        {gateway}
        <br />
        <S.BoldText>
          This Gateway and its sensors will be <br />
          permanently removed!
        </S.BoldText>
        <br />
        Are you sure?
      </S.ConfirmationText>
    )}
    <S.ModalButtonsContainer>
      <CS.NextButton
        onClick={handleClose}
        label={labels[type].cancelLabel.label}
        isNext={labels[type].cancelLabel.color === 'green'}
      />
      <CS.NextButton
        customColor={type === 'resourcegroup' && theme.primaryRed}
        onClick={onNext}
        label={labels[type].nextLabel.label}
        isNext={labels[type].nextLabel.color === 'green'}
      />
    </S.ModalButtonsContainer>
  </BaseDialog>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['resourcegroup', 'confirmation', 'hub']).isRequired,
  selectedSite: PropTypes.string.isRequired,
  gateway: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export { ConfirmationDialog };
