import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { USER_TYPES } from 'configs/enums';
import InstructionsHandler from 'helpers/InstructionsHandler';

import { ActivatorButton, ActivatorIcon, Container, CurrentLocationLabel, HomeButton, IconHome } from './styled.js';

const createLocationLabel = location => {
  if (location.pathname.includes('purgeparts')) return 'Purge Parts';
  if (location.pathname.endsWith('sensors')) return 'Available Sensors';
  return InstructionsHandler.getModuleNameFromLocation(location);
};

const HeaderLeftIcons = ({ location, toHome, toActivators, userType }) => (
  <Container>
    <HomeButton onClick={toHome}>
      <IconHome />
    </HomeButton>
    {(userType === USER_TYPES.SystemController ||
      userType === USER_TYPES.SystemAdministrator ||
      userType === USER_TYPES.Manager) && (
      <ActivatorButton onClick={toActivators}>
        <ActivatorIcon />
      </ActivatorButton>
    )}
    <CurrentLocationLabel>{createLocationLabel(location)}</CurrentLocationLabel>
  </Container>
);

HeaderLeftIcons.propTypes = {
  userType: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  toActivators: PropTypes.func.isRequired,
  toHome: PropTypes.func.isRequired,
};

export default withRouter(HeaderLeftIcons);
