import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DialogsPull from 'components/Dialogs/DialogsPull';
import EditSelectedShortcut from 'components/Dialogs/Shortcut/EditSelectedShortcut';
import ShortcutSiteSelection from 'components/Dialogs/Shortcut/ShortcutSiteSelection';
import ShortcutSiteSetup from 'components/Dialogs/Shortcut/ShortcutSiteSetup';
import Overview from 'components/Dialogs/Shortcut/Overview';
import Confirmation from 'components/Dialogs/Shortcut/Confirmation';
import Final from 'components/Dialogs/Shortcut/Final';

import { actions as shortcutActions } from 'redux/shortcuts';

import { ShortCutType } from 'configs/propTypes';

import theme from 'theme';

const STEPS = [
  {
    Component: EditSelectedShortcut,
    title: 'Shortcut Setup',
    color: theme.mainDarkBlue,
    isEdit: true,
  },
  {
    Component: ShortcutSiteSelection,
    title: 'Shortcut Site Selection',
    color: theme.mainDarkBlue,
    withOnClose: 'delete',
  },
  {
    Component: ShortcutSiteSetup,
    title: 'Shortcut Setup',
    color: theme.mainDarkBlue,
    withOnClose: 'delete',
  },
  {
    Component: Overview,
    title: 'Shortcut Setup',
    color: theme.mainDarkBlue,
    withOnClose: 'delete',
  },
  {
    Component: Confirmation,
    title: 'Confirmation',
    color: theme.mainGreen,
  },
  {
    Component: Final,
    title: 'Confirmation',
    color: theme.mainGreen,
  },
];

class ShortcutCreating extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    openCreateShortcutDialog: PropTypes.func,
    shortcut: ShortCutType.isRequired,
    shortcutToPreview: PropTypes.shape().isRequired,
    actions: PropTypes.shape({
      getShortcutSitesRequest: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    openCreateShortcutDialog: () => {},
  };

  state = {
    currentSite: null,
    overrideTime: false,
  };

  setOverride = value => {
    this.setState({ overrideTime: value });
  };

  setCurrentSite = site => {
    this.setState({ currentSite: site });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ currentSite: null });
    }
  }

  onBackToSites = () => {
    const { shortcut, actions } = this.props;

    actions.getShortcutSitesRequest({ shortcut });
  };

  render() {
    const { currentSite, overrideTime } = this.state;
    const { open, handleClose, handleExit, openCreateShortcutDialog, shortcutToPreview } = this.props;

    return (
      <DialogsPull
        open={open}
        steps={STEPS}
        handleClose={handleClose}
        isEdit={!!shortcutToPreview.Id}
        handleExit={handleExit}
        currentSite={currentSite}
        overrideTime={overrideTime}
        openCreateShortcutDialog={openCreateShortcutDialog}
        siteSelectionStepNumber={1}
        onSiteSelect={this.setCurrentSite}
        setOverride={this.setOverride}
        onBackToSites={this.onBackToSites}
      />
    );
  }
}

const mapStateToProps = ({ shortcuts }) => ({
  shortcut: shortcuts.shortcut,
  shortcutToPreview: shortcuts.shortcutToPreview,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutCreating);
