import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from 'components/Dialogs/Base';
import IconButton from 'material-ui/IconButton';

import { getPartsTemplate } from 'http/parts';

import * as S from './styled';

const styles = {
  modal: {
    maxWidth: '400px',
  },
  iconButton: {
    width: 88,
    height: 88,
  },
  icon: {
    width: 44,
    height: 44,
  },
};

const DownloadTemplateDialog = ({ open, onRequestClose }) => (
  <BaseDialog open={open} title="Download Template" modalStyles={styles.modal} onRequestClose={onRequestClose}>
    <S.Container>
      <S.Text>Download a spreadsheet template to use for part updates and uploads</S.Text>
      <S.Text>The spreadsheet format can not be altered, only populated with part information.</S.Text>
      <IconButton style={styles.iconButton} iconStyle={styles.icon} onClick={getPartsTemplate}>
        <S.DownloadIcon />
      </IconButton>
    </S.Container>
  </BaseDialog>
);

DownloadTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default DownloadTemplateDialog;
