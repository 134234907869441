import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import moment from 'moment';

import { TaskNotesPhotos } from 'configs/propTypes';
import { ButtonIcon } from 'components/Layout/Buttons';
import { CameraIcon } from 'components/Layout/Icons';
import withInputModal from 'components/Dialogs/withInputModal';
import TaskPhotoDialog from '../TaskPhotoDialog';
import {
  Block,
  BlockImage,
  PhotoContent,
  DateContainer,
  DateBlock,
  ImageContainer,
  TaskNotes,
  TextValue,
} from './styled';
import {
  Content,
  GrayTitle,
  BlockTitle,
  Breadcrumbs,
  BorderRadiusBlock,
  ColoredTitle,
  ColoredTitleNoCase,
  TaskDescription,
  Overlay,
  RadioGroup,
  RadioField,
  InputContainer,
} from '../../../../tasks/pages/TaskDetails/styled';

const params = {
  grabCursor: true,
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets',
  },
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
};

const TaskInfoForm = ({ item, notesPhotos, siteId, openPhotoDialog }) => (
  <Block>
    <Content>
      <DateContainer>
        <DateBlock>
          <TaskNotes>
            <BlockTitle>Date Scheduled</BlockTitle>
            <div>{moment(item.CreatedOn).format('MM/DD/YYYY')}</div>
          </TaskNotes>
        </DateBlock>
        <DateBlock>
          <TaskNotes>
            <BlockTitle>Date Completed</BlockTitle>
            <div>{moment(item.CompletedOnWithTime).format('MM/DD/YYYY LT')}</div>
          </TaskNotes>
        </DateBlock>
        <DateBlock>
          <BlockImage>
            <ButtonIcon tooltip="View photos" onClick={() => openPhotoDialog(item)}>
              <CameraIcon />
            </ButtonIcon>
          </BlockImage>
        </DateBlock>
      </DateContainer>
      <TaskDescription>
        <BlockTitle>{item.OriginatingTaskName || item.RuleType}</BlockTitle>
        <Breadcrumbs>
          {item.UnitName} {' > '} {item.ItemName} {' > '} {item.OriginatingChecklistName}
        </Breadcrumbs>
      </TaskDescription>
      <TaskNotes>
        <BlockTitle>Task notes</BlockTitle>
        <div>{item.InstructionalNotes}</div>
      </TaskNotes>

      {item.RuleType && (
        <div>
          {item.RuleType === 'Feedback' && (
            <BorderRadiusBlock>
              <ColoredTitle>{item.RuleData.FeedbackQuestion}</ColoredTitle>
              <GrayTitle>Response</GrayTitle>
              <TextValue>{item.RuleData.FeedbackResponse}</TextValue>
            </BorderRadiusBlock>
          )}

          {item.RuleType === 'Check Temperature' && (
            <div>
              <BorderRadiusBlock>
                <GrayTitle>Min</GrayTitle>
                <TextValue>{item.RuleData.MinTemperature}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <GrayTitle>Max</GrayTitle>
                <TextValue>{item.RuleData.MaxTemperature}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <ColoredTitle>Actual reading</ColoredTitle>
                <TextValue>{item.RuleData.ActualTemperature}</TextValue>
              </BorderRadiusBlock>
            </div>
          )}

          {item.RuleType === 'Verification' && (
            <BorderRadiusBlock>
              <ColoredTitleNoCase>{item.RuleData.VerificationSpecification}</ColoredTitleNoCase>
              <RadioGroup valueSelected={+item.RuleData.VerificationResponse} name="verification">
                <RadioField value={1} label="Yes" disabled />
                <RadioField value={0} label="No" disabled />
              </RadioGroup>
            </BorderRadiusBlock>
          )}

          {item.RuleType === 'Check Flow Rate' && (
            <div>
              <BorderRadiusBlock>
                <GrayTitle>Min</GrayTitle>
                <TextValue>{item.RuleData.MinFlow}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <GrayTitle>Max</GrayTitle>
                <TextValue>{item.RuleData.MaxFlow}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <ColoredTitle>Actual reading</ColoredTitle>
                <TextValue>{item.RuleData.ActualFlow}</TextValue>
              </BorderRadiusBlock>
            </div>
          )}

          {item.RuleType === 'Check Quantity' && (
            <div>
              <BorderRadiusBlock>
                <GrayTitle>Min</GrayTitle>
                <TextValue>{item.RuleData.MinQty}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <GrayTitle>Max</GrayTitle>
                <TextValue>{item.RuleData.MaxQty}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <ColoredTitle>Actual reading</ColoredTitle>
                <InputContainer>
                  <TextValue>{item.RuleData.ActualQty}</TextValue>
                </InputContainer>
              </BorderRadiusBlock>
            </div>
          )}

          {item.RuleType === 'Photo Confirmation' && (
            <div>
              <BorderRadiusBlock>
                <GrayTitle>Min photos</GrayTitle>
                <TextValue>{item.RuleData.MinPhotos}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <GrayTitle>Max photos</GrayTitle>
                <TextValue>{item.RuleData.MaxPhotos}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
              <BorderRadiusBlock>
                <ColoredTitle>Actual Quantity</ColoredTitle>
                <TextValue>{item.RuleData.CurrentPhotos}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
            </div>
          )}

          {item.RuleType === 'Scan Code' && (
            <div>
              <BorderRadiusBlock>
                <ColoredTitle>Scan Code</ColoredTitle>
                <TextValue>{item.RuleData.ExpectedScanCode}</TextValue>
                <Overlay />
              </BorderRadiusBlock>
            </div>
          )}
          <BorderRadiusBlock>
            <ColoredTitle>User notes</ColoredTitle>
            <TextValue>{item.UserComments || 'There is no comment'}</TextValue>
          </BorderRadiusBlock>
          <PhotoContent>
            {notesPhotos && notesPhotos.length ? (
              <Swiper {...params}>
                {notesPhotos.map((photo, index) => (
                  <ImageContainer key={index}>
                    <img alt={photo.Name} src={`${photo.src}?parentId=${item.Id}&inline=true&siteId=${siteId}`} />
                  </ImageContainer>
                ))}
              </Swiper>
            ) : null}
          </PhotoContent>
        </div>
      )}
    </Content>
  </Block>
);

const TaskInfoWithInput = withInputModal(TaskInfoForm);

TaskInfoForm.defaultProps = {
  item: {},
};

TaskInfoForm.propTypes = {
  item: PropTypes.object,
  notesPhotos: TaskNotesPhotos.isRequired,
  siteId: PropTypes.string.isRequired,
  openPhotoDialog: PropTypes.func.isRequired,
};

const TaskInfoDialog = ({
  item,
  notesPhotos,
  siteId,
  openDialog,
  openPhotoDialog,
  closePhotoDialog,
  photos,
  ...rest
}) => [
  <TaskInfoWithInput
    key="TaskInfoWithInput"
    item={item}
    notesPhotos={notesPhotos}
    siteId={siteId}
    openPhotoDialog={openPhotoDialog}
    {...rest}
  />,
  <TaskPhotoDialog
    key="Task photo dialog"
    // title="User Photos"
    item={item}
    open={openDialog}
    closePhotoDialog={closePhotoDialog}
    photos={photos}
    siteId={siteId}
  />,
];

TaskInfoDialog.defaultProps = {
  item: {},
  withSubmit: false,
};

TaskInfoDialog.propTypes = {
  item: PropTypes.object,
  notesPhotos: TaskNotesPhotos.isRequired,
  siteId: PropTypes.string.isRequired,
};

export default TaskInfoDialog;
