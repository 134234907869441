/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ArrowForwardIcon } from 'components/Layout/Icons';
import { ButtonFlat } from 'components/Layout/Buttons';
import { FieldCheckbox } from 'components/Layout/Inputs';

export const Container = styled(Flex).attrs({
  align: 'center',
  flex: 'none',
  justify: 'space-between',
  p: 1,
  w: 1,
})`
  box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 6px, rgba(0, 0, 0, 0.12) 0px -1px 4px;
`;

export const CheckboxWrapper = styled(Flex).attrs({
  flex: 'auto',
})``;

export const StartButton = styled(ButtonFlat).attrs({
  icon: <ArrowForwardIcon />,
  label: 'Start',
  style: ({ module, theme }) => ({
    color: module === 'home' ? 'inherit' : module === 'health' ? theme.textGray : theme.primaryWhite,
  }),
})`
  flex: none;

  div {
    span {
      font-size: 14px !important;
    }
  }
`;

export const StopFromShowing = styled(FieldCheckbox).attrs({
  iconStyle: ({ module, theme }) => ({
    fill: module === 'home' ? theme.modalsContentColor : module === 'health' ? theme.textGray : theme.primaryWhite,
  }),
  label: 'Stop this screen from showing',
  labelStyle: ({ module, theme }) => ({
    color: module === 'home' ? 'inherit' : module === 'health' ? theme.textGray : theme.primaryWhite,
  }),
})``;
