import styled from 'styled-components';

export const radioStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const FormContainer = styled.div`
  margin: 0 20px 20px;
  display: flex;
`;

export const LabelsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelsContainer = styled.div`
  display: flex;
  height: 35px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export const Label = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;
