export const constraints = {
  0: 'is',
  1: 'is not',
  2: 'begins with',
  4: 'ends with',
  8: 'contains',
  16: 'does not contain',
};

export const ignoreRules = ['SiteIgnore', 'UnitIgnore', 'ItemIgnore'];

export const ignoreRulesBySection = {
  location: {
    name: 'SiteIgnore',
    label: 'Sites',
  },
  unit: {
    name: 'UnitIgnore',
    label: 'List Templates (Units)',
  },
  item: {
    name: 'ItemIgnore',
    label: 'Items',
  },
};

export const types = {
  0: 'fixed - one time',
  1: 'dynamic - always on',
};

export const matchOperator = {
  0: 'All',
  1: 'Any of the following rules',
};

export const optionNames = {
  location: [
    { name: 'Site Name', value: 'SiteName' },
    { name: 'Site ID', value: 'SiteID' },
    { name: 'Site Country', value: 'SiteCountry' },
    { name: 'Site City', value: 'SiteCity' },
    { name: 'Site ZIP Code', value: 'SiteZIPCode' },
  ],
  unit: [
    { name: 'Unit Name', value: 'UnitName' },
    { name: 'Unit Description', value: 'UnitDescription' },
    { name: 'Unit Model', value: 'UnitModelNumber' },
    { name: 'Unit Location', value: 'UnitLocationDescription' },
    { name: 'Unit Vendor Name', value: 'UnitVendorName' },
    { name: 'Unit Built/Year', value: 'UnitYearBuilt' },
  ],
  item: [
    { name: 'Item Name', value: 'ItemName' },
    { name: 'Item Description', value: 'ItemDescription' },
    { name: 'Item Instructions', value: 'ItemInstructions' },
  ],
};

export const initialOptionName = {
  location: 'SiteName',
  unit: 'UnitName',
  item: 'Itemname',
};
