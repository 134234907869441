import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeConfirmationDialog } from 'redux/confirmationHandler';
import BaseSystemDialog from 'components/Dialogs/BaseSystem';

import * as S from './styled';

import theme from 'theme';

const Confirmation = ({
  confirmationMessage,
  onApprove,
  onReject,
  cancelText,
  confirmText,
  confirmButtonTextColor,
  ...rest
}) => {
  const approve = () => {
    onApprove();
    onReject();
  };

  const actions = [
    <S.CancelButton primary label={cancelText} onClick={onReject} />,
    <S.RedButton secondary label={confirmText} confirmButtonTextColor={confirmButtonTextColor} onClick={approve} />,
  ];

  return (
    <BaseSystemDialog actions={actions} handleClose={onReject} isError titleColor={theme.primaryRed} {...rest}>
      {confirmationMessage}
    </BaseSystemDialog>
  );
};

Confirmation.defaultProps = {
  confirmationMessage: '',
  title: '',
};

Confirmation.propTypes = {
  confirmationMessage: PropTypes.node,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmButtonTextColor: PropTypes.string.isRequired,
};

const mapStateToProps = ({ confirmationHandler }) => ({
  confirmationMessage: confirmationHandler.confirmationMessage,
  onApprove: confirmationHandler.onApprove,
  open: confirmationHandler.confirmationDialogOpened,
  title: confirmationHandler.confirmationDialogTitle,
  cancelText: confirmationHandler.cancelText,
  confirmText: confirmationHandler.confirmText,
  confirmButtonTextColor: confirmationHandler.confirmButtonTextColor,
});

export default connect(mapStateToProps, { onReject: closeConfirmationDialog })(Confirmation);
