import { combineReducers } from 'redux';

import app from './app';
import auth from './auth';
import confirmationHandler from './confirmationHandler';
import documentItem from './document';
import documents from './documents';
import errorHandler from './errorHandler';
import exchange from './exchange';
import exportPdf from './exportPdf';
import exportPdfReport from './exportPdfReport';
import gateways from './gateways';
import globalSettings from './globalSettings';
import tutorials from './tutorials';
import item from './item';
import meteringGroups from './meteringGroups';
import partOptions from './partOptions';
import parts from './parts';
import people from './people';
import queue from './queue';
import schedule from './schedule';
import settings from './settings';
import sites from './sites';
import statusIndex from './statusIndex';
import sensors from './sensors';
import tasks from './tasks';
import tenants from './tenants';
import unit from './unit';
import units from './units';
import versionLegals from './versionLegals';
import tiers from './tiers';
import health from './health';
import distributions from './distributions';
import teams from './teams';
import shortcuts from './shortcuts';
import newSites from './newSites';
import listTemplates from './listTemplates';

const rootReducer = combineReducers({
  app,
  auth,
  confirmationHandler,
  documentItem,
  documents,
  errorHandler,
  exchange,
  exportPdf,
  exportPdfReport,
  gateways,
  globalSettings,
  tutorials,
  health,
  item,
  meteringGroups,
  partOptions,
  parts,
  people,
  queue,
  schedule,
  sensors,
  settings,
  sites,
  statusIndex,
  tasks,
  tenants,
  unit,
  units,
  versionLegals,
  tiers,
  distributions,
  teams,
  shortcuts,
  newSites,
  listTemplates,
});

export default rootReducer;
