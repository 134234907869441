import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UnitType } from 'configs/propTypes';
import { actions as unitsActions } from 'redux/units';
import { actions as unitActions } from 'redux/unit';
import { schedulerToggle } from 'configs/toggles';
import { setSchedulerType } from 'helpers/storage';

import Subheader from 'components/Subheader';
import EmptyList from 'components/EmptyList';
import Toggle from 'components/Toggle';
import TableList from 'components/TableList';
import { ButtonIcon } from 'components/Layout/Buttons';
import { BackIcon } from 'components/Layout/Icons';

import { searchItemByKeyword } from 'helpers';

import InfoList from './InfoList';
import { tableColumns } from './constants';

import * as S from './styled';

class UnitsList extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.shape().isRequired,
    }).isRequired,
    requestFetchUnitsList: PropTypes.func.isRequired,
    selectedSite: PropTypes.string.isRequired,
    unitsList: PropTypes.arrayOf(UnitType).isRequired,
    getUnitItemsTasksRequest: PropTypes.func.isRequired,
    unitWithItemsTasksList: PropTypes.array.isRequired,
  };

  state = {
    searchData: '',
    type: (this.props.history.location.state && this.props.history.location.state.type) || 'Manual',
    unitToEdit: {},
    isModalVisible: false,
  };

  componentDidMount() {
    const { selectedSite } = this.props;
    const { type } = this.state;

    setSchedulerType(type);
    this.props.requestFetchUnitsList({
      includeChecklistData: true,
      filter: {
        SiteID: selectedSite,
        checklistType: type,
      },
    });
  }

  filterShortcutType = item =>
    (this.state.type === 'Shortcut' && item.TotalCountChecklist > 0) || this.state.type === 'Manual';

  searchInList = event => {
    this.setState({
      searchData: event.target.value,
    });
  };

  openListTemplate = id => {
    this.props.history.push(`/units/items/${id}`);
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  typeHandler = status => {
    const { selectedSite } = this.props;
    this.props.requestFetchUnitsList({
      includeChecklistData: true,
      filter: {
        SiteID: selectedSite,
        checklistType: status.title,
      },
    });
    this.setState({ type: status.title });
    setSchedulerType(status.title);
  };

  mapList = item => ({
    ...item,
    ModelNumber: (
      <>
        <S.BoldText>Model: </S.BoldText>
        {item.ModelNumber}
      </>
    ),
    Checklists: (
      <>
        <S.BoldText>Checklists: </S.BoldText>
        <S.RedText>{item.TotalCountChecklist}</S.RedText>
      </>
    ),
  });

  renderRightControllCell = item => (
    <td>
      <ButtonIcon onClick={() => this.goForward(item)} tooltip="View Details">
        <S.IconForward />
      </ButtonIcon>
    </td>
  );

  goForward = item => {
    this.props.history.push({
      pathname: `/scheduler/list/${item.Id}`,
    });
  };

  editUnit = item => {
    this.setState({
      unitToEdit: item,
      isModalVisible: true,
    });
    this.props.getUnitItemsTasksRequest({ Id: item.Id });
  };

  closeModal = () => {
    this.setState({
      unitToEdit: {},
      isModalVisible: false,
    });
  };

  render() {
    const { unitsList, unitWithItemsTasksList } = this.props;
    const { searchData, type, unitToEdit, isModalVisible } = this.state;

    return (
      <>
        <Subheader
          title="List Templates"
          isSiteOnLeftSide
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
            },
          ]}
        />
        <S.Toolbar>
          <Toggle config={schedulerToggle} selected={type.toLowerCase()} handler={this.typeHandler} />
        </S.Toolbar>
        {unitsList && unitsList.length ? (
          <S.TableContainer>
            <TableList
              list={unitsList
                .filter(this.filterShortcutType)
                .filter(searchItemByKeyword(tableColumns, searchData))
                .map(this.mapList)}
              tableColumns={tableColumns}
              onRowClick={this.editUnit}
              isPointer
              renderRightControllCell={this.renderRightControllCell}
              handlers={{}}
            />
          </S.TableContainer>
        ) : (
          <EmptyList text="There are no units" />
        )}
        <InfoList
          open={isModalVisible}
          handleClose={this.closeModal}
          unitToEdit={unitToEdit}
          unitWithItemsTasksList={unitWithItemsTasksList}
          openListTemplate={this.openListTemplate}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth: { selectedSite }, units: { unitsList }, unit: { unitWithItemsTasksList } }) => ({
  selectedSite,
  unitsList,
  unitWithItemsTasksList,
});

export default connect(mapStateToProps, {
  requestFetchUnitsList: unitsActions.unitsListRequest,
  getUnitItemsTasksRequest: unitActions.getUnitItemsTasksRequest,
})(UnitsList);
