import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import theme from 'theme';

import withInputModal from 'components/Dialogs/withInputModal';
import { ButtonIcon } from 'components/Layout/Buttons';
import { DeleteIcon } from 'components/Layout/Icons';

import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import RadioGroup from './RadioGroup';
import { mapStateToProps, mapDispatchToProps } from './index.props';

import * as S from './styled';

const DemoTasksDialogForm = ({
  demoTasksCount,
  deleteLoading,
  form,
  values,
  openConfirmationDialog,
  deleteDemoTasks,
}) => {
  const [isWarningTextShown, setIsWarningTextShown] = useState(false);

  const showWarningText = () => setIsWarningTextShown(true);

  const hideWarningText = () => setIsWarningTextShown(false);

  const submitDeleteDemoTasksDialog = () => {
    deleteDemoTasks();

    form.reset({
      ...values,
      DeleteDemoTasks: false,
    });
    showWarningText();
  };

  const openDeleteDemoTasksDialog = () => {
    openConfirmationDialog(
      'Are you sure you want to delete all Demo Tasks?',
      () => submitDeleteDemoTasksDialog(),
      'Deleting Demo Tasks',
    );
  };

  return (
    <S.Formcontainer>
      <S.Overlay show={deleteLoading}>
        <S.Loader />
      </S.Overlay>
      <Field key="IsDemoTasksMode" component={RadioGroup} name="IsDemoTasksMode" />
      <S.Separator />
      <S.DeleteAllDemoTasks>
        <S.CheckboxContainer>
          <Field
            component={S.Checkbox}
            name="DeleteDemoTasks"
            label={`${
              demoTasksCount
                ? `Delete ${demoTasksCount} Demo Task${demoTasksCount === 1 ? '' : 's'} in the system`
                : 'There are no Demo Tasks to delete'
            }`}
            // disabled={!demoTasksCount}
          />
        </S.CheckboxContainer>
        <S.ButtonContainer>
          <ButtonIcon
            onClick={openDeleteDemoTasksDialog}
            // disabled={!values.DeleteDemoTasks}
            tooltip={!values.DeleteDemoTasks ? '' : 'Delete tasks'}
          >
            <DeleteIcon color={theme.primaryBlack} />
          </ButtonIcon>
        </S.ButtonContainer>
      </S.DeleteAllDemoTasks>
      <ConfirmationDialog
        title="Alert"
        headingText="Task deleting will continue in the background until completed."
        open={isWarningTextShown}
        handleClose={hideWarningText}
        label="OK"
        isNext
        onClick={hideWarningText}
      />
    </S.Formcontainer>
  );
};

DemoTasksDialogForm.propTypes = {
  deleteDemoTasks: PropTypes.func.isRequired,
  demoTasksCount: PropTypes.number,
  deleteLoading: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    reset: PropTypes.func.isRequired,
  }).isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
  values: PropTypes.shape({
    DeleteDemoTasks: PropTypes.bool,
  }).isRequired,
};

DemoTasksDialogForm.defaultProps = {
  demoTasksCount: null,
  IsDemoTasksMode: 'DemoTasks',
};

export default connect(mapStateToProps, mapDispatchToProps)(withInputModal(DemoTasksDialogForm));
