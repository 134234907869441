import styled from 'styled-components';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

export const DialogContainer = styled.div`
  height: 470px;
  display: flex;
  flex-direction: column;
`;

export const CurrentSiteName = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.primaryDark};
  font-weight: 300;
  max-width: 600px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SelectorLabel = styled.p`
  text-align: center;
  margin-top: 0;

  & span {
    color: ${({ theme }) => theme.primaryDark};
    font-weight: bold;
  }
`;

export const RadioGroup = styled(RadioButtonGroup)`
  & > div {
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    border-radius: unset;
    position: relative;
    &:first-child {
      padding-top: 30px;
      max-width: 95%;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
`;

export const FieldRadio = styled(RadioButton)``;
