import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions as shortcutActions } from 'redux/shortcuts';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ShortcutRules from 'components/Layout/ShortcutRules';
import {
  generateLocationRules,
  checkOptions,
  createId,
  initialRule,
  generateInitialRules,
  initialValue,
} from 'components/Layout/ShortcutRules/helpers';
import TestrunButton from 'components/Buttons/TestrunButton';

import { getSelectedSite } from 'helpers/storage';

import { ShortCutType } from 'configs/propTypes';

import * as S from './styled';

const getAdditionalSiteId = (siteId, sitesList) => {
  const site = sitesList.find(item => item.id === siteId);
  if (site) return site.additionalId;
  return null;
};

const getStartRules = (isShortcutFromListTemplate, locationRules, createdTemplate) => {
  if (isShortcutFromListTemplate && !locationRules && createdTemplate.Id) {
    return [
      {
        MatchOperator: 0,
        OptionConstraint: 0,
        OptionName: 'SiteName',
        OptionValue: createdTemplate.SiteName,
      },
    ];
  } else if (isShortcutFromListTemplate && !locationRules && !createdTemplate.Id) {
    return [
      {
        MatchOperator: 0,
        OptionConstraint: 0,
        OptionName: 'SiteName',
        OptionValue: Object.values(createdTemplate)[0].SiteName,
      },
    ];
  }
  return locationRules;
};

class SiteRules extends React.PureComponent {
  static propTypes = {
    listOfSites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    shortcut: ShortCutType.isRequired,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    isShortcutFromListTemplate: PropTypes.bool.isRequired,
    additionalSiteId: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      sendLocationOptionsRequest: PropTypes.func.isRequired,
      saveShortcutOptionsRequest: PropTypes.func.isRequired,
    }).isRequired,
    createdTemplate: PropTypes.shape().isRequired,
    error: PropTypes.string.isRequired,
  };

  state = {
    isErrorShown: false,
    isPreviewShown: false,
    locationRules: generateInitialRules({
      rules: getStartRules(
        this.props.isShortcutFromListTemplate,
        this.props.shortcut.LocationRules,
        this.props.createdTemplate,
      ),
      flag: 'location',
    }),
  };

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onChangeIgnoreRules = flag => value => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], isRulesIgnored: value },
    });
  };

  getPreviewResults = values => {
    const valuesToSend = generateLocationRules(values, this.state.locationRules.rules);
    if (!checkOptions(valuesToSend.LocationRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      this.setState({ isPreviewShown: true });
      this.props.actions.sendLocationOptionsRequest(valuesToSend);
    }
  };

  onSubmitForm = ({ values }) => {
    const { shortcut, actions, onNext } = this.props;

    const updatedValues = generateLocationRules(values, this.state.locationRules.rules);
    const valuesToSend = {
      ...updatedValues,
      Id: shortcut.Id,
      Name: shortcut.Name,
    };

    if (!checkOptions(valuesToSend.LocationRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false, isPreviewShown: false });
      actions.sendLocationOptionsRequest({
        ...valuesToSend,
        onNext: () => {
          actions.saveShortcutOptionsRequest(valuesToSend);
          onNext();
        },
      });
    }
  };

  generatePreviewResults = sites =>
    sites.map(item => (
      <S.PreviewItem>
        <S.PreviewItemText>{item.AdditionalSiteID.substring(0, 5)} &nbsp;</S.PreviewItemText>
        <S.PreviewItemText>{item.Name}</S.PreviewItemText>
      </S.PreviewItem>
    ));

  onPrevious = e => {
    e.preventDefault();
    this.setState({ isPreviewShown: false });
    this.props.onBack();
  };

  componentDidUpdate() {
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { shortcut, isShortcutFromListTemplate, listOfSites, additionalSiteId, error } = this.props;
    const { locationRules, isPreviewShown } = this.state;

    const isListOfSiteEmpty = listOfSites === 'empty';
    const isInitialValues = !isShortcutFromListTemplate && isListOfSiteEmpty && locationRules.rules.length === 1;

    return (
      <S.Container>
        <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
        <S.Step>1 of 5</S.Step>
        <S.Title>Tasks will be created for:</S.Title>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <ShortcutRules
                title="Location/Site Rules"
                rules={locationRules}
                isInitialValues={isInitialValues}
                initialValue={initialValue}
                additionalSiteId={additionalSiteId}
                addOption={this.addOption}
                removeOption={this.removeOption}
                onChangeMatch={this.onChangeMatch}
                onChangeIgnoreRules={this.onChangeIgnoreRules}
              />
              <S.PreviewResultsContainer>
                <div />
                <TestrunButton onClick={() => this.getPreviewResults(values)} />
                {!isListOfSiteEmpty && isPreviewShown && (
                  <S.PreviewResultsInfo>{this.generatePreviewResults(listOfSites)}</S.PreviewResultsInfo>
                )}
              </S.PreviewResultsContainer>
              {this.state.isErrorShown && (
                <S.ErrorContainer>
                  Please add at least one rule or select &quot;Ignore rules&quot; to disable rules
                </S.ErrorContainer>
              )}
              {error.length > 0 && <S.ErrorContainer>{error}</S.ErrorContainer>}
              <ActionsContainer>
                <ActionButton label="Previous" onClick={e => this.onPrevious(e)} isNext />
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({
  auth: {
    user: { sitesAvailableToUserFullInfo },
  },
  shortcuts,
  listTemplates,
}) => ({
  listOfSites: shortcuts.listOfSites,
  shortcut: shortcuts.shortcut,
  createdTemplate: listTemplates.createdTemplate,
  additionalSiteId: getAdditionalSiteId(getSelectedSite(), sitesAvailableToUserFullInfo),
  error: shortcuts.error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteRules);
