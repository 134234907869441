import styled from 'styled-components';

import { ButtonFlat } from 'components/Layout/Buttons';

export const DialogContainer = styled.div`
  padding: 0 40px;
  text-align: center;
`;

export const NextButton = styled(ButtonFlat)`
  width: 100% !important;

  span {
    color: ${({ theme, isNext }) => (isNext ? theme.primaryGreen : theme.modalCancelColor)} !important;
  }
`;

export const NotificationText = styled.p`
  font-weight: 300;
  color: ${({ theme }) => theme.primaryRed};
  font-size: 18px;
`;

export const CurrentTenant = styled.h1`
  font-weight: ${({ gateway }) => (gateway ? 'bold' : 'normal')};
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 24px;
`;

export const CardContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  margin-bottom: 40px;
`;

export const CardHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.textGray};
  border-radius: 15px 15px 0 0;
  padding-left: 30px;
`;

export const CardHeaderTitle = styled.h2`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  width: 300px;
  text-align: left;
  text-transform: capitalize;
`;

export const CardHeaderIconContainer = styled.div`
  svg {
    transform: scale(1.3);
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const CardContentContainer = styled.div`
  padding: 30px 80px;
  text-align: 'center';
`;
