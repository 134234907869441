import React from 'react';
import PropTypes from 'prop-types';

import { Container, Data, DataLabel, DataRow, InformationWrapper, Space } from './styled';

const UserInformation = ({ userProfile }) => (
  <Container>
    {userProfile.map(item => (
      <DataRow key={item.label}>
        <DataLabel>{item.label}</DataLabel>
        <InformationWrapper>
          <Space />
          <Data>{item.value}</Data>
        </InformationWrapper>
      </DataRow>
    ))}
  </Container>
);

UserInformation.propTypes = {
  userProfile: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserInformation;
