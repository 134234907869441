import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonIcon } from 'components/Layout/Buttons';

export const Container = styled(Flex)`
  margin: ${({ withDelete }) => (!withDelete ? '-10px -15px' : '-10px -30px')};
`;

export const IconButton = styled(ButtonIcon).attrs({
  iconStyle: ({ theme }) => ({
    color: theme.primaryWhite,
  }),
})`
  word-wrap: normal;
  word-break: normal;
`;
