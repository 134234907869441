import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'material-ui/RadioButton';

import Levels from './Levels';

import * as S from './styled';

const Levels3 = (escalation, options) => [
  <RadioButton key="3" label={<Levels elevated isSelected={options === '3'} escalation={escalation} />} value="3" />,
  <RadioButton key="3Wh" label={<Levels watch isSelected={options === '3Wh'} escalation={escalation} />} value="3Wh" />,
  <RadioButton
    key="3Wg"
    label={<Levels warning isSelected={options === '3Wg'} escalation={escalation} />}
    value="3Wg"
  />,
];

const Levels4 = (escalation, options) => [
  <RadioButton
    key="4"
    label={<Levels elevated watch isSelected={options === '4'} escalation={escalation} />}
    value="4"
  />,
  <RadioButton
    key="4EW"
    label={<Levels elevated warning isSelected={options === '4EW'} escalation={escalation} />}
    value="4EW"
  />,
  <RadioButton
    key="4WW"
    label={<Levels watch warning isSelected={options === '4WW'} escalation={escalation} />}
    value="4WW"
  />,
];

const renderOptions = (escalation, options, quantity) => {
  switch (quantity) {
    case '2':
      return <RadioButton label={<Levels escalation={escalation} />} value="2" />;
    case '3':
      return Levels3(escalation, options);
    case '4':
      return Levels4(escalation, options);
    default:
      return <RadioButton label={<Levels elevated watch warning escalation={escalation} />} value="5" />;
  }
};

const LevelOptionsRadioGroup = ({ escalation, input: { onChange, value }, values: { options, quantity } }) => (
  <S.RadioGroup name="options" valueSelected={value} onChange={(event, data) => onChange(data)}>
    {renderOptions(escalation, options, quantity)}
  </S.RadioGroup>
);

LevelOptionsRadioGroup.propTypes = {
  escalation: PropTypes.string.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    options: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
  }).isRequired,
};

export default LevelOptionsRadioGroup;
