import styled from 'styled-components';

import logoImage from 'components/Layout/Images/logo-small.png';

export const FormContainer = styled.div`
  padding: 20px 50px;
`;

export const InstructionsText = styled.p`
  font-size: 18px;
  margin-top: 0;
`;

export const AdditionalInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AdditionalInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

export const AdditionalInfoText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const Logo = styled.img.attrs({
  src: logoImage,
  alt: 'InCheq logo',
})`
  height: 60px;
  align-self: baseline;
`;
