import styled from 'styled-components';

import { Flex } from 'grid-styled';

export const Title = styled(Flex).attrs({
  align: 'center',
})`
  font-size: 16px;
  font-weight: 900;
  color: ${({ theme }) => theme.primaryBlack};
  display: inline-block;
  max-width: 265px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 25px;

  @media only screen and (max-width: 450px) {
    display: none;
  }
`;

export default Title;
