import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { Form } from 'react-final-form';
import Checkbox from 'material-ui/Checkbox';

import { isSafari } from 'helpers';

export const FormContainer = styled(Form)`
  width: 100%;
  position: relative;
`;

export const FormTag = styled.form`
  overflow: hidden;
  height: 100%;
`;

export const SelectAllCheckboxes = styled(Checkbox).attrs({
  labelStyle: {
    fontSize: '14px',
  },
})`
  height: 25px !important;
  padding: 5px 0 5px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey} !important;
  background-color: ${({ theme }) => theme.primaryWhite};
`;

export const List = styled.div`
  ${isSafari() ? 'height: calc(100vh - 310px);' : 'height: calc(100% - 127px);'}
  padding: 10px 0 10px 12px;
  overflow-y: auto;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
`;

export const NextButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
})`
  height: 77px;
  border-top: 1px solid ${({ theme }) => theme.lightGrey};
`;
