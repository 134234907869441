import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import difference from 'lodash/difference';

import theme from 'theme';
import withDrawer from 'components/Dialogs/Drawer';
import CheckboxDrawerAdapter from 'components/Forms/CheckboxDrawerAdapter';
import { DrawerSaveButton } from 'components/Layout/Buttons';
import { FavoriteIcon, FavoriteBorderIcon } from 'components/Layout/Icons';

import * as S from './styled';

class AssignSitesForm extends Component {
  static propTypes = {
    favoriteSites: PropTypes.shape({
      siteIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    sitesList: PropTypes.arrayOf(
      PropTypes.shape({
        siteName: PropTypes.string.isRequired,
        siteId: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  combineCheckedSites = (newSiteIds, siteIds) => {
    newSiteIds.forEach(item => {
      if (!siteIds.includes(item)) {
        siteIds.push(item);
      }
    });

    return siteIds;
  };

  checkAllSites = (checked, form, siteIds) => {
    const { sitesList } = this.props;
    const newSiteIds = sitesList.map(site => site.siteId);

    form.reset({
      siteIds: checked
        ? this.combineCheckedSites(newSiteIds, [...siteIds])
        : siteIds.filter(siteId => !newSiteIds.includes(siteId)),
    });
  };

  checkedAllValue = siteIds => {
    const { sitesList } = this.props;
    return sitesList.length > 0 && !sitesList.some(item => !siteIds.includes(item.siteId));
  };

  render() {
    const { favoriteSites, onSubmit, sitesList } = this.props;

    return (
      <S.FormContainer
        onSubmit={onSubmit}
        initialValues={favoriteSites}
        render={({ handleSubmit, form, values: { siteIds } }) => {
          const checkedAll = this.checkedAllValue(siteIds);

          return (
            <S.FormTag onSubmit={handleSubmit}>
              <S.SelectAllCheckboxes
                label="Select/Deselect All"
                checked={checkedAll}
                onCheck={(event, checked) => this.checkAllSites(checked, form, siteIds)}
                disabled={!sitesList.length}
                checkedIcon={<FavoriteIcon />}
                uncheckedIcon={<FavoriteBorderIcon />}
                iconStyle={{ fill: checkedAll ? theme.primaryRed : theme.darkGrey }}
              />
              <S.List>
                {sitesList.map(site => (
                  <S.NextButtonContainer key={site.siteId}>
                    <S.CheckboxContainer>
                      <CheckboxDrawerAdapter site={site} isFavorite />
                    </S.CheckboxContainer>
                  </S.NextButtonContainer>
                ))}
              </S.List>
              <S.Footer>
                <DrawerSaveButton
                  disabled={
                    siteIds.length === favoriteSites.siteIds.length &&
                    difference(favoriteSites.siteIds, siteIds).length === 0
                  }
                />
              </S.Footer>
            </S.FormTag>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ({ health: { favoriteSites } }) => ({
  favoriteSites,
});

const AssignSitesWithInput = withDrawer(connect(mapStateToProps)(AssignSitesForm));

export default AssignSitesWithInput;
