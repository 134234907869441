export const tableColumns = [
  { field: 'UnitName', hideable: false, title: 'Unit Name' },
  { field: 'ItemName', hideable: false, title: 'Item Name' },
  { field: 'RuleType', hideable: false, title: 'Task Type' },
  { field: 'OriginatingTaskName', showColumn: false, title: 'Instructions' },
  { field: 'OriginatingChecklistName', showColumn: false, title: 'Checklist Name' },
  { field: 'ExecutionDate', showColumn: false, title: 'Date Scheduled' },
  { field: 'CompletedOn', hideable: false, title: 'Date Completed' },
  { field: 'CompletedBy', showColumn: false, title: 'Completed By' },
  { field: 'ManagedBy', showColumn: false, title: 'Managed By' },
  { field: 'InstructionalNotes', showColumn: false, title: 'Task Notes' },
  { field: 'TaskResult', showColumn: false, title: 'Task Result' },
  { field: 'UserComments', showColumn: false, title: 'User Notes' },
  { field: 'ImageQty', showColumn: false, title: 'Image Qty' },
];

export const downloadMenuItems = [
  { value: 'pdf', label: 'PDF' },
  { value: 'csv', label: 'CSV' },
];
