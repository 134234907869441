import styled from 'styled-components';
import { RadioButtonGroup } from 'material-ui/RadioButton';

import { ButtonIcon } from 'components/Layout/Buttons';
import CheckboxAdapter from 'components/Forms/CheckboxAdapter';
import { AddCircleIcon, RemoveCircleIcon } from 'components/Layout/Icons';

export const IconButton = styled(ButtonIcon)`
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  transform: translate3d(0, 0, 0) !important;
`;

export const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: start;
  width: 100%;
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

export const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export const MatchSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  span {
    display: inline-block;
    margin-right: 10px;
  }
`;

export const RuleContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  grid-column-gap: 10px;
`;

export const RuleButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AddIcon = styled(AddCircleIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
  isScaling: false,
})``;

export const RemoveIcon = styled(RemoveCircleIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const RadioGroup = styled(RadioButtonGroup)`
  display: flex;
  flex: 1;
`;

export const Checkbox = styled(CheckboxAdapter).attrs({
  style: { marginBottom: '15px' },
  iconStyle: ({ input, theme }) => ({ fill: input.value && theme.primaryScheduler }),
})``;
