import styled from 'styled-components';
import Download from 'material-ui/svg-icons/action/get-app';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 44px;
`;

export const Text = styled.span`
  margin-top: 20px;
  text-align: center;
`;

export const DownloadIcon = styled(Download)``;
