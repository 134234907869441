import React from 'react';
import PropTypes from 'prop-types';

import { BackButton, BackLink, ButtonsWrapper, IconArrowBack, SendButton } from './styled';

const Controls = ({ loading, title }) => (
  <ButtonsWrapper>
    <BackLink to="/login">
      <BackButton disabled={loading} icon={<IconArrowBack />} tabIndex={-1} />
    </BackLink>
    <SendButton disabled={loading}>{title}</SendButton>
  </ButtonsWrapper>
);

Controls.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default Controls;
