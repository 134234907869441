import React from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

// Wrap table in div outside of this component and configure specific styles with css selectors

const TableList = ({
  tableHeads,
  list,
  tableColumns,
  renderLeftControllCell,
  renderRightControllCell,
  renderLeftControllTableHead,
  renderRightControllTableHead,
  onColumnHeaderClick,
  handlers,
  checked,
  checkedAll,
  onRowClick,
  isPointer,
}) => (
  <S.Table>
    {tableHeads && (
      <thead>
        <S.TableRow>
          {renderLeftControllTableHead(list)}
          {tableHeads?.map(
            (
              { title, field, headerIcon, handlerName, cell, showColumn = true, isSortable = false, colSpan },
              index,
            ) => {
              if (!showColumn) {
                return null;
              }

              return cell ? (
                <S.TableHeaderCell key={index}>{cell(handlers[handlerName], checkedAll)}</S.TableHeaderCell>
              ) : (
                <S.TableHeaderCell
                  key={index}
                  onClick={isSortable ? () => onColumnHeaderClick(title, field) : null}
                  colSpan={colSpan}
                >
                  {headerIcon && <ButtonIcon onClick={handlers[handlerName]}>{headerIcon}</ButtonIcon>}
                  <S.Value>{title}</S.Value>
                </S.TableHeaderCell>
              );
            },
          )}
          {renderRightControllTableHead(tableHeads)}
        </S.TableRow>
      </thead>
    )}
    <tbody>
      {list.map((item, rowIndex) => (
        <S.TableRow key={item.Id} isPointer={isPointer} onClick={() => onRowClick && onRowClick(item)}>
          {renderLeftControllCell(item)}
          {tableColumns.map(
            ({ field, icon, handlerName, iconAttribute, cell, tooltip, isDisabled, showColumn = true }, index) => {
              if (!showColumn) {
                return null;
              }

              return cell ? (
                <S.TableCell key={`${index}${item.Id}`}>{cell(rowIndex, handlers[handlerName], checked)}</S.TableCell>
              ) : (
                <S.TableCell key={`${item.Id}-${index}`}>
                  {icon && (
                    <ButtonIcon
                      data-icon={item[iconAttribute]}
                      onClick={() => handlers[handlerName]?.(item)}
                      tooltip={tooltip && tooltip}
                      disabled={isDisabled}
                    >
                      {icon}
                    </ButtonIcon>
                  )}
                  <S.Value>{item[field]}</S.Value>
                </S.TableCell>
              );
            },
          )}
          {renderRightControllCell(item)}
        </S.TableRow>
      ))}
    </tbody>
  </S.Table>
);

TableList.propTypes = {
  tableHeads: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      headerIcon: PropTypes.node,
    }),
  ),
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      field: PropTypes.string,
      icon: PropTypes.node,
      handlerName: PropTypes.string,
      iconAttribute: PropTypes.string,
    }),
  ).isRequired,
  handlers: PropTypes.object,
  renderRightControllCell: PropTypes.func,
  checked: PropTypes.arrayOf(PropTypes.bool),
  renderLeftControllCell: PropTypes.func,
  renderLeftControllTableHead: PropTypes.func,
  renderRightControllTableHead: PropTypes.func,
  checkedAll: PropTypes.bool,
  isPointer: PropTypes.bool,
  onRowClick: PropTypes.func,
  onColumnHeaderClick: PropTypes.func,
};

TableList.defaultProps = {
  renderRightControllCell: () => null,
  handlers: {},
  checked: [],
  checkedAll: false,
  renderLeftControllCell: () => null,
  renderLeftControllTableHead: () => null,
  renderRightControllTableHead: () => null,
  onRowClick: () => null,
  tableHeads: null,
  isPointer: false,
  onColumnHeaderClick: () => null,
};

export default TableList;
