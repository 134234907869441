import styled from 'styled-components';
import { Flex } from 'grid-styled';

import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { SettingsIcon } from 'components/Layout/Icons';

export const Container = styled(Flex).attrs({
  column: true,
  align: 'center',
})`
  padding: 0 40px 20px;
`;

export const TextField = styled(TextFieldAdapter).attrs({
  disabled: true,
})``;

export const IconSettings = styled(SettingsIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})`
  transform: scale(1.7);
`;

export const EmptyList = styled.div`
  margin: 50px 0;
`;

export const NotificationIconContainer = styled.div`
  width: 100%;
  padding: 10px 70px 0 0;
  display: flex;
  justify-content: flex-end;
`;
