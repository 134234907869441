import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, Provider, ReactReduxContext } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { ButtonIcon } from 'components/Layout/Buttons';
import { Dialog, ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as scheduleActions } from 'redux/schedule';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ScheduleInfo } from 'configs/propTypes';
import TasksTable from './TasksTable';
import OverviewDialog from './OverviewDialog';
import { generateDate } from '../../../SchedulesList/AddChecklistModal/helpers';

import theme from 'theme';

import * as S from './styled';

const overrideValues = {
  0: 1,
  1: 7,
  2: 31,
  4: 365,
};

const returnAssignmentType = (type, assigneeId) => {
  if (type === 0) {
    return assigneeId ? 'Person' : 'Open Assignment';
  }
  return 'Team';
};

const getFilteredItemsWithTasks = (itemsWithTasks, missingTasks) => {
  const tmpArr = itemsWithTasks.slice();
  const missingArr = [];
  tmpArr.forEach(item => {
    const tmpTasks = item.TaskTemplates.slice();
    const filteredArr = tmpTasks.filter(task => missingTasks.find(missingTask => missingTask.Id === task.Id));
    if (filteredArr.length) {
      filteredArr.forEach(itemInside => {
        itemInside.ItemName = item.Name;
      });
    }
    missingArr.push(filteredArr);
  });
  return missingArr;
};

class ShortcutSetup extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      requestMissingTasks: PropTypes.func.isRequired,
      requestUnitItems: PropTypes.func.isRequired,
      requestCurrentUnit: PropTypes.func.isRequired,
      requestEditSchedule: PropTypes.func.isRequired,
      requestSchedulesList: PropTypes.func.isRequired,
      requestScheduleTemplates: PropTypes.func.isRequired,
      requestChecklistStatus: PropTypes.func.isRequired,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    scheduleInfo: ScheduleInfo.isRequired,
    currentUnit: PropTypes.shape({
      SiteName: PropTypes.string,
      AdditionalSiteId: PropTypes.string,
      Name: PropTypes.string,
    }).isRequired,
    unitId: PropTypes.string.isRequired,
    scheduleId: PropTypes.string.isRequired,
    missingTasks: PropTypes.array.isRequired,
    schedulesTrigger: PropTypes.number.isRequired,
    unitTasks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    details: PropTypes.shape({
      Name: PropTypes.string,
      TeamName: PropTypes.string,
      ChecklistAssignmentType: PropTypes.number,
      AssigneeID: PropTypes.string,
    }).isRequired,
    currentChecklistStatus: {
      RequireAttentionCount: PropTypes.number,
      InProgressCount: PropTypes.number,
      CompletedCount: PropTypes.number,
    }.isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  state = {
    tasksToAdd: [],
    tasksToSend: [],
    isConfirmOpen: false,
    isConfirmationOverrideOpened: false,
    overrideTime: false,
  };

  componentDidMount() {
    const { unitId, actions, scheduleId } = this.props;
    actions.requestCurrentUnit({ unitId });
    actions.requestUnitItems({ unitId });
    actions.requestChecklistStatus({ checklistId: scheduleId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ step: 0, currentSite: null });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setState({
        tasksToAdd: getFilteredItemsWithTasks(this.props.unitTasks, this.props.missingTasks),
        tasksToSend: [],
      });
    }
  }

  onAddTask = (index, itemId, itemIndex) => {
    const array = this.state.tasksToAdd.slice();
    const tmpArr = this.state.tasksToSend.slice();
    tmpArr.push(this.state.tasksToAdd[itemIndex][index]);
    this.setState({ tasksToSend: tmpArr });
    array[itemIndex].splice(index, 1);
    this.setState({ tasksToAdd: array });
  };

  onAddItem = index => {
    const array = this.state.tasksToAdd.slice();
    const tmpArr = this.state.tasksToSend.slice();
    const arrToConcat = tmpArr.concat(array[index]);
    this.setState({ tasksToSend: arrToConcat });
    array.splice(index, 1);
    this.setState({ tasksToAdd: array });
  };

  onSubmit = () => {
    const { unitId, actions, scheduleInfo, details } = this.props;
    const { tasksToSend, overrideTime } = this.state;
    const clone = cloneDeep(scheduleInfo);

    const taskTemplates = tasksToSend.map(item => ({ id: item.Id }));

    actions.requestEditSchedule({
      dataToSend: {
        ...clone,
        ScheduleDto: {
          ...clone.ScheduleDto,
          ScheduleData: {
            ...clone.ScheduleDto.ScheduleData,
            DailyRepeatUntil: generateDate(clone.ScheduleDto.ScheduleData.DailyRepeatUntil),
            DailyStartDate: generateDate(clone.ScheduleDto.ScheduleData.DailyStartDate),
          },
        },
      },
      ignoreLeadTime: overrideTime,
      unitId,
      currentChecklist: {
        PersonName: details?.AssigneeID ? details.AssigneeID : null,
        AssignmentType: details?.AssigneeID ? 2 : 0,
      },
      taskTemplates,
      isAssignmentChanged: true,
      onSuccess: () => {
        this.handleOverviewClose();
        this.onClose();
      },
    });
  };

  openOverview = () => {
    const { scheduleInfo, schedulesTrigger } = this.props;
    const diff = moment
      .duration(moment(scheduleInfo.ScheduleDto.ScheduleData.DailyStartDate).diff(moment(new Date())))
      .asDays();

    if (diff < overrideValues[schedulesTrigger]) {
      this.setState({ isConfirmationOverrideOpened: true });
    } else {
      this.setState({ isConfirmOpen: true });
    }
  };

  closeConfirmationDialog = () => {
    this.setState({ isConfirmationOverrideOpened: false });
  };

  confirmConfirmationDialog = isConfirm => {
    this.setState({ isConfirmationOverrideOpened: false, isConfirmOpen: true, overrideTime: isConfirm });
  };

  handleOverviewClose = () => {
    const { unitId, actions, scheduleId } = this.props;

    actions.requestMissingTasks({ checklistId: scheduleId, unitId });
    actions.requestScheduleTemplates({ scheduleId });
    this.setState({ isConfirmOpen: false });
  };

  onClose = () => {
    const { handleClose } = this.props;

    this.setState({
      tasksToAdd: getFilteredItemsWithTasks(this.props.unitTasks, this.props.missingTasks),
      tasksToSend: [],
    });
    handleClose();
  };

  render() {
    const { open, isLoading, currentUnit, currentChecklistStatus, people, details } = this.props;
    const { isConfirmOpen } = this.state;
    return (
      <ReactReduxContext.Consumer>
        {
          (ctx => (
            <Dialog
              open={open}
              onRequestClose={this.onClose}
              title="Add Items and Tasks"
              titleColor={theme.primaryBlue}
            >
              <S.DialogContainer>
                <S.Overlay show={isLoading}>
                  <S.Loader />
                </S.Overlay>
                <Provider store={ctx.store}>
                  <S.Container>
                    <S.MainTitle>
                      {currentUnit.Name &&
                        `Site: ${currentUnit.AdditionalSiteId.substring(0, 5)} ${currentUnit.SiteName}`}
                    </S.MainTitle>
                    <S.MainTitle>
                      {'List Template:'} <b>{currentUnit.Name && ` ${currentUnit.Name}`}</b>
                    </S.MainTitle>
                    <S.RedInfo>
                      Checklist:&nbsp;<S.Info>{details.Name}</S.Info>
                    </S.RedInfo>
                    <S.RedInfo>
                      Assigned to:&nbsp;
                      <S.Info>{returnAssignmentType(details.ChecklistAssignmentType, details.AssigneeID)}</S.Info>
                    </S.RedInfo>
                    {details.ChecklistAssignmentType !== 0 ? (
                      <S.RedInfo>
                        Name:&nbsp;<S.Info>{details.TeamName}</S.Info>
                      </S.RedInfo>
                    ) : (
                      <S.RedInfo>
                        Name:&nbsp;
                        <S.Info>
                          {details.AssigneeID &&
                          people.length &&
                          people.find(item => item.Id === this.props.details.AssigneeID)
                            ? people.find(item => item.Id === details.AssigneeID).Name
                            : 'Anyone'}
                        </S.Info>
                      </S.RedInfo>
                    )}
                    <S.RedText>(Missing) Items and Tasks to include</S.RedText>
                    <>
                      {this.state.tasksToAdd.map(
                        (item, index) =>
                          item.length > 0 && (
                            <S.Section>
                              <S.SectionHeader color={theme.primaryLists}>
                                {item[0].ItemName}
                                <ButtonIcon>
                                  <S.IconDelete onClick={() => this.onAddItem(index)} />
                                </ButtonIcon>
                              </S.SectionHeader>
                              <S.SectionBody>
                                <TasksTable list={item} onAdd={this.onAddTask} itemIndex={index} />
                              </S.SectionBody>
                            </S.Section>
                          ),
                      )}
                    </>
                    <S.FooterText>added tasks will disappear from this list</S.FooterText>
                    <ActionsContainer>
                      <ActionButton label="CANCEL/PREVIOUS" onClick={this.onClose} />
                      <ActionButton
                        disabled={this.state.tasksToSend.length === 0}
                        label="APPLY UPDATE"
                        isNext
                        onClick={this.openOverview}
                      />
                    </ActionsContainer>
                  </S.Container>
                </Provider>
              </S.DialogContainer>
              <ConfirmationDialog
                label="YES - OVERRIDE"
                labelCancel="NO - start next period"
                width={'40%'}
                maxWidth={'400px'}
                isCancelNext
                text={
                  <S.ConfirmationContainer>
                    <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
                    <S.ConfirmationText>
                      <b>Override the lead time?</b>
                    </S.ConfirmationText>
                  </S.ConfirmationContainer>
                }
                open={this.state.isConfirmationOverrideOpened}
                onClick={() => this.confirmConfirmationDialog(true)}
                handleClose={this.closeConfirmationDialog}
                onCancelClick={() => this.confirmConfirmationDialog(false)}
              />
              ,
              <OverviewDialog
                open={isConfirmOpen}
                handleClose={this.handleOverviewClose}
                details={details}
                currentChecklistStatus={currentChecklistStatus}
                newTasksCount={this.state.tasksToSend.length}
                people={people}
                onSubmit={this.onSubmit}
                returnAssignmentType={returnAssignmentType}
              />
            </Dialog>
            // eslint-disable-next-line no-extra-bind
          )).bind(this) // Dont forget to bind this
        }
      </ReactReduxContext.Consumer>
    );
  }
}

const mapStateToProps = ({ schedule, unit, settings }) => ({
  currentUnit: schedule.currentUnit,
  isLoading: schedule.loading || unit.loading || schedule.missingLoading,
  schedulesTrigger: settings.list.TasksSchedulesTrigger,
  missingTasks: schedule.missingTasks,
  unitTasks: unit.unitWithItemsTasksList,
  details: schedule.details,
  currentChecklistStatus: schedule.currentChecklistStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
      requestUnitItems: scheduleActions.fetchScheduleUnitItemsRequest,
      requestCurrentUnit: scheduleActions.fetchCurrentUnitRequest,
      requestMissingTasks: scheduleActions.missingTasksListRequest,
      requestEditSchedule: scheduleActions.editScheduleRequest,
      requestScheduleTemplates: scheduleActions.fetchScheduleTemplatesRequest,
      requestChecklistStatus: scheduleActions.checklistStatusRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutSetup);
