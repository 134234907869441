import styled from 'styled-components';
import TextField from 'material-ui/TextField';
import theme from 'theme';

const searchInputStyles = {
  border: `medium double ${theme.primaryHome}`,
  backgroundColor: theme.lightGrey,
  height: '25px',
  padding: '0 12px',
};

const placeholderStyles = {
  color: theme.primaryHome,
  bottom: '1px',
  left: '15px',
  zIndex: 1,
};

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchFieldContainer = styled.div``;

export const SearchField = styled(TextField).attrs({
  inputStyle: ({ inputStyles }) => inputStyles || searchInputStyles,
  hintStyle: ({ hintStyles }) => hintStyles || placeholderStyles,
})`
  height: unset !important;

  input[type='text'] {
    border-radius: ${({ bR }) => bR || '20px'};
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 40px;
`;
