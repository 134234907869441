import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

import { RadioButton } from 'material-ui';
import { isEmpty } from 'lodash';

import BaseDialog from 'components/Dialogs/Base';
import { ActionButton } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import { AssetType, UnitType } from 'configs/propTypes';

import * as S from './styled';

const AssetTypeDialog = ({ isOpen, onRequestClose, assetTypesList, unit, onChange, onRequestCloseAssetCategories }) => {
  const [search, setSearch] = useState('');
  const [assetTypes, setAssetTypes] = useState([]);
  const [selectedAssetType, setSelectedAssetType] = useState('');
  const assetType = assetTypesList.find(({ Id }) => Id === selectedAssetType);
  const { Id, Name } = assetType || {};

  const onSearch = (list, searchData) => {
    setSearch(searchData);
    setAssetTypes(list);
  };

  const onSave = () => {
    onChange('AssetTypeName', Name);
    onChange('AssetTypeID', Id);
    onRequestClose();
    onRequestCloseAssetCategories();
  };

  const onAssetTypeChange = (e, value) => setSelectedAssetType(value);

  useEffect(() => {
    setAssetTypes(assetTypesList);
  }, [assetTypesList]);

  useEffect(() => {
    if (unit.AssetTypeName) {
      setSelectedAssetType(unit.AssetTypeID);
    }
  }, [unit]);

  return (
    <BaseDialog open={isOpen} title="Asset Type" onRequestClose={onRequestClose} titleColor={theme.primarySites}>
      <S.DialogContainer>
        <S.Title>{assetTypesList[0]?.AssetCategoryName}</S.Title>
        <S.SecondaryBlock>
          <S.Label>Select Asset Type From List</S.Label>
          <S.SearchContainer>
            <SearchField
              list={assetTypesList}
              filterBy={['Name']}
              searchData={search}
              borderRadius="10px"
              placeholder="Filter Types"
              underlineShow={false}
              icon={<SearchIcon />}
              setSearchedItems={onSearch}
            />
          </S.SearchContainer>
          <S.GroupRadioButton valueSelected={selectedAssetType} onChange={onAssetTypeChange}>
            {assetTypes.map(el => (
              <RadioButton
                key={el.Id}
                value={el.Id}
                label={el.Name}
                iconStyle={{ fill: el.Id === selectedAssetType ? theme.primaryScheduler : theme.darkGrey }}
                style={S.radioButtonStyle}
              />
            ))}
          </S.GroupRadioButton>
        </S.SecondaryBlock>
        <ActionButton
          type="button"
          label="Save"
          isNext
          labelFontSize="20px"
          onClick={onSave}
          isDisable={isEmpty(assetType)}
          disabled={isEmpty(assetType)}
        />
      </S.DialogContainer>
    </BaseDialog>
  );
};

AssetTypeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  assetTypesList: AssetType.isRequired,
  unit: UnitType.isRequired,
  onChange: PropTypes.func.isRequired,
  onRequestCloseAssetCategories: PropTypes.func.isRequired,
};

export default AssetTypeDialog;
