import styled, { css } from 'styled-components';

import { ButtonFlat } from 'components/Layout/Buttons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AbortButton = styled(ButtonFlat).attrs({
  label: 'Abort',
  secondary: true,
})`
  min-width: 50% !important;

  ${({ disabled }) =>
    disabled
      ? null
      : css`
          span {
            color: ${({ theme }) => theme.partDetailsRed};
          }
        `};
`;

export const CommitButton = styled(ButtonFlat).attrs({
  label: 'Commit',
  primary: true,
})`
  min-width: 50% !important;
  border-left: 1px solid ${({ theme }) => theme.lightGrey} !important;

  ${({ disabled }) =>
    disabled
      ? null
      : css`
          span {
            color: ${({ theme }) => theme.greenButton};
          }
        `};
`;
