import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const RADIO_BUTTON_STYLE = { padding: '5px 0px', fontWeight: 'bold' };

const ShortcutTypeRadioGroup = ({ input, meta, style }) => (
  <RadioButtonGroup
    name="ShortcutType"
    valueSelected={input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    <RadioButton
      value={0}
      label="fixed - one time"
      iconStyle={{ fill: input.value.length === 0 || input.value === 0 ? theme.primaryScheduler : theme.darkGrey }}
      style={RADIO_BUTTON_STYLE}
    />
    <RadioButton
      value={1}
      label="dynamic - always on (autorun)"
      iconStyle={{ fill: input.value === 1 ? theme.primaryScheduler : theme.darkGrey }}
      style={RADIO_BUTTON_STYLE}
    />
  </RadioButtonGroup>
);

ShortcutTypeRadioGroup.propTypes = {
  style: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    visited: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default ShortcutTypeRadioGroup;
