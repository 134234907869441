import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import SelectField from 'components/Forms/SelectFieldAdapter';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import TimePickerAdapter from 'components/Forms/TimePickerAdapter';
import { SelectItem } from 'components/Layout/Selects';

import { timePeriod } from './constants';
import TypeRadioGroup from './TypeRadioGroup';

import * as S from './styled';

const TaskTiming = ({ values, initialValue }) => (
  <S.Section>
    <S.SectionHeader>Task Timing - Rules</S.SectionHeader>
    <S.SectionBody>
      <S.FieldBlockContainer>
        <Field name={'datetime-Archive'} label={'All archived tasks for'} component={S.Checkbox} />
        {values['datetime-Archive'] && (
          <S.FieldRadioContainer>
            <Field
              name={'datetime-Period'}
              component={SelectField}
              style={{ marginTop: '-11px' }}
              hintText={timePeriod[0].name}
            >
              {timePeriod.map(item => (
                <SelectItem key={item.name} value={item.value} primaryText={item.name} />
              ))}
            </Field>
            <Field component={TypeRadioGroup} name="datetime-Type" style={S.typeRadioStyle} />
          </S.FieldRadioContainer>
        )}
      </S.FieldBlockContainer>
      {!values['datetime-Archive'] && (
        <S.TimeBlockContainer>
          <S.TimeFieldBlock>
            <Field
              name="StartDate"
              component={DatePickerAdapter}
              floatingLabelText="Start Date"
              initialValue={initialValue.Name ? new Date(initialValue.StartDate) : null}
            />
          </S.TimeFieldBlock>
          <S.TimeFieldBlock>
            <Field
              name="EndDate"
              component={DatePickerAdapter}
              floatingLabelText="End Date"
              initialValue={initialValue.Name ? new Date(initialValue.EndDate) : null}
            />
          </S.TimeFieldBlock>
          <S.TimeFieldBlock>
            <Field
              name="StartTime"
              component={TimePickerAdapter}
              floatingLabelText="Start Time"
              initialValue={initialValue.Name ? new Date(initialValue.StartDate) : null}
            />
          </S.TimeFieldBlock>
          <S.TimeFieldBlock>
            <Field
              name="EndTime"
              component={TimePickerAdapter}
              floatingLabelText="End Time"
              initialValue={initialValue.Name ? new Date(initialValue.EndDate) : null}
            />
          </S.TimeFieldBlock>
        </S.TimeBlockContainer>
      )}
    </S.SectionBody>
  </S.Section>
);

TaskTiming.propTypes = {
  values: PropTypes.shape().isRequired,
  initialValue: PropTypes.shape().isRequired,
};

export default TaskTiming;
