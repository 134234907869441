import React from 'react';
import PropTypes from 'prop-types';
import { PartsListType, PartGroupType } from 'configs/propTypes';

import * as S from './styled';

const ConfirmPartRemovalText = ({ list, type }) =>
  type === 'parts' ? (
    <S.Container>
      <S.Text>Are you sure you want to delete?</S.Text>
      {list.length ? (
        <>
          <S.Text weight="bold">The following parts are actively linked:</S.Text>
          <table>
            <thead>
              <tr>
                <th>OEM Part ID</th>
                <th>My Part ID</th>
              </tr>
            </thead>
            <tbody>
              {list.map(listItem => (
                <tr>
                  <td>{listItem.OEMPartID}</td>
                  <td>{listItem.MyPartID}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </S.Container>
  ) : (
    <S.Container>
      <S.Text>Are you sure you want to delete?</S.Text>
      {list.length ? (
        <>
          <S.Text weight="bold">The following Groups contain actively linked Parts:</S.Text>
          <table>
            <thead>
              <tr>
                <th>Group Name</th>
              </tr>
            </thead>
            <tbody>
              {list.map(listItem => (
                <tr>
                  <td>{listItem.Name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </S.Container>
  );

ConfirmPartRemovalText.propTypes = {
  list: PropTypes.oneOfType([PartsListType, PartGroupType]).isRequired,
  type: PropTypes.oneOf(['parts', 'groups']).isRequired,
};

export default ConfirmPartRemovalText;
