import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonIcon } from 'components/Layout/Buttons';
import { CloseIcon, WarningIcon } from 'components/Layout/Icons';

export const Header = styled(Flex).attrs({
  justify: 'space-between',
})`
  padding: 5px 0 5px 5px !important;
  line-height: normal !important;
  font-size: 16px !important;
  background-color: ${({ theme }) => theme.lightGrey};
`;

export const Title = styled(Flex).attrs({
  align: 'center',
})`
  font-weight: bold;
`;

export const TitleText = styled.div`
  font-weight: bold;
  word-break: break-all;
`;

export const IconWarning = styled(WarningIcon)`
  min-width: 24px;
  transform: scale(0.7);
  margin: 0 10px 0 0 !important;
  position: relative !important;
`;

export const IconButton = styled(ButtonIcon)`
  min-width: 48px;
`;

export const IconClose = styled(CloseIcon)`
  background-color: ${({ theme }) => theme.primaryGrey} !important;
  color: ${({ theme }) => theme.primaryWhite} !important;
  border-radius: 50%;
  transform: scale(0.9);
`;
