import styled from 'styled-components';
import { FieldSelect, SelectItem } from 'components/Layout/Selects';

export const DialogContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

export const ConfirmationText = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const ConfirmationWarning = styled.div`
  font-size: 18px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  button {
    margin: 5px !important;
  }

  flex-direction: ${({ isColumn }) => isColumn && 'column'} !important;
`;

export const Select = styled(FieldSelect)``;

export const Item = styled(SelectItem)``;
