import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import validate from './validator';

import * as S from './styled';

const SiteForm = () => (
  <S.FormContainer>
    <S.FieldBlock>
      <Field component={TextFieldAdapter} name="Name" floatingLabelText="Site Name" />
    </S.FieldBlock>
    <S.FieldBlock>
      <Field component={TextFieldAdapter} name="Id" floatingLabelText="Site ID" />
    </S.FieldBlock>
  </S.FormContainer>
);

class Setup extends Component {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    setCurrentSite: PropTypes.func.isRequired,
  };

  onSubmitForm = ({ onNext, values }) => {
    this.props.setCurrentSite(values);
    onNext();
  };

  render() {
    const { onNext } = this.props;
    return (
      <S.Container>
        <S.TopInfo>
          <S.SubInfo>Fill in some basic Site information.</S.SubInfo>
          <S.SubInfo>More details can be added later</S.SubInfo>
        </S.TopInfo>

        <Form
          onSubmit={values => this.onSubmitForm({ values, onNext })}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm values={values} />
              <ActionsContainer>
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

export default Setup;
