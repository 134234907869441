import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { RadioButtonGroup } from 'material-ui/RadioButton';

export const RadioGroup = styled(RadioButtonGroup)`
  margin: ${({ isBoth }) => (isBoth ? '10px auto' : '10px')};
  display: ${({ isBoth }) => (isBoth ? 'block' : 'flex')};
  flex-wrap: wrap;

  & > div {
    width: ${({ isBoth }) => (isBoth ? '400px' : '50%')} !important;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    border-radius: unset;
    position: relative;
    div {
      align-items: center;
    }
    label > div {
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 485px) {
    & > div {
      width: ${({ isBoth }) => (isBoth ? '100%' : '50%')} !important;
      margin: 0;
    }
  }
`;

export const Container = styled(Flex)``;

export const LevelsImage = styled.div`
  margin-left: 20px;
`;

export const DashedLine = styled.div`
  width: 80px;
  border: 0.5px dashed ${({ theme }) => theme.primaryGrey};
`;
