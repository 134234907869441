import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const UploadImageRow = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  px: 2,
  py: 1,
  wrap: true,
})`
  overflow: hidden;
`;

export const Blank = () => {};
