import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DialogsPull from 'components/Dialogs/DialogsPull';
import ChecklistInfoPage from 'components/Dialogs/Shortcut/InfoPage';
import SiteSelection from 'components/Dialogs/Shortcut/ShortcutSiteSelection';
import Assignment from 'components/Dialogs/Shortcut/Assignment';

import { actions as shortcutActions } from 'redux/shortcuts';

import { ShortCutType } from 'configs/propTypes';

import InfoPage from './InfoPage';
import Setup from './Setup';
import SiteRules from './SiteRules';
import UnitRules from './UnitRules';
import ItemRules from './ItemRules';
import SiteSetup from './ShortcutSiteSetup';
import Pickup from './Pickup';
import Overview from './Overview';
import Confirmation from './Confirmation';
import Final from './Final';
import DemoMode from './DemoMode';

import theme from 'theme';

const STEPS = [
  { Component: InfoPage, color: theme.mainDarkBlue, title: 'New Shortcut' },
  { Component: Setup, color: theme.mainDarkBlue, title: 'Shortcut Setup' },
  { Component: SiteRules, color: theme.mainDarkBlue, title: 'Shortcut Setup' },
  { Component: UnitRules, color: theme.mainDarkBlue, title: 'Shortcut Setup' },
  { Component: ItemRules, color: theme.mainDarkBlue, title: 'Shortcut Setup' },
  { Component: SiteSelection, color: theme.mainDarkBlue, title: 'Shortcut Site Selection' },
  { Component: SiteSetup, color: theme.mainDarkBlue, title: 'Shortcut Setup' },
  { Component: Assignment, color: theme.darkGrey, title: 'Assignment Type for this Site' },
  { Component: Pickup, color: theme.mainDarkBlue, title: 'Shortcut Setup' },
  { Component: Overview, color: theme.mainDarkBlue, title: 'Shortcut Setup' },
  { Component: Confirmation, color: theme.mainGreen, title: 'Confirmation' },
  { Component: Final, color: theme.mainGreen, title: 'Confirmation' },
];

const FIRST_SETUP_STEPS = [
  { Component: ChecklistInfoPage, title: 'Adding a Checklist', color: theme.mainRed },
  { Component: DemoMode, title: 'Alert', color: theme.mainRed },
];

class ShortcutSetup extends Component {
  static propTypes = {
    isShortcutFromListTemplate: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    shortcut: ShortCutType.isRequired,
    isDemoMode: PropTypes.bool.isRequired,
    isDemoTaskMode: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      getShortcutSitesRequest: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    currentSite: null,
    overrideTime: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ currentSite: null });
    }
  }

  setOverride = value => {
    this.setState({ overrideTime: value });
  };

  setCurrentSite = site => {
    this.setState({ currentSite: site });
  };

  onBackToSites = () => {
    const { shortcut, actions } = this.props;
    actions.getShortcutSitesRequest({ shortcut });
  };

  render() {
    const { currentSite, overrideTime } = this.state;
    const { open, handleClose, handleExit, isShortcutFromListTemplate, history, isDemoMode, isDemoTaskMode } =
      this.props;

    const steps = isDemoMode ? [...FIRST_SETUP_STEPS, ...STEPS] : STEPS;
    const siteSelectionStepNumber = isDemoMode ? 7 : 6;

    return (
      <DialogsPull
        open={open}
        steps={steps}
        handleClose={handleClose}
        handleExit={handleExit}
        currentSite={currentSite}
        overrideTime={overrideTime}
        isShortcutFromListTemplate={isShortcutFromListTemplate}
        siteSelectionStepNumber={siteSelectionStepNumber}
        isDemoTaskMode={isDemoTaskMode}
        history={history}
        onSiteSelect={this.setCurrentSite}
        setOverride={this.setOverride}
        onBackToSites={this.onBackToSites}
      />
    );
  }
}

const mapStateToProps = ({ shortcuts, settings }) => ({
  listOfSites: shortcuts.listOfSites,
  shortcut: shortcuts.shortcut,
  currentChecklist: shortcuts.currentChecklist,
  isDemoTaskMode: settings.list.IsDemoTasksMode,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutSetup);
