import React from 'react';
import { Field } from 'react-final-form';

import { SensorTypeType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import validate from './validator';

import * as S from './styled';

const AddEditSensorTypeDialogForm = () => (
  <S.Container>
    <Field component={TextFieldAdapter} name="Type" floatingLabelText="Sensor Template Name" maxLength="255" />
    <Field component={S.Textarea} name="Notes" floatingLabelText="Sensor Template Notes" rows={4} />
  </S.Container>
);

const AddEditSensorTypeDialogWithInput = withInputModal(AddEditSensorTypeDialogForm);

const AddEditSensorTypeDialog = props => (
  <AddEditSensorTypeDialogWithInput initialValues={{ ...props.selectedSensorType }} validate={validate} {...props} />
);

AddEditSensorTypeDialog.propTypes = {
  selectedSensorType: SensorTypeType,
};

AddEditSensorTypeDialog.defaultProps = {
  selectedSensorType: {},
};

export default AddEditSensorTypeDialog;
