import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as authActions } from 'redux/auth';

import SuccessDialog from './SuccessDialog';
import TextInput from './TextInput';
import { signUpInputFields, entryExists } from './constants';
import validate from './validator';
import * as S from './styled';

class SignUpForm extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      openErrorDialog: PropTypes.func.isRequired,
      requestDemoUserName: PropTypes.func.isRequired,
      activateAccountRequest: PropTypes.func.isRequired,
    }).isRequired,
    authMode: PropTypes.oneOf(['info', 'signup']).isRequired,
    token: PropTypes.string.isRequired,
    tenantName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    goToLogin: PropTypes.func.isRequired,
  };

  state = {
    successDialogOpen: false,
    showPassword: false,
  };

  openSuccessDialog = () => this.setState({ successDialogOpen: true });

  closeSuccessDialog = form => {
    form.reset();
    this.setState({ successDialogOpen: false });
    this.props.goToLogin();
  };

  activateField = (values, value) => {
    const { tenantName, token } = this.props;
    if (!value) {
      this.props.actions.openErrorDialog(entryExists, 'Error');
    } else {
      this.props.actions.activateAccountRequest({
        values,
        tenantName,
        token,
        openSuccessDialog: this.openSuccessDialog,
      });
    }
  };

  onFormSubmit = values => {
    const { actions, userName } = this.props;
    if (values.name !== userName) {
      actions.requestDemoUserName({ values, cb: this.activateField });
    } else {
      this.activateField(values, true);
    }
  };

  handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  render() {
    const { authMode, tenantName, userName } = this.props;
    const { successDialogOpen, showPassword } = this.state;

    return (
      <Form
        onSubmit={this.onFormSubmit}
        validate={validate}
        initialValues={{ name: userName }}
        render={({ handleSubmit, form, submitting, values }) => (
          <S.SignUpContainer name={values.name} password={values.submittedPassword} authMode={authMode}>
            <S.AccountsInfoContainer>
              <S.AccountText>You have been invited to setup an account with</S.AccountText>
              <S.AccountTitle>{tenantName}</S.AccountTitle>
            </S.AccountsInfoContainer>
            <S.FormContainer onSubmit={handleSubmit} authMode={authMode}>
              {signUpInputFields.map(
                ({ fieldName, placeholder, label, type }) =>
                  ((values.submittedPassword && values.name) ||
                    fieldName === 'submittedPassword' ||
                    fieldName === 'name') && (
                    <>
                      <S.LabelContainer>
                        <S.Label>{label}</S.Label>
                        {fieldName !== 'phone' && <S.RequiredIcon> ★</S.RequiredIcon>}
                      </S.LabelContainer>
                      <S.FieldContainer key={fieldName}>
                        <TextInput
                          type={fieldName === 'submittedPassword' && showPassword ? 'text' : type}
                          name={fieldName}
                          placeholder={placeholder}
                        />
                        <S.PasswordShowButton
                          isPassword={type === 'password'}
                          type="button"
                          onClick={this.handleShowPassword}
                        >
                          {showPassword ? 'hide' : 'show'}
                        </S.PasswordShowButton>
                      </S.FieldContainer>
                    </>
                  ),
              )}
              {values.name && values.submittedPassword && values.firstName && values.lastName && (
                <S.SubmitButton type="submit" disabled={submitting}>
                  Activate Account
                </S.SubmitButton>
              )}
              <SuccessDialog open={successDialogOpen} onClose={() => this.closeSuccessDialog(form)} />
            </S.FormContainer>
          </S.SignUpContainer>
        )}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isDemoUserNameExists: auth.isDemoUserNameExists,
  isTenantNameExists: auth.isDemoTenantNameExists,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openErrorDialog,
      requestDemoUserName: authActions.checkIsDemoUserNameExistsRequest,
      activateAccountRequest: authActions.activateAccountRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
