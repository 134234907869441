import React from 'react';
import styled from 'styled-components';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';

const formatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}).format;

const Picker = props => <DatePicker formatDate={formatter} {...props} />;

const Time = props => <TimePicker {...props} />;

export const PickerTime = styled(Time).attrs({
  fullWidth: true,
  okLabel: 'Done',
})``;

export const PickerDate = styled(Picker).attrs({
  fullWidth: true,
  okLabel: 'Done',
})``;
