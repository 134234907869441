import styled from 'styled-components';

export const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const ManagerName = styled.span`
  display: inline-block;
  padding: 20px 0 0 25px;
  font-weight: 300;
`;

export const ViewDescription = styled.span`
  display: inline-block;
  font-size: 12px;
  padding: 20px 0 0 25px;
`;

export const RedText = styled.span`
  color: ${({ theme }) => theme.primaryRed};
`;

export const List = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
`;

export const Unit = styled.div`
  padding-left: 15px;
`;

export const UnitTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  padding: 0 0 0 10px;
`;

export const Items = styled.ul`
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  cursor: pointer;
  padding: 5px;
  align-items: center;

  span {
    &:first-child {
      display: inline-block;
      padding-right: 10px;
    }

    &:last-child {
      display: inline-block;
      padding-left: 10px;
      flex: 1;
    }
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
