import { returnIds } from './formatters';

const initialState = {
  error: '',
  localLoading: false,
  loading: false,
  loadingCurrentUnit: false,
  loadingList: false,
  loadingItems: false,
  loadingDetails: false,
  loadingStatus: false,
  loadingPeriod: false,
  loadingTemplates: false,
  loadingUser: false,
  loadingActivation: false,
  missingLoading: false,
  schedulesList: [],
  scheduleDeleted: false,
  currentUnit: {},
  unitItems: [],
  schedulesListIds: [],
  details: {
    ScheduleDto: {},
  },
  detailsActivation: {},
  userManagement: {},
  detailsList: [],
  assignableUsers: [],
  taskAssignableUsers: [],
  taskTemplates: {},
  taskTemplatesAmount: [],
  itemTaskTemplates: [],
  createdChecklist: {},
  missingTasks: [],
  currentChecklistStatus: {},
  taskStatus: {},
};

export const schedulesListRequest = state => ({
  ...state,
  error: '',
  loadingList: true,
  scheduleDeleted: false,
});
export const schedulesListSuccess = (state, { payload }) => ({
  ...state,
  loadingList: false,
  schedulesList: payload,
  schedulesListIds: returnIds(payload),
});
export const schedulesListFailure = (state, { payload }) => ({
  ...state,
  loadingList: false,
  error: payload.message,
});

export const fetchCurrentUnitRequest = state => ({
  ...state,
  error: '',
  loadingCurrentUnit: true,
});
export const fetchCurrentUnitSuccess = (state, { payload }) => ({
  ...state,
  loadingCurrentUnit: false,
  currentUnit: payload,
});
export const fetchCurrentUnitFailure = (state, { payload }) => ({
  ...state,
  loadingCurrentUnit: false,
  error: payload.message,
});

export const missingTasksListRequest = state => ({
  ...state,
  error: '',
  missingLoading: true,
  missingTasks: [],
});
export const missingTasksListSuccess = (state, { payload }) => ({
  ...state,
  missingLoading: false,
  missingTasks: payload,
});
export const missingTasksListFailure = (state, { payload }) => ({
  ...state,
  missingLoading: false,
  error: payload.message,
});

export const checklistStatusRequest = state => ({
  ...state,
  error: '',
  loading: true,
  currentChecklistStatus: [],
});
export const checklistStatusSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  currentChecklistStatus: payload,
});
export const checklistStatusFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchScheduleUnitItemsRequest = state => ({
  ...state,
  error: '',
  loadingItems: true,
});
export const fetchScheduleUnitItemsSuccess = (state, { payload }) => ({
  ...state,
  loadingItems: false,
  unitItems: payload,
});
export const fetchScheduleUnitItemsFailure = (state, { payload }) => ({
  ...state,
  loadingItems: false,
  error: payload.message,
});

export const addScheduleRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const addScheduleSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  createdChecklist: payload,
});
export const addScheduleFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchScheduleDetailsRequest = state => ({
  ...state,
  error: '',
  loadingDetails: true,
});
export const fetchScheduleDetailsSuccess = (state, { payload }) => ({
  ...state,
  loadingDetails: false,
  details: payload,
});
export const fetchScheduleDetailsFailure = (state, { payload }) => ({
  ...state,
  loadingDetails: false,
  error: payload.message,
});

export const fetchChecklistActivationRequest = state => ({
  ...state,
  error: '',
  loadingActivation: true,
});
export const fetchChecklistActivationSuccess = (state, { payload }) => ({
  ...state,
  loadingActivation: false,
  details: payload,
  detailsActivation: payload,
});
export const fetchChecklistActivationFailure = (state, { payload }) => ({
  ...state,
  loadingActivation: false,
  error: payload.message,
});

export const fetchSwitchAutorunRequest = state => ({
  ...state,
  error: '',
  loadingStatus: true,
});
export const fetchSwitchAutorunSuccess = (state, { payload }) => ({
  ...state,
  loadingStatus: false,
  details: payload,
});
export const fetchSwitchAutorunFailure = (state, { payload }) => ({
  ...state,
  loadingStatus: false,
  error: payload.message,
});

export const fetchChecklistRunNextPeriodRequest = state => ({
  ...state,
  error: '',
  loadingPeriod: true,
});
export const fetchChecklistRunNextPeriodSuccess = (state, { payload }) => ({
  ...state,
  loadingPeriod: false,
  details: payload,
});
export const fetchChecklistRunNextPeriodFailure = (state, { payload }) => ({
  ...state,
  loadingPeriod: false,
  error: payload.message,
});

export const getUserManagementRequest = state => ({
  ...state,
  error: '',
  loadingUser: true,
});
export const getUserManagementSuccess = (state, { payload }) => ({
  ...state,
  loadingUser: false,
  userManagement: payload,
});
export const getUserManagementFailure = (state, { payload }) => ({
  ...state,
  loadingUser: false,
  error: payload.message,
});

export const fetchScheduleAssignableUsersRequest = state => ({
  ...state,
  error: '',
  localLoading: true,
});
export const fetchScheduleAssignableUsersSuccess = (state, { payload }) => ({
  ...state,
  localLoading: false,
  assignableUsers: payload,
});
export const fetchScheduleAssignableUsersFailure = (state, { payload }) => ({
  ...state,
  localLoading: false,
  error: payload.message,
});

export const setSupervisorRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const setSupervisorSuccess = state => ({
  ...state,
  loading: false,
});
export const setSupervisorFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const editScheduleRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const editScheduleSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  createdChecklist: payload,
});
export const editScheduleFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const changeScheduleRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const changeScheduleSuccess = state => ({
  ...state,
  loading: false,
});
export const changeScheduleFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const deleteScheduleRequest = state => ({
  ...state,
  error: '',
  loading: true,
  scheduleDeleted: false,
});
export const deleteScheduleSuccess = state => ({
  ...state,
  loading: false,
  scheduleDeleted: true,
});
export const deleteScheduleFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const changeScheduleStatusRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const changeScheduleStatusSuccess = state => ({
  ...state,
  loading: false,
});
export const changeScheduleStatusFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const fetchTaskAssignableUsersRequest = state => ({
  ...state,
  error: '',
  localLoading: true,
});
export const fetchTaskAssignableUsersSuccess = (state, { payload }) => ({
  ...state,
  localLoading: false,
  taskAssignableUsers: payload,
});
export const fetchTaskAssignableUsersFailure = (state, { payload }) => ({
  ...state,
  localLoading: false,
  error: payload.message,
});

export const fetchScheduleTemplatesRequest = state => ({
  ...state,
  error: '',
  loadingTemplates: true,
});
export const fetchScheduleTemplatesSuccess = (state, { payload }) => ({
  ...state,
  loadingTemplates: false,
  taskTemplates: payload,
});
export const fetchScheduleTemplatesFailure = (state, { payload }) => ({
  ...state,
  loadingTemplates: false,
  error: payload.message,
});
export const fetchScheduleTemplatesAmount = (state, { payload }) => ({
  ...state,
  taskTemplatesAmount: payload,
});

export const fetchItemTemplatesRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const fetchItemTemplatesSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  itemTaskTemplates: payload,
});
export const fetchItemTemplatesFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const removeTaskTemplateRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const removeTaskTemplateSuccess = state => ({
  ...state,
  loading: false,
});
export const removeTaskTemplateFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const taskStatusRequest = state => ({
  ...state,
  error: '',
  loading: true,
  taskStatus: {},
});
export const taskStatusSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  taskStatus: payload,
});
export const taskStatusFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});
export const clearTaskStatus = state => ({
  ...state,
  taskStatus: {},
});

export const addChecklistTimeframeRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const addChecklistTimeframeSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  details: payload,
});
export const addChecklistTimeframeFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const addTaskTemplateRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const addTaskTemplateSuccess = state => ({
  ...state,
  loading: false,
});
export const addTaskTemplateFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export const assignEmployeeRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const assignEmployeeSuccess = state => ({
  ...state,
  loading: false,
});
export const assignEmployeeFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload.message,
});

export default initialState;
