import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  OFFLINE: undefined,
  ONLINE: undefined,

  SHOULD_REDIRECT_TO_LOGIN_SET: undefined,
  SHOULD_REDIRECT_TO_LOGIN_UNSET: undefined,

  UPDATE_CURRENT_MODULE: undefined,
  UPDATE_CURRENT_GLOBAL_SETTINGS_MODULE: undefined,

  FETCH_CURRENT_VERSION_REQUEST: undefined,
  FETCH_CURRENT_VERSION_SUCCESS: undefined,
  FETCH_CURRENT_VERSION_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.offline, handlers.offline],
    [actions.online, handlers.online],
    [actions.shouldRedirectToLoginSet, handlers.shouldRedirectToLoginSet],
    [actions.shouldRedirectToLoginUnset, handlers.shouldRedirectToLoginUnset],
    [actions.updateCurrentModule, handlers.updateCurrentModule],
    [actions.updateCurrentGlobalSettingsModule, handlers.updateCurrentGlobalSettingsModule],

    [actions.fetchCurrentVersionRequest, handlers.fetchCurrentVersionRequest],
    [actions.fetchCurrentVersionSuccess, handlers.fetchCurrentVersionSuccess],
    [actions.fetchCurrentVersionFailure, handlers.fetchCurrentVersionFailure],
  ]),
  initialState,
);

export default reducer;
