import { clearDocumentPhotos, updateDocumentPhotos, updateDocumentState } from './helpers';

const initialState = {
  documentDeleted: false,
  documentEdited: false,
  documentItem: {},
  error: '',
  loading: false,
  backgroundImage: { id: '', name: '' },
  footerImage: { id: '', name: '' },
  headerImage: { id: '', name: '' },
};

export const deleteDocumentRequest = state => ({
  ...state,
  documentDeleted: false,
  error: '',
  loading: true,
});

export const deleteDocumentSuccess = state => ({
  ...state,
  documentDeleted: true,
});

export const deleteDocumentFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteDocumentPictureRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteDocumentPictureSuccess = (state, { payload }) => updateDocumentPhotos(state, payload);

export const deleteDocumentPictureFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const documentFetchRequest = state => ({
  ...state,
  documentDeleted: false,
  documentEdited: false,
  error: '',
  loading: true,
});

export const documentFetchSuccess = (state, { payload }) => updateDocumentState(state, payload);

export const documentFetchFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const editDocumentRequest = state => ({
  ...state,
  documentEdited: false,
  error: '',
  loading: true,
});

export const editDocumentSuccess = state => ({
  ...state,
  documentEdited: true,
});

export const editDocumentFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const uploadPhotoRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const uploadPhotoSuccess = (state, { payload }) => updateDocumentPhotos(state, payload);

export const uploadPhotoFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const redirectFlagUnset = state => ({
  ...state,
  documentDeleted: false,
});

export const imagesStateClear = state => clearDocumentPhotos(state);

export default initialState;
