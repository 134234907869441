import styled from 'styled-components';
import { ContentContainer, ListContainer, ListItemContainer } from 'components/Layout/Containers';
import { NotificationOn } from 'components/Layout/Icons';

export const Container = styled(ContentContainer)``;

export const List = styled(ListContainer)``;

export const ListItem = styled(ListItemContainer).attrs({
  disabled: true,
})`
  padding: 16px !important;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey} !important;
  justify-content: flex-start;

  & > div:first-child {
    order: 0;
  }
`;

export const ItemTitle = styled.div`
  width: 100px;
`;

export const IotNotificationIcon = styled(NotificationOn).attrs({
  color: ({ theme }) => theme.primaryBlack,
})``;
