import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  UPLOAD_LOGO_REQUEST: undefined,
  UPLOAD_LOGO_SUCCESS: undefined,
  UPLOAD_LOGO_FAILURE: undefined,

  GET_LOGO_REQUEST: undefined,
  GET_LOGO_SUCCESS: undefined,
  GET_LOGO_FAILURE: undefined,

  DELETE_LOGO_REQUEST: undefined,
  DELETE_LOGO_SUCCESS: undefined,
  DELETE_LOGO_FAILURE: undefined,

  UPLOAD_LOGO_EMAIL_REQUEST: undefined,
  UPLOAD_LOGO_EMAIL_SUCCESS: undefined,
  UPLOAD_LOGO_EMAIL_FAILURE: undefined,

  GET_LOGO_EMAIL_REQUEST: undefined,
  GET_LOGO_EMAIL_SUCCESS: undefined,
  GET_LOGO_EMAIL_FAILURE: undefined,

  DELETE_LOGO_EMAIL_REQUEST: undefined,
  DELETE_LOGO_EMAIL_SUCCESS: undefined,
  DELETE_LOGO_EMAIL_FAILURE: undefined,

  GET_LOGO_MASTER_SUCCESS: undefined,
  GET_LOGO_EMAIL_MASTER_SUCCESS: undefined,

  SET_SETTINGS_REQUEST: undefined,
  SET_SETTINGS_SUCCESS: undefined,
  SET_SETTINGS_FAILURE: undefined,

  SET_SETTINGS_LINKS_REQUEST: undefined,
  SET_SETTINGS_LINKS_SUCCESS: undefined,
  SET_SETTINGS_LINKS_FAILURE: undefined,

  GET_SETTINGS_LINKS_LIST_REQUEST: undefined,
  GET_SETTINGS_LINKS_LIST_SUCCESS: undefined,

  GET_SETTINGS_REQUEST: undefined,
  GET_SETTINGS_SUCCESS: undefined,
  GET_SETTINGS_FAILURE: undefined,

  GET_MASTER_SETTINGS_FOR_ADMIN_REQUEST: undefined,
  GET_MASTER_SETTINGS_FOR_ADMIN_SUCCESS: undefined,
  GET_MASTER_SETTINGS_FOR_ADMIN_FAILURE: undefined,

  GET_MASTER_SETTINGS_LINKS_FOR_ADMIN_REQUEST: undefined,
  GET_MASTER_SETTINGS_LINKS_FOR_ADMIN_SUCCESS: undefined,
  GET_MASTER_SETTINGS_LINKS_FOR_ADMIN_FAILURE: undefined,

  RESET_MASTER_SETTINGS_FOR_ADMIN: undefined,

  GET_DEMO_TASKS_COUNT_REQUEST: undefined,
  GET_DEMO_TASKS_COUNT_SUCCESS: undefined,
  GET_DEMO_TASKS_COUNT_FAILURE: undefined,

  DELETE_DEMO_TASKS_REQUEST: undefined,
  DELETE_DEMO_TASKS_SUCCESS: undefined,
  DELETE_DEMO_TASKS_FAILURE: undefined,

  GET_SIGN_UP_NOTIFICATION_REQUEST: undefined,
  GET_SIGN_UP_NOTIFICATION_SUCCESS: undefined,
  GET_SIGN_UP_NOTIFICATION_FAILURE: undefined,

  SET_SIGN_UP_NOTIFICATION_REQUEST: undefined,
  SET_SIGN_UP_NOTIFICATION_SUCCESS: undefined,
  SET_SIGN_UP_NOTIFICATION_FAILURE: undefined,

  GET_SYSTEM_LIMITS_REQUEST: undefined,
  GET_SYSTEM_LIMITS_SUCCESS: undefined,
  GET_SYSTEM_LIMITS_FAILURE: undefined,

  SET_SYSTEM_LIMITS_REQUEST: undefined,
  SET_SYSTEM_LIMITS_SUCCESS: undefined,
  SET_SYSTEM_LIMITS_FAILURE: undefined,

  UPGRADE_INFO_REQUEST: undefined,
  UPGRADE_INFO_SUCCESS: undefined,
  UPGRADE_INFO_FAILURE: undefined,

  REFRESH: undefined,

  CHANGE_SCHEDULE_TRIGGER: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.changeScheduleTrigger, handlers.changeScheduleTrigger],

    [actions.uploadLogoRequest, handlers.uploadLogoRequest],
    [actions.uploadLogoSuccess, handlers.uploadLogoSuccess],
    [actions.uploadLogoFailure, handlers.uploadLogoFailure],

    [actions.getLogoRequest, handlers.getLogoRequest],
    [actions.getLogoSuccess, handlers.getLogoSuccess],
    [actions.getLogoFailure, handlers.getLogoFailure],

    [actions.getLogoMasterSuccess, handlers.getLogoMasterSuccess],

    [actions.deleteLogoRequest, handlers.deleteLogoRequest],
    [actions.deleteLogoSuccess, handlers.deleteLogoSuccess],
    [actions.deleteLogoFailure, handlers.deleteLogoFailure],

    [actions.uploadLogoEmailRequest, handlers.uploadLogoEmailRequest],
    [actions.uploadLogoEmailSuccess, handlers.uploadLogoEmailSuccess],
    [actions.uploadLogoEmailFailure, handlers.uploadLogoEmailFailure],

    [actions.getLogoEmailRequest, handlers.getLogoEmailRequest],
    [actions.getLogoEmailSuccess, handlers.getLogoEmailSuccess],
    [actions.getLogoEmailFailure, handlers.getLogoEmailFailure],

    [actions.getLogoEmailMasterSuccess, handlers.getLogoEmailMasterSuccess],

    [actions.deleteLogoEmailRequest, handlers.deleteLogoEmailRequest],
    [actions.deleteLogoEmailSuccess, handlers.deleteLogoEmailSuccess],
    [actions.deleteLogoEmailFailure, handlers.deleteLogoEmailFailure],

    [actions.setSettingsRequest, handlers.setSettingsRequest],
    [actions.setSettingsSuccess, handlers.setSettingsSuccess],
    [actions.setSettingsFailure, handlers.setSettingsFailure],

    [actions.setSettingsLinksRequest, handlers.setSettingsLinksRequest],
    [actions.setSettingsLinksSuccess, handlers.setSettingsLinksSuccess],
    [actions.setSettingsLinksFailure, handlers.setSettingsLinksFailure],

    [actions.getSettingsLinksListSuccess, handlers.getSettingsLinksListSuccess],

    [actions.getMasterSettingsLinksForAdminRequest, handlers.getMasterSettingsLinksForAdminRequest],
    [actions.getMasterSettingsLinksForAdminSuccess, handlers.getMasterSettingsLinksForAdminSuccess],
    [actions.getMasterSettingsLinksForAdminFailure, handlers.getMasterSettingsLinksForAdminFailure],

    [actions.getSettingsRequest, handlers.getSettingsRequest],
    [actions.getSettingsSuccess, handlers.getSettingsSuccess],
    [actions.getSettingsFailure, handlers.getSettingsFailure],

    [actions.getMasterSettingsForAdminRequest, handlers.getMasterSettingsForAdminRequest],
    [actions.getMasterSettingsForAdminSuccess, handlers.getMasterSettingsForAdminSuccess],
    [actions.getMasterSettingsForAdminFailure, handlers.getMasterSettingsForAdminFailure],

    [actions.resetMasterSettingsForAdmin, handlers.resetMasterSettingsForAdmin],

    [actions.deleteDemoTasksRequest, handlers.deleteDemoTasksRequest],
    [actions.deleteDemoTasksSuccess, handlers.deleteDemoTasksSuccess],
    [actions.deleteDemoTasksFailure, handlers.deleteDemoTasksFailure],

    [actions.getDemoTasksCountRequest, handlers.getDemoTasksCountRequest],
    [actions.getDemoTasksCountSuccess, handlers.getDemoTasksCountSuccess],
    [actions.getDemoTasksCountFailure, handlers.getDemoTasksCountFailure],

    [actions.getSignUpNotificationRequest, handlers.getSignUpNotificationRequest],
    [actions.getSignUpNotificationSuccess, handlers.getSignUpNotificationSuccess],
    [actions.getSignUpNotificationFailure, handlers.getSignUpNotificationFailure],

    [actions.setSignUpNotificationRequest, handlers.setSignUpNotificationRequest],
    [actions.setSignUpNotificationSuccess, handlers.setSignUpNotificationSuccess],
    [actions.setSignUpNotificationFailure, handlers.setSignUpNotificationFailure],

    [actions.getSystemLimitsRequest, handlers.getSystemLimitsRequest],
    [actions.getSystemLimitsSuccess, handlers.getSystemLimitsSuccess],
    [actions.getSystemLimitsFailure, handlers.getSystemLimitsFailure],

    [actions.setSystemLimitsRequest, handlers.setSystemLimitsRequest],
    [actions.setSystemLimitsSuccess, handlers.setSystemLimitsSuccess],
    [actions.setSystemLimitsFailure, handlers.setSystemLimitsFailure],

    [actions.upgradeInfoRequest, handlers.upgradeInfoRequest],
    [actions.upgradeInfoSuccess, handlers.upgradeInfoSuccess],
    [actions.upgradeInfoFailure, handlers.upgradeInfoFailure],
  ]),
  initialState,
);

export default reducer;
