import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const ColumnContainer = styled(Flex).attrs({
  column: true,
  w: [1, 0.9, 0.8],
})`
  padding: 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const Container = styled(Flex).attrs({
  align: 'center',
  column: true,
  justify: 'space-between',
  p: 2,
  w: [1, 0.95, 0.7, 0.65],
})`
  min-height: 0;

  @media only screen and (min-width: 48em) {
    width: 85% !important;
  }
`;

export const Message = styled(Box)`
  text-align: center;
`;

export const Header = styled(Box)`
  text-align: center;
  font-size: 30px;
  font-weight: 200;
  color: ${({ theme }) => theme.tenantNameColor};
  padding-bottom: 30px;
`;

export const SiteTitle = styled(Header)`
  margin: 15px 0 30px 0;
  padding: 0;
`;
