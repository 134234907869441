import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, ReactReduxContext } from 'react-redux';

import { Dialog } from 'components/Dialogs/v1/Base';

import * as S from './styled';

class DialogsPull extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        Component: PropTypes.elementType,
        title: PropTypes.string,
        color: PropTypes.string,
        withOnClose: PropTypes.string,
        isEdit: PropTypes.bool,
        isActivator: PropTypes.bool,
      }),
    ).isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
  };

  state = {
    step: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;

    if (nextProps.open && !open) {
      this.setState({ step: 0 });
    }
  }

  handlePrev = () => {
    this.setState(({ step }) => ({ step: step - 1 }));
  };

  handleNext = () => {
    this.setState(({ step }) => ({ step: step + 1 }));
  };

  handleStep = step => {
    this.setState({ step });
  };

  render() {
    const { open, handleClose, handleExit, isEdit, steps, ...rest } = this.props;
    const { step } = this.state;

    const Step = steps[step];
    if (Step?.withOnClose) {
      Step.withOnClose = 'delete';
    }
    if (Step?.withOnClose && isEdit) {
      Step.withOnClose = 'edit';
    }

    return (
      <ReactReduxContext.Consumer>
        {ctx => (
          <Dialog
            open={open}
            onRequestClose={() => handleClose(Step.withOnClose)}
            title={Step.title}
            titleColor={Step.color}
          >
            <S.DialogContainer>
              <Provider store={ctx.store}>
                <Step.Component
                  onNext={this.handleNext}
                  onBack={this.handlePrev}
                  onStep={this.handleStep}
                  onClose={handleClose}
                  onExit={handleExit}
                  isEditShortcut={Step?.isEdit}
                  isActivator={Step?.isActivator}
                  {...rest}
                />
              </Provider>
            </S.DialogContainer>
          </Dialog>
        )}
      </ReactReduxContext.Consumer>
    );
  }
}

export default DialogsPull;
