import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import Subheader from 'components/Subheader';
import TableList from 'components/TableList';
import { ButtonIcon } from 'components/Layout/Buttons';
import { AddIcon } from 'components/Layout/Icons';

import { searchItemByKeyword } from 'helpers';
import { actions as statusIndexActions } from 'redux/statusIndex';
import { CharacteristicListType, IndexItemType } from 'configs/propTypes';

import EditColumns from './components/EditColumns';
import IndexDetailsSetup from './components/IndexDetailsSetup';

import * as S from './styled';

class IndexItem extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      fetchCharacteristicListRequest: PropTypes.func.isRequired,
      fetchCharacteristicItemRequest: PropTypes.func.isRequired,
      fetchIndexItemRequest: PropTypes.func.isRequired,
      fetchIndexItemConfigRequest: PropTypes.func.isRequired,
      setIndexItemsStatus: PropTypes.func.isRequired,
    }).isRequired,
    characteristicList: CharacteristicListType.isRequired,
    indexItemConfig: PropTypes.object.isRequired,
    indexItem: IndexItemType.isRequired,
  };

  state = {
    searchKeyword: '',
    isEditColumnsModalOpen: false,
    isSetupModalOpen: false,
    isEditCharacteristicModalOpen: false,
    isConfirmationDialogOpened: false,
  };

  componentDidMount() {
    const {
      actions,
      match: {
        params: { id },
      },
    } = this.props;

    actions.setIndexItemsStatus('pending');
    actions.fetchIndexItemRequest(id);
    actions.fetchIndexItemConfigRequest(id);
    actions.fetchCharacteristicListRequest(id);
  }

  searchInList = e => this.setState({ searchKeyword: e.currentTarget.value });

  goBack = () => this.props.history.push(`/settings/global-index/${this.props.match.params.type}`);

  openEditColumnsModal = () => this.setState({ isEditColumnsModalOpen: true });
  closeEditColumnsModal = () => this.setState({ isEditColumnsModalOpen: false });

  openSetupModal = () => this.setState({ isSetupModalOpen: true });
  closeSetupModal = () => this.setState({ isSetupModalOpen: false, isEditCharacteristicModalOpen: false });

  openConfirmationDialog = () => this.setState({ isConfirmationDialogOpened: true });
  closeConfirmationDialog = () => this.setState({ isConfirmationDialogOpened: false });

  leftButtons = [
    {
      icon: <S.Back />,
      handler: this.goBack,
      hint: 'Back',
      tooltipStyles: { marginTop: -10 },
    },
  ];

  rightButtons = [
    {
      icon: <AddIcon />,
      handler: () => {
        if (isEmpty(this.props.indexItemConfig.DataColumns)) {
          this.openConfirmationDialog();
        } else {
          this.openSetupModal();
        }
      },
      hint: 'Add index item characteristic',
      tooltipStyles: { marginTop: 30, marginRight: 20 },
      tooltipPosition: 'top-left',
    },
  ];

  renderRightControllTableHead = () => {
    const {
      match: {
        params: { type },
      },
    } = this.props;

    if (type === 'iot') {
      return null;
    }

    return (
      <S.TableHeaderCell>
        <ButtonIcon onClick={this.openEditColumnsModal}>
          <S.Edit />
        </ButtonIcon>
      </S.TableHeaderCell>
    );
  };

  onRowClick = row => {
    this.setState({ isEditCharacteristicModalOpen: true });
    this.props.actions.fetchCharacteristicItemRequest(row.Id);
    this.openSetupModal();
  };

  render() {
    const {
      searchKeyword,
      isEditColumnsModalOpen,
      isSetupModalOpen,
      isEditCharacteristicModalOpen,
      isConfirmationDialogOpened,
    } = this.state;
    const {
      characteristicList,
      indexItemConfig,
      indexItem,
      match: {
        params: { type },
      },
    } = this.props;
    const tableList = characteristicList.map(({ DataColumns, Id }) => ({ ...DataColumns, Id }));
    const isDataColumnsEmpty = isEmpty(indexItemConfig.DataColumns);
    const tableConfigs = !isDataColumnsEmpty
      ? Object.keys(indexItemConfig.DataColumns).map(key => ({ title: key, field: key }))
      : [];

    return (
      <>
        <Subheader
          leftButtons={this.leftButtons}
          rightButtons={type === 'manual' ? this.rightButtons : []}
          title="Index Item Characteristics"
          isSearch
          searchData={searchKeyword}
          searchInList={this.searchInList}
          redSubtitle={indexItem.Name}
        />
        <S.TableContainer hasBackgroundColor={!!tableList.length}>
          <TableList
            list={tableList.filter(searchItemByKeyword(tableConfigs, searchKeyword))}
            onRowClick={this.onRowClick}
            tableColumns={tableConfigs}
            tableHeads={tableConfigs}
            renderRightControllTableHead={this.renderRightControllTableHead}
          />
        </S.TableContainer>
        <EditColumns isOpen={isEditColumnsModalOpen} onCloseDialog={this.closeEditColumnsModal} />
        <IndexDetailsSetup
          open={isSetupModalOpen}
          handleClose={this.closeSetupModal}
          isEditModal={isEditCharacteristicModalOpen}
        />
        <ConfirmationDialog
          title="Warning"
          open={isConfirmationDialogOpened}
          handleClose={this.closeConfirmationDialog}
          text={
            <div>
              <S.ConfirmationTitle>No Data Columns!</S.ConfirmationTitle>
              <S.ConfirmationText>Please add some data columns first.</S.ConfirmationText>
              <ActionsContainer paddingValue="0">
                <ActionButton isNext label="OK" onClick={this.closeConfirmationDialog} />
              </ActionsContainer>
            </div>
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ statusIndex: { characteristicList, indexItem, indexItemConfig } }) => ({
  characteristicList,
  indexItem,
  indexItemConfig,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...statusIndexActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexItem);
