import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TenantType, IndustryType } from 'configs/propTypes';

import { EMPTY_GUID } from 'helpers';

import { actions as tenantsActions } from 'redux/tenants';

import { openErrorDialog } from 'redux/errorHandler';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { TenantsModuleIcon } from 'components/Layout/Icons';
import EditPricingDialog from 'components/Dialogs/EditPricingDialog';
import Item from './Item';

class Pricing extends PureComponent {
  static propTypes = {
    error: PropTypes.string.isRequired,
    industryList: PropTypes.arrayOf(IndustryType).isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    tenantsList: PropTypes.arrayOf(TenantType).isRequired,
    requestOverwritePricingValues: PropTypes.func.isRequired,
    requestFetchTiers: PropTypes.func.isRequired,
    requestTenantsList: PropTypes.func.isRequired,
    requestTennantIndustryList: PropTypes.func.isRequired,
    clearTiers: PropTypes.func.isRequired,
  };

  state = {
    currentTenant: {},
    editPricingDialogOpened: false,
    searchData: '',
  };

  componentDidMount() {
    this.props.requestTenantsList();
    this.props.requestTennantIndustryList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  searchInList = e => this.setState({ searchData: e.target.value });

  filterTenantsList = ({ Name }) => Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  openEditPricingDialog = currentTenant => {
    const { industryList } = this.props;

    const industry = industryList.find(({ Name }) => Name === currentTenant.IndustryName);

    if (industry) {
      this.props.clearTiers();
      this.props.requestFetchTiers(industry.MeeteringGroupID);
    }

    this.setState({ editPricingDialogOpened: true, currentTenant });
  };

  closeEditPricingDialog = () => this.setState({ editPricingDialogOpened: false, currentTenant: {} });

  submitPricing = values => {
    const { currentTenant } = this.state;

    if (!values.MasterTierId) {
      values.MasterTierId = EMPTY_GUID;
    }

    const TenantTierDto = {
      TenantId: currentTenant.Id,
      IsOverwritten: false,
      ...values,
    };

    this.props.requestOverwritePricingValues(TenantTierDto);
    this.closeEditPricingDialog();
  };

  render() {
    const { currentTenant, editPricingDialogOpened, searchData } = this.state;
    const { tenantsList } = this.props;

    return (
      <>
        <Subheader
          title="Tenants"
          subTitleIcon={<TenantsModuleIcon />}
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
        />
        <SimpleList
          key="Tenants"
          data={tenantsList.filter(this.filterTenantsList)}
          emptyListMessage="There are no tenants avaliable"
          onItemClick={this.openEditPricingDialog}
          renderItemContent={Item}
        />
        <EditPricingDialog
          title="Edit Pricing"
          open={editPricingDialogOpened}
          handleClose={this.closeEditPricingDialog}
          onSubmit={this.submitPricing}
          tenant={currentTenant}
          modalStyles={{ maxWidth: '75%' }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ tenants }) => ({
  error: tenants.error,
  industryList: tenants.industryList,
  tenantsList: tenants.list,
});

const mapDispatchToProps = {
  openErrorDialog,
  requestOverwritePricingValues: tenantsActions.overwritePricingValuesRequest,
  requestFetchTiers: tenantsActions.fetchTiersRequest,
  requestTenantsList: tenantsActions.tenantsListRequest,
  requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
  clearTiers: tenantsActions.clearTiers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
