import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as tutorialsActions } from 'redux/tutorials';
import TableList from 'components/TableList';
import Subheader from 'components/Subheader';
import SequenceSetupDialog from 'components/Dialogs/SequenceSetupDialog';
import { AddIcon, BackIcon, LowPriorityIcon } from 'components/Layout/Icons';
import { LandingPageSectionType } from 'configs/propTypes';

import { getFeedType, tableConfigs } from './configs';
import VideoFeetSetup from './VideoFeetSetup';

import * as S from './styled';

class CategorySections extends PureComponent {
  state = {
    isVideoFeetSetupDialogOpen: false,
    searchData: '',
    isEditModal: false,
    isSequenceSetupDialogOpen: false,
  };

  componentDidMount() {
    const {
      actions,
      match: {
        params: { id },
      },
    } = this.props;

    actions.fetchLandingPageSectionListRequest(id);
  }

  openVideoFeetSetupDialog = () => this.setState({ isVideoFeetSetupDialogOpen: true });

  closeVideoFeetSetupDialog = () => this.setState({ isVideoFeetSetupDialogOpen: false, isEditModal: false });

  openSequenceSetupDialog = () => this.setState({ isSequenceSetupDialogOpen: true });

  closeSequenceSetupDialog = () => this.setState({ isSequenceSetupDialogOpen: false });

  searchInList = e => this.setState({ searchData: e.target.value });

  goBack = () => this.props.history.push('/tutorials');

  filterList = item => item.Header.toLowerCase().includes(this.state.searchData.toLowerCase());

  onRowClick = item => {
    this.setState({ isEditModal: true });
    this.props.actions.fetchLandingPageSectionRequest(item.Id);
    this.openVideoFeetSetupDialog();
  };

  itemsForReorder = items => {
    if (!items.length) {
      return [];
    }

    return [
      {
        items: items.map(el => ({
          id: el.Id,
          content: el.Header,
          sequence: el.Sequence,
        })),
        content: '',
      },
    ];
  };

  onSubmit = items => {
    const {
      match: {
        params: { id },
      },
      actions,
    } = this.props;
    actions.updateLandingPageSectionSequenceRequest({ items, id });
    this.closeSequenceSetupDialog();
  };

  mapList = item => ({
    ...item,
    FeedType: getFeedType(item.FeedType),
  });

  rightButtons = [
    {
      icon: <LowPriorityIcon />,
      handler: this.openSequenceSetupDialog,
      hint: 'Sequence setup',
      tooltipStyles: { marginTop: -10 },
    },
    {
      icon: <AddIcon />,
      handler: this.openVideoFeetSetupDialog,
      hint: 'Add',
      tooltipStyles: { marginTop: -10 },
    },
  ];

  renderRightControllTableHead = () => <S.TableHeaderCell>Visibility</S.TableHeaderCell>;

  renderRightControllCell = item => {
    const handleClick = e => {
      e.stopPropagation();
      this.props.actions.updateLandingPageSectionRequest(item.Id);
    };

    return (
      <td>
        <S.ButtonIcon
          tooltip={`${item.IsVisible ? 'Hide' : 'Publish'}  Tutorial Section`}
          tooltipPosition="bottom-center"
          onClick={handleClick}
        >
          {item.IsVisible ? <S.PauseIcon /> : <S.PlayIcon />}
        </S.ButtonIcon>
      </td>
    );
  };

  render() {
    const {
      landingPageSectionList,
      match: {
        params: { id, name },
      },
      isLoading,
    } = this.props;
    const { isVideoFeetSetupDialogOpen, isEditModal, searchData, isSequenceSetupDialogOpen } = this.state;

    return (
      <>
        <Subheader
          title="Landing Pages"
          isSiteOnLeftSide
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          zIndex={5}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.goBack,
              hint: 'Back',
              tooltipStyles: { marginTop: -10 },
            },
          ]}
          rightButtons={this.rightButtons}
          redSubtitle={name}
        />
        <S.TableContainer>
          <TableList
            list={landingPageSectionList.map(this.mapList).filter(this.filterList)}
            tableHeads={tableConfigs}
            tableColumns={tableConfigs}
            onRowClick={this.onRowClick}
            renderRightControllCell={this.renderRightControllCell}
            renderRightControllTableHead={this.renderRightControllTableHead}
          />
        </S.TableContainer>
        {isSequenceSetupDialogOpen && (
          <SequenceSetupDialog
            key="Sequence Setup Dialog"
            handleClose={this.closeSequenceSetupDialog}
            data={this.itemsForReorder(landingPageSectionList)}
            isLoading={isLoading}
            onSubmit={this.onSubmit}
            open={isSequenceSetupDialogOpen}
            isNewSaveButton
            listId="UnitItems"
          />
        )}
        <VideoFeetSetup
          isEditModal={isEditModal}
          landingPageID={id}
          landingPageName={name}
          open={isVideoFeetSetupDialogOpen}
          handleClose={this.closeVideoFeetSetupDialog}
        />
      </>
    );
  }
}

CategorySections.propTypes = {
  actions: PropTypes.shape({
    fetchLandingPageSectionListRequest: PropTypes.func.isRequired,
    fetchLandingPageSectionRequest: PropTypes.func.isRequired,
    updateLandingPageSectionRequest: PropTypes.func.isRequired,
    updateLandingPageSectionSequenceRequest: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: {
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    },
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  landingPageSectionList: PropTypes.arrayOf(LandingPageSectionType).isRequired,
};

const mapStateToProps = ({ tutorials: { landingPageSectionList, loading } }) => ({
  landingPageSectionList,
  isLoading: loading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...tutorialsActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySections);
