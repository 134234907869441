import styled from 'styled-components';

import { CenteredXYGreyContainer } from 'components/Layout/Containers';

export const ModalContainer = styled(CenteredXYGreyContainer)`
  display: ${({ isModalOpened }) => (isModalOpened ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex};
`;

export const Drawer = styled.div`
  height: calc(100% - ${({ fullHeight }) => (fullHeight ? '0px' : '64px')});
  background-color: white;
  width: 500px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: ${({ open }) => (open ? 0 : '-500px')};
  top: ${({ fullHeight }) => (fullHeight ? 0 : '64px')};
  z-index: ${({ zIndex }) => zIndex};
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 6px;
  box-sizing: border-box;
`;
