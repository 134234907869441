import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SiteType } from 'configs/propTypes';
import { ExportCSVIcon } from 'components/Layout/Icons';
import {
  CardContent,
  Category,
  CategoryName,
  Container,
  Content,
  Header,
  IconButton,
  Title,
  Trigger,
  UserName,
} from './styled';

class Item extends Component {
  static propTypes = {
    onExport: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(SiteType).isRequired,
    triggersByUserId: PropTypes.objectOf(PropTypes.objectOf(PropTypes.array)).isRequired,
    userId: PropTypes.string.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    expandedToggle: PropTypes.func.isRequired,
  };

  state = {
    isExpanded: false,
  };

  exportUserTriggers = () => {
    this.props.onExport(this.props.userId);
  };

  toggleCollapse = () => {
    this.props.expandedToggle(this.props.userId);
  };

  itemActions = (
    <IconButton onClick={this.exportUserTriggers} tooltip="Export per User">
      <ExportCSVIcon />
    </IconButton>
  );

  renderContent = triggers => (
    <Content>
      {triggers.Site && (
        <Category>
          <CategoryName>Location</CategoryName>
          {triggers.Site.map(item => {
            const triggeredSites = this.props.sites.filter(site => site.Id === item.SiteId);
            let siteName = '';

            if (triggeredSites.length) {
              siteName = triggeredSites[0].Name;
            }

            return (
              <Trigger key={item.TriggeredOnWithTime}>
                {siteName || item.SiteId}, {item.TriggeredOnWithTime}
              </Trigger>
            );
          })}
        </Category>
      )}
      {triggers.Unit && (
        <Category>
          <CategoryName>Unit</CategoryName>
          {triggers.Unit.map(item => (
            <Trigger key={item.TriggeredOnWithTime}>
              {item.UnitName}, {item.TriggeredOnWithTime}
            </Trigger>
          ))}
        </Category>
      )}
      {triggers.Item && (
        <Category>
          <CategoryName>Item</CategoryName>
          {triggers.Item.map(item => (
            <Trigger key={item.TriggeredOnWithTime}>
              {item.ItemName}, {item.TriggeredOnWithTime}
            </Trigger>
          ))}
        </Category>
      )}
    </Content>
  );

  render() {
    const { triggersByUserId, userId, isExpanded } = this.props;

    let total = 0;
    const categories = Object.keys(triggersByUserId[userId]);
    categories.forEach(category => {
      total += triggersByUserId[userId][category].length;
    });

    return (
      <Container expanded={isExpanded} onExpandChange={this.toggleCollapse}>
        <Header
          showExpandableButton
          subtitle={this.itemActions}
          title={
            <Title>
              <UserName>{triggersByUserId[userId][categories[0]][0].TriggeredByUserName}</UserName>
              <div>{total}</div>
            </Title>
          }
        />
        <CardContent expandable>{this.renderContent(triggersByUserId[userId])}</CardContent>
      </Container>
    );
  }
}

const mapStateToProps = ({ sites }) => ({
  sites: sites.list,
});

export default connect(mapStateToProps)(Item);
