import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import difference from 'lodash/difference';

import theme from 'theme';
import { actions as healthActions } from 'redux/health';
import { actions as sensorsActions } from 'redux/sensors';
import { actions as sitesActions } from 'redux/sites';
import Subheader from 'components/Subheader';

import FavoriteSitesDialog from './FavoriteSitesDialog';
import ItemSensorsDialog from './ItemSensorsDialog';
import List from './List';

import { getSitesList } from './selectors';

import * as S from './styled';

class AlertsList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      getAlertTrendsRequest: PropTypes.func.isRequired,
      getFavoriteSitesRequest: PropTypes.func.isRequired,
      getItemSensorsRequest: PropTypes.func.isRequired,
      getItemsWithAlertRequest: PropTypes.func.isRequired,
      requestSitesList: PropTypes.func.isRequired,
      setFavoriteSitesRequest: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    sitesList: PropTypes.arrayOf(
      PropTypes.shape({
        siteName: PropTypes.string.isRequired,
        siteId: PropTypes.string.isRequired,
      }),
    ).isRequired,
    userName: PropTypes.string.isRequired,
    userType: PropTypes.number.isRequired,
  };

  dashboardAutoRefresh = undefined;

  state = {
    alertTrendsVisible: {},
    favoriteSitesDialogOpened: false,
    initialFavoriteSites: [],
    itemSensorsDialogOpened: false,
    searchUserSitesData: '',
    selectedSensor: {},
    toggled: true,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getItemsWithAlertRequest();
    this.dashboardAutoRefresh = setInterval(() => {
      actions.getItemsWithAlertRequest(this.state.toggled ? null : true);
    }, 6e4);
  }

  componentWillUnmount() {
    clearInterval(this.dashboardAutoRefresh);
  }

  goBack = () => {
    this.props.history.push('/home');
  };

  filterSites = site => site.siteName.toLowerCase().includes(this.state.searchUserSitesData.toLowerCase());

  closeFavoriteSitesDialog = () => {
    this.setState({ favoriteSitesDialogOpened: false, searchUserSitesData: '' });
  };

  setInitialFavoriteSites = (initialFavoriteSites, selectedSensor) => {
    this.setState({
      favoriteSitesDialogOpened: true,
      initialFavoriteSites,
      selectedSensor,
    });
  };

  openFavoriteSitesDialog = selectedSensor => {
    const { actions } = this.props;

    actions.requestSitesList();
    actions.getFavoriteSitesRequest({ setInitialFavoriteSites: this.setInitialFavoriteSites, selectedSensor });
  };

  closeItemSensorsDialog = () => {
    this.setState({ alertTrendsVisible: {}, itemSensorsDialogOpened: false });
  };

  openItemSensorsDialog = selectedSensor => {
    const { actions } = this.props;

    actions.getItemSensorsRequest(selectedSensor.Id);
    this.setState({ itemSensorsDialogOpened: true, selectedSensor });
  };

  renderFavoriteSitesTitle = (
    <>
      <S.TitleText>Sites for</S.TitleText>
      <S.TitleText>
        <b>{this.props.userName}</b> ({this.props.userType === 4 ? 'System Administrator' : 'Dashboard User'})
      </S.TitleText>
    </>
  );

  toggleFavorite = () => {
    const { actions } = this.props;
    const { toggled } = this.state;

    actions.getItemsWithAlertRequest(toggled || null);
    this.setState({ toggled: !toggled });
  };

  searchSites = event => this.setState({ searchUserSitesData: event.target.value });

  openAlertTrends = (id, alertTrendsVisible, status) => {
    this.setState({
      alertTrendsVisible: {
        [id]: status ? !alertTrendsVisible[id] : false,
      },
    });
  };

  showAlertTrends = async (id, status) => {
    const { actions } = this.props;
    const { alertTrendsVisible } = this.state;

    if (alertTrendsVisible[id] || !status) {
      this.openAlertTrends(id, alertTrendsVisible);
    } else {
      actions.getAlertTrendsRequest({
        id,
        alertTrendsVisible,
        openAlertTrends: this.openAlertTrends,
        status,
      });
    }
  };

  submitFavoriteSitesDialog = ({ siteIds }) => {
    const { actions } = this.props;
    const { initialFavoriteSites, toggled } = this.state;

    const newFavoriteSites = difference(siteIds, initialFavoriteSites);
    const notFavoriteSites = difference(initialFavoriteSites, siteIds);

    actions.setFavoriteSitesRequest({ isFavorite: !toggled, newFavoriteSites, notFavoriteSites });
    this.closeFavoriteSitesDialog();
  };

  render() {
    const { sitesList } = this.props;
    const {
      alertTrendsVisible,
      favoriteSitesDialogOpened,
      itemSensorsDialogOpened,
      searchUserSitesData,
      selectedSensor,
      toggled,
    } = this.state;

    return (
      <>
        <Subheader
          title="Active Alerts Status"
          leftButtons={[
            {
              icon: <S.IconBack />,
              handler: this.goBack,
              hint: 'Back',
            },
            {
              component: (
                <S.ShowAllToggle defaultToggled label="Show All" onToggle={this.toggleFavorite} toggled={toggled} />
              ),
              isComponent: true,
            },
          ]}
          rightButtons={[
            {
              icon: <S.IconPinDrop />,
              handler: this.openFavoriteSitesDialog,
              hint: 'Sites',
            },
          ]}
          backgroundColor={theme.fieldsBorderColor}
          centeredTitle
        />
        <List
          isFavorite={!toggled}
          openItemSensorsDialog={this.openItemSensorsDialog}
          toggleFavorite={this.toggleFavorite}
        />
        <FavoriteSitesDialog
          title={this.renderFavoriteSitesTitle}
          open={favoriteSitesDialogOpened}
          handleClose={this.closeFavoriteSitesDialog}
          onSubmit={this.submitFavoriteSitesDialog}
          isSearch
          searchData={searchUserSitesData}
          searchInList={this.searchSites}
          sitesList={sitesList.filter(this.filterSites)}
          withoutTitleBorderBottom
        />
        <ItemSensorsDialog
          handleClose={this.closeItemSensorsDialog}
          open={itemSensorsDialogOpened}
          title={`Item Name: ${selectedSensor.Name}`}
          status={selectedSensor.StatusSensor}
          alertTrendsVisible={alertTrendsVisible}
          showAlertTrends={this.showAlertTrends}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  sitesList: getSitesList(user),
  userName: user.userName,
  userType: user.userType,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...healthActions,
      ...sensorsActions,
      requestSitesList: sitesActions.sitesListRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsList);
