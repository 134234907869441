import styled from 'styled-components';

export const Card = styled.li`
  list-style: none;
  width: 240px;
  height: 200px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  margin: 0 30px 30px 0;
`;

export const CardIconContainer = styled.div`
  position: absolute;
  right: -2px;
  top: -4px;
`;

export const CardHeader = styled.div`
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 15px 15px 0 0;
  position: relative;
`;

export const CardInfoContainer = styled.div`
  height: 120px;
  cursor: pointer;
  padding: 15px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardInfoTitle = styled.h2`
  color: ${({ theme }) => theme.textGray};
  font-size: 26px;
  font-weight: 200;
  margin: 0;
`;

export const CardInfoText = styled.p`
  font-size: 12px;
  width: 80%;
`;

export const CardStartText = styled(CardInfoText)`
  color: ${({ theme }) => theme.primaryDocuments};
  font-weight: bold;
  margin: 0;
`;
