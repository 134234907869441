import { createSelector } from 'reselect';

import { stringComparator } from 'helpers';

export const getTypesList = createSelector([typesList => typesList], typesList => {
  const typesListFiltered = typesList.filter(type => type.LevelsCount > 0);

  return typesListFiltered.sort((a, b) => stringComparator(a.Type, b.Type, true));
});

export const selector = () => {};
