import styled from 'styled-components';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';

export const Card = styled(BorderRadiusContainer)`
  margin: 0;
`;

export const CardHeader = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const CardContent = styled(InputWrapper)``;
