export const optionNames = {
  group: [
    { name: 'Group tasks by Team name', value: 1 },
    { name: 'Group tasks by Manager name', value: 2 },
    { name: 'Group tasks by who completed them', value: 4 },
    { name: 'Group tasks by day completed', value: 8 },
    { name: 'Group tasks by day due (original schedule date)', value: 16 },
    { name: 'Group tasks by Unit Name', value: 32 },
    { name: 'Group tasks by Item Name', value: 64 },
  ],
  subGroup: [
    { name: 'Subgroup tasks by Team name', value: 1 },
    { name: 'Subgroup tasks by Manager name', value: 2 },
    { name: 'Subgroup tasks by who completed them', value: 4 },
    { name: 'Subgroup tasks by day completed', value: 8 },
    { name: 'Subgroup tasks by day due (original schedule date)', value: 16 },
    { name: 'Subgroup tasks by Unit Name', value: 32 },
    { name: 'Subgroup tasks by Item Name', value: 64 },
    { name: 'Subgroup tasks by Task Type', value: 128 },
  ],
};
