import styled from 'styled-components';
import TextField from 'material-ui/TextField';
import MenuItem from 'material-ui/MenuItem';

import { UpdateIcon } from 'components/Layout/Icons';
import { FieldSelect } from 'components/Layout/Selects';
import { CurrentSiteContainer } from 'components/Dialogs/styled';

import theme from 'theme';

const inputStyle = {
  border: `1px solid ${theme.primaryGrey}`,
  borderRadius: 10,
  textAling: 'center',
};

const selectStyle = {
  width: 100,
  border: `1px solid ${theme.primaryGrey}`,
  borderRadius: 10,
};

export const DialogContainer = styled.div`
  height: 310px;
  display: flex;
  flex-direction: column;
`;

export const SiteContainer = styled(CurrentSiteContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px 20px;
`;

export const CurrentSiteName = styled.h1`
  text-align: center;
  color: ${() => theme.primaryDark};
  font-weight: 300;
  max-width: 600px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SelectorLabel = styled.p`
  text-align: center;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    color: ${() => theme.primaryDark};
    font-weight: bold;
  }

  ${({ isButton }) => isButton && 'cursor: pointer'};
`;

export const SetToDefaultIcon = styled(UpdateIcon)`
  margin-right: 10px;
`;

export const TimeFieldsContainer = styled.div`
  width: 330px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-bottom: 40px;
`;

export const TimeField = styled(TextField).attrs({
  inputStyle,
  underlineShow: false,
  style: { width: 100 },
  type: 'datetime',
  maxLength: 2,
  minLength: 2,
})`
  & input {
    text-align: center;
    ${({ isError }) =>
      isError && {
        border: `1px solid ${theme.primaryRed} !important`,
      }};
  }
`;

export const Select = styled(FieldSelect).attrs({
  style: selectStyle,
  underlineStyle: { display: 'none' },
  autoWidth: true,
  labelStyle: { left: '30px' },
  hintStyle: { left: '30px' },
})``;

export const Option = styled(MenuItem)``;
