const initialState = {
  error: '',
  documentCreated: false,
  loading: false,
  list: [],
};

export const documentsListRequest = state => ({
  ...state,
  loading: true,
  error: '',
  documentCreated: false,
});

export const documentsListSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  list: payload,
});

export const documentsListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const addDocumentRequest = state => ({
  ...state,
  loading: true,
  error: '',
  documentCreated: false,
});

export const addDocumentSuccess = state => ({
  ...state,
  documentCreated: true,
});

export const addDocumentFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export default initialState;
