import React from 'react';
import PropTypes from 'prop-types';

import { Container, Button } from './styled';

const Footer = ({ onNext, onPrev }) => (
  <Container>
    <Button label="Previous Checklist" onClick={onPrev} />
    <Button label="Next Checklist" onClick={onNext} />
  </Container>
);

Footer.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default Footer;
