import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DialogsPull from 'components/Dialogs/DialogsPull';
import { actions as shortcutActions } from 'redux/shortcuts';

import theme from 'theme';

import InfoPage from 'components/Dialogs/Shortcut/InfoPage';
import SelectType from 'components/Dialogs/Shortcut/SelectType';
import FilterShortcuts from 'components/Dialogs/Shortcut/FilterShortcuts';
import EditSelectedShortcut from 'components/Dialogs/Shortcut/EditSelectedShortcut';
import ShortcutSiteSelection from 'components/Dialogs/Shortcut/ShortcutSiteSelection';
import ShortcutSiteSetup from 'components/Dialogs/Shortcut/ShortcutSiteSetup';
import Overview from 'components/Dialogs/Shortcut/Overview';
import Assignment from 'components/Dialogs/Shortcut/Assignment';
import Pickup from 'components/Dialogs/Shortcut/Pickup';
import Confirmation from 'components/Dialogs/Shortcut/Confirmation';
import Final from 'components/Dialogs/Shortcut/Final';

const STEPS = [
  {
    Component: InfoPage,
    title: 'Adding a Checklist',
    color: theme.mainRed,
  },
  {
    Component: SelectType,
    title: 'New Checklist',
    color: theme.mainRed,
  },
  {
    Component: FilterShortcuts,
    title: 'Select a Shortcut',
    color: theme.mainDarkBlue,
  },
  {
    Component: EditSelectedShortcut,
    title: 'Shortcut Edit',
    color: theme.mainDarkBlue,
    isActivator: true,
  },
  {
    Component: ShortcutSiteSelection,
    title: 'Shortcut Site Selection',
    color: theme.mainDarkBlue,
    isActivator: true,
  },
  {
    Component: ShortcutSiteSetup,
    title: 'Shortcut Setup',
    color: theme.mainDarkBlue,
    isActivator: true,
  },
  {
    Component: Assignment,
    title: 'Shortcut Setup',
    color: theme.mainDarkBlue,
  },
  {
    Component: Pickup,
    title: 'Shortcut Setup',
    color: theme.mainDarkBlue,
  },
  {
    Component: Overview,
    title: 'Shortcut Setup',
    color: theme.mainDarkBlue,
  },
  {
    Component: Confirmation,
    title: 'Confirmation',
    color: theme.mainGreen,
  },
  {
    Component: Final,
    title: 'Confirmation',
    color: theme.mainGreen,
    withDeleteOnClose: false,
  },
];

class AddingChecklist extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    openCreateShortcutDialog: PropTypes.func.isRequired,
    shortcut: PropTypes.shape().isRequired,
    actions: PropTypes.shape({
      getShortcutSitesRequest: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    currentSite: null,
    overrideTime: false,
  };

  setOverride = value => {
    this.setState({ overrideTime: value });
  };

  setCurrentSite = site => {
    this.setState({ currentSite: site });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ currentSite: null });
    }
  }

  onBackToSites = () => {
    const { shortcut, actions } = this.props;

    actions.getShortcutSitesRequest({ shortcut });
  };

  render() {
    const { currentSite, overrideTime } = this.state;
    const { open, handleClose, handleExit, openCreateShortcutDialog } = this.props;

    return (
      <DialogsPull
        open={open}
        steps={STEPS}
        handleClose={handleClose}
        handleExit={handleExit}
        currentSite={currentSite}
        overrideTime={overrideTime}
        openCreateShortcutDialog={openCreateShortcutDialog}
        siteSelectionStepNumber={4}
        onSiteSelect={this.setCurrentSite}
        setOverride={this.setOverride}
        onBackToSites={this.onBackToSites}
      />
    );
  }
}

const mapStateToProps = ({ shortcuts }) => ({
  shortcut: shortcuts.shortcut,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddingChecklist);
