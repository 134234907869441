import React from 'react';
import { DeleteIcon } from 'components/Layout/Icons';

import { IconLink } from './styled';

export const tableHeads = {
  parts: [
    { title: 'link' },
    { title: 'OEM Part ID' },
    { title: 'My Part ID' },
    { title: 'My Part Name' },
    { title: 'My Part Category' },
    { title: 'My Part Type' },
    { title: 'My Part Unit' },
    { title: 'Link Qty' },
    { title: '' },
  ],
  groups: [
    { title: 'link' },
    { title: 'Group Name' },
    { title: 'Group Description' },
    { title: 'Part Qty' },
    { title: '' },
  ],
};

export const tableColumns = {
  parts: [
    { field: 'link', icon: <IconLink />, iconAttribute: 'LinksCount', handlerName: 'openDistributionDialog' },
    { field: 'OEMPartID' },
    { field: 'MyPartID' },
    { field: 'MyPartName' },
    { field: 'MyPartCategory' },
    { field: 'MyPartType' },
    { field: 'MyPartUnitOfMeasure' },
    { field: 'LinksCount', icon: <DeleteIcon />, handlerName: 'onPartRemove' },
  ],
  groups: [
    { field: 'link', icon: <IconLink />, iconAttribute: 'ItemLinksCount', handlerName: 'openDistributionDialog' },
    { field: 'Name' },
    { field: 'Description' },
    { field: 'LinksCount', icon: <DeleteIcon />, handlerName: 'onGroupRemove' },
  ],
};

export const addMenuItems = [
  { value: 'newPartDialogOpen', label: 'Part' },
  { value: 'newGroupDialogOpen', label: 'Group' },
];

export const exportMenuItems = [
  { value: 'partsTemplate', label: 'Download Template' },
  { value: 'parts', label: 'Download Parts' },
  { value: 'uploadParts', label: 'Upload Parts' },
];
