import styled from 'styled-components';
import { ButtonFlat } from 'components/Layout/Buttons';

export const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Item = styled.div`
  display: flex;
  border-bottom: ${({ modif }) => (modif ? '0' : '1')}px solid black;
  position: relative;
  padding: 10px 20px;
  padding-left: 40px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ modif }) => modif && '20px'};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 40px;
`;

export const Data = styled.div`
  font-weight: ${({ modif }) => modif && 'bold'};
  display: flex;
  align-items: ${({ modif }) => (modif ? 'flex-end' : 'center')};
`;

export const SaveButton = styled(ButtonFlat).attrs({
  label: 'Save',
  primary: true,
})`
  min-width: 50% !important;
  color: ${({ theme }) => theme.primaryGreen}!important;
`;

export const Label = styled.div`
  font-size: 12px;
  position: absolute;
  top: -5px;
`;

export const Input = styled.input`
  border-radius: 5px;
  width: 75%;
  height: 35px;
  margin-right: 20px;
  border: none;
  box-sizing: border-box;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.lightGrey};
  outline: none;
`;
