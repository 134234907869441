import styled from 'styled-components';

import { FieldCheckbox, FieldRadio } from 'components/Layout/Inputs';
import FormControls from 'components/Dialogs/withInputModal/Controls';

export const DialogContainer = styled.div`
  min-height: 717px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

export const SearchContainer = styled.div`
  width: 96%;
  height: 60px;
  padding-top: 20px;
  align-self: center;
  position: fixed;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
`;

export const ListContainer = styled.div`
  padding: 80px 0 30px;
`;

export const SaveButtonContainer = styled.div`
  width: 96%;
  height: 40px;
  background-color: ${({ theme }) => theme.primaryWhite};
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const CurrentSiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px 50px;
`;

export const SelectorContainer = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
`;

export const DialogFooter = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  border-top: ${({ theme }) => `2px solid ${theme.lightGrey}`};
`;

export const ButtonContainer = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '96%' : '50%')};
  cursor: pointer;
`;

export const SaveButton = styled(FormControls).attrs({
  submitLabel: ({ submitLabel }) => submitLabel || 'Save',
  withSubmit: true,
  withoutTopBorder: true,
  disableButtons: ({ disabled }) => disabled,
})``;

export const ItemCheckbox = styled(FieldCheckbox)`
  width: auto !important;
`;

export const ItemRadio = styled(FieldRadio)`
  width: auto !important;
`;

export const DividerLine = styled.hr`
  margin: 5px 0;
  height: 2px;
  border: none;
  background-color: ${({ theme }) => theme.lightGrey};
`;

export const ItemName = styled.span`
  color: ${({ theme }) => theme.primaryDark};
  word-break: break-word;
`;
