import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

class SearchField extends PureComponent {
  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    filterBy: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    searchData: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    underlineShow: PropTypes.bool,
    inputStyle: PropTypes.object,
    hintStyle: PropTypes.object,
    icon: PropTypes.node,
    setSearchedItems: PropTypes.func.isRequired,
    borderRadius: PropTypes.string,
  };

  static defaultProps = {
    searchData: '',
    underlineShow: false,
    hintStyle: null,
    inputStyle: null,
    icon: null,
    borderRadius: '',
  };

  onSearch = event => {
    const { list, filterBy, setSearchedItems } = this.props;
    const searchData = event.target.value;
    const searchQuery = searchData.toLowerCase();

    const filteredItems = list.filter(item => {
      if (typeof filterBy === 'string') {
        return item[filterBy].toLowerCase().includes(searchQuery);
      }
      return filterBy.some(field => typeof item[field] === 'string' && item[field].toLowerCase().includes(searchQuery));
    });

    setSearchedItems(filteredItems, searchData);
  };

  render() {
    const { searchData, placeholder, underlineShow, inputStyle, hintStyle, icon, borderRadius } = this.props;

    return (
      <S.SearchContainer>
        <S.SearchFieldContainer>
          <S.SearchField
            onChange={this.onSearch}
            value={searchData}
            hintText={placeholder}
            underlineShow={underlineShow}
            inputStyles={inputStyle}
            hintStyles={hintStyle}
            bR={borderRadius}
            inputRef={input => input && input.focus()}
          />
        </S.SearchFieldContainer>
        <S.IconContainer>{icon}</S.IconContainer>
      </S.SearchContainer>
    );
  }
}

export default SearchField;
