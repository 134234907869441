import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';

import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ShortcutTestrunDialog from 'components/Dialogs/Shortcut/ShortcutTestrunDialog';
import TestrunButton from 'components/Buttons/TestrunButton';
import { countItems, checkOptions } from 'components/Layout/ShortcutRules/helpers';
import { shortcutType } from 'configs/types';
import { actions as shortcutsActions } from 'redux/shortcuts';

import { ShortCutType, SiteType, UnitType, UnitItemType } from 'configs/propTypes';

import TypeRadioGroup from './TypeRadioGroup';
import OwnerRadioGroup from './OwnerRadioGroup';
import validate from './validator';
import * as S from './styled';

const compareDates = (compareDate, startDate, endDate) => {
  const momentCompareDate = moment(moment(compareDate).format('DD/MM/YYYY'), 'DD/MM/YYYY');
  const momentStartDate = moment(startDate, 'DD/MM/YYYY').subtract(1, 'days');
  const momentEndDate = moment(endDate, 'DD/MM/YYYY').add(1, 'days');

  if (!startDate && endDate) {
    return momentCompareDate.isBefore(momentEndDate);
  }
  if (!endDate && startDate) {
    return momentCompareDate.isAfter(momentStartDate);
  }
  if (endDate && startDate) {
    return momentCompareDate.isBetween(momentStartDate, momentEndDate);
  }
  return true;
};

const SiteForm = ({ list, values, userId }) => (
  <S.FormContainer>
    <S.FieldContainer>
      <Field component={OwnerRadioGroup} name="Owner" style={S.radioStyle} initialValue={0} />
    </S.FieldContainer>
    <S.SmallText>creation date range</S.SmallText>
    <S.DateBlock>
      <S.FieldBlock>
        <Field name="StartDate" component={DatePickerAdapter} floatingLabelText="Start Date" />
      </S.FieldBlock>
      <S.FieldBlock>
        <Field name="EndDate" component={DatePickerAdapter} floatingLabelText="End Date" />
      </S.FieldBlock>
    </S.DateBlock>
    <S.FieldContainer>
      <Field component={TypeRadioGroup} name="Type" style={S.radioStyle} initialValue={0} />
    </S.FieldContainer>
    <S.FieldContainer>
      <Field component={SelectFieldAdapter} floatingLabelText="Shortcut Name" name="ShortcutId">
        {list &&
          list
            .filter(item => item.Type === values.Type)
            .filter(item => compareDates(item.CreatedOn, values.StartDate, values.EndDate))
            .filter(item => {
              if (values.Owner === 0 && item.OwnerID === userId) {
                return true;
              }
              if (values.Owner === 0 && item.OwnerID !== userId) {
                return false;
              }
              return true;
            })
            .map(item => <MenuItem key={item.Id} primaryText={item.Name} value={item.Id} />)}
      </Field>
    </S.FieldContainer>
  </S.FormContainer>
);
class FilterShortcuts extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      getShortcutsRequest: PropTypes.func.isRequired,
      getSingleShortcutRequest: PropTypes.func.isRequired,
      sendLocationOptionsRequest: PropTypes.func.isRequired,
      sendItemOptionsRequest: PropTypes.func.isRequired,
      sendUnitOptionsRequest: PropTypes.func.isRequired,
    }).isRequired,
    list: PropTypes.arrayOf(ShortCutType).isRequired,
    userId: PropTypes.string.isRequired,
    listOfSites: PropTypes.oneOfType([PropTypes.string, SiteType]).isRequired,
    listOfUnits: PropTypes.oneOfType([PropTypes.string, UnitType]).isRequired,
    listOfItems: PropTypes.arrayOf(UnitItemType).isRequired,
    shortcutToPreview: ShortCutType.isRequired,
    onNext: PropTypes.func.isRequired,
  };

  state = {
    isErrorShown: false,
    previewOpen: false,
  };

  getPreviewResults = values => {
    const { actions } = this.props;
    if (!values.ShortcutId) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      actions.getSingleShortcutRequest({ shortcutId: values.ShortcutId });
      this.setState({ previewOpen: true });
    }
  };

  closePreview = () => this.setState({ previewOpen: false });

  onSubmitForm = ({ values }) => {
    const { onNext, actions } = this.props;
    actions.getSingleShortcutRequest({ shortcutId: values.ShortcutId, onNext });
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.getShortcutsRequest({ filter: { type: shortcutType.all } });
  }

  componentDidUpdate(prevProps) {
    const { actions, shortcutToPreview } = this.props;

    if (prevProps.shortcutToPreview.Id !== shortcutToPreview.Id) {
      const valuesToSend = {
        LocationRules: shortcutToPreview.LocationRules,
        ItemRules: shortcutToPreview.ItemRules,
        UnitRules: shortcutToPreview.UnitRules,
      };
      if (
        !checkOptions(valuesToSend.LocationRules) ||
        !checkOptions(valuesToSend.UnitRules) ||
        !checkOptions(valuesToSend.ItemRules)
      ) {
        this.setState({ isErrorShown: true });
      } else {
        this.setState({ isErrorShown: false, previewOpen: true });
        actions.sendLocationOptionsRequest(valuesToSend);
        actions.sendUnitOptionsRequest(valuesToSend);
        actions.sendItemOptionsRequest(valuesToSend);
      }
    }
  }

  getPreviewResultsData = () => {
    const { listOfSites, listOfUnits, listOfItems } = this.props;

    return [
      {
        data: listOfSites,
        isEmpty: listOfSites === 'empty',
        header: 'Location Result',
        foundItems: `Sites Found: ${listOfSites.length}`,
      },
      {
        data: listOfUnits,
        isEmpty: listOfUnits === 'empty',
        header: 'Unit Result',
        foundItems: `Units Found: ${listOfUnits.length}`,
      },
      {
        data: listOfItems.map(({ Items }) => Items).flat(),
        isEmpty: listOfItems.every(({ Items }) => Items.length === 0),
        header: 'Item Result',
        foundItems: `Items Found: ${countItems(listOfItems)}`,
      },
    ];
  };

  render() {
    const { shortcutToPreview, list, userId } = this.props;
    const { isErrorShown, previewOpen } = this.state;

    return (
      <S.Container>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm values={values} list={list} userId={userId} />
              <TestrunButton isCentered onClick={() => this.getPreviewResults(values)} />
              {isErrorShown && <S.ErrorContainer>Please select Shortcut</S.ErrorContainer>}
              <ActionsContainer>
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
        <ShortcutTestrunDialog
          open={previewOpen}
          handleClose={this.closePreview}
          shortcutToPreview={shortcutToPreview}
          results={shortcutToPreview.Id ? this.getPreviewResultsData() : []}
        />
      </S.Container>
    );
  }
}

SiteForm.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  values: PropTypes.shape().isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ shortcuts, auth }) => ({
  list: shortcuts.shortcutsList,
  userId: auth.user.userId,
  listOfItems: shortcuts.listOfItems,
  listOfUnits: shortcuts.listOfUnits,
  listOfSites: shortcuts.listOfSites,
  shortcutToPreview: shortcuts.shortcutToPreview,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutsActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterShortcuts);
