import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TierFullType } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';

import { actions as tiersActions } from 'redux/tiers';
import { actions as meteringActions } from 'redux/meteringGroups';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { AddIcon, BackIcon } from 'components/Layout/Icons';
import AddEditTierDialog from './pages/AddEditTierDialog';
import Item from './pages/Item';
import { bindActionCreators } from 'redux';
import DemoTierItem from './pages/DemoTierItem';

class TiersList extends Component {
  static propTypes = {
    addTier: PropTypes.func.isRequired,
    createdEditedTierId: PropTypes.string.isRequired,
    editTier: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    fetchTiers: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    tiersList: PropTypes.arrayOf(TierFullType).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        meeteringGroupId: PropTypes.string,
      }),
    }).isRequired,
    actions: PropTypes.shape({
      getGroupRequest: PropTypes.func.isRequired,
    }).isRequired,
    meeteringGroup: PropTypes.object.isRequired,
  };

  state = {
    addEditTierDialogOpened: false,
    searchData: '',
    tierToEdit: {
      meeteringGroupId: this.props.match.params.meeteringGroupId,
    },
  };

  componentDidMount() {
    const {
      fetchTiers,
      actions: { getGroupRequest },
      match: { params },
    } = this.props;
    getGroupRequest(params.meeteringGroupId);
    fetchTiers({ meeteringGroupId: params.meeteringGroupId });
  }

  onBackButtonClick = () => this.props.history.push('/globalsettings/metering');

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }

    if (nextProps.createdEditedTierId) {
      this.closeAddEditTierDialog();
    }
  }

  closeAddEditTierDialog = () => {
    this.setState({ addEditTierDialogOpened: false });
  };

  openAddEditTierDialog = tier => {
    if (tier.Id) {
      this.setState({
        addEditTierDialogOpened: true,
        tierToEdit: {
          ...tier,
        },
      });
    } else {
      this.setState({ addEditTierDialogOpened: true, tierToEdit: null });
    }
  };

  filterTiersList = ({ Name }) => Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  addTier = tier => {
    tier.MeeteringGroupID = this.props.match.params.meeteringGroupId;
    this.props.addTier(tier);
  };

  render() {
    const { addEditTierDialogOpened, searchData, tierToEdit } = this.state;
    const { editTier, tiersList, meeteringGroup } = this.props;

    return [
      <Subheader
        key="Tiers list subheader"
        rightButtons={[
          {
            icon: <AddIcon />,
            handler: this.openAddEditTierDialog,
            hint: 'Add',
          },
        ]}
        title={`${meeteringGroup.Name} Tiers`}
        isSearch
        searchData={searchData}
        searchInList={this.searchInList}
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: this.onBackButtonClick,
            hint: 'Back',
          },
        ]}
      />,
      <DemoTierItem />,
      <SimpleList
        key="Tiers"
        data={tiersList.filter(this.filterTiersList)}
        emptyListMessage="There are no tiers avaliable"
        onItemClick={this.openAddEditTierDialog}
        renderItemContent={Item}
      />,
      <AddEditTierDialog
        key="Add edit tier dialog"
        handleClose={this.closeAddEditTierDialog}
        onSubmit={tierToEdit ? editTier : this.addTier}
        open={addEditTierDialogOpened}
        tier={tierToEdit || undefined}
        title={tierToEdit ? 'Edit Tier' : 'Add Tier'}
      />,
    ];
  }
}

const mapStateToProps = ({ tiers, meteringGroups: { meeteringGroup } }) => ({
  createdEditedTierId: tiers.createdEditedTierId,
  error: tiers.error,
  tiersList: tiers.list,
  meeteringGroup,
});

const mapDispatchToProps = dispatch => ({
  addTier: bindActionCreators(tiersActions.addTierRequest, dispatch),
  editTier: bindActionCreators(tiersActions.editTierRequest, dispatch),
  fetchTiers: bindActionCreators(tiersActions.tiersListRequest, dispatch),
  openConfirmationDialog: bindActionCreators(openConfirmationDialog, dispatch),
  openErrorDialog: bindActionCreators(openErrorDialog, dispatch),
  actions: bindActionCreators(
    {
      ...meteringActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TiersList);
