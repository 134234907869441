import styled from 'styled-components';

import Subheader from 'components/Subheader';
import { AddIcon } from 'components/Layout/Icons';

export const Container = styled(Subheader)``;

export const Info = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
`;

export const RedInfo = styled(Info)`
  color: ${({ theme }) => theme.mainRed};
  display: flex !important;
`;

export const IconAdd = styled(AddIcon)``;
