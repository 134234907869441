import styled from 'styled-components';

import { ListContainer } from 'components/Layout/Containers';

export const Container = styled(ListContainer)`
  padding: 20px 20px 15px;
  display: flex;
`;

export const Site = styled.div`
  width: 252px;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.lightGrey};
`;

export const UnitsList = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.fieldsBorderColor};
`;

export const Unit = styled.div`
  padding: 10px 7px 7px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.lightGrey};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const UnitName = styled.div`
  font-size: 12px;
  font-weight: bold;
  word-break: break-all;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background-color: ${({ theme }) => theme.primaryGrey};
`;
