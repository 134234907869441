import styled from 'styled-components';

import { ButtonFlat } from 'components/Layout/Buttons';

export const CancelButton = styled(ButtonFlat)`
  span {
    color: ${({ theme }) => theme.greenButton} !important;
  }
`;

export const RedButton = styled(ButtonFlat)`
  span {
    color: ${({ theme, confirmButtonTextColor }) => confirmButtonTextColor || theme.modalCancelColor} !important;
  }
`;
