import * as itemState from '../item/handlers';
import { updateItemsState, updatePdfsState, updatePicturesState, updateTasksState } from './helpers';

const initialState = {
  createdEditedItem: false,
  createdEditedTask: false,
  createdItemId: null,
  error: '',
  itemPdfUploaded: false,
  itemPictureUploaded: false,
  loading: false,
  unitData: {},
  unitItemsList: [],
  unitItemsPdfsList: {},
  unitItemsPictures: {},
  unitItemsTasks: {},
  unitPdfsList: [],
  unitPdfUploaded: false,
  unitPicturesList: [],
  unitPictureUploaded: false,
  unitWithItemsTasksList: [],
  unitWithItemsTasksListUpdated: false,
};

export const deleteUnitRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteUnitSuccess = state => ({
  ...state,
  loading: false,
});

export const deleteUnitFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
});

export const deleteUnitPdfRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteUnitPdfSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  unitPdfsList: payload,
});

export const deleteUnitPdfFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteUnitPictureRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteUnitPictureSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  unitPicturesList: payload,
});

export const deleteUnitPictureFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchUnitRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const fetchUnitSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  unitData: payload,
});

export const fetchUnitFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const editUnitRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const editUnitSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  unitData: payload,
});

export const editUnitFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const uploadUnitPdfRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const uploadUnitPdfSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  unitPdfsList: payload,
  unitPdfUploaded: true,
});

export const uploadUnitPdfFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const uploadUnitPictureRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const uploadUnitPictureSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  unitPicturesList: payload,
  unitPictureUploaded: true,
});

export const uploadUnitPictureFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const addUnitItemRequest = state => ({
  ...state,
  createdEditedItem: false,
  error: '',
  loading: true,
});

export const addUnitItemSuccess = (state, { payload }) => updateItemsState(state, payload);

export const addUnitItemFailure = (state, { payload }) => ({
  ...state,
  createdItemId: itemState.default.createdItemId,
  error: payload.message,
  loading: false,
});

export const clearItemAdded = state => ({
  ...state,
  createdEditedItem: false,
});

export const deleteUnitItemRequest = state => ({
  ...state,
  createdEditedItem: false,
  error: '',
  loading: true,
});

export const deleteUnitItemSuccess = (state, { payload }) => ({
  ...state,
  createdEditedItem: true,
  loading: false,
  unitItemsList: payload,
});

export const deleteUnitItemFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
});

export const deleteUnitItemPdfRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteUnitItemPdfSuccess = (state, { payload }) => updatePdfsState(state, payload);

export const deleteUnitItemPdfFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const deleteUnitItemPictureRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteUnitItemPictureSuccess = (state, { payload }) => updatePicturesState(state, payload);

export const deleteUnitItemPictureFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const duplicateUnitItemRequest = state => ({
  ...state,
  createdEditedItem: false,
  error: '',
  loading: true,
});

export const duplicateUnitItemFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const editUnitItemRequest = state => ({
  ...state,
  createdEditedItem: false,
  error: '',
  loading: true,
});

export const editUnitItemSuccess = (state, { payload }) => ({
  ...state,
  createdEditedItem: true,
  loading: false,
  unitItemsList: payload,
});

export const editUnitItemFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const uploadUnitItemPdfRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const uploadUnitItemPdfSuccess = (state, { payload }) => ({
  ...updatePdfsState(state, payload),
  itemPdfUploaded: true,
});

export const uploadUnitItemPdfFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const uploadUnitItemPictureRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const uploadUnitItemPictureSuccess = (state, { payload }) => ({
  ...updatePicturesState(state, payload),
  itemPictureUploaded: true,
});

export const uploadUnitItemPictureFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const addUnitTaskRequest = state => ({
  ...state,
  createdEditedTask: false,
  error: '',
  loading: true,
});

export const addUnitTaskSuccess = (state, { payload }) => updateTasksState(state, payload);

export const addUnitTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
});

export const deleteUnitTaskRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const deleteUnitTaskSuccess = (state, { payload }) => updateTasksState(state, payload);

export const deleteUnitTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
});

export const editUnitTaskRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const editUnitTaskSuccess = (state, { payload }) => updateTasksState(state, payload);

export const editUnitTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const unitDataFetchRequest = state => ({
  ...state,
  loading: true,
});

export const unitDataFetchSuccess = (state, { payload }) => ({
  ...state,
  ...payload,
  loading: false,
});

export const unitDataFetchFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const itemPdfUploadedFlagUnset = state => ({
  ...state,
  itemPdfUploaded: false,
});

export const itemPictureUploadedFlagUnset = state => ({
  ...state,
  itemPictureUploaded: false,
});

export const unitPdfUploadedFlagUnset = state => ({
  ...state,
  unitPdfUploaded: false,
});

export const unitPictureUploadedFlagUnset = state => ({
  ...state,
  unitPictureUploaded: false,
});

export const fetchUnitItemsRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const fetchUnitItemsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  loading: false,
  unitItemsList: payload,
});

export const fetchUnitItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getDefaultBarcodeRequest = state => ({
  ...state,
  error: '',
});

export const getDefaultBarcodeSuccess = (state, { payload }) => ({
  ...state,
  unitDefaultBarcode: payload,
  loading: false,
});

export const getDefaultBarcodeFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getUnitItemsTasksRequest = state => ({
  ...state,
  loading: true,
  error: '',
});

export const getUnitItemsTasksSuccess = (state, { payload }) => ({
  ...state,
  unitWithItemsTasksList: payload,
  loading: false,
});

export const getUnitItemsTasksFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const updateTasksListRequest = state => ({
  ...state,
  error: '',
});

export const updateTasksListSuccess = (state, { payload }) => ({
  ...state,
  unitWithItemsTasksListUpdated: payload,
  loading: false,
});

export const updateTasksListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export default initialState;
