import styled from 'styled-components';
import MenuItem from 'material-ui/MenuItem';

export const HeaderMenuItem = styled(MenuItem)`
  ${({ theme }) => `
      margin-top: -8px;
      color: ${theme.primaryWhite}!important;
      background-color: ${theme.headerColor};
  `}
`;
