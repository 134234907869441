import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';

import { TaskNotesPhotos } from 'configs/propTypes';
import { getSelectedSite } from 'helpers/storage';
import EmptyList from 'components/EmptyList';
import { Content, FullScreenDialog, ImageContainer } from './styled';

const params = {
  grabCursor: true,
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets',
  },
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
};

class NotesPhotosModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    parentId: PropTypes.string.isRequired,
    photos: TaskNotesPhotos.isRequired,
  };

  state = {
    siteId: getSelectedSite(),
  };

  render() {
    const { open, handleClose, photos, parentId } = this.props;
    const { siteId } = this.state;

    return (
      <FullScreenDialog
        modal={false}
        open={open}
        defaultCancel
        defaultCancelText="Close"
        title="Notes photos"
        onRequestClose={handleClose}
      >
        <Content>
          {photos.length ? (
            <Swiper {...params}>
              {photos.map((photo, index) => (
                <ImageContainer key={index}>
                  <img alt={photo.Name} src={`${photo.src}?parentId=${parentId}&inline=true&siteId=${siteId}`} />
                </ImageContainer>
              ))}
            </Swiper>
          ) : (
            <EmptyList text="This task has no photos" />
          )}
        </Content>
      </FullScreenDialog>
    );
  }
}

const mapStateToProps = ({ tasks }) => ({
  photos: tasks.taskNotes,
});

export default connect(mapStateToProps)(NotesPhotosModal);
