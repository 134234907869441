import React from 'react';

import { InactiveUserIndicator } from 'components/Layout/Indicators';

import * as S from './styled';

const Item = person => (
  <S.Information>
    <InactiveUserIndicator isInactive={person.Status === 'Inactive'} />
    <S.Text>
      Username: {person.Name} ({person.UserType})
    </S.Text>
    <S.Text>
      Name: {person.FirstName} {person.LastName}
    </S.Text>
    <S.Text>Job: {person.JobFunctionName}</S.Text>
    <S.Text>Email: {person.Email}</S.Text>
    <S.Text>Phone: {person.Phone}</S.Text>
    <S.Text>Status: {person.Status}</S.Text>
  </S.Information>
);

export default Item;
