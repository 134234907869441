import React from 'react';
import { Field } from 'react-final-form';

import { UnitItemType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import validate from './validator';

import * as S from './styled';

const DuplicateForm = () => (
  <S.FormContainer>
    <Field key="CurrentNameField" component={TextFieldAdapter} disabled floatingLabelText="Current Name" name="Name" />
    <Field key="NewNameField" component={TextFieldAdapter} floatingLabelText="New Name" name="NewName" />
  </S.FormContainer>
);

const DuplicateWithInput = withInputModal(DuplicateForm);

const DuplicateDialog = ({ itemToDuplicate, ...rest }) => (
  <DuplicateWithInput
    {...rest}
    title="Duplicate this Item with all Tasks"
    initialValues={itemToDuplicate}
    validate={validate}
  />
);

DuplicateDialog.defaultProps = {
  itemToDuplicate: { Name: '', NewName: '', NewSequence: 0 },
};

DuplicateDialog.propTypes = {
  itemToDuplicate: UnitItemType,
};

export default DuplicateDialog;
