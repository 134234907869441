import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import IOT_HUBS from 'configs/iotHubs';
import { GatewayType } from 'configs/propTypes';

import { openErrorDialog } from 'redux/errorHandler';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as gatewaysActions } from 'redux/gateways';
import { actions as tenantsActions } from 'redux/tenants';
import { actions as sitesActions } from 'redux/sites';

import Subheader from 'components/Subheader';
import SimpleList from 'components/SimpleList';
import { AddIcon, BackIcon } from 'components/Layout/Icons';
import AddEditGatewayDialog from './AddEditGatewayDialog';

import Item from './Item';
import { getGatewaysListWithTenantName } from './selector';

class Gateways extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      createGatewayRequest: PropTypes.func.isRequired,
      deleteGatewayRequest: PropTypes.func.isRequired,
      editGatewayRequest: PropTypes.func.isRequired,
      getGatewaysRequest: PropTypes.func.isRequired,
      getRegionsRequest: PropTypes.func.isRequired,
      getResourceGroupInfoRequest: PropTypes.func.isRequired,
      openConfirmationDialog: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
      requestTenantsList: PropTypes.func.isRequired,
      getNewResourceGroupNameByTenantRequest: PropTypes.func.isRequired,
      getNewHubNameBySiteRequest: PropTypes.func.isRequired,
      getSitesList: PropTypes.func.isRequired,
      getHubTierInfoRequest: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    list: PropTypes.arrayOf(GatewayType).isRequired,
  };

  state = {
    addEditGatewayDialogOpened: false,
    isResourceGroupUnique: true,
    resourceGroupRegion: '',
    searchData: '',
    SKU: { SKUName: '', SKUTier: '', Capacity: 1 },
    selectedGateway: {},
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.requestTenantsList();
    actions.getRegionsRequest();
    actions.getGatewaysRequest({ isMaster: true });
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;

    if (nextProps.error) {
      actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  onStateChange = (key, value) => this.setState({ [key]: value });

  closeAddEditGatewayDialog = () =>
    this.setState({
      addEditGatewayDialogOpened: false,
      isResourceGroupUnique: true,
      resourceGroupRegion: '',
      SKU: { SKUName: '', SKUTier: '', Capacity: 1 },
    });
  openAddGatewayDialog = () => this.setState({ addEditGatewayDialogOpened: true, selectedGateway: {} });

  openEditGatewayDialog = selectedGateway => {
    const { actions } = this.props;
    const { TenantID, Name, SiteID, ResourceGroupName, IotHubName } = selectedGateway;

    actions.getSitesList({ tenantId: TenantID, filter: { name: Name, id: SiteID } });
    actions.getResourceGroupInfoRequest({
      ResourceGroupName,
      cb: value => this.onStateChange('resourceGroupRegion', value),
    });
    actions.getHubTierInfoRequest({
      iotHubName: IotHubName,
      resourceGroupName: ResourceGroupName,
      cb: SKU => this.onStateChange('SKU', SKU),
    });

    this.setState({
      addEditGatewayDialogOpened: true,
      selectedGateway,
    });
  };

  filterGateways = ({ tenantName, SerialNumber }) =>
    SerialNumber.includes(this.state.searchData) ||
    (tenantName && tenantName.toLowerCase().includes(this.state.searchData.toLowerCase()));

  onListClick = () => {
    this.props.history.push('/globalsettings');
  };

  onChangeFormField = (onchange, field, value) => onchange(field, value);

  onChangeTenant = (tenantId, region, form) => {
    const { actions } = this.props;

    actions.getSitesList({ tenantId });
    actions.getNewResourceGroupNameByTenantRequest({
      tenantId,
      region,
      changeFormField: (fieldName, fieldValue) => this.onChangeFormField(form.change, fieldName, fieldValue),
      changeState: this.onStateChange,
    });
  };

  onChangeSite = (siteId, values, form) => {
    const { actions } = this.props;
    const {
      SKU: { SKUName, SKUTier, Capacity },
    } = this.state;
    const { TenantID: tenantId, IotHubRegion, ResourceGroupName } = values;

    const hubData = {
      tenantId,
      siteId,
      region: IotHubRegion,
      resourceGroupName: ResourceGroupName,
      sku: {
        skuName: SKUName,
        skuTier: SKUTier,
        capacity: Capacity,
      },
    };

    actions.getNewHubNameBySiteRequest({
      siteId,
      tenantId,
      changeFormField: (fieldName, fieldValue) => this.onChangeFormField(form.change, fieldName, fieldValue),
      hubData,
    });
  };

  onChangeTier = (fieldName, value) => {
    const { SKU } = this.state;

    if (fieldName === 'SKUName') {
      const { skuTier } = IOT_HUBS.find(({ skuName }) => skuName === value);
      this.setState({ SKU: { ...SKU, SKUName: value, SKUTier: skuTier, Capacity: 1 } });
    } else {
      this.setState({ SKU: { ...SKU, [fieldName]: value } });
    }
  };

  searchInList = e => this.setState({ searchData: e.target.value });

  deleteGateway = () => {
    const { actions } = this.props;
    const {
      selectedGateway: { DeviceId, IotHubName, ResourceGroupName },
    } = this.state;

    actions.openConfirmationDialog(
      'Are you sure you want to permanently delete this Gateway? This cannot be undone!',
      () => {
        actions.deleteGatewayRequest({
          deviceId: DeviceId,
          iotHubName: IotHubName,
          resourceGroupName: ResourceGroupName,
        });
        this.closeAddEditGatewayDialog();
      },
      'Delete?',
    );
  };

  submitAddEditGatewayDialog = values => {
    const { actions } = this.props;

    const {
      DeviceId,
      IotHubRegion: Region,
      ResourceGroupName,
      IotHubName,
      TenantID: TenantId,
      SiteID: SiteId,
      Notes,
    } = values;

    if (values.Id) {
      actions.editGatewayRequest({ isMaster: true, values });
    } else {
      actions.createGatewayRequest({
        DeviceId,
        Region,
        ResourceGroupName,
        IotHubName,
        TenantId,
        SiteId,
        Notes,
      });
    }
    this.closeAddEditGatewayDialog();
  };

  render() {
    const { list } = this.props;
    const { addEditGatewayDialogOpened, searchData, selectedGateway, isResourceGroupUnique, resourceGroupRegion, SKU } =
      this.state;

    return (
      <>
        <Subheader
          title="Gateways"
          hintText="Filter Tenants or Serial Number"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onListClick,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openAddGatewayDialog,
              hint: 'Add',
            },
          ]}
        />
        <SimpleList
          data={list.filter(this.filterGateways)}
          onItemClick={this.openEditGatewayDialog}
          emptyListMessage="There are no gateways types available"
          renderItemContent={item => <Item item={item} />}
        />
        <AddEditGatewayDialog
          handleClose={this.closeAddEditGatewayDialog}
          title={`${selectedGateway.SerialNumber ? 'Edit' : 'Add'} Gateway`}
          open={addEditGatewayDialogOpened}
          onSubmit={this.submitAddEditGatewayDialog}
          onChangeSite={this.onChangeSite}
          onChangeTenant={this.onChangeTenant}
          onStateChange={this.onStateChange}
          handleDelete={this.deleteGateway}
          selectedGateway={selectedGateway}
          isResourceGroupUnique={isResourceGroupUnique}
          resourceGroupRegion={resourceGroupRegion}
          SKU={SKU}
          onChangeTier={this.onChangeTier}
          withDelete={Object.keys(selectedGateway).length > 0}
          withoutTopBorder
        />
      </>
    );
  }
}

const mapStateToProps = ({ gateways, tenants }) => ({
  error: gateways.error,
  list: getGatewaysListWithTenantName(gateways, tenants),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSitesList: sitesActions.sitesListByTenantRequest,
      openConfirmationDialog,
      openErrorDialog,
      requestTenantsList: tenantsActions.tenantsListRequest,
      ...gatewaysActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gateways);
