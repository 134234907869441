import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  FETCH_ITEM_DETAILS_REQUEST: undefined,
  FETCH_ITEM_DETAILS_SUCCESS: undefined,
  FETCH_ITEM_DETAILS_FAILURE: undefined,

  DELETE_PDF_REQUEST: undefined,
  DELETE_PDF_SUCCESS: undefined,
  DELETE_PDF_FAILURE: undefined,

  UPLOAD_PDF_REQUEST: undefined,
  UPLOAD_PDF_SUCCESS: undefined,
  UPLOAD_PDF_FAILURE: undefined,

  DELETE_PICTURE_REQUEST: undefined,
  DELETE_PICTURE_SUCCESS: undefined,
  DELETE_PICTURE_FAILURE: undefined,

  UPLOAD_PICTURE_REQUEST: undefined,
  UPLOAD_PICTURE_SUCCESS: undefined,
  UPLOAD_PICTURE_FAILURE: undefined,

  DELETE_TASK_REQUEST: undefined,
  DELETE_TASK_SUCCESS: undefined,
  DELETE_TASK_FAILURE: undefined,

  DELETE_ITEM_REQUEST: undefined,
  DELETE_ITEM_SUCCESS: undefined,
  DELETE_ITEM_FAILURE: undefined,

  EDIT_ITEM_REQUEST: undefined,
  EDIT_ITEM_SUCCESS: undefined,
  EDIT_ITEM_FAILURE: undefined,

  EDIT_TASK_REQUEST: undefined,
  EDIT_TASK_SUCCESS: undefined,
  EDIT_TASK_FAILURE: undefined,

  ADD_TASK_REQUEST: undefined,
  ADD_TASK_SUCCESS: undefined,
  ADD_TASK_FAILURE: undefined,

  GET_DEFAULT_BARCODE_REQUEST: undefined,
  GET_DEFAULT_BARCODE_SUCCESS: undefined,
  GET_DEFAULT_BARCODE_FAILURE: undefined,

  DUBLICATE_TASK_REQUEST: undefined,
  DUBLICATE_TASK_SUCCESS: undefined,
  DUBLICATE_TASK_FAILURE: undefined,

  FETCH_ITEM_TASKS_REQUEST: undefined,
  FETCH_ITEM_TASKS_SUCCESS: undefined,
  FETCH_ITEM_TASKS_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.fetchItemDetailsRequest, handlers.fetchItemDetailsRequest],
    [actions.fetchItemDetailsSuccess, handlers.fetchItemDetailsSuccess],
    [actions.fetchItemDetailsFailure, handlers.fetchItemDetailsFailure],

    [actions.deletePdfRequest, handlers.deletePdfRequest],
    [actions.deletePdfSuccess, handlers.deletePdfSuccess],
    [actions.deletePdfFailure, handlers.deletePdfFailure],

    [actions.uploadPdfRequest, handlers.uploadPdfRequest],
    [actions.uploadPdfSuccess, handlers.uploadPdfSuccess],
    [actions.uploadPdfFailure, handlers.uploadPdfFailure],

    [actions.deletePictureRequest, handlers.deletePictureRequest],
    [actions.deletePictureSuccess, handlers.deletePictureSuccess],
    [actions.deletePictureFailure, handlers.deletePictureFailure],

    [actions.uploadPictureRequest, handlers.uploadPictureRequest],
    [actions.uploadPictureSuccess, handlers.uploadPictureSuccess],
    [actions.uploadPictureFailure, handlers.uploadPictureFailure],

    [actions.deleteTaskRequest, handlers.deleteTaskRequest],
    [actions.deleteTaskSuccess, handlers.deleteTaskSuccess],
    [actions.deleteTaskFailure, handlers.deleteTaskFailure],

    [actions.deleteItemRequest, handlers.deleteItemRequest],
    [actions.deleteItemSuccess, handlers.deleteItemSuccess],
    [actions.deleteItemFailure, handlers.deleteItemFailure],

    [actions.editItemRequest, handlers.editItemRequest],
    [actions.editItemSuccess, handlers.editItemSuccess],
    [actions.editItemFailure, handlers.editItemFailure],

    [actions.editTaskRequest, handlers.editTaskRequest],
    [actions.editTaskSuccess, handlers.editTaskSuccess],
    [actions.editTaskFailure, handlers.editTaskFailure],

    [actions.addTaskRequest, handlers.addTaskRequest],
    [actions.addTaskSuccess, handlers.addTaskSuccess],
    [actions.addTaskFailure, handlers.addTaskFailure],

    [actions.getDefaultBarcodeRequest, handlers.getDefaultBarcodeRequest],
    [actions.getDefaultBarcodeSuccess, handlers.getDefaultBarcodeSuccess],
    [actions.getDefaultBarcodeFailure, handlers.getDefaultBarcodeFailure],

    [actions.dublicateTaskRequest, handlers.dublicateTaskRequest],
    [actions.dublicateTaskSuccess, handlers.dublicateTaskSuccess],
    [actions.dublicateTaskFailure, handlers.dublicateTaskFailure],

    [actions.fetchItemTasksRequest, handlers.fetchItemTasksRequest],
    [actions.fetchItemTasksSuccess, handlers.fetchItemTasksSuccess],
    [actions.fetchItemTasksFailure, handlers.fetchItemTasksFailure],
  ]),
  initialState,
);

export default reducer;
