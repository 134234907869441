import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { VersionLegalType } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { TextareaField } from 'components/Layout/Textarea';
import validate from './validator';
import { Block, FormContainer, Label, InputContainer, MarginField } from './styled';
import theme from 'theme';

const AddEditVersionLegalForm = () => (
  <FormContainer>
    <>
      <Block>
        <Label>Version Related</Label>
        <InputContainer>
          <Field
            component={TextFieldAdapter}
            floatingLabelText="Version number"
            name="VersionNumber"
            maxLength="5"
            isDecimal
          />
          <MarginField component={TextareaField} floatingLabelText="Version notes" name="VersionNotes" rows={7} />
        </InputContainer>
      </Block>
      <Block>
        <Label>Legal Related</Label>
        <InputContainer>
          <MarginField
            component={TextareaField}
            floatingLabelText="CopyRight Text"
            name="CopyrightText"
            maxLength="200"
            rows={1}
          />
          <Field
            component={TextareaField}
            floatingLabelText="Privacy URL"
            name="PrivacyUrl"
            type="url"
            maxLength="50"
            rows={1}
          />
        </InputContainer>
      </Block>
    </>
  </FormContainer>
);

const AddEditVersionLegalWithInput = withInputModal(AddEditVersionLegalForm);

const AddEditVersionLegalDialog = props => (
  <AddEditVersionLegalWithInput
    {...props}
    key="Add Edit Version legal With Input"
    initialValues={props.versionLegal}
    validate={validate}
    titleColor={theme.darkGrey}
  />
);

AddEditVersionLegalDialog.defaultProps = {
  handleDelete: () => {},
  versionLegal: {
    CopyrightText: '\u00A9 2021 Incheq LLC. All Rights Reserved',
    PrivacyUrl: 'https://',
  },
  withDelete: true,
};

AddEditVersionLegalDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  versionLegal: VersionLegalType,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
};

export default AddEditVersionLegalDialog;
