import styled from 'styled-components';

import { ControlsContainer, ControlButton } from '../RequestDetailsDialog/styled';

export const DialogContainer = styled.div`
  padding: 30px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
`;

export const DialogControlsContainer = styled(ControlsContainer)`
  height: 60px;
`;

export const DialogControlButton = styled(ControlButton)`
  font-size: 16px;
`;
