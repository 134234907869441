import styled from 'styled-components';

import { ButtonFlat } from 'components/Layout/Buttons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const ButtonChange = styled(ButtonFlat).attrs({
  backgroundColor: props => props.theme.primaryGreen,
  hoverColor: props => props.theme.primaryDocuments,
})`
  width: 80%;
  color: ${({ theme }) => theme.primaryWhite} !important;
`;

export const ButtonContinue = styled(ButtonChange).attrs({
  backgroundColor: props => props.theme.primary,
  hoverColor: props => props.theme.primaryLists,
})`
  margin-top: 10px !important;
`;
