const initialState = {
  shortcut: {},
  currentSchedule: {},
  shortcutSitesInfo: [],
  listOfSites: 'empty',
  listOfUnits: 'empty',
  listOfItems: [],
  managementOptions: 'empty',
  personOptions: 'empty',
  teamOptions: 'empty',
  error: '',
  errorAutorun: '',
  isLoading: false,
  checklistsList: {},
  shortcutsList: [],
  listStatus: [],
  shortcutToPreview: {},
  switchAutorun: {},
  runNextPeriod: {},
};

export const editSiteScheduleRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editSiteScheduleSuccess = (state, { payload }) => ({
  ...state,
  currentSchedule: payload,
  isLoading: false,
});

export const editSiteScheduleFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const sendSiteScheduleRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const sendSiteScheduleSuccess = (state, { payload }) => ({
  ...state,
  currentSchedule: payload,
  isLoading: false,
});

export const sendSiteScheduleFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getSingleShortcutRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSingleShortcutSuccess = (state, { payload }) => ({
  ...state,
  shortcutToPreview: payload,
  isLoading: false,
});

export const getSingleShortcutFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getSiteScheduleRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSiteScheduleSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  siteSchedule: payload,
});

export const getSiteScheduleFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getShortcutsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getShortcutsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  shortcutsList: payload,
});

export const getShortcutsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getShortcutSitesRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getShortcutSitesSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  shortcutSitesInfo: payload,
});

export const getShortcutSitesFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getSwitchAutorunRequest = state => ({
  ...state,
  errorAutorun: '',
  isLoading: true,
});

export const getSwitchAutorunSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  switchAutorun: payload,
});

export const getSwitchAutorunFailure = (state, { payload }) => ({
  ...state,
  errorAutorun: payload,
  isLoading: false,
});

export const getRunNextPeriodRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getRunNextPeriodSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  runNextPeriod: payload,
});

export const getRunNextPeriodFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createChecklistsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createChecklistsSuccess = (state, { payload }) => ({
  ...state,
  checklistsList: {
    ...state.checklistsList,
    [payload.siteId]: payload.checklists,
  },
  managementOptions: 'empty',
  personOptions: 'empty',
  teamOptioms: 'empty',
  currentSchedule: {},
  isLoading: false,
  shortcut: payload.isFinal ? {} : state.shortcut,
  listOfSites: payload.isFinal ? 'empty' : state.listOfSites,
});

export const createChecklistsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editChecklistRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editChecklistSuccess = state => ({
  ...state,
  isLoading: false,
});

export const editChecklistFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createShortcutRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createShortcutSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  shortcut: payload,
  checklistsList: {},
});

export const createShortcutFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editShortcutRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editShortcutSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  shortcut: payload,
});

export const editShortcutFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getListScheduleRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getListScheduleSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  listSchedule: payload,
});

export const getListScheduleFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const saveShortcutOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const saveShortcutOptionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  shortcut: payload,
});

export const saveShortcutOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const sendLocationOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const sendLocationOptionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  listOfSites: payload,
});

export const sendLocationOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const sendUnitOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const sendUnitOptionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  listOfUnits: payload,
});

export const sendUnitOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const sendItemOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const sendItemOptionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  listOfItems: payload,
});

export const sendItemOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const sendManagementOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
  managementOptions: 'loading',
});

export const sendManagementOptionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  managementOptions: payload,
});

export const sendManagementOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const sendPersonOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
  personOptions: 'loading',
});

export const sendPersonOptionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  personOptions: payload,
});

export const sendPersonOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const sendTeamOptionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
  teamOptions: 'loading',
});

export const sendTeamOptionsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  teamOptions: payload,
});

export const sendTeamOptionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const listStatusRequest = state => ({
  ...state,
  isLoading: true,
});

export const listStatusSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  listStatus: payload,
});

export const listStatusFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteShortcutRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteShortcutSuccess = state => ({
  ...state,
  isLoading: false,
  shortcut: {},
  listOfSites: 'empty',
  listOfUnits: 'empty',
  listOfItems: [],
  managementOptions: 'empty',
  personOptions: 'empty',
  teamOptions: 'empty',
  currentSchedule: {},
  siteSchedule: {},
  shortcutSitesInfo: [],
  checklistsList: {},
  shortcutToPreview: {},
  error: '',
});

export const deleteShortcutFailure = (state, { payload }) => ({
  ...state,
  shortcut: {},
  error: payload,
  isLoading: false,
});

export const deleteSiteSchedule = state => ({
  ...state,
  siteSchedule: {},
});

export const deletePersonOptions = state => ({
  ...state,
  personOptions: 'empty',
});

export default initialState;
