export const FORCE_LOGOUT_KEY = 'InCheq-Force-Logout';
export const INCHEQ_USER = 'InCheq-User';
export const INCHEQ_USER_TENANT_ID = 'InCheq-User-Tenant-Id';
export const INCHEQ_SCHEDULER_TYPE = 'Scheduler-Type';

export const setSchedulerType = type => {
  localStorage.setItem(INCHEQ_SCHEDULER_TYPE, JSON.stringify(type));
};

export const saveUser = user => {
  localStorage.setItem(INCHEQ_USER, JSON.stringify(user));
};

export const saveTenantId = user => {
  localStorage.setItem(INCHEQ_USER_TENANT_ID, JSON.stringify(user.tenantId));
};

export const setForceLogout = () => {
  localStorage.setItem(FORCE_LOGOUT_KEY, 'true');
};

export const clearForceLogout = () => {
  localStorage.setItem(FORCE_LOGOUT_KEY, 'false');
};

export const getSchedulerType = () => JSON.parse(localStorage.getItem(INCHEQ_SCHEDULER_TYPE));

export const getUser = () => JSON.parse(localStorage.getItem(INCHEQ_USER));

export const getTenantId = () => JSON.parse(localStorage.getItem(INCHEQ_USER_TENANT_ID));

export const deleteUser = () => {
  localStorage.removeItem(INCHEQ_USER);
};

export const saveSelectedSite = id => {
  localStorage.setItem(`InCheq-SelectedSite-${getUser().userId}`, id);
};

export const getSelectedSite = () => localStorage.getItem(`InCheq-SelectedSite-${getUser().userId}`);

export const getForceLogout = () => JSON.parse(localStorage.getItem(FORCE_LOGOUT_KEY));
