import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'components/Dialogs/v1/Base';
import { LaunchIcon } from 'components/Layout/Icons';

import theme from 'theme';

import * as S from './styled';

const TeamMemberInfoDialog = ({ open, handleClose, currentMember }) => (
  <Dialog open={open} onRequestClose={handleClose} title="Employee Detail" titleColor={theme.primaryBlue}>
    {currentMember && (
      <S.DialogContainer>
        <S.RedText>
          Availabilty: <S.BoldRedText>{currentMember.UserAvailabilityOptionName}</S.BoldRedText>
        </S.RedText>
        <S.SectionContainer>
          <S.Title>Name</S.Title>
          <S.Text>{currentMember.Name}</S.Text>
        </S.SectionContainer>
        <S.SectionContainer>
          <S.Title>User Type</S.Title>
          <S.Text>{currentMember.UserType}</S.Text>
        </S.SectionContainer>
        <S.SectionContainer>
          <S.Title>Email</S.Title>
          <S.TextContainer>
            <S.Text>{currentMember.Email}</S.Text>
            <S.Link href={`mailto:${currentMember.Email}`}>
              <LaunchIcon />
            </S.Link>
          </S.TextContainer>
        </S.SectionContainer>
        <S.SectionContainer>
          <S.Title>Phone</S.Title>
          <S.Text>{currentMember.Phone}</S.Text>
        </S.SectionContainer>
        <S.SectionContainer>
          <S.Title>Status</S.Title>
          <S.Text>{currentMember.Status}</S.Text>
        </S.SectionContainer>
        <S.SectionContainer>
          <S.Title>Job Function</S.Title>
          <S.Text>{currentMember.JobFunctionName}</S.Text>
        </S.SectionContainer>
      </S.DialogContainer>
    )}
  </Dialog>
);

TeamMemberInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentMember: PropTypes.shape().isRequired,
};

export default TeamMemberInfoDialog;
