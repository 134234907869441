import { call, put, takeEvery } from 'redux-saga/effects';
import { openErrorDialog } from 'redux/errorHandler';

import { getCurrentVersion } from 'http/versionLegals';

import { actions } from './index';

const publicRoutes = ['/login', '/forgot', '/activate', '/email/unsubscribe', '/verify'];

function* setShouldRedirectToLogin({ payload }) {
  const isPublicRoute = publicRoutes.includes(window.location.pathname);
  const error = payload?.error || payload;
  if (!isPublicRoute) {
    if (error && error.response && (error.response.status === 403 || error.response.status === 401)) {
      yield put(actions.shouldRedirectToLoginSet());
    }
  }
  if (error && error.response && error.response?.data?.type === 'DEMO_TIER_EXPIRED') {
    yield put(openErrorDialog(error.response.data.error, 'Alert'));
  }
}

function* fetchCurrentVersion() {
  try {
    const { data } = yield call(getCurrentVersion);
    yield put(actions.fetchCurrentVersionSuccess(data.root[0]));
  } catch (error) {
    yield put(actions.fetchCurrentVersionFailure(error));
  }
}

const appSagas = [
  takeEvery(action => /FAILURE/.test(action.type), setShouldRedirectToLogin),
  takeEvery(actions.fetchCurrentVersionRequest, fetchCurrentVersion),
];

export default appSagas;
