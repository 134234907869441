import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as partsActions } from 'redux/parts';
import { commitUpload, abortUpload } from 'http/parts';
import BaseDialog from 'components/Dialogs/Base';
import Warning from 'components/Dialogs/Warning';
import ConfirmDialog from 'components/Dialogs/Confirm';

import * as S from './styled';

import Upload from './Upload';

const styles = {
  modal: {
    maxWidth: '500px',
  },
};

const initialState = {
  isAbortDialogOpened: false,
  isWarningDialogOpened: false,
  isUploadFinished: false,
  temproraryId: null,
};
class UploadPartsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      getPartsRequest: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = { ...initialState };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ ...initialState });
    }
  }

  commit = () => {
    const { temproraryId } = this.state;
    commitUpload(temproraryId).then(() => {
      this.props.onRequestClose();
      this.props.actions.getPartsRequest();
    });
  };

  abort = () => {
    const { temproraryId } = this.state;
    abortUpload(temproraryId).then(() => {
      this.props.onRequestClose();
    });
  };

  handleConfirmationApprove = () => this.abort();

  handleConfirmationReject = () => this.setState({ isAbortDialogOpened: false });

  handleAbortPress = () => this.setState({ isAbortDialogOpened: true });

  handleWarningDialogClose = () => this.setState({ isWarningDialogOpened: false });

  onUploadStateChanged = (isUploadFinished, info) => {
    const newState = {
      temproraryId: info.PartTemplateTemporaryId,
    };

    if (info.HadErrors) {
      newState.isWarningDialogOpened = true;
      newState.isUploadFinished = false;
    }

    if (!info.HadErrors && isUploadFinished) {
      newState.isUploadFinished = true;
    }

    this.setState(newState);
  };

  render() {
    const { isUploadFinished, isWarningDialogOpened, isAbortDialogOpened } = this.state;
    const { open, onRequestClose } = this.props;

    const actions = [
      <S.AbortButton disabled={!isUploadFinished} onClick={this.handleAbortPress} />,
      <S.CommitButton disabled={!isUploadFinished} onClick={this.commit} />,
    ];

    return (
      <BaseDialog
        actions={actions}
        open={open}
        title="Upload Parts List"
        modalStyles={styles.modal}
        onRequestClose={onRequestClose}
        repositionOnUpdate
      >
        <S.Container>
          <Upload onUploadStateChanged={this.onUploadStateChanged} />
        </S.Container>
        <ConfirmDialog
          open={isAbortDialogOpened}
          title="Warning"
          text="Are you sure your want to abort? All Data will be removed"
          confirmText="Abort Upload"
          cancelText="Return"
          onApprove={this.handleConfirmationApprove}
          onReject={this.handleConfirmationReject}
        />
        <Warning
          open={isWarningDialogOpened}
          handleClose={this.handleWarningDialogClose}
          onSubmit={this.handleWarningDialogClose}
          text="The process has failed. Please check the file and try again"
          title="Warning"
        />
      </BaseDialog>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPartsRequest: partsActions.getPartsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPartsDialog);
