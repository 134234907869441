import styled from 'styled-components';
import { InputBlockLabel, BorderRadiusContainer } from 'components/Layout/Containers';
import { RadioButtonGroup } from 'material-ui';

const DialogContainer = styled.div`
  padding: 20px 40px;
`;

const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.mainRed};
`;

const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.mainLightBlue};
  padding: 16px;
  text-align: left;
`;

const SecondaryBlock = styled(BorderRadiusContainer)`
  margin: 30px 0;
`;

const SearchContainer = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin: 20px 0;
`;

const GroupRadioButton = styled(RadioButtonGroup)`
  padding: 10px 40px;
`;

const radioButtonStyle = {
  borderTop: '1px solid',
  padding: '10px 0',
  width: '100%',
};

export { Label, Title, SearchContainer, DialogContainer, SecondaryBlock, GroupRadioButton, radioButtonStyle };
