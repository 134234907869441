import styled from 'styled-components';

import { TextareaField } from 'components/Layout/Textarea';

export const Container = styled.div`
  padding: 0 20px;
`;

export const Textarea = styled(TextareaField)`
  margin-top: 15px;

  div > textarea {
    border-color: #e0e0e0 !important;
  }
`;
