import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { Bar } from 'components/Layout/Containers';
import { black } from 'material-ui/styles/colors';

export const Container = styled(Bar).attrs({
  titleStyle: {
    display: 'none',
  },
  iconStyleLeft: {
    height: '100%',
  },
  style: {
    padding: '10px',
  },
})`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? theme.fieldsBorderColor : theme.lightGrey} !important;
  z-index: ${({ zindex }) => zindex} !important;
  align-items: center;
  position: ${({ position }) => position} !important;
  top: ${({ top }) => top};
`;

export const SubTitle = styled(Flex).attrs({
  flex: '1',
})`
  font-size: 16px;
  font-weight: 900;
  color: ${({ theme }) => theme.primaryBlack};
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & :nth-child(1) {
    display: block;
    text-align: ${({ centeredTitle }) => (centeredTitle ? 'center' : 'auto')};
    font-size: ${({ centeredTitle }) => (centeredTitle ? '18px' : '16px')};
    font-weight: ${({ centeredTitle }) => (centeredTitle ? 'normal' : 'bold')};
  }
`;

export const SearchField = styled(SubTitle)`
  font-weight: normal;
  padding: 0 150px 0 10px;
`;

export const SubTitleIcon = styled(Flex)`
  padding-right: 15px;
`;

export const SubTitleIconStyle = {
  height: '32px',
  width: '32px',
};

export const Title = styled(SubTitle)`
  padding-right: ${({ padding }) => padding};

  @media only screen and (max-width: 530px) {
    flex-direction: column;
    padding-right: 0;

    & :nth-child(2) {
      padding-left: 0;
    }

    & :nth-child(2) > div {
      font-weight: 400;
      font-size: 15px;
      color: rgb(102, 102, 102);
    }
  }
`;

export const SiteCentered = styled(SubTitle)`
  padding-left: 0;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: 530px) {
    max-width: 150px;
  }
`;

export const SiteOnLeft = styled.div`
  padding: 4px 0;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal !important;
`;

export const ListVersionBlock = styled(Flex)`
  width: 25px;
  height: 25px;
  margin: 16px;
  padding: 2px;
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
`;

export const underlineStyle = {
  borderColor: black,
};

export const placeholderStyle = {
  color: 'darkgrey',
  borderColor: black,
  fontWeight: 'normal',
};

export const RedSubtitle = styled.div`
  color: ${({ theme }) => theme.mainRed};
  margin-top: 4px;
`;
