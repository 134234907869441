import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Container, Value } from './styled';

const Period = ({ type, startRange }) => {
  if (type !== 'day' && type !== 'week' && type !== 'all') {
    return null;
  }

  const endDate = moment(startRange).add(6, 'day').format('MM/DD/YYYY');

  return (
    <Container>
      {(type === 'day' || type === 'all') && <Value>{startRange.format('dddd | MMMM D, YYYY')}</Value>}
      {type === 'week' && (
        <Value>
          {startRange.format('MM/DD/YYYY')} - {endDate}
        </Value>
      )}
    </Container>
  );
};

Period.propTypes = {
  type: PropTypes.string.isRequired,
  startRange: PropTypes.object.isRequired,
};

export default Period;
