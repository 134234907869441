import { createSelector } from 'reselect';

export const getUnitsIdsList = createSelector(
  units => units.unitsList,
  unitsList => unitsList.map(unit => unit.Id),
);

export const getUnitNameById = createSelector(
  (units, unitId) => unitId,
  units => units.unitsList,
  (unitId, unitsList) => {
    const unitData = unitsList.filter(unit => unit.Id === unitId)[0];

    return unitData ? unitData.Name || 'No name unit' : '';
  },
);
