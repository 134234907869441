import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SensorType, SensorTypeType, UnitItemType } from 'configs/propTypes';
import { unitDetailsToggle } from 'configs/toggles';
import { resizeWindow } from 'helpers';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as sensorsActions } from 'redux/sensors';
import { actions as unitActions } from 'redux/unit';
import { AddIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import LevelSettingsDialog from 'components/Dialogs/LevelSettingsDialog';
import Toggle from 'components/Toggle';
import { ToolbarContainer } from '../styled';

import AddEditItemDialog from './AddEditItemDialog';
import AddEditTaskTemplateDialog from './AddEditTaskTemplateDialog';
import DuplicateDialog from './DuplicateDialog';
import SensorsDialog from './SensorsDialog';
import LinkSensorDialog from './LinkSensorDialog';
import Item from './Item';
import * as S from './styled';

class ItemsList extends PureComponent {
  static propTypes = {
    addItem: PropTypes.func.isRequired,
    addTaskTemplate: PropTypes.func.isRequired,
    createdEditedItem: PropTypes.bool.isRequired,
    createdEditedTask: PropTypes.bool.isRequired,
    createdItemId: PropTypes.string,
    deleteItem: PropTypes.func.isRequired,
    deleteItemPicture: PropTypes.func.isRequired,
    deleteTaskTemplate: PropTypes.func.isRequired,
    editItem: PropTypes.func.isRequired,
    editSensor: PropTypes.func.isRequired,
    editTaskTemplate: PropTypes.func.isRequired,
    getItemSensors: PropTypes.func.isRequired,
    getSensorsForSite: PropTypes.func.isRequired,
    getSensorLevels: PropTypes.func.isRequired,
    getSensorTypes: PropTypes.func.isRequired,
    getUnitItems: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(UnitItemType).isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        unitId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    requestDuplicateItem: PropTypes.func.isRequired,
    requestUploadUnitItemPicture: PropTypes.func.isRequired,
    sensorsForSite: PropTypes.arrayOf(SensorType).isRequired,
    setLevelsForItemSensor: PropTypes.func.isRequired,
    typesList: PropTypes.arrayOf(SensorTypeType).isRequired,
    unsetItemAdded: PropTypes.func.isRequired,
    userType: PropTypes.number.isRequired,
    isEditSensorLoading: PropTypes.bool.isRequired,
    typeHandler: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  };

  static defaultProps = {
    createdItemId: null,
  };

  state = {
    addEditItemDialogOpened: false,
    addEditTaskTemplateDialogOpened: false,
    addPhotosDialogOpened: false,
    distance: 3,
    duplicateDialogOpened: false,
    expandedItemId: null,
    flag: false,
    selectedItem: {},
    itemPhotosToDisplay: { itemId: '', photos: [] },
    itemToDuplicate: null,
    itemToEdit: null,
    levelSettingsDialogOpened: false,
    linkSensorDialogOpened: false,
    taskTemplateToEdit: { itemId: '', task: null },
    saveAndAddTaskSelected: false,
    selectedSensor: {},
    selectedSensorId: '',
    sensorsDialogOpened: false,
    uploadPdfDialogOpened: false,
    withSaveContinueTaskModal: false,
    setLevels: false,
  };

  componentDidUpdate(prevProps) {
    const { isEditSensorLoading, sensorsForSite } = this.props;
    const { setLevels, selectedSensorId } = this.state;

    if (isEditSensorLoading !== prevProps.isEditSensorLoading && isEditSensorLoading === false && setLevels) {
      const selectedSensor = sensorsForSite.find(item => item.Id === selectedSensorId);
      const { linkSensorDialogOpened } = this.state;
      if (!linkSensorDialogOpened && !this.props.error) {
        this.openLevelSettingsDialog(selectedSensor);
      }
      this.setState({ setLevels: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createdEditedTask) {
      this.closeAddEditTaskTemplateDialog();
    }

    if (nextProps.createdEditedItem) {
      this.props.unsetItemAdded();
      this.closeAddEditItemDialog();
    }
  }

  onDeleteItem = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Item? This cannot be undone!',
      this.onDeleteItemApprove,
      'Delete?',
    );
  };

  onDeleteItemApprove = () => {
    this.closeAddEditItemDialog();
    this.props.deleteItem(this.state.itemToEdit.Id);
  };

  onDeleteTaskTemplate = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Task? This cannot be undone!',
      this.onDeleteTaskTemplateApprove,
      'Delete?',
    );
  };

  onDeleteTaskTemplateApprove = () => {
    this.closeAddEditTaskTemplateDialog();

    const { itemId, task } = this.state.taskTemplateToEdit;
    this.props.deleteTaskTemplate({ itemId, taskId: task.Id });
  };

  onDeleteItemPicture = (pictureId, pictureName) => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Photo?',
      () =>
        this.props.deleteItemPicture({
          pictureId,
          pictureName,
          itemId: this.state.itemPhotosToDisplay.itemId,
        }),
      'Delete Photo?',
    );
  };

  addTask = taskData => {
    this.props.addTaskTemplate({ itemId: this.state.taskTemplateToEdit.itemId, taskData });
  };

  handleSaveAndSetLevels = () => this.setState({ setLevels: true });

  closeAddEditItemDialog = () => {
    this.setState({ addEditItemDialogOpened: false }, () => {
      this.openAddEditTaskTemplateDialogOnModalClose();
    });
  };

  closeAddEditTaskTemplateDialog = () => {
    this.setState({ addEditTaskTemplateDialogOpened: false }, () => {
      this.openAddEditTaskTemplateDialogOnModalClose();
    });
  };

  closeDuplicateDialog = () => {
    this.setState({ duplicateDialogOpened: false, itemToDuplicate: null });
  };

  closeSensorsDialog = () => {
    const {
      match: {
        params: { unitId },
      },
    } = this.props;

    this.props.getUnitItems({ unitId });
    this.setState({ sensorsDialogOpened: false });
  };

  closeLinkSensorDialog = () => {
    this.setState({ linkSensorDialogOpened: false });
  };

  closeLevelSettingsDialog = () => {
    this.setState({ levelSettingsDialogOpened: false, selectedSensor: {} });
  };

  duplicateItem = itemData => {
    this.closeDuplicateDialog();
    this.props.requestDuplicateItem(itemData);
  };

  editTask = taskData => {
    this.props.editTaskTemplate({ itemId: this.state.taskTemplateToEdit.itemId, taskData });
  };

  getSelectedSensorId = selectedSensorId => {
    this.setState({ selectedSensorId });
  };

  openDuplicateDialog = itemToDuplicate => {
    this.setState({ duplicateDialogOpened: true, itemToDuplicate });
  };

  openAddEditItemDialog = item => {
    if (item.Id) {
      this.setState({ addEditItemDialogOpened: true, itemToEdit: item });
    } else {
      this.setState({ addEditItemDialogOpened: true, itemToEdit: null });
    }
  };

  openAddEditTaskTemplateDialogOnModalClose = () => {
    if (this.state.saveAndAddTaskSelected) {
      const { createdItemId } = this.props;

      if (createdItemId) {
        setTimeout(() => {
          // need time for modal animations
          this.setState({
            saveAndAddTaskSelected: false,
            addEditTaskTemplateDialogOpened: true,
            taskTemplateToEdit: { itemId: this.props.createdItemId },
          });
        }, 500);
      }
    } else {
      this.setState({ withSaveContinueTaskModal: false });
    }
  };

  openSensorsDialog = item => {
    this.props.getItemSensors(item.Id);
    this.props.getSensorsForSite();
    this.props.getSensorTypes();

    this.setState({ sensorsDialogOpened: true, selectedItem: item });
  };

  openLinkSensorDialog = () => {
    this.setState({ linkSensorDialogOpened: true });
  };

  openLevelSettingsDialog = sensor => {
    this.props.getSensorLevels({ sensor, showLevelSettingsDialog: this.showLevelSettingsDialog });
  };

  showLevelSettingsDialog = selectedSensor => {
    this.setState({
      levelSettingsDialogOpened: true,
      selectedSensor,
    });
  };

  uploadItemPicture = files => {
    this.props.requestUploadUnitItemPicture({
      itemId: this.state.itemPhotosToDisplay.itemId,
      files,
    });
  };

  changeDistance = (event, index, value) => {
    this.setState({ distance: value });
  };

  renderSensorsDialogTitle = () => (
    <S.Title>
      <S.ItemName>{this.state.selectedItem.Name}</S.ItemName>
      <div>Sensors</div>
    </S.Title>
  );

  sumbitAddItem = item => this.props.addItem(item);

  submitEditItem = item => this.props.editItem(item);

  submitLinkSensorDialog = values => {
    const { sensorsForSite, typesList } = this.props;
    const {
      selectedItem: { Id, UnitId },
    } = this.state;

    const selectedSensor = sensorsForSite.find(item => item.Id === values.SensorID);
    const LevelsCount = typesList.find(type => type.Id === values.SensorTypeID).LevelsCount;

    this.props.editSensor({
      itemId: Id,
      values: {
        id: values.SensorID,
        dto: {
          ...selectedSensor,
          ItemId: Id,
          IsAssigned: true,
          LevelsCount,
          SensorTypeID: values.SensorTypeID,
          UnitId,
        },
      },
    });

    this.closeLinkSensorDialog();

    setTimeout(() => resizeWindow(), 0);
  };

  submitLevelSettingsDialog = values => {
    const { selectedItem, selectedSensor } = this.state;
    const newValues = [];

    Object.keys(values).forEach(val => {
      if (val.endsWith('StartData')) {
        const id = val.slice(0, -9);

        const level = {
          LevelName: values[`${id}LevelName`],
          MasterSensorID: selectedSensor.Id,
          MasterSensorTypeId: values.MasterSensorTypeId,
          OrderField: values[`${id}OrderField`],
          StartData: values[val],
          ShouldSendAlert: values[`${id}ShouldSendAlert`],
        };

        if (values.escalation === 'both' && level.LevelName === 'Normal') {
          level.StartData = null;
        }

        if (values[`${id}Original`]) {
          level.Id = id;
        }

        newValues.push(level);
      }
    });

    this.props.setLevelsForItemSensor({
      itemId: selectedItem.Id,
      newValues,
      sensorId: selectedSensor.Id,
    });
    this.closeLevelSettingsDialog();
  };

  handleSaveAndAddTask = () =>
    this.setState({
      withSaveContinueTaskModal: true,
      saveAndAddTaskSelected: true,
    });

  render() {
    const { items, userType, typeHandler, type } = this.props;
    const {
      addEditItemDialogOpened,
      addEditTaskTemplateDialogOpened,
      distance,
      duplicateDialogOpened,
      itemToDuplicate,
      itemToEdit,
      levelSettingsDialogOpened,
      linkSensorDialogOpened,
      selectedSensor,
      sensorsDialogOpened,
      taskTemplateToEdit,
      withSaveContinueTaskModal,
    } = this.state;

    return [
      <S.Container key="Items list">
        <ToolbarContainer>
          <Toggle config={unitDetailsToggle} selected={type.toLowerCase()} handler={typeHandler} minWidth />
          <ButtonIcon onClick={this.openAddEditItemDialog}>
            <AddIcon />
          </ButtonIcon>
        </ToolbarContainer>
        {items.length ? (
          items.map(item => (
            <Item
              key={item.Id}
              item={item}
              onClickItem={this.openAddEditItemDialog}
              onDuplicateItemClick={this.openDuplicateDialog}
              openSensorsDialog={this.openSensorsDialog}
              userType={userType}
            />
          ))
        ) : (
          <S.Message>This unit has no items.</S.Message>
        )}
      </S.Container>,
      <AddEditItemDialog
        key="Add edit item dialog"
        handleClose={this.closeAddEditItemDialog}
        handleDelete={this.onDeleteItem}
        onSubmit={itemToEdit ? this.submitEditItem : this.sumbitAddItem}
        open={addEditItemDialogOpened}
        title={itemToEdit ? 'Edit Item' : 'Add Item'}
        item={itemToEdit || undefined}
        distance={distance}
        handleSaveContinue={this.handleSaveAndAddTask}
        changeDistance={this.changeDistance}
        saveContinueLabel="Save and Add a Task"
        withSaveContinue={!itemToEdit}
        BigButtons
        withGreenButton={!itemToEdit}
        withGreenBorder={!itemToEdit}
        withSubmit={!itemToEdit}
        isNewDeleteButton={!!itemToEdit}
        isNewSubmitButton={!!itemToEdit}
        gapValue={itemToEdit ? '20px' : '5px'}
        hasPadding
      />,
      <AddEditTaskTemplateDialog
        key="Add edit task dialog"
        handleClose={this.closeAddEditTaskTemplateDialog}
        handleDelete={this.onDeleteTaskTemplate}
        onSubmit={taskTemplateToEdit.task ? this.editTask : this.addTask}
        open={addEditTaskTemplateDialogOpened}
        title={taskTemplateToEdit.task ? 'Edit Task' : 'Add Task'}
        task={taskTemplateToEdit.task || undefined}
        withDelete={!!taskTemplateToEdit.task}
        handleSaveContinue={this.handleSaveAndAddTask}
        saveContinueLabel="Save and Add Next Task"
        withSaveContinue={withSaveContinueTaskModal}
        BigButtons
        withGreenButton={!itemToEdit}
        withGreenBorder={!itemToEdit}
        gapValue={itemToEdit ? '20px' : '5px'}
        hasPadding
      />,
      <DuplicateDialog
        key="Duplicate Dialog"
        handleClose={this.closeDuplicateDialog}
        itemToDuplicate={itemToDuplicate}
        onSubmit={this.duplicateItem}
        open={duplicateDialogOpened}
      />,
      <SensorsDialog
        key="Sensors dialog"
        title={this.renderSensorsDialogTitle()}
        open={sensorsDialogOpened}
        handleClose={this.closeSensorsDialog}
        onSubmit={() => {}}
        openLevelSettingsDialog={this.openLevelSettingsDialog}
        openLinkSensorDialog={this.openLinkSensorDialog}
        withSubmit={false}
        withoutTopBorder
      />,
      <LinkSensorDialog
        key="Link Sensor Dialog"
        handleClose={this.closeLinkSensorDialog}
        handleSaveContinue={this.handleSaveAndSetLevels}
        title="Link Air Inlet Filter with Sensor"
        open={linkSensorDialogOpened}
        onSubmit={this.submitLinkSensorDialog}
        saveContinueLabel="Next: Set Sensor Levels"
        getSelectedSensorId={this.getSelectedSensorId}
        withSaveContinue
        BigButtons
        withoutTopBorder
      />,
      <LevelSettingsDialog
        key="Level Settings Dialog"
        handleClose={this.closeLevelSettingsDialog}
        title="Level Settings"
        open={levelSettingsDialogOpened}
        onSubmit={this.submitLevelSettingsDialog}
        selectedSensorType={{
          ...selectedSensor.SensorType,
          AreLevelsDifferentFromTemplate: selectedSensor.AreLevelsDifferentFromTemplate,
        }}
        withoutTopBorder
      />,
    ];
  }
}

const mapStateToProps = ({
  auth: {
    user: { userType },
  },
  sensors: { sensorsForSite, typesList, error, isEditSensorLoading },
  unit,
}) => ({
  createdEditedItem: unit.createdEditedItem,
  createdEditedTask: unit.createdEditedTask,
  createdItemId: unit.createdItemId,
  itemPdfs: unit.unitItemsPdfsList,
  itemPdfUploaded: unit.itemPdfUploaded,
  itemsPictures: unit.unitItemsPictures,
  itemPictureUploaded: unit.itemPictureUploaded,
  itemsTasks: unit.unitItemsTasks,
  sensorsForSite,
  typesList,
  userType,
  error,
  isEditSensorLoading,
});

const mapDispatchToProps = {
  addItem: unitActions.addUnitItemRequest,
  addTaskTemplate: unitActions.addUnitTaskRequest,
  deleteItem: unitActions.deleteUnitItemRequest,
  deleteItemPdf: unitActions.deleteUnitItemPdfRequest,
  deleteItemPicture: unitActions.deleteUnitItemPictureRequest,
  deleteTaskTemplate: unitActions.deleteUnitTaskRequest,
  editItem: unitActions.editUnitItemRequest,
  editTaskTemplate: unitActions.editUnitTaskRequest,
  editSensor: sensorsActions.editSensorRequest,
  getItemSensors: sensorsActions.getItemSensorsRequest,
  getSensorsForSite: sensorsActions.getSensorsForSiteRequest,
  getSensorLevels: sensorsActions.getLevelsForItemSensorRequest,
  getSensorTypes: sensorsActions.getSensorTypesRequest,
  getUnitItems: unitActions.fetchUnitItemsRequest,
  openConfirmationDialog,
  requestUploadUnitItemPdf: unitActions.uploadUnitItemPdfRequest,
  requestUploadUnitItemPicture: unitActions.uploadUnitItemPictureRequest,
  setLevelsForItemSensor: sensorsActions.setLevelsForItemSensorRequest,
  requestDuplicateItem: unitActions.duplicateUnitItemRequest,
  unsetItemPdfUploadedFlad: unitActions.itemPdfUploadedFlagUnset,
  unsetItemPictureUploadedFlad: unitActions.itemPictureUploadedFlagUnset,
  unsetItemAdded: unitActions.clearItemAdded,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemsList));
