import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import http from '../../../../../http';

import * as S from './styled';

const Image = ({ isMaster, isPDF }) => (
  <S.Image
    src={
      isPDF
        ? `${http.defaults.baseURL}/${isMaster ? 'Master' : ''}Settings/GetLogo?${moment()}`
        : `${http.defaults.baseURL}/${isMaster ? 'Master' : ''}Settings/GetLogoEmail?${moment()}`
    }
    alt="There is no logo, yet."
    isPDF={isPDF}
  />
);

Image.propTypes = {
  isMaster: PropTypes.bool.isRequired,
  isPDF: PropTypes.bool.isRequired,
};

export default Image;
