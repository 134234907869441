import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  DELETE_DOCUMENT_REQUEST: undefined,
  DELETE_DOCUMENT_SUCCESS: undefined,
  DELETE_DOCUMENT_FAILURE: undefined,
  DELETE_DOCUMENT_PICTURE_REQUEST: undefined,
  DELETE_DOCUMENT_PICTURE_SUCCESS: undefined,
  DELETE_DOCUMENT_PICTURE_FAILURE: undefined,
  DOCUMENT_FETCH_REQUEST: undefined,
  DOCUMENT_FETCH_SUCCESS: undefined,
  DOCUMENT_FETCH_FAILURE: undefined,
  EDIT_DOCUMENT_REQUEST: undefined,
  EDIT_DOCUMENT_SUCCESS: undefined,
  EDIT_DOCUMENT_FAILURE: undefined,
  UPLOAD_PHOTO_REQUEST: undefined,
  UPLOAD_PHOTO_SUCCESS: undefined,
  UPLOAD_PHOTO_FAILURE: undefined,

  REDIRECT_FLAG_UNSET: undefined,
  IMAGES_STATE_CLEAR: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.deleteDocumentRequest, handlers.deleteDocumentRequest],
    [actions.deleteDocumentSuccess, handlers.deleteDocumentSuccess],
    [actions.deleteDocumentFailure, handlers.deleteDocumentFailure],
    [actions.deleteDocumentPictureRequest, handlers.deleteDocumentPictureRequest],
    [actions.deleteDocumentPictureSuccess, handlers.deleteDocumentPictureSuccess],
    [actions.deleteDocumentPictureFailure, handlers.deleteDocumentPictureFailure],
    [actions.documentFetchRequest, handlers.documentFetchRequest],
    [actions.documentFetchSuccess, handlers.documentFetchSuccess],
    [actions.documentFetchFailure, handlers.documentFetchFailure],
    [actions.editDocumentRequest, handlers.editDocumentRequest],
    [actions.editDocumentSuccess, handlers.editDocumentSuccess],
    [actions.editDocumentFailure, handlers.editDocumentFailure],
    [actions.uploadPhotoRequest, handlers.uploadPhotoRequest],
    [actions.uploadPhotoSuccess, handlers.uploadPhotoSuccess],
    [actions.uploadPhotoFailure, handlers.uploadPhotoFailure],
    [actions.redirectFlagUnset, handlers.redirectFlagUnset],
    [actions.imagesStateClear, handlers.imagesStateClear],
  ]),
  initialState,
);

export default reducer;
