import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { actions as peopleActions } from 'redux/people';
import { actions as teamsActions } from 'redux/teams';
import { actions as settingsActions } from 'redux/settings';
import { actions as unitActions } from 'redux/unit';
import { actions as sitesActions } from 'redux/sites';
import { actions as scheduleActions } from 'redux/schedule';
import { TeamType } from 'configs/propTypes';
import { generateEditInitialValues } from '../helpers';
import ShortcutForm from './ShortcutForm';
import validate from './validator';

import * as S from './styled';

export const overrideValues = {
  0: 1,
  1: 7,
  2: 31,
  4: 365,
};

class SiteSetup extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      requestPeopleList: PropTypes.func.isRequired,
      getTeamsRequest: PropTypes.func.isRequired,
      getTeamMembersRequest: PropTypes.func.isRequired,
      requestGetSettings: PropTypes.func.isRequired,
      requestUnitItemsTasks: PropTypes.func.isRequired,
      requestAssignedForSite: PropTypes.func.isRequired,
      requestChecklistStatus: PropTypes.func.isRequired,
    }).isRequired,
    currentUnit: PropTypes.shape().isRequired,
    people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sitePeople: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setOverride: PropTypes.func.isRequired,
    schedulesTrigger: PropTypes.number.isRequired,
    list: PropTypes.arrayOf(TeamType).isRequired,
    setCurrentChecklist: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    currentUserId: PropTypes.string.isRequired,
    currentUserType: PropTypes.number.isRequired,
    currentChecklist: PropTypes.shape.isRequired,
    checklistToEdit: PropTypes.shape().isRequired,
    currentChecklistStatus: PropTypes.shape().isRequired,
    openTaskModal: PropTypes.func.isRequired,
    dublicateChecklist: PropTypes.bool.isRequired,
    checkChecklistName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      initialValues: this.props.currentChecklist.Name
        ? this.props.currentChecklist
        : generateEditInitialValues(this.props.checklistToEdit, this.props.dublicateChecklist),
      isConfirmationOpened: false,
      isConfirmationArchiveOpened: false,
      data: {},
      errorName: '',
    };
  }

  componentDidMount() {
    const { currentUnit, actions, checklistToEdit } = this.props;
    actions.requestPeopleList();
    actions.requestAssignedForSite({ siteId: currentUnit.SiteId, isShowOnlyActiveUsers: true });
    actions.getTeamsRequest();
    actions.requestGetSettings();
    actions.requestUnitItemsTasks({ Id: currentUnit.Id });
    if (checklistToEdit.Id) {
      actions.requestChecklistStatus({ checklistId: checklistToEdit.Id });
    }
  }

  onSend = () => this.props.onNext();

  cancelConfirmationDialog = () => {
    this.props.setOverride(false);
    this.setState({ isConfirmationOpened: false });
    this.onSend();
  };

  confirmConfirmationDialog = () => {
    this.props.setOverride(true);
    this.setState({ isConfirmationOpened: false });
    this.onSend();
  };

  closeConfirmationArchiveDialog = () => {
    this.setState({ isConfirmationArchiveOpened: false });
  };

  confirmConfirmationArchiveDialog = () => {
    this.setState({ isConfirmationArchiveOpened: false });
    this.checkOverride();
  };

  closeConfirmationDialog = () => this.setState({ isConfirmationOpened: false });

  onSubmitForm = ({ values }) => {
    const { currentChecklistStatus, checklistToEdit, dublicateChecklist, checkChecklistName } = this.props;
    if (checkChecklistName(values.Name) && !(checklistToEdit?.Name === values.Name)) {
      this.setState({ errorName: values.Name });
    } else {
      this.props.setCurrentChecklist(values);
      if (currentChecklistStatus.CompletedCount > 0 && !dublicateChecklist && checklistToEdit.Name) {
        this.setState({ isConfirmationArchiveOpened: true, data: values, errorName: '' });
      } else {
        this.setState({ data: values, errorName: '' }, this.checkOverride);
      }
    }
  };

  checkOverride = () => {
    const { currentChecklist, checklistToEdit, schedulesTrigger } = this.props;
    const { data } = this.state;

    const isEditTypeAndDiff =
      checklistToEdit?.Id &&
      checklistToEdit.IsDynamic !== !!data.Type &&
      moment.duration(moment(data.StartDate).diff(moment(new Date()))).asDays() < overrideValues[schedulesTrigger];

    if (isEditTypeAndDiff) {
      this.setState({ isConfirmationOpened: true });
      return;
    }

    const initialValues = currentChecklist.Name ? currentChecklist : generateEditInitialValues(checklistToEdit);
    const diff = checklistToEdit?.Id
      ? moment.duration(moment(initialValues.EndDate).diff(moment(new Date()))).asDays()
      : moment.duration(moment(data.StartDate).diff(moment(new Date()))).asDays();

    if (diff < overrideValues[schedulesTrigger]) {
      this.setState({ isConfirmationOpened: true });
    } else {
      this.onSend({ values: data });
    }
  };

  render() {
    const {
      currentUnit,
      people,
      sitePeople,
      list,
      currentUserId,
      currentUserType,
      checklistToEdit,
      openTaskModal,
      dublicateChecklist,
    } = this.props;
    const { initialValues, errorName } = this.state;

    return (
      <S.Container>
        <S.MainTitle>
          {currentUnit && `Site: ${currentUnit.AdditionalSiteId.substring(0, 5)} ${currentUnit.SiteName}`}
        </S.MainTitle>
        <S.MainTitle>
          {'List Template:'} <b>{currentUnit && ` ${currentUnit.Name}`}</b>
        </S.MainTitle>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          validate={validate}
          initialValues={
            initialValues?.Name
              ? initialValues
              : {
                  OwnerName:
                    people.length && currentUserType === 2 ? people.find(item => item.Id === currentUserId).Id : null,
                  RepeatInterval: initialValues.Id ? initialValues.RepeatInterval : 1,
                  RepeatCycle: -1,
                  AssignmentType: 0,
                  Type: 0,
                }
          }
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <ShortcutForm
                values={values}
                people={people}
                sitePeople={sitePeople}
                list={list}
                currentUserId={currentUserId}
                form={form}
                checklistToEdit={checklistToEdit}
                initialValues={initialValues}
                dublicateChecklist={dublicateChecklist}
                errorName={errorName}
              />
              <ActionsContainer>
                {!dublicateChecklist && checklistToEdit.Name && (
                  <ActionButton label="ARCHIVE/DELETE" type="button" onClick={openTaskModal} />
                )}
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
              <ConfirmationDialog
                label="YES - OVERRIDE"
                labelCancel="NO - start next period"
                width={'40%'}
                maxWidth={'400px'}
                isCancelNext
                text={
                  <S.ConfirmationContainer>
                    <S.ConfirmationText>Start date is sooner than lead time for creating tasks!</S.ConfirmationText>
                    <S.ConfirmationText>
                      <b>Override the lead time?</b>
                    </S.ConfirmationText>
                  </S.ConfirmationContainer>
                }
                open={this.state.isConfirmationOpened}
                onClick={() => this.confirmConfirmationDialog({ values })}
                handleClose={this.closeConfirmationDialog}
                onCancelClick={() => this.cancelConfirmationDialog({ values })}
              />
              <ConfirmationDialog
                width={'40%'}
                maxWidth={'400px'}
                text={
                  <S.ConfirmationArchiveContainer>
                    <S.ConfirmationText>
                      Completed tasks will be archived first.
                      <br />
                      Are you sure?
                    </S.ConfirmationText>
                    <ActionsContainer>
                      <ActionButton label="Cancel" onClick={this.closeConfirmationArchiveDialog} />
                      <ActionButton label="Yes" isNext onClick={this.confirmConfirmationArchiveDialog} />
                    </ActionsContainer>
                  </S.ConfirmationArchiveContainer>
                }
                open={this.state.isConfirmationArchiveOpened}
                handleClose={this.closeConfirmationArchiveDialog}
              />
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ people, sites, settings, teams, auth, schedule }) => ({
  isLoading: people.isLoading || sites.isLoading || teams.isLoading || settings.isLoading || auth.loading,
  schedulesTrigger: settings.list.TasksSchedulesTrigger,
  people: people.list.filter(user => user.Status !== 'Inactive'),
  sitePeople: sites.assignedUsersFullInfo,
  list: teams.list,
  currentUserId: auth.user.userId,
  currentUserType: auth.user.userType,
  currentChecklistStatus: schedule.currentChecklistStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...teamsActions,
      requestPeopleList: peopleActions.peopleListRequest,
      requestGetSettings: settingsActions.getSettingsRequest,
      requestUnitItemsTasks: unitActions.getUnitItemsTasksRequest,
      requestAssignedForSite: sitesActions.assignedUsersRequest,
      requestChecklistStatus: scheduleActions.checklistStatusRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteSetup);
