import React from 'react';
import PropTypes from 'prop-types';

import http from '../../../../../http';
import { UnitPictureType, UnitType } from 'configs/propTypes';
import ImageSwiper from 'components/ImageSwiper';
import { BarcodeContainer, BarcodeLink, Container, DividerLine, InformationContainer, Text } from './styled';

const getImageSource = picture =>
  `${http.defaults.baseURL}` +
  `/MasterUnitFile/Get?parentId=${picture.UnitId}&id=${picture.Id}&width=200&height=200&inline=true`;

const Details = ({ exportUnitQR, pictures, unit }) => (
  <Container>
    <InformationContainer>
      <Text>DESCRIPTION: {unit.Description}</Text>
      <Text>MODEL: {unit.ModelNumber}</Text>
      <Text>BUILT: {unit.ExportedFromSurveyProjects ? '' : unit.YearBuilt}</Text>
      <BarcodeContainer>
        <Text>Scan/QR code:</Text>
        <BarcodeLink onClick={exportUnitQR}>{unit.Barcode && unit.Barcode.Value}</BarcodeLink>
      </BarcodeContainer>
      <Text>LOCATION: {unit.LocationDescription}</Text>
      <DividerLine />
      <Text>VENDOR:</Text>
      <Text>{unit.VendorName}</Text>
      <Text>{unit.VendorEmail}</Text>
      <Text>{unit.VendorPhone}</Text>
      <Text>{unit.VendorNotes}</Text>
    </InformationContainer>
    <ImageSwiper getImageSource={getImageSource} noPicturesMessage="This unit has no photos." pictures={pictures} />
  </Container>
);

Details.propTypes = {
  exportUnitQR: PropTypes.func.isRequired,
  pictures: PropTypes.arrayOf(UnitPictureType).isRequired,
  unit: UnitType.isRequired,
};

export default Details;
