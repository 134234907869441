import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import theme from 'theme';
import { CheckBoxIcon } from '../Layout/Icons';

import * as S from './styled';

class ColumnVisibility extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.element.isRequired,
    handleRequestClose: PropTypes.func.isRequired,
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        showColumn: PropTypes.bool,
      }),
    ).isRequired,
    setSelectedLabel: PropTypes.func.isRequired,
  };

  onCheckboxChange = (title, isInputChecked) => {
    const selectedLabel = this.props.labels.map(label => {
      if (label.title === title) {
        return { ...label, showColumn: isInputChecked };
      }
      return label;
    });

    this.props.setSelectedLabel(selectedLabel);
    localStorage.setItem('reportPreviewTableConfigs', JSON.stringify(selectedLabel));
  };

  render() {
    const { anchorEl, open, handleRequestClose, labels } = this.props;

    return (
      <S.Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onRequestClose={handleRequestClose}
      >
        <S.Container>
          <S.Title>
            Show/Hide the following <br /> columns in the table:
          </S.Title>
          {labels.map(({ title, showColumn, hideable = true }) => (
            <Checkbox
              key={title}
              disabled={!hideable}
              checkedIcon={<CheckBoxIcon style={{ fill: theme.mainRed }} />}
              uncheckedIcon={!hideable ? <CheckBoxIcon /> : null}
              label={title}
              onCheck={(e, isInputChecked) => this.onCheckboxChange(title, isInputChecked)}
              iconStyle={{ fill: theme.primaryGrey }}
              checked={showColumn}
            />
          ))}
        </S.Container>
      </S.Popover>
    );
  }
}

export default ColumnVisibility;
