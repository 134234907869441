export const ERROR_DIALOG_OPENED = 'incheq/error/ERROR_DIALOG_OPENED';
const ERROR_DIALOG_CLOSED = 'incheq/error/ERROR_DIALOG_CLOSED';

const INITIAL_STATE = {
  errorDialogOpened: false,
  errorDialogTitle: null,
  errorMessage: '',
  buttonColor: '',
};

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ERROR_DIALOG_OPENED:
      return {
        ...state,
        errorDialogOpened: true,
        errorDialogTitle: action.payload.title,
        errorMessage: action.payload.message,
        buttonTextColor: action.payload.buttonTextColor,
        errorMessageTitle: action.payload.errorMessageTitle,
      };
    case ERROR_DIALOG_CLOSED:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}

export const openErrorDialog = (message = '', title = null, buttonTextColor = '', errorMessageTitle = '') => ({
  type: ERROR_DIALOG_OPENED,
  payload: { message, title, buttonTextColor, errorMessageTitle },
});

export const closeErrorDialog = () => ({ type: ERROR_DIALOG_CLOSED });
