import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { bindActionCreators } from 'redux';

import { actions as shortcutActions } from 'redux/shortcuts';

import http from '../../../../http';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ShortcutTestrunDialog from 'components/Dialogs/Shortcut/ShortcutTestrunDialog';
import DeleteShortcutDialog from 'components/Dialogs/Shortcut/DeleteShortcut';
import { InfoIcon } from 'components/Layout/Icons';
import ShortcutRules from 'components/Layout/ShortcutRules';
import TestrunButton from 'components/Buttons/TestrunButton';
import ShortcutTypeRadioGroup from 'components/Forms/RadioButtonGroup/ShortcutTypeRadioGroup';
import {
  createId,
  initialRule,
  generateInitialRules,
  generateUnitRules,
  generateLocationRules,
  generateItemRules,
  checkOptions,
  shortcutRules,
  countItems,
} from 'components/Layout/ShortcutRules/helpers';

import { ShortCutType, SiteType, UnitType, UnitItemType } from 'configs/propTypes';

import validate from './validator';

import * as S from './styled';

const defaultShortcutStatusData = {
  ChecklistCount: 0,
  CompletedCount: 0,
  InProgressCount: 0,
  RequireAttentionCount: 0,
  RequireAttentionSites: [],
  SiteCount: 0,
};

const SiteForm = ({ shortcutToPreview, error }) => (
  <S.FormContainer>
    <S.FieldBlock>
      <Field component={S.TextField} name="Name" floatingLabelText="Unique Shortcut Name" />
    </S.FieldBlock>
    <S.Icon
      tooltip="A shortcut is used to automatically schedule tasks and assign them to someone ."
      tooltipStyles={{ width: 120 }}
      tooltipPosition="bottom-left"
    >
      <InfoIcon />
    </S.Icon>
    {error.length > 0 && <S.ErrorContainer>{error}</S.ErrorContainer>}
    <S.RedInfo> Set up rules to create a shortcut useful for scheduling tasks for the resulting items(s)</S.RedInfo>
    <Field
      component={ShortcutTypeRadioGroup}
      name="Type"
      style={S.radioStyle}
      initialValue={shortcutToPreview.Type || 0}
    />
  </S.FormContainer>
);

class EditSelectedShortcut extends Component {
  static propTypes = {
    shortcutToPreview: ShortCutType.isRequired,
    listOfSites: PropTypes.oneOfType([PropTypes.string, SiteType]).isRequired,
    listOfUnits: PropTypes.oneOfType([PropTypes.string, UnitType]).isRequired,
    listOfItems: PropTypes.arrayOf(UnitItemType).isRequired,
    error: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    isEditShortcut: PropTypes.bool.isRequired,
    isActivator: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      createShortcutRequest: PropTypes.func.isRequired,
      editShortcutRequest: PropTypes.func.isRequired,
      saveShortcutOptionsRequest: PropTypes.func.isRequired,
      sendLocationOptionsRequest: PropTypes.func.isRequired,
      sendUnitOptionsRequest: PropTypes.func.isRequired,
      sendItemOptionsRequest: PropTypes.func.isRequired,
      deleteShortcutRequest: PropTypes.func.isRequired,
      getListScheduleRequest: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    locationRules: generateInitialRules({ rules: this.props.shortcutToPreview.LocationRules, flag: 'location' }),
    unitRules: generateInitialRules({ rules: this.props.shortcutToPreview.UnitRules, flag: 'unit' }),
    itemRules: generateInitialRules({ rules: this.props.shortcutToPreview.ItemRules, flag: 'item' }),
    shortcutName: '',
    previewOpen: false,
    isErrorShown: false,
    isDeleteShortcutDialogOpened: false,
    shortcutStatusData: defaultShortcutStatusData,
  };

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onChangeIgnoreRules = flag => value => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], isRulesIgnored: value },
    });
  };

  getPreviewResults = values => {
    const { actions } = this.props;
    const { locationRules, unitRules, itemRules } = this.state;

    const itemValues = generateItemRules(values, itemRules.rules);
    const locationValues = generateLocationRules(values, locationRules.rules);
    const unitValues = generateUnitRules(values, unitRules.rules);

    const valuesToSend = {
      ...itemValues,
      ...locationValues,
      ...unitValues,
    };

    if (
      !checkOptions(valuesToSend.LocationRules) ||
      !checkOptions(valuesToSend.UnitRules) ||
      !checkOptions(valuesToSend.ItemRules)
    ) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false, previewOpen: true, shortcutName: values.Name });
      actions.sendLocationOptionsRequest(valuesToSend);
      actions.sendUnitOptionsRequest(valuesToSend);
      actions.sendItemOptionsRequest(valuesToSend);
    }
  };

  closePreview = () => this.setState({ previewOpen: false });

  openSiteSelectionDialog = () => {
    this.props.onNext();
  };

  onSubmitForm = values => {
    const { actions, onNext, shortcutToPreview, isActivator } = this.props;

    const itemValues = generateItemRules(values, this.state.itemRules.rules);
    const locationValues = generateLocationRules(values, this.state.locationRules.rules);
    const unitValues = generateUnitRules(values, this.state.unitRules.rules);

    const rulesToSend = {
      ...itemValues,
      ...locationValues,
      ...unitValues,
    };

    const valuestToSend = {
      Name: values.Name,
      Type: values.Type,
      onNext,
      ...rulesToSend,
    };

    if (shortcutToPreview?.Id && !isActivator) {
      actions.getListScheduleRequest(shortcutToPreview.Id);
      actions.editShortcutRequest({ ...valuestToSend, id: shortcutToPreview.Id });
      actions.sendItemOptionsRequest({
        ...rulesToSend,
      });
      actions.sendLocationOptionsRequest(rulesToSend);
    } else if (
      !checkOptions(rulesToSend.LocationRules) ||
      !checkOptions(rulesToSend.UnitRules) ||
      !checkOptions(rulesToSend.ItemRules)
    ) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      actions.sendLocationOptionsRequest(rulesToSend);
      actions.sendUnitOptionsRequest(rulesToSend);
      actions.sendItemOptionsRequest({
        ...rulesToSend,
        onNext: () => actions.createShortcutRequest(valuestToSend),
      });
    }
  };

  getPreviewResultsData = () => {
    const { listOfSites, listOfUnits, listOfItems } = this.props;

    return [
      {
        data: listOfSites,
        isEmpty: listOfSites === 'empty' || listOfSites.length === 0,
        header: 'Location Result',
        foundItems: `Sites Found: ${listOfSites.length}`,
      },
      {
        data: listOfUnits,
        isEmpty: listOfUnits === 'empty' || listOfUnits.length === 0,
        header: 'Unit Result',
        foundItems: `Units Found: ${listOfUnits.length}`,
      },
      {
        data: listOfItems.map(({ Items }) => Items).flat(),
        isEmpty: countItems(listOfItems) === 0 || listOfItems.length === 0,
        header: 'Item Result',
        foundItems: `Items Found: ${countItems(listOfItems)}`,
      },
    ];
  };

  handleDeleteShortcut = () => {
    const { shortcutToPreview, actions, onExit } = this.props;

    actions.deleteShortcutRequest({
      shortcutId: shortcutToPreview.Id,
      onNext: () => {
        this.closeDeleteShortcutDialog();
        onExit();
      },
    });
  };

  openDeleteShortcutDialog = async () => {
    const { data } = await http.post('Shortcut/GetShortcutStatus', { shortcutId: this.props.shortcutToPreview.Id });

    this.setState({ shortcutStatusData: data.entity, isDeleteShortcutDialogOpened: true });
  };

  closeDeleteShortcutDialog = () => {
    this.setState({ isDeleteShortcutDialogOpened: false });
  };

  render() {
    const { shortcutToPreview, error, isEditShortcut, isActivator } = this.props;
    const { isErrorShown, previewOpen, shortcutName, isDeleteShortcutDialogOpened, shortcutStatusData } = this.state;

    const isEditMode = shortcutToPreview.Id;

    return (
      <S.Container>
        <Form
          onSubmit={this.onSubmitForm}
          initialValues={shortcutToPreview}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm values={values} shortcutToPreview={shortcutToPreview} error={error} />
              {shortcutRules.map(rule => (
                <ShortcutRules
                  key={rule}
                  title={`${rule} Rules`}
                  isInitialValues={!isEditMode}
                  isEditMode={!!isEditMode && isEditShortcut}
                  rules={this.state[`${rule}Rules`]}
                  addOption={this.addOption}
                  removeOption={this.removeOption}
                  onChangeMatch={this.onChangeMatch}
                  onChangeIgnoreRules={this.onChangeIgnoreRules}
                />
              ))}
              <TestrunButton isCentered onClick={() => this.getPreviewResults(values)} />
              {isErrorShown && (
                <S.ErrorContainer>
                  Please add at least one rule or select &quot;Ignore rules&quot; to disable rules
                </S.ErrorContainer>
              )}
              <ActionsContainer>
                {isEditMode && (
                  <ActionButton type="button" label="Delete Shortcut" onClick={this.openDeleteShortcutDialog} />
                )}
                <ActionButton
                  type="submit"
                  label={`${isEditMode && !isActivator ? 'Update' : 'Save'}/Next (Scheduling)`}
                  isNext
                />
              </ActionsContainer>
            </form>
          )}
        />
        <ShortcutTestrunDialog
          open={previewOpen}
          handleClose={this.closePreview}
          shortcutToPreview={shortcutToPreview}
          shortcutName={shortcutName}
          results={this.getPreviewResultsData() || []}
        />
        <DeleteShortcutDialog
          open={isDeleteShortcutDialogOpened}
          data={shortcutStatusData}
          handleClose={this.closeDeleteShortcutDialog}
          handleDelete={this.handleDeleteShortcut}
        />
      </S.Container>
    );
  }
}

SiteForm.propTypes = {
  shortcutToPreview: ShortCutType.isRequired,
  error: PropTypes.string.isRequired,
};

const mapStateToProps = ({ shortcuts }) => ({
  shortcutToPreview: shortcuts.shortcutToPreview,
  error: shortcuts.error,
  listOfItems: shortcuts.listOfItems,
  listOfUnits: shortcuts.listOfUnits,
  listOfSites: shortcuts.listOfSites,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSelectedShortcut);
