import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import CustomNumberInputField from 'components/Forms/CustomNumberInputField';
import withInputModal from 'components/Dialogs/withInputModal';
import validate from './validator';
import * as S from './styled';
import { TextareaField } from 'components/Layout/Textarea';
import { inputFields } from './constants';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';
import { actions as settingsActions } from 'redux/settings';

const countValues = [
  { name: 'sitesCount', label: <S.Label>How many Sites (Locations/Plants) will you need?</S.Label> },
  { name: 'itemsCount', label: <S.Label>How many Items to service or check per Site (rough)?</S.Label> },
  { name: 'sensorsCount', label: <S.Label>How many IoT Sensors do you think you need per Site?</S.Label> },
];

class UpgradeInfoForm extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      requestUpgradeInfo: PropTypes.func.isRequired,
    }).isRequired,
    industryList: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string,
        Name: PropTypes.string,
      }),
    ).isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    initialValues: {},
    values: {},
  };

  render() {
    const { form, industryList } = this.props;

    return (
      <>
        <S.InfoContainer>
          <S.SeparatorContainter>
            Incheq usage is based on the maximum amount of Sites, Items and / or Sensors you would like to use or track.
            You can add as many tasks and people as you would like.
          </S.SeparatorContainter>
          You can use Incheq as an alert platform and only use the <b>IOT features</b> by installing sensors and
          accessing the dashboard.
        </S.InfoContainer>

        <S.InputContainer>
          <S.ValuesContainer>
            {countValues.map(({ name, label }) => (
              <CustomNumberInputField key={name} name={name} change={form.change} labelComponent={label} value="0" />
            ))}
          </S.ValuesContainer>
          {!!industryList.length && (
            <S.SelectContainer>
              <Field name="industryId">
                {({ input, ...rest }) => (
                  <SelectFieldAdapter input={input} {...rest} floatingLabelText="Select your (closest) Industry Type">
                    {industryList.map(({ Id, Name }) => (
                      <SelectItem key={Id} value={Id} primaryText={Name} />
                    ))}
                  </SelectFieldAdapter>
                )}
              </Field>
            </S.SelectContainer>
          )}
          {inputFields.map(({ name, placeholder }) => (
            <Field
              key={name}
              component={TextareaField}
              name={name}
              rows={name === 'requestorQuestionsOrComments' ? 4 : 1}
              floatingLabelText={placeholder}
            />
          ))}
        </S.InputContainer>
      </>
    );
  }
}

UpgradeInfoForm.propTypes = {
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
  }).isRequired,
};

const UpgradeInfoDialogWithInput = withInputModal(UpgradeInfoForm);

const UpgradeInfoDialog = props => (
  <UpgradeInfoDialogWithInput validate={validate} initialValues={{ ...props.values }} {...props} />
);

UpgradeInfoDialog.propTypes = {
  values: PropTypes.shape({
    requestorEmail: PropTypes.string,
    requestorName: PropTypes.string,
    requestorCompany: PropTypes.string,
    industryId: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ auth }) => ({
  values: {
    requestorEmail: auth.user.userEmail,
    requestorName: auth.user.userName,
    requestorCompany: auth.user.tenantName,
    industryId: auth.user.industryId,
  },
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      requestUpgradeInfo: settingsActions.upgradeInfoRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeInfoDialog);
