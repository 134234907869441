import styled from 'styled-components';

export const AttentionIndicator = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  width: 6px;
  min-width: 4px;
  height: 100%;
  background-color: ${({ theme }) => theme.primaryRed};
  border-radius: 0 !important;
`;

export const InactiveUserIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: ${({ isInactive }) => (isInactive ? 'block' : 'none')};
  width: 10px;
  height: 100%;
  background-color: ${({ theme }) => theme.primaryRed};
`;

export const SchedulerIndicator = styled.div`
  position: absolute;
  right: 2px;
  top: 0;
  display: block;
  width: 10px;
  min-width: 4px;
  height: 100%;
  background-color: ${({ color }) => color};
  border-radius: 0 !important;
`;

export const VerificationIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  width: 6px;
  min-width: 4px;
  height: 100%;
  background-color: ${({ theme }) => theme.primaryGreen};
  border-radius: 0 !important;
`;
