import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';

export const Container = styled.div`
  padding: 30px 24px 12px;
`;

export const RadioGroup = styled.div`
  padding: 20px;
`;

export const DescriptionText = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.primaryGrey};
`;

export const DescriptionTextWithMarging = styled(DescriptionText)`
  margin-bottom: 16px;
`;

export const Spacer = styled.div`
  height: 20px;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled(ButtonIcon)`
  width: 24px !important;
  padding: 0 !important;

  svg {
    color: ${({ theme }) => theme.primarySites} !important;
  }

  &:hover {
    transform: translateZ(0) scale(1);
  }
`;
