import styled, { css } from 'styled-components';

export const fixedTableHeadStyles = css`
  overflow-x: auto;

  & table {
    border-top: none;
  }

  & thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    border-top: none;
    border-bottom: none;
  }

  & table thead th {
    box-shadow: inset 0 1px 0 #9c9c9c, inset 0 -1px 0 #9c9c9c;
  }

  thead > tr {
    &:first-child {
      border-bottom: none;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const TableHeaderCell = styled.th`
  font-weight: normal;
  background-color: ${({ theme }) => theme.lightGrey};
  text-align: left;

  & button {
    padding: 0 !important;
  }
`;

export const TableRow = styled.tr`
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  background-color: ${({ theme }) => theme.primaryWhite};
`;

export const TableCell = styled.td`
  &:not(:first-child) {
    padding-left: 10px;
  }

  & button {
    padding: 0 !important;
  }
`;

export const Value = styled.span`
  vertical-align: super;
`;
