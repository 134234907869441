import styled, { css } from 'styled-components';
import { Flex } from 'grid-styled';
import Checkbox from 'material-ui/Checkbox';

import { ListItemContainer } from 'components/Layout/Containers';

export const Container = styled(ListItemContainer).attrs({
  secondaryTextLines: 2,
})`
  border-bottom: ${({ islast, theme }) => (islast === 'true' ? 'none' : `1px solid ${theme.primaryGrey} !important`)};
  background-color: ${({ theme }) => theme.primaryWhite} !important;

  & > div > div {
    padding: 0 !important;

    & > div:first-child {
      order: 0;
    }
  }
`;

export const CheckBox = styled(Checkbox)`
  & > div > div {
    margin: 0 !important;

    & > div > svg {
      fill: ${({ theme, checked }) => (checked ? theme.mainRed : theme.textGray)} !important;
      fill: ${({ fill }) => fill} !important;
      fill: ${({ disabled }) => disabled && '#DFDFDF'} !important;
      color: ${({ disabled }) => disabled && '#DFDFDF'} !important;
    }
  }
`;

export const ItemText = styled(Flex).attrs({
  pl: [1, 2],
})`
  margin: 0px !important;
  padding: 5px;
  height: auto !important;
`;

export const ListVersionBlock = styled(Flex)`
  width: 25px;
  height: 25px;
  margin: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
`;

export const CheckboxBlock = styled(Flex)`
  width: 25px;
  height: 25px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Line = styled(Flex)`
  width: 100%;

  ${({ isDemo }) =>
    isDemo &&
    css`
      border-left: solid;
      border-left-color: ${({ theme }) => theme.mainDarkBlue};
      border-left-width: 6px;
    `}
`;

export const TextBlock = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  padding: 16px;
  min-width: 0;
  word-wrap: break-word;
  word-break: break-word;
`;
