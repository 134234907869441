import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { exportUnit } from 'http/queue';
import { actions as queueActions } from 'redux/queue';
import { CancelButton, DialogContainer, PrimaryText, SecondaryText, SubmitButton } from './styled';

const DIALOG_CONTENT = {
  commit: {
    primaryText: 'Are you sure you want to commit the unit?',
    secondaryText: 'Unit information will be moved out of the Queue into the Archive. This cannot be undone.',
  },
  export: {
    primaryText: 'Are you sure you want to download Unit data?',
    secondaryText: null,
  },
};

class ConfirmationCommitDialog extends Component {
  static propTypes = {
    mode: PropTypes.oneOf(['commit', 'export']).isRequired,
    onRequestClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      rangeEnd: PropTypes.string,
      rangeStart: PropTypes.string,
      selectedUnitId: PropTypes.string.isRequired,
      isRequireAttentionTasks: PropTypes.bool.isRequired,
      isRequiredAttentionTasksParts: PropTypes.bool.isRequired,
    }).isRequired,
    requestCommitUnit: PropTypes.func.isRequired,
  };

  onSubmit = () => {
    const {
      mode,
      params: { rangeEnd, rangeStart, selectedUnitId: unitId, isRequireAttentionTasks, isRequiredAttentionTasksParts },
    } = this.props;

    if (mode === 'commit') {
      this.props.requestCommitUnit({ rangeEnd, rangeStart, unitId });
    } else {
      exportUnit({ rangeEnd, rangeStart, unitId, isRequireAttentionTasks, isRequiredAttentionTasksParts });
    }

    this.props.onRequestClose();
  };

  render() {
    const { mode, onRequestClose, opened } = this.props;

    return (
      <DialogContainer
        actions={[
          <CancelButton onClick={onRequestClose} />,
          <SubmitButton label={mode === 'commit' ? 'Commit' : 'Download'} onClick={this.onSubmit} />,
        ]}
        onRequestClose={onRequestClose}
        open={opened}
        title={mode === 'commit' ? 'Commit Unit' : 'Export CSV'}
      >
        <PrimaryText>{DIALOG_CONTENT[mode].primaryText}</PrimaryText>
        {DIALOG_CONTENT[mode].secondaryText && <SecondaryText>{DIALOG_CONTENT[mode].secondaryText}</SecondaryText>}
      </DialogContainer>
    );
  }
}

export default connect(null, { requestCommitUnit: queueActions.commitUnitRequest })(ConfirmationCommitDialog);
