import styled from 'styled-components';
import { FileUploadIcon, CheckIcon } from 'components/Layout/Icons';
import CircularProgress from 'material-ui/CircularProgress';

export const Container = styled.div`
  flex: 1;
  width: 100%;
`;

export const UploadContainer = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px dashed grey;
  padding: 10px 20px;
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  height: 62px;
  width: 316px;
  cursor: pointer;
`;

export const FileUpload = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileUploadText = styled.div``;

export const Header = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PartsFileText = styled.div`
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  color: black;
`;

export const List = styled.div`
  padding: 0 20px;
  height: 222px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  align-items: center;
  height: 36px;
`;

export const Loader = styled.div`
  width: 20px;
`;

export const InfoText = styled.div`
  flex: 1;
`;

export const CheckContainer = styled.div`
  width: 20px;
`;

export const CheckCircle = styled.div`
  background-color: ${({ isSuccess, theme }) => (isSuccess ? theme.loginButtonColor : theme.primaryRed)};
  width: 16px;
  height: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Count = styled.div`
  width: 50px;
  text-align: center;
`;

export const UploadIcon = styled(FileUploadIcon)`
  color: ${({ theme }) => theme.primaryRed} !important;
  margin-left: 12px;
`;

export const ResultCheckIcon = styled(CheckIcon).attrs({
  style: {
    width: 12,
    height: 12,
  },
})`
  color: white !important;
`;

export const Spinner = styled(CircularProgress)`
  margin-right: 8px;
`;
