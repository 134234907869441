import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const Information = styled(Flex).attrs({
  column: true,
  flex: 'auto',
  justify: 'space-between',
  pr: '100px',
})``;

export const Text = styled(Box).attrs({
  flex: 'auto',
  pb: 1,
})`
  min-width: 0;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;
