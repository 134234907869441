import styled from 'styled-components';

export const DialogContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const InfoText = styled.span`
  white-space: pre;
  ${({ theme, notAvailable }) =>
    notAvailable && {
      color: theme.primaryRed,
    }}
`;
