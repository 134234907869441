const initialState = {
  isLoading: false,
  error: '',
  details: {},
  pdfs: [],
  pictures: [],
  tasks: [],
};

export const fetchItemDetailsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const fetchItemDetailsSuccess = (state, { payload }) => ({
  ...state,
  details: payload.details,
  pdfs: payload.pdfs,
  pictures: payload.pictures,
  tasks: payload.tasks,
  isLoading: false,
});

export const fetchItemDetailsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const fetchItemTasksRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const fetchItemTasksSuccess = (state, { payload }) => ({
  ...state,
  tasks: payload,
  error: '',
  isLoading: false,
});

export const fetchItemTasksFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePdfRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePdfSuccess = (state, { payload }) => ({
  ...state,
  pdfs: payload.pdfs,
  isLoading: false,
});

export const deletePdfFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const uploadPdfRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const uploadPdfSuccess = (state, { payload }) => ({
  ...state,
  pdfs: payload.pdfs,
  isLoading: false,
});

export const uploadPdfFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePictureRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePictureSuccess = (state, { payload }) => ({
  ...state,
  pictures: payload.pictures,
  isLoading: false,
});

export const deletePictureFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const uploadPictureRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const uploadPictureSuccess = (state, { payload }) => ({
  ...state,
  pictures: payload.pictures,
  isLoading: false,
});

export const uploadPictureFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteTaskRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteTaskSuccess = (state, { payload }) => ({
  ...state,
  tasks: payload.tasks,
  isLoading: false,
});

export const deleteTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteItemSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteItemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editItemSuccess = (state, { payload }) => ({
  ...state,
  details: payload.details,
  isLoading: false,
});

export const editItemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editTaskRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editTaskSuccess = (state, { payload }) => ({
  ...state,
  tasks: payload.tasks,
  isLoading: false,
});

export const editTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addTaskRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addTaskSuccess = (state, { payload }) => ({
  ...state,
  tasks: payload.tasks,
  isLoading: false,
});

export const addTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getDefaultBarcodeRequest = state => state;

export const getDefaultBarcodeSuccess = (state, { payload }) => ({
  ...state,
  itemDefaultBarcode: payload.data?.entity,
  error: '',
});

export const getDefaultBarcodeFailure = (state, { payload }) => ({
  ...state,
  error: payload,
});

export const dublicateTaskRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const dublicateTaskSuccess = (state, { payload }) => ({
  ...state,
  tasks: [...state.tasks, payload.data.entity.Dto],
  isLoading: false,
});

export const dublicateTaskFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
