import { actions as unitActions } from 'redux/unit';
import { actions as unitsActions } from 'redux/units';

const mapStateToProps = ({
  unit: { unitData },
  auth: {
    selectedSite,
    user: { sitesAvailableToUserFullInfo },
  },
  listTemplates: { listTaskStatus, isLoading },
  units: { assetCategoriesList, assetTypesList },
}) => ({
  unitID: unitData.Id,
  selectedSite,
  sitesAvailableToUserFullInfo,
  listTaskStatus,
  isLoading,
  assetCategoriesList,
  assetTypesList,
});

const mapDispatchToProps = {
  requestUnitDefaultBarcode: unitActions.getDefaultBarcodeRequest,
  ...unitsActions,
};

export { mapStateToProps, mapDispatchToProps };
