import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as CS from '../../styled';

import InformationForm from './InformationForm';
import SignUpForm from './SignUpForm';
import * as S from './styled';

class LoginPage extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    authMode: 'signup',
    upgradeInfoOpened: false,
    successDialogOpen: false,
  };

  goToLogin = () => {
    this.props.history.push('/login');
  };

  chooseSignInTab = () => this.setState({ authMode: 'info' });
  chooseSignUpTab = () => this.setState({ authMode: 'signup' });

  render() {
    const {
      location: { search },
    } = this.props;
    const { authMode } = this.state;

    return (
      <CS.Content>
        <CS.Logo />
        <S.SignInBookmark onClick={this.chooseSignInTab}>
          <S.SignInText>
            Information <S.IconInfo />
          </S.SignInText>
        </S.SignInBookmark>
        <InformationForm authMode={authMode} />
        <SignUpForm
          goToLogin={this.goToLogin}
          authMode={authMode}
          token={queryString.parse(search)?.token}
          userName={queryString.parse(search)?.userName}
          tenantName={queryString.parse(search)?.tenantName}
        />
        <S.LoginLinkContainer>
          <S.LoginLink to="/login">
            <S.IconArrowBack />
            Go To Login
          </S.LoginLink>
        </S.LoginLinkContainer>
        <S.SignUpBookmark onClick={this.chooseSignUpTab}>
          {authMode === 'info' && <S.SignUpText>Return to signup</S.SignUpText>}
        </S.SignUpBookmark>
      </CS.Content>
    );
  }
}

export default LoginPage;
