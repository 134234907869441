import styled from 'styled-components';

import { CenteredXYGreyContainer } from 'components/Layout/Containers';
import { ProgressCircular } from 'components/Layout/Progresses';

export const Container = styled(CenteredXYGreyContainer)`
  height: 100%;
`;

export const Progress = styled(ProgressCircular).attrs({
  size: 100,
})``;
