import styled from 'styled-components';

import { WarningIcon } from 'components/Layout/Icons';

export const ItemsList = styled.div``;

export const IconWarning = styled(WarningIcon)`
  min-width: 24px;
  transform: scale(0.7);
  margin: 0 10px 0 0 !important;
  position: relative !important;
`;
