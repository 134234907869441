import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const RadioButtonLabel = ({ label, additionalInfo }) => (
  <S.LabelContainer>
    <S.Label>{label}</S.Label>
    <S.Label>{additionalInfo}</S.Label>
  </S.LabelContainer>
);

RadioButtonLabel.propTypes = {
  label: PropTypes.string.isRequired,
  additionalInfo: PropTypes.string.isRequired,
};

export default RadioButtonLabel;
