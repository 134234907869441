import http from '../index';

export const uploadPhoto = (payload, params) =>
  http.post(`${payload.isMaster ? 'Master' : ''}Settings/UploadLogo`, params);

export const getLogo = payload => http.post(`${payload === 'master' ? 'Master' : ''}Settings/GetLogo`);

export const deleteLogo = payload => http.post(`${payload ? 'Master' : ''}Settings/DeleteLogo`);

export const uploadLogoEmail = (payload, params) =>
  http.post(`${payload.isMaster ? 'Master' : ''}Settings/UploadLogoEmail`, params);

export const getLogoEmail = payload => http.post(`${payload === 'master' ? 'Master' : ''}Settings/GetLogoEmail`);

export const deleteLogoEmail = payload => http.post(`${payload ? 'Master' : ''}Settings/DeleteLogoEmail`);

export const getMasterSettings = payload =>
  http.post(`${payload ? 'Master' : ''}Settings/Get${payload ? 'Master' : ''}Settings`);

export const setSettings = (isMaster, valuesWithSpacing) =>
  http.post(`${isMaster ? 'Master' : ''}Settings/Set${isMaster ? 'Master' : ''}Settings`, {
    [isMaster ? 'MasterSettingDto' : 'settingDto']: valuesWithSpacing,
  });

export const setSettingsLinks = (isMaster, params) => http.post(`${isMaster ? 'Master' : ''}SettingsLink/Put`, params);

export const getSettingsLinksList = isMaster => http.post(`${isMaster ? 'Master' : ''}SettingsLink/List`);

export const fetchMasterSettingsLinksForAdmin = () => http.post('MasterSettingsLink/ListSysAdmin');

export const fetchMasterSettingsForAdmin = () => http.post('MasterSettings/GetMasterSettingsSysAdmin');

export const fetchDemoTasksCount = () => http.post('Settings/GetCountDemoTasks');

export const removeDemoTasks = () => http.post('AssignedTask/DeleteAllDemoTasks');

export const getUpgradeInfo = upgradeTenantRequestDto =>
  http.post('UpgradeTenantRequest/CreateUpgradeTenantRequest', { upgradeTenantRequestDto });

export const getSignUpNotification = () => http.post('MasterSettingsLink/GetSignupNotification');

export const putSignUpNotification = dto => http.post('MasterSettingsLink/SetSignupNotification', { dto });

export const getMasterSystemLimits = () => http.post('MasterSystemLimits/GetMasterSystemLimits');

export const putSystemLimits = masterSystemLimitsDto =>
  http.post('MasterSystemLimits/SetMasterSystemLimits', { masterSystemLimitsDto });
