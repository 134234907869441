import { createSelector } from 'reselect';

const getDefaultWebAddress = list => list.DefaultWebAddress;
const getDefaultWebAddressFromReset = (list, masterSettings) => masterSettings.DefaultWebAddress;

export const defaultWebAddressSelector = createSelector(
  [getDefaultWebAddress, getDefaultWebAddressFromReset],
  (address, addressReset) => addressReset || address,
);

const getDefaultQRCodes = list => list.UseGroupKeyForQRCodes;
const getDefaultQRCodesFromReset = (list, masterSettings) => masterSettings.UseGroupKeyForQRCodes;

export const defaultQrCodesSelector = createSelector(
  [getDefaultQRCodes, getDefaultQRCodesFromReset],
  (qrCode, qrCodeReset) => qrCodeReset || qrCode,
);
