import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import Divider from 'material-ui/Divider';

export const BarcodeContainer = styled(Flex).attrs({
  wrap: true,
})``;

export const BarcodeLink = styled.div`
  color: #0000ee;
  cursor: pointer;
  margin-left: 5px;
  min-width: 0;
  text-decoration-line: underline;
  word-break: break-word;

  :active {
    color: #ff0000;
  }

  :focus {
    outline: 1px dotted #000000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  :visited {
    color: #551a8b;
  }
`;

export const Container = styled(Flex).attrs({
  flex: 'none',
})``;

export const DividerLine = styled(Divider)`
  margin: 10px 0 !important;
`;

export const InformationContainer = styled(Flex).attrs({
  column: true,
  flex: 'auto',
  p: 2,
})``;

export const Text = styled(Box).attrs({
  is: 'p',
  m: 0,
})``;
