import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const RadioGroup = ({ input, meta, style }) => (
  <RadioButtonGroup
    name="taskFrequency"
    defaultSelected={input.value.length === 0 ? 1 : input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    <RadioButton
      value={1}
      label="Every period"
      iconStyle={{ fill: input.value.length === 0 || input.value === 1 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={2}
      iconStyle={{ fill: input.value === 2 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={3}
      iconStyle={{ fill: input.value === 3 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={4}
      iconStyle={{ fill: input.value === 4 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={5}
      iconStyle={{ fill: input.value === 5 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={6}
      iconStyle={{ fill: input.value === 6 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
    <RadioButton
      value={7}
      label="Skip 6 periods"
      iconStyle={{ fill: input.value === 7 ? theme.primaryScheduler : theme.darkGrey }}
      style={{ padding: '5px 0px' }}
    />
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
