import { call, put, takeEvery } from 'redux-saga/effects';

import { formatServerError } from 'helpers';
import {
  deleteLastVersion,
  editNotes,
  getExchangeList,
  getItemFilesList,
  getItemTask,
  getItemTasksList,
  getListExchangeVersions,
  getListToTenant,
  getSitesByTenant,
  getUnitApprove,
  getUnitFilesList,
  getUnitItem,
  getUnitItemsList,
  getUnitVersion,
} from 'http/exchange';

import { actions } from './index';

let filterList;
let listVersionsId;

function* fetchExchangeList({ payload }) {
  let searchFilter;

  if (payload.status) {
    filterList = payload.status === 'approved' ? { approved: true } : { approved: false };
  }
  if (payload.Search) {
    searchFilter = payload.MasterUnitFilter
      ? { [payload.MasterUnitFilter]: payload.Search }
      : { unitName: payload.Search };
  }
  try {
    const { data } = yield call(getExchangeList, filterList, searchFilter);
    yield put(actions.exchangeListSuccess(data.root));
  } catch (error) {
    yield put(actions.exchangeListFailure(error));
  }
}

function* fetchListExchangeVersions({ payload }) {
  listVersionsId = payload;
  try {
    const { data } = yield call(getListExchangeVersions, payload);
    yield put(actions.listExchangeVersionsSuccess(data.root));
  } catch (error) {
    yield put(actions.listExchangeVersionsFailure(error));
  }
}

function* fetchUnitApprove({ payload }) {
  try {
    yield call(getUnitApprove, payload);
    yield put(actions.unitApproveSuccess());
    yield put(actions.exchangeListRequest({ status: 'new' }));
  } catch (error) {
    yield put(actions.unitApproveFailure(error));
    yield put(actions.exchangeListFailure(error));
  }
}

function* fetchSitesByTenant({ payload }) {
  try {
    const { data } = yield call(getSitesByTenant, payload);
    yield put(actions.sitesByTenantsListSuccess(data.root));
  } catch (error) {
    yield put(actions.sitesByTenantsListFailure(error));
  }
}

function* fetchListToTenant({ payload }) {
  try {
    yield call(getListToTenant, payload);
    yield put(actions.importListToTenantSuccess());
    yield put(actions.exchangeListRequest({ status: 'approved' }));
  } catch (error) {
    yield put(
      actions.importListToTenantFailure(formatServerError(error, 'Error occured while copying units to tenant')),
    );
    yield put(actions.exchangeListFailure(error));
  }
}

function* fetchUnitVersion({ payload }) {
  try {
    const { data } = yield call(getUnitVersion, payload);
    yield put(actions.getUnitVersionSuccess(data.entity));
  } catch (error) {
    yield put(actions.getUnitVersionFailure(error));
  }
}

function* fetchUnitFilesList({ payload }) {
  try {
    const { data } = yield call(getUnitFilesList, payload);
    yield put(actions.getUnitFilesListSuccess(data.root));
  } catch (error) {
    yield put(actions.getUnitFilesListFailure(error));
  }
}

function* fetchUnitItemsList({ payload }) {
  try {
    const { data } = yield call(getUnitItemsList, payload);
    yield put(actions.getItemsListSuccess(data.root));
  } catch (error) {
    yield put(actions.getItemsListFailure(error));
  }
}

function* fetchUnitItem({ payload }) {
  try {
    const { data } = yield call(getUnitItem, payload);
    yield put(actions.getItemSuccess(data));
  } catch (error) {
    yield put(actions.getItemFailure(error));
  }
}

function* fetchItemFilesList({ payload }) {
  try {
    const { data } = yield call(getItemFilesList, payload);
    yield put(actions.getItemFilesListSuccess(data.root));
  } catch (error) {
    yield put(actions.getItemFilesListFailure(error));
  }
}

function* fetchItemTasksList({ payload }) {
  try {
    const { data } = yield call(getItemTasksList, payload);
    yield put(actions.getItemTasksListSuccess(data.root));
  } catch (error) {
    yield put(actions.getItemTasksListFailure(error));
  }
}

function* fetchItemTask({ payload }) {
  try {
    const { data } = yield call(getItemTask, payload);
    yield put(actions.getItemTaskSuccess(data));
  } catch (error) {
    yield put(actions.getItemTaskFailure(error));
  }
}

function* fetchDeleteLastVersion({ payload }) {
  try {
    yield call(deleteLastVersion, payload);
    yield put(actions.deleteLastVersionSuccess());
    yield put(actions.listExchangeVersionsRequest(listVersionsId));
  } catch (error) {
    yield put(actions.deleteLastVersionFailure(error));
  }
}

function* fetchEditNotes({ payload }) {
  try {
    const { data } = yield call(editNotes, payload);
    yield put(actions.editNotesSuccess(data.entity));
    yield put(actions.listExchangeVersionsRequest(listVersionsId));
  } catch (error) {
    yield put(actions.editNotesFailure(error));
  }
}

const exchangeSagas = [
  takeEvery(actions.exchangeListRequest, fetchExchangeList),
  takeEvery(actions.listExchangeVersionsRequest, fetchListExchangeVersions),
  takeEvery(actions.unitApproveRequest, fetchUnitApprove),
  takeEvery(actions.sitesByTenantsListRequest, fetchSitesByTenant),
  takeEvery(actions.importListToTenantRequest, fetchListToTenant),
  takeEvery(actions.getUnitVersionRequest, fetchUnitVersion),
  takeEvery(actions.getUnitFilesListRequest, fetchUnitFilesList),
  takeEvery(actions.getItemsListRequest, fetchUnitItemsList),
  takeEvery(actions.getItemRequest, fetchUnitItem),
  takeEvery(actions.getItemFilesListRequest, fetchItemFilesList),
  takeEvery(actions.getItemTasksListRequest, fetchItemTasksList),
  takeEvery(actions.getItemTaskRequest, fetchItemTask),
  takeEvery(actions.deleteLastVersionRequest, fetchDeleteLastVersion),
  takeEvery(actions.editNotesRequest, fetchEditNotes),
];

export default exchangeSagas;
