import { createSelector } from 'reselect';

export const getLoadingState = createSelector(
  state => state.auth.loading,
  state => state.documentItem.loading,
  state => state.documents.loading,
  state => state.exchange.loading,
  state => state.exportPdf.loading,
  state => state.exportPdfReport.loading,
  state => state.gateways.isLoading,
  state => state.globalSettings.isLoading,
  state => state.health.isLoading,
  state => state.item.isLoading,
  state => state.people.loading,
  state => state.queue.loading,
  state => state.schedule.loading,
  state => state.schedule.loadingCurrentUnit,
  state => state.schedule.loadingList,
  state => state.schedule.loadingItems,
  state => state.schedule.loadingDetails,
  state => state.schedule.loadingTemplates,
  state => state.sensors.isLoading,
  state => state.sites.loading,
  state => state.tasks.loading,
  state => state.tasks.loadingUser,
  state => state.tenants.loading,
  state => state.unit.loading,
  state => state.units.loading,
  state => state.shortcuts.isLoading,
  (
    auth,
    documentItem,
    documents,
    exchange,
    exportPdf,
    exportPdfReport,
    gateways,
    globalSettings,
    health,
    item,
    people,
    queue,
    schedule,
    scheduleCurrentUnit,
    scheduleList,
    scheduleItems,
    scheduleDetails,
    scheduleTemplates,
    sensors,
    sites,
    tasks,
    tasksUser,
    tenants,
    unit,
    units,
    shortcuts,
  ) =>
    auth ||
    documentItem ||
    documents ||
    exchange ||
    exportPdf ||
    exportPdfReport ||
    gateways ||
    globalSettings ||
    health ||
    item ||
    people ||
    queue ||
    schedule ||
    scheduleCurrentUnit ||
    scheduleList ||
    scheduleItems ||
    scheduleDetails ||
    scheduleTemplates ||
    sensors ||
    sites ||
    tasks ||
    tasksUser ||
    tenants ||
    unit ||
    units ||
    shortcuts,
);

export const getLongLoadingState = createSelector(
  state => state.gateways.isLongLoading,
  gateways => gateways,
);

export const getCurrentModule = createSelector(
  app => app.currentModule,
  currentModule => currentModule === 'settings',
);
