const validator = values => {
  const errors = {};
  const { escalation } = values;
  const valuesFormatted = {};
  const overlapError = 'The "next" starting entry can only be minimum +0.0001 or -0.0001 from the "last" ending entry';

  const valuesList = Object.keys(values);
  const isDown = escalation === 'down';
  const isBoth = escalation === 'both';

  const toNumber = n => parseFloat(values[n]);

  const setValuesFormatted = (level, key) => {
    const id = key.slice(0, -9);
    const start = `${id}StartData`;
    const isLevelExists = valuesFormatted[`${level}Start`];

    if (isLevelExists) {
      // for escalation "Both"
      // the same type of level (example: the second Alert level) and it exists
      valuesFormatted[`${level}StartLow`] = start;
    } else if (isDown && level !== 'Normal') {
      valuesFormatted[`${level}StartLow`] = start;
    } else if (!isBoth || level !== 'Normal') {
      valuesFormatted[`${level}Start`] = start;
    }
  };

  valuesList.forEach(key => {
    if (values[key]) {
      if (values[key] === 'Normal') {
        setValuesFormatted('Normal', key);
      } else if (values[key] === 'Elevated') {
        setValuesFormatted('Elevated', key);
      } else if (values[key] === 'Watch') {
        setValuesFormatted('Watch', key);
      } else if (values[key] === 'Warning') {
        setValuesFormatted('Warning', key);
      } else if (values[key] === 'Alert') {
        setValuesFormatted('Alert', key);
      }
    }
  });

  const lowValues = Object.keys(valuesFormatted).reduce(
    (prev, cur) => (cur.endsWith('Low') ? [...prev, valuesFormatted[cur]] : prev),
    [],
  );

  const hasOverlappedPositive = (start, level) => {
    const { AlertStart, ElevatedStart, NormalStart, WarningStart, WatchStart } = valuesFormatted;

    if (level === 'Normal') {
      return start >= toNumber(ElevatedStart || WatchStart || WarningStart || AlertStart);
    } else if (level === 'Elevated') {
      return start >= toNumber(WatchStart || WarningStart || AlertStart) || toNumber(NormalStart) >= start;
    } else if (level === 'Watch') {
      return start >= toNumber(WarningStart || AlertStart) || toNumber(ElevatedStart || NormalStart) >= start;
    } else if (level === 'Warning') {
      return start >= toNumber(AlertStart) || toNumber(WatchStart || ElevatedStart || NormalStart) >= start;
    }

    return toNumber(WarningStart || WatchStart || ElevatedStart || NormalStart) >= start;
  };

  const hasOverlappedNegative = (start, level) => {
    const { AlertStartLow, ElevatedStartLow, NormalStart, WarningStartLow, WatchStartLow } = valuesFormatted;

    if (level === 'Normal') {
      return toNumber(ElevatedStartLow || WatchStartLow || WarningStartLow || AlertStartLow) >= start;
    } else if (level === 'Elevated') {
      return toNumber(WatchStartLow || WarningStartLow || AlertStartLow) >= start || start >= toNumber(NormalStart);
    } else if (level === 'Watch') {
      return toNumber(WarningStartLow || AlertStartLow) >= start || start >= toNumber(ElevatedStartLow || NormalStart);
    } else if (level === 'Warning') {
      return toNumber(AlertStartLow) >= start || start >= toNumber(WatchStartLow || ElevatedStartLow || NormalStart);
    }

    return start >= toNumber(WarningStartLow || WatchStartLow || ElevatedStartLow || NormalStart);
  };

  valuesList.forEach(key => {
    if (key.endsWith('LevelName')) {
      const id = key.slice(0, -9);
      const start = `${id}StartData`;
      const level = values[key];
      const startValue = toNumber(start);
      const isLowLevel = lowValues.indexOf(start) !== -1;

      if (values[start]) {
        if (isLowLevel || (isDown && level === 'Normal' && startValue < 0)) {
          if (hasOverlappedNegative(startValue, level)) {
            errors[start] = overlapError;
          }
        } else if (hasOverlappedPositive(startValue, level)) {
          errors[start] = overlapError;
        }
      }

      if (!values[start] && (!isBoth || (isBoth && level !== 'Normal'))) {
        errors[start] = 'Required';
      }

      if (level !== 'Normal') {
        if (isLowLevel && startValue >= 0) {
          errors[start] = 'This entry can only be negative';
        } else if (!isLowLevel && startValue < 0) {
          errors[start] = 'This entry can only be positive';
        }
      }
    }
  });

  return errors;
};

export default validator;
