import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import RadioGroup from 'components/Forms/RadioButtonGroup/SearchRadioGroup';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { Block, fullWidth } from './styled';

const SearchTenantForm = () => (
  <Block>
    <Field component={RadioGroup} name="MasterUnitFilter" fullWidth={fullWidth} />
    <Field component={TextFieldAdapter} floatingLabelText="Search" name="Search" maxLength="255" />
  </Block>
);

const SearchTenantWithInput = withInputModal(SearchTenantForm);

SearchTenantForm.propTypes = {};

const SearchTenantDialog = ({ ...rest }) => {
  const saveSearchValue = {
    Search: rest.searchParameter.Search,
    MasterUnitFilter: rest.searchParameter.MasterUnitFilter,
  };
  return [<SearchTenantWithInput {...rest} key="SearchTenantWithInput" initialValues={saveSearchValue} />];
};

SearchTenantDialog.defaultProps = {
  handleDelete: () => {},
  withDelete: false,
  withSubmit: true,
  isSearch: true,
  isSearchAll: true,
};

SearchTenantDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  searchParameter: PropTypes.object.isRequired,
  withDelete: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default SearchTenantDialog;
