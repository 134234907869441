import styled from 'styled-components';

import Subheader from 'components/Subheader';

export const Container = styled(Subheader)``;

export const UnitName = styled.div`
  display: inline-block !important;
  margin-right: 25px;
`;

export const Checklist = styled(UnitName)`
  margin: 0 5px 0 10px;
  color: ${({ theme }) => theme.primaryScheduler};
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin: 0;
`;

export const ConfirmationContainer = styled.div``;
