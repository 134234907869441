import styled from 'styled-components';
import { CardHeader, CardText } from 'material-ui/Card';

import { isIE11 } from 'helpers';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.lightGrey} !important;
  height: 100%;
`;

export const CardContent = styled(CardText)`
  display: flex;
`;

export const Header = styled(CardHeader)`
  background: ${({ theme }) => theme.primaryLists} !important;
  font-weight: 500 !important;
  padding: 4px !important;
  position: fixed;
  ${!isIE11 ? 'top: 186px' : ''}
  z-index: 2;
  position: sticky !important;

  svg {
    color: ${({ theme }) => theme.primaryWhite} !important;
  }

  & > div {
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    padding-right: 34px !important;

    span {
      color: ${({ theme }) => theme.primaryWhite} !important;
    }

    span:first-child {
      min-width: 0;
      word-break: break-all;
    }
  }
`;

export const RedTitle = styled.div`
  font-size: 18px !important;
  color: ${({ theme }) => theme.mainRed};
`;

export const UnitName = styled.div``;
