import { actions as unitsActions } from 'redux/units';
import { actions as unitActions } from 'redux/unit';
import { actions as authActions } from 'redux/auth';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as exportPdfActions } from 'redux/exportPdf';
import { actions as listTemplatesActions } from 'redux/listTemplates';

const mapStateToProps = ({
  auth: {
    selectedSite,
    user: { allowSubmitToListExchange, tenantIsOEM, userType },
  },
  exportPdf,
  unit: { unitItemsList, loading, unitData },
  units: { createdUnitId, error, unitsList, unitWithItemsList, loading: isUnitsLoading },
}) => ({
  allowSubmitToListExchange,
  createdUnitId,
  error,
  itemsList: unitItemsList,
  isUnitsLoading,
  isUnitLoading: loading,
  pdfList: exportPdf.list,
  selectedSite,
  tenantIsOEM,
  unitsList,
  unitWithItemsList,
  userType,
  IsGeneratedDefault: unitData.Barcode ? unitData.Barcode.IsGeneratedDefault : false,
  unit: unitData,
});

const mapDispatchToProps = {
  addUnit: unitsActions.addUnitRequest,
  fetchUnits: unitsActions.unitsListRequest,
  requestUnitsWithItems: unitsActions.getUnitsWithItemsRequest,
  requestUpdateItems: unitsActions.updateItemsListRequest,
  requestDuplicateUnit: unitsActions.duplicateUnitRequest,
  requestTenantToList: unitsActions.importTenantToListRequest,
  fetchAssetCategoriesListRequest: unitsActions.fetchAssetCategoriesListRequest,
  fetchUnit: unitActions.fetchUnitRequest,
  deleteUnit: unitActions.deleteUnitRequest,
  editUnit: unitActions.editUnitRequest,
  requestFetchUnitData: unitActions.unitDataFetchRequest,
  requestPdfList: exportPdfActions.getPdfListRequest,
  requestDeletePdf: exportPdfActions.deletePdfFileRequest,
  requestCreatePdf: exportPdfActions.createPdfFileRequest,
  requestRefreshPdf: exportPdfActions.refresh,
  getUnitTaskStatus: listTemplatesActions.getUnitTaskStatusRequest,
  getUserType: authActions.fetchUserSitesRequest,
  openErrorDialog,
};

export { mapStateToProps, mapDispatchToProps };
