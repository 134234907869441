import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import theme, { SensorLevelColors } from 'theme';
import { SensorType } from 'configs/propTypes';
import { SensorsDialog } from 'components/Layout/Modals';

import AlertTrends from '../AlertTrends';
import Title from './Title';

import * as S from './styled';

class ItemSensorsDialog extends Component {
  static propTypes = {
    alertTrends: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          fill: PropTypes.string.isRequired,
          time: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ).isRequired,
      dataInfo: PropTypes.shape({
        average: PropTypes.number,
        current: PropTypes.number,
        high: PropTypes.number,
        low: PropTypes.number,
      }).isRequired,
      normalMax: PropTypes.number,
      normalMin: PropTypes.number,
      tickFormat: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    alertTrendsVisible: PropTypes.shape({}).isRequired,
    handleClose: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(SensorType).isRequired,
    open: PropTypes.bool.isRequired,
    showAlertTrends: PropTypes.func.isRequired,
    status: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    status: '',
  };

  state = {
    hasPositiveValue: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.alertTrends.data !== nextProps.alertTrends.data) {
      let hasPositiveValue = false;

      nextProps.alertTrends.data.forEach(({ value }) => {
        if (value > 0 && !hasPositiveValue) {
          hasPositiveValue = true;
        }
      });

      this.setState({ hasPositiveValue });
    }
  }

  render() {
    const { alertTrends, alertTrendsVisible, handleClose, list, open, showAlertTrends, status, title } = this.props;
    const { hasPositiveValue } = this.state;

    return (
      <SensorsDialog
        autoDetectWindowHeight
        autoScrollBodyContent
        onRequestClose={handleClose}
        open={open}
        title={<Title handleClose={handleClose} status={status} title={title} />}
      >
        <S.Container>
          <S.Sensors>Sensors</S.Sensors>
          <S.Separator />
          {list.map(({ Id, SensorType: Type, Status }) => (
            <div key={Id}>
              <S.SensorItem
                key={Id}
                leftIcon={<S.IconWarning color={Status ? SensorLevelColors[Status] : theme.primaryGrey} />}
                onClick={() => showAlertTrends(Id, Status)}
              >
                {Type.Type}
              </S.SensorItem>
              <AlertTrends
                alertTrends={alertTrends}
                hasPositiveValue={hasPositiveValue}
                isVisible={alertTrendsVisible[Id]}
              />
            </div>
          ))}
        </S.Container>
      </SensorsDialog>
    );
  }
}

const mapStateToProps = ({ health: { alertTrends }, sensors: { list } }) => ({
  alertTrends,
  list,
});

export default connect(mapStateToProps)(ItemSensorsDialog);
