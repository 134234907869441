const initialState = {
  error: '',
  landingPageList: [],
  loading: false,
  landingPageSectionList: [],
  landingPageSection: {},
  videosCount: 0,
  videos: [],
};

export const fetchLandingPageListRequest = state => ({
  ...state,
  loading: true,
});

export const fetchLandingPageListSuccess = (state, { payload }) => ({
  ...state,
  landingPageList: payload,
  loading: false,
});

export const editLandingPageRequest = state => ({
  ...state,
  loading: true,
});

export const updateLandingPageSequenceRequest = state => ({
  ...state,
  loading: true,
});

export const fetchLandingPageSectionListRequest = state => ({
  ...state,
  loading: true,
});

export const fetchLandingPageSectionListSuccess = (state, { payload }) => ({
  ...state,
  landingPageSectionList: payload,
  loading: false,
});

export const createLandingPageSectionRequest = state => ({
  ...state,
  loading: true,
});

export const fetchLandingPageSectionRequest = state => ({
  ...state,
  loading: true,
});

export const fetchLandingPageSectionSuccess = (state, { payload }) => ({
  ...state,
  landingPageSection: payload,
  loading: false,
});

export const updateLandingPageSectionRequest = state => ({
  ...state,
  loading: true,
});

export const updateLandingPageSectionSuccess = (state, { payload }) => ({
  ...state,
  landingPageSection: payload,
  loading: false,
});

export const deleteLandingPageSectionRequest = state => ({
  ...state,
  loading: true,
});

export const updateLandingPageSectionSequenceRequest = state => ({
  ...state,
  loading: true,
});

export const fetchVideosCountRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const fetchVideosCountSuccess = (state, { payload }) => ({
  ...state,
  videosCount: payload,
  loading: false,
});

export const fetchVideosBySectionRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const fetchVideosBySectionSuccess = (state, { payload }) => ({
  ...state,
  videos: payload,
  loading: false,
});

export const setTutorialPageError = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const resetVideosCount = state => ({
  ...state,
  videosCount: 0,
});

export const refreshVimeoCacheRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const refreshVimeoCacheSuccess = state => ({
  ...state,
  error: '',
  loading: false,
});

export default initialState;
