import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import withInputModal from 'components/Dialogs/withInputModal';
import Search from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';

import validate from './validator';
import * as S from './styled';

class ChooseIndustryForm extends PureComponent {
  static propTypes = {
    group: PropTypes.shape({
      Name: PropTypes.string,
      IndustryID: PropTypes.string,
      Id: PropTypes.string,
    }).isRequired,
    industryList: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string,
        Name: PropTypes.string,
        MeeteringGroupID: PropTypes.string,
      }),
    ).isRequired,
  };

  state = {
    searchData: '',
    industries: this.props.industryList,
  };

  setSearchedItems = (industries, searchData) => this.setState({ industries, searchData });

  render() {
    const { searchData, industries } = this.state;
    const { group, industryList } = this.props;

    return (
      <S.FormContainer>
        <S.DialogHeader>
          <S.GroupName>{group.Name}</S.GroupName>
          <S.SearchContainer>
            <Search
              list={industryList}
              filterBy="Name"
              searchData={searchData}
              placeholder="filter Industries"
              setSearchedItems={this.setSearchedItems}
              icon={<SearchIcon />}
            />
          </S.SearchContainer>
        </S.DialogHeader>
        <Field name="IndustryID" type="radio">
          {({ input }) => (
            <S.RadioGroup {...input} defaultSelected={group.IndustryID}>
              {industries.length > 0
                ? industries.map(({ Id, Name, MeeteringGroupID }) => (
                    <S.RadioField
                      key={Id}
                      value={Id}
                      label={Name}
                      disabled={MeeteringGroupID && MeeteringGroupID !== group.Id}
                    />
                  ))
                : []}
            </S.RadioGroup>
          )}
        </Field>
      </S.FormContainer>
    );
  }
}

const ChooseIndustry = withInputModal(ChooseIndustryForm);

const ChooseIndustryDialog = props => <ChooseIndustry initialValues={props.group} validate={validate} {...props} />;

ChooseIndustryDialog.propTypes = {
  group: PropTypes.shape({
    Name: PropTypes.string,
    Notes: PropTypes.string,
  }).isRequired,
};

ChooseIndustryDialog.defaultProps = {
  group: {
    IndustryID: '',
  },
};

const mapStateToProps = ({ tenants: { industryList } }) => ({
  industryList,
});

export default connect(mapStateToProps)(ChooseIndustryDialog);
