import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const styles = {
  radioButton: {
    padding: '5px 0px',
    display: 'flex',
  },
};

const RadioGroup = ({ input, meta, style }) => (
  <RadioButtonGroup
    name="type"
    valueSelected={input.value}
    defaultSelected={0}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    <RadioButton
      value={0}
      label="Use an Existing Shortcut"
      iconStyle={{
        fill: input.value.length === 0 || input.value === 0 ? theme.primaryScheduler : theme.darkGrey,
        marginRight: '0',
      }}
      style={styles.radioButton}
    />
    <RadioButton
      value={1}
      label="Create a new Shortcut"
      iconStyle={{ fill: input.value === 1 ? theme.primaryScheduler : theme.darkGrey, marginRight: '-8px' }}
      style={styles.radioButton}
    />
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
