import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { DocumentImageType } from 'configs/propTypes';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import UploadImageRow from './UploadImageRow';
import { Block, FormContainer, Label } from './styled';

const Form = ({
  backgroundImage,
  deleteBackgroundImage,
  deleteFooterImage,
  deleteHeaderImage,
  footerImage,
  headerImage,
  initialValues,
  uploadBackgroundImage,
  uploadFooterImage,
  uploadHeaderImage,
}) => (
  <FormContainer>
    <Field component={TextFieldAdapter} floatingLabelText="Page Number" name="PageNumber" type="number" />
    <Field component={TextFieldAdapter} floatingLabelText="Header Text" name="HeaderText" multiLine rowsMax={3} />
    <Field component={TextFieldAdapter} floatingLabelText="Body Text" name="BodyText" multiLine rowsMax={3} />
    <Block>
      <Label> Header Image </Label>
      <UploadImageRow
        imageType="header-image"
        fileName={headerImage.name || initialValues.HeaderImageFileName}
        onDelete={deleteHeaderImage}
        onUpload={uploadHeaderImage}
      />
    </Block>
    <Block>
      <Label> Background Image </Label>
      <UploadImageRow
        imageType="background-image"
        fileName={backgroundImage.name || initialValues.BackgroundImageFileName}
        onDelete={deleteBackgroundImage}
        onUpload={uploadBackgroundImage}
      />
    </Block>
    <Block>
      <Label> Footer Image </Label>
      <UploadImageRow
        imageType="footer-image"
        fileName={footerImage.name || initialValues.FooterImageFileName}
        onDelete={deleteFooterImage}
        onUpload={uploadFooterImage}
      />
    </Block>
  </FormContainer>
);

Form.propTypes = {
  backgroundImage: DocumentImageType.isRequired,
  deleteBackgroundImage: PropTypes.func.isRequired,
  deleteFooterImage: PropTypes.func.isRequired,
  deleteHeaderImage: PropTypes.func.isRequired,
  footerImage: DocumentImageType.isRequired,
  headerImage: DocumentImageType.isRequired,
  initialValues: PropTypes.object.isRequired,
  uploadBackgroundImage: PropTypes.func.isRequired,
  uploadFooterImage: PropTypes.func.isRequired,
  uploadHeaderImage: PropTypes.func.isRequired,
};

export default Form;
