import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { GatewayType, HubType, TenantType, SiteType } from 'configs/propTypes';

import { getRegionsList } from 'redux/gateways/selectors';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import Sku from './Sku';
import { getServerHubList } from './selectors';
import validate from './validator';

import * as S from './styled';

const AddEditGatewayDialogForm = ({
  form,
  isResourceGroupUnique,
  onChangeSite,
  onChangeTenant,
  onChangeTier,
  onStateChange,
  regionsList,
  resourceGroupRegion,
  SKU,
  selectedGateway,
  sitesList,
  tenantsList,
  values,
}) => (
  <S.Container>
    <S.Block>
      <S.Label>Resource Group Setup</S.Label>
      <S.SelectContainer>
        <S.ResourceGroupRegion
          value={resourceGroupRegion.toLowerCase()}
          floatingLabelText="Resource Group Location"
          onChange={(e, i, value) => onStateChange('resourceGroupRegion', value)}
          disabled={!!selectedGateway.Id || !isResourceGroupUnique}
        >
          {regionsList.map(location => (
            <S.Item key={location} primaryText={location} value={location} />
          ))}
        </S.ResourceGroupRegion>
        <Field
          component={S.SelectField}
          name="TenantID"
          floatingLabelText="Select a Tenant"
          onChangeControl={value => onChangeTenant(value, resourceGroupRegion, form)}
          disabled={!resourceGroupRegion || !!selectedGateway.Id}
        >
          {tenantsList.map(({ Id, Name }) => (
            <S.Item key={Id} primaryText={Name} value={Id} />
          ))}
        </Field>
        <Field component={TextFieldAdapter} name="ResourceGroupName" floatingLabelText="Resource Group Name" disabled />
      </S.SelectContainer>
    </S.Block>
    <S.Block>
      <S.Label>Hub Setup</S.Label>
      <S.SelectContainer>
        <Field
          component={S.RegionSelectField}
          name="IotHubRegion"
          floatingLabelText="Resource Hub Location"
          disabled={!!selectedGateway.Id}
        >
          {regionsList.map(location => (
            <S.Item key={location} primaryText={location} value={location} />
          ))}
        </Field>
        <Sku
          SKU={SKU}
          handleTierChange={onChangeTier}
          disabled={!!selectedGateway.Id || !values.IotHubRegion || !values.ResourceGroupName}
        />
        <Field
          component={S.SelectField}
          name="SiteID"
          floatingLabelText="Select a Site"
          onChangeControl={value => onChangeSite(value, values, form)}
          disabled={!!selectedGateway.Id || !values.IotHubRegion || !SKU.SKUName || !SKU.Capacity}
        >
          {sitesList.map(({ Id, Name }) => (
            <S.Item key={Id} primaryText={Name} value={Id} />
          ))}
        </Field>
        <Field component={TextFieldAdapter} name="IotHubName" floatingLabelText="IoT Hub Name" disabled />
      </S.SelectContainer>
    </S.Block>
    <S.Block>
      <S.Label>Gateway Setup</S.Label>
      <S.SelectContainer>
        <Field component={TextFieldAdapter} name="DeviceId" floatingLabelText="Gateway Name (IoT Device)" disabled />
        <Field component={S.Textarea} name="Notes" floatingLabelText="Gateway Notes" rows={4} />
      </S.SelectContainer>
    </S.Block>
  </S.Container>
);

AddEditGatewayDialogForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  regionsList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isResourceGroupUnique: PropTypes.bool.isRequired,
  sitesList: PropTypes.arrayOf(SiteType).isRequired,
  tenantsList: PropTypes.arrayOf(TenantType).isRequired,
  onChangeSite: PropTypes.func.isRequired,
  onChangeTenant: PropTypes.func.isRequired,
  onChangeTier: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  selectedGateway: GatewayType.isRequired,
  values: PropTypes.shape({
    resourceGroupRegion: PropTypes.string,
    ResourceGroupName: PropTypes.string,
    IotHubRegion: PropTypes.string,
  }).isRequired,
  SKU: PropTypes.shape({
    SKUName: PropTypes.string,
    SKUTier: PropTypes.string,
    Capacity: PropTypes.number,
  }).isRequired,
  resourceGroupRegion: PropTypes.string.isRequired,
};

const AddEditGatewayDialogWithInput = withInputModal(AddEditGatewayDialogForm);

const AddEditGatewayDialog = props => (
  <AddEditGatewayDialogWithInput initialValues={{ ...props.selectedGateway }} validate={validate} {...props} />
);

AddEditGatewayDialog.propTypes = {
  iotHubsList: PropTypes.arrayOf(HubType).isRequired,
  selectedGateway: GatewayType.isRequired,
};

const mapStateToProps = ({
  gateways: { iotHubsList, regionsList, serversList },
  tenants: { list: tenantsList },
  sites: { list: sitesList },
}) => ({
  iotHubsList: getServerHubList(iotHubsList),
  regionsList: getRegionsList(regionsList),
  serversList: getServerHubList(serversList),
  tenantsList,
  sitesList,
});

export default connect(mapStateToProps)(AddEditGatewayDialog);
