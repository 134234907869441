export const constraints = {
  0: 'is',
  1: 'is not',
  2: 'begins with',
  4: 'ends with',
  8: 'contains',
  16: 'does not contain',
};

export const matchOperator = {
  0: 'All',
  1: 'Any of the following rules',
};

export const types = {
  0: 'fixed - one time',
  1: 'dynamic - always on',
};

export const optionNames = {
  location: [
    { name: 'Site Name', value: 'SiteName' },
    { name: 'Site ID', value: 'SiteID' },
    { name: 'Site State', value: 'SiteState' },
    { name: 'Site City', value: 'SiteCity' },
  ],
  unit: [
    { name: 'Unit Name', value: 'UnitName' },
    { name: 'Unit Description', value: 'UnitDescription' },
    { name: 'Unit Model', value: 'UnitModelNumber' },
    { name: 'Unit Location', value: 'UnitLocationDescription' },
  ],
  item: [
    { name: 'Item Name', value: 'ItemName' },
    { name: 'Item Description', value: 'ItemDescription' },
  ],
};
