import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonFlat, ButtonIcon, ButtonRaised } from 'components/Layout/Buttons';

export const ButtonContainer = styled(Flex).attrs({
  align: 'center',
})`
  height: 100%;

  &:first-child {
    margin-right: 0;
  }

  &:first-child {
    margin-right: 0;
  }
`;

export const Container = styled(Flex).attrs({
  align: 'center',
})`
  height: ${({ withBorder }) => (withBorder ? '50%' : '100%')} !important;
  border: ${({ withBorder, theme }) => (withBorder ? `1px solid ${theme.darkGrey}` : 'none')};
  border-radius: ${({ withBorder }) => withBorder && '4rem'};
  margin-right: 15px;
`;

export const FlatButton = styled(ButtonFlat)``;

export const IconButton = styled(ButtonIcon)`
  transform: translate3d(0, 0, 0) !important;
`;

export const RaisedButton = styled(ButtonRaised).attrs({
  backgroundColor: ({ buttoncolor, theme }) => buttoncolor || theme.darkGrey,
  labelColor: ({ labelcolor, theme }) => labelcolor || theme.primaryWhite,
  style: ({ borderstyle }) => ({
    border: borderstyle || null,
  }),
})`
  margin-left: 5px !important;

  @media only screen and (max-width: 370px) {
    max-width: 110px !important;
    button {
      span {
        padding: 0 5px !important;
      }
    }
  }
`;
