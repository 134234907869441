import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_PDF_REPORT_LIST_REQUEST: undefined,
  GET_PDF_REPORT_LIST_SUCCESS: undefined,
  GET_PDF_REPORT_LIST_FAILURE: undefined,

  DELETE_PDF_REPORT_FILE_REQUEST: undefined,
  DELETE_PDF_REPORT_FILE_SUCCESS: undefined,
  DELETE_PDF_REPORT_FILE_FAILURE: undefined,

  CREATE_PDF_REPORT_REQUEST: undefined,
  CREATE_PDF_REPORT_SUCCESS: undefined,
  CREATE_PDF_REPORT_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getPdfReportListRequest, handlers.getPdfReportListRequest],
    [actions.getPdfReportListSuccess, handlers.getPdfReportListSuccess],
    [actions.getPdfReportListFailure, handlers.getPdfReportListFailure],

    [actions.deletePdfReportFileRequest, handlers.deletePdfReportFileRequest],
    [actions.deletePdfReportFileSuccess, handlers.deletePdfReportFileSuccess],
    [actions.deletePdfReportFileFailure, handlers.deletePdfReportFileFailure],

    [actions.createPdfReportRequest, handlers.createPdfReportRequest],
    [actions.createPdfReportSuccess, handlers.createPdfReportSuccess],
    [actions.createPdfReportFailure, handlers.createPdfReportFailure],
  ]),
  initialState,
);

export default reducer;
