import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { GatewayType } from 'configs/propTypes';
import { actions as gatewaysActions } from 'redux/gateways';
import Subheader from 'components/Subheader';
import SimpleList from 'components/SimpleList';
import { ButtonIcon } from 'components/Layout/Buttons';
import { BackIcon } from 'components/Layout/Icons';

import Item from './Item';
import { getGatewaysListWithSiteName } from './selector';

import * as S from './styled';

class Gateways extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editGatewayRequest: PropTypes.func.isRequired,
      getGatewaysRequest: PropTypes.func.isRequired,
    }).isRequired,
    list: PropTypes.arrayOf(GatewayType).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    searchData: '',
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getGatewaysRequest({ isMaster: false });
  }

  filterGateways = ({ siteName, SerialNumber }) =>
    SerialNumber.includes(this.state.searchData) ||
    (siteName && siteName.toLowerCase().includes(this.state.searchData.toLowerCase()));

  backToSettings = () => {
    this.props.history.push('/settings');
  };

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  goToSensors = item => this.props.history.push(`/settings/gateways/${item.Id}/sensors`);

  renderRightControls = Id => (
    <div>
      <Link to={`/settings/gateways/${Id}/sensors`}>
        <ButtonIcon tooltip="Sensors">
          <S.IconForward />
        </ButtonIcon>
      </Link>
    </div>
  );

  render() {
    const { list } = this.props;
    const { searchData } = this.state;

    return (
      <>
        <Subheader
          title="Gateways"
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backToSettings,
              hint: 'Back',
            },
          ]}
          hintText="Filter Site or Serial Number"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
        />
        <SimpleList
          data={list.filter(this.filterGateways)}
          onItemClick={this.goToSensors}
          emptyListMessage="There are no IoT gateways avaliable"
          renderItemContent={item => <Item item={item} />}
          renderRightControls={item => this.renderRightControls(item.Id)}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user }, gateways }) => ({
  list: getGatewaysListWithSiteName(gateways, user),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...gatewaysActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gateways);
