import React from 'react';
import PropTypes from 'prop-types';
import { BackIcon } from 'components/Layout/Icons';

import { formatDate } from '../helpers';

import * as S from './styled';

const Header = ({ part, goBack }) => {
  const { MyPartID: myPartId, OEMPartID: oemId, CreatedOn: createdOn, UpdatedOn: updatedOn } = part;

  return (
    <S.Container>
      <S.Navigation>
        <S.IconButton onClick={goBack} tooltip="Back">
          <BackIcon />
        </S.IconButton>
        <span>Part Details</span>
      </S.Navigation>
      <S.Content>
        <S.Block />
        <S.Block>
          <S.TextRow align="center">
            <S.RedText pr={10}>OEM ID:</S.RedText>
            <S.Text>{oemId}</S.Text>
          </S.TextRow>
          <S.TextRow align="center">
            <S.RedText pr={10}>My Part ID:</S.RedText>
            <S.BoldText>{myPartId}</S.BoldText>
          </S.TextRow>
        </S.Block>
        <S.Block>
          <S.TextRow>
            <S.LightText pr={10}>Created:</S.LightText>
            <S.LightText pr={10}>{formatDate(createdOn)}</S.LightText>
          </S.TextRow>
          {updatedOn ? (
            <S.TextRow>
              <S.LightText pr={10}>Last Revision:</S.LightText>
              <S.LightText>{formatDate(updatedOn)}</S.LightText>
            </S.TextRow>
          ) : null}
        </S.Block>
      </S.Content>
    </S.Container>
  );
};

Header.propTypes = {
  part: PropTypes.shape({
    MyPartID: PropTypes.string,
    OEMPartID: PropTypes.string,
    CreatedOn: PropTypes.string,
    MyPartRevisionDate: PropTypes.string,
    UpdatedOn: PropTypes.string,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
};

export default Header;
