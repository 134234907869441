import { createSelector } from 'reselect';
import { UserTypes } from 'configs/types';

export const getSelectedSite = createSelector(
  state => state.auth.selectedSite,
  selectedSite => selectedSite,
);

export const getCurrentUser = createSelector(
  state => state.auth.user,
  userData => userData,
);

export const getProfileData = createSelector(
  auth => auth.user,
  userData => [
    {
      label: 'User Type:',
      value: UserTypes[userData.userType],
    },
    {
      label: 'Email:',
      value: userData.userEmail,
    },
    {
      label: 'Phone:',
      value: userData.userPhone,
    },
    {
      label: 'Job Function:',
      value: userData.userJobFunction,
    },
  ],
);

export const getSitesAvailableToCurrentUser = createSelector(
  state => state.auth.user,
  userData => Object.keys(userData.sitesAvailableToUser),
);
