import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from 'components/Dialogs/Base';
import Search from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import { GreenButton } from 'components/Layout/Buttons';

import { connect } from 'react-redux';
import { actions as partsActions } from 'redux/parts';

import * as S from './styled';

const PART_FILTERS = ['MyPartID', 'MyPartName', 'MyPartCategory'];
const GROUP_FILTERS = ['Name', 'Description'];

class SelectPartDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    getPartsRequest: PropTypes.func.isRequired,
    getGroupsRequest: PropTypes.func.isRequired,
    partsList: PropTypes.array.isRequired,
    groupsList: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSelectGroup: PropTypes.bool.isRequired,
  };

  state = {
    searchString: '',
    searchedItems: [],
    selectedItems: [],
    isFirstGroupsRequest: true,
    isFirstPartsRequest: true,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !nextProps.isSelectGroup && !nextProps.partsList.length && this.state.isFirstPartsRequest) {
      this.props.getPartsRequest();
      this.setState({ isFirstPartsRequest: false });
    }
    if (nextProps.open && nextProps.isSelectGroup && !nextProps.groupsList.length && this.state.isFirstGroupsRequest) {
      this.props.getGroupsRequest();
      this.setState({ isFirstGroupsRequest: false });
    }
    if (nextProps.partsList && !nextProps.isSelectGroup) {
      this.setState({ searchedItems: nextProps.partsList });
    }
    if (nextProps.groupsList && nextProps.isSelectGroup) {
      this.setState({ searchedItems: nextProps.groupsList });
    }
    if (nextProps.isSelectGroup !== this.props.isSelectGroup) {
      this.setState({ selectedItems: [] });
    }

    if (nextProps.open && !this.props.open) {
      this.setState({ selectedItems: [], searchString: '' });
    } else {
      this.setState({
        isFirstGroupsRequest: true,
        isFirstPartsRequest: true,
      });
    }
  }

  onChangeCheckbox = partId => e => {
    if (e.target.checked) this.setState({ selectedItems: [...this.state.selectedItems, partId] });
    else this.setState(oldState => ({ selectedItems: oldState.selectedItems.filter(item => item !== partId) }));
  };

  onChangeSelectAll = e => {
    if (e.target.checked) {
      const selected = this.state.searchedItems.map(item => item.Id);
      this.setState({ selectedItems: selected });
    } else this.setState({ selectedItems: [] });
  };

  handleLinkParts = () => {
    this.props.onSubmit(this.state.selectedItems);
    this.setState({ selectedItems: [] });
  };

  render() {
    const { isSelectGroup, groupsList, partsList } = this.props;
    const { selectedItems, searchedItems, searchString } = this.state;
    const isAllChecked = selectedItems.length === searchedItems.length;

    return (
      <BaseDialog {...this.props} bodyStyle={{ padding: 0, display: 'flex' }}>
        <S.Container>
          <Search
            placeholder={`Filter ${isSelectGroup ? 'Groups' : 'Parts'}`}
            list={isSelectGroup ? groupsList : partsList}
            filterBy={isSelectGroup ? GROUP_FILTERS : PART_FILTERS}
            setSearchedItems={(data, str) => this.setState({ searchedItems: data, searchString: str })}
            searchData={searchString}
            icon={<SearchIcon />}
          />
          <S.SelectAllContainer>
            <S.Row noborder>
              <S.Column>
                <p>Select All</p>
                <S.Checkbox disabled={!searchedItems.length} checked={isAllChecked} onCheck={this.onChangeSelectAll} />
              </S.Column>
            </S.Row>
          </S.SelectAllContainer>
          <S.Table>
            <S.Row>
              <S.HeaderColumn />
              <S.HeaderColumn>{isSelectGroup ? 'Group Name' : 'My ID'}</S.HeaderColumn>
              <S.HeaderColumn>{isSelectGroup ? 'Description' : 'My Name'}</S.HeaderColumn>
              <S.HeaderColumn>{isSelectGroup ? 'Parts Qty' : 'My Category'}</S.HeaderColumn>
            </S.Row>
            {searchedItems.map(item => (
              <S.Row key={item.Id}>
                <S.Column>
                  <S.Checkbox onCheck={this.onChangeCheckbox(item.Id)} checked={selectedItems.includes(item.Id)} />
                </S.Column>
                <S.Column isDisabled={isSelectGroup ? !item.Name : !item.MyPartID}>
                  {isSelectGroup ? item.Name || 'No Name' : item.MyPartID || 'No ID'}
                </S.Column>
                <S.Column isDisabled={isSelectGroup ? !item.Description : !item.MyPartName}>
                  {isSelectGroup ? item.Description || 'No Description' : item.MyPartName || 'No Name'}
                </S.Column>
                <S.Column isDisabled={isSelectGroup ? item.LinksCount < 0 : !item.MyPartCategory}>
                  {isSelectGroup ? item.LinksCount : item.MyPartCategory || 'No Category'}
                </S.Column>
              </S.Row>
            ))}
          </S.Table>
          <S.ButtonContainer>
            <GreenButton
              label="Add Selection"
              fullWidth
              primary
              disabled={!selectedItems.length}
              onClick={this.handleLinkParts}
            />
          </S.ButtonContainer>
        </S.Container>
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ parts: { list, groupsList } }) => ({
  partsList: list,
  groupsList,
});

const mapDispatchToProps = {
  getPartsRequest: partsActions.getPartsRequest,
  getGroupsRequest: partsActions.getPartGroupsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPartDialog);
