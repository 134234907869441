import React from 'react';
import PropTypes from 'prop-types';

import * as CS from 'components/Dialogs/styled';
import { ButtonIcon } from 'components/Layout/Buttons';

import { separateRuleType } from './helpers';
import * as S from './styled';

const BASE_INDEX = 1000;

const TasksTable = ({ list, onDelete }) => (
  <S.Container>
    <S.ListContainer>
      {list.length > 0 &&
        list.map(({ RuleType, ItemId }, index) => (
          <S.ListItemContainer index={BASE_INDEX - index} key={BASE_INDEX - index}>
            <S.ListItem>
              <S.ItemValue>{separateRuleType(RuleType)}</S.ItemValue>
              <S.ItemValue>
                <ButtonIcon>
                  <S.IconDelete onClick={() => onDelete(index, ItemId)} />
                </ButtonIcon>
              </S.ItemValue>
            </S.ListItem>
            {index !== list.length - 1 && <CS.DividerLine />}
          </S.ListItemContainer>
        ))}
    </S.ListContainer>
  </S.Container>
);

TasksTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TasksTable;
