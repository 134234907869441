import React from 'react';
import * as S from './styled';

export const indexItemsToggle = [
  { key: 'manual', title: 'Manual' },
  { key: 'upload', title: 'Upload' },
  { key: 'iot', title: 'IoT' },
  { key: 'api', title: 'API' },
];

export const tableConfigs = [
  { title: 'Name', field: 'Name' },
  { field: 'AdditionalIndexID', headerIcon: <S.FormatLineSpacing />, isSortable: true, title: 'ID' },
  { field: 'LiveDate', headerIcon: <S.FormatLineSpacing />, isSortable: true, title: 'LiveDate' },
  { field: 'EndDate', headerIcon: <S.FormatLineSpacing />, isSortable: true, title: 'EndDate' },
  { field: 'State', headerIcon: <S.FormatLineSpacing />, isSortable: true, title: 'State' },
  { field: 'CreatedOn', headerIcon: <S.FormatLineSpacing />, isSortable: true, title: 'CreatedOn' },
  { field: 'LiveTime', headerIcon: <S.FormatLineSpacing />, isSortable: true, title: 'LiveTime' },
  { field: 'EndTime', headerIcon: <S.FormatLineSpacing />, isSortable: true, title: 'EndTime', colSpan: 2 },
];
