import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import CheckboxAdapter from 'components/Forms/CheckboxAdapter';

import validate from './validator';
import * as S from './styled';
import { LoginButton } from 'components/Buttons/LoginButton/styled';

class LoginForm extends PureComponent {
  static propTypes = {
    authMode: PropTypes.oneOf(['signin', 'signup']).isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    showPassword: false,
  };

  handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  onFormSubmit = values => {
    this.props.onSubmit(values);
  };

  render() {
    const { authMode, loading } = this.props;
    const { showPassword } = this.state;

    return (
      <Form
        onSubmit={this.onFormSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <S.FormContainer onSubmit={handleSubmit} noValidate authMode={authMode}>
            <S.Label>Username</S.Label>
            <Field disabled={loading} name="Username">
              {({ input, meta }) => (
                <S.TextInput
                  type="text"
                  {...input}
                  meta={meta}
                  placeholder={meta.error && meta.touched ? meta.error : 'A cool name, email or phone number'}
                />
              )}
            </Field>
            <S.Label>Password</S.Label>
            <S.FieldContainer>
              <Field disabled={loading} name="Password">
                {({ input, meta }) => (
                  <S.TextInput
                    type={showPassword ? 'text' : 'password'}
                    {...input}
                    meta={meta}
                    placeholder={meta.error && meta.touched ? meta.error : 'A string, phrase or sentence'}
                  />
                )}
              </Field>
              <S.ShowPasswordButton type="button" onClick={this.handleShowPassword}>
                {showPassword ? 'hide' : 'show'}
              </S.ShowPasswordButton>
            </S.FieldContainer>
            <Field
              component={CheckboxAdapter}
              disabled={loading}
              label="Remember me"
              name="RememberMe"
              style={{ margin: 0 }}
            />
            <LoginButton type="submit">LOGIN</LoginButton>
          </S.FormContainer>
        )}
      />
    );
  }
}

export default LoginForm;
