import styled from 'styled-components';

import { isIE11 } from 'helpers';
import { ButtonIcon } from 'components/Layout/Buttons';
import { DateRangeIcon, PinDropIcon, TeamsLightBlue, TeamsSecondaryBlue, ExitToAppIcon } from 'components/Layout/Icons';
import MenuItem from 'material-ui/MenuItem';

export const HeaderMenuItem = styled(MenuItem)`
  ${({ theme }) => `
      margin-top: -16px;
      color: ${theme.primaryWhite}!important;
      background-color: ${theme.headerColor};
  `}
`;

const buttonLeftPosition = (isInactive, isSiteButton) => {
  let left;

  if (isSiteButton) {
    if (isIE11 && isInactive) {
      left = '-210px';
    } else {
      left = isInactive ? '-30px' : '8px';
    }
  } else if (isIE11 && isInactive) {
    left = '-280px';
  } else {
    left = isInactive ? '-50px' : '17px';
  }

  return {
    left,
    top: '46px',
    width: isIE11 || !isInactive ? 'auto' : '130px',
  };
};

export const RightControlsContainer = styled.div`
  justify-content: center;
  align-items: center;
`;

export const RelatedTasksButton = styled(ButtonIcon).attrs({
  iconStyle: {
    height: 36,
    width: 36,
  },
  tooltipStyles: ({ isInactive }) => buttonLeftPosition(isInactive, false),
})`
  height: 72px !important;
  padding: 16px !important;
  width: 72px !important;
`;

export const ActionPlaceholder = styled.div`
  width: 72px;
  height: 72px;
`;

export const SplitButton = styled(ButtonIcon)`
  position: absolute !important;
  left: 0;
  top: -13px;
`;

export const ActiveSitesButton = styled(RelatedTasksButton).attrs({
  tooltipStyles: ({ isInactive }) => buttonLeftPosition(isInactive, true),
})``;

export const CalendarIcon = styled(DateRangeIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})``;

export const TeamsIcon = styled(TeamsSecondaryBlue).attrs()``;
export const TeamsInactiveIcon = styled(TeamsLightBlue).attrs()``;

export const IconPinDrop = styled(PinDropIcon).attrs({
  color: ({ theme }) => theme.primarySites,
})``;

export const Title = styled.div`
  margin: 10px 10px 10px 15px;
`;

export const AssignedSitesTitleText = styled.h3`
  margin: 0;
  text-align: center;
`;

export const AssignedSitesTitleUser = styled(AssignedSitesTitleText)`
  text-align: left;
`;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0;
  text-align: start;
  width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
`;

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

export const SectionBody = styled.div`
  padding: 0px 25px;
`;

export const TaskStatusText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 14px;
  margin: 15px 0px;
`;

export const RedTaskStatusText = styled(TaskStatusText)`
  color: ${({ theme }) => theme.mainRed};
`;

export const SiteText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  padding-left: 15px;
`;

export const SiteRow = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 15px 0px;
`;

export const RedSubTitleText = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.mainRed};
  margin: 0px 0px 0px 5px;
`;

export const RedTitleText = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.mainRed};
  font-size: 20px;
  margin: 10px 0px 20px 5px;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const IconButton = styled(ButtonIcon)`
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  transform: translate3d(0, 0, 0) !important;
`;

export const ExitIcon = styled(ExitToAppIcon).attrs({
  color: ({ theme }) => theme.textGray,
})``;

export const ConfirmationContainer = styled.div`
  & > div:last-child {
    padding: 0px !important;
    margin-top: 20px;
    & > button:first-child {
      margin-right: 5px !important;
    }

    & > button:last-child {
      margin-left: 5px !important;
    }
  }
`;

export const ConfirmationBoldText = styled.span`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 600;
`;

export const ConfirmationText = styled.p`
  font-size: 16px;
  margin: 0px;
`;

export const ConfirmationWarningText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ConfirmationTitle = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 600;
`;
