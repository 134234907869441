import styled from 'styled-components';
import { ButtonIcon } from 'components/Layout/Buttons';
import { CheckIcon } from 'components/Layout/Icons';

const BlockContainer = styled.div`
  min-height: 160px;
  max-height: 300px;
  overflow-y: auto;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 20px;
  gap: 4px;
`;

const IconButton = styled(ButtonIcon)`
  svg {
    fill: ${({ theme }) => theme.mainRed} !important;
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)} !important;
  }
`;

const Check = styled(CheckIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export { BlockContainer, Block, IconButton, Check };
