import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonFlat } from 'components/Layout/Buttons';

export const Container = styled(Flex).attrs({
  justify: 'space-between',
  p: 1,
})`
  background-color: ${({ theme }) => theme.lightGrey};
  box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 6px, rgba(0, 0, 0, 0.12) 0px -1px 4px;
  min-height: 52px;
  position: relative;
  z-index: 10;
`;

export const Centered = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 15px);
`;

export const ReleaseButton = styled(ButtonFlat).attrs({
  primary: false,
  disabledBackgroundColor: ({ theme }) => theme.disabledColor,
  backgroundColor: ({ theme, disabled }) => (disabled ? theme.primaryHome : theme.lightGreenButton),
})`
  overflow: visible !important;
  border-radius: 6px !important;
  min-width: 130px !important;
  span {
    color: ${({ theme, disabled }) => (disabled ? theme.textGray : theme.greenButton)} !important;
  }
`;

export const Label = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  pointer-events: none;
  background: ${({ theme }) => theme.primaryRed};
  border-color: ${({ theme }) => theme.darkRed};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0.1em 0.1em;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 14px;
  height: 20px;
  min-width: 20px;
  padding: 0 3px;
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 99;
`;
