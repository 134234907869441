import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';

import { TaskPhoto } from 'configs/propTypes';
import withInputModal from 'components/Dialogs/withInputModal';
import { Block, PhotoContent, ImageArea, ImageContainer, TextValue, FullScreenDialog } from './styled';
import { Content } from '../../../../tasks/pages/TaskDetails/styled';

const params = {
  grabCursor: true,
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets',
  },
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
};

const TaskPhotoForm = ({ item, photos, siteId, open, closePhotoDialog }) => (
  <FullScreenDialog
    modal={false}
    open={open}
    defaultCancel
    defaultCancelText="Close"
    title="Photos"
    onRequestClose={closePhotoDialog}
  >
    <Block>
      {photos && photos.length ? (
        <ImageArea>
          <Content>
            <PhotoContent>
              <Swiper {...params}>
                {photos.map((photo, index) => (
                  <ImageContainer key={index}>
                    <img alt={photo.Name} src={`${photo.src}?parentId=${item.ItemId}&inline=true&siteId=${siteId}`} />
                  </ImageContainer>
                ))}
              </Swiper>
            </PhotoContent>
          </Content>
        </ImageArea>
      ) : (
        <TextValue>This task has no confirmation photos</TextValue>
      )}
    </Block>
  </FullScreenDialog>
);

const TaskPhotoWithInput = withInputModal(TaskPhotoForm);

TaskPhotoForm.defaultProps = {
  item: {},
  open: false,
};

TaskPhotoForm.propTypes = {
  item: PropTypes.object,
  photos: TaskPhoto.isRequired,
  siteId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  closePhotoDialog: PropTypes.func.isRequired,
};

const TaskPhotoDialog = ({ item, photos, siteId, open, closePhotoDialog, ...rest }) => (
  <TaskPhotoWithInput
    item={item}
    photos={photos}
    siteId={siteId}
    open={open}
    closePhotoDialog={closePhotoDialog}
    {...rest}
  />
);

TaskPhotoDialog.defaultProps = {
  item: {},
  withSubmit: false,
};

TaskPhotoDialog.propTypes = {
  item: PropTypes.object,
  photos: TaskPhoto.isRequired,
  siteId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closePhotoDialog: PropTypes.func.isRequired,
};

export default TaskPhotoDialog;
