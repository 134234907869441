import styled from 'styled-components';
import { Flex } from 'grid-styled';
import Checkbox from 'material-ui/Checkbox';

import { ListItemContainer } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';

export const Container = styled(ListItemContainer).attrs({
  secondaryTextLines: 2,
})`
  border-bottom: ${({ islast, theme }) => (islast === 'true' ? 'none' : `1px solid ${theme.primaryGrey} !important`)};
  background-color: ${({ theme }) => theme.primaryWhite} !important;
  z-index: ${({ index }) => index} !important;

  & > div > div {
    padding: 0 !important;

    & > div:last-child {
      padding: 0 !important;
    }
  }
`;

export const CheckBox = styled(Checkbox)`
  top: calc(50% - 12px) !important;
`;

export const ItemName = styled(Flex).attrs({})`
  font-weight: 500 !important;
  padding-left: ${({ withDelete }) => (!withDelete ? '0px' : '30px')};
`;

export const ItemText = styled(Flex).attrs({
  pl: [1, 2],
})`
  margin: 0px !important;
  height: auto !important;
`;

export const InfoBlock = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
`;

export const TextInfoBlock = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
`;

export const ButtonsBlock = styled(Flex)`
  justify-content: flex-end;
  padding-left: 15px;
  max-width: 40%;
`;

export const Line = styled(Flex)`
  width: 100%;
`;

export const TextBlock = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  padding: 16px 0;
  min-width: 0;
  word-wrap: break-word;
  word-break: break-all;
`;

export const Button = styled(ButtonIcon).attrs({
  tooltipStyles: {
    right: '40px',
    top: '10px',
    wordBreak: 'keep-all',
  },
})``;
