import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding-right: 100px;
`;

export const Cell = styled.div`
  flex-direction: column;
  flex: 1;
  cursor: pointer;
`;

export const Text = styled.p`
  align-self: flex-start;
  margin: 0;
  padding: 16px;
`;

export const ItemValue = styled.span`
  font-weight: bold;
`;
