export const tableConfigs = [
  { field: 'RolesIcon' },
  { field: 'PublishedName' },
  { field: 'Notes' },
  { field: 'ContentQty' },
];

export const tableHeads = [
  { title: 'Roles' },
  { title: 'Published Page Name' },
  { title: 'Notes' },
  { title: 'Content Qty', colSpan: 2 },
];
