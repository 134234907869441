import styled from 'styled-components';

export const SignUpContainer = styled.div`
  width: fill-available;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 22px;
  margin: 0 1px;
  background-color: ${({ theme }) => theme.signUpColor};
  z-index: ${({ authMode }) => (authMode === 'signup' ? 1 : 0)};
  border-radius: 15px;
  position: absolute;
  top: 192px;
`;

export const SignUpTitle = styled.h1`
  color: ${({ theme }) => theme.primaryWhite};
  text-shadow: ${({ theme }) => `-2px 2px 2px ${theme.primaryBlack}`};
  font-size: 20px;
  font-weight: bold;
  margin: 8px 0;
`;

export const AccountsInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const AccountsDataContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;
`;

export const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccountDataSquare = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 10px;
  border: ${({ theme }) => `2px solid ${theme.primaryGrey}`};
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 16px;
`;

export const AccountDataLabel = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-top: 10px;
  text-align: center;
`;

export const AccountInfoTextContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const AccountInfoText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primaryWhite};
  margin-bottom: 5px;
`;

export const FormLabel = styled.h2`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 6px 0;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: fill-available;
`;

export const FieldContainer = styled.div`
  position: relative;
  width: fill-available;
`;

export const NextButtonContainer = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: ${({ theme }) => `3px solid ${theme.signUpColor}`};
  background: none;
  position: absolute;
  top: 4px;
  right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.signUpColor};
  font-size: 20px;
`;
