import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as exchangeActions } from 'redux/exchange';

import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import Subheader from 'components/Subheader';
import List from './List';
import { BackIcon } from 'components/Layout/Icons';
import NotesDialog from './NotesDialog';

import { Container, Toolbar, TextBlock, VersionTextBlock, Line } from './styled';

class UnitVersionsList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    requestListExchangeVersions: PropTypes.func.isRequired,
    requestDeleteLastVersion: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    exchangeVersionsList: PropTypes.array.isRequired,
    requestEditNotes: PropTypes.func.isRequired,
  };

  state = {
    listVersionsHeader: ['Submitted by:', 'Date added:', 'Exchange Notes'],
    notesDialogOpened: false,
    itemId: '',
    itemNotes: null,
  };

  componentDidMount() {
    this.props.requestListExchangeVersions({ originalId: this.props.match.params.originalId });
  }

  onRowClick = (id, version) => {
    this.props.history.push(`/exchange/${id}/version/${version}`);
  };

  onBackToUnits = () => {
    this.props.history.push('/exchange');
  };

  onDeleteVersion = (id, index) => {
    if (!index) {
      this.props.openConfirmationDialog(
        'Are you sure want to delete this List',
        () => this.props.requestDeleteLastVersion(id),
        'Alert',
      );
    } else {
      this.props.openErrorDialog('This cannot be deleted newer version(s) are available', 'Error');
    }
  };

  onEditNotes = text => {
    this.props.requestEditNotes({
      id: this.state.itemId,
      ExchangeNotes: text.ExchangeNotes,
    });
    this.closeNotesDialog();
  };

  closeNotesDialog = () => {
    this.setState({ notesDialogOpened: false, itemId: '', itemNotes: null });
  };

  openNotesDialog = (id, notes) => {
    this.setState({ notesDialogOpened: true, itemId: id, itemNotes: { ExchangeNotes: notes } });
  };

  render() {
    const { exchangeVersionsList } = this.props;
    const { listVersionsHeader, notesDialogOpened, itemNotes } = this.state;

    return [
      <Container key="Versions list">
        <Subheader
          title="List Templates"
          centeredText={exchangeVersionsList[0]?.TenantName}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackToUnits,
              hint: 'Back',
            },
          ]}
        />
        <Toolbar style={{ padding: 0 }}>
          <VersionTextBlock>Version:</VersionTextBlock>
          <Line>
            {listVersionsHeader.map(title => (
              <TextBlock>{title}</TextBlock>
            ))}
            <TextBlock />
          </Line>
        </Toolbar>
        <List
          data={exchangeVersionsList}
          onItemClick={this.onRowClick}
          onDeleteVersion={this.onDeleteVersion}
          openModal={this.openNotesDialog}
        />
        ,
      </Container>,
      <NotesDialog
        key="NotesDialog"
        handleClose={this.closeNotesDialog}
        title="List Note"
        open={notesDialogOpened}
        notes={itemNotes}
        onSubmit={this.onEditNotes}
      />,
    ];
  }
}

const mapStateToProps = ({ exchange }) => ({
  exchangeVersionsList: exchange.exchangeVersionsList,
});

const mapDispatchToProps = {
  requestListExchangeVersions: exchangeActions.listExchangeVersionsRequest,
  requestDeleteLastVersion: exchangeActions.deleteLastVersionRequest,
  requestEditNotes: exchangeActions.editNotesRequest,
  openConfirmationDialog,
  openErrorDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitVersionsList);
