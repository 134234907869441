import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from 'components/Dialogs/Base';

import * as S from './styled';

import PartsList from './Parts';

const styles = {
  modal: {
    maxWidth: '500px',
  },
};

const DownloadPartsListDialog = ({ open, onRequestClose }) => (
  <BaseDialog open={open} title="Download Parts List" modalStyles={styles.modal} onRequestClose={onRequestClose}>
    <S.Container>
      <PartsList />
    </S.Container>
  </BaseDialog>
);

DownloadPartsListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default DownloadPartsListDialog;
