import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  changePartOption,
  createPartOption,
  fetchOptions,
  fetchSingleOptionsType,
  removePartOption,
} from 'http/partOptions';
import { formatServerError } from 'helpers';

import { actions } from './index';

const valuesType = {
  category: 'partCategoryDto',
  unitOfMeasure: 'partUnitOfMeasureDto',
  weightStandard: 'partWeightStandardDto',
  type: 'partTypeDto',
  sizingStandard: 'partSizingStandardDto',
  materialType: 'partMaterialTypeDto',
};

const urlTypes = {
  category: {
    list: 'ListPartCategories',
    create: 'CreatePartCategory',
    edit: 'EditPartCategory',
    delete: 'DeletePartCategory',
  },
  unitOfMeasure: {
    list: 'ListPartUnitOfMeasures',
    create: 'CreatePartUnitOfMeasure',
    edit: 'EditPartUnitOfMeasure',
    delete: 'DeletePartUnitOfMeasure',
  },
  weightStandard: {
    list: 'ListPartWeightStandards',
    create: 'CreatePartWeightStandard',
    edit: 'EditPartWeightStandard',
    delete: 'DeletePartWeightStandard',
  },
  type: {
    list: 'ListPartTypes',
    create: 'CreatePartType',
    edit: 'EditPartType',
    delete: 'DeletePartType',
  },
  sizingStandard: {
    list: 'ListPartSizingStandards',
    create: 'CreatePartSizingStandard',
    edit: 'EditPartSizingStandard',
    delete: 'DeletePartSizingStandard',
  },
  materialType: {
    list: 'ListPartMaterialTypes',
    create: 'CreatePartMaterialType',
    edit: 'EditPartMaterialType',
    delete: 'DeletePartMaterialType',
  },
};

function* getOptions() {
  const requests = Object.values(urlTypes).map(url => call(fetchOptions, url));
  try {
    const [category, unitOfMeasure, weightStandard, type, sizingStandard, materialType] = yield all(requests);
    const data = {
      category: category.data.root,
      unitOfMeasure: unitOfMeasure.data.root,
      weightStandard: weightStandard.data.root,
      type: type.data.root,
      sizingStandard: sizingStandard.data.root,
      materialType: materialType.data.root,
    };
    yield put(actions.getPartOptionsSuccess(data));
  } catch (error) {
    yield put(
      actions.getPartOptionsFailure(formatServerError(error, 'An error occurred while fetching part options.')),
    );
  }
}

function* getSingleOptionsType({ payload }) {
  try {
    const { data } = yield call(fetchSingleOptionsType, urlTypes, payload);
    yield put(actions.getSinglePartOptionsTypeSuccess({ type: payload, options: data.root }));
  } catch (error) {
    yield put(
      actions.getSinglePartOptionsTypeFailure(
        formatServerError(error, 'An error occurred while fetching part options.'),
      ),
    );
  }
}

function* addPartOption({ payload }) {
  const { optionType, values } = payload;
  try {
    yield call(createPartOption, urlTypes, optionType, values, valuesType);
    yield* getSingleOptionsType({ payload: optionType });
    yield put(actions.addPartOptionSuccess());
  } catch (error) {
    yield put(actions.addPartOptionFailure(formatServerError(error, 'An error occurred while adding part option.')));
  }
}

function* editPartOption({ payload }) {
  const { optionType, values } = payload;
  try {
    yield call(changePartOption, urlTypes, optionType, values, valuesType);
    yield* getSingleOptionsType({ payload: optionType });
    yield put(actions.editPartOptionSuccess());
  } catch (error) {
    yield put(actions.editPartOptionFailure(formatServerError(error, 'An error occurred while editing part option.')));
  }
}

function* deletePartOption({ payload }) {
  const { optionType, values } = payload;
  try {
    yield call(removePartOption, urlTypes, optionType, values);
    yield* getSingleOptionsType({ payload: optionType });
    yield put(actions.deletePartOptionSuccess());
  } catch (error) {
    yield put(
      actions.deletePartOptionFailure(formatServerError(error, 'An error occurred while deleting part option.')),
    );
  }
}

const partOptionsSagas = [
  takeEvery(actions.getPartOptionsRequest, getOptions),
  takeEvery(actions.getSinglePartOptionsTypeRequest, getSingleOptionsType),
  takeEvery(actions.addPartOptionRequest, addPartOption),
  takeEvery(actions.editPartOptionRequest, editPartOption),
  takeEvery(actions.deletePartOptionRequest, deletePartOption),
];

export default partOptionsSagas;
