import styled from 'styled-components';
import { BorderRadiusContainer } from 'components/Layout/Containers';

export const FormContainer = styled.div`
  position: relative;
`;

export const Instructions = styled(BorderRadiusContainer)`
  border-radius: 0;
`;

export const InstructionsLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  background: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-weight: 500;
`;

export const SpecialistTitle = styled.div``;

export const InputContainer = styled.div`
  padding: 25px 20px 0 20px;
`;
