import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    & > div:first-child {
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }
`;
