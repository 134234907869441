import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as tasksActions } from 'redux/tasks';
import { getSelectedSite } from 'helpers/storage';
import { TaskNotesPhotos } from 'configs/propTypes';

import NotesForm from './NotesForm';
import withInputModal from 'components/Dialogs/withInputModal';

const NotesWithInput = withInputModal(NotesForm);

class NotesModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    viewPhotos: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    requestUploadPhoto: PropTypes.func.isRequired,
    requestTaskNotes: PropTypes.func.isRequired,
    requestSaveNote: PropTypes.func.isRequired,
    notesPhotos: TaskNotesPhotos.isRequired,
    parentId: PropTypes.string.isRequired,
    comment: PropTypes.string,
  };

  static defaultProps = {
    comment: '',
  };

  state = {
    siteId: getSelectedSite(),
  };

  componentDidMount() {
    this.props.requestTaskNotes(this.props.parentId);
  }

  onUpload = event => {
    this.props.requestUploadPhoto({
      file: event.target.files,
      parentId: this.props.parentId,
    });
  };

  onSubmit = values => {
    this.props.requestSaveNote({
      comments: values.comments,
      parentId: this.props.parentId,
      onSuccess: this.props.handleClose,
    });
  };

  render() {
    const { comment, notesPhotos } = this.props;
    const { siteId } = this.state;

    return (
      <NotesWithInput
        title="Notes"
        onSubmit={this.onSubmit}
        onUpload={this.onUpload}
        siteId={siteId}
        notesPhotos={notesPhotos}
        initialValues={{ comments: comment }}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ tasks }) => ({
  notesPhotos: tasks.taskNotes,
});

export default connect(mapStateToProps, {
  requestTaskNotes: tasksActions.fetchNotesRequest,
  requestUploadPhoto: tasksActions.uploadPhotoTasksRequest,
  requestSaveNote: tasksActions.saveNoteRequest,
})(NotesModal);
