import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Swiper from 'react-id-swiper';

import { TaskNotesPhotos } from 'configs/propTypes';

import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { ImageIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import {
  BorderRadiusBlock,
  ColoredTitle,
  Container,
  Content,
  ImageContainer,
  InputContainer,
  UnloadImageRow,
} from './styled';

const params = {
  grabCursor: true,
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets',
  },
  rebuildOnUpdate: true,
  shouldSwiperUpdate: true,
};

const NotesForm = ({ notesPhotos, parentId, siteId, onUpload, viewPhotos }) => (
  <Container>
    <BorderRadiusBlock>
      <ColoredTitle>User notes</ColoredTitle>
      <InputContainer>
        <Field
          component={TextFieldAdapter}
          type="text"
          placeholder="Enter comments here..."
          name="comments"
          multiLine
          rowsMax={4}
        />
      </InputContainer>
    </BorderRadiusBlock>
    <Content>
      {notesPhotos && notesPhotos.length ? (
        <Swiper {...params}>
          {notesPhotos.map((photo, index) => (
            <ImageContainer key={index}>
              <img alt={photo.Name} src={`${photo.src}?parentId=${parentId}&inline=true&siteId=${siteId}`} />
            </ImageContainer>
          ))}
        </Swiper>
      ) : (
        <div>This task has no confirmation photos.</div>
      )}
    </Content>
    <UnloadImageRow>
      <input type="file" onChange={onUpload} />
      <ButtonIcon onClick={viewPhotos}>
        <ImageIcon />
      </ButtonIcon>
    </UnloadImageRow>
  </Container>
);

NotesForm.propTypes = {
  notesPhotos: TaskNotesPhotos.isRequired,
  parentId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  viewPhotos: PropTypes.func.isRequired,
};

export default NotesForm;
