import http from '../index';

export const assignTeamMembers = ({ teamId, userIds, SiteID }) =>
  http.post(`Team/AssignTeamMembers?siteId=${SiteID}`, { teamId, userIds });

export const fetchMembersForAssigment = dto => http.post('Team/ListForAssignment', dto);

export const fetchTeamMembers = dto => http.post('Team/ListTeamMembers', dto);

export const fetchTeams = dto => http.post('Team/List', dto);

export const fetchSiteManagers = SiteID => http.post('/SiteUserManagement/List', `filter.SiteId=${SiteID}`);

export const addTeam = team => http.post('Team/Post', { team });

export const putTeam = (team, ignoreSecurityRestriction) => http.post('Team/Put', { team, ignoreSecurityRestriction });

export const removeTeam = payload => http.post('Team/Delete', { teams: [payload] });

export const removeTeamMember = dto => http.post('Team/RemoveTeamMembers', dto);

export const fetchTeamListBySite = () => http.post('Team/ListBySite');

export const fetchFilteredTeamMembers = (teamId, filter) =>
  http.post('Team/ListFilteredTeamMembers', { teamId, filter });

export const teamUnassignedManager = (personId, type) =>
  http.post('Team/UnassignManager', `id=${personId}&additionalAction=${type}`);

export const fetchManagerStatus = personId =>
  http.post('Team/GetManagerStatus', `id=${personId}`).then(({ data: { entity } }) => {
    const KEYS = ['TeamLeadCount', 'TeamTaskCount'];
    const totalCount = KEYS.reduce((accum, key) => {
      const count = entity[key];
      // eslint-disable-next-line no-return-assign
      return (accum += count);
    }, 0);

    return {
      ...entity,
      totalCount,
    };
  });
