import React from 'react';
import { PropTypes } from 'prop-types';

import * as S from './styled';

const TestrunButton = ({ onClick, isCentered, disabled }) => (
  <S.ButtonContainer disabled={disabled} onClick={onClick} isCentered={isCentered} type="button">
    <S.IconContainer disabled={disabled}>
      <S.TestRunIcon />
    </S.IconContainer>
    <S.Text>Testrun results</S.Text>
  </S.ButtonContainer>
);

TestrunButton.propTypes = {
  isCentered: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

TestrunButton.defaultProps = {
  isCentered: false,
  disabled: false,
};

export default TestrunButton;
