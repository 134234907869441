import React from 'react';

import * as S from './styled';

const Tooltip = (
  <S.Tooltip>
    <div>
      <S.TooltipHeader>
        <b>QR Settings</b>
      </S.TooltipHeader>
      <S.TooltipFirstPart>Default QR code for </S.TooltipFirstPart>
      <S.TooltipFirstPart> all Units and Items</S.TooltipFirstPart>
    </div>
    <br />
    <div>
      <S.TooltipSecondPart>Can be overwritten by</S.TooltipSecondPart>
      <S.TooltipSecondPart>Unit and Item entry</S.TooltipSecondPart>
    </div>
  </S.Tooltip>
);

export default Tooltip;
