import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  ADD_SITE_REQUEST: undefined,
  ADD_SITE_SUCCESS: undefined,
  ADD_SITE_FAILURE: undefined,

  ASSIGN_SITE_REQUEST: undefined,
  ASSIGN_SITE_FAILURE: undefined,

  ASSIGNED_USERS_REQUEST: undefined,
  ASSIGNED_USERS_SUCCESS: undefined,
  ASSIGNED_USERS_FAILURE: undefined,

  DELETE_SITE_REQUEST: undefined,
  DELETE_SITE_FAILURE: undefined,

  EDIT_SITE_REQUEST: undefined,
  EDIT_SITE_SUCCESS: undefined,
  EDIT_SITE_FAILURE: undefined,

  MULTIPLE_SITE_EDIT_REQUEST: undefined,
  MULTIPLE_SITE_EDIT_SUCCESS: undefined,
  MULTIPLE_SITE_EDIT_FAILURE: undefined,

  SITES_LIST_REQUEST: undefined,
  SITES_LIST_BY_TENANT_REQUEST: undefined,
  SITES_LIST_SUCCESS: undefined,
  SITES_LIST_FAILURE: undefined,

  RESET_SITES_LIST: undefined,
  UPDATE_PAST_DUE_LIMIT: undefined,
  SET_SITES_STATUS: undefined,
  GET_PAST_DUE_LIMIT_REQUEST: undefined,
  GET_PAST_DUE_LIMIT_SUCCESS: undefined,
  GET_PAST_DUE_LIMIT_LIST_REQUEST: undefined,
  GET_PAST_DUE_LIMIT_LIST_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.addSiteRequest, handlers.addSiteRequest],
    [actions.addSiteSuccess, handlers.addSiteSuccess],
    [actions.addSiteFailure, handlers.addSiteFailure],

    [actions.assignSiteRequest, handlers.assignSiteRequest],
    [actions.assignSiteFailure, handlers.assignSiteFailure],

    [actions.assignedUsersRequest, handlers.assignedUsersRequest],
    [actions.assignedUsersSuccess, handlers.assignedUsersSuccess],
    [actions.assignedUsersFailure, handlers.assignedUsersFailure],

    [actions.deleteSiteRequest, handlers.deleteSiteRequest],
    [actions.deleteSiteFailure, handlers.deleteSiteFailure],

    [actions.editSiteRequest, handlers.editSiteRequest],
    [actions.editSiteSuccess, handlers.editSiteSuccess],
    [actions.editSiteFailure, handlers.editSiteFailure],

    [actions.multipleSiteEditRequest, handlers.multipleSiteEditRequest],
    [actions.multipleSiteEditSuccess, handlers.multipleSiteEditSuccess],
    [actions.multipleSiteEditFailure, handlers.multipleSiteEditFailure],

    [actions.sitesListByTenantRequest, handlers.sitesListByTenantRequest],

    [actions.sitesListRequest, handlers.sitesListRequest],
    [actions.sitesListSuccess, handlers.sitesListSuccess],
    [actions.sitesListFailure, handlers.sitesListFailure],

    [actions.resetSitesList, handlers.resetSitesList],
    [actions.updatePastDueLimit, handlers.updatePastDueLimit],
    [actions.setSitesStatus, handlers.setSitesStatus],
    [actions.getPastDueLimitRequest, handlers.getPastDueLimitRequest],
    [actions.getPastDueLimitSuccess, handlers.getPastDueLimitSuccess],
    [actions.getPastDueLimitListRequest, handlers.getPastDueLimitListRequest],
    [actions.getPastDueLimitListSuccess, handlers.getPastDueLimitListSuccess],
  ]),
  initialState,
);

export default reducer;
