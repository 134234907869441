import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonFlat } from 'components/Layout/Buttons';

export const Container = styled(Flex).attrs({
  justify: 'space-between',
  p: 1,
})`
  background-color: ${({ theme }) => theme.lightGrey};
  box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 6px, rgba(0, 0, 0, 0.12) 0px -1px 4px;
  min-height: 52px;
  position: relative;
  z-index: 10;
`;

export const FlatButton = styled(ButtonFlat).attrs({
  primary: true,
})``;
