import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Content = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  background-color: ${({ theme }) => theme.primaryWhite};
  overflow-y: auto;
`;
