import styled from 'styled-components';

import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

export const SearchContainer = styled.div`
  padding-top: 20px;
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin-top: 20px;
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
  margin: 15px 0;
`;

export const ListContainer = styled.div`
  padding: 20px 0 30px;
  overflow: auto;
  max-height: 25vh;
`;

export const Block = styled(BorderRadiusContainer)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.mainLightBlue};
`;

export const BoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;
`;

export const TopInfo = styled.div``;

export const Section = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin: ${({ inside }) => (inside ? '30px 30px' : '30px 16px 0')};
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const SectionHeader = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.primaryWhite};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 15px;
`;

export const SectionBody = styled.div`
  padding: 0 30px 15px;
  overflow: auto;
  max-height: 25vh;
`;

export const Title = styled.div`
  font-size: 22px;
  margin-bottom: 14px;
`;
