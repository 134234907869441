import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_PDF_LIST_REQUEST: undefined,
  GET_PDF_LIST_SUCCESS: undefined,
  GET_PDF_LIST_FAILURE: undefined,

  DELETE_PDF_FILE_REQUEST: undefined,
  DELETE_PDF_FILE_SUCCESS: undefined,
  DELETE_PDF_FILE_FAILURE: undefined,

  CREATE_PDF_FILE_REQUEST: undefined,
  CREATE_PDF_FILE_SUCCESS: undefined,
  CREATE_PDF_FILE_FAILURE: undefined,

  REFRESH: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getPdfListRequest, handlers.getPdfListRequest],
    [actions.getPdfListSuccess, handlers.getPdfListSuccess],
    [actions.getPdfListFailure, handlers.getPdfListFailure],

    [actions.deletePdfFileRequest, handlers.deletePdfFileRequest],
    [actions.deletePdfFileSuccess, handlers.deletePdfFileSuccess],
    [actions.deletePdfFileFailure, handlers.deletePdfFileFailure],

    [actions.createPdfFileRequest, handlers.createPdfFileRequest],
    [actions.createPdfFileSuccess, handlers.createPdfFileSuccess],
    [actions.createPdfFileFailure, handlers.createPdfFileFailure],

    [actions.refresh, handlers.refresh],
  ]),
  initialState,
);

export default reducer;
