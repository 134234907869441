export const tableConfigs = [
  { field: 'Header', title: 'Section Title Header' },
  { field: 'Subtitle', title: 'Section Sub Title' },
  { field: 'FeedType', title: 'Feed Type' },
  { field: 'FeedID', title: 'Feed ID' },
];

export const FEED_TYPE = {
  CHANNEL: 0,
  SHOWCASE: 1,
  GROUP: 2,
  TAG: 4,
};

export const getFeedType = type => {
  let res;

  switch (type) {
    case FEED_TYPE.CHANNEL:
      res = 'Channel';
      break;
    case FEED_TYPE.SHOWCASE:
      res = 'Showcase';
      break;
    case FEED_TYPE.GROUP:
      res = 'Group';
      break;
    case FEED_TYPE.TAG:
      res = 'Tag';
      break;
    default:
      res = 'None';
  }

  return res;
};
