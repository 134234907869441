import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Subheader from 'components/Subheader';
import { BackIcon } from 'components/Layout/Icons';
import { getSchedulerType } from 'helpers/storage';
import * as S from '../styled';

const Navbar = ({ title, history, unitId }) => (
  <Subheader
    title={`Template: ${title}`}
    zIndex={5}
    isSiteOnLeftSide
    rightContent={
      <>
        <S.RedText>Type:</S.RedText>
        &nbsp;<S.Text>{getSchedulerType()} Checklist</S.Text>
      </>
    }
    leftButtons={[
      {
        icon: <BackIcon />,
        handler: () => history.push(`/scheduler/list/${unitId}`),
        hint: 'Back',
      },
    ]}
  />
);

Navbar.propTypes = {
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
  unitId: PropTypes.string.isRequired,
};

Navbar.defaultProps = {
  title: '',
};

export default withRouter(Navbar);
