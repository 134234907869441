import http from '../index';

export const fetchOptionsList = dto => http.post('UserProfile/ListOptions', dto);

export const fetchJobsList = dto => http.post('UserProfile/ListJobFunctions', dto);

export const checkSiteRestrictionsRequest = dto => http.post('SiteManagement/CheckSiteRestrictions', dto);

export const createSiteUsers = dto => http.post('SiteManagement/CreateSiteUsers', dto);

export const createSiteBasicUser = params => http.post('UserManagement/CreateSiteBasicUser', params);
