import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TaskDetailsType } from 'configs/propTypes';
import moment from 'moment';

import { actions as tasksActions } from 'redux/tasks';

import { BackIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import Footer from './Footer';

import PhotosModal from './PhotosModal';
import NotesPhotosModal from './NotesPhotosModal';
import NotesModal from './NotesModal';

import {
  Container,
  Content,
  GrayTitle,
  AttentionToggle,
  BlockTitle,
  Breadcrumbs,
  BorderRadiusBlock,
  ColoredTitle,
  ColoredTitleNoCase,
  TaskDescription,
  TaskNotes,
  TextField,
  Overlay,
  NumericValue,
  RadioGroup,
  RadioField,
  InputContainer,
  ScanCodeVerified,
  BrowseFileButton,
} from './styled';

class TaskDetails extends Component {
  static propTypes = {
    clearTaskDetails: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    rangeStart: PropTypes.instanceOf(moment).isRequired,
    requestTaskDetails: PropTypes.func.isRequired,
    requestAssignTask: PropTypes.func.isRequired,
    requestSaveTask: PropTypes.func.isRequired,
    requestUploadPhoto: PropTypes.func.isRequired,
    requestUploadScanCode: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    userType: PropTypes.number.isRequired,
    task: TaskDetailsType.isRequired,
    scanCodeLoading: PropTypes.bool.isRequired,
    lastScanCode: PropTypes.shape({
      filename: PropTypes.string,
      success: PropTypes.bool,
    }),
    scanCodeError: PropTypes.object.isRequired,
  };

  static defaultProps = {
    lastScanCode: {
      filename: '',
      success: false,
    },
  };

  state = {
    taskId: this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1],
    ruleType: null,
    isRequiredAttention: this.props.task.Status === 'AttentionRequired',
    isPhotosModalVisible: false,
    isNotesModalVisible: false,
    FeedbackResponse: '',
    Exceptions: '',
    Verification: '',
    ActualFlow: '0',
    ActualQty: '',
    ActualTemperature: '',
    lastScanCodeState: {},
  };

  componentDidMount() {
    this.props.requestTaskDetails({ taskId: this.state.taskId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.task && nextProps.task.RuleData && nextProps.task.Id !== this.props.task.Id) {
      this.setState({
        ActualFlow: nextProps.task.RuleData.ActualFlow,
        ActualQty: nextProps.task.RuleData.ActualQty,
        ActualTemperature: nextProps.task.RuleData.ActualTemperature,
        Exceptions: nextProps.task.Status === 'AttentionRequired' ? nextProps.task.Exceptions : '',
        FeedbackResponse: nextProps.task.RuleData.FeedbackResponse || '',
        isRequiredAttention: nextProps.task.Status === 'AttentionRequired',
        ruleType: nextProps.task.RuleType,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearTaskDetails();
  }

  onPrev = () => {
    const { id } = this.props.match.params;
    const { history, rangeStart } = this.props;

    // should be refactored
    this.props.requestAssignTask({
      taskId: this.state.taskId,
      goToNext: nextId => {
        this.setState({ taskId: nextId }, () => {
          history.push(`./${nextId}`);
          this.props.requestTaskDetails({ taskId: nextId });
        });
      },
      goBack: () => history.push(`/tasks/${id}/${rangeStart.format('YYYY-MM-DD')}`),
    });
  };

  onNext = () => {
    const taskId = this.props.match.params.id;
    const { history, rangeStart } = this.props;

    const id = this.props.task.Id;
    const actualFlow = this.state.ActualFlow;
    const actualQty = this.state.ActualQty;
    const actualTemperature = this.state.ActualTemperature;
    const requiresAttention = +this.state.isRequiredAttention;
    const verificationResponse = this.state.VerificationResponse;
    const feedbackResponse = this.state.FeedbackResponse;
    const message = this.state.Exceptions;
    const ruleType = this.state.ruleType;
    const lastScanCodeForm = (this.state.lastScanCodeState || {}).file;

    this.props.requestSaveTask({
      id,
      ruleType,
      actualFlow,
      actualQty,
      actualTemperature,
      requiresAttention,
      message,
      verificationResponse,
      feedbackResponse,
      lastScanCodeForm,
      goToNext: nextId => {
        this.setState({ taskId: nextId }, () => {
          this.clearFileInput();
          history.push(`./${nextId}`);
          this.props.requestTaskDetails({ taskId: nextId });
        });
      },
      goBack: () => history.push(`/tasks/${taskId}/${rangeStart.format('YYYY-MM-DD')}`),
    });
  };

  onToggle = () => {
    this.setState(({ isRequiredAttention }) => ({ isRequiredAttention: !isRequiredAttention }));
  };

  registerFileInputRef = ref => {
    this.fileInputRef = ref;
  };

  clearFileInput = () => {
    if (this.fileInputRef) {
      this.fileInputRef.value = '';
    }
  };

  fileInputRef = null;

  handleFeedback = event => {
    this.setState({ FeedbackResponse: event.target.value });
  };

  handleCheckQuantity = event => {
    this.setState({ ActualQty: event.target.value });
  };

  handleVerification = event => {
    this.setState({ VerificationResponse: !!+event.target.value });
  };

  handleTemperature = event => {
    this.setState({ ActualTemperature: event.target.value });
  };

  handleFlowRate = event => {
    this.setState({ ActualFlow: event.target.value });
  };

  handleExceptions = event => {
    this.setState({ Exceptions: event.target.value });
  };

  handlePhoto = event => {
    if (!event.target.files.length) {
      return;
    }

    this.props.requestUploadPhoto({ file: event.target.files, parentId: this.state.taskId });
  };

  handleScanCode = event => {
    if (!event.target.files.length) {
      return;
    }
    this.setState({
      lastScanCodeState: {
        file: event.target.files[0],
        parentId: this.state.taskId,
        success: true,
      },
    });

    this.props.requestUploadScanCode({ file: event.target.files, parentId: this.state.taskId });
  };

  viewPhotos = () => {
    this.setState({ isPhotosModalVisible: true });
  };

  viewNotesPhotos = () => {
    this.setState({ isNotesPhotosModalVisible: true });
  };

  closeNotesPhotosModals = () => {
    this.setState({ isNotesPhotosModalVisible: false });
  };

  closePhotosModals = () => {
    this.setState({ isPhotosModalVisible: false });
  };

  closeNotesModals = () => {
    this.setState({ isNotesModalVisible: false });
  };

  viewNotes = () => {
    this.setState({ isNotesModalVisible: true });
  };

  back = () => {
    const { history, rangeStart } = this.props;
    const urlTaskId = this.props.match.params.id;

    history.push(`/tasks/${urlTaskId}/${rangeStart.format('YYYY-MM-DD')}`);
  };

  render() {
    const { task, lastScanCode, scanCodeError, userType, scanCodeLoading } = this.props;
    const {
      FeedbackResponse,
      isRequiredAttention,
      isNotesModalVisible,
      Exceptions,
      isPhotosModalVisible,
      isNotesPhotosModalVisible,
      ActualFlow,
      ActualQty,
      ActualTemperature,
      lastScanCodeState,
    } = this.state;

    return [
      <Container key="Task details">
        <Subheader
          title={task.ExecutionDate}
          isSiteOnLeftSide
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.back,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              title: 'View Photos...',
              handler: this.viewPhotos,
            },
            {
              title: 'View notes...',
              handler: this.viewNotes,
            },
          ]}
        />
        <Content>
          <TaskDescription>
            <BlockTitle>{task.OriginatingTaskName || task.RuleType}</BlockTitle>
            <Breadcrumbs>
              {task.UnitName} {' > '} {task.ItemName} {' > '} {task.OriginatingChecklistName}
            </Breadcrumbs>
          </TaskDescription>
          <TaskNotes>
            <BlockTitle>Task notes</BlockTitle>
            <div>{task.InstructionalNotes}</div>
          </TaskNotes>

          {task.RuleType && (
            <div>
              {task.RuleType === 'Feedback' && (
                <BorderRadiusBlock>
                  <ColoredTitle>{task.RuleData.FeedbackQuestion}</ColoredTitle>
                  <GrayTitle>Response</GrayTitle>
                  <InputContainer>
                    <TextField
                      placeholder="Provide feedback here"
                      name="Feedback"
                      onChange={this.handleFeedback}
                      value={FeedbackResponse}
                    />
                  </InputContainer>
                </BorderRadiusBlock>
              )}

              {task.RuleType === 'Check Temperature' && (
                <div>
                  <BorderRadiusBlock>
                    <GrayTitle>Min</GrayTitle>
                    <NumericValue>{task.RuleData.MinTemperature}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <GrayTitle>Max</GrayTitle>
                    <NumericValue>{task.RuleData.MaxTemperature}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <ColoredTitle>Actual reading</ColoredTitle>
                    <InputContainer>
                      <TextField
                        placeholder="Provide actual value"
                        name="Feedback"
                        type="number"
                        onChange={this.handleTemperature}
                        value={ActualTemperature}
                      />
                    </InputContainer>
                  </BorderRadiusBlock>
                </div>
              )}

              {task.RuleType === 'Verification' && (
                <BorderRadiusBlock>
                  <ColoredTitleNoCase>{task.RuleData.VerificationSpecification}</ColoredTitleNoCase>
                  <RadioGroup name="verification" onChange={this.handleVerification}>
                    <RadioField value={1} label="Yes" />
                    <RadioField value={0} label="No" />
                  </RadioGroup>
                </BorderRadiusBlock>
              )}

              {task.RuleType === 'Check Flow Rate' && (
                <div>
                  <BorderRadiusBlock>
                    <GrayTitle>Min</GrayTitle>
                    <NumericValue>{task.RuleData.MinFlow}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <GrayTitle>Max</GrayTitle>
                    <NumericValue>{task.RuleData.MaxFlow}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <ColoredTitle>Actual reading</ColoredTitle>
                    <InputContainer>
                      <TextField
                        placeholder="Provide actual value"
                        name="Feedback"
                        type="number"
                        onChange={this.handleFlowRate}
                        value={ActualFlow}
                      />
                    </InputContainer>
                  </BorderRadiusBlock>
                </div>
              )}

              {task.RuleType === 'Check Quantity' && (
                <div>
                  <BorderRadiusBlock>
                    <GrayTitle>Min</GrayTitle>
                    <NumericValue>{task.RuleData.MinQty}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <GrayTitle>Max</GrayTitle>
                    <NumericValue>{task.RuleData.MaxQty}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <ColoredTitle>Actual reading</ColoredTitle>
                    <InputContainer>
                      <TextField
                        placeholder="Provide actual value"
                        name="Feedback"
                        type="number"
                        onChange={this.handleCheckQuantity}
                        value={ActualQty}
                      />
                    </InputContainer>
                  </BorderRadiusBlock>
                </div>
              )}

              {task.RuleType === 'Photo Confirmation' && (
                <div>
                  <BorderRadiusBlock>
                    <GrayTitle>Min photos</GrayTitle>
                    <NumericValue>{task.RuleData.MinPhotos}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <GrayTitle>Max photos</GrayTitle>
                    <NumericValue>{task.RuleData.MaxPhotos}</NumericValue>
                    <Overlay />
                  </BorderRadiusBlock>
                  <BorderRadiusBlock>
                    <ColoredTitle>Photo</ColoredTitle>
                    <BrowseFileButton>
                      <input ref={this.registerFileInputRef} type="file" onChange={this.handlePhoto} />
                    </BrowseFileButton>
                  </BorderRadiusBlock>
                </div>
              )}

              {task.RuleType === 'Scan Code' && (
                <div>
                  <BorderRadiusBlock>
                    <ColoredTitle>Scan Code</ColoredTitle>
                    {(lastScanCode.filename || (lastScanCodeState.file || {}).name) && !scanCodeLoading ? (
                      <ScanCodeVerified>
                        {!lastScanCode.success && scanCodeError ? "Can't upload/verify scan code!" : 'Verified!'}
                      </ScanCodeVerified>
                    ) : null}
                    <BrowseFileButton>
                      <input type="file" onChange={this.handleScanCode} />
                    </BrowseFileButton>
                  </BorderRadiusBlock>
                </div>
              )}

              <BorderRadiusBlock>
                <ColoredTitle>Requires Attention</ColoredTitle>
                <AttentionToggle toggled={isRequiredAttention} onToggle={this.onToggle} />
              </BorderRadiusBlock>
              {isRequiredAttention && (
                <BorderRadiusBlock>
                  <ColoredTitle>Please explain the issue</ColoredTitle>
                  <InputContainer>
                    <TextField
                      placeholder="Explain the issue"
                      name="Issue"
                      multiLine
                      onChange={this.handleExceptions}
                      value={Exceptions}
                    />
                  </InputContainer>
                </BorderRadiusBlock>
              )}
            </div>
          )}
        </Content>
        {isPhotosModalVisible && (
          <PhotosModal open={isPhotosModalVisible} handleClose={this.closePhotosModals} parentId={task.ItemId} />
        )}
        {isNotesModalVisible && (
          <NotesModal
            open={isNotesModalVisible}
            viewPhotos={this.viewNotesPhotos}
            handleClose={this.closeNotesModals}
            parentId={task.Id}
            comment={task.UserComments}
            lastScanCode={this.state.lastScanCodeState}
          />
        )}
        {isNotesPhotosModalVisible && (
          <NotesPhotosModal
            open={isNotesPhotosModalVisible}
            handleClose={this.closeNotesPhotosModals}
            parentId={task.Id}
          />
        )}
      </Container>,
      <Footer
        isDisabled={task.Status === 'Completed'}
        key="Task details footer"
        userType={userType}
        isAssigned={!!task.AssigneeId}
        onPrev={this.onPrev}
        onNext={this.onNext}
      />,
    ];
  }
}

const mapStateToProps = ({ auth: { user }, tasks }) => ({
  lastScanCode: tasks.lastScanCode,
  scanCodeLoading: tasks.scanCodeLoading,
  rangeStart: tasks.rangeStart,
  task: tasks.taskDetails,
  userType: user.userType,
  scanCodeError: tasks.error,
});

const mapDispatchToProps = {
  clearTaskDetails: tasksActions.clearTaskDetails,
  requestTaskDetails: tasksActions.fetchDetailsRequest,
  requestSaveTask: tasksActions.saveTaskRequest,
  requestAssignTask: tasksActions.assignTaskRequest,
  requestUploadPhoto: tasksActions.uploadPhotoTasksRequest,
  requestUploadScanCode: tasksActions.uploadScanCodeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
