import moment from 'moment';

export const validator = values => {
  const errors = {};

  if (!values.StartDate) {
    errors.StartDate = 'Required';
  }

  if (!values.EndDate) {
    errors.EndDate = 'Required';
  }

  if (
    moment(values.EndDate).isBefore(moment(new Date())) ||
    moment(values.EndDate).isBefore(moment(values.currentEndDate))
  ) {
    errors.EndDate = 'New End date can not be changed to a date earlier than current End date';
  }

  if (moment(values.EndDate).isSameOrBefore(moment(values.StartDate))) {
    errors.EndDate = 'New End date can not be changed to a date earlier than Start date';
  }

  return errors;
};

export default validator;
