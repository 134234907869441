import styled from 'styled-components';

export const DialogContainer = styled.div`
  padding: 40px 136px 0;
  text-align: center;
`;

export const SectionContainer = styled.div`
  margin-bottom: 30px;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 17px;
  font-weight: 300;
  color: ${({ theme }) => theme.modalsContentColor};
`;

export const Title = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const BoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
`;
