import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const styles = {
  radioButton: {
    padding: '5px 0px',
    display: 'flex',
  },
};

const RadioGroup = ({ input, meta, style, name, items, radioButtonStyle, labelStyle }) => (
  <RadioButtonGroup
    name={name}
    valueSelected={input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    {items.map(item => (
      <RadioButton
        value={item.value}
        label={item.name}
        iconStyle={{
          fill: input.value === item.value ? theme.primaryScheduler : theme.darkGrey,
          marginRight: '5px',
          display: 'flex',
        }}
        labelStyle={{ textAlign: 'left', ...labelStyle }}
        style={{ ...styles.radioButton, ...radioButtonStyle }}
      />
    ))}
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  labelStyle: PropTypes.object.isRequired,
  radioButtonStyle: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default RadioGroup;
