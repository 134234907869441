import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getPeriod } from '../../modules/settings/SettingsList/PastDueBacklogDialog/constants';

import * as CS from 'components/Dialogs/styled';

import * as S from './styled';

class CheckboxList extends Component {
  static propTypes = {
    checkedSites: PropTypes.arrayOf(PropTypes.string),
    secondaryTitle: PropTypes.string,
    displayValue: PropTypes.oneOf(['notifications', 'offset', 'notificationTime', 'pastDueLimit']).isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        SiteId: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    clearCheckedSites: PropTypes.func.isRequired,
    fillCheckedSites: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checkedSites: [],
    secondaryTitle: '',
  };

  onItemCheck = (Id, checked) => {
    const { checkedSites, fillCheckedSites } = this.props;

    if (checked) {
      fillCheckedSites([...checkedSites, Id]);
    } else {
      const updatedSites = checkedSites.filter(siteId => siteId !== Id);
      fillCheckedSites(updatedSites);
    }
  };

  onAllItemsCheck = (_, checked) => {
    const { list, fillCheckedSites, clearCheckedSites } = this.props;

    const allSitesIds = list.map(({ SiteId }) => SiteId);

    if (checked) {
      fillCheckedSites(allSitesIds);
    } else {
      clearCheckedSites();
    }
  };

  onCheckboxClick = e => {
    e.stopPropagation();
  };

  handleOnItemClick = settings => {
    const { displayValue, onItemClick } = this.props;
    const {
      SiteId,
      Name,
      offset,
      NotificationIoTFrequency,
      TimeZoneIanaId,
      Hour,
      Minute,
      Period,
      PastDueLimit,
      PastDueLimitPeriodType,
      Id,
    } = settings;

    const commonSettings = { SiteId, Name };

    if (displayValue === 'notifications') {
      onItemClick({ ...commonSettings, NotificationIoTFrequency });
    }
    if (displayValue === 'offset') {
      onItemClick({ ...commonSettings, TimeZoneIanaId, offset });
    }
    if (displayValue === 'notificationTime') {
      onItemClick({ ...commonSettings, notificationTime: { Hour, Minute, Period } });
    }
    if (displayValue === 'pastDueLimit') {
      onItemClick({ ...commonSettings, PastDueLimit, PastDueLimitPeriodType, Id });
    }
  };

  render() {
    const { checkedSites, displayValue, list, secondaryTitle } = this.props;

    return (
      <>
        <S.ActivateLabel>Activate</S.ActivateLabel>
        <S.SelectCheckobesContainer>
          <S.ItemNameAndCheckbox>
            <CS.ItemCheckbox onCheck={this.onAllItemsCheck} />
            <S.SelectCheckobesLabel>Select/Deselect All</S.SelectCheckobesLabel>
          </S.ItemNameAndCheckbox>
          {secondaryTitle && <S.Text>{secondaryTitle}</S.Text>}
        </S.SelectCheckobesContainer>
        <CS.DividerLine />
        <S.ListContainer>
          {list.length > 0 &&
            list.map(
              ({
                SiteId,
                Name,
                offset,
                NotificationIoTFrequency,
                TimeZoneIanaId,
                Hour,
                Minute,
                Period,
                PastDueLimit,
                PastDueLimitPeriodType,
                Id,
              }) => (
                <div key={SiteId}>
                  <S.ListItem
                    onClick={() =>
                      this.handleOnItemClick({
                        SiteId,
                        Name,
                        offset,
                        NotificationIoTFrequency,
                        TimeZoneIanaId,
                        Hour,
                        Minute,
                        Period,
                        PastDueLimit,
                        PastDueLimitPeriodType,
                        Id,
                      })
                    }
                  >
                    <S.ItemNameAndCheckbox>
                      <CS.ItemCheckbox
                        checked={checkedSites.includes(SiteId)}
                        onCheck={(e, checked) => this.onItemCheck(SiteId, checked)}
                        onClick={this.onCheckboxClick}
                      />
                      <CS.ItemName>{Name}</CS.ItemName>
                    </S.ItemNameAndCheckbox>
                    {displayValue === 'offset' && offset && <S.ItemValue>{`UTC ${offset}`}</S.ItemValue>}
                    {displayValue === 'notifications' &&
                      !!NotificationIoTFrequency.toString().length &&
                      NotificationIoTFrequency !== 1 && (
                        <S.ItemValue>
                          {NotificationIoTFrequency === 0 ? 'Instantly' : NotificationIoTFrequency}
                          <S.ValueIcon />
                        </S.ItemValue>
                      )}
                    {displayValue === 'notificationTime' && Hour && Minute && Period && (
                      <S.ItemValue>
                        {`${Hour}:${Minute.length === 1 ? `0${Minute}` : Minute} ${Period.toLowerCase()}`}
                      </S.ItemValue>
                    )}
                    {displayValue === 'pastDueLimit' && (
                      <S.ItemValue>{getPeriod(PastDueLimitPeriodType, PastDueLimit)}</S.ItemValue>
                    )}
                  </S.ListItem>
                  <CS.DividerLine />
                </div>
              ),
            )}
        </S.ListContainer>
      </>
    );
  }
}

export default CheckboxList;
