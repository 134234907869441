import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';
import { ContentContainer, ListContainer, ListItemContainer } from 'components/Layout/Containers';

export const Container = styled(ContentContainer)``;

export const List = styled(ListContainer)``;

export const ListItem = styled(ListItemContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey} !important;
`;

export const IconButton = styled(ButtonIcon).attrs({
  iconStyle: ({ theme }) => ({
    color: theme.primaryGrey,
  }),
  tooltipStyles: {
    top: '14px',
    right: '24px',
  },
})`
  padding: 0 !important;
  top: 0 !important;
  height: 24px !important;
`;

export const ListItemText = styled.div`
  color: ${({ theme, isTeam }) => (isTeam ? theme.tiersColor : theme.primaryBlack)};
  color: ${({ theme, isPickUp }) => isPickUp && theme.orange};
  width: fit-content;
  min-width: 143px;
  break-inside: avoid;
`;

export const SecondaryTextContainer = styled.div`
  column-count: 2;
  margin: 0 !important;
  margin-right: 30px !important;
`;
