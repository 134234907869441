import 'react-app-polyfill/ie11';
import 'core-js/es';

import 'react-id-swiper/src/styles/css/swiper.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import './index.css';

import theme from './theme';
import AppRouter from './router';
import configureStore from './redux/configureStore';

const store = configureStore();

ReactDOM.render(
  <MuiThemeProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
