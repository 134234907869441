import React, { useState } from 'react';
import theme from 'theme';
import PropTypes from 'prop-types';
import BaseDialog from 'components/Dialogs/Base';

import { AssetCategoryType, AssetType, UnitType } from 'configs/propTypes';
import AssetTypeDialog from '../AssetTypeDialog';

import * as S from './styled';

const AssetCategoriesDialog = ({
  isOpen,
  onRequestClose,
  assetTypesList,
  assetCategoriesList,
  fetchAssetTypesListRequest,
  unit,
  onChange,
}) => {
  const [isAssetTypeDialogOpen, setIsAssetTypeDialogOpen] = useState(false);

  const openAssetTypeDialog = () => setIsAssetTypeDialogOpen(true);

  const closeAssetTypeDialog = () => setIsAssetTypeDialogOpen(false);

  const onCategoryClick = id => {
    openAssetTypeDialog();
    fetchAssetTypesListRequest(id);
  };

  return (
    <BaseDialog
      open={isOpen}
      title="Asset Category"
      onRequestClose={onRequestClose}
      titleColor={theme.primarySites}
      modalStyles={{ maxWidth: '600px' }}
    >
      <div>
        <S.List>
          {assetCategoriesList?.map(({ Id, Name, Description }) => (
            <S.ListItem key={Id} onClick={() => onCategoryClick(Id)}>
              <S.Item>{Name}</S.Item> {Description}
            </S.ListItem>
          ))}
        </S.List>

        <S.Label>Please select a category</S.Label>
      </div>
      <AssetTypeDialog
        isOpen={isAssetTypeDialogOpen}
        onRequestClose={closeAssetTypeDialog}
        onRequestCloseAssetCategories={onRequestClose}
        assetTypesList={assetTypesList}
        unit={unit}
        onChange={onChange}
      />
    </BaseDialog>
  );
};

AssetCategoriesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  assetTypesList: PropTypes.arrayOf(AssetType).isRequired,
  assetCategoriesList: PropTypes.arrayOf(AssetCategoryType).isRequired,
  fetchAssetTypesListRequest: PropTypes.func.isRequired,
  unit: UnitType.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AssetCategoriesDialog;
