import styled from 'styled-components';
import { Flex } from 'grid-styled';

import Dialog from 'components/Dialogs/Base';

export const Content = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  background: ${({ theme }) => theme.lightGrey};
  height: calc(80vh - 100px);
  overflow: hidden;
`;

export const FullScreenDialog = styled(Dialog)``;

export const ImageContainer = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: calc(80vh - 130px);
  }
`;
