import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PartsListType, PartGroupType } from 'configs/propTypes';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as partsActions } from 'redux/parts';
import { actions as distributionActions } from 'redux/distributions';
import { actions as unitsActions } from 'redux/units';

import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';
import { AddIcon, BackIcon, LinkIcon, UpDownArrowIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import TableList from 'components/TableList';
import PartSelectionDialog from 'components/Dialogs/PartSelectionDialog';
import ConfirmDialog from 'components/Dialogs/Confirm';
import LinkItemsDialog from 'components/Dialogs/LinkItemsDialog';
import ItemPartsViewDialog from 'components/Dialogs/ItemPartsViewDialog';

import { partsToggle } from 'configs/toggles';

import DownloadPartsListDialog from './DownloadPartsListDialog';
import DownloadTemplateDialog from './DownloadTemplateDialog';
import UploadPartsDialog from './UploadPartsDialog';
import NewPartDialog from './NewPartDialog';
import NewGroupDialog from './NewGroupDialog';
import DistributionDialog from './DistributionDialog';
import DistributionPreviewDrawer from './DistributionPreviewDrawer';
import DropdownMenu from './DropdownMenu';
import ConfirmPartRemovalText from './ConfirmPartRemovalText';
import ConfirmGroupRemovalText from './ConfirmGroupRemovalText';
import { tableHeads, tableColumns, addMenuItems, exportMenuItems } from './constants';
import { firstLetterUpperCase, isDistributionFormValid, generateDistributionRules } from './helpers';
import * as S from './styled';
import theme from 'theme';

class Parts extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      getPartsRequest: PropTypes.func.isRequired,
      getPartGroupsRequest: PropTypes.func.isRequired,
      addPartRequest: PropTypes.func.isRequired,
      addPartGroupRequest: PropTypes.func.isRequired,
      addPartGroupLinkRequest: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
      deletePartRequest: PropTypes.func.isRequired,
      getDistributionListPreview: PropTypes.func.isRequired,
      createPartLinksRequest: PropTypes.func.isRequired,
      deletePartGroupRequest: PropTypes.func.isRequired,
      getListUnitsWithItems: PropTypes.func.isRequired,
      createGroupLinksRequest: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.string,
      }).isRequired,
    }).isRequired,
    partsList: PartsListType.isRequired,
    groupsList: PartGroupType.isRequired,
    distributionPreviewList: PropTypes.array.isRequired,
    listUnitsWithItems: PropTypes.array.isRequired,
    selectedSite: PropTypes.string.isRequired,
    sites: PropTypes.array.isRequired,
  };

  state = {
    searchData: '',
    newPartDialogOpen: false,
    newGroupDialogOpen: false,
    partSelectionDialogOpen: false,
    newGroup: {},
    openedExportsDialogs: {},
    confirmDialog: {
      open: false,
      type: '',
      entityToRemove: {},
    },
    distributionDialogOpen: false,
    distributionPreviewDrawerOpen: false,
    distributionRules: {},
    itemForLinking: {},
    linkItemsDialogOpened: false,
    unitToView: null,
    itemToView: null,
    siteSelected: this.props.selectedSite,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getPartsRequest();
    actions.getPartGroupsRequest();
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;

    if (nextProps.error && nextProps.error !== this.props.error) {
      actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  modeHandler = mode => {
    this.setState({ confirmDialog: { open: false, type: '', entityToRemove: {} } }, () => {
      this.props.history.push(`/parts/${mode.key}`);
    });
  };

  onAddPart = (e, value) => this.setState({ [value]: true });

  closeNewPartDialog = () => this.setState({ newPartDialogOpen: false });
  submitNewPart = values => {
    this.props.actions.addPartRequest(values);
    this.closeNewPartDialog();
  };

  setNewGroup = newGroup => this.setState({ newGroup });

  closeNewGroupDialog = () => this.setState({ newGroupDialogOpen: false });
  submitNewGroup = values => {
    this.props.actions.addPartGroupRequest({ values, cb: this.setNewGroup });
    this.closeNewGroupDialog();
    this.props.history.push('/parts/groups');
  };

  closePartsSelectionDialog = () => this.setState({ partSelectionDialogOpen: false });
  openPartsSelectionDialog = () =>
    setTimeout(() => {
      if (this.state.newGroup.Id) {
        this.setState({ partSelectionDialogOpen: true });
        this.closeNewGroupDialog();
      }
    }, 500);

  submitPartSelection = values => {
    const { newGroup } = this.state;

    const partIDs = Object.entries(values)
      .filter(([, value]) => value)
      .map(([key]) => key);

    this.props.actions.addPartGroupLinkRequest({ partGroupDto: newGroup, partIDs });

    this.setState({ newGroup: {} });
    this.closePartsSelectionDialog();
  };

  searchInList = e => this.setState({ searchData: e.target.value });

  handleExportsMenuPressed = (event, value) => {
    this.setState({
      openedExportsDialogs: { ...this.state.openedExportsDialogs, [value]: true },
    });
  };

  handleExportsModalClose = type => () => {
    this.setState({
      openedExportsDialogs: { ...this.state.openedExportsDialogs, [type]: false },
    });
  };

  handlePartRemovePress = part => {
    this.setState({
      confirmDialog: {
        ...this.state.confirmDialog,
        open: true,
        entityToRemove: part,
        type: 'part',
      },
    });
  };

  handleGroupRemovePress = group => {
    this.setState({
      confirmDialog: {
        ...this.state.confirmDialog,
        open: true,
        entityToRemove: group,
        type: 'group',
      },
    });
  };

  handleRemoveReject = () => {
    this.setState({ confirmDialog: { ...this.state.confirmDialog, open: false, entityToRemove: {} } });
  };

  handleRemoveApprove = () => {
    const {
      confirmDialog: { entityToRemove, type },
    } = this.state;

    if (!entityToRemove.Id) {
      return;
    }

    if (type === 'part') {
      this.props.actions.deletePartRequest(entityToRemove.Id);
    } else {
      this.props.actions.deletePartGroupRequest(entityToRemove.Id);
    }

    this.handleRemoveReject();
  };

  handleOpenDistributionDialog = item => {
    this.setState({ distributionDialogOpen: true, itemForLinking: item });
  };

  handleCloseDistributionDialog = () => this.setState({ distributionDialogOpen: false });

  openLinkItemsDialog = siteId => {
    this.props.actions.getListUnitsWithItems(siteId);
    this.setState({ linkItemsDialogOpened: true });
  };
  closeLinkItemsDialog = () => this.setState({ linkItemsDialogOpened: false });

  openItemPartsViewDialog = (unit, item) =>
    this.setState({
      unitToView: unit,
      itemToView: item,
      itemPartsViewDialogOpened: true,
    });
  closeItemPartsViewDialog = () => this.setState({ itemPartsViewDialogOpened: false });

  handleSitesDropdownChange = (e, key, value) => {
    this.openLinkItemsDialog(value);
    this.setState({ siteSelected: value });
  };

  rightButtons = [
    { icon: <LinkIcon />, handler: () => this.openLinkItemsDialog(this.state.siteSelected), hint: 'part links' },
    {
      component: (
        <DropdownMenu
          onChange={this.handleExportsMenuPressed}
          headerText="Export/Import"
          items={exportMenuItems}
          icon={<UpDownArrowIcon />}
        />
      ),
      isComponent: true,
      hint: 'Import/Export',
    },
    {
      component: <DropdownMenu onChange={this.onAddPart} headerText="New" items={addMenuItems} icon={<AddIcon />} />,
      isComponent: true,
      hint: 'Add Part/Group',
    },
  ];

  goBack = () => this.props.history.push('/home');

  goForward = item => {
    const {
      match: {
        params: { type },
      },
    } = this.props;

    if (type === 'groups') {
      this.props.history.push({
        pathname: `/parts/groups/${item.Id}`,
        state: { group: item },
      });
    } else {
      this.props.history.push({ pathname: `/parts/parts/${item.Id}` });
    }
  };

  filterList = item => {
    const {
      match: {
        params: { type },
      },
    } = this.props;

    return tableColumns[type]
      .map(({ field }) => field)
      .some(
        field =>
          item[field] &&
          typeof item[field] === 'string' &&
          item[field].toLowerCase().includes(this.state.searchData.toLowerCase()),
      );
  };

  renderRightControllCell = item => (
    <td>
      <ButtonIcon onClick={() => this.goForward(item)} tooltip="Parts Detail">
        <S.IconForward />
      </ButtonIcon>
    </td>
  );

  openDistributionPreviewDrawer = () => this.setState({ distributionPreviewDrawerOpen: true });
  closeDistributionPreviewDrawer = () => this.setState({ distributionPreviewDrawerOpen: false });

  onSubmitDistributionRules = needOpenDrawer => values => {
    const {
      match: {
        params: { type },
      },
    } = this.props;
    const errors = isDistributionFormValid(values);
    if (errors.length) {
      return this.props.actions.openErrorDialog(
        `Please add at least one rule for ${errors.join(', ')} 
      section${errors.length > 1 ? "'s" : ''} or select "Ignore rules" to disable rules`,
        'Validation Error',
        theme.greenButton,
      );
    }

    const distributionRules = generateDistributionRules(values, {
      [type === 'parts' ? 'PartID' : 'PartGroupID']: this.state.itemForLinking.Id,
    });

    this.props.actions.getDistributionListPreview(distributionRules);

    if (needOpenDrawer) this.openDistributionPreviewDrawer();

    return this.setState({ distributionRules });
  };

  handleLinkParts = () => {
    const {
      distributionPreviewList,
      actions,
      match: {
        params: { type },
      },
    } = this.props;
    const distributionList = this.state.distributionRules;
    const itemIds = [];
    distributionPreviewList.map(unit => {
      unit.Items.map(item => itemIds.push(item.Id));
      return unit;
    });
    const body = {
      partId: this.state.itemForLinking.Id,
      itemIds,
      distributionList,
    };

    if (type === 'groups') {
      body.partGroupId = this.state.itemForLinking.Id;
      body.distributionList.partGroupName = this.state.itemForLinking.Name;
      delete body.partId;
      actions.createGroupLinksRequest(body);
      this.closeDistributionPreviewDrawer();
      this.handleCloseDistributionDialog();
      return;
    }

    actions.createPartLinksRequest(body);
    this.closeDistributionPreviewDrawer();
    this.handleCloseDistributionDialog();
  };

  render() {
    const {
      searchData,
      newPartDialogOpen,
      newGroupDialogOpen,
      partSelectionDialogOpen,
      openedExportsDialogs,
      confirmDialog,
      linkItemsDialogOpened,
      itemPartsViewDialogOpened,
    } = this.state;
    const {
      partsList,
      groupsList,
      distributionPreviewList,
      match: {
        params: { type },
      },
      sites,
    } = this.props;
    const list = type === 'parts' ? partsList : groupsList;
    const sitesList = sites.map(site => ({ value: site.id, label: site.name }));

    return (
      <>
        <Subheader
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.goBack,
              hint: 'Back',
            },
          ]}
          rightButtons={this.rightButtons}
          title={
            <div>
              <h2>Parts Master</h2>
              <Toggle config={partsToggle} selected={type} handler={this.modeHandler} />
            </div>
          }
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
        />
        <S.TableContainer mode={type}>
          <TableList
            tableHeads={tableHeads[type]}
            list={list.filter(this.filterList)}
            tableColumns={tableColumns[type]}
            renderRightControllCell={this.renderRightControllCell}
            handlers={{
              onPartRemove: this.handlePartRemovePress,
              openDistributionDialog: this.handleOpenDistributionDialog,
              onGroupRemove: this.handleGroupRemovePress,
            }}
          />
        </S.TableContainer>
        <NewPartDialog
          open={newPartDialogOpen}
          title="New Part"
          handleClose={this.closeNewPartDialog}
          onSubmit={this.submitNewPart}
          modalStyles={{ maxWidth: '75%' }}
        />
        <NewGroupDialog
          open={newGroupDialogOpen}
          title="New Group"
          handleClose={this.closeNewGroupDialog}
          onSubmit={this.submitNewGroup}
          withSaveContinue
          saveContinueLabel="Save and Add Parts"
          handleSaveContinue={this.openPartsSelectionDialog}
        />
        <PartSelectionDialog
          open={partSelectionDialogOpen}
          title="Select Part"
          submitLabel="Add Selection to Group"
          handleClose={this.closePartsSelectionDialog}
          onSubmit={this.submitPartSelection}
          parts={partsList}
        />
        <DownloadPartsListDialog
          open={openedExportsDialogs.parts}
          onRequestClose={this.handleExportsModalClose('parts')}
        />
        <DownloadTemplateDialog
          open={openedExportsDialogs.partsTemplate}
          onRequestClose={this.handleExportsModalClose('partsTemplate')}
        />
        <UploadPartsDialog
          open={openedExportsDialogs.uploadParts}
          onRequestClose={this.handleExportsModalClose('uploadParts')}
        />
        <ConfirmDialog
          open={confirmDialog.open}
          title="Warning"
          text={
            confirmDialog.type === 'part' ? (
              <ConfirmPartRemovalText linksCount={confirmDialog.entityToRemove.LinksCount} />
            ) : (
              <ConfirmGroupRemovalText linksCount={confirmDialog.entityToRemove.LinksCount} />
            )
          }
          confirmText="Delete"
          cancelText="Cancel"
          onApprove={this.handleRemoveApprove}
          onReject={this.handleRemoveReject}
        />
        <DistributionDialog
          open={this.state.distributionDialogOpen}
          handleClose={this.handleCloseDistributionDialog}
          title={`${firstLetterUpperCase(type)} Distribution`}
          type={type}
          selectedItem={this.state.itemForLinking}
          onSubmit={this.onSubmitDistributionRules(true)}
          onOpenPreviewInfo={this.onSubmitDistributionRules(false)}
          submitLabel="Save Next"
          previewList={distributionPreviewList}
        />
        <DistributionPreviewDrawer
          open={this.state.distributionPreviewDrawerOpen}
          handleClose={this.closeDistributionPreviewDrawer}
          commonLockHide
          handleLinkParts={this.handleLinkParts}
        />
        <LinkItemsDialog
          handleClose={this.closeLinkItemsDialog}
          open={linkItemsDialogOpened}
          units={this.props.listUnitsWithItems}
          commonLockHide
          openItemPartsViewDialog={this.openItemPartsViewDialog}
          isView
          withSiteDropdown
          dropdownList={sitesList}
          dropdownLabel="Select Site"
          handleDropdownChange={this.handleSitesDropdownChange}
          dropdownValue={this.state.siteSelected}
        />
        <ItemPartsViewDialog
          open={itemPartsViewDialogOpened}
          onRequestClose={this.closeItemPartsViewDialog}
          title="Item Parts View"
          units={this.props.listUnitsWithItems}
          unitToLink={this.state.unitToView}
          itemToLink={this.state.itemToView}
          isView
        />
      </>
    );
  }
}

const mapStateToProps = ({
  parts,
  distributions,
  units,
  auth: {
    selectedSite,
    user: { sitesAvailableToUserFullInfo },
  },
}) => ({
  error: parts.error,
  partsList: parts.list,
  groupsList: parts.groupsList,
  distributionPreviewList: distributions.previewList,
  listUnitsWithItems: units.listUnitsWithItems,
  selectedSite,
  sites: sitesAvailableToUserFullInfo,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openErrorDialog,
      ...partsActions,
      getDistributionListPreview: distributionActions.getDistributionListPreviewRequest,
      getListUnitsWithItems: unitsActions.getListUnitsWithItemsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Parts);
