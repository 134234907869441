import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Provider, ReactReduxContext, connect } from 'react-redux';

import { Dialog } from 'components/Dialogs/v1/Base';
import { actions as authActions } from 'redux/auth';

import theme from 'theme';

import InfoPage from './InfoPage';
import SelectSite from './SelectSite';
import UnitInfo from './UnitInfo';
import ItemInfo from './ItemInfo';
import SelectTasks from './SelectTasks';
import SearchListTemplate from './SearchListTemplate';
import ImportComplete from './ImportComplete';
import NewVersion from './NewVersion';
import ListTemplatePreview from './ListTemplatePreview';

import * as S from './styled';

const STEPS = {
  0: {
    Component: InfoPage,
  },
  1: {
    Component: SelectSite,
  },
  2: {
    Component: UnitInfo,
  },
  3: {
    Component: ItemInfo,
  },
  4: {
    Component: SelectTasks,
  },
};

const STEPS_IMPORT = {
  0: {
    Component: InfoPage,
  },
  1: {
    Component: SelectSite,
  },
  2: {
    Component: SearchListTemplate,
  },
  3: {
    Component: NewVersion,
  },
  4: {
    Component: ImportComplete,
  },
  5: {
    Component: ListTemplatePreview,
  },
};

class ListTemplate extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExit: PropTypes.func.isRequired,
    addAnotherItem: PropTypes.func.isRequired,
    openScheduleTaskOfferDialog: PropTypes.func.isRequired,
    openImportListTemplateDialog: PropTypes.func.isRequired,
    openItemLimitDialog: PropTypes.func.isRequired,
    firstTimeTemplate: PropTypes.bool.isRequired,
    anotherUnitTemplate: PropTypes.bool.isRequired,
    currentSite: PropTypes.shape().isRequired,
    setCurrentSite: PropTypes.func.isRequired,
    listTemplateNames: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      fetchSites: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    step: 0,
    currentTemplate: {},
    isImport: false,
  };

  setIsImport = () => {
    this.setState({ isImport: true });
  };

  setIsNew = () => {
    this.setState({ isImport: false });
  };

  setCurrentTempalte = template => {
    this.setState({ currentTemplate: template });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.props.actions.fetchSites();
      this.setState({ step: 0 });
    }
  }

  handlePrev = () => {
    this.setState(({ step }) => ({ step: step - 1 }));
  };

  handleNext = () => {
    this.setState(({ step }) => ({ step: step + 1 }));
  };

  getTitle = (step, isImport) => {
    if ((step === 3 || step === 4) && isImport) {
      return 'Alert';
    }
    if (step > 1) {
      return 'List Template Setup';
    }
    return 'Adding List Template(s)';
  };

  render() {
    const { step, currentTemplate, isImport } = this.state;
    const {
      open,
      handleClose,
      handleExit,
      openItemLimitDialog,
      firstTimeTemplate,
      anotherUnitTemplate,
      currentSite,
      setCurrentSite,
      listTemplateNames,
      openImportListTemplateDialog,
      openScheduleTaskOfferDialog,
      isLoading,
    } = this.props;
    const stepAnotherUnit = anotherUnitTemplate ? 2 + step : 0 + step;
    const stepPlus = firstTimeTemplate ? 0 + stepAnotherUnit : 3 + stepAnotherUnit;
    const Step = isImport ? STEPS_IMPORT[stepPlus].Component : STEPS[stepPlus].Component;
    const newItemInfoText = 'An Item can be any object that you would like to assign tasks to.';
    const isNewItemComponent = stepPlus === 3;

    return (
      <ReactReduxContext.Consumer>
        {
          ctx => (
            <Dialog
              open={open}
              onRequestClose={stepPlus > 1 ? handleClose : handleExit}
              title={this.getTitle(stepPlus, isImport)}
              titleColor={(isNewItemComponent || stepPlus === 4) && isImport ? theme.mainRed : theme.mainLightBlue}
              noCapitalize
            >
              <S.DialogContainer>
                <S.Overlay show={isLoading}>
                  <S.Loader />
                </S.Overlay>
                <Provider store={ctx.store}>
                  <Step
                    onNext={this.handleNext}
                    onBack={this.handlePrev}
                    onClose={handleClose}
                    onExit={handleExit}
                    setCurrentSite={setCurrentSite}
                    currentSite={currentSite}
                    listTemplateNames={listTemplateNames}
                    currentTemplate={currentTemplate}
                    setCurrentTemplate={this.setCurrentTempalte}
                    setIsImport={this.setIsImport}
                    setIsNew={this.setIsNew}
                    addAnotherItem={this.props.addAnotherItem}
                    openItemLimitDialog={openItemLimitDialog}
                    firstTimeTemplate={firstTimeTemplate}
                    openImportListTemplateDialog={openImportListTemplateDialog}
                    openScheduleTaskOfferDialog={openScheduleTaskOfferDialog}
                    anotherUnitTemplate={anotherUnitTemplate}
                    tooltipText={isNewItemComponent && newItemInfoText}
                  />
                </Provider>
              </S.DialogContainer>
            </Dialog>
          ) // Dont forget to bind this
        }
      </ReactReduxContext.Consumer>
    );
  }
}

const mapStateToProps = ({ listTemplates }) => ({
  isLoading: listTemplates.isLoading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchSites: authActions.fetchUserSitesRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListTemplate);
