export const checkboxesName = ['SendDailyMail', 'SendIotMail', 'SendIotTxt', 'SendIotPush'];

export const notificationInfoTitle = {
  SendDailyMail: 'Daily Mail',
  SendIotMail: 'IOT Mail',
  SendIotTxt: 'IOT TxT',
  SendIotPush: 'IOT Push',
};

export const modifyUserNotification = (notificationStatusUsers, userId, notification, checked, notifications) => {
  let user = notificationStatusUsers.find(({ UserId }) => userId === UserId);
  let updatedStatuses;

  if (user) {
    if (checked !== notifications[notification]) user[notification] = checked;
    else if (Object.keys(user).length === 2) user = {};
    else delete user[notification];

    updatedStatuses = notificationStatusUsers
      .map(currentUser => (currentUser.UserId === userId ? { ...notifications, ...user } : currentUser))
      .filter(currentUser => currentUser && currentUser.UserId);
  } else {
    updatedStatuses = [...notificationStatusUsers, { UserId: userId, ...notifications, [notification]: checked }];
  }

  return updatedStatuses;
};

export const fillWholeNotificationGroup = (statuses, newUserIds, checked, checkboxName) =>
  newUserIds
    .map(user => {
      let earlyModifiedUser = statuses.find(({ UserId }) => UserId === user.UserId);

      if (earlyModifiedUser) {
        if (checked) {
          if (!earlyModifiedUser[checkboxName]) earlyModifiedUser[checkboxName] = checked;
        } else if (earlyModifiedUser[checkboxName]) {
          if (Object.keys(earlyModifiedUser).length === 2) earlyModifiedUser = {};
          delete earlyModifiedUser[checkboxName];
        }

        return { ...user, ...earlyModifiedUser };
      }
      return user;
    })
    .filter(currentUser => currentUser && currentUser.UserId);

export const mergePeopleNotifications = (peopleList, checked, userId, checkboxName) =>
  peopleList.map(user =>
    user.UserId === userId ? { ...user, Notification: { ...user.Notification, [checkboxName]: checked } } : user,
  );

export const updateAllUsersByNotification = (peopleList, checked, checkboxName) =>
  peopleList.map(user => ({ ...user, Notification: { ...user.Notification, [checkboxName]: checked } }));
