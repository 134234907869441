const initialState = {
  error: '',
  isLoading: false,
  list: { parts: [], groups: [] },
  previewList: [],
  details: {},
};

export const getDistributionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getDistributionsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  list: { ...state.list, parts: payload },
});

export const getDistributionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteDistributionsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteDistributionsSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deleteDistributionsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getDistributionListPreviewRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getDistributionListPreviewSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: true,
  previewList: payload,
});

export const getDistributionListPreviewFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getDistributionDetailsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getDistributionDetailsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  details: payload,
});

export const getDistributionDetailsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getDistributionsGroupsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getDistributionsGroupsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  list: { ...state.list, groups: payload },
});

export const getDistributionsGroupsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getDistributionGroupDetailsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getDistributionGroupDetailsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  details: payload,
});

export const getDistributionGroupDetailsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteDistributionsGroupsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteDistributionsGroupsSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deleteDistributionsGroupsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
