import styled from 'styled-components';

import { ProgressCircular } from 'components/Layout/Progresses';

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const LogoContainerLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LogoContainerRight = styled.div`
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const LogoDescription = styled.div``;

export const ImageContainer = styled.div`
  width: 130px;
  height: 60px;
  display: flex;
  align-items: center;
`;

export const Loader = styled(ProgressCircular).attrs({
  size: 30,
})``;

export const NoLogo = styled.div`
  width: ${({ isPDF }) => (isPDF ? '126px' : '56px')};
  height: 56px;
  background-color: ${({ theme }) => theme.disabledColor};
  border: 2px solid ${({ theme }) => theme.lightGrey};
  border-radius: 5px;
`;

export const FileLoaderContainer = styled.div`
  align-self: flex-end;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
