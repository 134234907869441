import styled from 'styled-components';

export const FormContainer = styled.div`
  padding: 30px 20px;
`;

export const CardContentContainer = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

export const CurrentCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoldLabel = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const CurrentCount = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryBlack};
  margin-top: 35px;
`;

export const TenantMaxAccountValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    margin-bottom: 15px;
  }
`;

export const Label = styled.label`
  margin-left: 50px;
`;
