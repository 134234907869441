import React from 'react';
import PropTypes from 'prop-types';

import SimpleList from 'components/SimpleList';
import PdfItem from './PdfItem';
import { ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const DownloadReportDialog = ({
  endDate,
  handleClose,
  onSubmit,
  open,
  startDate,
  title,
  list,
  rightControls,
  site,
  redTitle,
  fileFormat,
}) => (
  <S.DialogContainer
    actions={[
      <ActionButton type="button" label="Cancel" onClick={handleClose} />,
      fileFormat !== 'pdf' && <ActionButton type="button" onClick={onSubmit} isNext label="NEW Download" />,
    ]}
    onRequestClose={handleClose}
    open={open}
    title={title}
  >
    {fileFormat === 'csv' ? (
      <>
        <S.Title>{site}</S.Title>
        <S.RedTitle>{redTitle}</S.RedTitle>
        <S.DateContainer>
          <S.TextDate>From: {startDate}</S.TextDate>
          <S.TextDate>To: {endDate}</S.TextDate>
        </S.DateContainer>
        <SimpleList key="Report List" data={list} renderItemContent={PdfItem} renderRightControls={rightControls} />
      </>
    ) : (
      <S.Title>Export Pdf is not available</S.Title>
    )}
  </S.DialogContainer>
);

DownloadReportDialog.defaultProps = {
  endDate: null,
  startDate: null,
};

DownloadReportDialog.propTypes = {
  title: PropTypes.string.isRequired,
  endDate: PropTypes.instanceOf(Date),
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date),
  list: PropTypes.array.isRequired,
  rightControls: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  redTitle: PropTypes.string.isRequired,
  fileFormat: PropTypes.string.isRequired,
};

export default DownloadReportDialog;
