import moment from 'moment';

export const formatAlertTrends = (alertTrendsData, levels) => {
  if (alertTrendsData.length === 0) {
    return { data: [] };
  }

  let alertTrends = [
    { time: 0 },
    { time: -0.5 },
    { time: -1 },
    { time: -1.5 },
    { time: -2 },
    { time: -2.5 },
    { time: -3 },
    { time: -3.5 },
    { time: -4 },
    { time: -4.5 },
  ];

  const dataInfo = {
    average: +alertTrendsData[0].SensorFieldDataForLevel,
    current: +alertTrendsData[0].SensorFieldDataForLevel,
    high: +alertTrendsData[0].SensorFieldDataForLevel,
    low: +alertTrendsData[0].SensorFieldDataForLevel,
  };
  let ticksCount = 0;
  const tickFormat = [];
  const now = alertTrendsData[0].CreatedOn;

  alertTrends = alertTrends
    .map((trend, index) => {
      // fill tickFormat with trend's time in minutes
      const tick = alertTrendsData[index];

      if (index === 0) {
        tickFormat.unshift('now');
        ticksCount = 1;
      } else if (tick && index === 2) {
        const time = moment(tick.CreatedOn);
        const closestToNow = moment(time).diff(now, 'minutes');

        tickFormat.unshift(`${closestToNow} min`);
      } else if (tick && index % 2 === 0 && index < 9) {
        const time = moment(tick.CreatedOn);
        const interval = moment(time).diff(now, 'minutes');

        tickFormat.unshift(`${interval}`);
      } else if (index % 2 === 0 && index < 9) {
        tickFormat.unshift('');
      }

      if (tick && +tick.SensorFieldDataForLevel > dataInfo.high) {
        dataInfo.high = +tick.SensorFieldDataForLevel;
      } else if (tick && +tick.SensorFieldDataForLevel < dataInfo.low) {
        dataInfo.low = +tick.SensorFieldDataForLevel;
      }

      if (tick && index !== 0) {
        dataInfo.average += +tick.SensorFieldDataForLevel;
        ticksCount += 1;
      }

      return {
        ...trend,
        value: (tick && +tick.SensorFieldDataForLevel) || 0.00005,
        fill: (tick && tick.StatusTelemetry) || '',
      };
    })
    .reverse();

  dataInfo.average = +(dataInfo.average / ticksCount).toFixed(4);

  const { Alert, Warning, Watch, Elevated, Normal, ElevatedLow, WatchLow, WarningLow, AlertLow } = levels.reduce(
    (prev, { LevelName, StartData }) => {
      if (prev[LevelName]) {
        return {
          ...prev,
          [`${LevelName}Low`]: +StartData,
        };
      }

      return { ...prev, [LevelName]: +StartData };
    },
    {},
  );

  let normalMin = Normal;
  const normalMax = (Elevated || Watch || Warning || Alert) - (Alert >= 0 ? 0.0001 : -0.0001);

  if (AlertLow) {
    normalMin = (ElevatedLow || WatchLow || WarningLow || AlertLow) + 0.0001;
  }

  return {
    data: alertTrends,
    dataInfo,
    // set 0.00001 for normalMax or normalMin if they are zero, beacause VictoryLine cannot take zero value
    normalMax: normalMax === 0 ? 0.00005 : normalMax,
    normalMin: normalMin === 0 ? -0.00005 : normalMin,
    tickFormat,
  };
};

export const formatFavoriteSites = favoriteSites => ({
  siteIds: favoriteSites ? favoriteSites.map(site => site.Id) : [],
});
