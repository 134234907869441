import styled from 'styled-components';

import { FieldSelect, SelectItem } from 'components/Layout/Selects';

export const Container = styled.div`
  & > div:last-child {
    padding: 20px 0px !important;
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 5px 0 30px 0;
  font-size: 24px;
  font-weight: 100;
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

export const Select = styled(FieldSelect)``;

export const Item = styled(SelectItem)``;
