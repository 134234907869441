import styled from 'styled-components';
import { Flex, Box } from 'grid-styled';

export const Column = styled(Box)`
  width: 890px;
`;

export const Content = styled(Flex).attrs({
  justify: 'center',
  p: 2,
  wrap: true,
})``;

export const Description = styled(Flex).attrs({
  column: true,
})`
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  padding: 10px;
`;

export const DescriptionLabel = styled.div`
  flex: none;
  font-weight: 500;
  margin-right: 10px;
`;

export const DescriptionText = styled.div`
  display: flex;
  min-width: 0;
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -ms-word-break: break-all;
`;

export const DescriptionMultiText = styled(DescriptionText)`
  margin-top: 10px;
`;

export const Documents = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
  wrap: true,
})``;
