import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';
import theme from 'theme';
import { PlayIconFilled } from 'components/Layout/Icons';

const StatusMenuDialog = ({
  isDynamic,
  isPaused,
  activeStatus,
  setTimeFrame,
  onClickStatusButton,
  IsNextPeriodAvailable,
  ...rest
}) => (
  <S.Modal {...rest}>
    <S.Triangle />
    <S.CalendarButton />
    {activeStatus === 'Inactive' ? (
      <S.SetTimeFrameButton activeStatus={activeStatus} />
    ) : (
      <S.SetTimeFrameButton
        onClick={() => {
          setTimeFrame();
          rest.onRequestClose();
        }}
      />
    )}
    {isDynamic && activeStatus === 'Active' && isPaused && (
      <S.StatusButtonDynamic onClick={() => onClickStatusButton({ isStart: isPaused })} />
    )}
    {isDynamic && activeStatus === 'Active' && !isPaused && (
      <S.StatusButtonPaused onClick={() => onClickStatusButton({ isStart: isPaused })} />
    )}
    {!isDynamic &&
      activeStatus === 'Active' &&
      (IsNextPeriodAvailable ? (
        <S.StatusButtonManual icon={<PlayIconFilled />} onClick={() => onClickStatusButton({ isNextPeriod: true })} />
      ) : (
        <S.StatusButtonManual
          icon={<PlayIconFilled color={theme.primaryGrey} />}
          data-tip
          data-for="period"
          data-offset="{'bottom': 120}"
        />
      ))}
    {activeStatus === 'Inactive' &&
      (isDynamic ? (
        <S.StatusButtonInactiveOutline data-tip data-for="inactive" data-offset="{'bottom': 120}" />
      ) : (
        <S.StatusButtonInactiveFilled data-tip data-for="inactive" data-offset="{'bottom': 120}" />
      ))}
    <S.Tooltip id="inactive" effect="solid">
      <div>
        inactive: <br /> end of <br /> shedule
      </div>
    </S.Tooltip>
    <S.Tooltip id="period" effect="solid">
      <div>
        No ability <br /> to create <br /> tasks
      </div>
    </S.Tooltip>
  </S.Modal>
);

StatusMenuDialog.propTypes = {
  isDynamic: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  activeStatus: PropTypes.string.isRequired,
  setTimeFrame: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onClickStatusButton: PropTypes.func.isRequired,
  IsNextPeriodAvailable: PropTypes.bool.isRequired,
};

export default StatusMenuDialog;
