import styled from 'styled-components';

import { InputBlockLabel } from 'components/Layout/Containers';

export const SearchContainer = styled.div`
  padding-top: 20px;
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin-top: 20px;
`;

export const ListContainer = styled.div`
  padding: 30px 0 30px;
  max-height: 20vh;
  overflow: auto;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;
`;

export const SubInfo = styled.div`
  margin-bottom: 6px;
`;

export const TopInfo = styled.div`
  text-align: center;
`;
