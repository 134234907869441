import styled from 'styled-components';

import { FieldSelect } from 'components/Layout/Selects';
import MenuItem from 'material-ui/MenuItem';

export const DialogContainer = styled.div`
  height: 290px;
  display: flex;
  flex-direction: column;
`;

export const CurrentSiteName = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.primaryDark};
  font-weight: 300;
  max-width: 600px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SelectorLabel = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  margin-bottom: 10px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.primaryDark};
`;

export const TimezoneLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Timezone = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Offset = styled.span`
  margin-left: 30px;
`;

export const Select = styled(FieldSelect)``;
export const Option = styled(MenuItem)``;

export const Container = styled.div`
  margin-top: 35px;
  position: fixed;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
  box-sizing: border-box;
  border-radius: 2px;
  width: 450px;
  z-index: 2600;
`;

export const ListContainer = styled.div`
  max-height: 35vh;
  overflow-y: auto;
`;

export const SearchContainer = styled.div`
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
  height: 60px;
  padding-top: 20px;
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
`;
