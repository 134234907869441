import { createSelector } from 'reselect';

const getGatewaysList = gateways => gateways.list;
const getTenantsList = (gateways, tenants) => tenants.list;

export const getGatewaysListWithTenantName = createSelector([getGatewaysList, getTenantsList], (gateways, tenants) =>
  gateways.map(gateway => {
    const tenant = tenants.find(item => item.Id === gateway.TenantID);

    gateway.tenantName = tenant ? tenant.Name : '';
    return gateway;
  }),
);

export const selector = () => {};
