import React from 'react';
import PropTypes from 'prop-types';

import { Container, Button } from './styled';

const Footer = ({ onNextDocument, onPreviousDocument }) => (
  <Container>
    <Button label="Previous Document" onClick={onPreviousDocument} />
    <Button label="Next Document" onClick={onNextDocument} />
  </Container>
);

Footer.propTypes = {
  onNextDocument: PropTypes.func.isRequired,
  onPreviousDocument: PropTypes.func.isRequired,
};

export default Footer;
