import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RemoveGroupsAndHubsDialog from 'components/Dialogs/RemoveGroupsAndHubsDialog';
import { BackIcon } from 'components/Layout/Icons';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';

import { ResourceGroupType } from 'configs/propTypes';

import { actions as gatewaysActions } from 'redux/gateways';

import { groupHubsData } from '../helpers';

import Item from './Item';
import { getResourceGroups, warningTexts } from './helpers';

class ResourceGroups extends Component {
  static propTypes = {
    action: PropTypes.shape({
      deleteResourceGroup: PropTypes.func.isRequired,
      getResourceGroups: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    list: PropTypes.arrayOf(PropTypes.shape(ResourceGroupType).isRequired).isRequired,
  };

  state = {
    searchData: '',
    removeResourceGroupDialogOpen: false,
    resourceGroupsData: [],
    groupForDelete: '',
  };

  componentDidMount() {
    this.props.action.getResourceGroups();
  }

  onBackButtonClick = () => this.props.history.push('/globalsettings');

  onSearch = e => this.setState({ searchData: e.target.value });

  filterResourceGroups = ({ tenant, resourceGroup }) =>
    `${tenant.name}${resourceGroup.name}`.toLocaleLowerCase().includes(this.state.searchData.toLocaleLowerCase());

  openResourceGroupDialog = item =>
    this.setState({
      removeResourceGroupDialogOpen: true,
      resourceGroupsData: groupHubsData(item),
      groupForDelete: item.resourceGroup.name,
    });
  closeRemoveResourceGroupDialog = () => this.setState({ removeResourceGroupDialogOpen: false });

  handleDeleteResourceGroup = () => {
    this.props.action.deleteResourceGroup({ groupName: this.state.groupForDelete });
    this.closeRemoveResourceGroupDialog();
  };

  render() {
    const { searchData, removeResourceGroupDialogOpen, resourceGroupsData } = this.state;
    const { list } = this.props;

    return (
      <>
        <Subheader
          title="IoT Resource Groups"
          hintText="Search"
          isSearch
          searchData={searchData}
          searchInList={this.onSearch}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackButtonClick,
              hint: 'Back',
            },
          ]}
        />
        <SimpleList
          data={list.filter(this.filterResourceGroups)}
          onItemClick={this.openResourceGroupDialog}
          emptyListMessage="There are no Resource Groups avaliable"
          renderItemContent={item => <Item item={item} />}
        />
        <RemoveGroupsAndHubsDialog
          open={removeResourceGroupDialogOpen}
          title="Remove Resource Group"
          closeModal={this.closeRemoveResourceGroupDialog}
          data={resourceGroupsData}
          alertText={warningTexts.notification}
          handleDelete={this.handleDeleteResourceGroup}
          warningText={warningTexts.confirmation}
        />
      </>
    );
  }
}

const mapStateToProps = ({ gateways }) => ({
  list: getResourceGroups(gateways.resourceGroupsList),
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      getResourceGroups: gatewaysActions.getResourceGroupsRequest,
      deleteResourceGroup: gatewaysActions.deleteServerRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceGroups);
