import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import * as CS from 'components/Dialogs/styled';

import IoTFrequency from './helpers';
import * as S from './styled';

class SetTimezoneDialog extends PureComponent {
  static propTypes = {
    checkedSitesNumber: PropTypes.number.isRequired,
    currentSite: PropTypes.shape({
      SiteId: PropTypes.string,
      Name: PropTypes.string,
      NotificationIoTFrequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    NotificationIoTFrequency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    applyToCheckedSelections: PropTypes.func.isRequired,
    applyToCurrentSite: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectNotification: PropTypes.func.isRequired,
  };

  static defaultProps = {
    NotificationIoTFrequency: 1,
  };

  onRadioGroupChange = (e, value) => this.props.selectNotification(value);

  render() {
    const {
      applyToCheckedSelections,
      applyToCurrentSite,
      checkedSitesNumber,
      currentSite,
      handleClose,
      NotificationIoTFrequency,
      open,
      title,
    } = this.props;

    const disableButtons = !NotificationIoTFrequency && NotificationIoTFrequency !== 0;

    return (
      <BaseDialog open={open} onRequestClose={handleClose} title={title}>
        <S.DialogContainer>
          <CS.CurrentSiteContainer>
            <S.CurrentSiteName>{`Current Site: ${currentSite.Name}`}</S.CurrentSiteName>
            <CS.SelectorContainer>
              <S.SelectorLabel>
                Notifications are sent when <span>new</span> alerts are present
              </S.SelectorLabel>
              <S.RadioGroup
                name="NotificationIoTFrequency"
                defaultSelected={
                  NotificationIoTFrequency.toString().length > 0 ? NotificationIoTFrequency : IoTFrequency[0].value
                }
                onChange={this.onRadioGroupChange}
              >
                {IoTFrequency.map(({ value, label }) => (
                  <S.FieldRadio key={value} label={label} value={value} />
                ))}
              </S.RadioGroup>
            </CS.SelectorContainer>
          </CS.CurrentSiteContainer>
          <CS.DialogFooter>
            {checkedSitesNumber > 1 && (
              <CS.ButtonContainer onClick={applyToCheckedSelections}>
                <CS.SaveButton disabled={disableButtons} submitLabel={`Apply to ${checkedSitesNumber} selections`} />
              </CS.ButtonContainer>
            )}
            <CS.ButtonContainer onClick={applyToCurrentSite} fullWidth={checkedSitesNumber < 2}>
              <CS.SaveButton disabled={disableButtons} submitLabel="Apply to this Site" />
            </CS.ButtonContainer>
          </CS.DialogFooter>
        </S.DialogContainer>
      </BaseDialog>
    );
  }
}

export default SetTimezoneDialog;
