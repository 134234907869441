export const validator = values => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.VendorName) {
    errors.VendorName = 'Required';
  }
  if (!values.IndustryId) {
    errors.IndustryId = 'Required';
  }

  return errors;
};

export default validator;
