import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as globalSettingsActions } from 'redux/globalSettings';

import { BackIcon, EditIcon, HelpIcon, TenantsModuleIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import TypesInfoDialog from './TypesInfoDialog';
import GeoLocationDialog from './GeoLocationDialog';

import Item from './Item';
import { ControlsBlock } from './styled';

class TenantsList extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    getTaskTypes: PropTypes.func.isRequired,
    getTenants: PropTypes.func.isRequired,
    putTaskTypes: PropTypes.func.isRequired,
    setSelectedTask: PropTypes.func.isRequired,
    taskTypes: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
        Value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  state = {
    isTypesInfoDialogOpen: false,
    isGeoLocationDialogOpen: false,
  };

  componentDidMount() {
    const { getTaskTypes } = this.props;

    getTaskTypes();
  }

  closeDialog = modal => () => {
    this.setState({ [modal]: false });
  };

  openDialog = modal => () => {
    this.setState({ [modal]: true });
  };

  onListClick = () => {
    this.props.history.push('/globalsettings');
  };

  openGeoLocationDialog = id => () => {
    const { setSelectedTask, getTenants } = this.props;

    setSelectedTask(id);
    getTenants(id);
    this.openDialog('isGeoLocationDialogOpen')();
  };

  renderRightControls = task => (
    <ControlsBlock>
      <ButtonIcon tooltip="Edit">{task.isUpdateble ? <EditIcon /> : null}</ButtonIcon>
      <ButtonIcon onClick={this.openGeoLocationDialog(task.id)}>
        <TenantsModuleIcon />
      </ButtonIcon>
    </ControlsBlock>
  );

  render() {
    const { isGeoLocationDialogOpen, isTypesInfoDialogOpen } = this.state;
    const { taskTypes, putTaskTypes } = this.props;

    return (
      <Fragment>
        <Subheader
          key="Tenants list subheader"
          title="Task Types"
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onListClick,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: <HelpIcon />,
              handler: this.openDialog('isTypesInfoDialogOpen'),
              hint: 'Add',
            },
          ]}
        />
        <SimpleList
          key="Tasks"
          data={taskTypes}
          emptyListMessage="There are no task types avaliable"
          renderItemContent={Item}
          renderRightControls={item => this.renderRightControls(item)}
        />
        <GeoLocationDialog
          key="GeoLocationDialog"
          handleClose={this.closeDialog('isGeoLocationDialogOpen')}
          title="Task Types Geo Location"
          open={isGeoLocationDialogOpen}
        />
        <TypesInfoDialog
          key="TypesInfoDialog"
          handleClose={this.closeDialog('isTypesInfoDialogOpen')}
          title="Task Types Info"
          data={taskTypes}
          open={isTypesInfoDialogOpen}
          putTaskTypes={putTaskTypes}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ globalSettings }) => ({
  taskTypes: globalSettings.dataTaskTypes,
  tenants: globalSettings.dataTenants,
});

const mapDispatchToProps = {
  getTaskTypes: globalSettingsActions.getTaskTypesRequest,
  putTaskTypes: globalSettingsActions.putTaskTypesRequest,
  setSelectedTask: globalSettingsActions.setSelectedTaskTypes,
  getTenants: globalSettingsActions.getTenantsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantsList);
