import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EmptyList from './EmptyList';
import ItemsList from '../ItemsList';

import * as S from './styled';

const List = ({ isFavorite, list, openItemSensorsDialog, notFavoriteAlerts, toggleFavorite }) => (
  <S.Container>
    {list.length !== 0 ? (
      <>
        {list.map(({ AdditionalSiteID, Name, UnitsList }) => (
          <S.Site key={Name}>
            <div>
              {AdditionalSiteID} {Name}
            </div>
            <S.UnitsList>
              {UnitsList.map(({ Name: unitName, ItemsList: itemsList }) => (
                <S.Unit key={unitName}>
                  <S.UnitName>UnitName: {unitName}</S.UnitName>
                  <S.Separator />
                  <ItemsList list={itemsList} openItemSensorsDialog={openItemSensorsDialog} />
                </S.Unit>
              ))}
            </S.UnitsList>
          </S.Site>
        ))}
      </>
    ) : (
      <EmptyList isFavorite={isFavorite} notFavoriteAlerts={notFavoriteAlerts} toggleFavorite={toggleFavorite} />
    )}
  </S.Container>
);

List.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openItemSensorsDialog: PropTypes.func.isRequired,
  notFavoriteAlerts: PropTypes.number.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
};

const mapStateToProps = ({ health: { list, notFavoriteAlerts } }) => ({
  list,
  notFavoriteAlerts,
});

export default connect(mapStateToProps)(List);
