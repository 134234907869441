import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

import { REMOVAL_TYPES, ACTIONS } from '../config';

const styles = {
  radio: { marginBottom: '10px' },
  label: { color: '#000' },
  icon: isActive => ({ fill: isActive && theme.mainRed }),
};

class ManagerHasTeamsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(REMOVAL_TYPES)).isRequired,
    teamData: PropTypes.shape({
      TeamLeadCount: PropTypes.number.isRequired,
      TeamTaskCount: PropTypes.number.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
  };

  state = {
    selectedAction: ACTIONS.open,
  };

  handleActionChange = (event, selectedAction) => {
    this.setState({ selectedAction });
  };

  handleSubmit = () => {
    const { type, onSubmit } = this.props;
    const { selectedAction } = this.state;
    onSubmit(type, selectedAction);
  };

  render() {
    const {
      open,
      handleClose,
      type,
      name,
      teamData: { TeamLeadCount, TeamTaskCount },
    } = this.props;
    const { selectedAction } = this.state;

    return (
      <>
        <Dialog open={open} onRequestClose={handleClose} title="Alert" titleColor={theme.mainRed}>
          <S.Container>
            <S.WarningsContainer>
              <S.UserNameText>{name}</S.UserNameText>
              <S.WarningText>
                {type === REMOVAL_TYPES.REMOVE ? 'Removing' : 'Deactivating'} this person may disrupt tasks in progress!
              </S.WarningText>
              <S.SmallWarningText>
                Completed team tasks will be moved to the <strong>archive</strong>
              </S.SmallWarningText>
            </S.WarningsContainer>
            <S.CardsContainer>
              <S.Card>
                <S.CardHeader>Team Involvement</S.CardHeader>
                <S.CardContent>
                  <S.RedRow>
                    <S.Value>{TeamLeadCount}</S.Value>
                    <S.Label>Teams are managed by this person</S.Label>
                  </S.RedRow>
                  <S.Row>
                    <S.Value>{TeamTaskCount}</S.Value>
                    <S.Label>Team tasks are assigned but not completed</S.Label>
                  </S.Row>
                </S.CardContent>
              </S.Card>
            </S.CardsContainer>
            <S.ActionsContainer>
              <RadioButtonGroup name="action" valueSelected={selectedAction} onChange={this.handleActionChange}>
                <RadioButton
                  value={ACTIONS.open}
                  label='Reassign all Teams and their tasks to "Unknown Manager"'
                  labelStyle={styles.label}
                  style={styles.radio}
                  iconStyle={styles.icon(selectedAction === ACTIONS.open)}
                />
                <RadioButton
                  value={ACTIONS.delete}
                  label="Delete all Teams including their non-completed tasks"
                  labelStyle={styles.label}
                  style={styles.radio}
                  iconStyle={styles.icon(selectedAction === ACTIONS.delete)}
                />
              </RadioButtonGroup>
            </S.ActionsContainer>
            <ActionsContainer>
              <ActionButton label="Next" isNext onClick={this.handleSubmit} />
            </ActionsContainer>
          </S.Container>
        </Dialog>
      </>
    );
  }
}

export default ManagerHasTeamsDialog;
