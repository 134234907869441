import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  text-align: center;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.mainRed};
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.primaryDark};
`;

export const RadioButtonsWrapper = styled.div`
  margin: 30px 80px;
  border-bottom: 2px solid ${({ theme }) => theme.line};
`;
