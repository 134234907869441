import { call, put, select, takeEvery } from 'redux-saga/effects';

import { actions as authActions } from 'redux/auth';

import { getCurrentUser, getSitesAvailableToCurrentUser } from 'redux/auth/selectors';
import {
  createSite,
  getAssignedForSite,
  getPastDueLimit,
  getPastDueLimitList,
  editPastDueLimit,
  getSitesList,
  getSitesListByTenant,
  numerousEdit,
  putSite,
  removeSite,
  setSite,
} from 'http/sites';
import { openErrorDialog } from 'redux/errorHandler';
import { formatServerError } from 'helpers';

import { formatAssigned, formatSite } from './formatters';
import { actions } from './index';

function* fetchAssignedForSite({ payload }) {
  try {
    const isShowOnlyActiveUsers = payload.isShowOnlyActiveUsers ? 'filter.ShowOnlyActiveUsers=true' : '';
    const { data } = yield call(getAssignedForSite, payload, isShowOnlyActiveUsers);
    yield put(actions.assignedUsersSuccess({ formatted: formatAssigned(data.root), full: data.root }));
    if (payload.onNext) {
      payload.onNext(data.root);
    }
  } catch (error) {
    yield put(actions.assignedUsersFailure(error));
  }
}

function* fetchSitesList() {
  try {
    const { data } = yield call(getSitesList);
    yield put(actions.sitesListSuccess(data.root));
  } catch (error) {
    yield put(actions.sitesListFailure(error));
  }
}

function* fetchSitesListByTenant({ payload }) {
  try {
    const { data } = yield call(getSitesListByTenant, payload);
    yield put(actions.sitesListSuccess(data.root));
  } catch (error) {
    yield put(actions.sitesListFailure(error));
  }
}

function* addSite({ payload }) {
  try {
    const params = formatSite(payload);
    const { data } = yield call(createSite, params);
    yield* fetchSitesList();
    yield put(actions.addSiteSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.addSiteFailure({
        error,
        message: formatServerError(error, 'An error occurred while saving the Site.'),
      }),
    );
  }
}

function* assignSite({ payload: { mode, siteId, userIds, onNext } }) {
  try {
    yield call(setSite, mode, siteId, userIds);
    yield* fetchAssignedForSite({ payload: { siteId } });
    const currentUser = yield select(getCurrentUser);
    if (userIds.length === 1 && userIds[0] === currentUser.userId) {
      yield put(authActions.fetchUserSitesRequest());
    }
    if (onNext) {
      onNext();
    }
  } catch (error) {
    yield put(
      actions.addSiteFailure({
        error,
        message: 'An error occurred while changing the assignment for this user.',
      }),
    );
  }
}

function* deleteSite({ payload }) {
  try {
    yield call(removeSite, payload);
    yield* fetchSitesList();
  } catch (error) {
    yield put(actions.deleteSiteFailure(error.response.data.error));
  }
}

function* editSite({ payload }) {
  try {
    const params = formatSite(payload);
    const { data } = yield call(putSite, payload, params);
    yield put(actions.editSiteSuccess(data.entity));
    yield* fetchSitesList();
    const sitesAvailableToCurrentUser = yield select(getSitesAvailableToCurrentUser);
    if (sitesAvailableToCurrentUser.includes(payload.Id)) {
      yield put(authActions.fetchUserSitesRequest());
    }
  } catch (error) {
    yield put(actions.editSiteFailure({ error, message: 'An error occurred while saving the Site.' }));
  }
}

function* multipleEdit({
  payload: {
    selectedSite,
    requestBody: { setingsName, settings },
    requestName,
  },
}) {
  try {
    yield call(numerousEdit, requestName, selectedSite, setingsName, settings);
    yield* fetchSitesList();
    yield put(actions.multipleSiteEditSuccess());
  } catch (error) {
    yield put(actions.multipleSiteEditFailure(error));
  }
}

function* fetchPastDueLimit({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(getPastDueLimit, payload);
    const { Site, ...rest } = entity;
    const { Id, Name, AdditionalSiteID } = Site;
    const pastDueLimitSite = { ...rest, SiteId: Id, Name: AdditionalSiteID ? `${AdditionalSiteID} ${Name}` : Name };

    yield put(actions.setSitesStatus('updated'));
    yield put(actions.getPastDueLimitSuccess(pastDueLimitSite));
    yield put(actions.setSitesStatus('succeeded'));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setSitesStatus('failed'));
  }
}

function* fetchPastDueLimitList() {
  try {
    const {
      data: { root },
    } = yield call(getPastDueLimitList);
    const pastDueLimitList = root.map(list => {
      const { Site, ...newList } = list;
      const { Id, Name, AdditionalSiteID } = Site;

      return { ...newList, SiteId: Id, Name: AdditionalSiteID ? `${AdditionalSiteID} ${Name}` : Name };
    });

    yield put(actions.setSitesStatus('updated'));
    yield put(actions.getPastDueLimitListSuccess(pastDueLimitList));
    yield put(actions.setSitesStatus('succeeded'));
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
    yield put(actions.setSitesStatus('failed'));
  }
}

function* updatePastDueLimit({ payload }) {
  try {
    yield call(editPastDueLimit, payload);
    yield* fetchPastDueLimitList();
  } catch (error) {
    yield put(openErrorDialog(formatServerError(error), 'Error'));
  }
}

const sitesSagas = [
  takeEvery(actions.addSiteRequest, addSite),
  takeEvery(actions.assignSiteRequest, assignSite),
  takeEvery(actions.deleteSiteRequest, deleteSite),
  takeEvery(actions.editSiteRequest, editSite),
  takeEvery(actions.assignedUsersRequest, fetchAssignedForSite),
  takeEvery(actions.sitesListRequest, fetchSitesList),
  takeEvery(actions.sitesListByTenantRequest, fetchSitesListByTenant),
  takeEvery(actions.multipleSiteEditRequest, multipleEdit),
  takeEvery(actions.updatePastDueLimit, updatePastDueLimit),
  takeEvery(actions.getPastDueLimitRequest, fetchPastDueLimit),
  takeEvery(actions.getPastDueLimitListRequest, fetchPastDueLimitList),
];

export default sitesSagas;
