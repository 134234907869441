import styled from 'styled-components';

export const SelectorContainer = styled.div`
  margin-top: 10px;
`;

export const ScrollList = {
  maxHeight: 200,
  overflow: 'auto',
};
