import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import { actions as listTemplatesActions } from 'redux/listTemplates';

import CheckboxList from './CheckboxList';
import TasksTable from './TasksTable';
import * as S from './styled';

import theme from 'theme';

class SelectSite extends Component {
  static propTypes = {
    openImportListTemplateDialog: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    currentSite: PropTypes.shape().isRequired,
    listTemplateNames: PropTypes.array.isRequired,
    currentTemplate: PropTypes.shape().isRequired,
    actions: PropTypes.shape({
      getItemsRequest: PropTypes.func.isRequired,
      copyUnitsRequest: PropTypes.func.isRequired,
      getLimitsRequest: PropTypes.func.isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    openItemLimitDialog: PropTypes.func.isRequired,
    currentItemsNumber: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
  };

  state = {
    members: [],
    membersForSearch: [],
    checkedItem: {},
    searchData: '',
    isEdited: false,
    isErrorShown: false,
  };

  setCheckedItem = checkedItem => this.setState({ checkedItem });
  setSearchedMembers = (members, searchData) => this.setState({ members, searchData });

  onDeleteItem = index => {
    const array = this.state.members.slice();
    const tmpIndex = this.state.membersForSearch.indexOf(
      this.state.membersForSearch.find(item => item.Id === array[index].Id),
    );
    const tmpArray = this.state.membersForSearch.slice();
    tmpArray.splice(tmpIndex, 1);
    array.splice(index, 1);
    this.setState({ members: array, membersForSearch: tmpArray, isEdited: true });

    if (array.length === 0) {
      this.setCheckedItem({});
    } else if (index === array.length) {
      this.setCheckedItem(array[index - 1]);
    } else {
      this.setCheckedItem(array[index]);
    }
  };

  onSuccess = () => {
    this.props.onExit();
    this.props.openImportListTemplateDialog();
  };

  onNextClick = () => {
    const valuesToSend = {
      updateExistingUnits: true,
      siteId: this.props.currentSite.id,
      siteName: this.props.currentSite.name,
      masterUnit: this.props.currentTemplate,
      itemDtos: this.state.members,
      onSuccess: this.onSuccess,
    };
    if (this.props.listTemplateNames.checkDuplicate) {
      const arrayOfNames = this.props.listTemplateNames.templateNames;
      arrayOfNames.push(this.props.currentTemplate.Name);
      this.props.listTemplateNames.checkDuplicate = false;
    }
    if (this.state.members.length < 1) {
      this.setState({ isErrorShown: true });
    } else if (this.props.currentItemsNumber + this.state.members.length > this.props.limit) {
      this.setState({ isErrorShown: false });
      this.props.openItemLimitDialog();
    } else {
      this.setState({ isErrorShown: false });
      this.props.actions.copyUnitsRequest(valuesToSend);
    }
  };

  componentDidMount() {
    const { actions, currentTemplate } = this.props;
    actions.getLimitsRequest();
    actions.getItemsRequest({ masterUnitId: currentTemplate.Id });
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.props;
    const { searchData, isEdited } = this.state;

    if (items && items.length > 0 && !isEdited && prevState.membersForSearch.length === 0 && searchData.length === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        members: items,
        membersForSearch: items,
        checkedItem: items[0],
      });
    }

    // fixes material-ui dialog height CU610-2191
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { members, membersForSearch, checkedItem, searchData } = this.state;
    const { currentSite, currentTemplate } = this.props;

    return (
      <S.Container>
        <S.TopInfo>
          <S.Title>
            {currentSite.additionalId.length > 0
              ? `${currentSite.additionalId.substring(0, 5)} ${currentSite.name}`
              : currentSite.name}
          </S.Title>
          <S.Title>
            Template: <S.BoldText>{currentTemplate.Name}</S.BoldText>
          </S.Title>
        </S.TopInfo>
        <S.Block>
          <S.Label>Select Item To View Task</S.Label>
          <S.SearchContainer>
            <SearchField
              list={membersForSearch}
              filterBy={['Name']}
              searchData={searchData}
              placeholder="Filter Items"
              underlineShow={false}
              icon={<SearchIcon />}
              setSearchedItems={this.setSearchedMembers}
            />
          </S.SearchContainer>
          {this.props.items.length > 0 && (
            <S.ListContainer>
              <CheckboxList list={members} checkedItem={checkedItem} setCheckedItem={this.setCheckedItem} />
            </S.ListContainer>
          )}
        </S.Block>
        <S.Section>
          <S.SectionHeader color={theme.darkGrey}>Current Item Tasks</S.SectionHeader>
          <S.SectionBody>{checkedItem.TaskTemplates && <TasksTable list={checkedItem.TaskTemplates} />}</S.SectionBody>
        </S.Section>
        {this.state.isErrorShown && <S.ErrorContainer>Items list can not be empty</S.ErrorContainer>}
        <ActionsContainer paddingValue={14}>
          <ActionButton label="Next" isNext onClick={this.onNextClick} />
        </ActionsContainer>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ listTemplates }) => ({
  items: listTemplates.itemsWithTasks,
  limit: listTemplates.limits.MaxItemsNumber,
  currentItemsNumber: listTemplates.limits.CurrentItemsNumber,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...listTemplatesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSite);
