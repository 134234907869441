import styled from 'styled-components';

import { ToolbarContainer } from 'components/Layout/Containers';

export const Container = styled(ToolbarContainer)`
  height: 50px;
  justify-content: center !important;
`;

export const Value = styled.span`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;
