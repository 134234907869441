import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import VideoCategory from './pages/VideoCategory';
import CategorySections from './pages/CategorySections';

const Tutorials = () => (
  <Switch>
    <Route exact path="/tutorials" component={VideoCategory} />
    <Route exact path="/tutorials/:id/:name" component={CategorySections} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Tutorials;
