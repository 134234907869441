import styled from 'styled-components';

export const Container = styled.div`
  &:last-child {
    padding: 20px 0px !important;
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 5px 0;
  font-size: 20px;
`;
