import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';

import withInputModal from 'components/Dialogs/withInputModal';
import CustomNumberInputField from 'components/Forms/CustomNumberInputField';

import * as S from './styled';

const demoTenantsMaxValues = [
  {
    name: 'DemoTenantMaxSitesEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Sites </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'DemoTenantMaxItemsEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Items </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'DemoTenantMaxSensorsEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Sensors </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'DemoTenantMaxTimeMonths',
    label: (
      <S.Label>
        Max <S.BoldLabel>Time (Months)</S.BoldLabel>
      </S.Label>
    ),
  },
];

const DemoTierForm = ({ form, currentDemoTenantsCount }) => (
  <S.FormContainer>
    <S.CardContentContainer>
      <S.CurrentCountContainer>
        <S.BoldLabel>Current</S.BoldLabel>
        <S.CurrentCount>{currentDemoTenantsCount}</S.CurrentCount>
      </S.CurrentCountContainer>
      <CustomNumberInputField
        name="DemoTenantMaximumCount"
        change={form.change}
        labelComponent={<S.BoldLabel>Maximum</S.BoldLabel>}
        labelPosition="top"
        size="large"
      />
      <S.TenantMaxAccountValuesContainer>
        {demoTenantsMaxValues.map(({ name, label }) => (
          <CustomNumberInputField key={name} name={name} change={form.change} labelComponent={label} />
        ))}
      </S.TenantMaxAccountValuesContainer>
    </S.CardContentContainer>
  </S.FormContainer>
);

DemoTierForm.propTypes = {
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
  }).isRequired,
  currentDemoTenantsCount: PropTypes.number.isRequired,
};

const DemoTier = withInputModal(DemoTierForm);

const DemoTierEditDialog = props => (
  <DemoTier
    {...props}
    initialValues={props.systemLimits}
    currentDemoTenantsCount={props.currentTenantsCount.DemoTenantCurrentCount}
    titleColor={theme.signUpColor}
  />
);

DemoTierEditDialog.propTypes = {
  systemLimits: PropTypes.shape({}).isRequired,
  currentTenantsCount: PropTypes.shape({
    DemoTenantCurrentCount: PropTypes.number,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DemoTierEditDialog;
