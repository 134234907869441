import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RemoveGroupsAndHubsDialog from 'components/Dialogs/RemoveGroupsAndHubsDialog';
import { BackIcon } from 'components/Layout/Icons';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';

import { ResourceGroupType } from 'configs/propTypes';

import { actions as gatewaysActions } from 'redux/gateways';
import { getSiteHubs } from 'redux/gateways/selectors';

import { groupHubsData } from '../helpers';

import Item from './Item';

const warningTexts = {
  notification: 'Deleting a Hub will delete all Gateways and Sensors',
  confirmation: 'Are you sure you want to delete the IoT Hub for this Site?',
};

class SiteHubs extends Component {
  static propTypes = {
    action: PropTypes.shape({
      deleteTenantSiteHub: PropTypes.func.isRequired,
      fetchTenantHubs: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        tenantId: PropTypes.string,
        siteId: PropTypes.string,
      }),
    }).isRequired,
    list: PropTypes.shape(ResourceGroupType.tenant).isRequired,
  };

  state = {
    searchData: '',
    removeHubsDialogOpen: false,
    hubsData: [],
    hubForDelete: '',
  };

  componentDidMount() {
    const {
      action,
      match: { params },
    } = this.props;
    action.fetchTenantHubs({ tenantId: params.tenantId });
  }

  onBackButtonClick = () => this.props.history.push(`/globalsettings/tenant/${this.props.match.params.tenantId}/hubs`);

  onSearch = e => this.setState({ searchData: e.target.value });

  filterHubs = ({ hubs }) => hubs.name.toLocaleLowerCase().includes(this.state.searchData.toLocaleLowerCase());

  openHubsDialog = item =>
    this.setState({
      removeHubsDialogOpen: true,
      hubsData: groupHubsData(item),
      hubForDelete: item.hubs.name,
    });
  closeRemoveHubsDialog = () => this.setState({ removeHubsDialogOpen: false });

  handleDeleteHubs = () => {
    const {
      match: {
        params: { tenantId },
      },
      action,
    } = this.props;
    const { hubForDelete } = this.state;

    action.deleteTenantSiteHub({ tenantId, iotHubName: hubForDelete });
    this.closeRemoveHubsDialog();
  };

  render() {
    const { searchData, removeHubsDialogOpen, hubsData } = this.state;
    const { list } = this.props;

    return (
      <>
        <Subheader
          title={list.length > 0 ? `IoT Hubs ${list[0].site.name}` : ''}
          hintText="Search"
          isSearch
          searchData={searchData}
          searchInList={this.onSearch}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackButtonClick,
              hint: 'Back',
            },
          ]}
        />
        <SimpleList
          data={list.filter(this.filterHubs)}
          onItemClick={this.openHubsDialog}
          emptyListMessage="There are no Hubs avaliable"
          renderItemContent={item => <Item item={item} />}
        />
        <RemoveGroupsAndHubsDialog
          open={removeHubsDialogOpen}
          title="Remove Hub"
          closeModal={this.closeRemoveHubsDialog}
          data={hubsData}
          alertText={warningTexts.notification}
          handleDelete={this.handleDeleteHubs}
          warningText={warningTexts.confirmation}
        />
      </>
    );
  }
}

const mapStateToProps = ({ gateways }, { match: { params } }) => ({
  list: getSiteHubs(params.siteId, gateways.tenantHubsList),
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      deleteTenantSiteHub: gatewaysActions.deleteHubFromTenantSiteRequest,
      fetchTenantHubs: gatewaysActions.getTenantHubsListRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteHubs);
