import React from 'react';
import * as S from './styled';

export const UNIT_DISPLAY_VALUE = {
  OWN: 2,
  DEMO: 4,
  REGULAR: 8,
  PRIVATE: 16,
};

export function getUnitDisplayOptions(tenantName) {
  return [
    {
      name: (
        <S.RadioText>
          Only demonstration Templates
          <S.Loyalty />
        </S.RadioText>
      ),
      value: UNIT_DISPLAY_VALUE.DEMO,
    },
    { name: <S.RadioText>Only regular Templates</S.RadioText>, value: UNIT_DISPLAY_VALUE.REGULAR },
    {
      name: (
        <S.RadioText>
          Only private Templates
          <S.Lock />
        </S.RadioText>
      ),
      value: UNIT_DISPLAY_VALUE.PRIVATE,
    },
    { name: <S.RadioText>Only {tenantName} Templates</S.RadioText>, value: UNIT_DISPLAY_VALUE.OWN },
  ];
}
