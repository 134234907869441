import React from 'react';
import PropTypes from 'prop-types';

import { TitleText } from './styled';

const Title = ({ title }) => <TitleText>{title}</TitleText>;

Title.defaultProps = {
  title: '',
};

Title.propTypes = {
  title: PropTypes.string,
};

export default Title;
