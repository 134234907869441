import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, FormSpy } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import TasksTable from './TasksTable';
import { actions as listTemplatesActions } from 'redux/listTemplates';
import validate from './validator';

import theme from 'theme';

import * as S from './styled';

const RULE_DATA = {
  '': [],
  CheckFlowRate: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'number' },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'number' },
  ],
  CheckQuantity: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'number' },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'number' },
  ],
  CheckTemperature: [
    { floatingLabelText: 'Minimum', name: 'RuleData.ExpectedLowerBound', type: 'number' },
    { floatingLabelText: 'Maximum', name: 'RuleData.ExpectedUpperBound', type: 'number' },
  ],
  Feedback: [{ floatingLabelText: 'Specification', name: 'RuleData.Specification', type: 'text' }],
  PhotoConfirmation: [
    { floatingLabelText: 'Min. Attachments', name: 'RuleData.MinAttachments', type: 'number' },
    { floatingLabelText: 'Max. Attachments', name: 'RuleData.MaxAttachments', type: 'number' },
  ],
  ScanCode: [],
  Signature: [],
  Verification: [{ floatingLabelText: 'Specification', name: 'RuleData.Specification', type: 'text' }],
};

const initialValues = {
  RuleData: {
    ExpectedLowerBound: null,
    ExpectedScanCode: null,
    ExpectedUpperBound: null,
    MaxAttachments: null,
    MinAttachments: null,
    Specification: '',
  },
  RuleType: '',
};

const decorator = form => {
  let previousValues = {};

  const unsubscribe = form.subscribe(
    state => {
      const values = state.values;

      form.batch(() => {
        if (Object.keys(previousValues).length !== 0 && values.RuleType !== previousValues.RuleType) {
          form.change('RuleData.ExpectedLowerBound', null);
          form.change('RuleData.ExpectedScanCode', null);
          form.change('RuleData.ExpectedUpperBound', null);
          form.change('RuleData.MaxAttachments', null);
          form.change('RuleData.MinAttachments', null);
          form.change('RuleData.Specification', '');
        }

        previousValues = values;
      });
    },
    { touched: true, values: true },
  );

  return unsubscribe;
};

const SiteForm = ({ form, onAddClick }) => {
  const { dirty } = form.getState();

  return (
    <S.FormContainer>
      <S.Section>
        <S.SectionHeader color={theme.darkGrey}>Task Entry</S.SectionHeader>
        <S.Section inside>
          <S.SectionHeader color={theme.mainLightBlue}>New Task Type</S.SectionHeader>
          <S.SectionBody>
            <S.FieldBlock>
              <Field component={SelectFieldAdapter} floatingLabelText="Task Type" name="RuleType">
                <MenuItem primaryText="Verification" value="Verification" />
                <MenuItem primaryText="Feedback" value="Feedback" />
                <MenuItem primaryText="Check Flow Rate" value="CheckFlowRate" />
                <MenuItem primaryText="Check Quantity" value="CheckQuantity" />
                <MenuItem primaryText="Check Temperature" value="CheckTemperature" />
                <MenuItem primaryText="Photo Confirmation" value="PhotoConfirmation" />
                <MenuItem primaryText="Scan Code" value="ScanCode" />
                <MenuItem primaryText="Signature Collection" value="Signature" />
              </Field>
            </S.FieldBlock>
          </S.SectionBody>
        </S.Section>
        <S.Section inside>
          <S.SectionHeader color={theme.mainLightBlue}>Rule Data</S.SectionHeader>
          <S.SectionBody>
            <FormSpy subscription={{ values: true }}>
              {({ values }) =>
                values.RuleType ? (
                  RULE_DATA[values.RuleType].map(item => (
                    <Field key={item.name} component={TextFieldAdapter} {...item} />
                  ))
                ) : (
                  <span />
                )
              }
            </FormSpy>
          </S.SectionBody>
        </S.Section>
        <ActionsContainer>
          <ActionButton type="submit" isNext isDisable={!dirty} label="Add this task" />
        </ActionsContainer>
        <S.AddBlock>
          <S.ContentBlock>
            <S.IconContainer onClick={() => onAddClick(form)}>
              <S.PlusIcon />
            </S.IconContainer>
            <S.SubText>add another TaskType</S.SubText>
          </S.ContentBlock>
        </S.AddBlock>
      </S.Section>
    </S.FormContainer>
  );
};

class Setup extends Component {
  static propTypes = {
    currentTemplate: PropTypes.shape().isRequired,
    currentSite: PropTypes.shape().isRequired,
    lastCreatedTemplate: PropTypes.shape().isRequired,
    listTemplateNames: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      createListTemplateRequest: PropTypes.func.isRequired,
      createListTemplateItemRequest: PropTypes.func.isRequired,
    }).isRequired,
    addAnotherItem: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    firstTimeTemplate: PropTypes.bool.isRequired,
    openItemLimitDialog: PropTypes.func.isRequired,
    currentItemsNumber: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    anotherUnitTemplate: PropTypes.func.isRequired,
  };

  state = {
    tasks: [],
    isErrorShown: false,
  };

  onAddClick = form => {
    form.restart();
  };

  onDelete = index => {
    const array = this.state.tasks.slice();
    array.splice(index, 1);
    this.setState({ tasks: array });
  };

  onSuccess = () => {
    this.props.onExit();
    this.props.addAnotherItem();
  };

  onNextClick = e => {
    e.preventDefault();
    const { currentTemplate, firstTimeTemplate, lastCreatedTemplate, limit, currentItemsNumber } = this.props;
    const { tasks } = this.state;
    if (this.props.listTemplateNames.checkDuplicate) {
      const arrayOfNames = this.props.listTemplateNames.templateNames;
      arrayOfNames.push(this.props.currentTemplate.Name);
      this.props.listTemplateNames.checkDuplicate = false;
    }
    const valuesToSend = {
      onSuccess: this.onSuccess,
      anotherUnitTemplate: this.props.anotherUnitTemplate,
      siteId: this.props.currentSite?.id,
      unitName: currentTemplate.Name,
      unitModel: currentTemplate.Type,
      itemName: currentTemplate.ItemName,
      tasks: tasks.map(item => {
        if (item.RuleType === 'ScanCode' || item.RuleType === 'Signature') {
          return { RuleType: item.RuleType, RuleData: { Specification: 'Specification' } };
        }
        return item;
      }),
    };
    if (tasks.length < 1) {
      this.setState({ isErrorShown: true });
    } else if (limit !== undefined && currentItemsNumber === limit) {
      this.props.openItemLimitDialog();
    } else {
      this.setState({ isErrorShown: false });
      const create = firstTimeTemplate
        ? this.props.actions.createListTemplateRequest
        : this.props.actions.createListTemplateItemRequest;
      create(firstTimeTemplate ? valuesToSend : { ...valuesToSend, unitId: lastCreatedTemplate.Id });
    }
  };

  onSubmitForm = ({ values }) => {
    this.setState({ tasks: this.state.tasks.concat(values) });
  };

  render() {
    const { currentTemplate, currentSite } = this.props;

    return (
      <S.Container>
        <S.TopInfo>
          <S.Title>
            {currentSite?.additionalId.length > 0
              ? `${currentSite?.additionalId.substring(0, 5)} ${currentSite?.name}`
              : currentSite?.name}
          </S.Title>
          <S.Title>
            Template: <S.BoldText>{currentTemplate.Name}</S.BoldText>
          </S.Title>
          <S.Title>
            Item: <S.BoldText>{currentTemplate.ItemName}</S.BoldText>
          </S.Title>
        </S.TopInfo>

        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          decorators={[decorator]}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <SiteForm formValues={values} onAddClick={this.onAddClick} form={form} />
              <S.Section>
                <S.SectionHeader color={theme.darkGrey} />
                <S.SectionBody>
                  <TasksTable list={this.state.tasks} onDelete={this.onDelete} />
                </S.SectionBody>
              </S.Section>
              {this.state.isErrorShown && <S.ErrorContainer>Please add at least one TaskType</S.ErrorContainer>}
              <ActionsContainer>
                <ActionButton label="Next" isNext onClick={this.onNextClick} />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

SiteForm.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  form: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ listTemplates }) => ({
  error: listTemplates.error,
  lastCreatedTemplate: listTemplates.lastCreatedTemplate,
  limit: listTemplates.limits.MaxItemsNumber,
  currentItemsNumber: listTemplates.limits.CurrentItemsNumber,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...listTemplatesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
