import styled from 'styled-components';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';

export const AddEditForm = styled.div`
  padding: 0 20px 70px;
`;

export const Instructions = styled(BorderRadiusContainer)`
  border-radius: 5px 5px 0 0;
`;

export const RuleData = styled(BorderRadiusContainer)`
  border-radius: 5px 5px 0 0;
`;

export const InputContainer = styled(InputWrapper)``;

export const InstructionsLabel = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
`;

export const RuleDataLabel = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryLists};
`;
