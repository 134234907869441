import styled from 'styled-components';

export const Container = styled.div`
  font-size: 12px;
`;

export const ListItemContainer = styled.div`
  z-index: ${({ index }) => index} !important;
`;

export const SelectCheckobesContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: flex-end;

  & > div {
    justify-content: flex-start;
    color: ${({ theme }) => theme.primaryBlack} !important;
    font-weight: bold;
  }
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const ListItem = styled.li`
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > div:last-child {
    justify-content: center;
  }
`;

export const ItemValue = styled.div`
  color: ${({ theme }) => theme.primaryDark};
  display: flex;
  align-items: center;
  width: 33%;
  word-break: break-word;
`;
