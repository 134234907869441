/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import DatePickerAdapter from 'components/Forms/DatePickerAdapter';
import TimePickerAdapter from 'components/Forms/TimePickerAdapter';
import { FieldRadio } from 'components/Layout/Inputs';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SelectField from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';

import theme from 'theme';

import { matchOperator, constraints, optionNames, optionArray, dateTimeArray, timeArray } from './constants';
import { changeOptionNameControl } from './helpers';
import * as S from './styled';

const ItemRules = ({
  title,
  rules,
  addOption,
  removeOption,
  onKeyDownHandler,
  onChangeHandler,
  isInitialValues,
  isEditMode,
  initialValue,
  values,
  getOptionValue,
}) => (
  <S.Section>
    <S.SectionHeader>{title}</S.SectionHeader>
    <S.SectionBody>
      <S.MatchSection>
        <span>Match</span>
        <Field name={`match-${rules.name}`} defaultValue={rules.match.toString()}>
          {({ input }) => (
            <S.RadioGroup
              name={input.name}
              defaultSelected={rules.match.toString()}
              onChange={(e, value) => onChangeHandler(e, value, rules.name, input)}
              selectedValue={rules.match.toString()}
            >
              {Object.keys(matchOperator).map(key => (
                <FieldRadio
                  key={key}
                  value={key}
                  label={matchOperator[key]}
                  style={{ width: 'auto', marginRight: '15px', flex: key }}
                  iconStyle={{ fill: input.value === key && theme.primaryScheduler }}
                />
              ))}
            </S.RadioGroup>
          )}
        </Field>
      </S.MatchSection>
      {rules.rules.map(rule => (
        <S.RuleContainer key={`${rule.name}${rule.id}`}>
          <Field
            name={`optionName-${rule.id}`}
            component={SelectField}
            autoWidth
            onChangeControl={() => changeOptionNameControl(values, rule)}
            hintText={optionNames[rule.name][0].name}
            initialValue={isInitialValues ? rule[`optionName-${rule.id}`] : initialValue.is}
          >
            {optionNames[rule.name].map(({ name, value }) => (
              <SelectItem key={name} value={value} primaryText={name} />
            ))}
          </Field>
          <Field
            name={`constraint-${rule.id}`}
            component={SelectField}
            hintText={constraints[0]}
            disabled={isEditMode}
            initialValue={
              isInitialValues && rule.optionConstraint !== 0 ? `${rule[`constraint-${rule.id}`]}` : initialValue.is
            }
          >
            {Object.keys(constraints).map(key => (
              <SelectItem key={constraints[key]} value={key} primaryText={constraints[key]} />
            ))}
          </Field>
          {!dateTimeArray.includes(values[`optionName-${rule.id}`]) ? (
            (values[`optionName-${rule.id}`] === optionArray[1] &&
              values[`constraint-${rule.id}`] === Object.keys(constraints)[0]) ||
            values[`optionName-${rule.id}`] === optionArray[0] ? (
              <Field
                name={`optionValue-${rule.id}`}
                component={SelectField}
                hintText={getOptionValue(values[`optionName-${rule.id}`])?.[0]?.Name}
                initialValue={isInitialValues ? rule[`optionValue-${rule.id}`] : initialValue.is}
                style={{ width: '148px' }}
                autoWidth
              >
                {getOptionValue(values[`optionName-${rule.id}`]).map(({ Id, Value, Name }) => (
                  <SelectItem key={Id || Value} value={Name || Value} primaryText={Name} />
                ))}
              </Field>
            ) : (
              <Field
                name={`optionValue-${rule.id}`}
                component={TextFieldAdapter}
                hintText="Enter Parameter"
                type={'text'}
                initialValue={isInitialValues ? rule[`optionValue-${rule.id}`] : initialValue.is}
                onKeyDown={e => onKeyDownHandler(e)}
              />
            )
          ) : (
            <S.DateContainer>
              <Field
                name={`optionValue-${rule.id}`}
                component={timeArray.includes(values[`optionName-${rule.id}`]) ? TimePickerAdapter : DatePickerAdapter}
                floatingLabelText={
                  optionNames.item.filter(item => item.value === values[`optionName-${rule.id}`])[0].name
                }
              />
            </S.DateContainer>
          )}
          <S.RuleButtonsGroup>
            {rules.rules.length > 1 ? (
              <S.IconButton onClick={() => removeOption(rule.name, rule.id)}>
                <S.RemoveIcon />
              </S.IconButton>
            ) : null}
            <S.IconButton onClick={() => addOption(rule.name)}>
              <S.AddIcon />
            </S.IconButton>
          </S.RuleButtonsGroup>
        </S.RuleContainer>
      ))}
    </S.SectionBody>
  </S.Section>
);

ItemRules.propTypes = {
  title: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    name: PropTypes.string,
    match: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    getOptionValue: PropTypes.func.isRequired,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        optionName: PropTypes.string,
        optionConstraint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        optionValue: PropTypes.string,
        matchOperator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }).isRequired,
  isInitialValues: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  addOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  onKeyDownHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    is: PropTypes.string,
  }),
  values: PropTypes.shape().isRequired,
  getOptionValue: PropTypes.func.isRequired,
};

ItemRules.defaultProps = {
  initialValue: {
    is: '',
  },
};

export default ItemRules;
