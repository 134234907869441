import styled from 'styled-components';
import Modal from 'material-ui/Dialog';

import { isIE11 } from 'helpers';

export const Dialog = styled(Modal).attrs({
  actionsContainerStyle: {
    borderTop: ({ theme }) => `1px solid ${theme.lightGrey}`,
    padding: '3px',
    paddingBottom: '2px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bodyStyle: {
    overflow: 'auto',
    padding: '0px',
  },
  titleStyle: {
    borderBottom: ({ theme }) => `1px solid ${theme.lightGrey}`,
  },
  contentStyle: ({ modalStyles }) => modalStyles,
})`
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1900px),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1900px),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1900px),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 1900px),
    only screen and (min-resolution: 192dpi) and (min-width: 1900px),
    only screen and (min-resolution: 2dppx) and (min-width: 1900px) {
    padding-top: 12px !important;
  }

  & > div {
    & > div {
      width: 95% !important;
    }
  }
`;

export const SensorsDialog = styled(Dialog)`
  div > div {
    border-radius: 10px !important;
    overflow: ${isIE11 ? 'hidden' : 'auto'};

    div {
      border-radius: 0 !important;
      div > div {
        div {
          border-radius: 10px !important;

          div:nth-child(2) > div > div {
            border-radius: 0 !important;
            div {
              border-radius: 0 !important;
            }
          }
        }
        span {
          border-radius: 10px !important;
        }
      }
    }
  }

  @media only screen and (min-width: 20em) {
    & > div > div {
      width: 300px !important;
    }
  }
`;
