import styled from 'styled-components';
import { Box } from 'grid-styled';

import { ListContainer, ListItemContainer, Row } from 'components/Layout/Containers';

export const Column = styled(Box)`
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 30%;
`;

export const ButtonColumn = styled(Column)`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: visible;
  white-space: unset;
  width: 50px;
`;

export const Container = styled(ListContainer)`
  padding: 16px;
  padding-bottom: 26px;
`;

export const Header = styled(Row)`
  background: ${({ theme }) => theme.primaryDocuments};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  padding: 10px 5px 10px 10px;
`;

export const HeaderColumn = styled(Column)`
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 500;
  font-size: 16px;
`;

export const ItemContainer = styled(ListItemContainer)`
  & > div > div {
    padding: 0 6px 0 10px !important;

    & > div:first-child {
      order: 0;
    }
  }
`;

export const List = styled(Box).attrs({
  column: true,
})`
  border-radius: 4px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25) !important;

  div:first-child {
    border-radius: 4px 4px 0 0;
  }

  div:last-child {
    span {
      border-radius: 0 0 4px 4px;
    }
  }
`;
