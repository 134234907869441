export const QUICK_TASKS_RADIO_BUTTONS = [
  {
    label: 'One week',
    value: 0,
  },
  {
    label: 'Two weeks',
    value: 1,
  },
  {
    label: 'One month',
    value: 2,
  },
  {
    label: 'Never',
    value: 4,
  },
];

export const COMPLETED_TASKS_RADIO_BUTTONS = [
  {
    label: 'One month',
    value: 0,
  },
  {
    label: 'Two months',
    value: 1,
  },
  {
    label: 'Three months',
    value: 2,
  },
  {
    label: 'Six months',
    value: 4,
  },
  {
    label: 'Never',
    value: 8,
  },
];
