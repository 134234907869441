import React from 'react';
import PropTypes from 'prop-types';

import { Container, Button } from './styled';

const Footer = ({ onNext, onPrev, period }) => {
  let prev;
  let next;
  const isVisible = period !== 'past';

  if (period === 'day') {
    prev = 'Previous Day';
    next = 'Next Day';
  }
  if (period === 'week') {
    prev = 'Previous Week';
    next = 'Next Week';
  }

  return isVisible ? (
    <Container>
      <Button label={prev} onClick={onPrev} />
      <Button label={next} onClick={onNext} />
    </Container>
  ) : null;
};

Footer.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
};

export default Footer;
