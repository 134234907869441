import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import AssignmentTypeRadioGroup from './AssignmentTypeRadioGroup';
import * as S from './styled';

const types = [
  {
    title: 'PICKUP',
    description: 'All Tasks are open to anyone and any Team for pick-up (Open Assignment)',
  },
  {
    title: 'TEAM',
    description: 'All tasks are assigned to a whole team',
  },
  {
    title: 'PERSON',
    description: 'All tasks are assigned to one specific person',
  },
];

const AssignmentForm = ({ teamsAreEmpty }) => (
  <S.FormContainer>
    <Field
      component={AssignmentTypeRadioGroup}
      name="AssignmentType"
      style={S.radioStyle}
      teamsAreEmpty={teamsAreEmpty}
      defaultValue={0}
    />
    <S.LabelsBlock>
      {types.map(({ title, description }) => (
        <Fragment key={title}>
          <div data-tip={teamsAreEmpty && title === 'TEAM' ? "You don't have any teams" : ''}>
            <S.LabelsContainer>
              <S.Label>{title}</S.Label>
              <S.Text>{description}</S.Text>
            </S.LabelsContainer>
          </div>
          {teamsAreEmpty && title === 'TEAM' && <ReactTooltip />}
        </Fragment>
      ))}
    </S.LabelsBlock>
  </S.FormContainer>
);

AssignmentForm.propTypes = {
  teamsAreEmpty: PropTypes.bool.isRequired,
};

export default AssignmentForm;
