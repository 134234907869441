import styled from 'styled-components';

import http from '../../../../../http';
import { BorderRadiusContainer } from 'components/Layout/Containers';

export const ImageContainer = styled(BorderRadiusContainer)`
  border: 1px solid ${({ theme }) => theme.lightGrey};
  height: 225px;
  min-width: 260px;
  max-height: 225px;
  max-width: 260px;
  padding: 5px;
`;

export const ImageLabel = styled.div`
  margin-left: 5px;
  font-weight: 500;
`;

export const NoImageText = styled.div`
  height: 55%;
  width: 70px;
  margin: 0 auto;
`;

export const PictureItem = styled.div`
  width: 200px;
  height: 200px;
  margin 0 auto;
  background-image: url(${http.defaults.baseURL}/DocumentImage/Get?id=${({ pictureId }) => pictureId}&inline=true);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
