import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Layout/Icons';
import * as S from './styled';

const rowsRange = [10, 25, 50, 100];

class PageFooter extends PureComponent {
  static propTypes = {
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
  };

  handleBackButtonClick = () => {
    const { page, onPageChange } = this.props;
    onPageChange(page - 1);
  };

  handleNextButtonClick = () => {
    const { page, onPageChange } = this.props;
    onPageChange(page + 1);
  };

  render() {
    const { page, rowsPerPage, onRowsPerPageChange, count } = this.props;
    return (
      <S.Container>
        <S.Text>Rows per page:</S.Text>
        <S.Select hintText="range" value={rowsPerPage} onChange={(e, index, value) => onRowsPerPageChange(value)}>
          {rowsRange.map(period => (
            <S.Option key={period} value={period} primaryText={period} />
          ))}
        </S.Select>
        <S.Text>
          {count > 0 ? page * rowsPerPage - rowsPerPage + 1 : 0}-
          {page * rowsPerPage >= count ? count : page * rowsPerPage} of {count}{' '}
        </S.Text>
        <S.IconButton onClick={this.handleBackButtonClick} disabled={page < 2}>
          <ChevronLeftIcon />
        </S.IconButton>
        <S.IconButton onClick={this.handleNextButtonClick} disabled={page * rowsPerPage >= count}>
          <ChevronRightIcon />
        </S.IconButton>
      </S.Container>
    );
  }
}

export default PageFooter;
