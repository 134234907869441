import React from 'react';
import PropTypes from 'prop-types';

import withInputModal from 'components/Dialogs/withInputModal';

import * as S from './styled';

const SendLoginEmailDialogForm = ({ userName }) => (
  <S.Container>
    <S.ContentBlock>
      <div>Sites for</div>
      <div>
        <b>{userName}</b>
      </div>
      <div>have been changed</div>
    </S.ContentBlock>
    <S.ContentBlock>
      <div>Send user new login & welcome</div>
      <div>message?</div>
    </S.ContentBlock>
  </S.Container>
);

SendLoginEmailDialogForm.propTypes = {
  userName: PropTypes.string.isRequired,
};

const SendLoginEmailDialogWithInput = withInputModal(SendLoginEmailDialogForm);

const SendLoginEmailDialog = props => <SendLoginEmailDialogWithInput {...props} />;

export default SendLoginEmailDialog;
