import styled from 'styled-components';
import { Flex } from 'grid-styled';

export const Container = styled(Flex).attrs({
  flex: 1,
  justify: 'center',
  w: 1,
})`
  overflow-y: auto;
`;

export const Content = styled(Flex).attrs({
  flex: 1,
  justify: 'center',
  m: 'auto',
})``;

export const HomeInstructionsWrapper = styled(Flex).attrs({
  justify: 'center',
  pt: 70,
  pr: 1,
  pl: 1,
  direction: 'column',
  w: [1, 0.8, 0.7, 0.6],
})`
  @media screen and (min-width: 76em) {
    width: 60%;
  }

  @media screen and (min-width: 92em) {
    width: 40%;
  }
`;

export const WelcomeMessage = styled.div`
  font-family: 'Helvetic Name', sans-serif;
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  font-size: 50px;
  font-weight: 100;
  margin: 0 0 10px 0;
`;

export const IntroText = styled.div`
  font-family: 'Roboto Light';
  color: ${({ theme }) => theme.cardText};
  text-align: center;
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 30px;
`;

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
