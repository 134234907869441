import React, { Component } from 'react';
import IconButton from 'material-ui/IconButton';
import Download from 'material-ui/svg-icons/action/get-app';

import { DeleteIcon } from 'components/Layout/Icons';
import { ProgressCircular } from 'components/Layout/Progresses';
import { exportParts, getDownloadedPartsList, downloadPartListById, removePartsExport } from 'http/parts';

import * as S from './styled';

const styles = {
  iconButton: {
    padding: 0,
  },
};

export default class Parts extends Component {
  static propTypes = {};

  state = {
    list: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchPartsExports();
  }

  fetchPartsExports = () => {
    this.setState({ isLoading: true });
    getDownloadedPartsList()
      .then(list => {
        this.setState({ list, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleRemovePress = id => {
    removePartsExport(id).then(this.fetchPartsExports);
  };

  handleDownloadPress = id => downloadPartListById(id);

  handleCreateNewPress = () => {
    this.setState({ isLoading: true });
    exportParts()
      .then(({ data }) => {
        this.setState({ list: data.root, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { list, isLoading } = this.state;

    return (
      <S.Container>
        <S.Info>Showing 4 latest list maximum.</S.Info>
        {!list.length && <S.NoExports>There are no previous exports yet, please create a new export.</S.NoExports>}
        {isLoading ? (
          <S.Loader>
            <ProgressCircular />
          </S.Loader>
        ) : (
          <S.Exports>
            {list.map(item => (
              <S.Item key={item.Id}>
                <S.Details>{item.Name}</S.Details>
                <S.Actions>
                  <IconButton onClick={() => this.handleRemovePress(item.Id)} style={styles.iconButton}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => this.handleDownloadPress(item.Id)} style={styles.iconButton}>
                    <Download />
                  </IconButton>
                </S.Actions>
              </S.Item>
            ))}
          </S.Exports>
        )}

        <S.NewExport>
          <S.SubmitButton onClick={this.handleCreateNewPress} label="Create New" primary fullWidth />
        </S.NewExport>
      </S.Container>
    );
  }
}
