import styled from 'styled-components';

import CheckboxAdapter from 'components/Forms/CheckboxAdapter';
import { ProgressCircular } from 'components/Layout/Progresses';

export const Loader = styled(ProgressCircular).attrs({
  size: 90,
})`
  circle {
    stroke: ${({ theme }) => `${theme.primary} !important`};
  }
`;

export const Overlay = styled.div`
  display: ${({ show }) => !show && 'none !important'};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.overlay};
  z-index: 2000;
`;

export const Formcontainer = styled.form`
  margin: 0 20px;
`;

export const Separator = styled.div`
  height: 1px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.disabledColor};
`;

export const Checkbox = styled(CheckboxAdapter).attrs({
  labelStyle: ({ disabled, theme }) => ({
    color: disabled ? theme.disabledColor : theme.darkGrey,
  }),
})`
  margin: 0 !important;
  padding-left: 10px !important;
  align-self: center !important;
`;

export const DeleteAllDemoTasks = styled.div`
  display: flex;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-basis: 90%;
`;

export const ButtonContainer = styled.div``;
