import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ButtonRaised } from 'components/Layout/Buttons';

export const Button = styled(ButtonRaised).attrs({
  backgroundColor: props => (props['data-isactive'] ? props.theme.darkGrey : props.theme.lightGrey),
})`
  border: 1px solid ${({ theme }) => theme.darkGrey};
  // border-right-width: ${props => (props['data-isfirst'] || props['data-islast'] ? '1px' : '0')};
  // border-left-width: ${props => (props['data-isfirst'] || props['data-islast'] ? '1px' : '0')};
  border-bottom-left-radius: ${props => (props['data-isfirst'] ? '4px' : '0')} !important;
  border-bottom-right-radius: ${props => (props['data-islast'] ? '4px' : '0')} !important;
  border-top-left-radius: ${props => (props['data-isfirst'] ? '4px' : '0')} !important;
  border-top-right-radius: ${props => (props['data-islast'] ? '4px' : '0')} !important;
  color: ${props => (props['data-isactive'] ? props.theme.primaryWhite : props.theme.darkGrey)} !important;
  transition: unset !important;

  button {
    border-radius: 0 !important;
    transition: unset !important;
    min-width: ${({ minWidth }) => (minWidth ? '100px' : 'none')} !important;
    span {
      color: inherit !important;
      transition: unset !important;
      min-width: 55px;
      line-height: 38px;
    }

    &:disabled {
      span {
        color: ${({ theme }) => theme.primaryGrey} !important;
      }
    }
  }
`;

export const Container = styled(Flex)`
  position: relative;
  height: 38px;
`;

export const Item = styled.div`
  position: relative;
`;

export const Label = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  pointer-events: none;
  background: ${({ theme }) => theme.primaryRed};
  border-color: ${({ theme }) => theme.darkRed};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0.1em 0.1em;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 14px;
  height: 20px;
  min-width: 20px;
  padding: 0 3px;
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 99;
`;
