import http, { baseURL } from '../index';

export const exportParts = () => http.post('PartTemplate/GenerateAndListPartTemplate');

export const removePartsExport = id => http.post('PartTemplate/Delete', { partTemplateId: id });

export const downloadPartListById = id => {
  window.open(`${baseURL}/PartTemplate/DownloadPartTemplate?partTemplateId=${id}`);
};

export const getDownloadedPartsList = () => http.post('PartTemplate/List').then(({ data }) => data.root);

export const uploadParts = file => {
  const formData = new FormData();
  formData.append('file', file);

  return http.post('PartTemplate/UploadPartTemplate', formData).then(({ data }) => data);
};

export const commitUpload = id => http.post('PartTemplate/CommitPartTemplate', { partTemplateTemporaryId: id });

export const abortUpload = id => http.post('PartTemplate/AbortPartTemplate', { partTemplateTemporaryId: id });

export const fetchParts = () => http.post('Parts/ListParts');

export const fetchPartById = dto => http.post('Parts/GetPart', dto);

export const fetchPartsByGroupId = dto => http.post('Parts/ListPartsByGroup', dto);

export const createPart = partDto => http.post('Parts/CreatePart', { partDto });

export const partEdit = partDto => http.post('Parts/EditPart', { partDto });

export const fetchOEMs = () => http.post('Parts/ListOEMs');

export const createOEM = OEMDto => http.post('Parts/CreateOEM', { OEMDto });

export const OEMEdit = OEMDto => http.post('Parts/EditOEM', { OEMDto });

export const removeOEM = dto => http.post('Parts/DeleteOEM', dto);

export const fetchSuppliers = () => http.post('Parts/ListSuppliers');

export const createSupplier = supplierDto => http.post('Parts/CreateSupplier', { supplierDto });

export const supplierEdit = supplierDto => http.post('Parts/EditSupplier', { supplierDto });

export const removeSupplier = dto => http.post('Parts/DeleteSupplier', dto);

export const fetchPartGroups = () => http.post('Parts/ListPartGroups');

export const createPartGroup = partGroupDto => http.post('Parts/CreatePartGroup', { partGroupDto });

export const createPartGroupLink = dto => http.post('Parts/CreatePartGroupLink', dto);

export const removePartsGroupLink = dto => http.post('Parts/DeletePartGroupLinks', dto);

export const fetchListPartsByItem = dto => http.post('Parts/ListPartsByItem', dto);

export const addPartItemLink = dto => http.post('Parts/CreatePartItemLink', dto);

export const deletePartFromItem = dto => http.post('Parts/RemovePartFromItem', dto);

export const fetchListPartGroupsByItem = dto => http.post('Parts/ListPartGroupsByItem', dto);

export const addPartGroupItemLink = dto => http.post('Parts/CreatePartGroupItemLink', dto);

export const removePartFromGroup = dto => http.post('Parts/RemovePartFromPartGroup', dto);

export const addPartLinks = dto => http.post('DistributionLists/CreatePartLinks', dto);

export const fetchLastExecutionDate = dto => http.post('Parts/GetLastExecutionDate', dto);

export const deletePartGroupFromItem = dto => http.post('Parts/RemovePartGroupFromItem', dto);

export const removePart = payload => http.post('Parts/DeletePart', { partId: payload });

export const removeParts = payload => http.post('Parts/DeleteParts', { partIds: payload });

export const removePartGroup = payload => http.post('Parts/DeletePartGroup', { partGroupId: payload });

export const removePartGroups = payload => http.post('Parts/DeletePartGroups', { partGroupIds: payload });

export const addGroupLinks = dto => http.post('DistributionListGroups/CreateGroupLinks', dto);

export const fetchLastExecutionGroupDate = dto => http.post('Parts/GetGroupLastExecutionDate', dto);

export const getPartsTemplate = () => {
  window.open(`${baseURL}/PartTemplate/GenerateAndDownloadEmptyPartTemplate`);
};
