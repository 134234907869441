import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as queueActions } from 'redux/queue';
import moment from 'moment';

import DropdownMenu from 'modules/parts/pages/DropdownMenu';
import Subheader from 'components/Subheader';
import TableList from 'components/TableList';
import ColumnVisibility from 'components/ColumnVisibility';

import { BackIcon, AddSignUpNotification, CloudDownload, ImportIcon, DeleteIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import { convertDateToThreeDigit, searchItemByKeyword } from 'helpers';

import { WITHOUT_GROUPING } from '../ReportSetup/ReportGrouping/helpers';
import GroupedTable from './components/Table';
import DownloadReportDialog from './DownloadReportDialog';
import { tableColumns, downloadMenuItems } from './constants';

import * as S from './styled';

class ReportPreview extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      fetchReportPreview: PropTypes.func.isRequired,
      fetchReportRequest: PropTypes.func.isRequired,
      fetchListFileReportsRequest: PropTypes.func.isRequired,
      downloadReportFileRequest: PropTypes.func.isRequired,
      deleteReportFileRequest: PropTypes.func.isRequired,
      generateAndDownloadReportFileRequest: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: {
        reportId: PropTypes.string,
      },
    }).isRequired,
    reportList: PropTypes.array.isRequired,
    reportFilesList: PropTypes.array.isRequired,
    reportPreview: PropTypes.array.isRequired,
    selectedSite: PropTypes.string.isRequired,
    sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
  };

  state = {
    searchData: '',
    isOpenModal: false,
    anchorEl: null,
    tableColumns,
    reportDownloadModalOpened: false,
    fileFormat: '',
  };

  componentDidMount() {
    const {
      actions,
      match: { params },
    } = this.props;
    const tableConfigs = JSON.parse(localStorage.getItem('reportPreviewTableConfigs'));
    if (tableConfigs) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ tableColumns: tableConfigs });
    }

    actions.fetchReportPreview(params.reportId);
  }

  findSite = (sitesArray, selectedSite) => {
    const site = sitesArray.find(siteObject => siteObject.id === selectedSite);
    if (site.additionalId) {
      return `${site.additionalId.substring(0, 5)} ${site.name}`;
    }
    return `${site.name}`;
  };

  goBack = () => this.props.history.push('/queue/export');

  openDownloadReportFile = (e, value) => {
    const { actions, match } = this.props;
    actions.fetchListFileReportsRequest({ Id: match.params.reportId });
    this.setState({ reportDownloadModalOpened: true, fileFormat: value });
  };

  rightButtons = [
    {
      component: (
        <DropdownMenu
          onChange={this.openDownloadReportFile}
          headerText="Download"
          items={downloadMenuItems}
          icon={<CloudDownload />}
        />
      ),
      hint: 'Downloads',
      isComponent: true,
    },
    {
      icon: <AddSignUpNotification />,
      hint: 'Columns',
      handler: e => this.openModal(e),
    },
  ];

  leftButtons = [
    {
      icon: <BackIcon />,
      handler: this.goBack,
      hint: 'Back',
    },
  ];

  mapList = item => ({
    ...item,
    CompletedOn: convertDateToThreeDigit(item.CompletedOn),
    ExecutionDate: convertDateToThreeDigit(item.CompletedOn),
  });

  searchInList = e => this.setState({ searchData: e.target.value });

  openModal = e =>
    this.setState({
      isOpenModal: true,
      anchorEl: e.currentTarget,
    });

  closeModal = () => this.setState({ isOpenModal: false });

  setCheckedLabel = checkedLabel => this.setState({ tableColumns: checkedLabel });

  deleteReportFile = item => this.props.actions.deleteReportFileRequest({ fileId: item.Id });

  downloadReportFile = item => this.props.actions.downloadReportFileRequest({ fileId: item.Id });

  rightControls = item => (
    <div>
      <ButtonIcon onClick={() => this.deleteReportFile(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => this.downloadReportFile(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  closeDownloadReportDialog = () => {
    this.setState({
      reportDownloadModalOpened: false,
      format: '',
    });
  };

  downloadReport = () => {
    const { actions, match } = this.props;
    actions.generateAndDownloadReportFileRequest({ Id: match.params.reportId });
    this.closeDownloadReportDialog();
  };

  render() {
    const { searchData, isOpenModal, anchorEl, fileFormat, reportDownloadModalOpened } = this.state;
    const {
      reportPreview,
      reportFilesList,
      sitesAvailableToUserFullInfo,
      selectedSite,
      match: { params },
      reportList,
    } = this.props;
    const report = reportList.find(({ Id }) => Id === params.reportId);
    const { Name, GroupBy, SubGroupBy, EndDate, StartDate } = report || {};
    const withGrouping = GroupBy !== WITHOUT_GROUPING && SubGroupBy !== WITHOUT_GROUPING;

    return (
      <>
        <Subheader
          leftButtons={this.leftButtons}
          isSiteOnLeftSide
          rightButtons={this.rightButtons}
          withBorder
          title="Task Reports"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          redSubtitle={Name}
        />
        <ColumnVisibility
          labels={this.state.tableColumns}
          setSelectedLabel={this.setCheckedLabel}
          anchorEl={anchorEl}
          open={isOpenModal}
          handleRequestClose={this.closeModal}
        />

        {/* eslint-disable-next-line no-nested-ternary */}
        {!reportPreview?.length ? (
          <S.EmptyList>
            <S.EmptyListText>There is no data available to create a report.</S.EmptyListText>
            <S.EmptyListText>Please adjust the rules and try again.</S.EmptyListText>
          </S.EmptyList>
        ) : withGrouping ? (
          <GroupedTable
            list={reportPreview}
            tableColumns={this.state.tableColumns}
            tableHeads={this.state.tableColumns}
            searchData={searchData}
          />
        ) : (
          <S.TableContainer>
            <TableList
              list={reportPreview.map(this.mapList).filter(searchItemByKeyword(tableColumns, searchData))}
              tableColumns={this.state.tableColumns}
              tableHeads={this.state.tableColumns}
            />
          </S.TableContainer>
        )}
        <DownloadReportDialog
          key="Date Range Dialog"
          title={fileFormat === 'pdf' ? 'Export PDF' : 'Export CSV'}
          endDate={moment(EndDate).format('ddd, MMM-DD-YYYY')}
          startDate={moment(StartDate).format('ddd, MMM-DD-YYYY')}
          handleClose={this.closeDownloadReportDialog}
          onSubmit={this.downloadReport}
          open={reportDownloadModalOpened}
          list={reportFilesList}
          rightControls={this.rightControls}
          site={this.findSite(sitesAvailableToUserFullInfo, selectedSite)}
          redTitle={Name}
          fileFormat={fileFormat}
        />
      </>
    );
  }
}

const mapStateToProps = ({ queue, auth }) => ({
  reportList: queue.reportList,
  reportPreview: queue.reportPreview,
  reportFilesList: queue.reportFilesList,
  report: queue.report,
  selectedSite: auth.selectedSite,
  sitesAvailableToUserFullInfo: auth.user.sitesAvailableToUserFullInfo,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchReportPreview: queueActions.fetchReportPreviewRequest,
      fetchReportRequest: queueActions.fetchReportRequest,
      fetchListFileReportsRequest: queueActions.fetchListFileReportsRequest,
      downloadReportFileRequest: queueActions.downloadReportFileRequest,
      deleteReportFileRequest: queueActions.deleteReportFileRequest,
      generateAndDownloadReportFileRequest: queueActions.generateAndDownloadReportFileRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPreview);
