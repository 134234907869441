import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import theme from '../../../../theme';
import { ButtonIcon } from 'components/Layout/Buttons';
import { DeleteIcon } from 'components/Layout/Icons';
import { TextareaField } from 'components/Layout/Textarea';

import * as S from './styled';

class NotificationBlock extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    name: PropTypes.shape({
      0: PropTypes.string.isRequired,
      1: PropTypes.string.isRequired,
      2: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
  };

  resetBlock = () => {
    const { form, title, values } = this.props;

    const valuesForReset = {};

    valuesForReset[`Salutation${title.replace(/[/\s//]/g, '')}`] = '';
    valuesForReset[`IntroText${title.replace(/[/\s//]/g, '')}`] = '';
    valuesForReset[`ClosingText${title.replace(/[/\s//]/g, '')}`] = '';

    form.reset({
      ...values,
      ...valuesForReset,
    });
  };

  render() {
    const { disabled, name, title } = this.props;

    return (
      <S.FormContainer>
        <S.Instructions>
          <S.InstructionsLabel>
            <S.SpecialistTitle>{title}</S.SpecialistTitle>
            <ButtonIcon onClick={this.resetBlock} disabled={disabled} tooltip={disabled ? '' : 'Clear Text'}>
              <DeleteIcon color={theme.primaryWhite} />
            </ButtonIcon>
          </S.InstructionsLabel>
          <S.InputContainer>
            <Field component={TextareaField} name={name[0]} floatingLabelText="Salutation" />
            <Field component={TextareaField} name={name[1]} floatingLabelText="Intro text" rows={9} />
            <Field component={TextareaField} name={name[2]} floatingLabelText="Closing text" rows={3} />
          </S.InputContainer>
        </S.Instructions>
      </S.FormContainer>
    );
  }
}

export default NotificationBlock;
