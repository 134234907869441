import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const DialogTitle = ({ handleClose, handleDelete, title, titleColor, withDelete, withCloseButton }) => (
  <S.Container titleColor={titleColor}>
    {title}
    {withDelete && <S.DeleteButton onClick={handleDelete} />}
    {withCloseButton && (
      <S.CloseButton onClick={handleClose}>
        <S.IconClose />
      </S.CloseButton>
    )}
  </S.Container>
);

DialogTitle.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleColor: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
  withCloseButton: PropTypes.bool,
};

DialogTitle.defaultProps = {
  handleDelete: null,
  withCloseButton: true,
  title: null,
  withDelete: false,
};

export default DialogTitle;
