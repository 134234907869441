import theme from 'theme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

export const SLIDER_MIN_VALUE = 1;
export const SLIDER_MAX_VALUE = 12;
export const SLIDER_STEP_VALUE = 1;

export const PERIOD_TYPE = {
  NONE: -1,
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
};

export const options = [
  { name: 'Days', value: 0 },
  { name: 'Weeks', value: 1 },
  { name: 'Months', value: 2 },
];

export const typeRadioStyle = {
  display: 'flex',
  width: '350px',
  margin: '0 auto',
};

export const muiTheme = getMuiTheme({
  slider: {
    trackColor: theme.primaryHealth,
    selectionColor: theme.mainRed,
    trackColorSelected: theme.primaryHealth,
    handleSize: 24,
    handleSizeActive: 24,
    trackSize: 6,
  },
});

export const getPeriod = (type, limit) => {
  let period = '';

  switch (type) {
    case PERIOD_TYPE.DAY:
      period = 'days';
      break;
    case PERIOD_TYPE.WEEK:
      period = 'weeks';
      break;
    case PERIOD_TYPE.MONTH:
      period = 'months';
      break;
    default:
      break;
  }

  return `${limit} ${period}`;
};
