import React from 'react';
import PropTypes from 'prop-types';

import { Container, ReleaseButton, Centered, Label } from './styled';

const buttonLabel = {
  approved: 'Copy To a Tenant',
  new: 'Approve',
};

const Footer = ({ onCenter, status, isChecked, quantity }) => (
  <Container>
    <Centered>
      <ReleaseButton disabled={!isChecked} label={buttonLabel[status]} onClick={onCenter}>
        {!!quantity && <Label>{quantity}</Label>}
      </ReleaseButton>
    </Centered>
  </Container>
);

Footer.defaultProps = {
  // isCentralButtonVisible: true,
  onCenter: () => {},
  isChecked: true,
  status: 'new',
  quantity: 0,
};

Footer.propTypes = {
  onCenter: PropTypes.func,
  isChecked: PropTypes.bool,
  // isCentralButtonVisible: PropTypes.bool,
  status: PropTypes.string,
  quantity: PropTypes.number,
};

export default Footer;
