import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'material-ui';

import * as CS from 'components/Dialogs/styled';

import * as S from './styled';

import theme from 'theme';

const BASE_INDEX = 1000;

class CheckboxList extends Component {
  static propTypes = {
    checkedTemplate: PropTypes.shape().isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setCheckedTemplate: PropTypes.func.isRequired,
    setCurrentTemplate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checkedMembers: [],
  };

  onItemCheck = Id => {
    const template = this.props.list.find(item => item.Id === Id);
    this.props.setCheckedTemplate(template);
    this.props.setCurrentTemplate(template);
  };

  onCheckboxClick = e => {
    e.stopPropagation();
  };

  render() {
    const { checkedTemplate, list } = this.props;

    return (
      <S.Container>
        <S.SelectCheckobesContainer>
          <S.ItemValue>Unit/Object Name</S.ItemValue>
          <S.ItemValue>Model</S.ItemValue>
          <S.ItemValue>Year</S.ItemValue>
          <S.ItemValue>Items</S.ItemValue>
        </S.SelectCheckobesContainer>
        <CS.DividerLine />
        <S.ListContainer>
          {list.length > 0 &&
            list.map(({ Id, Name, ModelNumber, YearBuilt, Items, UnitType }, index) => {
              const isDemo = UnitType === 'Demo';

              return (
                <S.ListItemContainer index={BASE_INDEX - index} key={Id}>
                  <S.ListItem>
                    {isDemo && (
                      <IconButton tooltip="demo list">
                        <S.Loyalty />
                      </IconButton>
                    )}
                    <CS.ItemRadio
                      checked={checkedTemplate.Id === Id}
                      onCheck={(e, checked) => this.onItemCheck(Id, checked)}
                      onClick={this.onCheckboxClick}
                      iconStyle={{ fill: checkedTemplate.Id === Id && theme.mainRed }}
                    />
                    <S.ItemValue>{Name}</S.ItemValue>
                    <S.ItemValue>{ModelNumber}</S.ItemValue>
                    <S.ItemValue>{YearBuilt}</S.ItemValue>
                    <S.ItemValue>{Items.length}</S.ItemValue>
                  </S.ListItem>
                  <CS.DividerLine />
                </S.ListItemContainer>
              );
            })}
        </S.ListContainer>
      </S.Container>
    );
  }
}

export default CheckboxList;
