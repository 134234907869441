import React from 'react';
import { Field } from 'react-final-form';
import * as S from './styled';

const LinkIntro = () => (
  <S.IntroContainer>
    <S.IntroLabel>Link Intro Text</S.IntroLabel>
    <Field component={S.TextField} name="LinkIntroText" hintText="Link Text" underlineShow={false} multiLine rows={4} />
  </S.IntroContainer>
);

export default LinkIntro;
