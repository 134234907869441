import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import theme from '../../../../theme';

import * as S from './styled';

class RadioGroup extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    input: PropTypes.shape({
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      error: PropTypes.string,
      meta: PropTypes.bool,
      visited: PropTypes.bool.isRequired,
      touched: PropTypes.bool.isRequired,
    }).isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    values: {},
  };

  handleOnChange = (data, input) => {
    const { form, values } = this.props;
    input.onChange(data);

    if (data === 'UseUniqueID') {
      form.reset({
        ...values,
        DefaultWebAddress: '',
        UseGroupKeyForQRCodes: data,
      });
    }
  };

  render() {
    const { input, meta } = this.props;

    return (
      <S.RadioGroup
        name="UseGroupKeyForQRCodes"
        valueSelected={input.value}
        onChange={(event, data) => this.handleOnChange(data, input)}
        errorText={meta.visited || meta.touched ? meta.error : ''}
      >
        <S.FieldRadio label="Use unique id's only" labelStyle={{ color: theme.disabledColor }} value="UseUniqueID" />
        <S.FieldRadio
          label="Use default web address (enter below)"
          labelStyle={{ color: theme.disabledColor }}
          value="UseDefaultWebAddress"
        />
        <S.FieldRadio
          label="Use default web address and Unique ID together"
          labelStyle={{ color: theme.disabledColor }}
          value="UseUniqueIDAndDefaultWebAddress"
        />
      </S.RadioGroup>
    );
  }
}

export default RadioGroup;
