import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import * as S from './styled';

const InfoPage = ({ currentSite, currentTemplate, onNext, onExit, limit, currentItemsNumber, openItemLimitDialog }) => (
  <S.DialogContainer>
    <S.TopInfo>
      <S.Title>
        {currentSite.additionalId.length > 0
          ? `${currentSite.additionalId.substring(0, 5)} ${currentSite.name}`
          : currentSite.name}
      </S.Title>
    </S.TopInfo>
    <S.Text>The following List Template is being imported:</S.Text>
    <S.BoldText>{currentTemplate.Name}</S.BoldText>
    <ActionsContainer>
      <ActionButton
        label="Next"
        isNext
        onClick={() => {
          if (currentItemsNumber >= limit) {
            onExit();
            openItemLimitDialog();
          } else {
            onNext();
          }
        }}
      />
    </ActionsContainer>
  </S.DialogContainer>
);

InfoPage.propTypes = {
  currentSite: PropTypes.shape().isRequired,
  currentTemplate: PropTypes.shape().isRequired,
  onNext: PropTypes.func.isRequired,
  openItemLimitDialog: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  currentItemsNumber: PropTypes.number.isRequired,
};

const mapStateToProps = ({ listTemplates }) => ({
  limit: listTemplates.limits.MaxItemsNumber,
  currentItemsNumber: listTemplates.limits.CurrentItemsNumber,
});

export default connect(mapStateToProps, null)(InfoPage);
