import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const ItemName = styled.div`
  flex: 1;
  max-width: 360px;
  word-wrap: break-word;
  padding: 5px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  background-color: #666 !important;
  color: white !important;
  max-width: 150px;
  max-height: 50px;
  margin-left: 10px !important;
  padding: 8px 10px;
  &:after {
    display: none;
  }
`;
