import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import BaseDialog from 'components/Dialogs/Base';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';

import * as CS from 'components/Dialogs/styled';

import { getTimezoneOffset } from 'helpers';

import * as S from './styled';

const allTimezones = moment.tz.names();

const getTime = timezone => parseInt(timezone.split(':')[0], 10);

const getTimezone = location =>
  location
    .map(zone => ({ location: zone, timezone: `${getTimezoneOffset(zone)} ${zone}` }))
    .sort((a, b) => {
      if (getTime(a.timezone) === getTime(b.timezone) && a.timezone > b.timezone) {
        return 1;
      } else if (getTime(a.timezone) > getTime(b.timezone)) {
        return 1;
      }
      return -1;
    });

class SetTimezoneDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    currentSite: PropTypes.shape({
      SiteId: PropTypes.string,
      Name: PropTypes.string,
      TimeZoneIanaId: PropTypes.string,
      offset: PropTypes.string,
    }).isRequired,
    checkedSitesNumber: PropTypes.number.isRequired,
    selectedTimezoneValue: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    selectTimezone: PropTypes.func.isRequired,
    applyToCheckedSelections: PropTypes.func.isRequired,
    applyToCurrentSite: PropTypes.func.isRequired,
    isSelectTimezone: PropTypes.bool.isRequired,
    closeSelectTimezone: PropTypes.func.isRequired,
    searchTimezone: PropTypes.string.isRequired,
    setSearchTimezone: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedTimezoneValue: '',
  };

  state = {
    sitesForSearch: getTimezone(allTimezones),
    sites: getTimezone(allTimezones),
  };

  onSelectChange = (e, index, value) => this.props.selectTimezone(value);

  onClickItem = value => {
    this.props.setSearchTimezone('');
    this.setState({ sites: getTimezone(allTimezones) });
    this.props.selectTimezone(value.location);
  };

  setSearchedSites = (sites, searchData) => {
    this.setState({ sites });
    this.props.setSearchTimezone(searchData);
  };

  closeModal = () => {
    this.props.handleClose();
    this.setState({ sites: getTimezone(allTimezones) });
  };

  render() {
    const {
      open,
      title,
      currentSite,
      checkedSitesNumber,
      selectedTimezoneValue,
      applyToCheckedSelections,
      applyToCurrentSite,
      isSelectTimezone,
      closeSelectTimezone,
      searchTimezone,
    } = this.props;
    const { sitesForSearch, sites } = this.state;

    const disableButtons = !selectedTimezoneValue && !currentSite.TimeZoneIanaId;
    const currentOffset = selectedTimezoneValue ? getTimezoneOffset(selectedTimezoneValue) : '';

    return (
      <BaseDialog open={open} onRequestClose={this.closeModal} title={title}>
        <S.DialogContainer>
          <CS.CurrentSiteContainer>
            <S.CurrentSiteName>Current Site: ${currentSite.Name}</S.CurrentSiteName>
            <CS.SelectorContainer>
              <S.SelectorLabel>
                <S.Label>City time zone:</S.Label>
                {(selectedTimezoneValue || currentSite.TimeZoneIanaId) && (
                  <S.TimezoneLabel>
                    <S.Timezone>{selectedTimezoneValue || currentSite.TimeZoneIanaId}</S.Timezone>
                    <S.Offset>{`UTC ${currentOffset || currentSite.offset}`}</S.Offset>
                  </S.TimezoneLabel>
                )}
              </S.SelectorLabel>
              {isSelectTimezone ? (
                <S.Select
                  hintText="Select City"
                  value={selectedTimezoneValue}
                  onChange={this.onSelectChange}
                  onClick={closeSelectTimezone}
                >
                  {!!allTimezones.length &&
                    getTimezone(allTimezones).map(value => (
                      <S.Option key={value.location} value={value.location} primaryText={value.timezone} />
                    ))}
                </S.Select>
              ) : (
                <S.Container>
                  <S.SearchContainer>
                    <SearchField
                      list={sitesForSearch}
                      filterBy="timezone"
                      searchData={searchTimezone}
                      placeholder="Filter Zones"
                      underlineShow={false}
                      icon={<SearchIcon />}
                      setSearchedItems={this.setSearchedSites}
                    />
                  </S.SearchContainer>
                  <S.ListContainer>
                    {sites.map(value => (
                      <S.Option
                        key={value.location}
                        value={value.location}
                        primaryText={value.timezone}
                        onClick={() => this.onClickItem(value)}
                      />
                    ))}
                  </S.ListContainer>
                </S.Container>
              )}
            </CS.SelectorContainer>
          </CS.CurrentSiteContainer>
          <CS.DialogFooter>
            {checkedSitesNumber > 1 && (
              <CS.ButtonContainer onClick={() => applyToCheckedSelections(currentOffset)}>
                <CS.SaveButton submitLabel={`Apply to ${checkedSitesNumber} selections`} disabled={disableButtons} />
              </CS.ButtonContainer>
            )}
            <CS.ButtonContainer onClick={() => applyToCurrentSite(currentOffset)} fullWidth={checkedSitesNumber < 2}>
              <CS.SaveButton submitLabel="Apply to this Site" disabled={disableButtons} />
            </CS.ButtonContainer>
          </CS.DialogFooter>
        </S.DialogContainer>
      </BaseDialog>
    );
  }
}

export default SetTimezoneDialog;
