import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const ConfirmPartRemovalText = ({ linksCount }) => (
  <div>
    <div>Are you sure you want to delete this part?</div>
    {linksCount ? <S.AdditionalWarning>This part is Actively linked!</S.AdditionalWarning> : null}
  </div>
);

ConfirmPartRemovalText.propTypes = {
  linksCount: PropTypes.number.isRequired,
};

export default ConfirmPartRemovalText;
