import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import LevelsQuantityRadioGroup from './LevelsQuantityRadioGroup';
import LevelOptionsRadioGroup from './LevelOptionsRadioGroup';

import * as S from './styled';

const LevelsQuantityDialogForm = ({ escalation, form, values }) => (
  <S.Container>
    <S.Block>
      <S.Label>Levels Quantity (Escalate {escalation === 'both' ? 'Both Ways' : 'Up or Down'})</S.Label>
      <Field
        component={LevelsQuantityRadioGroup}
        name="quantity"
        form={form}
        values={values}
        isBoth={escalation === 'both'}
      />
    </S.Block>
    <S.Block>
      <S.Label>Level Options</S.Label>
      <Field component={LevelOptionsRadioGroup} name="options" escalation={escalation} values={values} />
    </S.Block>
  </S.Container>
);

LevelsQuantityDialogForm.propTypes = {
  escalation: PropTypes.string.isRequired,
  form: PropTypes.shape({
    reset: PropTypes.func.isRequired,
  }).isRequired,
  values: PropTypes.shape({}).isRequired,
};

const LevelsQuantityDialogWithInput = withInputModal(LevelsQuantityDialogForm);

const LevelsQuantityDialog = props => (
  <LevelsQuantityDialogWithInput initialValues={{ quantity: '2', options: '2' }} {...props} />
);

export default LevelsQuantityDialog;
