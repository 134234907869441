import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const SiteSelector = ({ siteName, onClick }) => (
  <S.SelectorContainer onClick={onClick}>
    <S.IconContainer>
      <S.LocationAction />
    </S.IconContainer>
    <S.ModuleLabel>{siteName || 'Site Selection'}</S.ModuleLabel>
  </S.SelectorContainer>
);

SiteSelector.propTypes = {
  siteName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

SiteSelector.defaultProps = {
  siteName: '',
};

export default SiteSelector;
