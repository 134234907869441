import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as CS from 'components/Dialogs/styled';

import * as S from './styled';

import theme from 'theme';

const BASE_INDEX = 1000;

class CheckboxList extends Component {
  static propTypes = {
    checkedSite: PropTypes.shape().isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setCheckedSite: PropTypes.func.isRequired,
    setCurrentSite: PropTypes.func.isRequired,
    isSiteSelection: PropTypes.bool,
  };

  static defaultProps = {
    checkedMembers: [],
    isSiteSelection: false,
  };

  onItemCheck = id => {
    const { setCurrentSite, setCheckedSite, list } = this.props;
    const site = list.find(item => item.id === id);
    setCheckedSite(site);
    if (setCurrentSite) setCurrentSite(site);
  };

  onCheckboxClick = e => {
    e.stopPropagation();
  };

  render() {
    const { checkedSite, list, isSiteSelection } = this.props;

    return (
      <S.Container>
        <S.ListContainer>
          {list.length > 0 &&
            list.map(({ id, name, additionalId }, index) => (
              <S.ListItemContainer key={id} index={BASE_INDEX - index}>
                <S.ListItem onClick={() => this.onItemCheck(id)}>
                  <S.ItemNameAndCheckbox isSiteSelection={isSiteSelection}>
                    <CS.ItemRadio
                      checked={checkedSite.id === id}
                      onCheck={(e, checked) => this.onItemCheck(id, checked)}
                      onClick={this.onCheckboxClick}
                      iconStyle={{ fill: checkedSite.id === id && theme.mainRed }}
                    />
                    {isSiteSelection ? (
                      <S.SiteSelectionItems>
                        <span>{additionalId && additionalId.substring(0, 5)}</span>
                        <span>{name}</span>
                      </S.SiteSelectionItems>
                    ) : (
                      <CS.ItemName>
                        {additionalId.length > 0 ? `${additionalId.substring(0, 5)} ${name}` : name}
                      </CS.ItemName>
                    )}
                  </S.ItemNameAndCheckbox>
                </S.ListItem>
                <CS.DividerLine />
              </S.ListItemContainer>
            ))}
        </S.ListContainer>
      </S.Container>
    );
  }
}

export default CheckboxList;
