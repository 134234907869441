import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import { Notification, TaskType, Footer, Button, CloseButton, ContentContainer } from './styled';

class DublicateDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    dublicationTaskType: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    itemId: PropTypes.string,
    taskId: PropTypes.string.isRequired,
    dublicateTaskRequest: PropTypes.func.isRequired,
  };

  static defaultProps = {
    itemId: '',
  };

  render() {
    const { dublicationTaskType, handleClose, open, itemId, taskId, dublicateTaskRequest } = this.props;

    return (
      <BaseDialog onRequestClose={handleClose} open={open} title="Duplicate Task">
        <ContentContainer>
          <TaskType>{dublicationTaskType}</TaskType>
          <Notification>This task will be duplicated</Notification>
        </ContentContainer>
        <Footer>
          <Button label="OK" onClick={() => dublicateTaskRequest({ params: { itemId, taskId } })} />
          <CloseButton onClick={handleClose} />
        </Footer>
      </BaseDialog>
    );
  }
}

export default DublicateDialog;
