import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';

import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as newSitesActions } from 'redux/newSites';

import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import { checkOptions, countUnitsAndItems } from 'components/Layout/ShortcutRules/helpers';
import { optionRequestByType } from 'components/Dialogs/Shortcut/ShortcutSiteSetup/helpers';
import TestrunButton from 'components/Buttons/TestrunButton';

import { getAvailabilityOptions } from 'http/system';

import { AssignmentType } from 'configs/types';
import { ASSIGNMENT_TYPE } from '../constants';

import SectionRules from './SectionRules';
import { generateManagementRules, generateTeamRules, generatePersonRules } from './helpers';
import * as S from './styled';

const initialRule = {
  optionName: '',
  optionConstraint: 0,
  optionValue: '',
  matchOperator: 0,
};

const createId = (flag = '') => `${flag}${new Date().getTime()}`;

class Pickup extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      sendManagementOptionsRequest: PropTypes.func.isRequired,
      sendPersonOptionsRequest: PropTypes.func.isRequired,
      editSiteScheduleRequest: PropTypes.func.isRequired,
      sendTeamOptionsRequest: PropTypes.func.isRequired,
      getJobsListRequest: PropTypes.func.isRequired,
      listStatusRequest: PropTypes.func.isRequired,
    }).isRequired,
    shortcut: PropTypes.shape().isRequired,
    currentSchedule: PropTypes.shape().isRequired,
    currentSite: PropTypes.shape().isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    manager: PropTypes.shape().isRequired,
    person: PropTypes.shape().isRequired,
    team: PropTypes.shape().isRequired,
    error: PropTypes.bool.isRequired,
    teamsList: PropTypes.shape().isRequired,
    jobsList: PropTypes.shape().isRequired,
    listStatus: PropTypes.shape().isRequired,
    listOfItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  state = {
    managementRules: {
      name: 'management',
      match: 0,
      rules: [{ id: createId('management'), name: 'management', ...initialRule }],
      isRulesIgnored: false,
    },
    personRules: {
      name: 'person',
      match: 0,
      rules: [{ id: createId('person'), name: 'person', ...initialRule }],
      isRulesIgnored: false,
    },
    teamRules: {
      name: 'team',
      match: 0,
      rules: [{ id: createId('team'), name: 'team', ...initialRule }],
      isRulesIgnored: false,
    },
    isPreviewShown: false,
    isPreviewPersonShown: false,
    isErrorShown: false,
    list: [],
    data: { 'match-management': '0' },
  };

  componentDidMount() {
    this.fetchAvailabilityOptions();
    this.props.actions.getJobsListRequest();
    this.props.actions.listStatusRequest();
  }

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  changeData = (values, name, value, id) => {
    values[`optionValue-${id}`] = '';
    values[`constraint-${id}`] = '';
    values[name] = value;
    this.setState({ data: values });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onSubmitForm = ({ values }) => {
    const { currentSchedule, onNext, actions } = this.props;
    const { personRules, managementRules, teamRules } = this.state;

    const updatedValuesToSend = {
      ...generatePersonRules(values, personRules.rules),
      ...generateManagementRules(values, managementRules.rules),
      ...generateTeamRules(values, teamRules.rules),
      id: currentSchedule.Id,
      AssignmentType: AssignmentType[currentSchedule.AssignmentType - 1],
      onNext,
    };

    if (
      (currentSchedule.AssignmentType === ASSIGNMENT_TYPE.PERSON && !checkOptions(updatedValuesToSend.PersonRules)) ||
      (currentSchedule.AssignmentType === ASSIGNMENT_TYPE.TEAM && !checkOptions(updatedValuesToSend.TeamRules)) ||
      (currentSchedule.AssignmentType === ASSIGNMENT_TYPE.MANAGER && !checkOptions(updatedValuesToSend.ManagementRules))
    ) {
      this.setState({ isErrorShown: true });
    } else {
      const checkOptionRequest = actions[optionRequestByType[currentSchedule.AssignmentType - 1]];

      this.setState({ isErrorShown: false });
      if (currentSchedule.AssignmentType === ASSIGNMENT_TYPE.PERSON) {
        this.getPreviewResults(values);
      }
      checkOptionRequest({
        PersonRules: updatedValuesToSend.PersonRules,
        TeamRules: updatedValuesToSend.TeamRules,
        ManagementRules: updatedValuesToSend.ManagementRules,
        AssignmentType: AssignmentType[currentSchedule.AssignmentType - 1],
        SiteId: currentSchedule.SiteID,
        onNext: () => actions.editSiteScheduleRequest(updatedValuesToSend),
      });
      this.setState({ isPreviewShown: false, isPreviewPersonShown: false });
    }
  };

  onPrevious = e => {
    e.preventDefault();
    this.setState({ isPreviewShown: false, isPreviewPersonShown: false, isPreviewTeamShown: false });
    this.props.onBack();
  };

  getPersonPreviewResults = values => {
    const { currentSchedule, actions, currentSite } = this.props;

    const updatedValuesToSend = {
      ...generatePersonRules(values, this.state.personRules.rules),
      AssignmentType: AssignmentType[currentSchedule.AssignmentType - 1],
      id: currentSchedule.Id,
      siteId: currentSite.Id,
    };
    if (!checkOptions(updatedValuesToSend.PersonRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      this.setState({ isPreviewPersonShown: true });
      actions.sendPersonOptionsRequest(updatedValuesToSend);
    }
  };

  getTeamPreviewResults = values => {
    const { currentSchedule, actions, currentSite } = this.props;

    const valuesToSend = generateTeamRules(values, this.state.teamRules.rules);

    const updatedValuesToSend = {
      ...valuesToSend,
      id: currentSchedule.Id,
      siteId: currentSite.Id,
      AssignmentType: AssignmentType[currentSchedule.AssignmentType - 1],
    };
    if (!checkOptions(valuesToSend.TeamRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      this.setState({ isPreviewTeamShown: true });
      actions.sendTeamOptionsRequest(updatedValuesToSend);
    }
  };

  getPreviewResults = values => {
    const { currentSchedule, actions, currentSite } = this.props;

    const valuesToSend = generateManagementRules(values, this.state.managementRules.rules);
    const updatedValuesToSend = {
      ...valuesToSend,
      id: currentSchedule.Id,
      siteId: currentSite.Id,
      AssignmentType: AssignmentType[currentSchedule.AssignmentType - 1],
    };
    if (!checkOptions(valuesToSend.ManagementRules)) {
      this.setState({ isErrorShown: true });
    } else {
      this.setState({ isErrorShown: false });
      this.setState({ isPreviewShown: true });
      actions.sendManagementOptionsRequest(updatedValuesToSend);
    }
  };

  generatePreviewResults = manager => (
    <S.PreviewItem>
      <S.PreviewItemText>
        {manager.FirstName && `${manager.FirstName} `}
        {manager.LastName && `${manager.LastName}`}
      </S.PreviewItemText>
    </S.PreviewItem>
  );

  generateTeamPreviewResults = team => (
    <S.PreviewItem>
      <S.PreviewItemText>{team.Name}</S.PreviewItemText>
    </S.PreviewItem>
  );

  fetchAvailabilityOptions = () => {
    this.setState({ isLoading: true });
    getAvailabilityOptions()
      .then(({ data: { root } }) => this.setState({ list: root, isLoading: false }))
      .catch(error => this.setState({ isLoading: false, error: error.toString() }));
  };

  render() {
    const {
      currentSite,
      currentSchedule,
      shortcut,
      manager,
      person,
      error,
      team,
      listStatus,
      listOfItems,
      teamsList,
      jobsList,
    } = this.props;
    const { data } = this.state;
    const unitsAndItems = countUnitsAndItems(listOfItems, currentSite.Id);

    return (
      <S.Container>
        <S.MainTitle>{shortcut && shortcut.Name}</S.MainTitle>
        <S.SiteBlock>
          <S.SecondaryTitle>
            {currentSite && `${currentSite.AdditionalSiteID.substring(0, 5)} ${currentSite.Name}`}
          </S.SecondaryTitle>
          <S.Title>{`${unitsAndItems[0]} Units`}</S.Title>
          <S.Title>{`${unitsAndItems[1]} Items`}</S.Title>
        </S.SiteBlock>
        <S.Step>5 of 5</S.Step>
        <Form
          onSubmit={values => this.onSubmitForm({ values })}
          initialValues={data}
          render={({ handleSubmit, values, reset }) => (
            <form onSubmit={handleSubmit}>
              {currentSchedule.AssignmentType === 2 ? (
                <S.Section>
                  <S.SectionHeader>Management/Supervisor Rules</S.SectionHeader>
                  <S.TeamTitle>Manager responsible is based on Team selection below</S.TeamTitle>
                </S.Section>
              ) : (
                <SectionRules
                  title="Management/Supervisor Rules"
                  rules={this.state.managementRules}
                  addOption={this.addOption}
                  removeOption={this.removeOption}
                  onChangeMatch={this.onChangeMatch}
                  onChangeIgnoreRules={this.onChangeIgnoreRules}
                  values={values}
                  changeData={this.changeData}
                  availabilityOptions={this.state.list}
                  teamsAreEmpty={teamsList.length === 0}
                  teamsList={teamsList}
                  jobsList={jobsList}
                  reset={reset}
                  listStatus={listStatus}
                />
              )}

              <S.PreviewResultsContainer>
                {currentSchedule.AssignmentType === 2 ? (
                  <div />
                ) : (
                  <S.WrapperText>
                    <S.BoldText>If more than one manager is found:</S.BoldText>
                    Manager with last name priority will be chosen (A-Z)
                    <br />
                    <br />
                    <S.BoldText>If NO manager is found:</S.BoldText>
                    <div>
                      Tasks will be assigned to “<S.PrimaryText>Unknown Manager</S.PrimaryText>”
                    </div>
                  </S.WrapperText>
                )}
                <S.Wrapper>
                  <TestrunButton
                    disabled={currentSchedule.AssignmentType === 2}
                    onClick={() => this.getPreviewResults(values)}
                  />
                  {currentSchedule.AssignmentType === 2
                    ? team.Name && (
                        <S.PreviewResultsInfo>{this.generateTeamPreviewResults(team.Manager)}</S.PreviewResultsInfo>
                      )
                    : manager.Name &&
                      this.state.isPreviewShown && (
                        <S.PreviewResultsInfo>{this.generatePreviewResults(manager)}</S.PreviewResultsInfo>
                      )}
                </S.Wrapper>
              </S.PreviewResultsContainer>
              {currentSchedule.AssignmentType === 2 && (
                <>
                  <SectionRules
                    title="Team Rules"
                    rules={this.state.teamRules}
                    addOption={this.addOption}
                    removeOption={this.removeOption}
                    onChangeMatch={this.onChangeMatch}
                    onChangeIgnoreRules={this.onChangeIgnoreRules}
                    values={values}
                    availabilityOptions={this.state.list}
                    changeData={this.changeData}
                    teamsList={teamsList}
                    jobsList={jobsList}
                    reset={reset}
                    listStatus={listStatus}
                  />
                  <S.PreviewResultsContainer>
                    <S.WrapperText>
                      <S.BoldText>If more than one Team is found:</S.BoldText>
                      Team with name priority will be chosen (A-Z)
                      <br />
                      <br />
                      <S.BoldText>If NO Team is found:</S.BoldText>
                      <div>
                        Tasks will be assigned to “<S.PrimaryText>Unknown Team</S.PrimaryText>” and “
                        <S.PrimaryText>Unknown Manager</S.PrimaryText>”
                      </div>
                    </S.WrapperText>
                    <S.Wrapper>
                      <TestrunButton onClick={() => this.getTeamPreviewResults(values)} />
                      {team.Name && this.state.isPreviewTeamShown && (
                        <S.PreviewResultsInfo>{this.generateTeamPreviewResults(team)}</S.PreviewResultsInfo>
                      )}
                    </S.Wrapper>
                  </S.PreviewResultsContainer>
                </>
              )}
              {currentSchedule.AssignmentType === 3 && (
                <>
                  <S.RedText>Item tasks will be assigned to:</S.RedText>
                  <SectionRules
                    title="Person Assignment Rules"
                    rules={this.state.personRules}
                    addOption={this.addOption}
                    removeOption={this.removeOption}
                    onChangeMatch={this.onChangeMatch}
                    onChangeIgnoreRules={this.onChangeIgnoreRules}
                    values={values}
                    availabilityOptions={this.state.list}
                    changeData={this.changeData}
                    teamsList={teamsList}
                    jobsList={jobsList}
                    reset={reset}
                    listStatus={listStatus}
                  />
                  <S.PreviewResultsContainer>
                    <S.WrapperText>
                      <S.BoldText>If more than one person is found:</S.BoldText>
                      Person with last name priority will be chosen (A-Z)
                      <br />
                      <br />
                      <S.BoldText>If NO person is found:</S.BoldText>
                      <div>
                        Tasks will be assigned to “<S.PrimaryText>Pick-Up</S.PrimaryText>”(Unassigned)
                      </div>
                    </S.WrapperText>
                    <S.Wrapper>
                      <TestrunButton onClick={() => this.getPersonPreviewResults(values)} />
                      {person.Name && this.state.isPreviewPersonShown && (
                        <S.PreviewResultsInfo>{this.generatePreviewResults(person)}</S.PreviewResultsInfo>
                      )}
                    </S.Wrapper>
                  </S.PreviewResultsContainer>
                </>
              )}
              {this.state.isErrorShown && <S.ErrorContainer>Please add at least one rule</S.ErrorContainer>}
              {!this.state.isErrorShown && !!error.length && <S.ErrorContainer>{error}</S.ErrorContainer>}
              <ActionsContainer>
                <ActionButton label="Previous" isNext onClick={this.onPrevious} />
                <ActionButton type="submit" label="Next" isNext />
              </ActionsContainer>
            </form>
          )}
        />
      </S.Container>
    );
  }
}

const mapStateToProps = ({ shortcuts, teams, newSites }) => ({
  manager: shortcuts.managementOptions,
  person: shortcuts.personOptions,
  team: shortcuts.teamOptions,
  shortcut: shortcuts.shortcut,
  currentSchedule: shortcuts.currentSchedule,
  error: shortcuts.error,
  listOfItems: shortcuts.listOfItems,
  teamsList: teams.list,
  jobsList: newSites.jobsList,
  listStatus: shortcuts.listStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...shortcutActions,
      ...newSitesActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pickup);
