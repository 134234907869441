import http from '../index';

export const getDistributionList = () => http.post('DistributionLists/List');

export const deleteDistributionList = dto => http.post('DistributionLists/Delete', dto);

export const previewDistributionList = dto => http.post('DistributionLists/Preview', { distributionList: dto });

export const getDistributionListDetails = filter => http.post('DistributionLists/Get', { filter });

export const getDistributionListGroups = () => http.post('DistributionListGroups/List');

export const deleteDistributionListGroups = dto => http.post('DistributionListGroups/Delete', dto);

export const getDistributionListGroupsDetails = filter => http.post('DistributionListGroups/Get', { filter });
