import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

import { ToolbarContainer } from 'components/Layout/Containers';
import { isIE11 } from 'helpers';

export const Container = styled(Box).attrs({})`
  background-color: ${({ theme }) => theme.lightGrey} !important;
  z-index: 0;
  overflow-y: auto;
`;

export const Message = styled(Box)`
  font-size: 16px;
  text-align: center;
`;

export const Title = styled(Flex)`
  width: calc(100% - 44px);
  line-height: ${isIE11 ? '50px' : 'normal'};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ItemName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

export const Toolbar = styled(ToolbarContainer)``;
