import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import { Section, SectionHeader, SectionBody } from '../styled';
import * as S from './styled';

const OverviewDialog = ({
  open,
  handleClose,
  details,
  currentChecklistStatus,
  newTasksCount,
  people,
  onSubmit,
  returnAssignmentType,
}) => (
  <Dialog open={open} onRequestClose={handleClose} title="Alert" titleColor={theme.mainRed}>
    <S.Container>
      <S.RedText>Editing this Checklist may disrupt tasks in progress!</S.RedText>
      <S.RedText>New Tasks will change the sequence and workload of assignees</S.RedText>
      <Section inside>
        <SectionHeader color={theme.primarySites}>Assignments Status</SectionHeader>
        <SectionBody>
          <S.RedInfo>
            Checklist:&nbsp;<S.Info>{details.Name}</S.Info>
          </S.RedInfo>
          <S.RedInfo>
            Assigned to:&nbsp;
            <S.Info>{returnAssignmentType(details.ChecklistAssignmentType, details.AssigneeID)}</S.Info>
          </S.RedInfo>
          {details.ChecklistAssignmentType !== 0 ? (
            <S.RedInfo>
              Name:&nbsp;<S.Info>{details.TeamName}</S.Info>
            </S.RedInfo>
          ) : (
            <S.RedInfo>
              Name:&nbsp;
              <S.Info>
                {details.AssigneeID ? people.find(item => item.Id === details.AssigneeID).Name : 'Anyone'}
              </S.Info>
            </S.RedInfo>
          )}
        </SectionBody>
      </Section>
      <Section inside>
        <SectionHeader color={theme.primarySites}>Tasks Status</SectionHeader>
        <SectionBody>
          <S.Text>
            <b>{currentChecklistStatus.InProgressCount}</b>&nbsp;Tasks are in progress
          </S.Text>
          <S.Text>
            <b>{currentChecklistStatus.CompletedCount}</b>&nbsp;Tasks are completed but not archieved
          </S.Text>
          <S.Text>
            <b>{currentChecklistStatus.RequireAttentionCount}</b>&nbsp;In progress but require attention
          </S.Text>
          <S.RedText>
            <b>{newTasksCount}</b>&nbsp;New Tasks will be added
          </S.RedText>
        </SectionBody>
      </Section>
      <ActionsContainer>
        <ActionButton label="CANCEL/PREVIOUS" onClick={handleClose} />
        <ActionButton label="PROCEED UPDATE" isNext onClick={onSubmit} />
      </ActionsContainer>
    </S.Container>
  </Dialog>
);

OverviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  details: PropTypes.shape({
    Name: PropTypes.string,
    TeamName: PropTypes.string,
    ChecklistAssignmentType: PropTypes.number,
    AssigneeID: PropTypes.string,
  }).isRequired,
  currentChecklistStatus: {
    RequireAttentionCount: PropTypes.number,
    InProgressCount: PropTypes.number,
    CompletedCount: PropTypes.number,
  }.isRequired,
  newTasksCount: PropTypes.number.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  returnAssignmentType: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OverviewDialog;
