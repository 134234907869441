import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { GatewayType, SensorType, SensorTypeType } from 'configs/propTypes';
import { actions as gatewaysActions } from 'redux/gateways';
import { actions as sensorsActions } from 'redux/sensors';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as sitesActions } from 'redux/sites';
import { actions as unitsActions } from 'redux/units';
import { actions as unitActions } from 'redux/unit';
import Subheader from 'components/Subheader';
import SimpleList from 'components/SimpleList';
import { BackIcon } from 'components/Layout/Icons';
import ConfigSensorDialog from './ConfigSensorDialog';
import Item from './Item';
import { getSiteNameSelector } from './selectors';

import * as S from './styled';

class Sensors extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      editSensorRequest: PropTypes.func.isRequired,
      getGatewayRequest: PropTypes.func.isRequired,
      getSensorTypesRequest: PropTypes.func.isRequired,
      getSensorsRequest: PropTypes.func.isRequired,
      requestFetchUnitItems: PropTypes.func.isRequired,
      requestFetchUnitsList: PropTypes.func.isRequired,
      requestSitesList: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        gatewayId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    selectedGateway: GatewayType.isRequired,
    sensorsList: PropTypes.arrayOf(SensorType).isRequired,
    siteName: PropTypes.string.isRequired,
    typesList: PropTypes.arrayOf(SensorTypeType).isRequired,
    error: PropTypes.object.isRequired,
  };

  state = {
    selectedSensor: {},
    sensorOpened: false,
  };

  componentDidUpdate(prevProps) {
    const { error, actions } = this.props;

    if (prevProps?.error?.length === 0 && error.length > 0) {
      actions.openErrorDialog(error, 'Error');
    }
  }

  componentDidMount() {
    const {
      actions,
      match: {
        params: { gatewayId },
      },
    } = this.props;

    actions.requestSitesList();
    actions.getGatewayRequest(gatewayId);
    actions.getSensorsRequest(gatewayId);
  }

  closeSensorDialog = () => {
    this.setState({ sensorOpened: false });
  };

  openSensorDialog = item => {
    const {
      actions,
      selectedGateway: { SiteID },
    } = this.props;

    actions.getSensorTypesRequest();
    actions.requestFetchUnitsList({ filter: { SiteID } });

    if (item.UnitId) {
      actions.requestFetchUnitItems({ unitId: item.UnitId, SiteID });
    }

    this.setState({
      selectedSensor: item,
      sensorOpened: true,
    });
  };

  backToGateways = () => {
    this.props.history.push('/settings/gateways');
  };

  submitSensorDialog = values => {
    const {
      actions,
      match: {
        params: { gatewayId },
      },
      typesList,
    } = this.props;

    values.IsAssigned = !!values.ItemId;
    values.LevelsCount = typesList.find(type => type.Id === values.SensorTypeID).LevelsCount;

    actions.editSensorRequest({
      gatewayId,
      values: { id: values.Id, dto: values },
    });
    this.closeSensorDialog();
  };

  renderRightControls = (item, siteName) => (
    <div>
      <S.IconButton onClick={() => this.openSensorDialog(item)} tooltip="Assign" hasSite={siteName}>
        <S.IconAntenna isAssigned={item.ItemId} />
      </S.IconButton>
    </div>
  );

  render() {
    const {
      selectedGateway: { DeviceId, SiteID },
      sensorsList,
      siteName,
    } = this.props;
    const { selectedSensor, sensorOpened } = this.state;

    return (
      <>
        <Subheader
          title={`Gateway: ${DeviceId}`}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backToGateways,
              hint: 'Back',
            },
          ]}
          assignedSite={siteName}
          isSiteOnLeftSide
        />
        <SimpleList
          data={sensorsList}
          emptyListMessage="There are no sensors types avaliable"
          renderItemContent={item => <Item {...item} />}
          renderRightControls={item => this.renderRightControls(item, siteName)}
        />
        <ConfigSensorDialog
          handleClose={this.closeSensorDialog}
          title={`Sensor SN:${selectedSensor.SerialNumber}`}
          open={sensorOpened}
          onSubmit={this.submitSensorDialog}
          siteName={siteName}
          SiteID={SiteID}
          selectedSensor={selectedSensor}
          withoutTopBorder
        />
      </>
    );
  }
}

const mapStateToProps = ({ gateways: { selectedGateway }, sensors, sites }) => ({
  selectedGateway,
  sensorsList: sensors.list,
  siteName: getSiteNameSelector(selectedGateway, sites),
  typesList: sensors.typesList,
  error: sensors.error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      requestFetchUnitItems: unitActions.fetchUnitItemsRequest,
      requestFetchUnitsList: unitsActions.unitsListRequest,
      requestSitesList: sitesActions.sitesListRequest,
      ...gatewaysActions,
      ...sensorsActions,
      openErrorDialog,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sensors);
