import styled from 'styled-components';
import { ButtonFlat } from 'components/Layout/Buttons';
import { HideOnTablets } from 'components/Layout/Media';

const setButtonsBlockHeight = (withSaveContinue, BigButtons) => {
  if (withSaveContinue) {
    return BigButtons ? '100px' : '80px';
  }

  return 'auto';
};

export const ButtonsBlock = styled.div`
  padding: ${({ hasPadding }) => (hasPadding ? '10px 32px' : '0 2px')};
  box-sizing: border-box;
  z-index: 99;
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: ${({ withSaveContinue }) => (withSaveContinue ? 'column' : 'row')};
  gap: ${({ gapValue }) => gapValue && gapValue};
  align-items: center;
  justify-content: flex-end;
  border-radius: 2px;
  border-top: ${({ theme, withoutTopBorder }) => (withoutTopBorder ? '0px' : `1px solid ${theme.lightGrey}`)};
  height: ${({ withSaveContinue, BigButtons }) => setButtonsBlockHeight(withSaveContinue, BigButtons)};

  button {
    width: 100% !important;
    height: ${({ withSaveContinue, BigButtons }) => (withSaveContinue && BigButtons ? '50px !important' : null)};
  }
`;

export const CancelCustomButton = styled(ButtonFlat).attrs({
  label: props => props.label,
})`
  span {
    color: ${({ theme, customColor }) => customColor || theme.modalCancelColor} !important;
  }
`;

export const DeleteButton = styled(ButtonFlat).attrs({
  label: 'Delete',
})`
  ${HideOnTablets}
  span {
    color: ${({ theme }) => theme.primaryRed} !important;
  }
`;

export const SaveContinueButton = styled(ButtonFlat).attrs({
  label: props => props.saveContinueLabel,
  notAllowedCursor: props => props.disabled,
})`
  background-color: ${({ theme, green }) => (green ? theme.lightGreenButton : 'green')} !important;
  border-radius: ${({ border }) => (border ? '5px' : '2px')} !important;
  span {
    color: ${({ theme, green }) => (green ? theme.greenButton : theme.primaryWhite)} !important;
  }
  ${props => (props.notAllowedCursor ? 'cursor: not-allowed !important;' : '')}
`;

export const SubmitButton = styled(ButtonFlat).attrs({
  label: props => props.label,
  notAllowedCursor: props => props.disabled,
})`
  border-radius: ${({ border }) => (border ? '5px' : '2px')} !important;
  border: ${({ border, theme }) => (border ? `2px solid ${theme.greenButton}` : 'none')} !important;
  border-left: 2px solid ${({ theme }) => theme.lightGrey};
  span {
    color: ${({ theme, green }) => (green ? theme.greenButton : theme.modalOkColor)} !important;
  }
  ${props => (props.notAllowedCursor ? 'cursor: not-allowed !important;' : '')}
`;

export const CopyButton = styled(SubmitButton).attrs({
  label: 'Copy',
})``;

export const SearchButton = styled(SubmitButton).attrs({
  label: 'Find',
})`
  background: ${({ theme }) => theme.primaryGreen} !important;
  span {
    color: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const SearchAllButton = styled(SubmitButton).attrs({
  label: 'Find all',
})`
  border: 1px solid ${({ theme }) => theme.darkGrey} !important;
  margin-left: 5px !important;
  span {
    color: ${({ theme }) => theme.darkGrey} !important;
  }
`;

export const CreateButton = styled(SubmitButton).attrs({
  label: 'Create New',
})``;

export const SubmitIconButton = styled(ButtonFlat)``;

export const ResetButton = styled(ButtonFlat).attrs({
  label: props => props.label,
  notAllowedCursor: props => props.disabled,
})`
  width: 100%;
  display: flex !important;
  justify-content: center !important;

  span {
    font-weight: 400 !important;
    color: black !important;
  }
  ${props => (props.notAllowedCursor ? 'cursor: not-allowed !important;' : '')}
`;
