import styled from 'styled-components';
import { Box } from 'grid-styled';

export const Container = styled(Box).attrs({
  p: 2,
})``;

export const Message = styled(Box)`
  font-size: 16px;
  text-align: center;
`;
