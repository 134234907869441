import styled from 'styled-components';
import { ForwardIcon } from 'components/Layout/Icons';

export const TableContainer = styled.div`
  & td:nth-child(-n + 1) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  th,
  td {
    padding: 0 20px !important;
    min-width: 16vw;
  }

  & td:nth-child(1) {
    background-color: ${({ theme }) => theme.fieldsBorderColor};
  }

  & td:last-child {
    text-align: right;
  }
`;

export const IconForward = styled(ForwardIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const TableCell = styled.td`
  div {
    display: inline-block;
  }
`;

export const EmptyList = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 220px;
`;

export const EmptyListText = styled.p`
  color: ${({ theme }) => theme.tenantNameColor};
  font-size: 16px;
`;
