import styled from 'styled-components';

export const DialogContainer = styled.div`
  padding: 15px 65px;
`;

export const DialogText = styled.p`
  font-size: 20px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 300)};
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
`;

export const IconContainer = styled.div`
  margin: 0 auto;
  width: 40px;
`;

export const CloseButtonContainer = styled.div`
  height: 50px;
  border-top: 1px solid ${({ theme }) => theme.primaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.primaryGreen};
  font-weight: bold;
  background: none;
  border: none;
  font-size: 18px;
`;
