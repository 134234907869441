import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

export const Container = styled(Flex).attrs({
  column: true,
  p: 2,
})``;

export const Data = styled(Box).attrs({
  w: [0.8, 0.7],
})`
  min-width: 0;
  word-wrap: break-word;
`;

export const DataLabel = styled(Flex).attrs({
  justify: ['flex-start', 'flex-end'],
  w: [1, 0.25],
})``;

export const DataRow = styled(Flex).attrs({
  direction: ['column', 'row'],
  mb: 1,
})``;

export const InformationWrapper = styled(Flex).attrs({
  w: [1, 0.75],
})``;

export const Space = styled(Box).attrs({
  w: [0, 0.05],
})``;
