import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { HelpIcon, RestoreIcon } from 'components/Layout/Icons';

import * as S from './styled';

const TextTooltipFieldAdapter = ({
  isDefaultBarcode,
  isDisabled,
  isHelp,
  isReset,
  isTooltipTop,
  handleReset,
  labelText,
  name,
  setDefaultBarcode,
  tooltip,
  tooltipWidth,
  hintText,
}) => (
  <Field floatingLabelText={labelText} name={name} hintText={hintText}>
    {({ input, meta, ...rest }) => (
      <S.InputToolTipWrapper>
        <S.TextField
          {...input}
          {...rest}
          disabled={isDisabled}
          onChange={(event, value) => {
            input.onChange(value);

            if (isDefaultBarcode) {
              setDefaultBarcode(false);
            }
          }}
          errorText={meta.visited || meta.touched ? meta.error : ''}
          isHelp={isHelp}
          isReset={isReset}
          maxLength="255"
        />
        <S.ButtonWrapper>
          {isReset ? (
            <S.IconButton
              onClick={handleReset}
              tooltip={isHelp ? 'Reset to default' : 'Reset to system'}
              tooltipPosition={isTooltipTop ? 'top-left' : 'bottom-center'}
            >
              <RestoreIcon />
            </S.IconButton>
          ) : null}
          {isHelp ? (
            <S.IconButton
              tooltip={tooltip}
              tooltipPosition={isTooltipTop ? 'top-left' : 'bottom-left'}
              tooltipWidth={tooltipWidth}
            >
              <HelpIcon />
            </S.IconButton>
          ) : null}
        </S.ButtonWrapper>
      </S.InputToolTipWrapper>
    )}
  </Field>
);

TextTooltipFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  isDefaultBarcode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHelp: PropTypes.bool,
  isReset: PropTypes.bool,
  isTooltipTop: PropTypes.bool,
  handleReset: PropTypes.func,
  labelText: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setDefaultBarcode: PropTypes.func,
  tooltip: PropTypes.object,
  tooltipWidth: PropTypes.string,
  hintText: PropTypes.string,
};

TextTooltipFieldAdapter.defaultProps = {
  handleReset: () => {},
  input: {},
  isDefaultBarcode: false,
  isDisabled: false,
  isHelp: false,
  isReset: false,
  isTooltipTop: false,
  meta: {},
  setDefaultBarcode: () => {},
  tooltip: {},
  tooltipWidth: '',
  hintText: '',
};

export default TextTooltipFieldAdapter;
