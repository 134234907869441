import styled from 'styled-components';

import { InputBlockLabel } from 'components/Layout/Containers';

export const AddBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0;
`;

export const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  width: calc(13px + 50%);
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.mainRed};
  svg {
    fill: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

export const FormContainer = styled.div`
  padding: 5px 35px;
`;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 5px 5px 0;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const SubText = styled.div`
  font-size: 14px;
`;

export const FieldLabel = styled.label`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 500;
  padding: 7px 0 5px 13px;
  text-align: left;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 15px 0;
`;

export const radioStyle = {
  display: 'flex',
  width: '100%',
};
