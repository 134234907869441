export const validator = values => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (values?.Manager?.Status === 'Unknown' && values?.Manager?.Id === values.ManagerID) {
    errors.ManagerID = 'Assign a manager';
  }

  if (!values.ManagerID) {
    errors.ManagerID = 'Required';
  }

  return errors;
};

export default validator;
