const initialState = {
  error: '',
  loading: false,
  list: [],
};

export const getPdfListRequest = state => ({
  ...state,
  list: '',
  error: '',
  loading: true,
});
export const getPdfListSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  loading: false,
});
export const getPdfListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const deletePdfFileRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const deletePdfFileSuccess = state => ({
  ...state,
  loading: false,
});
export const deletePdfFileFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});
export const createPdfFileRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const createPdfFileSuccess = state => ({
  ...state,
  loading: false,
});
export const createPdfFileFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
});
export const refresh = () => initialState;

export default initialState;
