export const divideByDate = list => {
  const data = list.reduce(
    (result, item) => {
      const newItem = { ...item };

      newItem.ItemName = newItem.UnitName;
      newItem.UnitName = newItem.ExecutionDate;

      result.amount += 1;

      if (!result.tasks[newItem.UnitName]) {
        result.tasks[newItem.UnitName] = [];
        result.tasks[newItem.UnitName].push(newItem);
      } else {
        result.tasks[newItem.UnitName].push(newItem);
      }

      return result;
    },
    { tasks: {}, amount: 0 },
  );

  Object.keys(data.tasks).forEach(key => {
    data.tasks[key].sort(
      (a, b) => a.ItemSequence - b.ItemSequence || a.TaskOrder - b.TaskOrder || !!a.Exceptions > !!b.Exceptions,
    );
  });

  return data;
};

export const divideByUnitName = list => {
  const data = list.reduce(
    (result, item) => {
      result.amount += 1;

      if (!result.tasks[item.UnitName]) {
        result.tasks[item.UnitName] = [];
        result.tasks[item.UnitName].push(item);
      } else {
        result.tasks[item.UnitName].push(item);
      }

      return result;
    },
    { tasks: {}, amount: 0 },
  );

  Object.keys(data.tasks).forEach(key => {
    data.tasks[key].sort(
      (a, b) => a.ItemSequence - b.ItemSequence || a.TaskOrder - b.TaskOrder || !!a.Exceptions > !!b.Exceptions,
    );
  });

  return data;
};

export const prepareSaveTaskParams = ({
  actualFlow,
  actualQty,
  actualTemperature,
  feedbackResponse,
  message,
  id,
  requiresAttention,
  ruleType,
  verificationResponse,
}) => {
  const ruleTypeParams = {
    RequiresAttention: { id, requiresAttention, message },
    Verification: { id, verificationResponse, requiresAttention: 0, message },
    Feedback: { id, feedbackResponse, requiresAttention: 0, message },
    'Check Flow Rate': { id, actualReading: actualFlow, requiresAttention: 0, message },
    'Check Quantity': { id, actualReading: actualQty, requiresAttention: 0, message },
    'Check Temperature': { id, actualReading: actualTemperature, requiresAttention: 0, message },
    'Photo Confirmation': { id, requiresAttention: 0, message },
    'Scan Code': { id, requiresAttention: 0, message },
    ForceComplete: { taskId: id },
  };

  return requiresAttention ? ruleTypeParams.RequiresAttention : ruleTypeParams[ruleType];
};

export const findNextTaskId = (list, currentTaskId) => {
  let foundTask = {};
  const units = Object.keys(list.tasks);

  units.some(unit =>
    list.tasks[unit].some((item, index) => {
      if (item.Id !== currentTaskId) {
        return false;
      }

      foundTask = list.tasks[unit][index + 1];
      return true;
    }),
  );

  return foundTask && foundTask.Id;
};

export const prepareSaveTaskUrl = ({ ruleType, requiresAttention }) => {
  const ruleTypeUrl = {
    RequiresAttention: 'AssignedTaskActions/MarkTaskAsNeedsAttention/',
    Verification: 'AssignedTaskActions/CompleteVerificationTask/',
    Feedback: 'AssignedTaskActions/CompleteFeedbackTask/',
    'Check Flow Rate': 'AssignedTaskActions/CompleteFlowRateTask/',
    'Check Quantity': 'AssignedTaskActions/CompleteQuantityTask/',
    'Check Temperature': 'AssignedTaskActions/CompleteTemperatureTask/',
    'Photo Confirmation': 'AssignedTaskActions/CompletePhotoConfirmationTask/',
    'Scan Code': 'AssignedTaskActions/CompleteScanCodeTask/',
    ForceComplete: 'AssignedTaskActions/ForceCompleteTask/',
  };

  return requiresAttention ? ruleTypeUrl.RequiresAttention : ruleTypeUrl[ruleType];
};
