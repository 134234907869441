import queryString from 'query-string';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { unsubscribeUsers } from 'services/OneSignal';
import http from '../../http';
import { clearForceLogout, setForceLogout } from '../../helpers/storage';

import { addPrefixToObjectKeys, formatServerError, parseServerError } from 'helpers';
import { deleteUser, getSelectedSite, getUser, saveSelectedSite, saveUser, saveTenantId } from 'helpers/storage';
import { actions as partsActions } from 'redux/parts';
import { openErrorDialog, ERROR_DIALOG_OPENED } from 'redux/errorHandler';

import { USER_TYPES } from 'configs/enums';
import {
  accountForgotPassword,
  accountLogOff,
  accountLogOn,
  authenticationStart,
  createTenantRequest,
  getUserSites,
  passwordReset,
  tenantManagementLoginAs,
  tenantNameExists,
  userNameExists,
  verifyTenantRequestToken,
  activateAccount,
} from 'http/auth';

import { actions } from './index';
import { getCurrentUser } from './selectors';

function* updateUserAndSites(user) {
  yield call(saveUser, user);
  yield call(clearForceLogout);
  const data = { user };
  const storedSite = yield call(getSelectedSite);
  const sitesAvailableToUser = Object.keys(user.sitesAvailableToUser);
  const defaultSite = sitesAvailableToUser[0] ? sitesAvailableToUser[0] : '';
  const [fullStoredSiteInfo] = user.sitesAvailableToUserFullInfo.filter(site => site.id === storedSite);
  if (sitesAvailableToUser.includes(storedSite)) {
    http.defaults.siteId = storedSite;
    data.selectedSite = storedSite;
    data.siteName = fullStoredSiteInfo.additionalId
      ? `${fullStoredSiteInfo.additionalId.substring(0, 5)} ${fullStoredSiteInfo.name}`
      : `${fullStoredSiteInfo.name}`;
  } else {
    saveSelectedSite(defaultSite);
    http.defaults.siteId = defaultSite;
    data.selectedSite = defaultSite;
  }
  return data;
}

function* authenticate({ payload }) {
  try {
    const params = queryString.stringify(addPrefixToObjectKeys(payload, 'Model'));
    const config = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };
    const {
      data: { entity },
    } = yield call(accountLogOn, params, config);
    yield call(saveTenantId, entity);
    if (entity.success) {
      if (entity.userType !== USER_TYPES.SystemController) {
        yield put(partsActions.getPartsRequest());
        yield put(partsActions.getPartGroupsRequest());
      }
      const data = yield* updateUserAndSites(entity);
      yield put(actions.authenticateSuccess(data));
    } else {
      yield put(openErrorDialog("You've entered incorrect username or password. Please, try once again.", 'Error'));
    }
  } catch (error) {
    yield put(actions.authenticateFailure(error));
  }
}

function* checkAuthentication() {
  try {
    yield call(authenticationStart);
    const storedUser = yield call(getUser);
    const storedSite = yield call(getSelectedSite);
    if (storedUser && storedUser.userId) {
      http.defaults.siteId = storedSite;
      yield put(actions.authenticationCheckSuccess({ user: storedUser, selectedSite: storedSite }));
    } else {
      yield put(actions.authenticationCheckFailure());
    }
  } catch (error) {
    yield put(actions.authenticationCheckFailure());
  }
}

function* fetchSitesAvailableToCurrentUser() {
  try {
    const currentUser = yield select(getCurrentUser);
    const params = `model.Username=${encodeURIComponent(currentUser.userName)}`;
    const {
      data: { entity },
    } = yield call(getUserSites, params);
    if (entity.success) {
      const data = yield* updateUserAndSites(entity);
      yield put(actions.fetchUserSitesSuccess(data));
    } else {
      yield put(actions.fetchUserSitesFailure('Request error'));
    }
  } catch (error) {
    yield put(actions.fetchUserSitesFailure(error));
  }
}

function* forgotPassword({ payload }) {
  try {
    yield call(accountForgotPassword, payload);
    yield put(openErrorDialog('Reset instructions were sent to your email.', 'Success'));
    yield put(actions.forgotPasswordSuccess());
  } catch (error) {
    yield put(
      actions.forgotPasswordFailure({
        error,
        message: formatServerError(error, 'An error occurred while resetting password.'),
      }),
    );
  }
}

function* loginAs({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(tenantManagementLoginAs, payload);
    const userForSave = { tenantId: payload.tenant.Id };
    yield call(saveTenantId, userForSave);
    if (entity.success) {
      const data = yield* updateUserAndSites(entity);
      yield put(partsActions.getPartsRequest());
      yield put(partsActions.getPartGroupsRequest());
      yield put(actions.usersLoginasSuccess(data));
    } else {
      yield put(
        actions.usersLoginasFailure({ message: 'An error occurred while trying to login as user. Please try again.' }),
      );
    }
  } catch (error) {
    yield put(actions.usersLoginasFailure(error));
  }
}

function* logout() {
  try {
    yield call(accountLogOff);
    yield call(setForceLogout);
    yield call(deleteUser);
    http.defaults.siteId = '';
    yield put(actions.logoutSuccess());
    unsubscribeUsers();
  } catch (error) {
    yield put(actions.logoutFailure(error));
  }
}

function* resetPassword({ payload }) {
  try {
    const params = queryString.stringify(payload);
    yield call(passwordReset, params);
    yield put(openErrorDialog('Your password has been reset.', 'Success!'));
    yield put(actions.resetPasswordSuccess());
  } catch (error) {
    yield put(actions.resetPasswordFailure(error));
  }
}

function* fetchDemoUserName({ payload }) {
  const { values, cb, userName } = payload;
  const params = `userName=${encodeURIComponent(userName || values.name)}`;
  try {
    const { data } = yield call(userNameExists, params);
    const value = userName ? 'password' : values;
    cb(value, !data.entity);
    yield put(actions.checkIsDemoNameExistsSuccess(data.entity));
  } catch (error) {
    yield put(actions.checkIsDemoNameExistsFailure(error));
  }
}

function* fetchActivateAccount({ payload }) {
  const { values, tenantName, token, openSuccessDialog } = payload;
  try {
    const { data } = yield call(activateAccount, tenantName, token, values);
    openSuccessDialog();
    yield put(actions.activateAccountSuccess(data.entity));
  } catch (error) {
    if (parseServerError(error)?.message.includes(`User with such token ${token} not found`)) {
      yield put({
        type: ERROR_DIALOG_OPENED,
        payload: { message: 'The link is expired. You cannot reuse it', title: 'Alert' },
      });
    } else {
      yield put({ type: ERROR_DIALOG_OPENED, payload: parseServerError(error) });
    }
    yield put(actions.activateAccountFailure(error));
  }
}

function* fetchDemoTenantName({ payload }) {
  const { tenantName, cb } = payload;
  const params = `tenantName=${encodeURIComponent(tenantName)}`;
  try {
    const { data } = yield call(tenantNameExists, params);
    cb('industry', !data.entity);
    yield put(actions.checkIsDemoNameExistsSuccess(data.entity));
  } catch (error) {
    yield put(actions.checkIsDemoNameExistsFailure(error));
  }
}

function* fetchDemoTenant({ payload }) {
  const { values, openSuccessDialog } = payload;
  try {
    const data = yield call(createTenantRequest, values);
    yield put(actions.createDemoTenantSuccess(data));
    openSuccessDialog();
  } catch (error) {
    yield put(
      actions.createDemoTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while creating a demo account. Please try again.'),
      }),
    );
  }
}

function* fetchVerifyTenant({ payload }) {
  const { verifyData, openSuccessDialog } = payload;
  try {
    yield call(verifyTenantRequestToken, verifyData);
    yield put(actions.verifyTenantSuccess());
    openSuccessDialog();
  } catch (error) {
    yield put(
      actions.verifyTenantFailure({
        error,
        message: formatServerError(error, 'An error occurred while verifying a demo account. Please try again.'),
      }),
    );
  }
}

const authSagas = [
  takeEvery(actions.authenticateRequest, authenticate),
  takeEvery(actions.authenticationCheckRequest, checkAuthentication),
  takeEvery(actions.fetchUserSitesRequest, fetchSitesAvailableToCurrentUser),
  takeEvery(actions.forgotPasswordRequest, forgotPassword),
  takeEvery(actions.usersLoginasRequest, loginAs),
  takeEvery(actions.logoutRequest, logout),
  takeEvery(actions.resetPasswordRequest, resetPassword),
  takeEvery(actions.checkIsDemoUserNameExistsRequest, fetchDemoUserName),
  takeEvery(actions.checkIsDemoTenantNameExistsRequest, fetchDemoTenantName),
  takeEvery(actions.createDemoTenantRequest, fetchDemoTenant),
  takeEvery(actions.activateAccountRequest, fetchActivateAccount),
  takeEvery(actions.verifyTenantRequest, fetchVerifyTenant),
];

export default authSagas;
