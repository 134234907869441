import styled from 'styled-components';

export const Title = styled.div`
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  margin-bottom: 18px;
`;

export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
`;
