import { all } from 'redux-saga/effects';

import gatewaysSagas from './gateways/sagas';
import teamsSagas from './teams/sagas';
import shortcutSagas from './shortcuts/sagas';
import newSitesSagas from './newSites/sagas';
import listTemplatesSagas from './listTemplates/sagas';
import itemSagas from './item/sagas';
import globalSettingsSagas from './globalSettings/sagas';
import tutorialsSagas from './tutorials/sagas';
import sensorsSagas from './sensors/sagas';
import healthSagas from './health/sagas';
import meteringGroupsSagas from './meteringGroups/sagas';
import partsSagas from './parts/sagas';
import partOptionsSagas from './partOptions/sagas';
import distributionsSagas from './distributions/sagas';
import peopleSagas from './people/sagas';
import documentSagas from './document/sagas';
import documentsSagas from './documents/sagas';
import tenantsSagas from './tenants/sagas';
import scheduleSagas from './schedule/sagas';
import tiersSagas from './tiers/sagas';
import versionLegalsSagas from './versionLegals/sagas';
import unitSagas from './unit/sagas';
import settingsSagas from './settings/sagas';
import exchangeSagas from './exchange/sagas';
import unitsSagas from './units/sagas';
import tasksSagas from './tasks/sagas';
import exportPdfReportSagas from './exportPdfReport/sagas';
import authSagas from './auth/sagas';
import exportPdfSagas from './exportPdf/sagas';
import queueSagas from './queue/sagas';
import sitesSagas from './sites/sagas';
import statusIndexSagas from './statusIndex/sagas';
import appSagas from './app/sagas';

export default function* root() {
  yield all([
    ...listTemplatesSagas,
    ...newSitesSagas,
    ...shortcutSagas,
    ...teamsSagas,
    ...gatewaysSagas,
    ...itemSagas,
    ...sensorsSagas,
    ...healthSagas,
    ...meteringGroupsSagas,
    ...partsSagas,
    ...partOptionsSagas,
    ...distributionsSagas,
    ...peopleSagas,
    ...documentSagas,
    ...documentsSagas,
    ...tenantsSagas,
    ...scheduleSagas,
    ...tiersSagas,
    ...versionLegalsSagas,
    ...unitSagas,
    ...settingsSagas,
    ...exchangeSagas,
    ...unitsSagas,
    ...tasksSagas,
    ...exportPdfReportSagas,
    ...authSagas,
    ...exportPdfSagas,
    ...queueSagas,
    ...sitesSagas,
    ...statusIndexSagas,
    ...appSagas,
    ...globalSettingsSagas,
    ...tutorialsSagas,
  ]);
}
