import styled from 'styled-components';
import Dialog from 'material-ui/Dialog';

export const Modal = styled(Dialog).attrs({
  actionsContainerStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})`
  z-index: 4000 !important;

  & > div > div {
    width: 95% !important;
  }

  @media only screen and (min-width: 32em) {
    & > div > div {
      width: 70% !important;
    }
  }

  @media only screen and (min-width: 48em) {
    & > div > div {
      width: 55% !important;
    }
  }

  @media only screen and (min-width: 64em) {
    & > div > div {
      width: 45% !important;
    }
  }

  @media only screen and (min-width: 80em) {
    & > div > div {
      width: 30% !important;
    }
  }
`;
