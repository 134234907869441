import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import { Block } from './styled';

const NotesForm = () => (
  <Block>
    <Field component={TextFieldAdapter} floatingLabelText="Notes" name="ExchangeNotes" multiLine rowsMax={10} />
  </Block>
);

const NotesWithInput = withInputModal(NotesForm);

const NotesDialog = ({ notes, ...rest }) => [<NotesWithInput initialValues={notes} {...rest} />];

NotesDialog.defaultProps = {
  handleDelete: () => {},
  withDelete: false,
  withSubmit: true,
};

NotesDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  withDelete: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default NotesDialog;
