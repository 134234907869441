import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_PART_OPTIONS_REQUEST: undefined,
  GET_PART_OPTIONS_SUCCESS: undefined,
  GET_PART_OPTIONS_FAILURE: undefined,
  GET_SINGLE_PART_OPTIONS_TYPE_REQUEST: undefined,
  GET_SINGLE_PART_OPTIONS_TYPE_SUCCESS: undefined,
  GET_SINGLE_PART_OPTIONS_TYPE_FAILURE: undefined,
  ADD_PART_OPTION_REQUEST: undefined,
  ADD_PART_OPTION_SUCCESS: undefined,
  ADD_PART_OPTION_FAILURE: undefined,
  EDIT_PART_OPTION_REQUEST: undefined,
  EDIT_PART_OPTION_SUCCESS: undefined,
  EDIT_PART_OPTION_FAILURE: undefined,
  DELETE_PART_OPTION_REQUEST: undefined,
  DELETE_PART_OPTION_SUCCESS: undefined,
  DELETE_PART_OPTION_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getPartOptionsRequest, handlers.getPartOptionsRequest],
    [actions.getPartOptionsSuccess, handlers.getPartOptionsSuccess],
    [actions.getPartOptionsFailure, handlers.getPartOptionsFailure],
    [actions.getSinglePartOptionsTypeRequest, handlers.getSinglePartOptionsTypeRequest],
    [actions.getSinglePartOptionsTypeSuccess, handlers.getSinglePartOptionsTypeSuccess],
    [actions.getSinglePartOptionsTypeFailure, handlers.getSinglePartOptionsTypeFailure],
    [actions.addPartOptionRequest, handlers.addPartOptionRequest],
    [actions.addPartOptionSuccess, handlers.addPartOptionSuccess],
    [actions.addPartOptionFailure, handlers.addPartOptionFailure],
    [actions.editPartOptionRequest, handlers.editPartOptionRequest],
    [actions.editPartOptionSuccess, handlers.editPartOptionSuccess],
    [actions.editPartOptionFailure, handlers.editPartOptionFailure],
    [actions.deletePartOptionRequest, handlers.deletePartOptionRequest],
    [actions.deletePartOptionSuccess, handlers.deletePartOptionSuccess],
    [actions.deletePartOptionFailure, handlers.deletePartOptionFailure],
  ]),
  initialState,
);

export default reducer;
