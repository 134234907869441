import styled from 'styled-components';
import { Flex } from 'grid-styled';

import { ListItemContainer } from 'components/Layout/Containers';

export const Container = styled(ListItemContainer).attrs({
  secondaryTextLines: 2,
})`
  border-bottom: ${({ islast, theme }) => (islast === 'true' ? 'none' : `1px solid ${theme.primaryGrey} !important`)};
  background-color: ${({ theme }) => theme.primaryWhite} !important;

  & > div > div {
    padding: 10px 0 !important;

    & > div:first-child {
      order: 0;
    }
    & > div:last-child {
      padding: 0 !important;
    }
  }
`;

export const ListVersionBlock = styled(Flex).attrs({
  column: true,
  justify: 'center',
  align: 'center',
})`
  width: 105px;
  height: 100%;
`;

export const Version = styled(Flex).attrs({
  justify: 'center',
  align: 'center',
})`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
`;

export const Line = styled(Flex).attrs({
  w: 1,
})``;

export const Fields = styled(Flex).attrs({
  w: [0.8, 0.85, 0.85, 0.9],
})``;

export const ButtonsBlock = styled(Flex).attrs({
  justify: 'flex-end',
  align: 'baseline',
  w: [0.2, 0.15, 0.15, 0.1],
})``;

export const TextBlock = styled(Flex).attrs({
  column: true,
  justify: 'center',
  align: 'baseline',
})`
  &:first-child {
    width: 25%;
  }
  &:nth-child(2) {
    width: 15%;
    padding: 0 5px;
  }
`;

export const Notes = styled(Flex).attrs({
  column: true,
  justify: 'center',
  align: 'baseline',
  w: [0.5, 0.5, 0.6, 0.6],
})``;
