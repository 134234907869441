import http from '../index';

export const fetchLimits = tenantId => http.post('TenantManagement/GetTenantLimitsInfo', { tenantId });

export const fetchItems = dto => http.post('Item/ListMasterItemsWithTasks', dto);

export const copyUnitsToTenantFromMaster = dto => http.post('Unit/CopyUnitsToTenantFromMaster', dto);

export const makeListTemplate = dto => http.post('Unit/CreateListTemplate', dto);

export const createItemListTemplate = dto => http.post('Item/CreateItemListTemplate', dto);

export const fetchAllUnits = () => http.post('Unit/ListMasterUnits');

export const getListMasterUnits = dto => http.post('Unit/ListMasterUnits', dto);

export const canImportUnit = dto => http.post('Unit/CanImportUnit', dto);

export const fetchUnitTaskStatus = unitId => http.post('Unit/GetTaskStatus', { unitId });

export const getAssetCategoryList = () => http.post('MasterAssetCategory/List');

export const getAssetCategory = id => http.post('MasterAssetCategory/Get', { id });

export const createAssetCategory = dto => http.post('MasterAssetCategory/Post', { dto });

export const updateAssetCategory = dto => http.post('MasterAssetCategory/Put', { dto });

export const deleteAssetCategory = id => http.post('MasterAssetCategory/Delete', { id });

export const getAssetTypeList = filter => http.post('MasterAssetType/List', { filter });

export const getAssetType = id => http.post('MasterAssetType/Get', { id });

export const createAssetType = dto => http.post('MasterAssetType/Post', { dto });

export const updateAssetType = dto => http.post('MasterAssetType/Put', { dto });

export const deleteAssetType = id => http.post('MasterAssetType/Delete', { id });
