import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';

import * as S from './styled';

const Card = ({ info, onCardClick, PrimaryIcon, SecondaryIcon, title, color, tooltip }) => (
  <S.Card onClick={onCardClick}>
    <S.CardHeader color={color}>
      <PrimaryIcon />
      <S.CardIconContainer>
        <IconButton tooltip={tooltip}>
          <SecondaryIcon />
        </IconButton>
      </S.CardIconContainer>
    </S.CardHeader>
    <S.CardInfoContainer>
      <S.CardInfoTitle>{title}</S.CardInfoTitle>
      <S.CardInfoText>{info}</S.CardInfoText>
      <S.CardStartText>Start</S.CardStartText>
    </S.CardInfoContainer>
  </S.Card>
);

Card.propTypes = {
  info: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired,
  PrimaryIcon: PropTypes.node.isRequired,
  SecondaryIcon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

Card.defaultProps = {
  tooltip: '',
};

export default Card;
