const IoTFrequency = [
  {
    value: 1,
    label: 'Off - no notifications',
  },
  {
    value: 0,
    label: 'Instantly',
  },
  {
    value: 20,
    label: '20 minutes',
  },
  {
    value: 30,
    label: '30 minutes',
  },
  {
    value: 60,
    label: '60 minutes',
  },
];

export default IoTFrequency;
