import styled from 'styled-components';

import { SensorItemContainer } from 'components/Layout/Containers';
import { WarningIcon } from 'components/Layout/Icons';

export const Container = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.lightGrey};
`;

export const Separator = styled.div`
  height: 1px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.primaryGrey};
`;

export const Sensors = styled.div`
  padding: 0 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const SensorItem = styled(SensorItemContainer)`
  & > div > div {
    display: block;
    height: 30px;
    padding: 0 0 0 35px !important;
    line-height: 30px;
    justify-content: flex-end;
    flex-direction: row-reverse;
    text-overflow: ellipsis;
    overflow: hidden;

    svg {
      top: 2px !important;
      position: absolute !important;
    }
  }
`;

export const IconWarning = styled(WarningIcon)`
  min-width: 24px;
  transform: scale(0.7);
  margin: 0 10px 0 0 !important;
  position: relative !important;
`;
