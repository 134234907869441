import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { DeleteIcon, EditIcon } from 'components/Layout/Icons';
import { AssetType } from 'configs/propTypes';

import * as S from './styled';

const AssetTypes = ({
  selectedId,
  assetTypes,
  setSelectedId,
  openConfirmationModel,
  setAssetTypes,
  initialValues,
  updateAssetTypeRequest,
}) => {
  const onAssetChangeHandler = e => {
    const newArr = assetTypes.map(a => (a.Id === selectedId ? { ...a, Name: e.currentTarget.value } : a));
    setAssetTypes(newArr);
  };

  const onSaveAssetHandler = () => {
    const changed = assetTypes.find(a => a.Id === selectedId);
    setSelectedId('');

    const payload = {
      masterAssetCategoryID: initialValues.Id,
      masterAssetCategoryName: initialValues.Name,
      name: changed.Name,
      id: changed.Id,
    };

    updateAssetTypeRequest(payload);
  };

  return (
    <S.BlockContainer>
      {assetTypes?.map(({ Id, Name }) => (
        <S.Block key={Id}>
          <TextField
            hintText={Name}
            value={Name}
            style={{ width: '100%' }}
            autoFocus
            disabled={selectedId !== Id}
            onChange={onAssetChangeHandler}
          />
          {selectedId === Id ? (
            <S.IconButton onClick={onSaveAssetHandler}>
              <S.Check />
            </S.IconButton>
          ) : (
            <S.IconButton disabled={selectedId && selectedId !== Id} onClick={() => setSelectedId(Id)}>
              <EditIcon />
            </S.IconButton>
          )}
          <S.IconButton
            onClick={() => {
              openConfirmationModel();
              setSelectedId(Id);
            }}
          >
            <DeleteIcon />
          </S.IconButton>
        </S.Block>
      ))}
    </S.BlockContainer>
  );
};

AssetTypes.propTypes = {
  selectedId: PropTypes.string.isRequired,
  assetTypes: PropTypes.arrayOf(AssetType).isRequired,
  initialValues: PropTypes.object.isRequired,
  openConfirmationModel: PropTypes.func.isRequired,
  setAssetTypes: PropTypes.func.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  updateAssetTypeRequest: PropTypes.func.isRequired,
};

export default memo(AssetTypes);
