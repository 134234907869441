import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

export const userTypeSelector = createSelector(
  user => user.userType,
  user => user === 2,
);

export const peopleListSelector = createSelector(
  people => people.list,
  list => {
    const newList = [];

    list.forEach(item => {
      const newItem = { ...item };

      if (newItem.UserType === 'TaskSpecialist') {
        newItem.UserType = 'Task Specialist';
      } else if (newItem.UserType === 'SystemAdministrator') {
        newItem.UserType = 'System Administrator';
      } else if (newItem.UserType === 'DashboardUser') {
        newItem.UserType = 'Dashboard User';
      }

      newList.push(newItem);
    });

    return newList;
  },
);

export const getSitesList = createSelector(
  sites => sites.list,
  sites => {
    const sitesSorted = sites.map(value => ({
      siteName: value.AdditionalSiteID ? `${value.AdditionalSiteID.substring(0, 5)} ${value.Name}` : `${value.Name}`,
      siteId: value.Id,
    }));

    return sortBy(sitesSorted, [site => site.siteName.toLowerCase()]);
  },
);
