import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

const FirstTimeSetupDialog = ({ open, handleClose, handleNext }) => (
  <Dialog open={open} onRequestClose={handleClose} title="First Time Setup" titleColor={theme.darkGrey}>
    <S.DialogContainer>
      <S.SectionContainer>
        <S.Title>Purpose of this Action Card:</S.Title>
      </S.SectionContainer>
      <S.SectionContainer>
        <S.Text>
          Assist in quickly setting up what is needed to start testing the application in demonstration/test mode.
        </S.Text>
      </S.SectionContainer>
      <S.SectionContainer>
        <S.Text>There will be 3 steps</S.Text>
        <S.Text>1 - Site and People creation</S.Text>
        <S.Text>2 - List Template download or creation</S.Text>
        <S.Text>3 - Checklist creation</S.Text>
      </S.SectionContainer>
      <S.SectionContainer>
        <S.Title>Result:</S.Title>
        <S.Text>
          This process will result in installing at least one checklist for a Site and assign tasks to someone you
          choose for testing the application.
        </S.Text>
      </S.SectionContainer>
      <S.Text>All the tasks can be deleted at once after testing.</S.Text>
    </S.DialogContainer>
    <ActionsContainer>
      <ActionButton label="Next" onClick={handleNext} isNext />
    </ActionsContainer>
  </Dialog>
);

FirstTimeSetupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export { FirstTimeSetupDialog };
