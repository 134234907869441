import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { EnvelopeIcon } from 'components/Layout/Icons';

import { actions as authActions } from 'redux/auth';
import { actions as tasksActions } from 'redux/tasks';
import { actions as tenantsActions } from 'redux/tenants';
import { actions as settingsActions } from 'redux/settings';

import { getUser } from 'helpers/storage';

import * as CS from '../../styled';

import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import * as S from './styled';

import UpgradeInfoDialog from 'components/Dialogs/UpgradeInfoDialog';
import UpgradeInfoSuccessDialog from 'components/Dialogs/UpgradeInfoDialog/UpgradeInfoSuccessDialog';

class LoginPage extends PureComponent {
  static propTypes = {
    authenticate: PropTypes.func.isRequired,
    authenticated: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    industryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    tenantRequestsStatistics: PropTypes.shape({
      AvailableDemoTenantsCount: PropTypes.number,
      DemoTenantMonthTerm: PropTypes.number,
    }).isRequired,
    resetTasksRangeStart: PropTypes.func.isRequired,
    unsetReseted: PropTypes.func.isRequired,
    requestTennantIndustryList: PropTypes.func.isRequired,
    requestTenantRequestsStatistics: PropTypes.func.isRequired,
    requestUpgradeInfo: PropTypes.func.isRequired,
  };

  state = {
    authMode: 'signin',
    upgradeInfoOpened: false,
    successDialogOpen: false,
  };

  componentDidMount() {
    this.props.unsetReseted();
    this.props.resetTasksRangeStart();
    this.props.requestTennantIndustryList();
    this.props.requestTenantRequestsStatistics();
  }

  componentDidUpdate() {
    const { authenticated, loading, location, history } = this.props;

    const storedUser = getUser();

    if (storedUser && authenticated && !loading) {
      const path = location.state ? location.state.from.pathname : '/home';
      history.push(path);
    }
  }

  chooseSignInTab = () => this.setState({ authMode: 'signin' });
  chooseSignUpTab = () => this.setState({ authMode: 'signup' });

  openSuccessDialog = () => this.setState({ successDialogOpen: true });
  closeSuccessDialog = () => this.setState({ successDialogOpen: false });

  openUpgradeInfoDialog = () => this.setState({ upgradeInfoOpened: true });
  closeUpgradeInfoDialog = () => this.setState({ upgradeInfoOpened: false });
  submitUpgradeInfoDialog = values => {
    this.props.requestUpgradeInfo({ values, openSuccessDialog: this.openSuccessDialog });
    this.closeUpgradeInfoDialog();
  };

  render() {
    const { authenticate, loading, industryList, tenantRequestsStatistics } = this.props;
    const { authMode, upgradeInfoOpened, successDialogOpen } = this.state;

    return (
      <CS.Content>
        <CS.Logo />
        <S.SignInBookmark onClick={this.chooseSignInTab}>
          <S.SignInText>sign in</S.SignInText>
        </S.SignInBookmark>
        <LoginForm loading={loading} onSubmit={authenticate} authMode={authMode} />
        <SignUpForm
          authMode={authMode}
          industryList={industryList}
          chooseSignInTab={this.chooseSignInTab}
          tenantRequestsStatistics={tenantRequestsStatistics}
        />
        {authMode === 'signin' ? (
          <S.ForgotPasswordContainer>
            <S.ForgotLink to="/forgot">Forgot your password?</S.ForgotLink>
          </S.ForgotPasswordContainer>
        ) : (
          <S.UpgradeAccountContainer>
            <UpgradeInfoDialog
              handleClose={this.closeUpgradeInfoDialog}
              open={upgradeInfoOpened}
              title="Upgrade Info"
              onSubmit={this.submitUpgradeInfoDialog}
              BigButtons
              industryList={industryList}
              submitLabel="SEND REQUEST"
            />
            <EnvelopeIcon style={S.envelopeIconStyles} />
            <S.UpgradeAccountLink onClick={this.openUpgradeInfoDialog}>
              Click here for info or to ask about upgrading to a <b>paid</b> account
            </S.UpgradeAccountLink>
            <UpgradeInfoSuccessDialog open={successDialogOpen} onClose={this.closeSuccessDialog} />
          </S.UpgradeAccountContainer>
        )}
        <S.SignUpBookmark onClick={this.chooseSignUpTab}>
          <S.SignUpText>sign up</S.SignUpText>
        </S.SignUpBookmark>
      </CS.Content>
    );
  }
}

const mapStateToProps = ({ auth, tenants }) => ({
  authenticated: auth.authenticated,
  loading: auth.loading,
  industryList: tenants.industryList,
  tenantRequestsStatistics: tenants.tenantRequestsStatistics,
});

const mapDispatchToProps = {
  authenticate: authActions.authenticateRequest,
  resetTasksRangeStart: tasksActions.resetRangeStart,
  unsetReseted: authActions.resetedUnset,
  requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
  requestTenantRequestsStatistics: tenantsActions.tenantRequestsStatisticsRequest,
  requestUpgradeInfo: settingsActions.upgradeInfoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
