import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  EXCHANGE_LIST_REQUEST: undefined,
  EXCHANGE_LIST_SUCCESS: undefined,
  EXCHANGE_LIST_FAILURE: undefined,

  LIST_EXCHANGE_VERSIONS_REQUEST: undefined,
  LIST_EXCHANGE_VERSIONS_SUCCESS: undefined,
  LIST_EXCHANGE_VERSIONS_FAILURE: undefined,

  UNIT_APPROVE_REQUEST: undefined,
  UNIT_APPROVE_SUCCESS: undefined,
  UNIT_APPROVE_FAILURE: undefined,

  SITES_BY_TENANTS_LIST_REQUEST: undefined,
  SITES_BY_TENANTS_LIST_SUCCESS: undefined,
  SITES_BY_TENANTS_LIST_FAILURE: undefined,

  IMPORT_LIST_TO_TENANT_REQUEST: undefined,
  IMPORT_LIST_TO_TENANT_SUCCESS: undefined,
  IMPORT_LIST_TO_TENANT_FAILURE: undefined,

  GET_UNIT_VERSION_REQUEST: undefined,
  GET_UNIT_VERSION_SUCCESS: undefined,
  GET_UNIT_VERSION_FAILURE: undefined,

  GET_UNIT_FILES_LIST_REQUEST: undefined,
  GET_UNIT_FILES_LIST_SUCCESS: undefined,
  GET_UNIT_FILES_LIST_FAILURE: undefined,

  GET_ITEMS_LIST_REQUEST: undefined,
  GET_ITEMS_LIST_SUCCESS: undefined,
  GET_ITEMS_LIST_FAILURE: undefined,

  GET_ITEM_REQUEST: undefined,
  GET_ITEM_SUCCESS: undefined,
  GET_ITEM_FAILURE: undefined,

  GET_ITEM_FILES_LIST_REQUEST: undefined,
  GET_ITEM_FILES_LIST_SUCCESS: undefined,
  GET_ITEM_FILES_LIST_FAILURE: undefined,

  GET_ITEM_TASKS_LIST_REQUEST: undefined,
  GET_ITEM_TASKS_LIST_SUCCESS: undefined,
  GET_ITEM_TASKS_LIST_FAILURE: undefined,

  GET_ITEM_TASK_REQUEST: undefined,
  GET_ITEM_TASK_SUCCESS: undefined,
  GET_ITEM_TASK_FAILURE: undefined,

  DELETE_LAST_VERSION_REQUEST: undefined,
  DELETE_LAST_VERSION_SUCCESS: undefined,
  DELETE_LAST_VERSION_FAILURE: undefined,

  EDIT_NOTES_REQUEST: undefined,
  EDIT_NOTES_SUCCESS: undefined,
  EDIT_NOTES_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.exchangeListRequest, handlers.exchangeListRequest],
    [actions.exchangeListSuccess, handlers.exchangeListSuccess],
    [actions.exchangeListFailure, handlers.exchangeListFailure],

    [actions.listExchangeVersionsRequest, handlers.listExchangeVersionsRequest],
    [actions.listExchangeVersionsSuccess, handlers.listExchangeVersionsSuccess],
    [actions.listExchangeVersionsFailure, handlers.listExchangeVersionsFailure],

    [actions.unitApproveRequest, handlers.unitApproveRequest],
    [actions.unitApproveSuccess, handlers.unitApproveSuccess],
    [actions.unitApproveFailure, handlers.unitApproveFailure],

    [actions.sitesByTenantsListRequest, handlers.sitesByTenantsListRequest],
    [actions.sitesByTenantsListSuccess, handlers.sitesByTenantsListSuccess],
    [actions.sitesByTenantsListFailure, handlers.sitesByTenantsListFailure],

    [actions.importListToTenantRequest, handlers.importListToTenantRequest],
    [actions.importListToTenantSuccess, handlers.importListToTenantSuccess],
    [actions.importListToTenantFailure, handlers.importListToTenantFailure],

    [actions.getUnitVersionRequest, handlers.getUnitVersionRequest],
    [actions.getUnitVersionSuccess, handlers.getUnitVersionSuccess],
    [actions.getUnitVersionFailure, handlers.getUnitVersionFailure],

    [actions.getUnitFilesListRequest, handlers.getUnitFilesListRequest],
    [actions.getUnitFilesListSuccess, handlers.getUnitFilesListSuccess],
    [actions.getUnitFilesListFailure, handlers.getUnitFilesListFailure],

    [actions.getItemsListRequest, handlers.getItemsListRequest],
    [actions.getItemsListSuccess, handlers.getItemsListSuccess],
    [actions.getItemsListFailure, handlers.getItemsListFailure],

    [actions.getItemRequest, handlers.getItemRequest],
    [actions.getItemSuccess, handlers.getItemSuccess],
    [actions.getItemFailure, handlers.getItemFailure],

    [actions.getItemFilesListRequest, handlers.getItemFilesListRequest],
    [actions.getItemFilesListSuccess, handlers.getItemFilesListSuccess],
    [actions.getItemFilesListFailure, handlers.getItemFilesListFailure],

    [actions.getItemTasksListRequest, handlers.getItemTasksListRequest],
    [actions.getItemTasksListSuccess, handlers.getItemTasksListSuccess],
    [actions.getItemTasksListFailure, handlers.getItemTasksListFailure],

    [actions.getItemTaskRequest, handlers.getItemTaskRequest],
    [actions.getItemTaskSuccess, handlers.getItemTaskSuccess],
    [actions.getItemTaskFailure, handlers.getItemTaskFailure],

    [actions.deleteLastVersionRequest, handlers.deleteLastVersionRequest],
    [actions.deleteLastVersionSuccess, handlers.deleteLastVersionSuccess],
    [actions.deleteLastVersionFailure, handlers.deleteLastVersionFailure],

    [actions.editNotesRequest, handlers.editNotesRequest],
    [actions.editNotesSuccess, handlers.editNotesSuccess],
    [actions.editNotesFailure, handlers.editNotesFailure],
  ]),
  initialState,
);

export default reducer;
