import React from 'react';

import { Information, Text } from './styled';

const PdfItem = pdf => (
  <Information>
    <Text>{pdf.Name}</Text>
  </Information>
);

export default PdfItem;
