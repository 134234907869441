import { getSelectedSite } from 'helpers/storage';

const initialState = {
  createdUnitId: '',
  error: '',
  loading: false,
  unitsList: [],
  selectedSite: '',
  unitWithItemsList: [],
  listUnitsWithItems: [],
  isListUnitsWithItemsLoading: false,
  assetCategoriesList: [],
  assetTypesList: [],
};

export const addUnitRequest = state => ({
  ...state,
  createdUnitId: '',
  error: '',
  loading: true,
});

export const addUnitSuccess = (state, { payload }) => ({
  ...state,
  createdUnitId: payload.Id,
  loading: false,
});

export const addUnitFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const duplicateUnitRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const duplicateUnitFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const unitsListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});

export const unitsListSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  unitsList: payload,
  selectedSite: getSelectedSite(),
});

export const unitsListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const importTenantToListRequest = state => ({
  ...state,
  error: '',
  loading: false,
});

export const importTenantToListFailure = (state, { payload }) => ({
  ...state,
  error: payload.error,
  loading: false,
});

export const getUnitsWithItemsRequest = state => ({
  ...state,
  error: '',
  loading: false,
});

export const getUnitsWithItemsSuccess = (state, { payload }) => ({
  ...state,
  unitWithItemsList: payload,
  loading: false,
});

export const getUnitsWithItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const getListUnitsWithItemsRequest = state => ({
  ...state,
  error: '',
  loading: false,
  isListUnitsWithItemsLoading: true,
});

export const getListUnitsWithItemsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  listUnitsWithItems: payload,
  loading: false,
  isListUnitsWithItemsLoading: false,
});

export const getListUnitsWithItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
  isListUnitsWithItemsLoading: false,
});

export const updateItemsListRequest = state => ({
  ...state,
  error: '',
  loading: false,
});

export const updateItemsListFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  loading: false,
});

export const fetchAssetCategoriesListRequest = state => ({
  ...state,
  loading: true,
});

export const fetchAssetCategoriesListSuccess = (state, { payload }) => ({
  ...state,
  assetCategoriesList: payload,
  loading: false,
});

export const fetchAssetTypesListRequest = state => ({
  ...state,
  loading: true,
});

export const fetchAssetTypesListSuccess = (state, { payload }) => ({
  ...state,
  assetTypesList: payload,
  loading: false,
});

export default initialState;
