import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import { VisibilityIcon } from 'components/Layout/Icons';

export const tableHeads = {
  parts: [
    {
      cell: (selectAll, checkedAll) => (
        <div>
          <div>All</div>
          <Checkbox onClick={selectAll} checked={checkedAll} name="all" />
        </div>
      ),
      handlerName: 'selectAll',
    },
    { title: 'Distribution Name' },
    { title: 'My Part ID' },
    { title: 'Date Executed' },
    { title: 'Time' },
  ],
  groups: [
    {
      cell: (selectAll, checkedAll) => (
        <div>
          <div>All</div>
          <Checkbox onClick={selectAll} checked={checkedAll} name="all" />
        </div>
      ),
      handlerName: 'selectAll',
    },
    { title: 'Distribution Name' },
    { title: 'Group Name' },
    { title: 'Date Executed' },
    { title: 'Time' },
  ],
};

export const tableColumns = {
  parts: [
    {
      cell: (index, handleChange, checked) => <Checkbox onClick={handleChange(index)} checked={checked[index]} />,
      handlerName: 'handleOnChange',
    },
    { field: 'name' },
    { field: 'MyPartID' },
    { field: 'dateExecuted' },
    { field: 'time' },
    {
      field: 'details',
      icon: <VisibilityIcon />,
      tooltip: 'View details',
      iconAttribute: 'Details',
      handlerName: 'viewDetails',
    },
  ],
  groups: [
    {
      cell: (index, handleChange, checked) => <Checkbox onClick={handleChange(index)} checked={checked[index]} />,
      handlerName: 'handleOnChange',
    },
    { field: 'name' },
    { field: 'PartGroupName' },
    { field: 'dateExecuted' },
    { field: 'time' },
    {
      field: 'details',
      icon: <VisibilityIcon />,
      tooltip: 'View details',
      iconAttribute: 'Details',
      handlerName: 'viewDetails',
    },
  ],
};
