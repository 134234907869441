import React from 'react';

import * as S from './styled';

const Item = tenant => (
  <S.Container>
    <S.Cell>
      <S.Text>Name: {tenant.Name}</S.Text>
      {tenant.Notes && <S.Text>Notes: {tenant.Notes}</S.Text>}
    </S.Cell>
    <S.Cell>
      <S.Text>Industry: {tenant.IndustryName}</S.Text>
    </S.Cell>
    <S.Cell>
      <S.Text>Tier: {tenant.IsDemoTier ? 'Demo Tenants' : tenant.TierInfo.Name}</S.Text>
    </S.Cell>
  </S.Container>
);

export default Item;
