import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { ForwardIcon, GroupIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import { fixedTableHeadStyles } from 'components/TableList/styled';

export const TableContainer = styled.div`
  ${fixedTableHeadStyles}
  
  & td:nth-child(n+2):nth-child(-n+3){
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  th:nth-child(n){
    color: ${({ theme }) => theme.darkGrey}
    text-align: center;
    font-size: 14px;
  }

  td:nth-child(n){
    font-size: 14px;
  }

  th:nth-child(4), td:nth-child(4) {
    text-align: right;  
    min-width: 7vw;
  }

  th:nth-child(n+1):nth-child(-n+4), td:nth-child(n+1):nth-child(-n+4) {
    padding: 0 24px !important;
  }

  td:nth-child(n+2):nth-child(-n+3){
    min-width: 20vw;
  }
  
  & td:first-child {
    background-color: ${({ theme }) => theme.archiveNameColor}
    text-align: center;
    min-width: 5vw;
  }

  & td:last-child {
    text-align: right;
    width: 48px;
  }
`;

export const IconForward = styled(ForwardIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const TableCell = styled.td`
  div {
    display: inline-block;
  }
`;

export const EmptyList = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 220px;
`;

export const EmptyListText = styled.p`
  color: ${({ theme }) => theme.tenantNameColor};
  font-size: 16px;
`;

export const IconButton = styled(ButtonIcon)`
  width: 35px !important;
  padding: 0 !important;
  z-index: 0 !important;
  &:hover {
    transform: translateZ(0) scale(1);
  }
`;

export const RolesItem = styled.div`
  position: relative;
  min-width: 30%;
  text-align: left;
  color: ${({ theme, isColorRed }) => (isColorRed ? theme.primaryRed : theme.primaryGrey)};
`;

export const TooltipBlock = styled.div`
  position: relative;
  display: flex;
  width: 150px;
  padding: 20px;
  color: ${({ theme }) => theme.primaryWhite};
  justify-content: space-between;
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  padding-bottom: 10px;
`;

export const RolesTooltipBlock = styled.div`
  font-size: 12px;
  width: 100px;
  text-align: left;
  position: relative;
  font-weight: 100;
`;

export const RolesTooltipItem = styled.div`
  position: relative;
`;

export const RolesIcon = styled(GroupIcon).attrs({
  color: ({ theme }) => theme.darkGrey,
})`
  transform: scale(1.8);
`;

export const WhiteRolesIcon = styled(GroupIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
  style: { transition: 'none' },
})`
  transform: scale(1.8);
`;

export const Tooltip = styled(ReactTooltip)`
  background-color: ${({ theme }) => theme.primaryGrey} !important;
  text-align: center;
  border-radius: 10px !important;
  padding: 0px 7px !important;
  opacity: 0.95 !important;
  margin: 0px 50px 0px !important;
  font-size: 10px !important;

  &:after {
    display: none;
  }
`;
