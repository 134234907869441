export const constraints = {
  0: 'is',
  1: 'is not',
  2: 'begins with',
  4: 'ends with',
  8: 'contains',
  16: 'does not contain',
};

export const constraintsNumber = {
  0: 'is',
  1: 'is not',
  128: 'is greater than',
  256: 'is less than',
};

export const constraintsAvailability = {
  0: 'is',
  1: 'is not',
};

export const ignoreRules = ['SiteIgnore', 'UnitIgnore', 'ItemIgnore'];

export const ignoreRulesBySection = {
  location: {
    name: 'SiteIgnore',
  },
  unit: {
    name: 'UnitIgnore',
  },
  item: {
    name: 'ItemIgnore',
  },
};

export const types = {
  0: 'fixed - one time',
  1: 'dynamic - always on',
};

export const matchOperator = {
  0: 'All',
  1: 'Any of the following rules',
};

export const optionNames = {
  person: [
    { name: 'User Name', value: 'UserName' },
    { name: 'User First Name', value: 'UserFirstName' },
    { name: 'User Last Name', value: 'UserLastName' },
    { name: 'Availability', value: 'UserUserAvailabilityOptionId' },
    { name: 'Today Tasks Qty', value: 'TodayQty' },
    { name: 'This Week Tasks Qty', value: 'WeekQty' },
    { name: 'This Month Tasks Qty', value: 'MonthQty' },
  ],
  team: [
    { name: 'Team Name', value: 'TeamName' },
    { name: 'Team Leader Last Name', value: 'TeamManagerLastName' },
    { name: 'Team Leader First Name', value: 'TeamManagerFirstName' },
    { name: 'Team Assistant Last Name', value: 'TeamAssistantLastName' },
    { name: 'Team Location', value: 'TeamLocation' },
    { name: 'Team Notes', value: 'TeamNotes' },
    { name: 'Team Status', value: 'TeamStatus' },
  ],
  management: [
    { name: 'Team Name', value: 'UserTeamName' },
    { name: 'Last Name', value: 'UserLastName' },
    { name: 'First Name', value: 'UserFirstName' },
    { name: 'E-mail', value: 'UsereMail' },
    { name: 'Job Function', value: 'UserJobFunction' },
    { name: 'Availability', value: 'UserUserAvailabilityOptionId' },
    { name: 'Today Checklist Qty', value: 'TodayQty' },
    { name: 'This Week Checklist Qty', value: 'WeekQty' },
    { name: 'This Month Checklist Qty', value: 'MonthQty' },
    { name: 'This Year Checklist Qty', value: 'YearQty' },
  ],
};
