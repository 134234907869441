import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  CHECKLIST_STATUS_REQUEST: undefined,
  CHECKLIST_STATUS_SUCCESS: undefined,
  CHECKLIST_STATUS_FAILURE: undefined,

  MISSING_TASKS_LIST_REQUEST: undefined,
  MISSING_TASKS_LIST_SUCCESS: undefined,
  MISSING_TASKS_LIST_FAILURE: undefined,

  SCHEDULES_LIST_REQUEST: undefined,
  SCHEDULES_LIST_SUCCESS: undefined,
  SCHEDULES_LIST_FAILURE: undefined,

  FETCH_CURRENT_UNIT_REQUEST: undefined,
  FETCH_CURRENT_UNIT_SUCCESS: undefined,
  FETCH_CURRENT_UNIT_FAILURE: undefined,

  FETCH_SCHEDULE_UNIT_ITEMS_REQUEST: undefined,
  FETCH_SCHEDULE_UNIT_ITEMS_SUCCESS: undefined,
  FETCH_SCHEDULE_UNIT_ITEMS_FAILURE: undefined,

  ADD_SCHEDULE_REQUEST: undefined,
  ADD_SCHEDULE_SUCCESS: undefined,
  ADD_SCHEDULE_FAILURE: undefined,

  FETCH_SCHEDULE_DETAILS_REQUEST: undefined,
  FETCH_SCHEDULE_DETAILS_SUCCESS: undefined,
  FETCH_SCHEDULE_DETAILS_FAILURE: undefined,

  FETCH_CHECKLIST_ACTIVATION_REQUEST: undefined,
  FETCH_CHECKLIST_ACTIVATION_SUCCESS: undefined,
  FETCH_CHECKLIST_ACTIVATION_FAILURE: undefined,

  FETCH_SWITCH_AUTORUN_REQUEST: undefined,
  FETCH_SWITCH_AUTORUN_SUCCESS: undefined,
  FETCH_SWITCH_AUTORUN_FAILURE: undefined,

  FETCH_CHECKLIST_RUN_NEXT_PERIOD_REQUEST: undefined,
  FETCH_CHECKLIST_RUN_NEXT_PERIOD_SUCCESS: undefined,
  FETCH_CHECKLIST_RUN_NEXT_PERIOD_FAILURE: undefined,

  GET_USER_MANAGEMENT_REQUEST: undefined,
  GET_USER_MANAGEMENT_SUCCESS: undefined,
  GET_USER_MANAGEMENT_FAILURE: undefined,

  FETCH_SCHEDULE_ASSIGNABLE_USERS_REQUEST: undefined,
  FETCH_SCHEDULE_ASSIGNABLE_USERS_SUCCESS: undefined,
  FETCH_SCHEDULE_ASSIGNABLE_USERS_FAILURE: undefined,

  SET_SUPERVISOR_REQUEST: undefined,
  SET_SUPERVISOR_SUCCESS: undefined,
  SET_SUPERVISOR_FAILURE: undefined,

  EDIT_SCHEDULE_REQUEST: undefined,
  EDIT_SCHEDULE_SUCCESS: undefined,
  EDIT_SCHEDULE_FAILURE: undefined,

  CHANGE_SCHEDULE_REQUEST: undefined,
  CHANGE_SCHEDULE_SUCCESS: undefined,
  CHANGE_SCHEDULE_FAILURE: undefined,

  DELETE_SCHEDULE_REQUEST: undefined,
  DELETE_SCHEDULE_SUCCESS: undefined,
  DELETE_SCHEDULE_FAILURE: undefined,

  CHANGE_SCHEDULE_STATUS_REQUEST: undefined,
  CHANGE_SCHEDULE_STATUS_SUCCESS: undefined,
  CHANGE_SCHEDULE_STATUS_FAILURE: undefined,

  FETCH_TASK_ASSIGNABLE_USERS_REQUEST: undefined,
  FETCH_TASK_ASSIGNABLE_USERS_SUCCESS: undefined,
  FETCH_TASK_ASSIGNABLE_USERS_FAILURE: undefined,

  FETCH_SCHEDULE_TEMPLATES_REQUEST: undefined,
  FETCH_SCHEDULE_TEMPLATES_SUCCESS: undefined,
  FETCH_SCHEDULE_TEMPLATES_FAILURE: undefined,
  FETCH_SCHEDULE_TEMPLATES_AMOUNT: undefined,

  FETCH_ITEM_TEMPLATES_REQUEST: undefined,
  FETCH_ITEM_TEMPLATES_SUCCESS: undefined,
  FETCH_ITEM_TEMPLATES_FAILURE: undefined,

  REMOVE_TASK_TEMPLATE_REQUEST: undefined,
  REMOVE_TASK_TEMPLATE_SUCCESS: undefined,
  REMOVE_TASK_TEMPLATE_FAILURE: undefined,

  TASK_STATUS_REQUEST: undefined,
  TASK_STATUS_SUCCESS: undefined,
  TASK_STATUS_FAILURE: undefined,
  CLEAR_TASK_STATUS: undefined,

  ADD_CHECKLIST_TIMEFRAME_REQUEST: undefined,
  ADD_CHECKLIST_TIMEFRAME_SUCCESS: undefined,
  ADD_CHECKLIST_TIMEFRAME_FAILURE: undefined,

  ADD_TASK_TEMPLATE_REQUEST: undefined,
  ADD_TASK_TEMPLATE_SUCCESS: undefined,
  ADD_TASK_TEMPLATE_FAILURE: undefined,

  ASSIGN_EMPLOYEE_REQUEST: undefined,
  ASSIGN_EMPLOYEE_SUCCESS: undefined,
  ASSIGN_EMPLOYEE_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.schedulesListRequest, handlers.schedulesListRequest],
    [actions.schedulesListSuccess, handlers.schedulesListSuccess],
    [actions.schedulesListFailure, handlers.schedulesListFailure],

    [actions.fetchCurrentUnitRequest, handlers.fetchCurrentUnitRequest],
    [actions.fetchCurrentUnitSuccess, handlers.fetchCurrentUnitSuccess],
    [actions.fetchCurrentUnitFailure, handlers.fetchCurrentUnitFailure],

    [actions.missingTasksListRequest, handlers.missingTasksListRequest],
    [actions.missingTasksListSuccess, handlers.missingTasksListSuccess],
    [actions.missingTasksListFailure, handlers.missingTasksListFailure],

    [actions.checklistStatusRequest, handlers.checklistStatusRequest],
    [actions.checklistStatusSuccess, handlers.checklistStatusSuccess],
    [actions.checklistStatusFailure, handlers.checklistStatusFailure],

    [actions.fetchScheduleUnitItemsRequest, handlers.fetchScheduleUnitItemsRequest],
    [actions.fetchScheduleUnitItemsSuccess, handlers.fetchScheduleUnitItemsSuccess],
    [actions.fetchScheduleUnitItemsFailure, handlers.fetchScheduleUnitItemsFailure],

    [actions.addScheduleRequest, handlers.addScheduleRequest],
    [actions.addScheduleSuccess, handlers.addScheduleSuccess],
    [actions.addScheduleFailure, handlers.addScheduleFailure],

    [actions.fetchScheduleDetailsRequest, handlers.fetchScheduleDetailsRequest],
    [actions.fetchScheduleDetailsSuccess, handlers.fetchScheduleDetailsSuccess],
    [actions.fetchScheduleDetailsFailure, handlers.fetchScheduleDetailsFailure],

    [actions.fetchChecklistActivationRequest, handlers.fetchChecklistActivationRequest],
    [actions.fetchChecklistActivationSuccess, handlers.fetchChecklistActivationSuccess],
    [actions.fetchChecklistActivationFailure, handlers.fetchChecklistActivationFailure],

    [actions.fetchSwitchAutorunRequest, handlers.fetchSwitchAutorunRequest],
    [actions.fetchSwitchAutorunSuccess, handlers.fetchSwitchAutorunSuccess],
    [actions.fetchSwitchAutorunFailure, handlers.fetchSwitchAutorunFailure],

    [actions.fetchChecklistRunNextPeriodRequest, handlers.fetchChecklistRunNextPeriodRequest],
    [actions.fetchChecklistRunNextPeriodSuccess, handlers.fetchChecklistRunNextPeriodSuccess],
    [actions.fetchChecklistRunNextPeriodFailure, handlers.fetchChecklistRunNextPeriodFailure],

    [actions.getUserManagementRequest, handlers.getUserManagementRequest],
    [actions.getUserManagementSuccess, handlers.getUserManagementSuccess],
    [actions.getUserManagementFailure, handlers.getUserManagementFailure],

    [actions.fetchScheduleAssignableUsersRequest, handlers.fetchScheduleAssignableUsersRequest],
    [actions.fetchScheduleAssignableUsersSuccess, handlers.fetchScheduleAssignableUsersSuccess],
    [actions.fetchScheduleAssignableUsersFailure, handlers.fetchScheduleAssignableUsersFailure],

    [actions.setSupervisorRequest, handlers.setSupervisorRequest],
    [actions.setSupervisorSuccess, handlers.setSupervisorSuccess],
    [actions.setSupervisorFailure, handlers.setSupervisorFailure],

    [actions.editScheduleRequest, handlers.editScheduleRequest],
    [actions.editScheduleSuccess, handlers.editScheduleSuccess],
    [actions.editScheduleFailure, handlers.editScheduleFailure],

    [actions.changeScheduleRequest, handlers.changeScheduleRequest],
    [actions.changeScheduleSuccess, handlers.changeScheduleSuccess],
    [actions.changeScheduleFailure, handlers.changeScheduleFailure],

    [actions.deleteScheduleRequest, handlers.deleteScheduleRequest],
    [actions.deleteScheduleSuccess, handlers.deleteScheduleSuccess],
    [actions.deleteScheduleFailure, handlers.deleteScheduleFailure],

    [actions.changeScheduleStatusRequest, handlers.changeScheduleStatusRequest],
    [actions.changeScheduleStatusSuccess, handlers.changeScheduleStatusSuccess],
    [actions.changeScheduleStatusFailure, handlers.changeScheduleStatusFailure],

    [actions.fetchTaskAssignableUsersRequest, handlers.fetchTaskAssignableUsersRequest],
    [actions.fetchTaskAssignableUsersSuccess, handlers.fetchTaskAssignableUsersSuccess],
    [actions.fetchTaskAssignableUsersFailure, handlers.fetchTaskAssignableUsersFailure],

    [actions.fetchScheduleTemplatesRequest, handlers.fetchScheduleTemplatesRequest],
    [actions.fetchScheduleTemplatesSuccess, handlers.fetchScheduleTemplatesSuccess],
    [actions.fetchScheduleTemplatesFailure, handlers.fetchScheduleTemplatesFailure],
    [actions.fetchScheduleTemplatesAmount, handlers.fetchScheduleTemplatesAmount],

    [actions.fetchItemTemplatesRequest, handlers.fetchItemTemplatesRequest],
    [actions.fetchItemTemplatesSuccess, handlers.fetchItemTemplatesSuccess],
    [actions.fetchItemTemplatesFailure, handlers.fetchItemTemplatesFailure],

    [actions.removeTaskTemplateRequest, handlers.removeTaskTemplateRequest],
    [actions.removeTaskTemplateSuccess, handlers.removeTaskTemplateSuccess],
    [actions.removeTaskTemplateFailure, handlers.removeTaskTemplateFailure],

    [actions.taskStatusRequest, handlers.taskStatusRequest],
    [actions.taskStatusSuccess, handlers.taskStatusSuccess],
    [actions.taskStatusFailure, handlers.taskStatusFailure],
    [actions.clearTaskStatus, handlers.clearTaskStatus],

    [actions.addChecklistTimeframeRequest, handlers.addChecklistTimeframeRequest],
    [actions.addChecklistTimeframeSuccess, handlers.addChecklistTimeframeSuccess],
    [actions.addChecklistTimeframeFailure, handlers.addChecklistTimeframeFailure],

    [actions.addTaskTemplateRequest, handlers.addTaskTemplateRequest],
    [actions.addTaskTemplateSuccess, handlers.addTaskTemplateSuccess],
    [actions.addTaskTemplateFailure, handlers.addTaskTemplateFailure],

    [actions.assignEmployeeRequest, handlers.assignEmployeeRequest],
    [actions.assignEmployeeSuccess, handlers.assignEmployeeSuccess],
    [actions.assignEmployeeFailure, handlers.assignEmployeeFailure],
  ]),
  initialState,
);

export default reducer;
