import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SettingsListType } from 'configs/propTypes';

import { actions as settingsActions } from 'redux/settings';
import { actions as appActions } from 'redux/app';
import { actions as tutorialsActions } from 'redux/tutorials';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as tenantsActions } from 'redux/tenants';

import {
  AddSignUpNotification,
  AssigmentIcon,
  DialpadIcon,
  ExportQRIcon,
  GateWayIcon,
  HubIcon,
  ImageIcon,
  IndustryIcon,
  KeyIcon,
  LinkIcon,
  LimitsIcon,
  MeteringIcon,
  PricingIcon,
  ResourceGroupsIcon,
  TextIcon,
  Vimeo,
  ListsModuleIcon,
} from 'components/Layout/Icons';
import LogoDialog from 'components/Dialogs/LogoDialog';
import ScanDialog from 'components/Dialogs/ScanDialog';
import LinksDialog from 'components/Dialogs/LinksDialog';
import TextDialog from 'components/Dialogs/TextDialog';

import KyesDialog from '../Keys';
import SignUpNotificationDialog from '../SignUpNotification';
import SystemLimitsDialog from '../SystemLimits';

import * as S from './styled';
import VimeoAccountSetupDialog from '../VimeoAccountSetupDialog';

class SettingsList extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    requestGetPhoto: PropTypes.func.isRequired,
    requestGetPhotoEmail: PropTypes.func.isRequired,
    requestGetSettings: PropTypes.func.isRequired,
    requestGetSettingsLinksList: PropTypes.func.isRequired,
    requestSystemLimits: PropTypes.func.isRequired,
    requestSetSettings: PropTypes.func.isRequired,
    requestSignUpNotification: PropTypes.func.isRequired,
    requestCurrentTenantsCount: PropTypes.func.isRequired,
    setSignUpNotification: PropTypes.func.isRequired,
    setSystemLimits: PropTypes.func.isRequired,
    setDefaultLinksSettings: PropTypes.func.isRequired,
    settings: SettingsListType.isRequired,
    updateCurrentGlobalSettingsModule: PropTypes.func.isRequired,
    refreshVimeoCacheRequest: PropTypes.func.isRequired,
  };

  state = {
    LogoOpened: false,
    ScanOpened: false,
    LinksOpened: false,
    TextOpened: false,
    KyesOpened: false,
    SignUpNotificationOpened: false,
    LimitsOpened: false,
    changedValues: {},
    isVimeoAccountSetupDialogOpen: false,
  };

  componentDidMount() {
    this.props.requestGetSettings(true);
    this.props.requestCurrentTenantsCount();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.loading && nextState.ScanOpened) {
      return false;
    }
    return true;
  }

  closeLogoDialog = () => {
    this.setState({ LogoOpened: false });
  };

  openLogoDialog = () => {
    this.props.requestGetPhoto('master');
    this.props.requestGetPhotoEmail('master');
    this.setState({ LogoOpened: true });
  };

  closeScanDialog = () => {
    this.props.updateCurrentGlobalSettingsModule();
    this.setState({ ScanOpened: false });
  };

  openScanDialog = () => {
    this.props.updateCurrentGlobalSettingsModule('qr');
    this.setState({ ScanOpened: true });
  };

  closeTextDialog = () => {
    this.setState({ TextOpened: false });
  };

  openTextDialog = () => {
    this.setState({ TextOpened: true });
  };

  submitScanDialog = values => {
    const { settings } = this.props;

    this.props.requestSetSettings({
      isMaster: true,
      values: { ...settings, ...values },
    });
    this.closeScanDialog();
  };

  closeLinksDialog = () => {
    this.props.updateCurrentGlobalSettingsModule();
    this.setState({ LinksOpened: false });
  };

  openLinksDialog = () => {
    this.props.updateCurrentGlobalSettingsModule('systemLinks');
    this.props.requestGetSettingsLinksList({ isMaster: true });
    this.setState({ LinksOpened: true });
  };

  openKeysModal = () => this.setState({ KyesOpened: true });
  closeKeysModal = () => this.setState({ KyesOpened: false });

  openVimeoAccountSetupDialog = () => this.setState({ isVimeoAccountSetupDialogOpen: true });

  closeVimeoAccountSetupDialog = () => this.setState({ isVimeoAccountSetupDialogOpen: false });

  openSignUpNotificationDialog = () => {
    this.props.requestSignUpNotification();
    this.setState({ SignUpNotificationOpened: true });
  };
  closeSignUpNotificationDialog = () => this.setState({ SignUpNotificationOpened: false });

  openLimitsDialog = () => {
    this.props.requestSystemLimits();
    this.setState({ LimitsOpened: true });
  };
  closeLimitsDialog = () => this.setState({ LimitsOpened: false });

  getChangedValues = (value, rest) => {
    const { changedValues } = this.state;
    this.setState({
      changedValues: {
        ...changedValues,
        [rest.link.Id]: rest.reset
          ? rest.link
          : {
              ...(changedValues[rest.link.Id] ? changedValues[rest.link.Id] : rest.link),
              [rest.name.substring(1)]: value,
            },
      },
    });
  };

  submitLinksDialog = values => {
    const { settings } = this.props;
    const { changedValues } = this.state;
    this.props.requestSetSettings({
      isMaster: true,
      values: { ...settings, LinkIntroText: values.LinkIntroText },
    });
    this.props.setDefaultLinksSettings({ requestValues: changedValues, isMaster: true, stateValues: values });
    this.closeLinksDialog();
  };

  submitTextDialog = values => {
    const { settings } = this.props;

    this.props.requestSetSettings({
      isMaster: true,
      values: { ...settings, ...values },
    });
    this.closeTextDialog();
  };

  submitSignUpNotification = values => {
    this.props.setSignUpNotification(values);
    this.closeSignUpNotificationDialog();
  };

  submitSystemLimits = values => {
    this.props.setSystemLimits(values);
    this.closeLimitsDialog();
  };

  render() {
    const {
      LimitsOpened,
      LinksOpened,
      LogoOpened,
      ScanOpened,
      TextOpened,
      KyesOpened,
      SignUpNotificationOpened,
      isVimeoAccountSetupDialogOpen,
    } = this.state;

    return [
      <S.Container key="ListItemsContainer">
        <S.List>
          <S.ListItem key="General">
            <S.ItemTitle>General</S.ItemTitle>
            <S.IconButton onClick={this.openLogoDialog}>
              <ImageIcon />
              <S.ButtonCaption>Logos</S.ButtonCaption>
            </S.IconButton>
            <S.SecondButton onClick={this.openLimitsDialog}>
              <LimitsIcon />
              <S.ButtonCaption>Limits</S.ButtonCaption>
            </S.SecondButton>
            <S.RouterLink to="/globalsettings/industries">
              <S.SecondButton>
                <IndustryIcon />
                <S.ButtonCaption>Industries</S.ButtonCaption>
              </S.SecondButton>
            </S.RouterLink>
            <S.RouterLink to="/globalsettings/asset">
              <S.SecondButton>
                <ListsModuleIcon />
                <S.ButtonCaption>Templates</S.ButtonCaption>
              </S.SecondButton>
            </S.RouterLink>
          </S.ListItem>
          <S.ListItem key="Verification">
            <S.ItemTitle>Verification</S.ItemTitle>
            <S.RouterLink to="/globalsettings/pages">
              <S.IconButton>
                <AssigmentIcon />
                <S.ButtonCaption>Tasks</S.ButtonCaption>
              </S.IconButton>
            </S.RouterLink>
            <S.SecondButton onClick={this.openScanDialog}>
              <ExportQRIcon />
              <S.ButtonCaption>Scan</S.ButtonCaption>
            </S.SecondButton>
          </S.ListItem>
          <S.ListItem key="Notifications">
            <S.ItemTitle>Notifications</S.ItemTitle>
            <S.IconButton onClick={this.openTextDialog}>
              <TextIcon />
              <S.ButtonCaption>Text</S.ButtonCaption>
            </S.IconButton>
            <S.SecondButton onClick={this.openLinksDialog}>
              <LinkIcon />
              <S.ButtonCaption>Links</S.ButtonCaption>
            </S.SecondButton>
            <S.SecondButton onClick={this.openSignUpNotificationDialog}>
              <AddSignUpNotification />
              <S.ButtonCaption>Signups</S.ButtonCaption>
            </S.SecondButton>
          </S.ListItem>
          <S.ListItem key="IoT">
            <S.ItemTitle type="IoT">IoT</S.ItemTitle>
            <S.RouterLink customMargin="19px" to="/globalsettings/resourcegroups">
              <S.IconButton>
                <ResourceGroupsIcon />
                <S.ButtonCaption>Resource Groups</S.ButtonCaption>
              </S.IconButton>
            </S.RouterLink>
            <S.RouterLink to="/globalsettings/hubs">
              <S.IconButton>
                <HubIcon />
                <S.ButtonCaption>Hubs</S.ButtonCaption>
              </S.IconButton>
            </S.RouterLink>
            <S.RouterLink to="/globalsettings/gateways">
              <S.SecondButton>
                <GateWayIcon />
                <S.ButtonCaption>Gateways</S.ButtonCaption>
              </S.SecondButton>
            </S.RouterLink>
            <S.IconButton onClick={this.openKeysModal}>
              <KeyIcon />
              <S.ButtonCaption>Keys</S.ButtonCaption>
            </S.IconButton>
          </S.ListItem>
          <S.ListItem key="Accounts">
            <S.ItemTitle>Accounts</S.ItemTitle>
            <S.RouterLink customMargin="12px" to="/globalsettings/metering">
              <S.IconButton>
                <MeteringIcon />
                <S.ButtonCaption>Metering</S.ButtonCaption>
              </S.IconButton>
            </S.RouterLink>
            <S.RouterLink to="/globalsettings/pricing">
              <S.IconButton>
                <PricingIcon />
                <S.ButtonCaption>Pricing</S.ButtonCaption>
              </S.IconButton>
            </S.RouterLink>
          </S.ListItem>
          <S.ListItem key="Technical">
            <S.ItemTitle>Technical</S.ItemTitle>
            <S.RouterLink to="/globalsettings/versionlegals">
              <S.SecondButton>
                <DialpadIcon />
                <S.ButtonCaption>Versions</S.ButtonCaption>
              </S.SecondButton>
            </S.RouterLink>
            <S.IconButton onClick={this.openVimeoAccountSetupDialog}>
              <Vimeo />
              <S.ButtonCaption>Vimeo</S.ButtonCaption>
            </S.IconButton>
          </S.ListItem>
        </S.List>
      </S.Container>,
      <LogoDialog
        key="LogoDialog"
        handleClose={this.closeLogoDialog}
        title="Tenants Logos (replace defaults)"
        open={LogoOpened}
      />,
      <ScanDialog
        key="ScanDialog"
        handleClose={this.closeScanDialog}
        title="Default QR settings for Units and Items"
        open={ScanOpened}
        onSubmit={this.submitScanDialog}
        withoutTopBorder
        BigButtons
      />,
      <TextDialog
        key="TextDialog"
        handleClose={this.closeTextDialog}
        title="Notifications Text"
        open={TextOpened}
        onSubmit={this.submitTextDialog}
        BigButtons
      />,
      <LinksDialog
        key="LinksDialog"
        handleClose={this.closeLinksDialog}
        title="System Links"
        open={LinksOpened}
        BigButtons
        onSubmit={this.submitLinksDialog}
        getChangedValues={this.getChangedValues}
      />,
      <KyesDialog key="KeysDialog" handleClose={this.closeKeysModal} open={KyesOpened} />,
      <SignUpNotificationDialog
        key="SignUpNotification"
        title="New Account Message"
        handleClose={this.closeSignUpNotificationDialog}
        onSubmit={this.submitSignUpNotification}
        open={SignUpNotificationOpened}
      />,
      <SystemLimitsDialog
        key="SystemLimits"
        title="System Limits"
        open={LimitsOpened}
        handleClose={this.closeLimitsDialog}
        onSubmit={this.submitSystemLimits}
      />,
      <VimeoAccountSetupDialog
        open={isVimeoAccountSetupDialogOpen}
        handleClose={this.closeVimeoAccountSetupDialog}
        refreshVimeoCacheRequest={this.props.refreshVimeoCacheRequest}
      />,
    ];
  }
}

const mapStateToProps = ({ settings }) => ({
  loading: settings.loading,
  settings: settings.list,
});

const mapDispatchToProps = {
  openConfirmationDialog,
  openErrorDialog,
  requestGetPhoto: settingsActions.getLogoRequest,
  requestGetPhotoEmail: settingsActions.getLogoEmailRequest,
  requestGetSettings: settingsActions.getSettingsRequest,
  requestGetSettingsLinksList: settingsActions.getSettingsLinksListRequest,
  requestSystemLimits: settingsActions.getSystemLimitsRequest,
  requestSetSettings: settingsActions.setSettingsRequest,
  requestSignUpNotification: settingsActions.getSignUpNotificationRequest,
  requestCurrentTenantsCount: tenantsActions.getCurrentTenantsCountRequest,
  setSignUpNotification: settingsActions.setSignUpNotificationRequest,
  setSystemLimits: settingsActions.setSystemLimitsRequest,
  setDefaultLinksSettings: settingsActions.setSettingsLinksRequest,
  updateCurrentGlobalSettingsModule: appActions.updateCurrentGlobalSettingsModule,
  refreshVimeoCacheRequest: tutorialsActions.refreshVimeoCacheRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsList);
