import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

class Item extends Component {
  static propTypes = {
    item: PropTypes.shape({
      Id: PropTypes.string.isRequired,
      TeamID: PropTypes.string.isRequired,
      UserID: PropTypes.string.isRequired,
      User: PropTypes.shape({
        Status: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
        UserType: PropTypes.string.isRequired,
        UserAvailabilityOptionName: PropTypes.string.isRequired,
        JobFunctionName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
  };
  state = {
    currentUser: {},
  };

  render() {
    const {
      item: { User },
      onClick,
    } = this.props;

    return (
      <S.Gateway onClick={() => onClick(User)}>
        {User.Status !== 'Active' && <S.InactiveMarker />}
        <S.InfoBlock>
          <S.Text>{User.Name}</S.Text>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.Text>User Type: {User.UserType}</S.Text>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.Text>Job Function: {User.JobFunctionName} </S.Text>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.Text>Status: {User.Status}</S.Text>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.Text>Availability: {User.UserAvailabilityOptionName} </S.Text>
        </S.InfoBlock>
      </S.Gateway>
    );
  }
}

export default Item;
