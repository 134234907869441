import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, MenuItem, IconMenu } from 'material-ui';

import * as S from './styled';

const DropdownMenu = ({ icon, items, onChange, headerText }) => (
  <IconMenu iconButtonElement={<IconButton>{icon}</IconButton>} onChange={onChange}>
    {headerText ? (
      <S.HeaderMenuItem disabled key="header">
        {headerText}
      </S.HeaderMenuItem>
    ) : null}
    {items.map(item => (
      <MenuItem key={item.value} value={item.value} primaryText={item.label} />
    ))}
  </IconMenu>
);

DropdownMenu.propTypes = {
  icon: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  headerText: PropTypes.string,
};

DropdownMenu.defaultProps = {
  headerText: '',
};

export default DropdownMenu;
