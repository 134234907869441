import React from 'react';
import PropTypes from 'prop-types';

import { Container, FlatButton } from './styled';

const Footer = ({ onNext, onPrev }) => (
  <Container>
    <FlatButton label="Previous Day" onClick={onPrev} />
    <FlatButton label="Next Day" onClick={onNext} />
  </Container>
);

Footer.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

export default Footer;
