import React from 'react';
import PropTypes from 'prop-types';

import { ToggleItem } from 'configs/propTypes';
import { CalendarIcon, ExportCSVIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';
import { DatePicker } from './styled.js';

const Toolbar = ({ exportDayTriggers, onDayChange, openCalendar, selectedType, typeConfig, typeHandler }) => (
  <Subheader
    leftButtons={[
      {
        icon: <CalendarIcon />,
        handler: openCalendar,
        hint: 'Set day',
      },
      {
        icon: <ExportCSVIcon />,
        handler: exportDayTriggers,
        hint: 'Export per Day',
        tooltipStyles: {
          whiteSpace: 'nowrap',
        },
      },
      {
        isComponent: true,
        component: <DatePicker id="queue-day-picker" onChange={onDayChange} />,
      },
    ]}
    rightButtons={[
      {
        isComponent: true,
        component: <Toggle config={typeConfig} selected={selectedType} handler={typeHandler} />,
      },
    ]}
  />
);

Toolbar.propTypes = {
  exportDayTriggers: PropTypes.func.isRequired,
  onDayChange: PropTypes.func.isRequired,
  openCalendar: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  typeConfig: PropTypes.arrayOf(ToggleItem).isRequired,
  typeHandler: PropTypes.func.isRequired,
};

export default Toolbar;
