import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { actions as unitActions } from 'redux/unit';
import { actions } from './index';
import { formatServerError } from 'helpers';
import * as http from 'http/item';

function* uploadPdf(payload) {
  try {
    const { files, itemId } = payload.payload;
    const params = new FormData();
    params.append('file', files[0]);
    params.append('parentId', itemId);
    params.append('dto.ItemId', itemId);
    yield call(http.uploadPdf, itemId, params);
    const pdfs = yield call(http.fetchPDFs, itemId);
    yield put(actions.uploadPdfSuccess({ pdfs }));
  } catch (error) {
    yield put(actions.uploadPdfFailure(formatServerError(error, 'An error occurred while saving the Unit Pdf.')));
  }
}

function* deletePdf({ payload: { pdfId, pdfName, itemId } }) {
  try {
    yield call(http.deletePfd, pdfId, pdfName, itemId);
    const pdfs = yield call(http.fetchPDFs, itemId);
    yield put(actions.deletePdfSuccess({ pdfs }));
  } catch (error) {
    yield put(actions.deletePdfFailure(formatServerError(error, 'An error occurred while deleting the Item PDF.')));
  }
}

function* deletePicture({ payload }) {
  try {
    const { itemId, pictureId, pictureName } = payload;
    yield call(http.deletePicture, pictureId, pictureName, itemId);
    const pictures = yield call(http.fetchPictures, itemId);
    yield put(actions.deletePictureSuccess({ pictures }));
  } catch (error) {
    yield put(
      actions.deletePictureFailure(formatServerError(error, 'An error occurred while deleting the Item Picture.')),
    );
  }
}

function* uploadPicture({ payload: { files, itemId } }) {
  try {
    const params = new FormData();
    params.append('file', files[0]);
    params.append('parentId', itemId);
    params.append('dto.ItemId', itemId);
    yield call(http.uploadPicture, itemId, params);
    const pictures = yield call(http.fetchPictures, itemId);
    yield put(actions.uploadPictureSuccess({ pictures }));
  } catch (error) {
    yield put(
      actions.uploadPictureFailure(formatServerError(error, 'An error occurred while saving the Item Picture.')),
    );
  }
}

function* fetchItemTasks({ payload }) {
  try {
    const tasks = yield call(http.fetchTaskTemplates, payload);
    yield put(actions.fetchItemTasksSuccess(tasks));
  } catch (error) {
    yield put(actions.fetchItemTasksFailure(formatServerError(error, 'An error occurred while fetching tasks.')));
  }
}

function* fetchItemDetails({ payload }) {
  try {
    const [details, pdfs, pictures, tasks] = yield all([
      call(http.fetchItem, payload),
      call(http.fetchPDFs, payload),
      call(http.fetchPictures, payload),
      call(http.fetchTaskTemplates, payload),
    ]);
    yield put(
      actions.fetchItemDetailsSuccess({
        details,
        pdfs,
        pictures,
        tasks,
      }),
    );
  } catch (error) {
    yield put(actions.fetchItemDetailsFailure(formatServerError(error, 'An error occurred while fetching item.')));
  }
}

function* deleteTask({ payload: { itemId, taskId } }) {
  try {
    yield call(http.deleteTask, taskId, itemId);
    const tasks = yield call(http.fetchTaskTemplates, itemId);
    yield put(actions.deleteTaskSuccess({ tasks }));
  } catch (error) {
    yield put(actions.deleteTaskFailure(formatServerError(error, 'An error occurred while deleting task.')));
  }
}

function* deleteItem({ payload: { itemId, unitId, goBack } }) {
  try {
    yield call(http.deleteItem, itemId, unitId);
    yield put(actions.deleteItemSuccess());
    yield put(unitActions.fetchUnitItemsRequest({ unitId }));
    yield call(goBack);
  } catch (error) {
    yield put(actions.deleteItemFailure(formatServerError(error, 'An error occurred while deleting item.')));
  }
}

function* editItem({ payload: { item, onSuccess } }) {
  try {
    yield call(http.editItem, item);
    const details = yield call(http.fetchItem, item.Id);
    yield put(actions.editItemSuccess({ details }));
    yield call(onSuccess);
  } catch (error) {
    yield put(actions.editItemFailure(formatServerError(error, 'An error occurred while saving the Item.')));
  }
}

function* editTask({ payload: { itemId, data, onSuccess } }) {
  try {
    yield call(http.editTask, itemId, data);
    const tasks = yield call(http.fetchTaskTemplates, itemId);
    yield put(actions.editTaskSuccess({ tasks }));
    yield call(onSuccess);
  } catch (error) {
    yield put(actions.editTaskFailure(formatServerError(error, 'An error occurred while saving the Task.')));
  }
}

function* addTask({ payload: { itemId, data, onSuccess } }) {
  try {
    yield call(http.addTask, itemId, data);
    const tasks = yield call(http.fetchTaskTemplates, itemId);
    yield put(actions.addTaskSuccess({ tasks }));
    yield call(onSuccess);
  } catch (error) {
    yield put(actions.addTaskFailure(formatServerError(error, 'An error occurred while saving the Task.')));
  }
}

function* getDefaultBarcode({ payload: { params, reset } }) {
  try {
    const { data } = yield call(http.getBarcode, params);
    yield put(actions.getDefaultBarcodeSuccess({ data }));
    reset(data?.entity);
  } catch (error) {
    yield put(actions.getDefaultBarcodeFailure(formatServerError(error, 'An error occurred while saving the Task.')));
  }
}

function* dublicateTask({ payload: { params } }) {
  try {
    const { data } = yield call(http.dublicateTask, params);
    yield put(actions.dublicateTaskSuccess({ data }));
  } catch (error) {
    yield put(actions.dublicateTaskFailure(formatServerError(error, 'An error occurred while saving the Task.')));
  }
}

const itemSagas = [
  takeEvery(actions.fetchItemDetailsRequest, fetchItemDetails),
  takeEvery(actions.deletePdfRequest, deletePdf),
  takeEvery(actions.uploadPdfRequest, uploadPdf),
  takeEvery(actions.deletePictureRequest, deletePicture),
  takeEvery(actions.uploadPictureRequest, uploadPicture),
  takeEvery(actions.deleteTaskRequest, deleteTask),
  takeEvery(actions.deleteItemRequest, deleteItem),
  takeEvery(actions.editItemRequest, editItem),
  takeEvery(actions.editTaskRequest, editTask),
  takeEvery(actions.addTaskRequest, addTask),
  takeEvery(actions.getDefaultBarcodeRequest, getDefaultBarcode),
  takeEvery(actions.dublicateTaskRequest, dublicateTask),
  takeLatest(actions.fetchItemTasksRequest, fetchItemTasks),
];

export default itemSagas;
