import { call, put, takeEvery, all } from 'redux-saga/effects';

import { formatServerError } from 'helpers';

import {
  deleteLogo,
  deleteLogoEmail,
  fetchDemoTasksCount,
  getLogo,
  getLogoEmail,
  getMasterSettings,
  fetchMasterSettingsForAdmin,
  fetchMasterSettingsLinksForAdmin,
  uploadLogoEmail,
  getMasterSystemLimits,
  setSettings,
  setSettingsLinks,
  putSignUpNotification,
  putSystemLimits,
  getSettingsLinksList,
  getSignUpNotification,
  getUpgradeInfo,
  uploadPhoto,
  removeDemoTasks,
} from 'http/settings';

import { addSpacingForNotifications } from './helpers';
import { actions } from './index';

function* fetchUploadPhoto({ payload }) {
  const params = new FormData();
  params.append('file', payload.file[0]);
  try {
    yield call(uploadPhoto, payload, params);
    yield put(actions.uploadLogoSuccess());
    yield put(actions.getLogoRequest(payload.isMaster ? 'master' : ''));
  } catch (error) {
    yield put(actions.uploadLogoFailure(error.response.data));
  }
}

function* fetchGetPhoto({ payload }) {
  try {
    yield call(getLogo, payload);
    if (payload !== 'master') {
      yield put(actions.getLogoSuccess(payload !== 'master'));
      yield put(actions.getSettingsRequest());
    } else {
      yield put(actions.getLogoMasterSuccess());
    }
  } catch (error) {
    yield put(actions.getLogoFailure(error));
  }
}

function* fetchDeletePhoto({ payload }) {
  try {
    yield call(deleteLogo, payload);
    yield put(actions.deleteLogoSuccess());
    yield put(actions.getLogoRequest(payload ? 'master' : ''));
  } catch (error) {
    yield put(actions.deleteLogoFailure(error));
  }
}

function* fetchUploadPhotoEmail({ payload }) {
  const params = new FormData();
  params.append('file', payload.file[0]);
  try {
    yield call(uploadLogoEmail, payload, params);
    yield put(actions.uploadLogoEmailSuccess());
    yield put(actions.getLogoEmailRequest(payload.isMaster ? 'master' : ''));
  } catch (error) {
    yield put(actions.uploadLogoEmailFailure(error.response.data));
  }
}

function* fetchGetPhotoEmail({ payload }) {
  try {
    yield call(getLogoEmail, payload);
    if (payload !== 'master') {
      yield put(actions.getLogoEmailSuccess(payload !== 'master'));
      yield put(actions.getSettingsRequest());
    } else {
      yield put(actions.getLogoEmailMasterSuccess());
    }
  } catch (error) {
    yield put(actions.getLogoEmailFailure(error));
  }
}

function* fetchDeletePhotoEmail({ payload }) {
  try {
    yield call(deleteLogoEmail, payload);
    yield put(actions.deleteLogoEmailSuccess());
    yield put(actions.getLogoEmailRequest(payload ? 'master' : ''));
  } catch (error) {
    yield put(actions.deleteLogoEmailFailure(error));
  }
}

function* fetchGetSettings({ payload }) {
  try {
    const { data } = yield call(getMasterSettings, payload);
    yield put(actions.getSettingsSuccess(addSpacingForNotifications(data.entity, /<br>/g, '\n')));
  } catch (error) {
    yield put(actions.getSettingsFailure(error.response.data));
  }
}

function* fetchSetSettings({ payload: { isMaster, values } }) {
  try {
    const valuesWithSpacing = addSpacingForNotifications(values, /[\r\n]/g, '<br>');
    const { data } = yield call(setSettings, isMaster, valuesWithSpacing);
    yield put(actions.setSettingsSuccess(addSpacingForNotifications(data.entity, /<br>/g, '\n')));
  } catch (error) {
    yield put(actions.setSettingsFailure(error));
  }
}

function* fetchSetSettingsLinks({ payload: { requestValues, isMaster, stateValues } }) {
  try {
    const requests = Object.entries(requestValues).map(link => {
      const params = {
        id: link[0],
        dto: link[1],
      };
      return call(setSettingsLinks, isMaster, params);
    });
    yield all(requests);
    yield put(actions.setSettingsLinksSuccess(stateValues));
  } catch (error) {
    yield put(actions.setSettingsLinksFailure(error));
  }
}

function* fetchSettingsLinksList({ payload: { isMaster } }) {
  try {
    const {
      data: { root },
    } = yield call(getSettingsLinksList, isMaster);
    yield put(actions.getSettingsLinksListSuccess(root));
  } catch (error) {
    yield put(actions.setSettingsLinksFailure(error));
  }
}

function* getMasterSettingsLinksForAdmin() {
  try {
    const {
      data: { root },
    } = yield call(fetchMasterSettingsLinksForAdmin);
    yield put(actions.getMasterSettingsLinksForAdminSuccess(root));
  } catch (error) {
    yield put(actions.getMasterSettingsLinksForAdminFailure(error));
  }
}

function* fetchGetMasterSettingsForAdmin() {
  try {
    const { data } = yield call(fetchMasterSettingsForAdmin);
    yield put(actions.getMasterSettingsForAdminSuccess(addSpacingForNotifications(data.entity, /<br>/g, '\n')));
  } catch (error) {
    yield put(actions.getMasterSettingsForAdminFailure(error.response.data));
  }
}

function* fetchGetDemoTasksCount() {
  try {
    const { data } = yield call(fetchDemoTasksCount);
    yield put(actions.getDemoTasksCountSuccess(data.entity));
  } catch (error) {
    yield put(actions.getDemoTasksCountFailure(error.response.data));
  }
}

function* fetchDeleteDemoTasks() {
  try {
    yield call(removeDemoTasks);
    yield put(actions.deleteDemoTasksSuccess());
    yield fetchGetDemoTasksCount();
  } catch (error) {
    yield put(actions.deleteDemoTasksFailure(error.response.data));
  }
}

function* fetchUpgradeInfo({ payload }) {
  const { values, openSuccessDialog } = payload;
  try {
    const { data } = yield call(getUpgradeInfo, values);
    yield put(actions.upgradeInfoSuccess(data.entity));
    openSuccessDialog();
  } catch (error) {
    yield put(
      actions.upgradeInfoFailure({
        error,
        message: formatServerError(error, 'An error occurred while creating a demo account. Please try again.'),
      }),
    );
  }
}

function* fetchSignUpNotification() {
  try {
    const { data } = yield call(getSignUpNotification);
    yield put(actions.getSignUpNotificationSuccess(data.entity));
  } catch (error) {
    yield put(actions.getSignUpNotificationFailure(error.response.data));
  }
}

function* fetchSetSignUpNotification({ payload }) {
  try {
    yield call(putSignUpNotification, payload);
    yield put(actions.getSignUpNotificationSuccess(payload));
  } catch (error) {
    yield put(actions.getSignUpNotificationFailure(error.response.data));
  }
}

function* fetchSystemLimits() {
  try {
    const { data } = yield call(getMasterSystemLimits);
    yield put(actions.getSystemLimitsSuccess(data.entity));
  } catch (error) {
    yield put(actions.getSystemLimitsFailure(error.response.data));
  }
}

function* fetchSetSystemLimits({ payload }) {
  try {
    if (!payload.EmailForUpgrade) {
      payload.EmailForUpgrade = '';
    }
    yield call(putSystemLimits, payload);
    yield put(actions.setSystemLimitsSuccess(payload));
    yield* fetchSystemLimits();
  } catch (error) {
    yield put(actions.setSystemLimitsFailure(error.response.data));
  }
}

const settingsSagas = [
  takeEvery(actions.uploadLogoRequest, fetchUploadPhoto),
  takeEvery(actions.getLogoRequest, fetchGetPhoto),
  takeEvery(actions.deleteLogoRequest, fetchDeletePhoto),
  takeEvery(actions.uploadLogoEmailRequest, fetchUploadPhotoEmail),
  takeEvery(actions.getLogoEmailRequest, fetchGetPhotoEmail),
  takeEvery(actions.deleteLogoEmailRequest, fetchDeletePhotoEmail),
  takeEvery(actions.setSettingsRequest, fetchSetSettings),
  takeEvery(actions.setSettingsLinksRequest, fetchSetSettingsLinks),
  takeEvery(actions.getSettingsLinksListRequest, fetchSettingsLinksList),
  takeEvery(actions.getSettingsRequest, fetchGetSettings),
  takeEvery(actions.getMasterSettingsForAdminRequest, fetchGetMasterSettingsForAdmin),
  takeEvery(actions.getMasterSettingsLinksForAdminRequest, getMasterSettingsLinksForAdmin),
  takeEvery(actions.getDemoTasksCountRequest, fetchGetDemoTasksCount),
  takeEvery(actions.deleteDemoTasksRequest, fetchDeleteDemoTasks),
  takeEvery(actions.upgradeInfoRequest, fetchUpgradeInfo),
  takeEvery(actions.getSignUpNotificationRequest, fetchSignUpNotification),
  takeEvery(actions.setSignUpNotificationRequest, fetchSetSignUpNotification),
  takeEvery(actions.getSystemLimitsRequest, fetchSystemLimits),
  takeEvery(actions.setSystemLimitsRequest, fetchSetSystemLimits),
];

export default settingsSagas;
