import React from 'react';
import PropTypes from 'prop-types';

import withDrawer from 'components/Dialogs/Drawer';
import DndList from 'components/DndList';

import * as S from './styled';

const DragFullListForm = ({ units, onSubmit }) => (
  <DndList key="CurrentNameField" data={units} name="Name" listId="FullUnitItems" onSave={onSubmit} />
);

DragFullListForm.propTypes = {
  units: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const DragFullListWithInput = withDrawer(DragFullListForm);

const DragFullListDialog = ({ units, ...rest }) => (
  <DragFullListWithInput {...rest} title={<S.Title>Sequence Setup (drag items to order)</S.Title>} units={units} />
);

DragFullListDialog.defaultProps = {};

DragFullListDialog.propTypes = {
  units: PropTypes.array.isRequired,
};

export default DragFullListDialog;
