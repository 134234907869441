import { Field } from 'react-final-form';
import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MargingField = styled(Field)`
  margin: 25px 25px 25px 25px;
  width: 60% !important;
`;

export const TenantMaxAccountValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    margin-bottom: 15px;
    margin-top: 15px;
  }
`;

export const BoldLabel = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Label = styled.label`
  margin-left: 50px;
`;
