import { call, put, select, takeEvery } from 'redux-saga/effects';

import { deleteDocumentImage, getDocument, putDocument, removeDocument, uploadDocumentImage } from 'http/document';

import { actions } from './index';
import { getDocumentId } from './selectors';

function* fetchDocument({ payload }) {
  try {
    const { data } = yield call(getDocument, payload);
    yield put(actions.documentFetchSuccess(data.entity));
  } catch (error) {
    yield put(actions.documentFetchFailure(error));
  }
}

function* deleteDocument({ payload }) {
  try {
    yield call(removeDocument, payload);
    yield put(actions.deleteDocumentSuccess());
  } catch (error) {
    yield put(actions.deleteDocumentFailure({ error, message: 'An error occurred while deleting the Document.' }));
  }
}

function* deleteDocumentPhoto({ payload: { imageId, type } }) {
  try {
    yield call(deleteDocumentImage, imageId);
    yield put(actions.deleteDocumentPictureSuccess({ type }));
    const currentDocumentId = yield select(getDocumentId);
    if (currentDocumentId) {
      yield* fetchDocument({ payload: currentDocumentId });
    }
  } catch (error) {
    yield put(
      actions.deleteDocumentPictureFailure({ error, message: 'An error occurred while deleting the Unit Picture.' }),
    );
  }
}

function* editDocument({ payload }) {
  try {
    yield call(putDocument, payload);
    yield put(actions.editDocumentSuccess());
    yield* fetchDocument({ payload: payload.Id });
  } catch (error) {
    yield put(actions.editDocumentFailure({ error, message: 'An error occurred while saving the Document.' }));
  }
}

function* uploadPhoto({ payload }) {
  try {
    const file = payload.files[0];
    const type = payload.imageType;
    const params = new FormData();
    params.append('file', file);
    const { data } = yield call(uploadDocumentImage, params);
    const result = { id: data.entity.Id, name: data.entity.Name, type };
    yield put(actions.uploadPhotoSuccess(result));
  } catch (error) {
    yield put(actions.uploadPhotoFailure(error));
  }
}

const documentSagas = [
  takeEvery(actions.documentFetchRequest, fetchDocument),
  takeEvery(actions.editDocumentRequest, editDocument),
  takeEvery(actions.deleteDocumentRequest, deleteDocument),
  takeEvery(actions.uploadPhotoRequest, uploadPhoto),
  takeEvery(actions.deleteDocumentPictureRequest, deleteDocumentPhoto),
];

export default documentSagas;
