export const validator = values => {
  const errors = {};

  if (!values.ShortcutId) {
    errors.ShortcutId = 'Required';
  }

  return errors;
};

export default validator;
