import React from 'react';
import PropTypes from 'prop-types';

import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import theme from 'theme';

import * as S from './styled';

const MissingDialogContent = () => (
  <>
    <S.DialogTitle>Missing Data</S.DialogTitle>
    <S.DialogText>
      One Manage and <br />
      one Task role <br />
      have to be present or
    </S.DialogText>
    <S.DialogWarning>Checklists cannot be created.</S.DialogWarning>
  </>
);

const MissingResultsDialog = ({ open, label, handleClose, confirm }) => (
  <ConfirmationDialog
    open={open}
    title="Alert"
    text={<MissingDialogContent />}
    label={label}
    labelCancel="Previous"
    width="40%"
    isNext
    titleColor={theme.redButton}
    handleClose={handleClose}
    onCancelClick={handleClose}
    onClick={confirm}
  />
);

MissingResultsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default MissingResultsDialog;
