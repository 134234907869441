const initialState = {
  createdEditedTierId: '',
  error: '',
  name: '',
  tenantMaxSitesEach: 0,
  tenantMaxItemsEach: 0,
  tenantMaxSensorsEach: 0,
  tasksCharge: 0,
  iotCharge: 0,
  meeteringGroupId: '',
  loading: false,
  list: [],
};

export const addTierRequest = state => ({
  ...state,
  createdEditedTierId: '',
  error: '',
  loading: true,
});
export const addTierSuccess = (state, { payload }) => ({
  ...state,
  createdEditedTierId: payload.Id,
});
export const addTierFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const editTierRequest = state => ({
  ...state,
  createdEditedTierId: '',
  error: '',
  loading: true,
});
export const editTierSuccess = (state, { payload }) => ({
  ...state,
  createdEditedTierId: payload.Id,
});
export const editTierFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export const tiersListRequest = state => ({
  ...state,
  error: '',
  loading: true,
});
export const tiersListSuccess = (state, { payload }) => ({
  ...state,
  list: payload,
  loading: false,
});
export const tiersListFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: false,
});

export default initialState;
