import React from 'react';
import { BoldText } from '../styled';

export const constraints = {
  0: 'is',
  1: 'is not',
  2: 'begins with',
  4: 'ends with',
  8: 'contains',
  16: 'does not contain',
};

export const constraintsQR = {
  0: 'is',
};

export const constraintsSelect = {
  0: 'is',
  1: 'is not',
};

export const constraintsNumber = {
  0: 'is',
  1: 'is not',
  128: 'is greater than',
  256: 'is less than',
};

export const UserType = [
  { Name: 'Task Specialist', Value: 'TaskSpecialist' },
  { Name: 'Manager', Value: 'Manager' },
];

export const TaskType = [
  { Name: 'Verification', Value: 'Verification' },
  { Name: 'Feedback', Value: 'Feedback' },
  { Name: 'Check Flow Rate', Value: 'CheckFlowRate' },
  { Name: 'Check Quantity', Value: 'CheckQuantity' },
  { Name: 'Check Temperature', Value: 'CheckTemperature' },
  { Name: 'Photo Confirmation', Value: 'PhotoConfirmation' },
  { Name: 'Scan Code', Value: 'ScanCode' },
  { Name: 'Signature Collection', Value: 'Signature' },
];

export const QR = [
  { Name: 'Not Present', Value: 0 },
  { Name: 'Present', Value: 1 },
];

export const ignoreRules = ['UnitIgnore', 'ItemIgnore', 'TaskIgnore', 'TaskManagedByIgnore', 'TaskCompletedByIgnore'];

export const optionArray = [
  'TaskManagedByJobFunction',
  'TaskCompletedByJobFunction',
  'TaskManagedByLastName',
  'UnitQR',
  'TaskCompletedByUserType',
  'TaskType',
];

export const numberArray = ['TaskRuleMinimum', 'TaskRuleMaximum'];

export const ignoreRulesBySection = {
  unit: {
    name: 'UnitIgnore',
    label: (
      <>
        Ignore rules: Include tasks for all <BoldText>Units</BoldText> (List Templates)
      </>
    ),
  },
  item: {
    name: 'ItemIgnore',
    label: (
      <>
        Ignore rules: Include tasks for all <BoldText>Items</BoldText>
      </>
    ),
  },
  taskType: {
    name: 'TaskIgnore',
    label: (
      <>
        Ignore rules: Include tasks for all <BoldText>Tasks Types</BoldText>
      </>
    ),
  },
  taskManagedBy: {
    name: 'TaskManagedByIgnore',
    label: (
      <>
        Ignore rules: Include tasks <BoldText>managed by anyone</BoldText>
      </>
    ),
  },
  taskCompletedBy: {
    name: 'TaskCompletedByIgnore',
    label: (
      <>
        Ignore rules: Include tasks <BoldText>completed by anyone</BoldText>
      </>
    ),
  },
};

export const matchOperator = {
  0: 'All',
  1: 'Any of the following rules',
};

export const optionNames = {
  unit: [
    { name: 'Unit Name', value: 'UnitName' },
    { name: 'Unit Model', value: 'UnitModelNumber' },
    { name: 'Unit Description', value: 'UnitDescription' },
    { name: 'Unit Built/Year', value: 'UnitYearBuilt' },
    { name: 'Unit Location', value: 'UnitLocationDescription' },
    { name: 'Unit Vendor Name', value: 'UnitVendorName' },
    { name: 'Unit Vendor Notes', value: 'UnitVendorNotes' },
    { name: 'QR', value: 'UnitQR' },
  ],
  item: [
    { name: 'Item Name', value: 'ItemName' },
    { name: 'Item Description', value: 'ItemDescription' },
    { name: 'Item Instructions', value: 'ItemInstructions' },
    { name: 'Item Notes', value: 'ItemNotes' },
  ],
  taskType: [
    { name: 'Task Type', value: 'TaskType' },
    { name: 'Task Instruction or Question', value: 'TaskRuleQuestion' },
    { name: 'Task Notes', value: 'TaskNotes' },
    { name: 'Task Rule Specification', value: 'TaskRuleSpecification' },
    { name: 'Task Rule Minimum', value: 'TaskRuleMinimum' },
    { name: 'Task Rule Maximum', value: 'TaskRuleMaximum' },
  ],
  taskManagedBy: [
    { name: 'First Name', value: 'TaskManagedByFirstName' },
    { name: 'Last Name', value: 'TaskManagedByLastName' },
    { name: 'Job Function', value: 'TaskManagedByJobFunction' },
  ],
  taskCompletedBy: [
    { name: 'First Name', value: 'TaskCompletedByFirstName' },
    { name: 'Last Name', value: 'TaskCompletedByLastName' },
    { name: 'User Type', value: 'TaskCompletedByUserType' },
    { name: 'Job Function', value: 'TaskCompletedByJobFunction' },
  ],
};

export const redHeader = ['taskManagedBy', 'taskCompletedBy'];
