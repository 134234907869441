import styled from 'styled-components';
import { IconButton } from 'material-ui';
import { PauseCircleOutlineIcon, PlayIconCircleOutline } from 'components/Layout/Icons';
import { fixedTableHeadStyles } from 'components/TableList/styled';

export const TableContainer = styled.div`
  ${fixedTableHeadStyles}

  & thead {
    color: ${({ theme }) => theme.primaryGrey};
  }

  thead > tr > th {
    font-weight: 500;
  }

  th,
  td {
    padding: 0 20px !important;
  }

  & td:nth-child(n + 2) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  & td:nth-child(-n + 1) {
    background-color: ${({ theme }) => theme.archiveNameColor};
  }

  th:nth-child(n) {
    text-align: center;
  }

  td:last-child {
    text-align: center;
  }
`;

export const TableHeaderCell = styled.th`
  background-color: ${({ theme }) => theme.lightGrey};
  text-align: center;
`;

export const ButtonIcon = styled(IconButton).attrs({
  style: {
    width: 72,
    height: 72,
    padding: 16,
  },
  tooltipStyles: {
    marginTop: 4,
  },
  iconStyle: { width: '40px', height: '40px' },
})``;

export const PauseIcon = styled(PauseCircleOutlineIcon)`
  color: ${({ theme }) => theme.darkGrey} !important;
`;

export const PlayIcon = styled(PlayIconCircleOutline)`
  color: ${({ theme }) => theme.mainRed} !important;
`;
