import React from 'react';
import PropTypes, { bool } from 'prop-types';
import moment from 'moment';

import { TeamType } from 'configs/propTypes';

import * as S from './styled';
import { IconMenu } from 'material-ui/IconMenu';
import { IconButton } from 'material-ui';
import { LoopIcon, PlayIconFilled } from 'components/Layout/Icons';
import theme from 'theme';

const Item = ({
  onClickStatusButton,
  isConfirmationOverrideOpened,
  item: { Name, Type, OwnerName, LastExecutionDate, Id, IsNextPeriodAvailable },
  onClick,
}) => (
  <S.Gateway onClick={() => onClick(Id)}>
    <S.InfoBlock>
      <S.Text>Name: {Name} </S.Text>
    </S.InfoBlock>
    <S.InfoBlock>
      <S.Text>{OwnerName} </S.Text>
    </S.InfoBlock>
    <S.InfoBlockDate>
      <S.Text>Last Run: {moment(LastExecutionDate).format('MMM-DD-YYYY')} </S.Text>
    </S.InfoBlockDate>
    <IconMenu
      onClick={event => {
        event.stopPropagation();
      }}
      iconButtonElement={<IconButton>{Type ? <LoopIcon color={theme.primaryRed} /> : <PlayIconFilled />}</IconButton>}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      menuStyle={{ maxWidth: 50, padding: 0, marginTop: 0 }}
      listStyle={{ paddingBottom: 0, paddingTop: 0 }}
      useLayerForClickAway={!isConfirmationOverrideOpened}
    >
      <S.ShortcutButton />
      {Type ? (
        <S.StopAutorunButton
          onClick={() => {
            onClickStatusButton({ shortcutId: Id, isStart: !Type });
          }}
        />
      ) : (
        <>
          <S.StartAutorunButton
            onClick={() => {
              onClickStatusButton({ shortcutId: Id, isStart: !Type });
            }}
          />
          <S.NextPeriodButton
            onClick={() => {
              onClickStatusButton({ shortcutId: Id, isNextPeriod: true });
            }}
            disabled={!IsNextPeriodAvailable}
          />
        </>
      )}
      <S.Tooltip id="version" place="top" effect="solid">
        <div>No ability to create tasks</div>
      </S.Tooltip>
    </IconMenu>
  </S.Gateway>
);

Item.propTypes = {
  item: TeamType.isRequired,
  isConfirmationOverrideOpened: bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickStatusButton: PropTypes.func.isRequired,
};

export default Item;
