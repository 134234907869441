const initialState = {
  error: '',
  isLoading: false,
  list: [],
  groupsList: [],
  groupPartsList: [],
  oemList: [],
  suppliersList: [],
  listPartsByItem: [],
  listPartGroupsByItem: [],
  currentPart: {},
  lastExecutionDate: '',
};

// Parts actions

export const getPartsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getPartsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  list: payload,
});

export const getPartsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getPartByIdRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getPartByIdSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  currentPart: payload,
});

export const getPartByIdFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getPartsByGroupIdRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getPartsByGroupIdSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  groupPartsList: payload,
});

export const getPartsByGroupIdFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addPartRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addPartSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const addPartFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editPartRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editPartSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  currentPart: payload,
});

export const editPartFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

// OEMS actions

export const getOemListRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getOemListSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  oemList: payload,
});

export const getOemListFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addOemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addOemSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const addOemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editOemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editOemSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const editOemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteOemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteOemSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deleteOemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

// Suppliers actions

export const getSuppliersListRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSuppliersListSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  suppliersList: payload,
});

export const getSuppliersListFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addSupplierRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addSupplierSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const addSupplierFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const editSupplierRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const editSupplierSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const editSupplierFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteSupplierRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteSupplierSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deleteSupplierFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

// Part Groups actions

export const getPartGroupsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getPartGroupsSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  groupsList: payload,
});

export const getPartGroupsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addPartGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addPartGroupSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const addPartGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const addPartGroupLinkRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addPartGroupLinkSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const addPartGroupLinkFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePartsGroupLinkRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePartsGroupLinkSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deletePartsGroupLinkFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

// link part item actions

export const getListPartsByItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getListPartsByItemSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  listPartsByItem: payload,
});

export const getListPartsByItemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createPartItemLinkRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createPartItemLinkSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const createPartItemLinkFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const removePartFromItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const removePartFromItemSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const removePartFromItemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getListPartGroupsByItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getListPartGroupsByItemSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  isLoading: false,
  listPartGroupsByItem: payload,
});

export const getListPartGroupsByItemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createPartGroupItemLinkRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createPartGroupItemLinkSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const createPartGroupItemLinkFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const removePartGroupFromItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const removePartGroupFromItemSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});
export const removePartGroupFromItemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePartRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePartSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deletePartFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePartsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePartsSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deletePartsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePartGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePartGroupSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deletePartGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePartGroupsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePartGroupsSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deletePartGroupsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deletePartFromGroupRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deletePartFromGroupSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const deletePartFromGroupFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createPartLinksRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createPartLinksSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const createPartLinksFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

// Distribution creation get last execution date for

export const getLastExecutionDateRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getLastExecutionDateSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  lastExecutionDate: payload,
  isLoading: false,
});

export const getLastExecutionDateFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createGroupLinksRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createGroupLinksSuccess = state => ({
  ...state,
  error: '',
  isLoading: false,
});

export const createGroupLinksFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getLastExecutionGroupDateRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getLastExecutionGroupDateSuccess = (state, { payload }) => ({
  ...state,
  error: '',
  lastExecutionDate: payload,
  isLoading: false,
});

export const getLastExecutionGroupDateFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
