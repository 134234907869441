import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as meteringActions } from 'redux/meteringGroups';
import { actions as tenantsActions } from 'redux/tenants';
import { addIndustryName } from 'redux/meteringGroups/selectors';
import { openConfirmationDialog } from 'redux/confirmationHandler';

import { MeteringGroupType } from 'configs/propTypes';

import { ButtonIcon } from 'components/Layout/Buttons';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { AddIcon, BackIcon } from 'components/Layout/Icons';

import { IconForward } from '../styled';

import ChooseIndustryDialog from './ChooseIndustryDialog';
import AddEditGroupDialog from './AddEditGroupDialog';
import Item from './Item';

class MeteringGroups extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      addGroupRequest: PropTypes.func.isRequired,
      addIndustryToGroupRequest: PropTypes.func.isRequired,
      deleteGroupRequest: PropTypes.func.isRequired,
      editGroupRequest: PropTypes.func.isRequired,
      getGroupsRequest: PropTypes.func.isRequired,
      openConfirmationDialog: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
      requestTennantIndustryList: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    meteringGroups: PropTypes.arrayOf(MeteringGroupType).isRequired,
  };

  state = {
    addEditGroupDialogOpened: false,
    chooseIndustryDialogOpened: false,
    groupToEdit: {},
    searchData: '',
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.requestTennantIndustryList();
    actions.getGroupsRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  onBackButtonClick = () => this.props.history.push('/globalsettings');

  closeAddEditGroupDialog = () => this.setState({ addEditGroupDialogOpened: false });

  openAddEditGroupDialog = group => {
    if (group.Id) {
      this.setState({ addEditGroupDialogOpened: true, groupToEdit: group });
    } else {
      this.setState({ addEditGroupDialogOpened: true, groupToEdit: {} });
    }
  };

  filterMeteringGroupsList = ({ Name, Notes, TiersCount, IndustryName }) =>
    `${Name} ${Notes} ${TiersCount} ${IndustryName}`
      .toString()
      .toLowerCase()
      .includes(this.state.searchData.toLowerCase());

  searchInList = e => this.setState({ searchData: e.target.value });

  renderRightControls = Id => (
    <div>
      <Link to={`/globalsettings/metering/${Id}/tiers`}>
        <ButtonIcon tooltip="Metering Group">
          <IconForward />
        </ButtonIcon>
      </Link>
    </div>
  );

  openChooseIndustryDialog = Id => {
    if (Id) {
      this.props.history.push(`metering/${Id}/tiers`);
    } else {
      this.setState({ chooseIndustryDialogOpened: true });
    }
  };

  openIdustriesDialog = values => {
    const { actions } = this.props;
    const { Id, Name, Notes } = values;

    if (Id) {
      actions.editGroupRequest({ Id, Name, Notes, cb: this.openChooseIndustryDialog });
    } else {
      actions.addGroupRequest({ Name, Notes, cb: this.openChooseIndustryDialog });
    }

    this.setState({
      groupToEdit: { ...this.state.groupToEdit, ...values },
      addEditGroupDialogOpened: false,
    });
  };

  closeChooseIndustryDialog = () => this.setState({ chooseIndustryDialogOpened: false, groupToEdit: {} });

  submitGroup = values => {
    const { actions, meteringGroups } = this.props;
    const { Id, IndustryID: industryId, Name: groupName } = values;
    let meeteringGroupId = Id;

    if (!meeteringGroupId) {
      const { Id: groupId } = meteringGroups.find(({ Name, IndustryID: industry }) => Name === groupName && !industry);
      meeteringGroupId = groupId;
    }

    actions.addIndustryToGroupRequest({ meeteringGroupId, industryId });
    this.closeChooseIndustryDialog();
  };

  deleteGroup = () => {
    this.props.actions.deleteGroupRequest({ meteringGroupId: this.state.groupToEdit.Id });
    this.closeAddEditGroupDialog();
  };

  openConfirmationDeleteGroup = () => {
    this.props.actions.openConfirmationDialog(
      'Are you sure you want to permanently delete this Group? This cannot be undone!',
      () => this.deleteGroup(),
      'Delete?',
    );
  };

  render() {
    const { searchData, addEditGroupDialogOpened, groupToEdit, chooseIndustryDialogOpened } = this.state;
    const { meteringGroups } = this.props;

    return (
      <>
        <Subheader
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openAddEditGroupDialog,
              hint: 'Add',
            },
          ]}
          title="Metering Groups"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackButtonClick,
              hint: 'Back',
            },
          ]}
        />
        <SimpleList
          data={meteringGroups.filter(this.filterMeteringGroupsList)}
          emptyListMessage="There are no Metering Groups avaliable"
          onItemClick={this.openAddEditGroupDialog}
          renderItemContent={Item}
          renderRightControls={item => this.renderRightControls(item.Id)}
        />
        <AddEditGroupDialog
          title={groupToEdit.Id ? 'Edit Group' : 'New Group'}
          submitLabel={groupToEdit.Id ? 'Save/Next' : 'Save/Details'}
          open={addEditGroupDialogOpened}
          handleClose={this.closeAddEditGroupDialog}
          onSubmit={this.openIdustriesDialog}
          group={groupToEdit}
          withSubmit={false}
          isNewDeleteButton
          isNewSubmitButton
          hasPadding
          gapValue="20px"
          handleDelete={groupToEdit.Id ? this.openConfirmationDeleteGroup : this.closeAddEditGroupDialog}
          deleteLabel={groupToEdit.Id ? 'Delete' : 'Cancel'}
        />
        <ChooseIndustryDialog
          title="Industry Selection"
          open={chooseIndustryDialogOpened}
          handleClose={this.closeChooseIndustryDialog}
          onSubmit={this.submitGroup}
          group={groupToEdit}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  error: state.meteringGroups.error,
  meteringGroups: addIndustryName(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openConfirmationDialog,
      openErrorDialog,
      requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
      ...meteringActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeteringGroups);
