/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';

import withDrawer from 'components/Dialogs/Drawer';
import { LinkIcon, GroupAddIcon } from 'components/Layout/Icons';

import * as S from './styled';

const UnitsList = ({ units, manager, openAddEditTeamDialog, isLoading }) => (
  <S.List>
    <S.ManagerName>{manager?.Name}</S.ManagerName>
    <S.ViewDescription>
      Click on team link to open selection (<S.RedText>red</S.RedText>=lead)
    </S.ViewDescription>
    {isLoading ? (
      <S.ProgressContainer>
        <CircularProgress />
      </S.ProgressContainer>
    ) : (
      units?.map(unit => (
        <S.Unit key={unit.SiteName}>
          <S.UnitTitle>{unit.SiteName}</S.UnitTitle>
          <S.Items>
            {unit.Teams?.map(item => (
              <S.Item key={item.Id} onClick={() => openAddEditTeamDialog(item, unit.SiteId)}>
                <LinkIcon colored={(manager?.Name == item?.Manager?.Name).toString()} />
                <span>
                  {item?.Name} <b>Assistant: </b>
                  {item?.Assistant?.Name}
                </span>
              </S.Item>
            ))}
            <S.Item>
              <GroupAddIcon onClick={() => openAddEditTeamDialog()} />
            </S.Item>
          </S.Items>
        </S.Unit>
      ))
    )}
  </S.List>
);

UnitsList.defaultProps = {
  units: [],
  isView: false,
  isLoading: false,
};

UnitsList.propTypes = {
  units: PropTypes.array,
  manager: PropTypes.object.isRequired,
  openAddEditTeamDialog: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

const LinkItemsInput = withDrawer(UnitsList);

const LinkTeamsDialog = props => <LinkItemsInput title={<S.Title>Linked Teams</S.Title>} {...props} />;

export default LinkTeamsDialog;
