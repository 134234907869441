import styled from 'styled-components';

import { CenteredXYGreyContainer } from 'components/Layout/Containers';

export const HomeContainer = styled(CenteredXYGreyContainer)`
  // detecting macbook 13'
  // @media screen and (min-resolution: 192dpi) and (min-width: 800px) and
  // (min-device-width:1280px) and (max-device-width: 1440px) {
  //   transform: scale(0.8)
  // }
  display: flex;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
