import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

import { ButtonIcon } from 'components/Layout/Buttons';

export const ItemInfo = styled(Box).attrs({})`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 5px;
  box-sizing: border-box;
  font-size: 12px;
  font-style: italic;
  height: auto !important;
`;

export const Status = styled.div`
  padding-right: 15px;
`;

export const ListContainer = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  background-color: ${({ theme }) => theme.lightGrey};
  overflow-y: auto;
`;

export const InfoTooltip = styled(ButtonIcon).attrs({
  tooltipStyles: {
    wordBreak: 'keep-all',
    top: '10px',
    right: '40px',
    width: '100%',
  },
})`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')} !important;
`;
