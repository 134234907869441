import styled from 'styled-components';
import { Box } from 'grid-styled';

export const ItemInfo = styled(Box).attrs({})`
  display: flex;
  align-items: center;
  padding: 0 10px 0 5px;
  box-sizing: border-box;
  min-width: 65px;
  font-size: 12px;
  font-style: italic;
  height: auto !important;
  color: ${({ theme }) => theme.primaryRed};

  @media only screen and (max-width: 400px) {
    max-width: 48px !important;
  }
`;

export const ConditionalItemTitle = styled.span`
  color: ${({ isAssigned, theme }) => (isAssigned ? theme.primaryQueue : theme.primaryBlack)};
`;
