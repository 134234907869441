import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';
import * as CS from 'components/Dialogs/styled';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';

import { actions as peopleActions } from 'redux/people';

import NotificationInfoDialog from '../NotificationInfoDialog';

import {
  checkboxesName,
  modifyUserNotification,
  fillWholeNotificationGroup,
  mergePeopleNotifications,
  updateAllUsersByNotification,
  notificationInfoTitle,
} from './helpers';
import { getPeople } from './selectors';
import * as S from './styled';

class SetNotificationStatusDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    peopleList: PropTypes.arrayOf(
      PropTypes.shape({
        UserId: PropTypes.string,
        Name: PropTypes.string,
        SendDailyMail: PropTypes.bool,
        SendIotMail: PropTypes.bool,
        SendIotTxt: PropTypes.bool,
        SendIotPush: PropTypes.bool,
      }),
    ).isRequired,
    selectedSite: PropTypes.string.isRequired,
    userRole: PropTypes.oneOf(['SystemAdministrator', 'Manager', 'TaskSpecialist', 'DashboardUser']).isRequired,
    handleClose: PropTypes.func.isRequired,
    multipleEdit: PropTypes.func.isRequired,
  };

  state = {
    people: [],
    peopleForSearch: [],
    notificationStatusUsers: [],
    searchData: '',
    notificationInfoDialogOpen: false,
    notificationLabel: '',
  };

  componentDidUpdate(prevProps, prevState) {
    const { peopleList, userRole } = this.props;
    const { searchData } = this.state;

    if (
      peopleList &&
      peopleList.length > 0 &&
      (prevState.people.length === 0 || userRole !== prevProps.userRole) &&
      searchData.length === 0
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ people: peopleList, peopleForSearch: peopleList });
    }
  }

  closeModal = () => {
    this.setState({
      people: [],
      peopleForSearch: [],
      notificationStatusUsers: [],
      searchData: '',
      notificationLabel: '',
    });
    this.props.handleClose();
  };

  setSearchedPeople = (people, searchData) => this.setState({ people, searchData });

  onCheckboxCheck = (checked, userId, checkboxName) => {
    const { notificationStatusUsers: statuses, people, peopleForSearch } = this.state;
    const { peopleList } = this.props;
    const { Notification } = peopleList.find(({ UserId }) => UserId === userId);
    const notificationStatusUsers = modifyUserNotification(statuses, userId, checkboxName, checked, Notification);

    const updatedPeople = mergePeopleNotifications(people, checked, userId, checkboxName);
    const updatedPeopleForSearch = mergePeopleNotifications(peopleForSearch, checked, userId, checkboxName);

    this.setState({ notificationStatusUsers, people: updatedPeople, peopleForSearch: updatedPeopleForSearch });
  };

  onGroupCheckboxesCheck = (checked, checkboxName) => {
    const { notificationStatusUsers: statuses, people, peopleForSearch } = this.state;
    const { peopleList } = this.props;

    const idsToSetUp = peopleList.map(user => ({ UserId: user.UserId, ...user.Notification, [checkboxName]: checked }));

    const notificationStatusUsers = fillWholeNotificationGroup(statuses, idsToSetUp, checked, checkboxName);
    const updatedPeople = updateAllUsersByNotification(people, checked, checkboxName);

    const updatedPeopleForSearch = updateAllUsersByNotification(peopleForSearch, checked, checkboxName);
    this.setState({ notificationStatusUsers, people: updatedPeople, peopleForSearch: updatedPeopleForSearch });
  };

  closeNotificationInfoModal = () => this.setState({ notificationInfoDialogOpen: false });
  openNotificationInfoModal = notificationLabel =>
    this.setState({ notificationInfoDialogOpen: true, notificationLabel });

  saveNotifications = () => {
    const { notificationStatusUsers } = this.state;
    const { selectedSite, multipleEdit } = this.props;

    if (notificationStatusUsers.length === 0) return;

    multipleEdit({ selectedSite, notificationStatusUsers });
    this.closeModal();
  };

  render() {
    const { open, title, userRole } = this.props;
    const {
      people,
      peopleForSearch,
      searchData,
      notificationStatusUsers,
      notificationInfoDialogOpen,
      notificationLabel,
    } = this.state;

    const disableSaving = notificationStatusUsers.length === 0;
    const infoIsNotAvailable = userRole === 'DashboardUser' && notificationLabel === 'SendDailyMail';

    return (
      <>
        <BaseDialog open={open} onRequestClose={this.closeModal} title={title}>
          <CS.DialogContainer>
            <CS.SearchContainer>
              <SearchField
                list={peopleForSearch}
                filterBy="Name"
                searchData={searchData}
                placeholder="filter people"
                underlineShow={false}
                icon={<SearchIcon />}
                setSearchedItems={this.setSearchedPeople}
              />
            </CS.SearchContainer>
            <CS.ListContainer>
              <S.CheckboxesHeader>
                <S.CheckboxesContainer>
                  {checkboxesName.map((checkboxName, i) => (
                    <S.HeaderCheckboxContainer key={checkboxName.substring(4)}>
                      <S.NotificationInfo onClick={() => this.openNotificationInfoModal(checkboxName)} />
                      <S.GroupLabel>{notificationInfoTitle[checkboxName]}</S.GroupLabel>
                      <CS.ItemCheckbox
                        onCheck={(e, checked) => this.onGroupCheckboxesCheck(checked, checkboxName)}
                        disabled={i === 0 && userRole === 'DashboardUser'}
                      />
                    </S.HeaderCheckboxContainer>
                  ))}
                </S.CheckboxesContainer>
                <S.SelectDeselectLabel>Select/Deselect All</S.SelectDeselectLabel>
              </S.CheckboxesHeader>
              <CS.DividerLine />
              <S.List>
                {people.length > 0 &&
                  people.map(({ UserId, Name, Notification }) => (
                    <div key={UserId}>
                      <S.ListItem>
                        <S.CheckboxesContainer>
                          {checkboxesName.map(checkboxName => (
                            <CS.ItemCheckbox
                              key={checkboxName}
                              onCheck={(e, checked) => this.onCheckboxCheck(checked, UserId, checkboxName)}
                              checked={Notification[checkboxName]}
                              disabled={userRole === 'DashboardUser' && checkboxName === 'SendDailyMail'}
                            />
                          ))}
                        </S.CheckboxesContainer>
                        <CS.ItemName>{Name}</CS.ItemName>
                      </S.ListItem>
                      <CS.DividerLine />
                    </div>
                  ))}
              </S.List>
            </CS.ListContainer>
            <CS.SaveButtonContainer onClick={this.saveNotifications}>
              <CS.SaveButton disabled={disableSaving} />
            </CS.SaveButtonContainer>
          </CS.DialogContainer>
        </BaseDialog>
        {notificationLabel && (
          <NotificationInfoDialog
            open={notificationInfoDialogOpen}
            notificationLabel={notificationLabel}
            title={`${notificationInfoTitle[notificationLabel]} Notification`}
            handleClose={this.closeNotificationInfoModal}
            notAvailable={infoIsNotAvailable}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, people }, { userRole }) => ({
  selectedSite: auth.selectedSite,
  peopleList: getPeople(people.list, userRole),
});

const mapDispatchToProps = {
  multipleEdit: peopleActions.multipleEditPersonRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetNotificationStatusDialog);
