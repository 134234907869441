import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const buttons = [0, 1, 2];

const RadioGroup = ({ input, meta, style, teamsAreEmpty }) => (
  <RadioButtonGroup
    name="taskFrequency"
    defaultSelected={input.value.length === 0 ? 1 : input.value}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    {buttons.map(num => (
      <RadioButton
        key={num}
        value={num}
        iconStyle={{
          fill: input.value.length === 0 || input.value === num ? theme.primaryScheduler : theme.darkGrey,
        }}
        disabled={num === 1 && teamsAreEmpty}
        style={{ padding: '5px 0px' }}
      />
    ))}
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  teamsAreEmpty: PropTypes.bool.isRequired,
};

export default RadioGroup;
