import { all, call, put, takeEvery } from 'redux-saga/effects';

import { formatServerError } from 'helpers';
import {
  changeMasterSensorLevel,
  deleteMasterSensorLevel,
  fetchItemSensors,
  fetchLevelsForItemSensor,
  fetchSensors,
  fetchSensorsForSite,
  fetchSensorTypes,
  getMasterSensorLevelList,
  putSensor,
  removeSensorType,
  setLevelsForItemMasterSensor,
  setMasterSensorType,
} from 'http/sensors';

import { actions } from './index';

function* getSensors({ payload }) {
  try {
    const { data } = yield call(fetchSensors, payload);
    yield put(actions.getSensorsSuccess(data.root));
  } catch (error) {
    yield put(actions.getSensorsFailure(error));
  }
}

function* editSensor({ payload: { gatewayId, itemId, values } }) {
  try {
    yield call(putSensor, values, itemId);
    yield put(actions.editSensorSuccess());
    if (gatewayId) {
      yield put(actions.getSensorsRequest(gatewayId));
    } else if (itemId) {
      yield put(actions.getItemSensorsRequest(itemId));
      yield put(actions.getSensorsForSiteRequest());
    }
  } catch (error) {
    yield put(actions.editSensorFailure(formatServerError(error, 'An error occurred while editing a sensor.')));
  }
}

function* getSensorsForSite() {
  try {
    const { data } = yield call(fetchSensorsForSite);
    yield put(actions.getSensorsForSiteSuccess(data.root));
  } catch (error) {
    yield put(actions.getSensorsForSiteFailure(error));
  }
}

function* getItemSensors({ payload }) {
  try {
    const { data } = yield call(fetchItemSensors, payload);
    yield put(actions.getItemSensorsSuccess(data.root));
  } catch (error) {
    yield put(actions.getItemSensorsFailure(error));
  }
}

function* getSensorTypes() {
  try {
    const { data } = yield call(fetchSensorTypes);
    yield put(actions.getSensorTypesSuccess(data.root));
  } catch (error) {
    yield put(actions.getSensorTypesFailure(error));
  }
}

function* setSensorType({ payload }) {
  try {
    yield call(setMasterSensorType, payload);
    yield put(actions.setSensorTypeSuccess());
    yield put(actions.getSensorTypesRequest());
  } catch (error) {
    yield put(actions.setSensorTypeFailure(error));
  }
}

function* deleteSensorType({ payload }) {
  try {
    yield call(removeSensorType, payload);
    yield put(actions.deleteSensorTypeSuccess());
    yield put(actions.getSensorTypesRequest());
  } catch (error) {
    yield put(actions.deleteSensorTypeFailure(error.response.data.error));
  }
}

function* getSensorLevelsSettings({ payload: { sensorData, callback } }) {
  try {
    const { data } = yield call(getMasterSensorLevelList, sensorData);
    yield put(actions.getSensorLevelsSettingsSuccess(data.root));
    callback(sensorData);
  } catch (error) {
    yield put(actions.getSensorLevelsSettingsFailure(error));
  }
}

function* setSensorLevelsSettings({ payload }) {
  try {
    yield all(
      payload.map(level => {
        if (level.deleteId) {
          return call(deleteMasterSensorLevel, level);
        }
        return call(changeMasterSensorLevel, level);
      }),
    );
    yield put(actions.setSensorLevelsSettingsSuccess());
    yield put(actions.getSensorTypesRequest());
  } catch (error) {
    yield put(actions.setSensorLevelsSettingsFailure(error));
  }
}

function* getLevelsForItemSensor({ payload: { sensor, showLevelSettingsDialog } }) {
  try {
    const { data } = yield call(fetchLevelsForItemSensor, sensor);
    yield put(actions.getLevelsForItemSensorSuccess(data.entity));
    if (showLevelSettingsDialog) {
      showLevelSettingsDialog(data.entity);
    }
  } catch (error) {
    yield put(actions.getLevelsForItemSensorFailure(error));
  }
}

function* setLevelsForItemSensor({ payload: { itemId, newValues, sensorId } }) {
  try {
    yield call(setLevelsForItemMasterSensor, newValues, sensorId);
    yield put(actions.setLevelsForItemSensorSuccess());
    yield put(actions.getItemSensorsRequest(itemId));
  } catch (error) {
    yield put(actions.setLevelsForItemSensorFailure(error));
  }
}

const sensorsSagas = [
  takeEvery(actions.getSensorsRequest, getSensors),
  takeEvery(actions.editSensorRequest, editSensor),
  takeEvery(actions.getSensorsForSiteRequest, getSensorsForSite),
  takeEvery(actions.getItemSensorsRequest, getItemSensors),
  takeEvery(actions.getSensorTypesRequest, getSensorTypes),
  takeEvery(actions.setSensorTypeRequest, setSensorType),
  takeEvery(actions.deleteSensorTypeRequest, deleteSensorType),
  takeEvery(actions.getSensorLevelsSettingsRequest, getSensorLevelsSettings),
  takeEvery(actions.setSensorLevelsSettingsRequest, setSensorLevelsSettings),
  takeEvery(actions.getLevelsForItemSensorRequest, getLevelsForItemSensor),
  takeEvery(actions.setLevelsForItemSensorRequest, setLevelsForItemSensor),
];

export default sensorsSagas;
