import React from 'react';
import PropTypes from 'prop-types';

import { SensorLevelColors } from 'theme';
import { SensorItemContainer } from 'components/Layout/Containers';

import * as S from './styled';

const ItemsList = ({ list, openItemSensorsDialog }) => (
  <S.ItemsList>
    {list.map(item => (
      <SensorItemContainer
        key={item.Id}
        leftIcon={<S.IconWarning color={SensorLevelColors[item.StatusSensor]} />}
        primaryText={`ItemName: ${item.Name}`}
        onClick={() => openItemSensorsDialog(item)}
      />
    ))}
  </S.ItemsList>
);

ItemsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  openItemSensorsDialog: PropTypes.func.isRequired,
};

export default ItemsList;
