import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const Section = ({ title, children, warning }) => (
  <S.Section>
    <S.Title warning={warning}>{title}</S.Title>
    <S.Content>{children}</S.Content>
  </S.Section>
);

Section.defaultProps = {
  title: '',
  children: () => {},
  warning: 0,
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  warning: PropTypes.oneOf([0, 1]),
};

export default Section;
