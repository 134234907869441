import React from 'react';
import moment from 'moment';

import { ScheduleDto } from 'configs/propTypes';

import { Container, Info, RedInfo } from './styled';

const ScheduleData = ({ scheduleDto }) => {
  let period;

  if (scheduleDto.ScheduleType === 'RecurringDaily') {
    period = {
      repeat: 'Daily',
      start: moment(scheduleDto.ScheduleData.DailyStartDate).format('ddd-DMMM-YYYY'),
      end: moment(scheduleDto.ScheduleData.DailyRepeatUntil).format('ddd-DMMM-YYYY'),
      skip: scheduleDto.ScheduleData.DailyRepeatInterval,
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringWeekly') {
    period = {
      repeat: 'Weekly',
      start: moment(scheduleDto.ScheduleData.WeeklyStartDate).format('ddd-DMMM-YYYY'),
      end: moment(scheduleDto.ScheduleData.WeeklyRepeatUntil).format('ddd-DMMM-YYYY'),
      skip: scheduleDto.ScheduleData.WeeklyRepeatInterval,
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringMonthly') {
    period = {
      repeat: 'Monthly',
      start: moment(scheduleDto.ScheduleData.MonthlyStartDate).format('ddd-DMMM-YYYY'),
      end: moment(scheduleDto.ScheduleData.MonthlyRepeatUntil).format('ddd-DMMM-YYYY'),
      skip: scheduleDto.ScheduleData.MonthlyRepeatInterval,
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringYearly') {
    period = {
      repeat: 'Yearly',
      start: moment(scheduleDto.ScheduleData.YearlyStartDate).format('ddd-DMMM-YYYY'),
      end: moment(scheduleDto.ScheduleData.YearlyRepeatUntil).format('ddd-DMMM-YYYY'),
      skip: scheduleDto.ScheduleData.YearlyRepeatInterval,
    };
  }

  if (scheduleDto.ScheduleType === 'OneTime') {
    period = {
      repeat: 'One Time',
      start: moment(scheduleDto.ScheduleData.ScheduledDate).format('ddd-DMMM-YYYY'),
    };
  }

  if (scheduleDto.ScheduleType) {
    return (
      <Container>
        <RedInfo>
          Repeat:&nbsp;<Info>{period.repeat}</Info>
        </RedInfo>
        <RedInfo>
          Skip:&nbsp;<Info>{period.skip}</Info>
        </RedInfo>
        <RedInfo>
          Start:&nbsp;<Info>{period.start}</Info>
        </RedInfo>
        <RedInfo>
          End:&nbsp;<Info>{period.end}</Info>
        </RedInfo>
      </Container>
    );
  }
  return null;
};

ScheduleData.propTypes = {
  scheduleDto: ScheduleDto.isRequired,
};

export default ScheduleData;
