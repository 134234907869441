import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { ScheduleInfo, UnitType } from 'configs/propTypes';
import schedulerStatus from './config';
import { formatScheduleInfo } from './formatters';
import { getUnitItems } from 'redux/schedule/selectors';
import { getSchedulerType } from 'helpers/storage';
import { actions as scheduleActions } from 'redux/schedule';
import { actions as teamActions } from 'redux/teams';

import { Dialog, ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import Subheader from 'components/Subheader';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { SchedulerIndicator } from 'components/Layout/Indicators';
import { AddIcon, BackIcon, ChevronRightIcon } from 'components/Layout/Icons';

import AddChecklistModal from './AddChecklistModal';
import DuplicateScheduleModal from './DuplicateScheduleModal';
import ChecklistInfo from './ChecklistInfo/index';
import theme from 'theme';
import * as S from './styled';

const getInitialDates = scheduleDto => {
  let dates = {};
  if (scheduleDto.ScheduleType === 'RecurringDaily') {
    dates = {
      repeatCycle: 'Daily',
      startDate: moment(scheduleDto.ScheduleData.DailyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.DailyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.DailyStartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.DailyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringWeekly') {
    dates = {
      repeatCycle: 'Weekly',
      startDate: moment(scheduleDto.ScheduleData.WeeklyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.WeeklyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.WeeklyStartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.WeeklyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringMonthly') {
    dates = {
      repeatCycle: 'Monthly',
      startDate: moment(scheduleDto.ScheduleData.MonthlyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.MonthlyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.MonthlytartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.MonthlyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'RecurringYearly') {
    dates = {
      repeatCycle: 'Yearly',
      startDate: moment(scheduleDto.ScheduleData.YearlyStartDate).format('L').replaceAll('/', '-'),
      endDate: moment(scheduleDto.ScheduleData.YearlyRepeatUntil).format('L').replaceAll('/', '-'),
      startTime: moment(scheduleDto.ScheduleData.YearlyStartDate).format('h:mm a'),
      endTime: moment(scheduleDto.ScheduleData.YearlyRepeatUntil).format('h:mm a'),
    };
  }

  if (scheduleDto.ScheduleType === 'OneTime') {
    dates = {
      repeatCycle: null,
      startDate: moment(scheduleDto.ScheduleData.DailyStartDate).format('L').replaceAll('/', '-'),
      endDate: null,
      startTime: moment(scheduleDto.ScheduleData.DailyStartDate).format('h:mm a'),
      endTime: null,
    };
  }
  return dates;
};

const findSite = (sitesArray, selectedSite) => {
  const site = sitesArray.find(siteObject => siteObject.id === selectedSite);
  if (site.additionalId) {
    return `${site.additionalId.substring(0, 5)} ${site.name}`;
  }
  return `${site.name}`;
};

class SchedulesList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    getTeamsRequest: PropTypes.func.isRequired,
    requestSchedulesList: PropTypes.func.isRequired,
    requestCurrentUnit: PropTypes.func.isRequired,
    requestUserManagement: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(ScheduleInfo).isRequired,
    currentUnit: UnitType.isRequired,
    requestScheduleDetails: PropTypes.func.isRequired,
    requestChecklistActivation: PropTypes.func.isRequired,
    requestScheduleTemplates: PropTypes.func.isRequired,
    requestDeleteSchedule: PropTypes.func.isRequired,
    requestChecklistStatus: PropTypes.func.isRequired,
    scheduleInfo: PropTypes.object.isRequired,
    currentChecklistStatus: PropTypes.object.isRequired,
    detailsActivation: PropTypes.object.isRequired,
    taskTemplates: PropTypes.object.isRequired,
    selectedSite: PropTypes.string.isRequired,
    sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    userManagement: PropTypes.object.isRequired,
  };

  state = {
    duplicatedSchedule: {},
    isCopyModalVisible: false,
    isModalVisible: false,
    searchData: '',
    isConfirmationDialogOpened: false,
    isStatusInactive: false,
    isOpenChecklistInfo: false,
    checklistToEdit: {},
    isConfirmationOpened: false,
    schedule: '',
    editChecklist: false,
    isTasksModalOpened: false,
    isDeleteChecklistModalOpened: false,
    dublicateChecklist: false,
    notUniqueChecklistName: '',
  };

  componentDidMount() {
    this.props.requestSchedulesList({ unitId: this.props.match.params.unitId });
    this.props.requestCurrentUnit({ unitId: this.props.match.params.unitId });
    this.props.getTeamsRequest();
  }

  componentDidUpdate(prevProps) {
    const { scheduleInfo, detailsActivation, match } = this.props;
    if (
      prevProps.scheduleInfo !== scheduleInfo &&
      scheduleInfo.ChecklistAssignmentType === 0 &&
      scheduleInfo.AssigneeID
    ) {
      this.props.requestUserManagement({ id: scheduleInfo.AssigneeID });
    }
    if (prevProps.detailsActivation !== detailsActivation) {
      this.props.requestSchedulesList({ unitId: match.params.unitId });
      this.selectActivation();
    }
  }

  getManager = id => this.props.teams.find(({ Id }) => Id === id)?.Manager.Name;

  getTeamMember = type => {
    const { userManagement, scheduleInfo } = this.props;
    const AssignmentType = {
      0: (scheduleInfo.AssigneeID && `${userManagement.FirstName} ${userManagement.LastName}`) || 'Open Assignment',
      1: 'Open Assignment',
    };
    return AssignmentType[type];
  };

  onListClick = () => {
    this.props.history.push('/scheduler', { type: getSchedulerType() });
  };

  openChecklistInfo = () => {
    this.setState({ isOpenChecklistInfo: true });
  };

  closeChecklistInfo = () => {
    this.setState({ isOpenChecklistInfo: false });
  };

  openModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      dublicateChecklist: false,
      checklistToEdit: {},
      isModalVisible: false,
    });
  };

  filterList = item => item.Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  searchInList = event => {
    this.setState({
      searchData: event.target.value,
    });
  };

  toEditShortcut = id => {
    this.props.history.push({
      pathname: '/shortcuts',
      search: `?id=${id}`,
    });
  };

  getCountTasks = () => {
    const { taskTemplates } = this.props;
    let count = 0;
    Object.keys(taskTemplates).forEach(k => {
      count += taskTemplates[k].length;
    });
    return count;
  };

  handleCopyModalClose = () =>
    this.setState({
      isCopyModalVisible: false,
      dublicateChecklist: false,
      duplicatedSchedule: {},
    });

  checkChecklistName = name => this.props.list.find(el => el.Name === name)?.Name;

  submitDuplicateModal = (name, item) => {
    if (this.checkChecklistName(name)) {
      this.setState({
        notUniqueChecklistName: name,
      });
    } else {
      this.handleCopyModalClose();
      this.setState({
        notUniqueChecklistName: '',
        checklistToEdit: { ...item, Name: name },
        dublicateChecklist: true,
      });
      this.openModal();
    }
  };

  handleCopyClick = (event, item) => {
    event.stopPropagation();
    this.setState({
      isCopyModalVisible: true,
      duplicatedSchedule: item,
    });
  };

  onChecklistClick = item => {
    if (item.ActiveStatus === 'PendingActivation') {
      this.setState({ isConfirmationOpened: true, schedule: item, editChecklist: true });
    } else if (item.ActiveStatus === 'Inactive') {
      this.setState({
        isStatusInactive: true,
        checklistToEdit: item,
      });
    } else {
      this.setState({
        checklistToEdit: item,
      });
      this.openModal();
    }
  };

  handleDeleteApproval = () => {
    this.setState({ isModalVisible: false, checklistToEdit: {} });
    this.setState({ isConfirmationDialogOpened: false });
  };

  handleDeleteReject = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  schedulerStatus = ({ IsDynamic, ActiveStatus, IsPaused }) => {
    if (!IsDynamic && ActiveStatus === 'Active') {
      return schedulerStatus.Pause;
    }
    if (ActiveStatus === 'Active') {
      if (IsPaused) {
        return schedulerStatus.Pause;
      }
      return schedulerStatus.Active;
    }
    if (ActiveStatus === 'Inactive') {
      return schedulerStatus.Inactive;
    }
    return { title: '' };
  };

  closeActivationDialog = () => {
    this.setState({ isConfirmationOpened: false });
  };

  checkActivationDialog = async url => {
    const { match } = this.props;
    const { schedule } = this.state;
    await this.props.requestChecklistActivation({ unitId: match.params.unitId, id: schedule.Id, url });
    this.setState({ isConfirmationOpened: false });
  };

  selectActivation = () => {
    const { location, history, detailsActivation } = this.props;
    const { editChecklist } = this.state;
    if (editChecklist) {
      this.setState({ editChecklist: false });
      if (detailsActivation.ActiveStatus === 'Inactive') {
        this.setState({
          isStatusInactive: true,
        });
      } else {
        this.setState({
          checklistToEdit: detailsActivation,
        });
        this.openModal();
      }
    } else if (getSchedulerType() === 'Shortcut') {
      this.props.requestScheduleDetails({ scheduleId: detailsActivation.Id });
      this.props.requestScheduleTemplates({ scheduleId: detailsActivation.Id });
      this.openChecklistInfo();
    } else {
      history.push(`${location.pathname}/details/${detailsActivation.Id}`);
    }
  };

  toSchedulesDetails = item => {
    const { history, location } = this.props;
    if (item.ActiveStatus === 'PendingActivation') {
      this.setState({ isConfirmationOpened: true, schedule: item });
    } else if (getSchedulerType() === 'Shortcut') {
      this.props.requestScheduleDetails({ scheduleId: item.Id });
      this.props.requestScheduleTemplates({ scheduleId: item.Id });
      this.openChecklistInfo();
    } else {
      history.push(`${location.pathname}/details/${item.Id}`);
    }
  };
  openTaskModal = () => {
    const { checklistToEdit, isStatusInactive } = this.state;
    this.setState({ isTasksModalOpened: true });
    if (isStatusInactive) {
      this.setState({ isStatusInactive: false });
    }
    this.props.requestChecklistStatus({ checklistId: checklistToEdit.Id });
  };

  deleteChecklist = () => {
    const { checklistToEdit, isModalVisible } = this.state;
    this.setState({ isDeleteChecklistModalOpened: false });
    if (isModalVisible) {
      this.setState({ isModalVisible: false });
    }
    this.props.requestDeleteSchedule({ scheduleId: checklistToEdit.Id, unitId: checklistToEdit.UnitId });
  };

  getSiteWithId = (sitesArray, selectedSite) => {
    const site = sitesArray.find(siteObject => siteObject.id === selectedSite);
    return (
      <>
        {site.additionalId ? site.additionalId.substring(0, 5) : 'Site ID'} <S.BoldText>{site.name}</S.BoldText>
      </>
    );
  };

  goToAttention = () => {
    const { push } = this.props.history;
    push(`/queue/${moment().format('YYYY-MM-DD')}/all`);
  };

  render() {
    const {
      list,
      currentUnit,
      scheduleInfo,
      taskTemplates,
      sitesAvailableToUserFullInfo,
      selectedSite,
      currentChecklistStatus,
    } = this.props;
    const {
      searchData,
      isConfirmationDialogOpened,
      isOpenChecklistInfo,
      checklistToEdit,
      isStatusInactive,
      isTasksModalOpened,
      isDeleteChecklistModalOpened,
      dublicateChecklist,
      duplicatedSchedule,
      isCopyModalVisible,
      isModalVisible,
      notUniqueChecklistName,
    } = this.state;

    return (
      <S.Container>
        <Subheader
          title={`Template: ${currentUnit.Name}`}
          zIndex={5}
          top="0"
          rightContent={
            <>
              <S.RedText>Type:</S.RedText>
              &nbsp;{getSchedulerType()} Checklists
            </>
          }
          isSiteOnLeftSide
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onListClick,
              hint: 'Back',
            },
          ]}
        />
        <Subheader
          title={<S.Checklists>Checklists</S.Checklists>}
          zIndex={4}
          rightButtons={[
            {
              icon: getSchedulerType() === 'Manual' && <AddIcon />,
              handler: () => {
                this.setState({ checklistToEdit: {} });
                this.openModal();
              },
              hint: 'Add',
            },
          ]}
          top="0"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
        />
        {list && list.length ? (
          <S.List>
            {list.filter(this.filterList).map(item => (
              <S.ListItem
                key={item.Id}
                onClick={() =>
                  getSchedulerType() === 'Shortcut' ? this.toSchedulesDetails(item) : this.onChecklistClick(item)
                }
                primaryText={item.Name}
              >
                <S.StatusBlock>
                  {getSchedulerType() === 'Manual' && (
                    <ChevronRightIcon
                      color={theme.primaryGrey}
                      style={{ transform: 'scale(1.7)' }}
                      onClick={e => {
                        e.stopPropagation();
                        this.toSchedulesDetails(item);
                      }}
                    />
                  )}
                  <S.StatusText>{this.schedulerStatus(item)?.title}</S.StatusText>
                  {getSchedulerType() === 'Manual' && (
                    <S.IconCopy
                      onClick={event => {
                        this.handleCopyClick(event, item);
                      }}
                    />
                  )}
                  <S.InfoText>{formatScheduleInfo(item)}</S.InfoText>
                  <SchedulerIndicator color={this.schedulerStatus(item)?.color} />
                </S.StatusBlock>
              </S.ListItem>
            ))}
          </S.List>
        ) : (
          <S.EmptyList>
            {getSchedulerType() === 'Manual' ? (
              <>
                <S.EmptyListText>No Checklists have been created yet.</S.EmptyListText>
                <S.EmptyListText>You can create one by selecting + in the upper right corner</S.EmptyListText>
              </>
            ) : (
              <S.EmptyListText>There are no schedules</S.EmptyListText>
            )}
          </S.EmptyList>
        )}
        <AddChecklistModal
          open={isModalVisible}
          handleClose={() => {
            this.setState({ isConfirmationDialogOpened: true });
          }}
          handleExit={this.closeModal}
          currentUnit={currentUnit}
          openTaskModal={this.openTaskModal}
          history={this.props.history}
          checklistToEdit={checklistToEdit}
          dublicateChecklist={dublicateChecklist}
          checkChecklistName={this.checkChecklistName}
        />
        <DuplicateScheduleModal
          open={isCopyModalVisible}
          handleClose={this.handleCopyModalClose}
          item={duplicatedSchedule}
          submitDuplicateModal={this.submitDuplicateModal}
          errorName={notUniqueChecklistName}
        />
        <ConfirmationDialog
          label={checklistToEdit.Name ? 'Yes' : 'Delete Checklist'}
          text={
            <>
              <S.ConfirmationText>
                {checklistToEdit.Name
                  ? 'Aborting this process will cancel all changes.'
                  : 'Aborting the process will delete this checklist and cannot be undone!'}
              </S.ConfirmationText>
              <S.ConfirmationWarning>Are you sure?</S.ConfirmationWarning>
            </>
          }
          open={isConfirmationDialogOpened}
          onClick={this.handleDeleteApproval}
          handleClose={this.handleDeleteReject}
        />
        <ConfirmationDialog
          width={'40%'}
          maxWidth={'400px'}
          handleClose={this.closeActivationDialog}
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Do you want to Activate or Deactivate this Checklist?</S.ConfirmationText>
              <ActionsContainer>
                <ActionButton
                  label="Deactivate"
                  onClick={() => {
                    this.checkActivationDialog('ChecklistActions/Deactivate');
                  }}
                />
                <ActionButton
                  label="Activate"
                  isNext
                  onClick={() => {
                    this.checkActivationDialog('ChecklistActions/Activate');
                  }}
                />
              </ActionsContainer>
            </S.ConfirmationContainer>
          }
          open={this.state.isConfirmationOpened}
        />
        <ConfirmationDialog
          label="Alert"
          open={isStatusInactive}
          width={'50%'}
          maxWidth={'350px'}
          handleClose={() =>
            this.setState({
              isStatusInactive: false,
            })
          }
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>You cannot edit this Checklist as end date is already reached.</S.ConfirmationText>
              <ActionsContainer>
                <ActionButton
                  isNext
                  label="CANCEL"
                  onClick={() =>
                    this.setState({
                      isStatusInactive: false,
                    })
                  }
                />
                <ActionButton label="ARCHIVE/DELETE" onClick={this.openTaskModal} />
              </ActionsContainer>
            </S.ConfirmationContainer>
          }
        />
        <ConfirmationDialog
          label="Alert"
          open={isTasksModalOpened}
          width={'80%'}
          maxWidth={'850px'}
          handleClose={() =>
            this.setState({
              isTasksModalOpened: false,
            })
          }
          text={
            <S.ConfirmationContainer>
              <S.RedTitleText>Removing this Checklist may disrupt tasks in progress!</S.RedTitleText>
              <S.RedSubTitleText>
                Completed tasks will be moved to the <S.BoldText>archive</S.BoldText>
              </S.RedSubTitleText>
              <S.Section>
                <S.SectionHeader>Task Status</S.SectionHeader>
                <S.SectionBody>
                  <S.TaskStatusText>
                    <S.BoldText>{currentChecklistStatus.InProgressCount}</S.BoldText> Task are in progress
                  </S.TaskStatusText>
                  <S.TaskStatusText>
                    <S.BoldText>{currentChecklistStatus.CompletedCount}</S.BoldText> Tasks are completed but not
                    archived
                  </S.TaskStatusText>
                  <S.RedTaskStatusText>
                    <S.BoldText>{currentChecklistStatus.RequireAttentionCount}</S.BoldText> Tasks require attention
                  </S.RedTaskStatusText>
                </S.SectionBody>
              </S.Section>
              <S.Section>
                <S.SectionHeader>Requiring Attention</S.SectionHeader>
                <S.SectionBody>
                  <S.SiteRow>
                    <S.IconButton onClick={this.goToAttention}>
                      <S.ExitIcon />
                    </S.IconButton>
                    <S.SiteText>{this.getSiteWithId(sitesAvailableToUserFullInfo, selectedSite)}</S.SiteText>
                  </S.SiteRow>
                </S.SectionBody>
              </S.Section>
              <S.RedSubTitleText>All none completed tasks will be deleted.</S.RedSubTitleText>
              <ActionsContainer>
                <ActionButton
                  isNext
                  label="Cancel"
                  onClick={() =>
                    this.setState({
                      isTasksModalOpened: false,
                    })
                  }
                />
                <ActionButton
                  label="Remove Checklist"
                  onClick={() =>
                    this.setState({
                      isTasksModalOpened: false,
                      isDeleteChecklistModalOpened: true,
                    })
                  }
                />
              </ActionsContainer>
            </S.ConfirmationContainer>
          }
        />
        <ConfirmationDialog
          label="Alert"
          open={isDeleteChecklistModalOpened}
          width={'50%'}
          maxWidth={'350px'}
          handleClose={() =>
            this.setState({
              isDeleteChecklistModalOpened: false,
            })
          }
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationBoldText>
                <S.ConfirmationText>All none completed tasks will be deleted!</S.ConfirmationText>
              </S.ConfirmationBoldText>
              <S.ConfirmationWarningText>Are you sure you want to remove this Checklist?</S.ConfirmationWarningText>
              <ActionsContainer>
                <ActionButton label="Remove Checklist" onClick={this.deleteChecklist} />
              </ActionsContainer>
            </S.ConfirmationContainer>
          }
        />
        <Dialog
          open={isOpenChecklistInfo}
          onRequestClose={this.closeChecklistInfo}
          title="Shortcut Checklist"
          titleColor={theme.mainDarkBlue}
          modalStyles={{ width: '80%', maxWidth: 500 }}
        >
          <ChecklistInfo
            checklistInfo={scheduleInfo}
            site={findSite(sitesAvailableToUserFullInfo, selectedSite)}
            dates={getInitialDates(scheduleInfo.ScheduleDto)}
            getCountTasks={this.getCountTasks}
            taskTemplates={taskTemplates}
            toEditShortcut={this.toEditShortcut}
            getManager={this.getManager}
            getTeamMember={this.getTeamMember}
          />
        </Dialog>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ schedule, auth, teams }) => ({
  scheduleInfo: schedule.details,
  list: schedule.schedulesList.filter(item => {
    if (item.IsFromShortcut && getSchedulerType() === 'Shortcut') {
      return true;
    }
    if (!item.IsFromShortcut && getSchedulerType() === 'Manual') {
      return true;
    }
    return false;
  }),
  detailsActivation: schedule.detailsActivation,
  selectedSite: auth.selectedSite,
  sitesAvailableToUserFullInfo: auth.user.sitesAvailableToUserFullInfo,
  currentUnit: schedule.currentUnit,
  unitItems: getUnitItems(schedule),
  userManagement: schedule.userManagement,
  taskTemplates: schedule.taskTemplates,
  currentChecklistStatus: schedule.currentChecklistStatus,
  teams: teams.list,
});

const mapDispatchToProps = {
  requestSchedulesList: scheduleActions.schedulesListRequest,
  requestCurrentUnit: scheduleActions.fetchCurrentUnitRequest,
  requestScheduleDetails: scheduleActions.fetchScheduleDetailsRequest,
  requestChecklistActivation: scheduleActions.fetchChecklistActivationRequest,
  requestScheduleTemplates: scheduleActions.fetchScheduleTemplatesRequest,
  requestUserManagement: scheduleActions.getUserManagementRequest,
  requestChecklistStatus: scheduleActions.checklistStatusRequest,
  requestDeleteSchedule: scheduleActions.deleteScheduleRequest,
  getTeamsRequest: teamActions.getTeamsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesList);
