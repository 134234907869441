import { createSelector } from 'reselect';

const getTenantSettings = list => list;
const getMasterSettings = (list, masterSettings) => masterSettings;
const getUserType = (list, masterSettings, userType) => userType;

export const getStandartMessage = createSelector(
  [getTenantSettings, getMasterSettings, getUserType],
  (tenantSettings, masterSettings, userType) => {
    let standartMessage = [];
    const settings = tenantSettings.SalutationTaskSpecialist ? tenantSettings : masterSettings;

    if (userType === 'Task Specialist') {
      standartMessage = [
        settings.SalutationTaskSpecialist,
        settings.IntroTextTaskSpecialist,
        settings.ClosingTextTaskSpecialist,
      ];
    } else if (userType === 'Manager') {
      standartMessage = [
        settings.SalutationManagerAdmin,
        settings.IntroTextManagerAdmin,
        settings.ClosingTextManagerAdmin,
      ];
    } else if (userType === 'System Administrator') {
      standartMessage = [
        settings.SalutationOEMManagerAdmin,
        settings.IntroTextOEMManagerAdmin,
        settings.ClosingTextOEMManagerAdmin,
      ];
    }

    return standartMessage;
  },
);

export const selector = () => {};
