import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../../../theme';
import { ButtonIcon } from 'components/Layout/Buttons';
import { DeleteIcon, FileUploadIcon } from 'components/Layout/Icons';
import InputFile from './InputFile';
import Image from './Image';

import * as S from './styled';

const LogoField = ({
  description,
  files,
  isLoading,
  isMaster,
  isPDF,
  isTenantLogo,
  hasLogo,
  onDelete,
  onDrop,
  onUpload,
  size,
  value,
}) => (
  <S.LogoContainer>
    <S.LogoContainerLeft>
      <S.LogoDescription>
        {description}
        <br />
        {size}
        <br />
        png
      </S.LogoDescription>
      <S.ImageContainer>
        {hasLogo
          ? !isLoading && <Image isMaster={isMaster} isPDF={isPDF} />
          : isMaster && !isLoading && <S.NoLogo isPDF={isPDF} />}
        {isLoading && <S.Loader />}
      </S.ImageContainer>
    </S.LogoContainerLeft>
    <S.LogoContainerRight>
      <S.FileLoaderContainer>
        {isMaster
          ? !hasLogo && !isLoading && <InputFile files={files} onDrop={onDrop} value={value} />
          : (!hasLogo || !isTenantLogo) && !isLoading && <InputFile files={files} onDrop={onDrop} value={value} />}
      </S.FileLoaderContainer>
      {!isLoading && (
        <S.Buttons>
          <ButtonIcon
            onClick={onUpload}
            disabled={isMaster ? !!hasLogo || !value : (!!hasLogo && isTenantLogo) || !value}
          >
            <FileUploadIcon color={theme.primaryBlack} />
          </ButtonIcon>
          <ButtonIcon onClick={onDelete} disabled={isMaster ? !hasLogo : !hasLogo || !isTenantLogo || isLoading}>
            <DeleteIcon color={theme.primaryBlack} />
          </ButtonIcon>
        </S.Buttons>
      )}
    </S.LogoContainerRight>
  </S.LogoContainer>
);

LogoField.defaultProps = {
  isPDF: false,
};

LogoField.propTypes = {
  description: PropTypes.string.isRequired,
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMaster: PropTypes.bool.isRequired,
  isPDF: PropTypes.bool,
  isTenantLogo: PropTypes.bool.isRequired,
  hasLogo: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default LogoField;
