import styled from 'styled-components';

export const Container = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid ${({ theme }) => theme.primaryGrey};

    thead {
      background: ${({ theme }) => theme.lightGrey};
    }

    tbody {
      background: ${({ theme }) => theme.fieldsBorderColor};
    }

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};

      & > td,
      & > th {
        padding: 15px;
        border-right: 1px solid ${({ theme }) => theme.primaryWhite};
        border-left: 1px solid ${({ theme }) => theme.primaryWhite};
      }

      & > td {
        text-align: left;
      }
    }
  }
`;

export const Text = styled.p`
  margin: 0 0 ${({ weight }) => (weight ? '30px' : '5px')};
  padding: 0;
  font-weight: ${({ weight }) => weight || 400};
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
`;
