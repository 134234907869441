import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';

import { AddIcon, CheckIcon, DeleteIcon, EditIcon } from 'components/Layout/Icons';
import { Dialog } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import {
  getAvailabilityOptions,
  createAvailabilityOption,
  updateAvailabilityOption,
  removeAvailabilityOption,
} from 'http/system';

import theme from 'theme';

import * as S from './styled';

const INITIAL_STATE = {
  list: [],
  isLoading: false,
  isConfirmationDialogOpened: false,
  itemIdToDelete: null,
  newItemText: '',
  error: '',
  editError: '',
  globalError: '',
  editingItem: {},
};

// TODO: extract editable list to separate shared component if needed
export default class AvailabilityOptionsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = { ...INITIAL_STATE };

  componentDidMount() {
    this.fetchAvailabilityOptions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ ...INITIAL_STATE });
      this.fetchAvailabilityOptions();
    }
  }

  fetchAvailabilityOptions = () => {
    this.setState({ isLoading: true });
    getAvailabilityOptions()
      .then(({ data: { root } }) => this.setState({ list: root, isLoading: false }))
      .catch(error => this.setState({ isLoading: false, error: error.toString() }));
  };

  handleConfirmationDialogOpen = id => this.setState({ isConfirmationDialogOpened: true, itemIdToDelete: id });

  handleConfirmationDialogClose = () => this.setState({ isConfirmationDialogOpened: false });

  handleInputChange = (e, value) => {
    this.setState({ editingItem: { ...this.state.editingItem, Name: value } });
  };

  handleNewItemTextChange = (e, newItemText) => this.setState({ newItemText });

  handleOptionDeletion = () => {
    const { itemIdToDelete } = this.state;
    this.setState({ isLoading: true, error: false, globalError: '' });

    return removeAvailabilityOption(itemIdToDelete)
      .then(() => {
        this.setState({ itemIdToDelete: null, isConfirmationDialogOpened: false });
        this.fetchAvailabilityOptions();
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          this.setState({ isLoading: false, globalError: error, isConfirmationDialogOpened: false });
        },
      );
  };

  // eslint-disable-next-line consistent-return
  handleEditItem = item => {
    const { editingItem, list } = this.state;

    if (!editingItem.Id) {
      return this.setState({ editingItem: { ...item } });
    }

    const optionInList = list.find(({ Id }) => Id === editingItem.Id);

    if (optionInList.Name === editingItem.Name) {
      return this.setState({ editingItem: {} });
    }

    this.setState({ isLoading: true, error: '', globalError: '' });
    if (editingItem.Name.trim() === '') {
      this.setState({ editError: 'Please enter a correct name' });
    } else {
      return updateAvailabilityOption(editingItem)
        .then(() => {
          this.setState({ editingItem: {}, editError: '' });
          this.fetchAvailabilityOptions();
        })
        .catch(
          ({
            response: {
              data: { error },
            },
          }) => {
            this.setState({ isLoading: false, editError: error });
          },
        );
    }
  };

  handleNewItemSubmit = event => {
    event.preventDefault();
    const { newItemText } = this.state;

    if (!newItemText.trim()) {
      return this.setState({ error: 'Please enter a correct name' });
    }

    this.setState({ isLoading: true, error: '', globalError: '' });
    return createAvailabilityOption({ Name: newItemText })
      .then(() => {
        this.setState({ newItemText: '' });
        this.fetchAvailabilityOptions();
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          this.setState({ error, isLoading: false });
        },
      );
  };

  render() {
    const { list, isConfirmationDialogOpened, newItemText, error, editError, editingItem, globalError } = this.state;
    const { open, handleClose } = this.props;

    return (
      <Dialog open={open} onRequestClose={handleClose} title="Availability Options" titleColor={theme.mainRed}>
        <S.Container>
          {globalError ? <S.Error>{globalError}</S.Error> : null}
          <S.List>
            {list.map(({ Id, Name }) => (
              <S.ItemContainer key={Id}>
                <S.ItemName>
                  <TextField
                    id={Id}
                    value={editingItem.Id && editingItem.Id === Id ? editingItem.Name : Name}
                    fullWidth
                    onChange={this.handleInputChange}
                    disabled={editingItem.Id !== Id}
                    underlineShow={editingItem.Id === Id}
                    errorText={editingItem.Id === Id && editError}
                  />
                </S.ItemName>
                <S.ItemActions>
                  <IconButton
                    disabled={(editingItem.Id && editingItem.Id !== Id) || Name === 'Unknown'}
                    onClick={() => this.handleEditItem({ Id, Name })}
                  >
                    {editingItem.Id === Id ? <CheckIcon /> : <EditIcon />}
                  </IconButton>
                  <IconButton onClick={() => this.handleConfirmationDialogOpen(Id)}>
                    <DeleteIcon />
                  </IconButton>
                </S.ItemActions>
              </S.ItemContainer>
            ))}
          </S.List>
          <S.CreateContainer onSubmit={this.handleNewItemSubmit}>
            <TextField
              hintText="New Specification Name"
              floatingLabelText="New Specification Name"
              fullWidth
              value={newItemText}
              onChange={this.handleNewItemTextChange}
              errorText={error}
            />
            <IconButton type="submit">
              <AddIcon />
            </IconButton>
          </S.CreateContainer>
        </S.Container>
        <ConfirmationDialog
          open={isConfirmationDialogOpened}
          handleClose={this.handleConfirmationDialogClose}
          headingText="Are you sure you want to remove this option?"
          label="Remove"
          onClick={this.handleOptionDeletion}
        />
      </Dialog>
    );
  }
}
