import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import { HideOnPhones } from 'components/Layout/Media';
import { fixedTableHeadStyles } from 'components/TableList/styled';
import { CopyIcon, ChatIcon } from 'components/Layout/Icons';
import ReactTooltip from 'react-tooltip';

export const Container = styled(Flex).attrs({
  column: true,
  flex: 1,
})``;

export const Message = styled(Box)`
  font-size: 16px;
  padding: 15px;
`;

export const Name = styled(Box)`
  font-size: 16px;
  word-break: break-all;
`;

export const TaskContainer = styled(Flex).attrs({
  align: 'center',
  justify: 'space-between',
  px: 2,
})``;

export const ButtonsContainer = styled(Flex)`
  ${HideOnPhones}
`;

export const EmptyList = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 220px;
`;

export const TableContainer = styled.div`
  margin-bottom: 60px;

  ${fixedTableHeadStyles}
  
  & td:nth-child(n+1):nth-child(-n+5){
    border-right: 1px solid ${({ theme }) => theme.lightGrey}
  }

  th:nth-child(n){
    background-color: ${({ theme }) => theme.mainLightBlue};
    border-right: 1px solid ${({ theme }) => theme.lightGrey}
    color: ${({ theme }) => theme.primaryWhite}
    text-align: center;
    font-size: 14px;
  }

  td:nth-child(n){
    border-bottom: 1px solid ${({ theme }) => theme.lightGrey}
    font-size: 14px;
  }

  th:nth-child(n+1):nth-child(-n+4), td:nth-child(n+1):nth-child(-n+4) {
    padding: 0 24px !important;
  }
  
  & td:first-child {
    min-width: 10vw;
  }

  & td:nth-child(n+3):nth-child(-n+6) {
    width: 70px;
  }

  & td:nth-child(n+5):nth-child(-n+6) {
    text-align: center;
  }
`;

export const IconCopy = styled(CopyIcon).attrs({
  color: ({ theme }) => theme.textGray,
})``;

export const NotesIcon = styled(ChatIcon).attrs({
  color: ({ theme }) => theme.textGray,
})``;

export const WhiteNotesIcon = styled(ChatIcon).attrs({
  color: ({ theme }) => theme.primaryWhite,
  style: { transition: 'none' },
})`
  transform: scale(1.8);
  padding: 5px 0;
`;

export const TooltipBlock = styled.div`
  position: relative;
  display: flex;
  width: 330px;
  padding: 10px;
  color: ${({ theme }) => theme.primaryWhite};
  justify-content: space-between;
`;

export const TooltipTitle = styled.div`
  font-size: 14pt;
  padding-bottom: 5px;
`;

export const TooltipText = styled.div`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 12pt;
`;

export const NotesTooltipBlock = styled.div`
  font-size: 12px;
  width: 290px;
  text-align: left;
  position: relative;
  font-weight: 100;
`;

export const Tooltip = styled(ReactTooltip)`
  background-color: ${({ theme }) => theme.primaryGrey} !important;
  text-align: center;
  border-radius: 10px !important;
  padding: 0px 7px !important;
  opacity: 0.95 !important;
  margin: 0px 0px 0px -60px !important;
  font-size: 10px !important;
  z-index: 1000 !important;
  &:after {
    display: none;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
