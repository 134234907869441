import React from 'react';
import PropTypes from 'prop-types';

import DrawerTitle from './Title';

import * as S from './styled';

const withDrawer = (Comp, zIndex = 3) => {
  function Wrapper({ children, fullHeight, ...props }) {
    return (
      <>
        <S.ModalContainer isModalOpened={props.open} onClick={props.handleClose} zIndex={zIndex} />
        <S.Drawer open={props.open} fullHeight={fullHeight} zIndex={zIndex}>
          <DrawerTitle {...props} />
          <Comp {...props}>{children}</Comp>
        </S.Drawer>
      </>
    );
  }

  Wrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    fullHeight: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
  };

  Wrapper.defaultProps = {
    children: [],
    fullHeight: false,
  };

  return Wrapper;
};

export default withDrawer;
