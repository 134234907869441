import styled from 'styled-components';
import { ButtonFlat } from 'components/Layout/Buttons';

export const Content = styled.div`
  padding: 0px 40px 15px 40px;
`;

export const Title = styled.h2`
  font-size: 20px;
  padding: 15px;
  border-bottom: 2px solid ${({ theme }) => theme.darkGrey};
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  font-weight: 400;
`;

export const TaskInfo = styled.ul`
  margin-bottom: 20px;
  padding: 0;
  font-size: 18px;

  & li {
    list-style-type: none;
  }
`;

export const LocationInfo = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  & li {
    list-style-type: none;
    margin-bottom: 15px;
  }
`;

export const Parts = styled.div`
  margin-bottom: 20px;
`;

export const TextSection = styled.div`
  margin-bottom: 20px;

  & > p {
    margin: 5px 0;
  }
`;

export const TextTitle = styled.div`
  font-weight: bold;
`;

export const Buttons = styled.div`
  border-top: 2px solid ${({ theme }) => theme.darkGrey};
`;

export const CancelButton = styled(ButtonFlat)`
  div {
    color: ${({ theme }) => theme.greenButton} !important;
  }
`;

export const RedButton = styled(ButtonFlat)`
  div {
    color: ${({ theme }) => theme.partDetailsRed} !important;
  }
`;
