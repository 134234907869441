import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import validate from './validator';
import * as S from './styled';

const DuplicateScheduleForm = ({ errorName }) => (
  <S.FormContainer>
    <Field component={TextFieldAdapter} type="text" name="OldName" disabled floatingLabelText="Current Name" />
    <Field component={TextFieldAdapter} type="text" name="name" floatingLabelText="New Name" />
    {errorName && <S.ErrorContainer>Checklist with name &apos;{errorName}&apos; already exists</S.ErrorContainer>}
  </S.FormContainer>
);

const DuplicateScheduleWithInput = withInputModal(DuplicateScheduleForm);

const DuplicateScheduleModal = props => (
  <DuplicateScheduleWithInput
    {...props}
    initialValues={{ OldName: props.item.Name }}
    title="Duplicate Schedule"
    onSubmit={values => props.submitDuplicateModal(values.name, props.item)}
    validate={validate}
  />
);

DuplicateScheduleModal.propTypes = {
  item: PropTypes.string.isRequired,
  submitDuplicateModal: PropTypes.func.isRequired,
};

DuplicateScheduleForm.propTypes = {
  errorName: PropTypes.string.isRequired,
};

export default DuplicateScheduleModal;
