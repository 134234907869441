import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import * as CS from 'components/Dialogs/styled';

import SetNotificationStatusDialog from '../SetNotificationStatusDialog';

import { userRoles } from './helpers';
import * as S from './styled';

class SelectUserRoleDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    role: '',
    setNotificationStatusDialogOpen: false,
  };

  onRadioGroupChange = (e, value) => this.setState({ role: value });

  openSetNotificationStatusDialogOpen = () => {
    if (!this.state.role) return;

    this.setState({ setNotificationStatusDialogOpen: true });
    this.props.handleClose();
  };

  closeSetNotificationStatusDialogOpen = () => {
    this.setState({ setNotificationStatusDialogOpen: false });
  };

  render() {
    const { handleClose, open, title } = this.props;
    const { role, setNotificationStatusDialogOpen } = this.state;
    const roleObject = userRoles.find(({ value }) => value === role);

    return (
      <>
        <BaseDialog open={open} onRequestClose={handleClose} title={title}>
          <S.DialogContainer>
            <S.RadioGroupContainer>
              <S.RadioGroup name="userRole" value={role} onChange={this.onRadioGroupChange}>
                {userRoles.map(({ value, label }) => (
                  <S.FieldRadio key={value} label={label} value={value} />
                ))}
              </S.RadioGroup>
            </S.RadioGroupContainer>
            <CS.DialogFooter>
              <CS.ButtonContainer onClick={this.openSetNotificationStatusDialogOpen} fullWidth>
                <CS.SaveButton disabled={!role} submitLabel="Next" />
              </CS.ButtonContainer>
            </CS.DialogFooter>
          </S.DialogContainer>
        </BaseDialog>
        {role && (
          <SetNotificationStatusDialog
            open={setNotificationStatusDialogOpen}
            handleClose={this.closeSetNotificationStatusDialogOpen}
            title={roleObject ? `Set Notification Status ${roleObject.label}` : ''}
            userRole={role}
          />
        )}
      </>
    );
  }
}

export default SelectUserRoleDialog;
