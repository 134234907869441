import styled from 'styled-components';
import { Flex } from 'grid-styled';
import Slider from 'material-ui/Slider';

import { FieldSelect, SelectItem } from 'components/Layout/Selects';

export const TierContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const IotHubItem = styled(SelectItem)``;

export const Select = styled(FieldSelect)``;

export const SliderContainer = styled(Flex).attrs({
  column: true,
  align: 'center',
  w: 1,
  mx: 3,
})``;

export const SliderLabel = styled.div`
  font-size: 12px;
  opacity: 0.5;
  margin-bottom: 20px;
`;

export const SliderField = styled(Slider)`
  & {
    width: 100%;
    div {
      margin: 0 !important;
    }
  }
`;

export const Capacity = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
})`
  align-self: center;
  width: 80px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  user-select: none;
`;
