export const validator = values => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (!values.AssetTypeName) {
    errors.AssetTypeName = 'Required';
  }

  if (values.BeaconMajor && (values.BeaconMajor < 0 || values.BeaconMajor > 32767)) {
    errors.BeaconMajor = 'Must be a number from 0 to 32.767';
  }

  return errors;
};

export default validator;
