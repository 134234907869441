import styled from 'styled-components';

import { ListContainer } from 'components/Layout/Containers';

export const Container = styled(ListContainer)`
  max-height: 100%;
  padding-bottom: 21px;
  z-index: 1;
`;

export const Message = styled.div`
  padding: 16px;
  text-align: center;
`;

export const MessageContainer = styled(ListContainer)`
  height: 100%;
  justify-content: center;
`;
