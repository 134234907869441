import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import { Link } from 'react-router-dom';

import { PaperContainer } from 'components/Layout/Containers';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DashboardContainer = styled(Flex).attrs({
  align: 'center',
  column: true,
  justify: 'space-between',
  p: 2,
  w: [1, 0.95, 0.7, 0.65],
})`
  min-height: 0;
`;

export const Header = styled(Box)`
  text-align: center;
  font-size: 30px;
  font-weight: 200;
  color: ${({ theme }) => theme.tenantNameColor};
  padding-bottom: 30px;
`;

export const ColumnContainer = styled(Flex).attrs({
  column: true,
  w: [1, 0.9, 0.8],
})`
  padding: 15px;
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
`;

export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.lightGrey};
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 2px;
`;

export const StaticticsHeader = styled.span`
  color: ${({ theme }) => theme.primarySettings};
  font-size: 22px;
`;

export const StatisticsModules = styled(Flex).attrs({
  flex: 'none',
  justify: 'space-around',
})`
  width: 100%;
  margin-top: 20px;
`;

export const StatisticsModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatisticsValue = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 50% !important;
  background-color: ${({ color }) => color} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 200;
  font-size: 24px;
`;

export const StatisticsLabel = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryBlack};
  text-transform: uppercase;
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const ModulePaper = styled(PaperContainer)`
  border-radius: 8px !important;
  background-color: ${({ theme }) => theme.primaryQueue} !important;
  position: relative;
`;

export const ModuleLabel = styled(Box).attrs({
  mr: 1,
})`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 30px;
  margin-left: 30px;

  @media only screen and (max-width: 330px) {
    margin-left: 15px;
  }
`;

export const TasksNumberContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 60px;
  height: 35px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primaryRed};
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModuleIconContainer = styled.div`
  border-radius: 8px 0 0 8px;
  height: 70px;
  width: 70px;
  background: ${({ theme }) => theme.secondaryYellow};
  display: flex;
  justify-content: center;
  align-items: center;

  && svg {
    color: ${({ theme }) => theme.primaryQueue} !important;
    width: 80% !important;
    height: 90% !important;
  }
`;
