import styled from 'styled-components';

import { FieldSelect, SelectItem } from 'components/Layout/Selects';

export const DialogContainer = styled.div`
  padding: 0 40px;
  text-align: center;
`;

export const CardContentContainer = styled.div`
  padding: 30px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Select = styled(FieldSelect)``;

export const Item = styled(SelectItem)``;
