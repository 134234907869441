import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseDialog from 'components/Dialogs/Base';

import { actions as partsActions } from 'redux/parts';
import { connect } from 'react-redux';

import * as S from './styled';
import { NullOrObject } from 'configs/propTypes';

class ItemPartsViewDialog extends PureComponent {
  static propTypes = {
    unitToLink: NullOrObject,
    itemToLink: NullOrObject,
    units: PropTypes.array.isRequired,
    openSelectPartsDialog: PropTypes.func,
    openSelectGroupsDialog: PropTypes.func,
    listPartsByItem: PropTypes.array.isRequired,
    listPartGroupsByItem: PropTypes.array.isRequired,
    getListPartsByItem: PropTypes.func.isRequired,
    getListPartGroupsByItem: PropTypes.func.isRequired,
    removePartFromItem: PropTypes.func.isRequired,
    removePartGroupFromItem: PropTypes.func.isRequired,
    removeHandler: PropTypes.func,
    isView: PropTypes.bool,
  };

  static defaultProps = {
    unitToLink: null,
    itemToLink: null,
    openSelectPartsDialog: null,
    openSelectGroupsDialog: null,
    removeHandler: null,
    isView: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.itemToLink?.Id !== this.props.itemToLink?.Id) {
      const payload = { itemId: nextProps.itemToLink.Id };
      this.props.getListPartsByItem(payload);
      this.props.getListPartGroupsByItem(payload);
    }
  }

  removePartFromItem = partId => () => {
    const payload = {
      partId,
      siteId: this.props.unitToLink.SiteId,
      itemId: this.props.itemToLink.Id,
    };

    if (this.props.removeHandler) {
      this.props.removeHandler(
        () => this.props.removePartFromItem(payload),
        'Are you sure you want to remove this part?',
      );
    } else this.props.removePartFromItem(payload);
  };

  removeGroupFromItem = groupId => () => {
    const payload = {
      partGroupId: groupId,
      siteId: this.props.unitToLink.SiteId,
      itemId: this.props.itemToLink.Id,
    };

    if (this.props.removeHandler) {
      this.props.removeHandler(
        () => this.props.removePartGroupFromItem(payload),
        'Are you sure you want to remove this group?',
      );
    } else this.props.removePartGroupFromItem(payload);
  };

  getActualTotalLinksCount = () => {
    const { units, unitToLink, itemToLink } = this.props;

    if (unitToLink && itemToLink) {
      const unit = units.find(item => item.Id === unitToLink?.Id);
      const item = unit?.Items.find(unitItem => unitItem.Id === itemToLink.Id);
      return item?.TotalLinksCountWithGroups || 0;
    }

    return 0;
  };

  render() {
    const {
      listPartsByItem,
      listPartGroupsByItem,
      unitToLink,
      itemToLink,
      openSelectPartsDialog,
      openSelectGroupsDialog,
      isView,
      ...rest
    } = this.props;

    return (
      <BaseDialog {...rest} modalStyles={{ maxWidth: '480px' }}>
        <S.DialogContent>
          <S.LocationInfo>
            <S.LocationInfoHeader>Location Info</S.LocationInfoHeader>
            <S.LocationInfoContent>
              <p>
                <span>Site: </span> {unitToLink?.SiteName}
              </p>
              <p>
                <span>Unit: </span> {unitToLink?.Name}
              </p>
              <p>
                <span>Item: </span> {itemToLink?.Name}
              </p>
            </S.LocationInfoContent>
          </S.LocationInfo>
          <S.TotalPartsCount>
            <p>Total Parts Count (incl. Groups)</p>
            <p>{this.getActualTotalLinksCount()}</p>
          </S.TotalPartsCount>
          <S.PartsInfo>
            <S.PartsInfoHeader view={isView}>
              <S.Col>Parts Info</S.Col>
              <S.Col textCenter="true">Qty</S.Col>
              {!isView ? <S.Col /> : null}
            </S.PartsInfoHeader>
            <S.PartsInfoContent>
              {listPartsByItem.map(part => (
                <S.PartsInfoContentRow key={part.Id} view={isView}>
                  <S.Col>{part.MyPartID}</S.Col>
                  <S.Col>{part.MyPartName}</S.Col>
                  <S.Col textCenter="true">{part.LinksCount}</S.Col>
                  {!isView ? (
                    <S.Col textCenter="true">
                      <S.DeleteBtn onClick={this.removePartFromItem(part.Id)} />
                    </S.Col>
                  ) : null}
                </S.PartsInfoContentRow>
              ))}
            </S.PartsInfoContent>
            {openSelectPartsDialog ? (
              <S.AddBtnContainer>
                <S.AddIcon onClick={openSelectPartsDialog} />
              </S.AddBtnContainer>
            ) : null}
          </S.PartsInfo>
          <S.GroupInfo>
            <S.GroupInfoHeader view={isView}>
              <S.Col>Groups Info</S.Col>
              <S.Col textCenter="true">Qty</S.Col>
              {!isView ? <S.Col /> : null}
            </S.GroupInfoHeader>
            <S.GroupInfoContent>
              {listPartGroupsByItem.map(group => (
                <S.GroupInfoContentRow key={group.Id} view={isView}>
                  <S.Col>{group.Name}</S.Col>
                  <S.Col textCenter="true">{group.LinksCount}</S.Col>
                  {!isView ? (
                    <S.Col textCenter="true">
                      <S.DeleteBtn onClick={this.removeGroupFromItem(group.Id)} />
                    </S.Col>
                  ) : null}
                </S.GroupInfoContentRow>
              ))}
            </S.GroupInfoContent>
            {openSelectGroupsDialog ? (
              <S.AddBtnContainer>
                <S.AddIcon onClick={openSelectGroupsDialog} />
              </S.AddBtnContainer>
            ) : null}
          </S.GroupInfo>
        </S.DialogContent>
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ auth: { siteName }, parts: { listPartsByItem, listPartGroupsByItem } }) => ({
  siteName,
  listPartsByItem,
  listPartGroupsByItem,
});

const mapDispatchToProps = {
  getListPartsByItem: partsActions.getListPartsByItemRequest,
  getListPartGroupsByItem: partsActions.getListPartGroupsByItemRequest,
  removePartFromItem: partsActions.removePartFromItemRequest,
  removePartGroupFromItem: partsActions.removePartGroupFromItemRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemPartsViewDialog);
