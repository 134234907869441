import { bindActionCreators } from 'redux';

import { actions as settingsActions } from 'redux/settings';
import { actions as appActions } from 'redux/app';
import { actions as peopleActions } from 'redux/people';
import { actions as sitesActions } from 'redux/sites';
import { actions as partsActions } from 'redux/parts';

import { openErrorDialog } from 'redux/errorHandler';
import { openConfirmationDialog } from 'redux/confirmationHandler';

const mapStateToProps = ({ settings, parts }) => ({
  error: settings.error || parts.error,
  isUpdated: settings.isUpdated,
  loading: settings.loading,
  masterSystemLinks: settings.masterSystemLinks,
  settings: settings.list,
  systemLinks: settings.systemLinks,
  oemList: parts.oemList,
  suppliersList: parts.suppliersList,
  isDemoTasksMode: settings.list.IsDemoTasksMode ? 'DemoTasks' : 'LiveTasks',
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDemoTasksCount: settingsActions.getDemoTasksCountRequest,
      openErrorDialog,
      requestGetPhoto: settingsActions.getLogoRequest,
      requestGetPhotoEmail: settingsActions.getLogoEmailRequest,
      requestGetSettings: settingsActions.getSettingsRequest,
      requestGetSettingsLinksList: settingsActions.getSettingsLinksListRequest,
      requestSetSettings: settingsActions.setSettingsRequest,
      resetMasterSettingsForAdmin: settingsActions.resetMasterSettingsForAdmin,
      setDefaultLinksSettings: settingsActions.setSettingsLinksRequest,
      updateCurrentGlobalSettingsModule: appActions.updateCurrentGlobalSettingsModule,
      requestSitesList: sitesActions.sitesListRequest,
      requestPeopleList: peopleActions.peopleListRequest,
      resetSiteList: sitesActions.resetSitesList,
      openConfirmationDialog,
      ...partsActions,
    },
    dispatch,
  ),
});

export { mapStateToProps, mapDispatchToProps };
