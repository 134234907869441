import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { PartsListType } from 'configs/propTypes';

import BaseDialog from 'components/Dialogs/Base';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import { GreenButton } from 'components/Layout/Buttons';

import { setInitialLinkedParts } from './helpers';
import * as S from './styled';

const tableHeads = ['checkbox', 'My ID', 'My Name', 'My Category'];

const tableColumns = ['Id', 'MyPartID', 'MyPartName', 'MyPartCategory'];

class PartSelectionDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    currentParts: PropTypes.arrayOf(PartsListType),
    parts: PropTypes.arrayOf(PartsListType).isRequired,
  };

  static defaultProps = {
    currentParts: [],
  };

  state = {
    list: this.props.parts,
    searchData: '',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.setState({ list: nextProps.parts, searchData: '' });
    }
  }

  handleSelectAllPress = (form, checked) => {
    const { list } = this.state;

    if (!checked) {
      form.reset({});
      return;
    }

    const values = list.reduce(
      (accum, value) => ({
        ...accum,
        [value.Id]: true,
      }),
      {},
    );
    form.reset(values);
  };

  onSearch = (list, searchData) => this.setState({ list, searchData });

  render() {
    const { list, searchData } = this.state;
    const { open, handleClose, title, currentParts, submitLabel, onSubmit } = this.props;

    return (
      <BaseDialog
        open={open}
        onRequestClose={handleClose}
        title={title}
        bodyStyle={{ display: 'flex', flex: 1, padding: 0 }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={setInitialLinkedParts(currentParts || [])}
          render={({ handleSubmit, form, values }) => {
            const selectedCheckboxes = Object.keys(values).filter(key => values[key]);
            const isAllChecked = selectedCheckboxes.length === list.length;
            const isDisabled = !selectedCheckboxes.length;

            return (
              <S.Form onSubmit={handleSubmit} noValidate>
                <S.DialogContainer>
                  <S.SearchContainer>
                    <SearchField
                      list={this.props.parts}
                      filterBy={['MyPartID', 'MyPartName', 'MyPartCategory']}
                      searchData={searchData}
                      placeholder="filter parts"
                      icon={<SearchIcon />}
                      inputStyle={S.searchInputStyles}
                      setSearchedItems={this.onSearch}
                    />
                  </S.SearchContainer>
                  <S.TableListContainer>
                    <S.TableList>
                      <S.TableRow>
                        {tableHeads.map(head => (
                          <S.TableHeaderCell key={head}>
                            {head === 'checkbox' ? (
                              <>
                                <S.SelectAll>
                                  Select
                                  <br />
                                  All
                                </S.SelectAll>
                                <S.CheckboxSimple
                                  isChecked={isAllChecked}
                                  onCheck={(_, value) => this.handleSelectAllPress(form, value)}
                                />
                              </>
                            ) : (
                              head
                            )}
                          </S.TableHeaderCell>
                        ))}
                      </S.TableRow>
                      <S.TableBody>
                        {list.map(part => (
                          <S.TableRow key={part.Id}>
                            {tableColumns.map(column => (
                              <S.TableCell key={column}>
                                {column === 'Id' ? (
                                  <Field name={part[column]} component={S.CheckboxForm} />
                                ) : (
                                  part[column]
                                )}
                              </S.TableCell>
                            ))}
                          </S.TableRow>
                        ))}
                      </S.TableBody>
                    </S.TableList>
                  </S.TableListContainer>
                  <S.Controls>
                    <GreenButton type="submit" disabled={isDisabled} fullWidth primary label={submitLabel} />
                  </S.Controls>
                </S.DialogContainer>
              </S.Form>
            );
          }}
        />
      </BaseDialog>
    );
  }
}

export default PartSelectionDialog;
