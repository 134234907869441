import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as authActions } from 'redux/auth';
import { INSTRUCTIONS_MODULES } from 'configs/routes';
import theme from 'theme';

import * as S from './styled';

const greeyIcons = ['Health', 'Sites', 'People', 'Settings', 'Shortcuts'];

const selectIcon = item => {
  if (item.title === 'Shortcuts') {
    return <S.ShortcutsIcon />;
  } else if (item.title === 'Teams') {
    return <S.TeamsIcon />;
  }
  return <item.icon color={greeyIcons.includes(item.title) ? theme.darkGrey : item.color} />;
};

const SideMenuDialog = ({ logout, openProfile, selectedSite, toSelectedPage, userType, ...rest }) => (
  <S.Modal {...rest}>
    <S.Triangle />
    <S.ProfileButton onClick={openProfile} />
    {Object.values(INSTRUCTIONS_MODULES[userType]).map(
      item =>
        (userType <= 4 && !selectedSite && item.tooltip === 'Site Specific') || (
          <S.InstructionButton
            key={item.title}
            icon={selectIcon(item)}
            label={item.title}
            onClick={() => toSelectedPage(item.path)}
          />
        ),
    )}
    <S.LogOutButton onClick={logout} />
  </S.Modal>
);

SideMenuDialog.propTypes = {
  logout: PropTypes.func.isRequired,
  openProfile: PropTypes.func.isRequired,
  selectedSite: PropTypes.string.isRequired,
  toSelectedPage: PropTypes.func.isRequired,
  userType: PropTypes.number.isRequired,
};

const mapStateToProps = ({
  auth: {
    selectedSite,
    user: { userType },
  },
}) => ({
  selectedSite,
  userType,
});

const mapDispatchToProps = {
  logout: authActions.logoutRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuDialog);
