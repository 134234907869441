import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'components/Dialogs/Base';
import InputForm from 'components/Forms';

import FormControls from './Controls';
import * as S from './styled';

const withInputModal = Component => {
  function Wrapper({
    BigButtons,
    cancelLabel,
    deleteLabel,
    createQR,
    disableButtons,
    handleClick,
    handleClose,
    handleDelete,
    handleReset,
    handleSaveContinue,
    icon,
    isCopy,
    isSearch,
    isSearchAll,
    open,
    saveContinueLabel,
    submitLabel,
    withCancel,
    withDelete,
    withOnClick,
    withoutTopBorder,
    withReset,
    withSaveContinue,
    withSubmit,
    withGreenButton,
    isNewSubmitButton,
    withGreenBorder,
    isNewDeleteButton,
    hasPadding,
    gapValue,
    hasPaddingBottom,
    ...rest
  }) {
    return (
      <Dialog
        BigButtons={BigButtons}
        createQR={createQR}
        handleClick={handleClick}
        handleDelete={handleDelete}
        handleReset={handleReset}
        handleSaveContinue={handleSaveContinue}
        isCopy={isCopy}
        isSearch={isSearch}
        isSearchAll={isSearchAll}
        modal={false}
        open={open}
        onRequestClose={handleClose}
        withDelete={withDelete}
        withOnClick={withOnClick}
        withoutTopBorder={withoutTopBorder}
        withReset={withReset}
        withSaveContinue={withSaveContinue}
        withSubmit={withSubmit}
        {...rest}
      >
        <InputForm
          {...rest}
          controls={
            <FormControls
              BigButtons={BigButtons}
              cancelLabel={cancelLabel}
              deleteLabel={deleteLabel}
              createQR={createQR}
              disableButtons={disableButtons}
              icon={icon}
              handleClick={handleClick}
              handleClose={handleClose}
              handleDelete={handleDelete}
              handleReset={handleReset}
              handleSaveContinue={handleSaveContinue}
              isCopy={isCopy}
              isSearch={isSearch}
              isSearchAll={isSearch}
              open={open}
              saveContinueLabel={saveContinueLabel}
              submitLabel={submitLabel}
              withCancel={withCancel}
              withDelete={withDelete}
              withOnClick={withOnClick}
              withoutTopBorder={withoutTopBorder}
              withReset={withReset}
              withSaveContinue={withSaveContinue}
              withSubmit={withSubmit}
              withGreenButton={withGreenButton}
              withGreenBorder={withGreenBorder}
              isNewSubmitButton={isNewSubmitButton}
              isNewDeleteButton={isNewDeleteButton}
              hasPadding={hasPadding}
              gapValue={gapValue}
            />
          }
          flag
          renderChildren={({ form, values, pristine }) => (
            <S.DialogContainer hasPaddingBottom={hasPaddingBottom}>
              <Component form={form} open={open} values={values} pristine={pristine} {...rest} />
            </S.DialogContainer>
          )}
        />
      </Dialog>
    );
  }

  Wrapper.propTypes = {
    BigButtons: PropTypes.bool,
    createQR: PropTypes.bool,
    disableButtons: PropTypes.bool,
    handleClose: PropTypes.func,
    handleClick: PropTypes.func,
    handleDelete: PropTypes.func,
    handleReset: PropTypes.func,
    handleSaveContinue: PropTypes.func,
    icon: PropTypes.node,
    isCopy: PropTypes.bool,
    isSearch: PropTypes.bool,
    isSearchAll: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    saveContinueLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    deleteLabel: PropTypes.string,
    submitLabel: PropTypes.string,
    withCancel: PropTypes.bool,
    withDelete: PropTypes.bool,
    withOnClick: PropTypes.bool.isRequired,
    withoutTopBorder: PropTypes.bool,
    withReset: PropTypes.bool,
    withSaveContinue: PropTypes.bool,
    withSubmit: PropTypes.bool,
    withGreenButton: PropTypes.bool,
    isNewSubmitButton: PropTypes.bool,
    isNewDeleteButton: PropTypes.bool,
    hasPaddingBottom: PropTypes.bool,
    hasPadding: PropTypes.bool,
    gapValue: PropTypes.string,
    withGreenBorder: PropTypes.bool,
  };

  Wrapper.defaultProps = {
    BigButtons: false,
    cancelLabel: 'Cancel',
    deleteLabel: 'Delete',
    createQR: false,
    disableButtons: false,
    handleClick: () => {},
    handleClose: () => {},
    handleDelete: () => {},
    handleReset: () => {},
    handleSaveContinue: () => {},
    icon: null,
    isCopy: false,
    isSearch: false,
    isSearchAll: false,
    saveContinueLabel: 'Save and Add Next Task',
    submitLabel: 'Save',
    withCancel: false,
    withDelete: false,
    withOnClick: false,
    withoutTopBorder: false,
    withReset: false,
    withSaveContinue: false,
    withSubmit: true,
    withGreenButton: false,
    isNewSubmitButton: false,
    withGreenBorder: false,
    isNewDeleteButton: false,
    hasPaddingBottom: true,
    hasPadding: false,
    gapValue: '',
  };

  return Wrapper;
};

export default withInputModal;
