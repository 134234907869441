/* global OneSignal */
window.OneSignal = window.OneSignal || [];
const { REACT_APP_ONESIGNAL_APP_ID, REACT_APP_ONESIGNAL_SAFARI_WEB_ID } = process.env;

let isInitialized = false;

export const unsubscribeUsers = () => {
  OneSignal.push(() => OneSignal.removeExternalUserId());
};

export const init = userId => {
  if (!userId) {
    // eslint-disable-next-line no-console
    console.error('OneSignal: No user ID: ', userId);
    return;
  }

  if (!isInitialized) {
    isInitialized = true;
    // eslint-disable-next-line no-console
    console.log('OneSignal initialization');
    OneSignal.push(() => {
      // eslint-disable-next-line no-console
      console.log('OneSignal Push');
      OneSignal.log.setLevel('trace');
      OneSignal.init({
        appId: REACT_APP_ONESIGNAL_APP_ID,
        safari_web_id: REACT_APP_ONESIGNAL_SAFARI_WEB_ID,
        notifyButton: {
          enable: true,
        },
      });
      OneSignal.setExternalUserId(userId);
    });
  } else {
    OneSignal.push(() => {
      OneSignal.setExternalUserId(userId);
    });
  }
};
