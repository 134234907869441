import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BaseDialog from 'components/Dialogs/Base';
import ConnectionField from 'components/Dialogs/Activators/ConnectionField';

import * as CS from 'components/Dialogs/Activators/styled';

import { resourceIcons } from 'configs/enums';

import { actions as tenantsActions } from 'redux/tenants';
import { actions as sitesActions } from 'redux/sites';
import { actions as gatewaysActions } from 'redux/gateways';

import theme from 'theme';

import * as S from './styled';

const cards = [
  { name: 'tenant', previousCard: '' },
  { name: 'site', previousCard: 'tenant' },
  { name: 'hub', previousCard: 'site' },
  { name: 'gateway', previousCard: 'hub' },
  { name: 'connection', previousCard: 'gateway' },
];

const cardFields = {
  tenant: { key: 'Id', value: 'Id', primaryText: 'Name', hintText: 'Select Tenant' },
  site: { key: 'Id', value: 'Id', primaryText: 'Name', hintText: 'Select Site' },
  hub: { key: 'Name', value: 'Name', primaryText: 'Name', hintText: 'Select Hub' },
  gateway: { key: 'DeviceId', value: 'DeviceId', primaryText: 'DeviceId', hintText: 'Select Gateway' },
};

class KeysDialog extends Component {
  static propTypes = {
    action: PropTypes.shape({
      getGateways: PropTypes.func.isRequired,
      getHubs: PropTypes.func.isRequired,
      getSites: PropTypes.func.isRequired,
      getTenants: PropTypes.func.isRequired,
      getConnectionString: PropTypes.func.isRequired,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    lists: PropTypes.shape({
      gateway: PropTypes.arrayOf(
        PropTypes.shape({
          TenantID: PropTypes.string,
          ResourceGroupName: PropTypes.string,
        }),
      ),
    }).isRequired,
  };

  state = {
    connection: '',
    data: {
      tenant: '',
      site: '',
      hub: '',
      gateway: '',
    },
    isCopied: false,
  };

  componentDidMount() {
    this.props.action.getTenants();
  }

  selectChange = (value, card) => {
    const {
      action: { getSites, getHubs, getGateways },
    } = this.props;
    const { data, connection, isCopied } = this.state;

    if (connection || isCopied) {
      this.setState({ isCopied: false, connection: '' });
    }

    if (card === 'tenant') {
      this.setState({ data: { tenant: value, site: '', hub: '', gateway: '' } });
      getSites({ tenantId: value });
    }
    if (card === 'site') {
      this.setState({ data: { ...data, site: value, hub: '', gateway: '' } });
      getHubs({ tenantId: data.tenant, siteId: value });
    }
    if (card === 'hub') {
      this.setState({ data: { ...data, hub: value, gateway: '' } });
      getGateways({ tenantId: data.tenant, siteId: data.site, iotHubName: value });
    }
    if (card === 'gateway') {
      this.setState({ data: { ...data, gateway: value } });
    }
  };

  setConnectionString = connection => this.setState({ connection });

  onRetrieve = () => {
    const {
      action,
      lists: { gateway: gatewaysList },
    } = this.props;
    const {
      data: { tenant, hub, gateway },
    } = this.state;
    const { ResourceGroupName } = gatewaysList.find(tmpGateway => tmpGateway.TenantID === tenant);

    action.getConnectionString({
      resourceGroupName: ResourceGroupName,
      iotHubName: hub,
      deviceId: gateway,
      cb: this.setConnectionString,
    });
  };

  onCopy = () => navigator.clipboard.writeText(this.state.connection).then(() => this.setState({ isCopied: true }));

  onClose = () =>
    this.setState({ connection: '', data: { tenant: '', site: '', hub: '', gateway: '' }, isCopied: false }, () =>
      this.props.handleClose(),
    );

  render() {
    const { data, connection, isCopied } = this.state;
    const { open, lists } = this.props;

    return (
      <BaseDialog open={open} onRequestClose={this.onClose} title="Retrieve Gateway Key" titleColor={theme.darkGrey}>
        <S.DialogContainer>
          <CS.NotificationText>Please make your selections to retrieve a Gateway Key</CS.NotificationText>
          {cards.map(({ name, previousCard }) => (
            <CS.CardContainer key={name}>
              <CS.CardHeader>
                <CS.CardHeaderTitle>{name !== 'connection' ? name : 'Gateway Connection'}</CS.CardHeaderTitle>
                <CS.CardHeaderIconContainer>{resourceIcons[name]}</CS.CardHeaderIconContainer>
              </CS.CardHeader>
              <S.CardContentContainer>
                {name === 'connection' ? (
                  <ConnectionField
                    onRetrieve={this.onRetrieve}
                    onCopy={this.onCopy}
                    connectionString={connection}
                    isCopied={isCopied}
                    gateway={data.gateway}
                  />
                ) : (
                  <S.Select
                    value={data[name]}
                    onChange={(e, i, value) => this.selectChange(value, name)}
                    hintText={cardFields[name].hintText}
                    disabled={previousCard && !data[previousCard]}
                  >
                    {lists[name].map(item => (
                      <S.Item
                        key={item[cardFields[name].key]}
                        value={item[cardFields[name].value]}
                        primaryText={item[cardFields[name].primaryText]}
                      />
                    ))}
                  </S.Select>
                )}
              </S.CardContentContainer>
            </CS.CardContainer>
          ))}
        </S.DialogContainer>
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ gateways, tenants, sites }) => ({
  lists: {
    tenant: tenants.list,
    site: sites.list,
    hub: gateways.tenantHubsList,
    gateway: gateways.list,
  },
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      getTenants: tenantsActions.tenantsListRequest,
      getSites: sitesActions.sitesListByTenantRequest,
      getHubs: gatewaysActions.getTenantHubsListRequest,
      getGateways: gatewaysActions.getGatewaysByHubRequest,
      getConnectionString: gatewaysActions.getDeviceConnectionStringRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeysDialog);
