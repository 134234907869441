import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import Details from './pages/Details';
import List from './pages/List';

const Documents = () => (
  <Switch>
    <Route exact path="/documents" component={List} />
    <Route exact path="/documents/details/:documentId" component={Details} />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default Documents;
