import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { actions as newSitesActions } from 'redux/newSites';
import { actions as peopleActions } from 'redux/people';
import { actions as queueActions } from 'redux/queue';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import theme from 'theme';

import ReportRules from './ReportRules';
import TaskTiming from './TaskTiming';
import ReportGrouping from './ReportGrouping';
import {
  createId,
  initialRule,
  generateInitialRules,
  generateUnitRules,
  generateItemRules,
  generateTaskTypeRules,
  generateTaskManagedByRules,
  generateTaskCompletedByRules,
  reportRules,
  initialTitle,
  checkOptions,
} from './ReportRules/helpers';
import { UserType, QR, TaskType } from './ReportRules/constants';
import { generateStartDate, generateEndDate } from './TaskTiming/helpers';
import { timePeriod } from './TaskTiming/constants';

import { generateReportGroupValues, WITHOUT_GROUPING } from './ReportGrouping/helpers';

import validate from './validator';
import * as S from './styled';

class ReportSetup extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      getJobsListRequest: PropTypes.func.isRequired,
      peopleListRequest: PropTypes.func.isRequired,
      createReportRequest: PropTypes.func.isRequired,
      editReportRequest: PropTypes.func.isRequired,
      deleteReportRequest: PropTypes.func.isRequired,
    }).isRequired,
    jobsList: PropTypes.shape().isRequired,
    handleExit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    site: PropTypes.string.isRequired,
    peopleList: PropTypes.shape().isRequired,
    reportToEdit: PropTypes.shape().isRequired,
  };

  state = {
    unitRules: generateInitialRules({ rules: this.props.reportToEdit.UnitRules, flag: 'unit' }),
    itemRules: generateInitialRules({ rules: this.props.reportToEdit.ItemRules, flag: 'item' }),
    taskTypeRules: generateInitialRules({ rules: this.props.reportToEdit.TaskTypeRules, flag: 'taskType' }),
    taskManagedByRules: generateInitialRules({
      rules: this.props.reportToEdit.TaskManagedByRules,
      flag: 'taskManagedBy',
    }),
    taskCompletedByRules: generateInitialRules({
      rules: this.props.reportToEdit.TaskCompletedByRules,
      flag: 'taskCompletedBy',
    }),
    archivedTaskTime: { isAllArchivedTasks: true },
    isErrorShown: false,
    isConfirmationDialogOpened: false,
  };

  componentDidMount() {
    this.props.actions.getJobsListRequest();
    this.props.actions.peopleListRequest();
  }

  reloadState = () => {
    this.setState({
      unitRules: generateInitialRules({
        rules: this.props.reportToEdit.UnitRules,
        flag: 'unit',
      }),
      itemRules: generateInitialRules({
        rules: this.props.reportToEdit.ItemRules,
        flag: 'item',
      }),
      taskTypeRules: generateInitialRules({
        rules: this.props.reportToEdit.TaskTypeRules,
        flag: 'taskType',
      }),
      taskManagedByRules: generateInitialRules({
        rules: this.props.reportToEdit.TaskManagedByRules,
        flag: 'taskManagedBy',
      }),
      taskCompletedByRules: generateInitialRules({
        rules: this.props.reportToEdit.TaskCompletedByRules,
        flag: 'taskCompletedBy',
      }),
    });
  };

  setInitialValue = () => {
    const { reportToEdit } = this.props;
    const initialValue = {
      'datetime-Archive': true,
      'datetime-Type': 1,
      'group-select': 0,
      'group-ignore': true,
      'subgroup-ignore': true,
      'datetime-Period': timePeriod[0].value,
    };
    if (reportToEdit.GroupBy === 4 && reportToEdit.SubGroupBy === 32) {
      initialValue['group-ignore'] = true;
      initialValue['group-select'] = 0;
    } else if (reportToEdit.GroupBy === WITHOUT_GROUPING && reportToEdit.SubGroupBy === WITHOUT_GROUPING) {
      initialValue['group-ignore'] = false;
      initialValue['group-select'] = 0;
    } else {
      initialValue['group-ignore'] = false;
      initialValue['group-select'] = 1;
      initialValue['subgroup-ignore'] = false;
    }
    if (reportToEdit.PeriodInterval === 0) {
      initialValue['datetime-Archive'] = false;
    } else {
      initialValue['datetime-Period'] = reportToEdit.PeriodInterval;
      initialValue['datetime-Type'] = reportToEdit.Period;
    }
    const data = { ...initialValue };
    return data;
  };
  componentDidUpdate(prevProps) {
    const { reportToEdit } = this.props;
    if (prevProps.reportToEdit.Id !== reportToEdit.Id) {
      this.reloadState();
    }
  }

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  onChangeHandler = (e, value, name, input) => {
    input.onChange(value);
    this.onChangeMatch(name, value);
  };

  onChangeIgnoreRules = flag => value => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], isRulesIgnored: value },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  getOptionValue = value => {
    switch (value) {
      case 'UnitQR':
        return QR;
      case 'TaskCompletedByUserType':
        return UserType;
      case 'TaskManagedByJobFunction':
        return [...this.props.jobsList];
      case 'TaskCompletedByJobFunction':
        return [...this.props.jobsList];
      case 'TaskManagedByLastName':
        return [
          ...this.props.peopleList
            .filter(item => item.UserType === 'Manager')
            .map(item => ({ Value: item.LastName, Name: item.LastName })),
        ];
      case 'TaskType':
        return TaskType;
      default:
        return QR;
    }
  };

  onSubmitForm = values => {
    const { actions, handleExit, reportToEdit } = this.props;
    const { unitRules, itemRules, taskTypeRules, taskManagedByRules, taskCompletedByRules } = this.state;
    const unitValues = generateUnitRules(values, unitRules.rules);
    const itemValues = generateItemRules(values, itemRules.rules);
    const taskTypeValues = generateTaskTypeRules(values, taskTypeRules.rules);
    const taskManagedByValues = generateTaskManagedByRules(values, taskManagedByRules.rules);
    const taskCompletedByValues = generateTaskCompletedByRules(values, taskCompletedByRules.rules);
    const rulesToSend = {
      ...unitValues,
      ...itemValues,
      ...taskTypeValues,
      ...taskManagedByValues,
      ...taskCompletedByValues,
    };
    const reportGroup = generateReportGroupValues(values);
    const valuesToSend = {
      Name: values.Name,
      ...rulesToSend,
      ...reportGroup,
    };
    if (values['datetime-Archive']) {
      valuesToSend.StartDate = new Date(Date.now());
      valuesToSend.EndDate = new Date(Date.now());
      valuesToSend.PeriodInterval = values['datetime-Period'];
      valuesToSend.Period = values['datetime-Type'];
    } else {
      valuesToSend.StartDate = generateStartDate(values);
      valuesToSend.EndDate = generateEndDate(values);
      valuesToSend.PeriodInterval = 0;
      valuesToSend.Period = 0;
    }
    if (
      !checkOptions(valuesToSend.UnitRules) ||
      !checkOptions(valuesToSend.ItemRules) ||
      !checkOptions(valuesToSend.TaskTypeRules) ||
      !checkOptions(valuesToSend.TaskManagedByRules) ||
      !checkOptions(valuesToSend.TaskCompletedByRules)
    ) {
      this.setState({ isErrorShown: true });
    } else if (reportToEdit.Name) {
      this.setState({ isErrorShown: false });
      actions.editReportRequest({ ...valuesToSend, Id: reportToEdit.Id });
      handleExit();
      this.reloadState();
    } else {
      this.setState({ isErrorShown: false });
      actions.createReportRequest(valuesToSend);
      handleExit();
      this.reloadState();
    }
  };

  closeModal = () => {
    this.reloadState();
    this.props.handleExit();
  };

  openDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: true });
  };

  closeDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  onClickDialogHandler = () => {
    const { actions, handleExit, reportToEdit } = this.props;
    actions.deleteReportRequest({ id: reportToEdit.Id });
    this.setState({ isConfirmationDialogOpened: false });
    this.reloadState();
    handleExit();
  };

  render() {
    const { open, site, reportToEdit } = this.props;
    const { isErrorShown, isConfirmationDialogOpened } = this.state;
    return (
      <Dialog
        open={open}
        onRequestClose={this.closeModal}
        title={reportToEdit?.Name ? 'Report Edit' : 'New Report Setup'}
        titleColor={theme.primaryQueue}
      >
        <S.DialogContainer>
          <S.Overlay show={false}>
            <S.Loader />
          </S.Overlay>
          <S.MainTitle>{site && site}</S.MainTitle>
          <S.FormContainer>
            <Form
              onSubmit={this.onSubmitForm}
              validate={validate}
              initialValues={
                reportToEdit?.Name
                  ? this.setInitialValue()
                  : {
                      'datetime-Archive': true,
                      'datetime-Period': timePeriod[0].value,
                      'datetime-Type': 1,
                      'group-select': 0,
                      'group-ignore': true,
                      'subgroup-ignore': true,
                    }
              }
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <S.FieldBlock>
                    <Field
                      component={S.TextField}
                      name="Name"
                      floatingLabelText="Report name"
                      initialValue={reportToEdit?.Name}
                    />
                  </S.FieldBlock>
                  <TaskTiming values={values} initialValue={reportToEdit} />
                  <S.RedTitle>Tasks Selection</S.RedTitle>
                  <S.RedText>Selections will include/exclude tasks on the report</S.RedText>
                  {reportRules.map(rule => (
                    <ReportRules
                      key={rule}
                      title={`${initialTitle[rule]} - Rules`}
                      rules={this.state[`${rule}Rules`]}
                      addOption={this.addOption}
                      removeOption={this.removeOption}
                      onChangeMatch={this.onChangeMatch}
                      isInitialValues={!!reportToEdit.Name}
                      onChangeIgnoreRules={this.onChangeIgnoreRules}
                      values={values}
                      getOptionValue={this.getOptionValue}
                      onKeyDownHandler={this.onKeyDownHandler}
                      onChangeHandler={this.onChangeHandler}
                    />
                  ))}
                  <ReportGrouping values={values} isInitialValues={!!reportToEdit.Name} initialValue={reportToEdit} />
                  {isErrorShown && (
                    <S.ErrorContainer>
                      Please add at least one rule or select &quot;Ignore rules&quot; to disable rules
                    </S.ErrorContainer>
                  )}
                  <ActionsContainer>
                    {reportToEdit.Name && (
                      <ActionButton type="button" label="DELETE REPORT" onClick={this.openDeleteDialog} />
                    )}
                    <ActionButton type="submit" isNext label={'SAVE REPORT'} />
                  </ActionsContainer>
                  <ConfirmationDialog
                    label="Yes"
                    maxWidth={'80%'}
                    text={
                      <S.ConfirmationContainer>
                        <S.ConfirmationWarning>
                          This action cannot be undone,
                          <br /> are you sure?
                        </S.ConfirmationWarning>
                      </S.ConfirmationContainer>
                    }
                    open={isConfirmationDialogOpened}
                    onClick={this.onClickDialogHandler}
                    handleClose={this.closeDeleteDialog}
                  />
                </form>
              )}
            />
          </S.FormContainer>
        </S.DialogContainer>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ newSites, people }) => ({
  jobsList: newSites.jobsList,
  peopleList: people.list,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...newSitesActions,
      peopleListRequest: peopleActions.peopleListRequest,
      createReportRequest: queueActions.createReportRequest,
      editReportRequest: queueActions.editReportRequest,
      deleteReportRequest: queueActions.deleteReportRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetup);
