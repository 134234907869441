import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import { EditIcon, TasksModuleIcon, CloseIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

class TypesInfoDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    putTaskTypes: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,
        Value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    title: PropTypes.string.isRequired,
  };

  state = {
    currentId: '',
    currentValue: '',
  };

  editField = (id, index) => {
    this.setState({
      currentId: id,
      disableInput: false,
      currentValue: this.props.data[index].Value,
    });
  };
  saveValue = (id, currentId, index) => {
    const payload = {
      id,
      value: this.state.currentValue.trim(),
    };
    this.props.putTaskTypes(payload);
    this.closeInput(id, currentId, index);
  };

  onChange = value => this.setState({ currentValue: value });

  closeInput = (fieldId, currentId, index) => {
    if (fieldId === currentId) {
      this.setState({
        currentId: '',
        currentValue: this.props.data[index].Value,
      });
    }
  };

  render() {
    const { handleClose, open, data, title } = this.props;
    const { currentId, currentValue } = this.state;
    return (
      <BaseDialog onRequestClose={handleClose} open={open} title={title}>
        <S.Container>
          <S.Cell>
            <S.Head>Task Type:</S.Head>
            {data.map(elem => (
              <S.TaskName key={elem.Id} modif>
                {elem.Name}
              </S.TaskName>
            ))}
          </S.Cell>
          <S.Cell>
            <S.Head>User Inputs</S.Head>
            {data.map((elem, index) => (
              <S.InputContainer key={elem.Id}>
                <S.TextField
                  ref={this.registerInput}
                  value={(currentId === elem.Id && currentValue) || (currentId !== elem.Id && elem.Value) || ''}
                  onChange={(event, value) => this.onChange(value)}
                  disabled={elem.Id !== currentId}
                  underlineShow={currentId === elem.Id}
                  name={elem.Id}
                />
                {currentId !== elem.Id ? (
                  <ButtonIcon onClick={() => this.editField(elem.Id, index)}>
                    <EditIcon />
                  </ButtonIcon>
                ) : (
                  <>
                    <ButtonIcon disabled={!currentValue} onClick={() => this.saveValue(elem.Id, currentId, index)}>
                      <TasksModuleIcon />
                    </ButtonIcon>
                    <ButtonIcon onClick={() => this.closeInput(elem.Id, currentId, index)}>
                      <CloseIcon />
                    </ButtonIcon>
                  </>
                )}
              </S.InputContainer>
            ))}
          </S.Cell>
        </S.Container>
      </BaseDialog>
    );
  }
}

export default TypesInfoDialog;
