/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const InputFile = ({ files, onDrop, value }) => (
  <S.UploadImageRow>
    <input accept="image/*" files={files} onChange={onDrop} type="file" value={value} />
  </S.UploadImageRow>
);

InputFile.propTypes = {
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onDrop: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputFile;
