import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ErrorRoutePage from 'components/ErrorRoutePage';

import PartsList from './page/PartsList';

const PurgeParts = () => (
  <Switch>
    <Route exact path="/settings/purgeparts/:type" component={PartsList} />
    <Redirect to="/settings/purgeparts/parts" />
    <Route path="/*" component={ErrorRoutePage} />
  </Switch>
);

export default PurgeParts;
