import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { periodToggle, queueToggle, attentionTasksToggle } from 'configs/toggles';
import { QueueItem } from 'configs/propTypes';
import { actions as authActions } from 'redux/auth';
import { getSearchParam } from 'helpers';

import { actions as queueActions } from 'redux/queue';
import { openErrorDialog } from 'redux/errorHandler';

import { ContentContainer } from 'components/Layout/Containers';
import WarningModal from 'components/Dialogs/Warning';
import { findSite } from 'components/Subheader/helpers';
import Period from 'components/Period';
import Subheader from 'components/Subheader';
import { BackIcon } from 'components/Layout/Icons';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import ConfirmationCommitDialog from './ConfirmationCommitDialog';
import Footer from './Footer';
import List from './List';
import Toolbar from './Toolbar';
import theme from 'theme';

class Overview extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      openErrorDialog: PropTypes.func.isRequired,
      requestQueueList: PropTypes.func.isRequired,
      clearStateError: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    lists: PropTypes.shape({
      day: PropTypes.shape({
        entities: PropTypes.objectOf(PropTypes.arrayOf(QueueItem)),
        keys: PropTypes.arrayOf(PropTypes.string),
        ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      }),
      week: PropTypes.shape({
        entities: PropTypes.objectOf(PropTypes.arrayOf(QueueItem)),
        keys: PropTypes.arrayOf(PropTypes.string),
        ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      }),
      past: PropTypes.shape({
        entities: PropTypes.objectOf(PropTypes.arrayOf(QueueItem)),
        keys: PropTypes.arrayOf(PropTypes.string),
        ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      }),
      all: PropTypes.shape({
        entities: PropTypes.objectOf(PropTypes.arrayOf(QueueItem)),
        keys: PropTypes.arrayOf(PropTypes.string),
        ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      }),
      parts: PropTypes.shape({
        entities: PropTypes.objectOf(PropTypes.arrayOf(QueueItem)),
        keys: PropTypes.arrayOf(PropTypes.string),
        ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      }),
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    selectedSite: PropTypes.string.isRequired,
    commitMessage: PropTypes.string.isRequired,
    sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
  };

  state = {
    confirmationDialogOpened: false,
    alertDialogOpened: false,
    warningDialogOpen: false,
    confirmationMode: 'commit',
    params: {
      rangeEnd: '',
      rangeStart: '',
      selectedUnitId: '',
      isRequireAttentionTasks: false,
    },
    queueType: queueToggle[0],
  };

  componentDidMount() {
    const { actions, sitesAvailableToUserFullInfo } = this.props;

    const siteFromParams = getSearchParam('site');

    if (siteFromParams) {
      authActions.onSiteChange({ checkedSite: siteFromParams, sitesCollection: sitesAvailableToUserFullInfo });
    }

    actions.requestQueueList({ rangeStart: this.props.match.params.date });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.commitMessage === 'unit contains demo tasks. Unit cannot be committed.' &&
      prevProps.commitMessage !== this.props.commitMessage
    ) {
      this.setState({ alertDialogOpened: true });
    }
  }
  componentWillReceiveProps(nextProps) {
    const { actions, match } = this.props;
    if (match.params.date !== nextProps.match.params.date) {
      actions.requestQueueList({ rangeStart: nextProps.match.params.date });
    }

    if (nextProps.error) {
      actions.openErrorDialog(nextProps.error, 'Error');
      actions.clearStateError();
    }
  }

  onCommitClick = unitId => {
    const { date, period } = this.props.match.params;
    const rangeStart = moment(date).format('MM/DD/YYYY');
    let rangeEnd;

    if (period === 'day') {
      rangeEnd = rangeStart;
    } else if (period === 'week') {
      rangeEnd = moment(date).add(6, 'd').format('MM/DD/YYYY');
    }

    this.openConfirmationDialog('commit', unitId, rangeStart, rangeEnd);
  };

  onExportClick = unitId => {
    const { date, period } = this.props.match.params;
    const rangeStart = moment(date).format('MM-DD-YYYY');
    const isRequireAttentionTasks = period === 'all' || period === 'parts';
    const isRequiredAttentionTasksParts = period === 'parts';
    let rangeEnd;

    if (period === 'day') {
      rangeEnd = rangeStart;
    } else if (period === 'week') {
      rangeEnd = moment(date).add(6, 'd').format('MM-DD-YYYY');
    }

    this.openConfirmationDialog(
      'export',
      unitId,
      rangeStart,
      rangeEnd,
      isRequireAttentionTasks,
      isRequiredAttentionTasksParts,
    );
  };

  onNext = () => {
    const { date, period } = this.props.match.params;
    const rangeStart = moment(date);

    let newDate;

    if (period === 'day') {
      newDate = rangeStart.add(1, 'd').format('YYYY-MM-DD');
    }
    if (period === 'week') {
      newDate = rangeStart.add(1, 'w').format('YYYY-MM-DD');
    }

    this.props.history.push(`../${newDate}/${period}`);
  };

  onPrev = () => {
    const { date, period } = this.props.match.params;
    const rangeStart = moment(date);

    let newDate;

    if (period === 'day') {
      newDate = rangeStart.subtract(1, 'd').format('YYYY-MM-DD');
    }
    if (period === 'week') {
      newDate = rangeStart.subtract(1, 'w').format('YYYY-MM-DD');
    }

    this.props.history.push(`../${newDate}/${period}`);
  };

  onUnitItemClick = item => {
    const { date, period } = this.props.match.params;
    const assigneeId = item.AssigneeId || 'allusers';
    this.props.history.push(`/queue/${date}/${period}/${item.UnitId}/category/${item.AssigneeName}/${assigneeId}`);
  };

  closeConfirmationDialog = () => {
    this.setState({ confirmationDialogOpened: false, selectedUnitId: '' });
  };

  openConfirmationDialog = (
    confirmationMode,
    selectedUnitId,
    rangeStart,
    rangeEnd,
    isRequireAttentionTasks,
    isRequiredAttentionTasksParts,
  ) => {
    this.setState({
      confirmationDialogOpened: true,
      confirmationMode,
      params: { rangeEnd, rangeStart, selectedUnitId, isRequireAttentionTasks, isRequiredAttentionTasksParts },
    });
  };

  openWarningDialog = () => this.setState({ warningDialogOpen: true });
  closeWarningDialog = () => this.setState({ warningDialogOpen: false });
  closeAlertDialog = () => this.setState({ alertDialogOpened: false });
  periodHandler = value => {
    const rangeStart = moment().format('YYYY-MM-DD');
    this.props.history.push(`../${rangeStart}/${value.key}`);
  };

  typeHandler = queueType => {
    const { push } = this.props.history;

    if (queueType.key === 'archive') {
      push('/queue/export');
    } else if (queueType.key === 'trigger') {
      push(`/queue/trigger/${moment().format('YYYY-MM-DD')}`);
    }
  };

  goBack = () => this.props.history.push('/queue');

  render() {
    const { lists, match, selectedSite, sitesAvailableToUserFullInfo, loading } = this.props;
    const { date, period } = match.params;
    const { confirmationDialogOpened, confirmationMode, params, queueType, warningDialogOpen, alertDialogOpened } =
      this.state;

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const isAttentionTasks = period === 'all' || period === 'parts';
    const rangeStart = moment(date);
    const unitNames = lists[period].keys.sort();
    const units = lists[period].entities;
    const countTasksArray = lists.past.ids;

    periodToggle[2].total = countTasksArray.reduce(reducer, 0);

    return [
      <ContentContainer key="Queue Overview">
        <Subheader
          title={isAttentionTasks ? 'Attention' : 'Progress'}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.goBack,
              hint: 'Back',
            },
          ]}
        />
        <Toolbar
          location={findSite(sitesAvailableToUserFullInfo, selectedSite)}
          periodConfig={isAttentionTasks ? attentionTasksToggle : periodToggle}
          periodHandler={this.periodHandler}
          selectedPeriod={period}
          selectedType={queueType.key}
          typeConfig={queueToggle}
          typeHandler={this.typeHandler}
          withoutTypeConfig={isAttentionTasks}
        />
        {!isAttentionTasks && <Period type={period} startRange={rangeStart} />}
        {!loading && (
          <List
            onCommitUnit={this.onCommitClick}
            onExportUnit={this.onExportClick}
            onUnitItemClick={this.onUnitItemClick}
            units={units}
            unitsNames={unitNames}
            isAttentionTasks={isAttentionTasks}
          />
        )}
      </ContentContainer>,
      !isAttentionTasks && <Footer key="Queue Footer" onPrev={this.onPrev} onNext={this.onNext} period={period} />,
      <ConfirmationCommitDialog
        key="Queue confirmation dialog"
        mode={confirmationMode}
        onRequestClose={this.closeConfirmationDialog}
        opened={confirmationDialogOpened}
        params={params}
      />,
      <WarningModal
        key="Queue warning dialog"
        handleClose={this.closeWarningDialog}
        open={warningDialogOpen}
        text="Sorry, this feature is currently
        not available. Please contact
        the Incheq team for activation
        and implementation."
        onSubmit={this.closeWarningDialog}
        title="Notification"
      />,
      <ConfirmationDialog
        isNext={false}
        handleClose={this.closeAlertDialog}
        title={'Alert'}
        titleColor={theme.redButton}
        text={'Unit contains demo tasks. Unit cannot be committed.'}
        onClick={this.closeAlertDialog}
        label={'Close'}
        open={alertDialogOpened}
      />,
    ];
  }
}

const mapStateToProps = ({ auth, queue }) => ({
  error: queue.error,
  lists: queue.queueList,
  loading: queue.loading,
  selectedSite: auth.selectedSite,
  commitMessage: queue.commitMessage,
  sitesAvailableToUserFullInfo: auth.user.sitesAvailableToUserFullInfo,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      clearStateError: queueActions.stateErrorClear,
      openErrorDialog,
      requestQueueList: queueActions.fetchQueueListRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
