import styled from 'styled-components';

import { ButtonFlat } from 'components/Layout/Buttons';
import { ButtonIcon } from '../Layout/Buttons';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;
export const Overlay = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export const DragDropContainer = styled.div`
  height: calc(100% - 56px);
  overflow-y: auto;
`;

export const Separator = styled.div`
  width: 99%;
  height: 1px;
  align-self: center;
  background-color: black;
`;

export const SaveButton = styled(ButtonFlat).attrs({
  label: 'Save',
  backgroundColor: ({ theme, disabled }) => (disabled ? theme.primaryGrey : theme.primaryGreen),
})`
  width: 30% !important;
  align-self: center;
  margin-top: 20px !important;
  span {
    color: ${({ theme }) => theme.primaryBlack} !important;
    text-transform: unset !important;
    font-weight: 500 !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EntryNumberTask = styled.div`
  display: flex;
  justify-content: center;
  min-width: 20px;
  padding: 15px 0 0 25px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  padding-left: 15px;
`;

export const EntryNumberItem = styled.div`
  display: flex;
  padding-top: 18px;
  font-size: 16px;
  font-weight: bold;
`;

export const ListLockContainer = styled.div`
  display: flex;
  padding-left: 15px;
`;

export const ListLockTitle = styled.div`
  padding-top: 20px;
`;

export const SeparatorContainer = styled.div`
  padding-left: 25px;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  order: 999;
  margin-left: auto;
  padding-right: 15px;
`;

export const InfoTooltip = styled(ButtonIcon).attrs({
  tooltipStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    minWidth: '150px',
    minHeight: '30px',
    top: '40px',
    right: '0px',
    left: '-110px',
  },
})``;
