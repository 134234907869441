import { createSelector } from 'reselect';

const getIndustries = state => state.tenants.industryList;
const getMeteringGroups = state => state.meteringGroups.list;

export const addIndustryName = createSelector([getIndustries, getMeteringGroups], (industries, groups) =>
  groups.map(({ IndustryID, ...rest }) => ({
    ...rest,
    IndustryID,
    IndustryName: industries.find(({ Id }) => Id === IndustryID)?.Name,
  })),
);

export const selector = () => {};
