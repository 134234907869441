import styled from 'styled-components';

import { SettingsWhitesIcon } from 'components/Layout/Icons';

export const IconSettingsWhites = styled(SettingsWhitesIcon).attrs({
  color: ({ theme }) => theme.primaryGrey,
})``;

export const ConfirmationContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ConfirmationWarning = styled.div`
  font-size: 18px;
  margin: 0 15px;
`;
