import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openErrorDialog } from 'redux/errorHandler';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as itemActions } from 'redux/item';
import { actions as unitActions } from 'redux/unit';
import { getItemsIds } from 'redux/unit/selectors';
import { getQRSource } from 'http/unit';

import AddEditTaskTemplateDialog from '../ItemsList/AddEditTaskTemplateDialog';
import TopBar from '../TopBar';
import http from '../../../../../http';
import DragItemsListDialog from '../../UnitsList/DragItemsListDialog';
import DublicateDialog from '../DublicateDialog';
import Footer from '../Footer';
import { SequenceSetupIcon } from '../styled';

import Tasks from './Tasks';
import Details from './Details';
import * as S from './styled';

let barcode = '';
let timestamp = '';

const getImageSource = (itemId, barcodeValue) => {
  if (barcodeValue !== barcode) {
    timestamp = Date.now();
    barcode = barcodeValue;
  }

  return (
    `${http.defaults.baseURL}/ItemBarcode/Get` +
    `?itemId=${itemId}&siteId=${http.defaults.siteId}&width=200&height=200&inline=true&_dc=${timestamp}`
  );
};

class ItemDetails extends Component {
  static propTypes = {
    addTaskRequest: PropTypes.func.isRequired,
    deletePdfRequest: PropTypes.func.isRequired,
    deletePictureRequest: PropTypes.func.isRequired,
    deleteTaskRequest: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    editTaskRequest: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    fetchItemDetailsRequest: PropTypes.func.isRequired,
    requestFetchUnitItems: PropTypes.func.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,
    itemsIds: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    pdfs: PropTypes.array.isRequired,
    pictures: PropTypes.array.isRequired,
    tasks: PropTypes.array.isRequired,
    uploadPdfRequest: PropTypes.func.isRequired,
    uploadPictureRequest: PropTypes.func.isRequired,
    dublicateTaskRequest: PropTypes.func.isRequired,
    updateUnitItemsTasks: PropTypes.func.isRequired,
  };

  state = {
    uploadPdfDialogOpened: false,
    addEditTaskDialogOpened: false,
    taskToEdit: {},
    saveAndAddTaskSelected: false,
    withSaveContinueTaskModal: true,
    dublicateDialogOpened: false,
    dublicationTaskType: '',
    dublicationTaskId: '',
    dublicationTask: {},
    dragFullListDialogOpened: false,
    type: 'tasks',
    searchData: '',
  };

  componentDidMount() {
    const {
      match: {
        params: { itemId },
      },
      fetchItemDetailsRequest,
    } = this.props;
    fetchItemDetailsRequest(itemId);
  }

  componentWillReceiveProps(nextProps) {
    const newTasks = nextProps.tasks.filter(x => !this.props.tasks.includes(x));

    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }

    if (nextProps.match.params.itemId !== this.props.match.params.itemId) {
      this.props.fetchItemDetailsRequest(nextProps.match.params.itemId);
    }

    if (newTasks.length === 1 && this.props.tasks.length && this.state.dublicateDialogOpened) {
      const { details } = this.props;
      const { Id: itemId } = details;

      this.setState({ dublicateDialogOpened: false }, () => {
        if (Object.keys(newTasks).length) {
          this.openAddEditTaskDialog(itemId, newTasks[0]);
        }
      });
    }
  }

  goToItemsList = () => {
    const {
      details,
      history,
      match: { params },
    } = this.props;

    this.props.requestFetchUnitItems({ unitId: details.UnitId });

    history.push(`/units/items/${params.unitId}`);
  };

  uploadItemPicture = files => {
    this.props.uploadPictureRequest({
      itemId: this.props.details.Id,
      files,
    });
  };

  onDeleteTask = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Task? This cannot be undone!',
      this.onDeleteTaskApprove,
      'Delete?',
    );
  };

  onDeleteTaskApprove = () => {
    this.closeAddEditTaskDialog();

    const { task } = this.state.taskToEdit;
    this.props.deleteTaskRequest({
      itemId: this.props.details.Id,
      taskId: task.Id,
    });
  };

  openAddEditTaskDialog = (itemId, task) => {
    let data;
    if (task?.Id) {
      data = this.props.tasks.find(item => item.Id === task.Id);
    }
    this.setState({
      addEditTaskDialogOpened: true,
      taskToEdit: {
        task: task.Id ? data : null,
        itemId,
      },
      withSaveContinueTaskModal: !task.Id,
    });
  };

  onDeleteItemPdf = (pdfId, pdfName) => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this PDF?',
      () =>
        this.props.deletePdfRequest({
          pdfId,
          pdfName,
          itemId: this.props.details.Id,
        }),
      'Delete PDF?',
    );
  };

  onDeleteItemPicture = (pictureId, pictureName) => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Photo?',
      () =>
        this.props.deletePictureRequest({
          pictureId,
          pictureName,
          itemId: this.props.details.Id,
        }),
      'Delete Photo?',
    );
  };

  uploadItemPdf = files => {
    this.props.uploadPdfRequest({
      itemId: this.props.details.Id,
      files,
    });
  };

  closeAddEditTaskDialog = () => this.setState({ addEditTaskDialogOpened: false });

  handleCloseAddEditTaskDialog = () =>
    this.setState({ addEditTaskDialogOpened: false }, () => {
      this.openAddEditTaskDialogOnModalClose();
    });

  openAddEditTaskDialogOnModalClose = () => {
    const { saveAndAddTaskSelected } = this.state;

    if (saveAndAddTaskSelected) {
      setTimeout(() => {
        // need time for modal animations
        this.setState({
          saveAndAddTaskSelected: false,
          addEditTaskDialogOpened: true,
        });
      }, 500);
    } else {
      this.setState({ withSaveContinueTaskModal: false });
    }
  };

  addTask = data => {
    this.props.addTaskRequest({
      itemId: this.props.details.Id,
      data,
      onSuccess: this.handleCloseAddEditTaskDialog,
    });
  };

  editTask = data => {
    this.props.editTaskRequest({
      itemId: this.props.details.Id,
      data,
      onSuccess: this.closeAddEditTaskDialog,
    });
  };

  handleSaveAndAddTask = () =>
    this.setState({
      withSaveContinueTaskModal: true,
      saveAndAddTaskSelected: true,
    });

  onNextItem = () => {
    const {
      history: { push },
      match: {
        params: { itemId },
      },
      itemsIds,
    } = this.props;
    const nextItemIndex = itemsIds.indexOf(itemId) + 1;
    const index = nextItemIndex > itemsIds.length - 1 ? 0 : nextItemIndex;
    push(`../details/${itemsIds[index]}`);
  };

  onPrevItem = () => {
    const {
      history: { push },
      match: {
        params: { itemId },
      },
      itemsIds,
    } = this.props;
    const prevItemIndex = itemsIds.indexOf(itemId) - 1;
    const index = prevItemIndex < 0 ? itemsIds.length - 1 : prevItemIndex;
    push(`../details/${itemsIds[index]}`);
  };

  toggleDublicateDialog = task => {
    this.setState(prevState => ({
      dublicateDialogOpened: !prevState.dublicateDialogOpened,
      dublicationTaskType: task.RuleType || '',
      dublicationTaskId: task.Id || '',
      dublicationTask: task || {},
    }));
  };

  openDragFullListDialog = () => {
    this.setState(prevState => ({
      dragFullListDialogOpened: !prevState.dragFullListDialogOpened,
    }));
  };

  prepareTasksDragListContent = () => {
    const { tasks, details } = this.props;

    return [
      {
        content: details.Name,
        id: details.Id,
        isStrictOrder: details.IsStrictOrder,
        items: tasks.map((task, i) => ({
          id: task.Id,
          content: task.RuleType,
          sequence: i + 1,
          tooltipTitle: task.Name,
        })),
      },
    ];
  };

  typeHandler = status => {
    this.setState({ type: status.key });
  };

  exportQR = () => {
    const {
      match: { params },
    } = this.props;
    getQRSource(params.itemId);
  };

  searchInList = event => {
    this.setState({
      searchData: event.target.value,
    });
  };

  rightButtons = [
    {
      icon: <SequenceSetupIcon />,
      hint: 'Tasks',
      handler: this.openDragFullListDialog,
      tooltipStyles: { marginTop: '-10px' },
    },
  ];

  render() {
    const {
      addEditTaskDialogOpened,
      taskToEdit,
      withSaveContinueTaskModal,
      dragFullListDialogOpened,
      dublicationTaskType,
      dublicateDialogOpened,
      dublicationTaskId,
      type,
      searchData,
    } = this.state;
    const { details, pdfs, pictures, tasks, dublicateTaskRequest, updateUnitItemsTasks } = this.props;
    const { Id: itemId } = details;

    return (
      <S.Container>
        <TopBar
          type={type}
          key="Item Details Bar"
          searchData={searchData}
          searchInList={this.searchInList}
          rightButtons={this.rightButtons}
          title={
            <>
              <S.RedTitle>{details.Name}</S.RedTitle>
              <S.UnitName>{details.UnitName}</S.UnitName>
            </>
          }
          goBack={this.goToItemsList}
        />
        {type === 'tasks' ? (
          <Tasks
            itemId={details.Id}
            onAddEditTaskTemplateClick={this.openAddEditTaskDialog}
            tasks={tasks}
            onCopyTask={this.toggleDublicateDialog}
            openDragFullListDialog={this.openDragFullListDialog}
            type={type}
            typeHandler={this.typeHandler}
            searchData={searchData}
          />
        ) : (
          <Details
            uploadUnitPdf={this.uploadItemPdf}
            pdfs={pdfs}
            exportUnitQR={this.exportQR}
            pictures={pictures}
            item={details}
            type={type}
            getImageSource={getImageSource}
            onDeleteUnitPicture={this.onDeleteItemPicture}
            uploadUnitPicture={this.uploadItemPicture}
            typeHandler={this.typeHandler}
            onDeleteUnitPdf={this.onDeleteItemPdf}
            exportUnitPdf={this.uploadItemPicture}
          />
        )}
        <Footer key="Item Details Footer" label="Item" onNext={this.onNextItem} onPrev={this.onPrevItem} />
        <AddEditTaskTemplateDialog
          BigButtons
          handleClose={this.closeAddEditTaskDialog}
          handleDelete={this.onDeleteTask}
          handleSaveContinue={this.handleSaveAndAddTask}
          open={addEditTaskDialogOpened}
          onSubmit={taskToEdit.task ? this.editTask : this.addTask}
          saveContinueLabel="Save and Add a Task"
          title={taskToEdit.task ? 'Edit Task' : 'Add Task'}
          task={taskToEdit.task || undefined}
          withSaveContinue={withSaveContinueTaskModal}
          withGreenButton={!taskToEdit.task}
          withGreenBorder={!taskToEdit.task}
          withSubmit={!taskToEdit.task}
          isNewDeleteButton={!!taskToEdit.task}
          isNewSubmitButton={!!taskToEdit.task}
          gapValue={taskToEdit.task ? '20px' : '5px'}
          hasPadding
        />
        <DublicateDialog
          open={dublicateDialogOpened}
          dublicationTaskType={dublicationTaskType}
          handleClose={this.toggleDublicateDialog}
          itemId={itemId}
          taskId={dublicationTaskId}
          dublicateTaskRequest={dublicateTaskRequest}
        />
        {tasks.length && dragFullListDialogOpened ? (
          <DragItemsListDialog
            key="DragItemsList Dialog"
            handleClose={this.openDragFullListDialog}
            units={this.prepareTasksDragListContent()}
            onSubmit={values => updateUnitItemsTasks({ values, itemId })}
            open={dragFullListDialogOpened}
            listId="ItemsTasks"
            title="Sequence Setup (drag tasks to order)"
            commonLockHide
          />
        ) : null}
      </S.Container>
    );
  }
}

const mapStateToProps = ({ item: { error, details, pictures, tasks, pdfs }, unit }) => ({
  details,
  error,
  itemsIds: getItemsIds(unit),
  unit: unit.unitData,
  pdfs,
  pictures,
  tasks,
});

export default connect(mapStateToProps, {
  ...itemActions,
  requestFetchUnitItems: unitActions.fetchUnitItemsRequest,
  openErrorDialog,
  openConfirmationDialog,
  updateUnitItemsTasks: unitActions.updateTasksListRequest,
})(ItemDetails);
