import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import { HealthIcon } from 'components/Layout/Icons';

import theme from 'theme';

import * as S from './styled';

const SuccessDialog = ({ open, onClose }) => (
  <BaseDialog open={open} onRequestClose={onClose} title="Success" titleColor={theme.signUpColor}>
    <S.DialogContainer>
      <S.DialogText>Thank you!</S.DialogText>
      <S.DialogText>
        The Incheq servers are crunching away
        <br /> to setup your account!
      </S.DialogText>
      <S.DialogText bold>You will receive an email as soon as it is ready.</S.DialogText>
      <S.IconContainer>
        <HealthIcon style={{ width: '40px', height: '40px' }} />
      </S.IconContainer>
    </S.DialogContainer>
    <S.CloseButtonContainer>
      <S.CloseButton type="button" onClick={onClose}>
        Close
      </S.CloseButton>
    </S.CloseButtonContainer>
  </BaseDialog>
);

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessDialog;
