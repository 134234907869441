/* eslint-disable no-tabs */
import styled from 'styled-components';
import { FieldText } from 'components/Layout/Inputs';

export const Container = styled.div`
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
`;

export const TextField = styled(FieldText)`
  margin: 0;
`;

export const TaskName = styled.div`
  font-weight: ${({ modif }) => modif && 'bold'};
  padding: 15px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const InputContainer = styled.div`
  min-width: 278px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Head = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.primaryTasks};
  padding: 8px 0;
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
`;
