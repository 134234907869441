import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

const UpgradeInfoSuccessDialog = ({ open, onClose, isActivator }) => (
  <BaseDialog
    open={open}
    onRequestClose={onClose}
    title={isActivator ? 'Confirmation' : 'Success'}
    titleColor={isActivator ? theme.mainGreen : theme.signUpColor}
  >
    <S.DialogContainer>
      {!isActivator ? (
        <>
          <S.DialogText>Thank you!</S.DialogText>
          <S.DialogText>Your request is successfully submitted.</S.DialogText>
          <S.DialogText>We&apos;ll notify you when it&apos;s processed.</S.DialogText>
        </>
      ) : (
        <>
          <S.DialogText>Your upgrade request has been sent!</S.DialogText>
          <ActionsContainer>
            <ActionButton label="Next" isNext onClick={onClose} />
          </ActionsContainer>
        </>
      )}
    </S.DialogContainer>
  </BaseDialog>
);

UpgradeInfoSuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isActivator: PropTypes.bool,
};

UpgradeInfoSuccessDialog.defaultProps = {
  isActivator: false,
};

export default UpgradeInfoSuccessDialog;
