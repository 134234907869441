import styled from 'styled-components';

import { ButtonIcon } from 'components/Layout/Buttons';

export const FormContainer = styled.div`
  padding: 0 10px 20px 30px;
`;

export const TenantName = styled.h1`
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  font-size: 20px;
`;

export const IndustryName = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
  margin-bottom: 20px;
`;

export const SelectContainer = styled.div`
  width: 60%;
`;

export const SelectTierLabel = styled.label`
  display: block;
  color: ${({ theme }) => theme.modalsContentColor};
`;

export const TiersSettingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const FormCardContainer = styled.div`
  width: inherit;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 2px;
  margin: 0 20px 20px 0;
`;

export const CardHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 0 0;
  background-color: ${({ theme }) => theme.tiersColor};
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  font-weight: 500;
  padding-left: 20px;
`;

export const RecentRequestHeader = styled(CardHeader)`
  background-color: ${({ theme }) => theme.signUpColor};
`;

export const CardContentContainer = styled.div`
  padding: 20px 20px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardContentTitle = styled.div`
  height: 20px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: bold;
  align-items: center;
`;

export const ResetToTierContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  align-items: center;
  cursor: pointer;
`;

export const ResetButton = styled(ButtonIcon)`
  & svg {
    fill: ${({ theme }) => theme.primaryRed} !important;
    width: 30px !important;
    height: 30px !important;
  }
`;

export const TiersSettingsList = styled.ul`
  list-style: none;
  margin: 30px 0;
  padding: 0;
`;

export const TierSettingsFieldContainer = styled.li`
  display: flex;
  align-items: center;
  ${({ field }) => field === 'TasksCharge' && { marginTop: '25px' }};
  margin-bottom: 10px;
`;

export const TierSettingsField = styled.div`
  width: 65px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${({ theme, field }) => (field === 'TotalCharge' ? `${theme.primaryLists}80` : theme.primaryWhite)};
  text-align: center;
  border: ${({ theme }) => `1px solid ${theme.primaryGrey}`};
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 18px;
  font-weight: bold;
`;

export const TierFieldLabel = styled.span`
  color: ${({ theme }) => theme.modalsContentColor};
  margin-left: 50px;
`;

export const AdditionalServicesFieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div:last-child {
    width: 48% !important;
  }
`;

export const CommonTotalValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0 40px;
`;

export const CommonTotalFieldValue = styled(TierSettingsField)`
  width: 65px;
  height: 38px;
  background-color: ${({ theme }) => theme.tiersColor};
  color: ${({ theme }) => theme.primaryWhite};
`;

export const RecentInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.modalsContentColor};
  font-weight: 500;
  margin: 0 0 5px -20px;
`;

export const EmailButton = styled(ButtonIcon)`
  padding: 0 !important;
  width: 20px !important;
  height: 20px !important;
  & svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const DeleteRequestButton = styled.button`
  width: 100%;
  height: 40px !important;
  margin: 30px 0 0 -20px;
  background-color: ${({ theme }) => theme.primaryRed};
  border: none;
  color: ${({ theme }) => theme.primaryWhite};
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
`;
