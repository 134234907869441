import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const Container = styled.div`
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
`;

export const Dropdown = styled.div`
  overflow: hidden;
`;

export const TooltipList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const TooltipListItem = styled.li``;

export const SelectFieldWidth = {
  width: '100%',
};
