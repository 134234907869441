import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import CustomNumberInputField from 'components/Forms/CustomNumberInputField';
import { TextareaField } from 'components/Layout/Textarea';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import * as S from './styled';

const demoTenantsMaxValues = [
  {
    name: 'DemoTenantMaxSitesEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Sites </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'DemoTenantMaxItemsEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Items </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'DemoTenantMaxSensorsEach',
    label: (
      <S.Label>
        Max <S.BoldLabel>Sensors </S.BoldLabel>each
      </S.Label>
    ),
  },
  {
    name: 'DemoTenantMaxTimeMonths',
    label: (
      <S.Label>
        Max <S.BoldLabel>Time (Months)</S.BoldLabel>
      </S.Label>
    ),
  },
];

const SystemLimitsForm = ({ form, currentTenantsCount }) => (
  <S.FormContainer>
    <S.FormCardContainer>
      <S.DemoTenantsHeader>Demo Tenants</S.DemoTenantsHeader>
      <S.CardContentContainer>
        <S.CurrentCountContainer>
          <S.BoldLabel>Current</S.BoldLabel>
          <S.CurrentCount>{currentTenantsCount.DemoTenantCurrentCount}</S.CurrentCount>
        </S.CurrentCountContainer>
        <CustomNumberInputField
          name="DemoTenantMaximumCount"
          change={form.change}
          labelComponent={<S.BoldLabel>Maximum</S.BoldLabel>}
          labelPosition="top"
          size="large"
        />
        <S.TenantMaxAccountValuesContainer>
          {demoTenantsMaxValues.map(({ name, label }) => (
            <CustomNumberInputField key={name} name={name} change={form.change} labelComponent={label} />
          ))}
        </S.TenantMaxAccountValuesContainer>
      </S.CardContentContainer>
    </S.FormCardContainer>
    <S.FormCardContainer>
      <S.AllTenantsHeader>All Tenants</S.AllTenantsHeader>
      <S.AllTenantsContentContainer>
        <S.AllTenantsCurrentCountContainer>
          <S.BoldLabel>Current</S.BoldLabel>
          <S.CurrentCount>{currentTenantsCount.AllTenantCurrentCount}</S.CurrentCount>
        </S.AllTenantsCurrentCountContainer>
        <CustomNumberInputField
          name="AllTenantsMaximumCount"
          change={form.change}
          labelComponent={<S.BoldLabel>Maximum</S.BoldLabel>}
          labelPosition="top"
          size="large"
        />
      </S.AllTenantsContentContainer>
    </S.FormCardContainer>
    <Field
      component={TextareaField}
      name="LimitMessage"
      floatingLabelText="Limits Reached Message (both user and Incheq staff)"
      rows={5}
    />
    <Field
      component={TextareaField}
      name="DemoLimitMessage"
      floatingLabelText="Demo Time Limit Reached Message"
      rows={5}
    />
    <Field component={TextFieldAdapter} name="EmailForUpgrade" floatingLabelText="Email for Upgrade Requests" />
  </S.FormContainer>
);

SystemLimitsForm.propTypes = {
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
  }).isRequired,
  currentTenantsCount: PropTypes.shape({
    AllTenantCurrentCount: PropTypes.number,
    DemoTenantCurrentCount: PropTypes.number,
  }).isRequired,
};

const SystemLimits = withInputModal(SystemLimitsForm);

const SystemLimitsDialog = props => <SystemLimits initialValues={props.systemLimits} {...props} />;

SystemLimitsDialog.propTypes = {
  systemLimits: PropTypes.shape({
    DemoTenantMaximumCount: PropTypes.number,
    DemoTenantMaxSitesEach: PropTypes.number,
    DemoTenantMaxItemsEach: PropTypes.number,
    DemoTenantMaxSensorsEach: PropTypes.number,
    DemoTenantMaxTimeMonths: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ settings: { systemLimits }, tenants }) => ({
  systemLimits,
  currentTenantsCount: tenants.currentTenantsCount,
});

export default connect(mapStateToProps)(SystemLimitsDialog);
