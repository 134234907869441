import { openErrorDialog } from 'redux/errorHandler';
import { actions as authActions } from 'redux/auth';
import { actions as peopleActions } from 'redux/people';
import { actions as settingsActions } from 'redux/settings';
import { actions as sitesActions } from 'redux/sites';
import { actions as teamsActions } from 'redux/teams';
import { getSitesList, peopleListSelector, userTypeSelector } from './selectors';

export const mapStateToProps = ({
  auth: { user },
  people,
  teams: { filteredTeamMembers, teamMembers, membersForAssigment, siteManagers, isLoading, teamList, team },
  sites,
}) => ({
  createdEditedPersonId: people.createdEditedPersonId,
  error: people.error,
  isManager: userTypeSelector(user),
  peopleList: peopleListSelector(people),
  siteList: getSitesList(sites),
  tenantIsOEM: user.tenantIsOEM,
  userType: user.userType,
  availabilityOptions: people.availabilityOptions,
  jobsList: people.jobsOptions,
  sitesAvailableToUserFullInfo: user.sitesAvailableToUserFullInfo,
  filteredTeamMembers,
  teamMembers,
  membersForAssigment,
  siteManagers,
  isLoading,
  teamList,
  team,
});

export const mapDispatchToProps = {
  ...peopleActions,
  fetchJobsOptions: peopleActions.getJobsOptionsRequest,
  addPerson: peopleActions.addPersonRequest,
  deletePerson: peopleActions.deletePersonRequest,
  editPerson: peopleActions.editPersonRequest,
  fetchAssignedSite: peopleActions.assignedSitesRequest,
  fetchPeople: peopleActions.peopleListRequest,
  fetchSitesList: sitesActions.sitesListRequest,
  fetchAvailabilityOptions: peopleActions.getAvailabilityOptionsRequest,
  getSettings: settingsActions.getSettingsRequest,
  getSitesForAdmin: authActions.fetchUserSitesRequest,
  getMasterSettings: settingsActions.getMasterSettingsForAdminRequest,
  openErrorDialog,
  requestSendEmailContent: peopleActions.sendEmailContentRequest,
  updateAssignedSites: peopleActions.updateAssignedSitesRequest,
  onSiteChange: authActions.onSiteChange,
  unassignedManager: peopleActions.unassignedManagerRequest,
  getTeamListBySite: teamsActions.getTeamListBySiteRequest,
  ...teamsActions,
};
