import styled from 'styled-components';

import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';

export const SearchContainer = styled.div`
  padding-top: 20px;
  align-self: center;
  background-color: ${({ theme }) => theme.primaryWhite};
  z-index: 1600;
  margin-top: 20px;
`;

export const ListContainer = styled.div`
  padding: 30px 0 30px;
`;

export const Block = styled(BorderRadiusContainer)``;

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primarySites};
`;

export const BoldText = styled.b`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Text = styled.div`
  font-size: 20px;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;
`;

export const TopInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  font-size: 12px;
`;

export const LimitCount = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.lightGrey} !important;
  border: ${({ theme }) => `2px solid ${theme.primaryBlack}`};
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubInfo = styled.div``;
