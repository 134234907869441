import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseSystemDialog from 'components/Dialogs/BaseSystem';

import theme from 'theme';

import * as S from './styled';

class WarningModal extends PureComponent {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const { handleClose, onSubmit, open, text, title } = this.props;

    return (
      <BaseSystemDialog
        actions={[<S.OkButton label="OK" onClick={onSubmit} />]}
        handleClose={handleClose}
        open={open}
        title={title}
        titleColor={theme.primaryRed}
        isError
      >
        {text}
      </BaseSystemDialog>
    );
  }
}

export default WarningModal;
