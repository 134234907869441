import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';

import theme from 'theme';

import * as CS from '../styled';

import * as S from './styled';

const InstructionsDialog = ({ open, handleClose, handleNext, instructionsData }) => (
  <BaseDialog
    open={open}
    onRequestClose={handleClose}
    title={instructionsData.instructionsDialogTitle}
    titleColor={theme.darkGrey}
  >
    <S.DialogContainer>
      <S.SectionContainer>
        <S.Title>Purpose of this Action Card:</S.Title>
        <S.Text>{instructionsData.purpose}</S.Text>
      </S.SectionContainer>
      <S.SectionContainer>
        <S.Title>Preparation:</S.Title>
        <S.Text>Have the following information ready:</S.Text>
        <S.Text>
          <S.BoldText>Tenant name</S.BoldText> (company name)
        </S.Text>
        <S.Text>
          <S.BoldText>Site name</S.BoldText>
          {` (where the hardware ${instructionsData.type === 'remove' ? 'is reporting' : 'will report'} from)`}
        </S.Text>
      </S.SectionContainer>
      <>
        {instructionsData.type === 'remove' ? (
          <S.SectionContainer>
            <S.Text>
              All sensors currently connected to this device will become inactive. This removal will not affect any
              other devices active at the location.
            </S.Text>
          </S.SectionContainer>
        ) : (
          <>
            <S.SectionContainer>
              <S.Text>
                Ideally the new Gateway and sensor(s) are in your possession and close by. At the end of this process
                you will receive the “connection string” that needs to be entered in the Gateway Device through a web
                browser.
              </S.Text>
              <S.Text>
                If you do not have the Gateway available, you can still go ahead and set this up - using the “connection
                string” later to finish the process.
              </S.Text>
            </S.SectionContainer>
            <S.SectionContainer>
              <S.Text>
                Once the connection string is entered into the Gateway, the system will be “live” and if connected to
                the internet, can immediately be used to report data.
              </S.Text>
            </S.SectionContainer>
          </>
        )}
      </>
    </S.DialogContainer>
    <CS.NextButton label="Next" onClick={handleNext} isNext />
  </BaseDialog>
);

InstructionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  instructionsData: PropTypes.shape({
    instructionsDialogTitle: PropTypes.string,
    purpose: PropTypes.string,
    type: PropTypes.oneOf(['setup', 'add', 'remove']),
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export { InstructionsDialog };
