import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { UnitItemPdfType } from 'configs/propTypes';
import SimpleList from 'components/SimpleList';
import { DeleteIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';

const renderItemContent = pdf => pdf.Name || 'No pdf image';

class PdfsBody extends PureComponent {
  static propTypes = {
    handleItemClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    pdfs: PropTypes.arrayOf(UnitItemPdfType).isRequired,
  };

  state = {
    files: [],
    value: '',
  };

  onDrop = event => {
    this.setState({ files: event.target.files, value: event.target.value });
  };

  upload = () => {
    this.props.onUpload(this.state.files);
    this.setState({ files: [], value: '' });
  };

  renderRightControls = pdf => (
    <ButtonIcon onClick={() => this.props.onDelete(pdf.Id, pdf.Name)} tooltip="Delete">
      <DeleteIcon />
    </ButtonIcon>
  );

  render() {
    const { handleItemClick, pdfs } = this.props;
    const { files, value } = this.state;

    return (
      <>
        <S.FileSection>
          <S.FileSectionHeader>
            <S.IconContainer>
              <S.IconPdf />
            </S.IconContainer>
          </S.FileSectionHeader>
          <>
            <S.ListContainer>
              <SimpleList
                emptyListMessage="There are no PDFs, yet."
                data={pdfs}
                onItemClick={handleItemClick}
                renderItemContent={renderItemContent}
                renderRightControls={this.renderRightControls}
              />
            </S.ListContainer>
            <S.UploadPdfRule>File should not exceed 200 MB.</S.UploadPdfRule>
            <S.UploadPdfRow>
              <S.InputButton
                accept="application/pdf"
                files={files}
                id="file"
                onChange={this.onDrop}
                type="file"
                value={value}
              />
              <S.LabelButton htmlFor="file">Choose File</S.LabelButton>
              <S.FileName>{files[0]?.name ? files[0].name : ''}</S.FileName>
              <S.UploadButton label="Upload New PDF" onClick={this.upload} disabled={!value} />
            </S.UploadPdfRow>
          </>
        </S.FileSection>
      </>
    );
  }
}

export default PdfsBody;
