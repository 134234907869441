import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as meteringActions } from 'redux/meteringGroups';
import { actions as tenantsActions } from 'redux/tenants';
import { addGroupName } from 'redux/tenants/selectors';

import { IndustryType } from 'configs/propTypes';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { AddIcon, BackIcon } from 'components/Layout/Icons';

import ChooseGroupDialog from './ChooseGroupDialog';
import AddEditGroupDialog from './AddEditIndustryDialog';
import Item from './Item';

class Industries extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      requestAddIndustry: PropTypes.func.isRequired,
      addIndustryToGroupRequest: PropTypes.func.isRequired,
      getGroupsRequest: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
      requestEditIndustry: PropTypes.func.isRequired,
      requestTenantIndustryList: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    industriesList: PropTypes.arrayOf(IndustryType).isRequired,
  };

  state = {
    addEditIndustryDialogOpened: false,
    chooseGroupDialogOpened: false,
    industryToEdit: {},
    searchData: '',
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.requestTenantIndustryList();
    actions.getGroupsRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  onBackButtonClick = () => this.props.history.push('/globalsettings');

  closeAddEditIndustryDialog = () => this.setState({ addEditIndustryDialogOpened: false });

  openAddEditIndustryDialog = industry => {
    if (industry.Id) {
      this.setState({ addEditIndustryDialogOpened: true, industryToEdit: industry });
    } else {
      this.setState({ addEditIndustryDialogOpened: true, industryToEdit: {} });
    }
  };

  filterIndustriesList = ({ Name, MeeteringGroupName }) =>
    `${Name} ${MeeteringGroupName}`.toString().toLowerCase().includes(this.state.searchData.toLowerCase());

  searchInList = e => this.setState({ searchData: e.target.value });

  openChooseGroupDialog = () => this.setState({ chooseGroupDialogOpened: true });

  openGroupsDialog = values => {
    const { actions } = this.props;
    const { Id, Name } = values;

    this.setState({
      industryToEdit: { ...this.state.industryToEdit, ...values },
      addEditIndustryDialogOpened: false,
    });

    if (Id) {
      if (Name !== this.state.industryToEdit.Name) {
        actions.requestEditIndustry({ IndustryID: Id, Name, cb: this.openChooseGroupDialog });
      } else {
        this.openChooseGroupDialog();
      }
    } else {
      this.openChooseGroupDialog();
    }
  };

  closeChooseGroupDialog = () => this.setState({ chooseGroupDialogOpened: false, industryToEdit: {} });

  submitGroup = values => {
    const { actions } = this.props;
    const { Id, MeeteringGroupID: meeteringGroupId, Name } = values;

    if (!Id) {
      actions.requestAddIndustry({ Name, meeteringGroupId });
    } else {
      actions.addIndustryToGroupRequest({ meeteringGroupId, industryId: Id });
    }

    this.closeChooseGroupDialog();
  };

  render() {
    const { searchData, addEditIndustryDialogOpened, industryToEdit, chooseGroupDialogOpened } = this.state;
    const { industriesList } = this.props;

    return (
      <>
        <Subheader
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openAddEditIndustryDialog,
              hint: 'Add',
            },
          ]}
          title="Industries"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackButtonClick,
              hint: 'Back',
            },
          ]}
        />
        <SimpleList
          data={industriesList.filter(this.filterIndustriesList)}
          emptyListMessage="There are no Industries avaliable"
          onItemClick={this.openAddEditIndustryDialog}
          renderItemContent={Item}
        />
        <AddEditGroupDialog
          title={industryToEdit.Id ? 'Edit' : 'New Industry'}
          submitLabel={industryToEdit.Id ? 'Save / Next' : 'Next'}
          open={addEditIndustryDialogOpened}
          handleClose={this.closeAddEditIndustryDialog}
          onSubmit={this.openGroupsDialog}
          industry={industryToEdit}
        />
        <ChooseGroupDialog
          title="Group Selection"
          open={chooseGroupDialogOpened}
          handleClose={this.closeChooseGroupDialog}
          onSubmit={this.submitGroup}
          industry={industryToEdit}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  error: state.meteringGroups.error || state.tenants.error,
  industriesList: addGroupName(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openErrorDialog,
      requestAddIndustry: tenantsActions.addTenantIndustryRequest,
      requestEditIndustry: tenantsActions.editTenantIndustryRequest,
      requestTenantIndustryList: tenantsActions.tenantIndustryListRequest,
      ...meteringActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Industries);
