import styled from 'styled-components';
import { DeleteIcon, AddCircleIcon } from 'components/Layout/Icons';

export const DialogContent = styled.div`
  padding: 40px 25px 20px;
`;

export const LocationInfo = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.45);
  overflow: hidden;
  margin-bottom: 20px;
`;

export const LocationInfoHeader = styled.p`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 15px;
  margin: 0;
`;

export const LocationInfoContent = styled.div`
  padding: 15px 20px 10px;
  color: ${({ theme }) => theme.primaryDark};

  p {
    font-size: 15px;

    span {
      font-weight: bold;
    }
  }
`;

export const TotalPartsCount = styled.div`
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 0;

    &:last-child {
      font-size: 18px;
    }
  }
`;

export const PartsInfo = styled(LocationInfo)``;

export const PartsInfoHeader = styled(LocationInfoHeader)`
  background-color: ${({ theme }) => theme.primaryRed};
  display: grid;
  grid-template-columns: ${({ view }) => (view ? '8fr 2fr' : '8fr 1fr 1fr')};
  grid-column-gap: 10px;
`;

export const PartsInfoContent = styled(LocationInfoContent)``;

export const PartsInfoContentRow = styled.div`
  display: grid;
  grid-template-columns: ${({ view }) => (view ? '4fr 4fr 2fr' : '4fr 4fr 1fr 1fr')};
  grid-column-gap: 10px;
  color: ${({ theme }) => theme.primaryRed};
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 8px;
`;

export const Col = styled.span`
  justify-content: ${({ textCenter }) => (textCenter === 'true' ? 'center' : 'flex-start')};
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DeleteBtn = styled(DeleteIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const GroupInfo = styled(LocationInfo)``;

export const GroupInfoHeader = styled(PartsInfoHeader)``;

export const GroupInfoContent = styled(PartsInfoContent)``;

export const GroupInfoContentRow = styled(PartsInfoContentRow)`
  grid-template-columns: ${({ view }) => (view ? '8fr 2fr' : '8fr 1fr 1fr')};
`;

export const AddBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 20px;
`;

export const AddIcon = styled(AddCircleIcon)`
  transform: scale(1);
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
`;
