import React from 'react';
import PropTypes from 'prop-types';

import EmptyList from 'components/EmptyList';
import { ListContainer } from 'components/Layout/Containers';

import RequestItem from './Request';

const requestsByType = {
  new: [
    { field: 'tenantName', title: 'Company Name:' },
    { field: 'fullName', title: 'Contact Name:' },
    { field: 'requestDate', title: 'Request Date:' },
    { field: 'requestTime', title: 'Time:' },
    { field: 'isVerificationReceived', title: 'Verification received:', isLastField: true },
  ],
  sent: [
    { field: 'tenantName', title: 'Company Name:' },
    { field: 'fullName', title: 'Contact Name:' },
    { field: 'requestDate', title: 'Request Date:' },
    { field: 'requestTime', title: 'Time:' },
    { field: 'sentDate', title: 'Sent:' },
    { field: 'sentTime', title: 'Time:' },
  ],
  blocked: [
    { field: 'tenantName', title: 'Company Name:' },
    { field: 'fullName', title: 'Contact Name:' },
    { field: 'requestDate', title: 'Request Date:' },
    { field: 'requestTime', title: 'Time:' },
    { field: 'notes', title: 'Note: ', isLastField: true },
  ],
};

const RequestsList = ({ requests, requestsType, onItemClick, changeNote }) => (
  <ListContainer>
    {requests.length > 0 ? (
      requests.map(request => (
        <RequestItem
          key={request.id}
          request={request}
          fields={requestsByType[requestsType]}
          onItemClick={() => onItemClick(request)}
          changeNote={e => changeNote(e, { id: request.id, notes: request.notes })}
        />
      ))
    ) : (
      <EmptyList text="There are no requests" />
    )}
  </ListContainer>
);

RequestsList.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestsType: PropTypes.oneOf(['new', 'sent', 'blocked']).isRequired,
  onItemClick: PropTypes.func.isRequired,
  changeNote: PropTypes.func.isRequired,
};

export default RequestsList;
