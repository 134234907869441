import styled from 'styled-components';
import Modal from 'material-ui/Dialog';

export const Dialog = styled(Modal).attrs({
  titleStyle: {
    fontSize: 30,
    fontWeight: 'lighter',
  },
  bodyStyle: {
    padding: '0 10px',
    overflowX: 'hidden',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
  contentStyle: {
    width: '100%',
    maxWidth: '662px',
  },
})`
  & > div > div > div {
    border: 2px solid ${({ theme }) => theme.primaryWhite} !important;
    border-radius: 20px !important;
    background: ${({ backgroundColor }) => backgroundColor} !important;

    > h3 {
      color: ${({ theme, module }) => (module === 'health' ? theme.textGray : theme.primaryWhite)} !important;
    }
  }

  & > div:last-child {
    background: ${({ theme }) => theme.modalsContentColor} !important;
  }
`;
