import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import * as CS from 'components/Dialogs/styled';

import { NotificationTime } from 'configs/propTypes';
import { validateHour, validateMinute, periods } from './helpers';

import * as S from './styled';

class SetTimezoneDialog extends PureComponent {
  static propTypes = {
    checkedSitesNumber: PropTypes.number.isRequired,
    currentSite: PropTypes.shape({
      SiteId: PropTypes.string,
      Name: PropTypes.string,
      notificationTime: PropTypes.shape(NotificationTime),
    }).isRequired,
    inputTime: PropTypes.shape(NotificationTime).isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    applyToCheckedSelections: PropTypes.func.isRequired,
    applyToCurrentSite: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    setNotificationTime: PropTypes.func.isRequired,
    setDefaultNotification: PropTypes.func.isRequired,
  };

  changeTime = (value, fieldName) => this.props.setNotificationTime({ value, fieldName });

  render() {
    const {
      applyToCheckedSelections,
      applyToCurrentSite,
      checkedSitesNumber,
      currentSite,
      handleClose,
      inputTime: { Hour, Minute, Period },
      open,
      setDefaultNotification,
      title,
    } = this.props;

    const emptyInputTimeValues = Object.values(this.props.inputTime).some(value => value === '');
    const fieldsIncorrect = validateHour(Hour) || validateMinute(Minute);
    const disableButtons = emptyInputTimeValues || fieldsIncorrect;

    return (
      <BaseDialog open={open} onRequestClose={handleClose} title={title}>
        <S.DialogContainer>
          <S.SiteContainer>
            <S.CurrentSiteName>{`Current Site: ${currentSite.Name}`}</S.CurrentSiteName>
            <CS.SelectorContainer>
              <S.TimeFieldsContainer>
                <S.TimeField
                  placeholder="hour"
                  value={Hour}
                  onChange={(e, value) => this.changeTime(value, 'Hour')}
                  isRequired
                  isError={validateHour(Hour)}
                />
                <S.TimeField
                  placeholder="minute"
                  value={Minute}
                  onChange={(e, value) => this.changeTime(value, 'Minute')}
                  isRequired
                  isError={validateMinute(Minute)}
                />
                <S.Select hintText="am" value={Period} onChange={(e, index, value) => this.changeTime(value, 'Period')}>
                  {periods.map(period => (
                    <S.Option key={period} value={period} primaryText={period} />
                  ))}
                </S.Select>
              </S.TimeFieldsContainer>
              <S.SelectorLabel isButton onClick={setDefaultNotification}>
                <S.SetToDefaultIcon /> Set To 11:00 pm default
              </S.SelectorLabel>
            </CS.SelectorContainer>
          </S.SiteContainer>
          <CS.DialogFooter>
            {checkedSitesNumber > 1 && (
              <CS.ButtonContainer onClick={applyToCheckedSelections}>
                <CS.SaveButton submitLabel={`Apply to ${checkedSitesNumber} selections`} disabled={disableButtons} />
              </CS.ButtonContainer>
            )}
            <CS.ButtonContainer onClick={applyToCurrentSite} fullWidth={checkedSitesNumber < 2}>
              <CS.SaveButton submitLabel="Apply to this Site" disabled={disableButtons} />
            </CS.ButtonContainer>
          </CS.DialogFooter>
        </S.DialogContainer>
      </BaseDialog>
    );
  }
}

export default SetTimezoneDialog;
