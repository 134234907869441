import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { UserType } from 'configs/propTypes';
import { actions as tasksActions } from 'redux/tasks';
import Subheader from 'components/Subheader';
import EmptyList from 'components/EmptyList';
import * as S from './styled';
import { BackIcon, ForwardIcon } from 'components/Layout/Icons';

class UsersList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    users: PropTypes.arrayOf(UserType).isRequired,
    requestUsersList: PropTypes.func.isRequired,
  };

  state = {
    searchData: '',
  };

  componentDidMount() {
    this.props.requestUsersList();
  }

  onTaskClick = id => {
    this.props.history.push(`/tasks/${id}/${moment().format('YYYY-MM-DD')}`);
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  filterList = item => item.Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  searchInList = event => {
    this.setState({
      searchData: event.target.value,
    });
  };

  render() {
    const { users } = this.props;
    const { searchData } = this.state;

    return (
      <S.Container>
        <Subheader
          title="Assignees"
          zIndex={4}
          isSiteOnLeftSide
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
            },
          ]}
        />
        {users.length ? (
          <S.List>
            {users.filter(this.filterList).map(({ Id, IsTeam, Name, TodayTasksCount, WeekTasksCount }) => (
              <S.ListItem
                key={Id}
                rightIcon={
                  <S.IconButton onClick={() => this.onTaskClick(Id)} tooltipPosition="top-left" tooltip="Show tasks">
                    <ForwardIcon />
                  </S.IconButton>
                }
                secondaryText={
                  <S.SecondaryTextContainer>
                    <S.ListItemText isTeam={IsTeam} isPickUp={Name === 'Pick-Up'}>
                      Today Tasks: {TodayTasksCount}
                    </S.ListItemText>
                    <S.ListItemText isTeam={IsTeam} isPickUp={Name === 'Pick-Up'}>
                      7Day Tasks: {WeekTasksCount}
                    </S.ListItemText>
                  </S.SecondaryTextContainer>
                }
                primaryText={
                  <S.ListItemText isTeam={IsTeam} isPickUp={Name === 'Pick-Up'}>
                    {Name}
                  </S.ListItemText>
                }
              />
            ))}
          </S.List>
        ) : (
          <EmptyList text="There are no users" />
        )}
      </S.Container>
    );
  }
}

const mapStateToProps = ({ tasks: { users } }) => ({
  users,
});

export default connect(mapStateToProps, { requestUsersList: tasksActions.usersListRequest })(UsersList);
