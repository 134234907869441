import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as authActions } from 'redux/auth';
import { getProfileData } from 'redux/auth/selectors';
import UserInformation from './UserInformation';
import { CancelButton, DialogContainer, LogOutButton } from './styled';

const ProfileDialog = ({ onLogout, onRequestClose, open, userProfile }) => {
  const actions = [<CancelButton onClick={onRequestClose} />, <LogOutButton onClick={onLogout} />];

  return (
    <DialogContainer actions={actions} onRequestClose={onRequestClose} open={open}>
      <UserInformation userProfile={userProfile} />
    </DialogContainer>
  );
};

ProfileDialog.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userProfile: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ auth }) => ({
  userProfile: getProfileData(auth),
});

export default connect(mapStateToProps, { onLogout: authActions.logoutRequest })(ProfileDialog);
