import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { sortBy } from 'lodash';
import theme from 'theme';

import { BorderRadiusContainer } from 'components/Layout/Containers';
import { ActionButton, ActionsContainer, Dialog } from 'components/Dialogs/v1/Base';
import SearchField from 'components/Layout/Search';
import { SearchIcon } from 'components/Layout/Icons';
import CheckboxList from 'modules/activator/dialogs/ListTemplate/SelectSite/CheckboxList';

import { actions as authActions } from 'redux/auth';
import { SitesAvailableToUserFullInfoType } from 'types/auth';
import { AuthReducerType, PropsType, SitesCollectionType } from './types';

import * as S from './styled';

const SiteSelectionDialog = ({
  isOpen,
  sitesAvailableToUserFullInfo,
  isLoading,
  selectedSite,
  onClose,
  actions,
}: PropsType) => {
  const [sites, setSites] = useState<SitesAvailableToUserFullInfoType[]>(sitesAvailableToUserFullInfo);
  const [checkedSite, setCheckedSite] = useState<Partial<SitesAvailableToUserFullInfoType>>({});
  const [search, setSearch] = useState('');
  const additionalIdSorter = ({ additionalId }: { additionalId: string }) => additionalId.toLowerCase();
  const nameSorter = ({ name }: { name: string }) => name.toLowerCase();
  const sortedSitesAvailableToUserFullInfo = sortBy(sitesAvailableToUserFullInfo, [additionalIdSorter, nameSorter]);
  const sortedSites = sortBy(sites, [additionalIdSorter, nameSorter]);

  const onSiteChecked = (checkedValue: Partial<SitesAvailableToUserFullInfoType>) => setCheckedSite(checkedValue);

  const setSearchedSites = (filteredSites: SitesAvailableToUserFullInfoType[], value: string) => {
    setSites(filteredSites);
    setSearch(value);
  };

  const onCloseDialog = () => {
    setSearch('');
    setCheckedSite({ id: selectedSite });

    onClose();
  };

  const onSiteChange = () => {
    const checkedSiteName = checkedSite.name;

    const sitesCollection = sites.reduce((acc, { name, id }) => {
      acc.push({ siteName: name, siteId: id });
      return acc;
    }, [] as SitesCollectionType[]);
    actions.onSiteChange({ checkedSite: checkedSiteName, sitesCollection });
    onCloseDialog();
  };

  useEffect(() => {
    setCheckedSite({ id: selectedSite });
  }, [selectedSite]);

  return (
    <Dialog
      open={isOpen}
      title="Site Selection"
      noCapitalize
      onRequestClose={onCloseDialog}
      centeredTitle={null}
      zIndex={null}
      titleColor={theme.headerColor}
    >
      <S.DialogContainer>
        <S.Overlay show={isLoading}>
          <S.Loader size={90} />
        </S.Overlay>
        <S.TopInfo>
          <S.SubInfo>Select a Site/Location to manage</S.SubInfo>
        </S.TopInfo>
        <BorderRadiusContainer column justify="">
          <S.Label>
            Sites <S.SitesIcon />
          </S.Label>
          <S.SearchContainer>
            <SearchField
              list={sitesAvailableToUserFullInfo.map(item => ({
                ...item,
                additionalId: item.additionalId.substring(0, 5),
              }))}
              filterBy={['name', 'additionalId']}
              searchData={search}
              placeholder="Filter Sites"
              underlineShow={false}
              icon={<SearchIcon />}
              setSearchedItems={setSearchedSites}
            />
          </S.SearchContainer>

          <S.ListContainer>
            <S.TitleContainer>
              <S.Title>Site ID</S.Title>
              <S.Title>Name</S.Title>
            </S.TitleContainer>
            <S.Hr />

            <CheckboxList
              isSiteSelection
              list={!search.length ? sortedSitesAvailableToUserFullInfo : sortedSites}
              checkedSite={checkedSite}
              setCheckedSite={onSiteChecked}
            />
          </S.ListContainer>
        </BorderRadiusContainer>
        <ActionsContainer isColumn paddingValue="14">
          <ActionButton label="APPLY" isNext onClick={onSiteChange} />
        </ActionsContainer>
      </S.DialogContainer>
    </Dialog>
  );
};

const mapStateToProps = ({
  auth: {
    selectedSite,
    user: { sitesAvailableToUserFullInfo },
    loading,
  },
}: {
  auth: AuthReducerType;
}) => ({
  selectedSite,
  sitesAvailableToUserFullInfo,
  isLoading: loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      onSiteChange: authActions.onSiteChange,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelectionDialog);
