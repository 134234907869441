import React from 'react';
import PropTypes from 'prop-types';
import { Container, Text } from './styled';

const EmptyList = ({ text }) => (
  <Container>
    <Text>{text}</Text>
  </Container>
);

EmptyList.propTypes = {
  text: PropTypes.string.isRequired,
};

EmptyList.defaultProps = {
  text: 'List is empty',
};

export default EmptyList;
