import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import { Modal } from './styled';

const BaseSystemDialog = ({ children, title, titleColor, isError, ...rest }) => (
  <Modal
    autoDetectWindowHeight
    autoScrollBodyContent
    title={<Title handleClose={rest.handleClose} title={title} titleColor={titleColor} isError={isError} />}
    {...rest}
  >
    {children}
  </Modal>
);

BaseSystemDialog.defaultProps = {
  actions: [],
  children: null,
  title: '',
  titleColor: '',
  isError: false,
};

BaseSystemDialog.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  isError: PropTypes.bool,
};

export default BaseSystemDialog;
