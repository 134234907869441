import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import { connect } from 'react-redux';

import theme from 'theme';

import BaseDialog from 'components/Dialogs/Base';
import { LaunchIcon, ThumbUpIcon, ThumbDownIcon, EditIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';

import { openErrorDialog } from 'redux/errorHandler';

import BlockDialog from './BlockDialog';
import ApproveDialog from './ApproveDialog';
import { cards, initialEmail } from './constants';
import * as S from './styled';

class RequestDetailsDialog extends PureComponent {
  static propTypes = {
    error: PropTypes.string.isRequired,
    request: PropTypes.shape({
      email: PropTypes.string,
      id: PropTypes.string,
      industryId: PropTypes.string,
      isVerificationReceived: PropTypes.bool,
      verifiedIndustryId: PropTypes.string,
      requestDate: PropTypes.string,
      requestTime: PropTypes.string,
      sentDate: PropTypes.string,
      sentTime: PropTypes.string,
    }).isRequired,
    requestsType: PropTypes.oneOf(['new', 'sent', 'blocked']).isRequired,
    open: PropTypes.bool.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    approveDemoTenant: PropTypes.func.isRequired,
    blockDemoTenant: PropTypes.func.isRequired,
    onRemoveDemoTenant: PropTypes.func.isRequired,
  };

  state = {
    isEditEmail: false,
    emailText: initialEmail,
    note: '',
    openApproveDialog: false,
    openBlockDialog: false,
    openRemoveDialog: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  closeAndReset = () => {
    this.setState({ isEditEmail: false, emailText: initialEmail });

    this.props.onClose();
  };

  changeEmailMode = () => this.setState({ isEditEmail: !this.state.isEditEmail });

  openEmailClient = e => {
    window.location = `mailto:${this.props.request.email}`;
    e.preventDefault();
  };

  handleBlock = () => {
    this.setState({ openBlockDialog: true });
  };

  closeBlockDialog = () => {
    this.setState({ openBlockDialog: false });
    this.closeAndReset();
  };

  blockDemoTenant = () => {
    this.props.blockDemoTenant({
      notes: this.state.note,
      id: this.props.request.id,
      requestType: this.props.requestsType,
    });
    this.closeBlockDialog();
    this.closeAndReset();
  };

  handleApprove = () => {
    const { request } = this.props;

    if (request.industryId !== request.verifiedIndustryId) {
      this.setState({ openApproveDialog: true });
    } else {
      this.props.approveDemoTenant({
        emailText: this.state.emailText,
        id: this.props.request.id,
        requestType: this.props.requestsType,
      });
      this.closeAndReset();
    }
  };

  closeApproveDialog = () => {
    this.setState({ openApproveDialog: false });
    this.closeAndReset();
  };

  approveDemoTenant = () => {
    this.props.approveDemoTenant({
      emailText: this.state.emailText,
      id: this.props.request.id,
      requestType: this.props.requestsType,
    });
    this.closeApproveDialog();
  };

  onEmailTextChange = (e, value) => this.setState({ emailText: value });
  onNoteChange = (e, value) => this.setState({ note: value });

  handleRemove = () => this.setState({ openRemoveDialog: true });

  handleAcceptRemoveDialogPress = () => {
    this.props.onRemoveDemoTenant({
      id: this.props.request.id,
      requestType: this.props.requestsType,
    });
    this.setState({ openRemoveDialog: false });
    this.props.onClose();
  };

  handleRejectRemoveDialogPress = () => this.setState({ openRemoveDialog: false });

  render() {
    const { open, request, requestsType } = this.props;
    const { isEditEmail, emailText, openApproveDialog, note, openBlockDialog, openRemoveDialog } = this.state;

    return (
      <BaseDialog open={open} onRequestClose={this.closeAndReset} title="Request Details">
        <S.DialogContainer>
          {cards.map(({ cardTitle, data, date }) => (
            <S.CardContainer key={cardTitle}>
              <S.CardHeader>{cardTitle}</S.CardHeader>
              <S.CardContentContainer>
                <S.DateContainer>
                  {date.map(({ title, field }) => (
                    <S.Date key={field}>
                      {title} <S.Value>{request[field] || 'N/A'}</S.Value>
                    </S.Date>
                  ))}
                </S.DateContainer>
                <S.CardInfoList>
                  {data.map(({ field, title }) =>
                    field === 'isVerificationReceived' ? (
                      <S.ListItem key={field}>
                        <Checkbox
                          iconStyle={{ fill: theme.primaryPink }}
                          style={{ padding: '10px 0' }}
                          label={title}
                          labelPosition="right"
                          checked={request[field]}
                        />
                      </S.ListItem>
                    ) : (
                      <S.ListItem key={field}>
                        <S.Info>
                          {title} <S.Value>{request[field] || 'N/A'}</S.Value>
                        </S.Info>
                        {field === 'email' && (
                          <ButtonIcon onClick={this.openEmailClient}>
                            <LaunchIcon style={S.iconStyle} />
                          </ButtonIcon>
                        )}
                        {field === 'verifiedIndustryName' && request.isVerificationReceived && (
                          <>
                            {request.industryId === request.verifiedIndustryId ? (
                              <ThumbUpIcon style={{ color: theme.primaryGreen }} />
                            ) : (
                              <ThumbDownIcon style={{ color: theme.primaryRed }} />
                            )}
                          </>
                        )}
                      </S.ListItem>
                    ),
                  )}
                </S.CardInfoList>
              </S.CardContentContainer>
            </S.CardContainer>
          ))}
          <S.CardContainer>
            <S.CardHeader>
              Email Text
              {requestsType !== 'sent' && (
                <S.EditEmailButton onClick={this.changeEmailMode}>
                  <EditIcon />
                </S.EditEmailButton>
              )}
            </S.CardHeader>
            <S.CardContentContainer>
              <TextField
                multiLine
                rows={3}
                fullWidth
                value={emailText}
                disabled={!isEditEmail}
                onChange={this.onEmailTextChange}
              />
              <S.Info>
                Click on this link to login:
                <S.IncheqLink href="https://app.incheq.com" target="_blank">
                  {' '}
                  https://app.incheq.com
                </S.IncheqLink>
              </S.Info>
            </S.CardContentContainer>
          </S.CardContainer>
        </S.DialogContainer>
        {requestsType !== 'sent' && (
          <S.ControlsContainer>
            {requestsType !== 'blocked' && (
              <S.ControlButton type="button" onClick={this.handleBlock}>
                Block
              </S.ControlButton>
            )}

            <S.ControlButton onClick={this.handleRemove}>Delete</S.ControlButton>

            <S.ControlButton type="button" isApprove requestsType={requestsType} onClick={this.handleApprove}>
              Approve and send
              <br /> email to user
            </S.ControlButton>
          </S.ControlsContainer>
        )}
        <ApproveDialog
          open={openApproveDialog}
          text={
            <p>
              The response does not match!
              <br /> Are you sure you want to approve?
            </p>
          }
          onClose={this.closeApproveDialog}
          onApprove={this.approveDemoTenant}
        />
        <ApproveDialog
          open={openRemoveDialog}
          text={
            <p>
              Are you sure?
              <br /> All data will be erased and cannot be undone!
            </p>
          }
          onClose={this.handleRejectRemoveDialogPress}
          onApprove={this.handleAcceptRemoveDialogPress}
        />
        <BlockDialog
          open={openBlockDialog}
          note={note}
          onNoteChange={this.onNoteChange}
          onClose={this.closeBlockDialog}
          onBlock={this.blockDemoTenant}
        />
      </BaseDialog>
    );
  }
}

const mapStateToProps = ({ tenants }) => ({
  error: tenants.error,
});

const mapDispatchToProps = {
  openErrorDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetailsDialog);
