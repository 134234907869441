import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  USERS_LIST_REQUEST: undefined,
  USERS_LIST_SUCCESS: undefined,
  USERS_LIST_FAILURE: undefined,

  FETCH_TASKS_REQUEST: undefined,
  FETCH_TASKS_SUCCESS: undefined,
  FETCH_TASKS_FAILURE: undefined,

  FETCH_USER_REQUEST: undefined,
  FETCH_USER_SUCCESS: undefined,
  FETCH_USER_FAILURE: undefined,

  FETCH_DETAILS_REQUEST: undefined,
  FETCH_DETAILS_SUCCESS: undefined,
  FETCH_DETAILS_FAILURE: undefined,

  FETCH_PHOTOS_REQUEST: undefined,
  FETCH_PHOTOS_SUCCESS: undefined,
  FETCH_PHOTOS_FAILURE: undefined,

  FETCH_NOTES_REQUEST: undefined,
  FETCH_NOTES_SUCCESS: undefined,
  FETCH_NOTES_FAILURE: undefined,

  RELEASE_TASK_REQUEST: undefined,
  RELEASE_TASK_SUCCESS: undefined,
  RELEASE_TASK_FAILURE: undefined,

  ASSIGN_TASK_REQUEST: undefined,
  ASSIGN_TASK_SUCCESS: undefined,
  ASSIGN_TASK_FAILURE: undefined,

  SAVE_TASK_REQUEST: undefined,
  SAVE_TASK_SUCCESS: undefined,
  SAVE_TASK_FAILURE: undefined,

  SAVE_NOTE_REQUEST: undefined,
  SAVE_NOTE_SUCCESS: undefined,
  SAVE_NOTE_FAILURE: undefined,

  UPLOAD_PHOTO_TASKS_REQUEST: undefined,
  UPLOAD_PHOTO_TASKS_SUCCESS: undefined,
  UPLOAD_PHOTO_TASKS_FAILURE: undefined,

  UPLOAD_SCAN_CODE_REQUEST: undefined,
  UPLOAD_SCAN_CODE_SUCCESS: undefined,
  UPLOAD_SCAN_CODE_FAILURE: undefined,

  UPDATE_PAGE: undefined,
  UPDATE_ROWS_PER_PAGE: undefined,
  UPDATE_PERIOD: undefined,
  UPDATE_TASKS_TYPE: undefined,
  RESET_PERIOD: undefined,
  RESET_TASKS_TYPE: undefined,
  UPDATE_RANGE_START: undefined,
  RESET_RANGE_START: undefined,
  CLEAR_TASK_DETAILS: undefined,

  SEND_TASK_INFO_EMAIL_REQUEST: undefined,
  SEND_TASK_INFO_EMAIL_SUCCESS: undefined,
  SEND_TASK_INFO_EMAIL_FAILURE: undefined,

  GET_FILTERED_TASK_COUNT_SUCCESS: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.usersListRequest, handlers.usersListRequest],
    [actions.usersListSuccess, handlers.usersListSuccess],
    [actions.usersListFailure, handlers.usersListFailure],

    [actions.fetchUserRequest, handlers.fetchUserRequest],
    [actions.fetchUserSuccess, handlers.fetchUserSuccess],
    [actions.fetchUserFailure, handlers.fetchUserFailure],

    [actions.fetchTasksRequest, handlers.fetchTasksRequest],
    [actions.fetchTasksSuccess, handlers.fetchTasksSuccess],
    [actions.fetchTasksFailure, handlers.fetchTasksFailure],

    [actions.fetchDetailsRequest, handlers.fetchDetailsRequest],
    [actions.fetchDetailsSuccess, handlers.fetchDetailsSuccess],
    [actions.fetchDetailsFailure, handlers.fetchDetailsFailure],

    [actions.fetchPhotosRequest, handlers.fetchPhotosRequest],
    [actions.fetchPhotosSuccess, handlers.fetchPhotosSuccess],
    [actions.fetchPhotosFailure, handlers.fetchPhotosFailure],

    [actions.fetchNotesRequest, handlers.fetchNotesRequest],
    [actions.fetchNotesSuccess, handlers.fetchNotesSuccess],
    [actions.fetchNotesFailure, handlers.fetchNotesFailure],

    [actions.releaseTaskRequest, handlers.releaseTaskRequest],
    [actions.releaseTaskSuccess, handlers.releaseTaskSuccess],
    [actions.releaseTaskFailure, handlers.releaseTaskFailure],

    [actions.assignTaskRequest, handlers.assignTaskRequest],
    [actions.assignTaskSuccess, handlers.assignTaskSuccess],
    [actions.assignTaskFailure, handlers.assignTaskFailure],

    [actions.saveTaskRequest, handlers.saveTaskRequest],
    [actions.saveTaskSuccess, handlers.saveTaskSuccess],
    [actions.saveTaskFailure, handlers.saveTaskFailure],

    [actions.saveNoteRequest, handlers.saveNoteRequest],
    [actions.saveNoteSuccess, handlers.saveNoteSuccess],
    [actions.saveNoteFailure, handlers.saveNoteFailure],

    [actions.getFilteredTaskCountSuccess, handlers.getFilteredTaskCountSuccess],

    [actions.updatePage, handlers.updatePage],
    [actions.updateRowsPerPage, handlers.updateRowsPerPage],
    [actions.updatePeriod, handlers.updatePeriod],
    [actions.updateTasksType, handlers.updateTasksType],
    [actions.resetPeriod, handlers.resetPeriod],
    [actions.resetTasksType, handlers.resetTasksType],
    [actions.updateRangeStart, handlers.updateRangeStart],
    [actions.resetRangeStart, handlers.resetRangeStart],

    [actions.uploadPhotoTasksRequest, handlers.uploadPhotoTasksRequest],
    [actions.uploadPhotoTasksSuccess, handlers.uploadPhotoTasksSuccess],
    [actions.uploadPhotoTasksFailure, handlers.uploadPhotoTasksFailure],

    [actions.uploadScanCodeRequest, handlers.uploadScanCodeRequest],
    [actions.uploadScanCodeSuccess, handlers.uploadScanCodeSuccess],
    [actions.uploadScanCodeFailure, handlers.uploadScanCodeFailure],

    [actions.sendTaskInfoEmailRequest, handlers.sendTaskInfoEmailRequest],
    [actions.sendTaskInfoEmailSuccess, handlers.sendTaskInfoEmailSuccess],
    [actions.sendTaskInfoEmailFailure, handlers.sendTaskInfoEmailFailure],

    [actions.clearTaskDetails, handlers.clearTaskDetails],
  ]),
  initialState,
);

export default reducer;
