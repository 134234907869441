import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actions as tutorialActions } from 'redux/tutorials';
import { Loader, Overlay } from '../../../Layout/Progresses';
import { INSTRUCTIONS_MODULES } from 'configs/routes';
import { TutorialVideosType } from 'configs/propTypes';
import { getPageNameByModule } from 'helpers';
import TutorialVideoDialog from './TutorialVideoDialog';

import * as S from './styled';

const TutorialVideos = ({ module, userType, videos, isLoading, actions: { fetchVideosBySectionRequest }, onBack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoContent, setVideoContent] = useState({});
  const page = INSTRUCTIONS_MODULES[userType][module] || {};
  const pageName = getPageNameByModule(module);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (pageName) {
      fetchVideosBySectionRequest(pageName);
    }

    return () => {
      onBack();
    };
  }, [fetchVideosBySectionRequest, onBack, pageName]);

  const onPreviewImageClick = video => {
    openModal();
    setVideoContent(video);
  };

  return (
    <S.Container>
      <S.Header>
        {page.icon && <page.icon style={S.IconStyle} />}
        <S.Title module={page.title}>{page.title}</S.Title>
      </S.Header>
      <S.Main module={page.title}>
        {isLoading ? (
          <Overlay show={isLoading}>
            <Loader size={90} />
          </Overlay>
        ) : (
          videos.map(({ header, subtitle, data }, index) => (
            <React.Fragment key={index}>
              <S.VideoHeader>{header}</S.VideoHeader>
              <S.Subtitle>{subtitle}</S.Subtitle>

              <S.Article>
                {data.map(video => (
                  <S.Section key={video.Id} module={page.title}>
                    <S.SectionContent>
                      <S.SectionTitle>{video.Name}</S.SectionTitle>
                    </S.SectionContent>
                    <S.Image src={video.PreviewPicture} alt={video.Name} onClick={() => onPreviewImageClick(video)} />
                  </S.Section>
                ))}
              </S.Article>
            </React.Fragment>
          ))
        )}
      </S.Main>
      <TutorialVideoDialog open={isModalOpen} module={module} videoContent={videoContent} onRequestClose={closeModal} />
    </S.Container>
  );
};

TutorialVideos.propTypes = {
  module: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  videos: TutorialVideosType.isRequired,
  userType: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    fetchVideosBySectionRequest: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({
  auth: {
    user: { userType },
  },
  tutorials: { videos, loading },
}) => ({
  userType,
  videos,
  isLoading: loading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...tutorialActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialVideos);
