import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'components/Dialogs/Base';
import FormControls from 'components/Dialogs/withInputModal/Controls';

import theme from 'theme';

import * as S from './styled';

const Confirmation = ({ open, handleClose, submit, confirmationText }) => (
  <BaseDialog open={open} onRequestClose={handleClose} title="Alert">
    <S.ConfirmationText>{confirmationText}</S.ConfirmationText>
    <S.BoldText>This can not be undone!</S.BoldText>
    <S.ModalButtonsContainer>
      <FormControls
        handleClose={handleClose}
        withCancel
        customColor={theme.primaryGreen}
        cancelLabel="Cancel"
        handleDelete={submit}
        withDelete
      />
    </S.ModalButtonsContainer>
  </BaseDialog>
);

Confirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmationText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default Confirmation;
