import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TenantType } from 'configs/propTypes';

import { openErrorDialog } from 'redux/errorHandler';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as tenantsActions } from 'redux/tenants';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { BackIcon } from 'components/Layout/Icons';

import UpgradeInfoDialog from './UpgradeInfoDialog';
import Item from './Item';
import * as S from './styled';

import theme from 'theme';

class UpgradeRequests extends PureComponent {
  static propTypes = {
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    upgradeRequestsList: PropTypes.arrayOf(TenantType).isRequired,
    requestUpgradeRequestsList: PropTypes.func.isRequired,
    requestTennantIndustryList: PropTypes.func.isRequired,
    requestEditUpgradeRequest: PropTypes.func.isRequired,
    requestDeleteUpgradeRequest: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
  };

  state = {
    searchData: '',
    requestToEdit: {},
    upgradeInfoDialogOpen: false,
  };

  componentDidMount() {
    this.props.requestUpgradeRequestsList();
    this.props.requestTennantIndustryList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  handleBack = () => this.props.history.push('/home');

  searchInList = e => this.setState({ searchData: e.target.value });

  filterTenantsList = ({ ControllerNotes, RequestorCompany, RequestorName }) =>
    `${ControllerNotes} ${RequestorCompany} ${RequestorName}`
      .toLowerCase()
      .includes(this.state.searchData.toLowerCase());

  openAddEditTenantDialog = requestToEdit => this.setState({ requestToEdit, upgradeInfoDialogOpen: true });

  closeUpgradeInfoDialog = () => this.setState({ upgradeInfoDialogOpen: false, requestToEdit: {} });

  submitUpgradeRequest = values => {
    this.props.requestEditUpgradeRequest(values);
    this.closeUpgradeInfoDialog();
  };

  deleteUpgradeRequest = () => {
    this.props.requestDeleteUpgradeRequest({ upgradeTenantRequestId: this.state.requestToEdit.Id });
    this.setState({ upgradeInfoDialogOpen: false });
  };

  openConfirmationDelete = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Request? This cannot be undone!',
      this.deleteUpgradeRequest,
      'Delete?',
    );
  };

  render() {
    const { searchData, upgradeInfoDialogOpen, requestToEdit } = this.state;
    const { upgradeRequestsList } = this.props;
    const internalList = upgradeRequestsList.filter(({ TenantID }) => TenantID);
    const externalList = upgradeRequestsList.filter(({ TenantID }) => !TenantID);

    return (
      <>
        <Subheader
          title="Upgrade Requests"
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.handleBack,
              hint: 'Back',
            },
          ]}
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          rightContent={
            <S.RequestsInfoContainer>
              Requests: <S.RequestsNumberContainer>{upgradeRequestsList.length}</S.RequestsNumberContainer>
            </S.RequestsInfoContainer>
          }
        />
        <S.ListContainer>
          <S.ListSectionName>Internal Requests (existing Tenants)</S.ListSectionName>
          <SimpleList
            data={internalList.filter(this.filterTenantsList)}
            emptyListMessage="There are no Upgrade Requests avaliable"
            onItemClick={this.openAddEditTenantDialog}
            renderItemContent={Item}
          />
        </S.ListContainer>
        <S.ListContainer>
          <S.ListSectionName>External Requests</S.ListSectionName>
          <SimpleList
            data={externalList.filter(this.filterTenantsList)}
            emptyListMessage="There are no Upgrade Requests avaliable"
            onItemClick={this.openAddEditTenantDialog}
            renderItemContent={Item}
          />
        </S.ListContainer>
        <UpgradeInfoDialog
          title="Upgrade Info"
          open={upgradeInfoDialogOpen}
          handleClose={this.closeUpgradeInfoDialog}
          onSubmit={this.submitUpgradeRequest}
          info={requestToEdit}
          withDelete
          withGreenButton
          customColor={theme.primaryRed}
          handleDelete={this.openConfirmationDelete}
        />
      </>
    );
  }
}

const mapStateToProps = ({ tenants }) => ({
  error: tenants.error,
  upgradeRequestsList: tenants.upgradeRequestsList,
});

const mapDispatchToProps = {
  requestTennantIndustryList: tenantsActions.tenantIndustryListRequest,
  requestUpgradeRequestsList: tenantsActions.getUpgradeRequestsRequest,
  requestEditUpgradeRequest: tenantsActions.editUpgradeRequestRequest,
  requestDeleteUpgradeRequest: tenantsActions.deleteUpgradeRequestRequest,
  openErrorDialog,
  openConfirmationDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeRequests);
