import styled from 'styled-components';
import CircularProgress from 'material-ui/CircularProgress';

export const ProgressCircular = styled(CircularProgress)``;

export const Overlay = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none !important')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.overlay};
  z-index: 2000;
`;

export const Loader = styled(ProgressCircular).attrs({
  size: props => props.size,
})``;
