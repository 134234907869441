import React from 'react';
import { Field } from 'react-final-form';

import withInputModal from 'components/Dialogs/withInputModal';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';

import validate from './validator';
import * as S from './styled';

const NewGroupForm = () => (
  <S.DialogContainer>
    <S.NameContainer>
      <Field name="Name" component={TextFieldAdapter} placeholder="Group Name" />
    </S.NameContainer>
    <S.DescriptionCardContainer>
      <S.DescriptionHeader>Optional Data</S.DescriptionHeader>
      <S.DescriptionFieldContainer>
        <Field name="Description" component={TextFieldAdapter} placeholder="Group Description" />
      </S.DescriptionFieldContainer>
    </S.DescriptionCardContainer>
  </S.DialogContainer>
);

const NewGroup = withInputModal(NewGroupForm);

const NewGroupDialog = props => <NewGroup initialValues={{}} validate={validate} withGreenButton {...props} />;

export default NewGroupDialog;
