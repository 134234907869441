import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider, ReactReduxContext } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions as appActions } from 'redux/app';

import InstructionsHandler from 'helpers/InstructionsHandler';
import Actions from './Actions';
import Instructions from './Instructions';
import TutorialVideos from './TutorialVideos';

import { Modal } from './styled';

const STEPS = {
  0: {
    Component: Instructions,
  },
  1: {
    Component: TutorialVideos,
  },
};

class InstructionsDialog extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    module: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    updateCurrentModule: PropTypes.func.isRequired,
  };

  state = {
    backgroundColor: InstructionsHandler.getBackgroundColorForModule(this.props.location),
    stopFromShowing: InstructionsHandler.getStopFromShowingForModule(this.props.location),
    step: 0,
  };

  componentDidMount() {
    const currentModule = InstructionsHandler.getModuleFromLocation(this.props.location);

    this.props.updateCurrentModule(currentModule);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const currentModule = InstructionsHandler.getModuleFromLocation(nextProps.location);

      this.props.updateCurrentModule(currentModule);

      this.setState({
        backgroundColor: InstructionsHandler.getBackgroundColorForModule(nextProps.location),
        stopFromShowing: InstructionsHandler.getStopFromShowingForModule(nextProps.location),
      });
    }
  }

  checkStopFromShowing = () => {
    const { stopFromShowing } = this.state;
    const { location } = this.props;

    if (stopFromShowing) {
      InstructionsHandler.unsetStopFromShowingForModule(location);
    } else {
      InstructionsHandler.setStopFromShowingForModule(location);
    }

    this.setState({ stopFromShowing: !stopFromShowing });
  };

  handlePrev = () => this.setState({ step: 0 });

  handleNext = () => this.setState({ step: 1 });

  render() {
    const { module, onRequestClose, open } = this.props;
    const { backgroundColor, stopFromShowing, step } = this.state;
    const Step = STEPS[step].Component;

    return (
      <ReactReduxContext.Consumer>
        {ctx => (
          <Modal backgroundColor={backgroundColor} isOpen={open} onRequestClose={onRequestClose}>
            <Provider store={ctx.store}>
              <Step onNext={this.handleNext} onBack={this.handlePrev} module={module} />
            </Provider>
            <Actions
              checkStopFromShowing={this.checkStopFromShowing}
              module={module}
              onStartClick={onRequestClose}
              stopFromShowingChecked={stopFromShowing}
            />
          </Modal>
        )}
      </ReactReduxContext.Consumer>
    );
  }
}

export default withRouter(connect(null, { updateCurrentModule: appActions.updateCurrentModule })(InstructionsDialog));
