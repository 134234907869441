import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TenantType, TiersListType } from 'configs/propTypes';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as authActions } from 'redux/auth';
import { actions as tenantsActions } from 'redux/tenants';

import { EMPTY_GUID } from 'helpers';

import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import PopoverMenu from 'components/PopoverMenu';
import { ButtonIcon } from 'components/Layout/Buttons';
import EditPricingDialog from 'components/Dialogs/EditPricingDialog';

import { AddIcon, PauseIcon, PlayIcon, TenantsModuleIcon } from 'components/Layout/Icons';
import AddEditTenantDialog from './AddEditTenantDialog';
import Item from './Item';

import * as S from './styled';

class TenantsList extends PureComponent {
  static propTypes = {
    activateTenant: PropTypes.func.isRequired,
    addIndustry: PropTypes.func.isRequired,
    addTenant: PropTypes.func.isRequired,
    deactivateTenant: PropTypes.func.isRequired,
    deleteIndustry: PropTypes.func.isRequired,
    deleteTenant: PropTypes.func.isRequired,
    editIndustry: PropTypes.func.isRequired,
    editTenant: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    fetchLoginAsUsers: PropTypes.func.isRequired,
    fetchIndustry: PropTypes.func.isRequired,
    fetchTenants: PropTypes.func.isRequired,
    requestFetchTiers: PropTypes.func.isRequired,
    clearTiers: PropTypes.func.isRequired,
    industryList: PropTypes.arrayOf(TenantType).isRequired,
    tiersList: PropTypes.arrayOf(TiersListType).isRequired,
    loginAsUser: PropTypes.func.isRequired,
    loginAsUsers: PropTypes.array.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    tenantsList: PropTypes.arrayOf(TenantType).isRequired,
    requestOverwritePricingValues: PropTypes.func.isRequired,
  };

  state = {
    addEditTenantDialogOpened: false,
    addEditDeleteIndustryDialogOpened: false,
    disableIndustryField: false,
    industryToEdit: {},
    newIndustryName: '',
    searchData: '',
    tenantToEdit: null,
    tenantStatus: true,
    isPricingEditDialogOpen: false,
  };

  componentDidMount() {
    this.props.fetchTenants();
    this.props.fetchIndustry();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  onDeleteApprove = () => {
    this.closeAddEditTenantDialog();
    this.props.deleteTenant(this.state.tenantToEdit.Id);
  };

  onDeleteTenant = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Tenant? This cannot be undone!',
      this.onDeleteApprove,
      'Delete?',
    );
  };

  onAddIndustry = name => {
    this.props.addIndustry({
      Name: name.trim(),
    });
    this.setState({ newIndustryName: '' });
  };

  onEditIndustry = (id, name) => {
    this.props.editIndustry({
      IndustryID: id,
      Name: name.trim(),
    });
    this.setState({ disableIndustryField: true });
  };

  onEnableEditIndustry = industry => {
    this.setState({ disableIndustryField: industry.Id, industryToEdit: industry });
  };

  onDeleteIndustry = industry => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Industry? This cannot be undone!',
      () => this.onDeleteIndustryApprove(industry),
      'Delete?',
    );
  };

  onDeleteIndustryApprove = industry => {
    this.closeAddEditTenantDialog();
    this.props.deleteIndustry(industry);
  };

  onOpenLoginAsUsers = tenant => {
    this.props.fetchLoginAsUsers(tenant.Id);
  };

  onToggleBlockTenant = tenant => {
    if (tenant.Status === 'Deactivated') {
      this.props.activateTenant(tenant.Id);
    } else {
      this.props.deactivateTenant(tenant.Id);
    }
  };

  handleIndustryChange = industryId => {
    const industry = this.props.industryList.find(({ IndustryID }) => IndustryID === industryId);
    if (industry) {
      this.props.requestFetchTiers(industry.MeeteringGroupID);
    }
  };

  handleChange = event => {
    if (event.target.name === 'Industry') {
      this.setState({
        industryToEdit: {
          ...this.state.industryToEdit,
          Name: event.target.value,
        },
      });
    } else {
      this.setState({
        newIndustryName: event.target.value.trimLeft(),
      });
    }
  };

  closeAddEditTenantDialog = () => {
    this.setState({ addEditTenantDialogOpened: false, disableIndustryField: true });
  };

  closeAddEditDeleteIndustryDialog = () => {
    this.setState({
      addEditDeleteIndustryDialogOpened: false,
      addEditTenantDialogOpened: true,
      disableIndustryField: true,
    });
  };

  loginAsUser = (selectedUser, tenant) => {
    this.props.loginAsUser({ user: selectedUser, tenant });
  };

  openAddEditTenantDialog = tenant => {
    if (tenant.Id) {
      this.setState(
        {
          addEditTenantDialogOpened: true,
          tenantToEdit: {
            ...tenant,
          },
        },
        () => {
          const { IndustryId } = tenant;
          const industry = this.props.industryList.find(({ IndustryID }) => IndustryID === IndustryId);
          if (industry) {
            this.props.requestFetchTiers(industry.MeeteringGroupID);
          }
        },
      );
    } else {
      this.props.clearTiers();
      this.setState({ addEditTenantDialogOpened: true, tenantToEdit: null });
    }
  };

  openAddEditDeleteIndustryDialog = () => {
    this.setState({ addEditDeleteIndustryDialogOpened: true });
  };

  openPricingEditDialog = currentTenant => e => {
    e.stopPropagation();
    const { industryList } = this.props;
    const industry = industryList.find(({ Name }) => Name === currentTenant.IndustryName);

    if (industry) {
      this.props.clearTiers();
      this.props.requestFetchTiers(industry.MeeteringGroupID);
    }

    this.setState({
      isPricingEditDialogOpen: true,
      tenantToEdit: {
        ...currentTenant,
      },
    });
  };

  closeEditPricingDialog = () => {
    this.setState({ isPricingEditDialogOpen: false });
  };

  renderRightControls = tenant => (
    <S.ControlsBlock>
      <PopoverMenu
        icon={<S.GroupIconColored />}
        label={'login as'}
        menuTitle="Login As"
        keyName="Name"
        labelPosition="before"
        emptyText="No available users"
        data={this.props.loginAsUsers}
        onItemClick={selectedUser => this.loginAsUser(selectedUser, tenant)}
        onOpen={() => this.onOpenLoginAsUsers(tenant)}
      />
      <ButtonIcon
        onClick={() => this.onToggleBlockTenant(tenant)}
        tooltip={tenant.Status === 'Active' ? 'Block tenant' : 'Unlock tenant'}
      >
        {tenant.Status === 'Active' ? <PauseIcon /> : <PlayIcon />}
      </ButtonIcon>
    </S.ControlsBlock>
  );

  filterTenantsList = ({ Name }) => Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  onSubmit = tenant => {
    const { tenantToEdit } = this.state;
    if (tenantToEdit) {
      this.props.editTenant(tenant);
    } else {
      this.props.addTenant(tenant);
    }
    this.closeAddEditTenantDialog();
  };

  submitPricing = values => {
    const { tenantToEdit } = this.state;

    if (!values.MasterTierId) {
      values.MasterTierId = EMPTY_GUID;
    }

    const TenantTierDto = {
      TenantId: tenantToEdit.Id,
      IsOverwritten: false,
      ...values,
    };

    this.props.requestOverwritePricingValues(TenantTierDto);
    this.closeEditPricingDialog();
  };

  render() {
    const {
      addEditTenantDialogOpened,
      addEditDeleteIndustryDialogOpened,
      disableIndustryField,
      industryToEdit,
      newIndustryName,
      searchData,
      tenantToEdit,
      isPricingEditDialogOpen,
    } = this.state;
    const { industryList, tenantsList, tiersList } = this.props;

    return [
      <Subheader
        key="Tenants list subheader"
        rightButtons={[
          {
            icon: <AddIcon />,
            handler: this.openAddEditTenantDialog,
            hint: 'Add',
          },
        ]}
        title="Tenants"
        subTitleIcon={<TenantsModuleIcon />}
        isSearch
        searchData={searchData}
        searchInList={this.searchInList}
      />,
      <SimpleList
        key="Tenants"
        data={tenantsList.filter(this.filterTenantsList)}
        emptyListMessage="There are no tenants avaliable"
        onItemClick={this.openAddEditTenantDialog}
        renderItemContent={Item(this.openPricingEditDialog)}
        renderRightControls={item => this.renderRightControls(item)}
      />,
      <AddEditTenantDialog
        key="Add edit tenant dialog"
        handleClose={this.closeAddEditTenantDialog}
        handleDelete={this.onDeleteTenant}
        onSubmit={this.onSubmit}
        open={addEditTenantDialogOpened}
        tenant={tenantToEdit || undefined}
        title={tenantToEdit ? 'Edit Tenant' : 'Add Tenant'}
        withDelete={!!tenantToEdit}
        enabledIsOEM={!tenantToEdit}
        dataIndustry={industryList}
        tiers={tiersList}
        onItemClick={this.openAddEditDeleteIndustryDialog}
        onCloseDialog={this.closeAddEditDeleteIndustryDialog}
        onIndustryChange={this.handleIndustryChange}
        handleAddIndustry={this.onAddIndustry}
        handleDeleteIndustry={this.onDeleteIndustry}
        handleEditIndustry={this.onEditIndustry}
        openIndustryDialog={addEditDeleteIndustryDialogOpened}
        enableField={disableIndustryField}
        onEnableEditIndustry={this.onEnableEditIndustry}
        handleChange={this.handleChange}
        newIndustryName={newIndustryName}
        editIndustryName={industryToEdit.Name}
      />,
      <EditPricingDialog
        title="Edit Pricing"
        open={isPricingEditDialogOpen}
        handleClose={this.closeEditPricingDialog}
        onSubmit={this.submitPricing}
        tenant={tenantToEdit || {}}
        modalStyles={{ maxWidth: '75%' }}
      />,
    ];
  }
}

const mapStateToProps = ({ tenants }) => ({
  createdEditedTenantId: tenants.createdEditedTenantId,
  error: tenants.error,
  industryList: tenants.industryList,
  tiersList: tenants.tiersList,
  loginAsUsers: tenants.loginAsUsers,
  tenantsList: tenants.list,
});

const mapDispatchToProps = {
  addIndustry: tenantsActions.addTenantIndustryRequest,
  addTenant: tenantsActions.addTenantRequest,
  activateTenant: tenantsActions.activateTenantRequest,
  deactivateTenant: tenantsActions.deactivateTenantRequest,
  deleteIndustry: tenantsActions.deleteTenantIndustryRequest,
  deleteTenant: tenantsActions.deleteTenantRequest,
  editIndustry: tenantsActions.editTenantIndustryRequest,
  editTenant: tenantsActions.editTenantRequest,
  fetchTenants: tenantsActions.tenantsListRequest,
  fetchLoginAsUsers: tenantsActions.usersLoginasListRequest,
  fetchIndustry: tenantsActions.tenantIndustryListRequest,
  loginAsUser: authActions.usersLoginasRequest,
  openConfirmationDialog,
  openErrorDialog,
  requestFetchTiers: tenantsActions.fetchTiersRequest,
  clearTiers: tenantsActions.clearTiers,
  requestOverwritePricingValues: tenantsActions.overwritePricingValuesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantsList);
