const initialState = {
  alertTrends: { data: [], dataInfo: {} },
  error: '',
  favoriteSites: { siteIds: [] },
  isLoading: false,
  list: [],
  notFavoriteAlerts: 0,
};

export const getItemsWithAlertRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getItemsWithAlertSuccess = (state, { payload }) => ({
  ...state,
  list: payload.SitesList,
  notFavoriteAlerts: payload.TotalCount,
  isLoading: false,
});

export const getItemsWithAlertFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getFavoriteSitesRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getFavoriteSitesSuccess = (state, { payload }) => ({
  ...state,
  favoriteSites: payload,
  isLoading: false,
});

export const getFavoriteSitesFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const setFavoriteSitesRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const setFavoriteSitesSuccess = state => ({
  ...state,
  isLoading: false,
});

export const setFavoriteSitesFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getAlertTrendsRequest = state => ({
  ...state,
  alertTrends: initialState.alertTrends,
  error: '',
  isLoading: true,
});

export const getAlertTrendsSuccess = (state, { payload }) => ({
  ...state,
  alertTrends: payload,
  isLoading: false,
});

export const getAlertTrendsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
