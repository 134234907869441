import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as settingsActions } from 'redux/settings';
import { actions as tenantsActions } from 'redux/tenants';

import DemoTierDialog from '../DemoTierEditDialog';
import { PitchGroup, CustomItem, FirstItem, LastItem, Container, EmptyItem } from './styled';

class DemoTierItem extends Component {
  static propTypes = {
    error: PropTypes.string.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    demoTier: PropTypes.object.isRequired,
    requestSystemLimits: PropTypes.func.isRequired,
    updateDemoTierLimits: PropTypes.func.isRequired,
    requestCurrentTenantsCount: PropTypes.func.isRequired,
    currentTenantsCount: PropTypes.number.isRequired,
  };

  state = {
    demoTierOpened: false,
  };

  componentDidMount() {
    this.props.requestSystemLimits();
    this.props.requestCurrentTenantsCount();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.props.openErrorDialog(nextProps.error, 'Error');
    }
  }

  openDemoTierDialog = () => {
    this.setState({ demoTierOpened: true });
  };

  closeDemoTierDialog = () => this.setState({ demoTierOpened: false });

  submitDemoTier = values => {
    this.props.updateDemoTierLimits(values);
    this.closeDemoTierDialog();
  };

  render() {
    const { demoTierOpened } = this.state;
    const { demoTier, currentTenantsCount } = this.props;
    return (
      <Container>
        <PitchGroup onClick={this.openDemoTierDialog}>
          <FirstItem>Demo tenants</FirstItem>
          <CustomItem>Max Sites: {demoTier.DemoTenantMaxSitesEach}</CustomItem>
          <CustomItem>Max Items: {demoTier.DemoTenantMaxItemsEach}</CustomItem>
          <EmptyItem>$$$$$</EmptyItem>
          <CustomItem>Max Sensors: {demoTier.DemoTenantMaxSensorsEach}</CustomItem>
          <EmptyItem>$$$$$</EmptyItem>
          <LastItem>Max Month: {demoTier.DemoTenantMaxTimeMonths}</LastItem>
        </PitchGroup>
        <DemoTierDialog
          key="Demo Tier"
          handleClose={this.closeDemoTierDialog}
          onSubmit={this.submitDemoTier}
          open={demoTierOpened}
          systemLimits={demoTier}
          currentTenantsCount={currentTenantsCount}
          title="Demo Tenants"
        />
      </Container>
    );
  }
}

DemoTierItem.propTypes = {
  tier: PropTypes.shape({
    DemoTenantMaxSitesEach: PropTypes.number,
    DemoTenantMaxItemsEach: PropTypes.number,
    DemoTenantMaxSensorsEach: PropTypes.number,
    DemoTenantMaxTimeMonths: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ settings: { systemLimits }, tenants: { currentTenantsCount } }) => ({
  demoTier: systemLimits,
  currentTenantsCount,
});

const mapDispatchToProps = {
  openConfirmationDialog,
  openErrorDialog,
  requestSystemLimits: settingsActions.getSystemLimitsRequest,
  updateDemoTierLimits: settingsActions.setSystemLimitsRequest,
  requestCurrentTenantsCount: tenantsActions.getCurrentTenantsCountRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoTierItem);
