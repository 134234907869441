import { call, put, takeEvery } from 'redux-saga/effects';

import { formatServerError } from 'helpers';
import { actions } from './index';
import {
  deleteDistributionList,
  getDistributionList,
  previewDistributionList,
  getDistributionListGroups,
  getDistributionListGroupsDetails,
  deleteDistributionListGroups,
  getDistributionListDetails,
} from 'http/distributions';

function* getDistributions() {
  try {
    const { data } = yield call(getDistributionList);
    yield put(actions.getDistributionsSuccess(data.root));
  } catch (error) {
    yield put(actions.getDistribuitonsFailure(formatServerError(error, 'An error occurred while fetching parts.')));
  }
}

function* deleteDistributions({ payload }) {
  try {
    yield call(deleteDistributionList, payload);
    yield put(actions.deleteDistributionsSuccess());
    yield* getDistributions();
  } catch (error) {
    yield put(
      actions.deleteDistributionsFailure(
        formatServerError(error, 'An error occurred while deleting distribution items.'),
      ),
    );
  }
}

function* getDistributionListPreview({ payload }) {
  try {
    const { data } = yield call(previewDistributionList, payload);
    yield put(actions.getDistributionListPreviewSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getDistributionListPreviewFailure(
        formatServerError(error, 'An error occurred while getting distribution list preview'),
      ),
    );
  }
}

function* getDistributionDetails({ payload }) {
  try {
    const { data } = yield call(getDistributionListDetails, payload);
    yield put(actions.getDistributionDetailsSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getDistributionDetailsFailure(
        formatServerError(error, 'An error occurred while fetching distribution details'),
      ),
    );
  }
}

function* getDistributionsGroups() {
  try {
    const { data } = yield call(getDistributionListGroups);
    yield put(actions.getDistributionsGroupsSuccess(data.root));
  } catch (error) {
    yield put(
      actions.getDistributionsGroupsFailure(
        formatServerError(error, 'An error occurred while fetching distribution groups list'),
      ),
    );
  }
}

function* deleteDistributionsGroups({ payload }) {
  try {
    yield call(deleteDistributionListGroups, payload);
    yield put(actions.deleteDistributionsGroupsSuccess());
    yield* getDistributionsGroups();
  } catch (error) {
    yield put(
      actions.deleteDistributionsGroupsFailure(
        formatServerError(error, 'An error occurred while deleting distribution items.'),
      ),
    );
  }
}

function* getDistributionGroupDetails({ payload }) {
  try {
    const { data } = yield call(getDistributionListGroupsDetails, payload);
    yield put(actions.getDistributionGroupDetailsSuccess(data.entity));
  } catch (error) {
    yield put(
      actions.getDistributionGroupDetailsFailure(
        formatServerError(error, 'An error occurred while fetching distribution group details'),
      ),
    );
  }
}

const distributionsSagas = [
  takeEvery(actions.getDistributionsRequest, getDistributions),
  takeEvery(actions.deleteDistributionsRequest, deleteDistributions),
  takeEvery(actions.getDistributionListPreviewRequest, getDistributionListPreview),
  takeEvery(actions.getDistributionDetailsRequest, getDistributionDetails),
  takeEvery(actions.getDistributionsGroupsRequest, getDistributionsGroups),
  takeEvery(actions.deleteDistributionsGroupsRequest, deleteDistributionsGroups),
  takeEvery(actions.getDistributionGroupDetailsRequest, getDistributionGroupDetails),
];

export default distributionsSagas;
