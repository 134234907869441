import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';
import theme from 'theme';
import { ActionButton, ActionsContainer, Dialog } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { TextareaField } from 'components/Layout/Textarea';
import { actions as tutorialsActions } from 'redux/tutorials';
import { LandingPageSectionType } from 'configs/propTypes';

import { validator, feedType, ERROR_MESSAGE } from './helpers';

import * as S from './styled';

class VideoFeetSetup extends PureComponent {
  state = {
    isDeleteConfirmationModalOpen: false,
    feedIdError: '',
  };

  openDeleteConfirmationModal = () => this.setState({ isDeleteConfirmationModalOpen: true });

  closeDeleteConfirmationModal = () => this.setState({ isDeleteConfirmationModalOpen: false });

  onDelete = () => {
    const { handleClose, actions, landingPageSection, landingPageID } = this.props;
    this.closeDeleteConfirmationModal();

    actions.deleteLandingPageSectionRequest({
      id: landingPageSection.Id,
      landingPageID,
    });
    handleClose();
  };

  showFeedIdError = () => this.setState({ feedIdError: ERROR_MESSAGE });

  clearFeedIdError = () => this.setState({ feedIdError: '' });

  onFeedIdFieldBlur = e => {
    if (e.currentTarget.value) {
      this.clearFeedIdError();
    } else {
      this.showFeedIdError();
    }
  };

  onFeedIdChange = value => {
    if (value) {
      this.clearFeedIdError();
    } else {
      this.showFeedIdError();
    }
  };

  onSubmit = values => {
    const { Header, Subtitle, FeedType, FeedID } = values;
    const { isEditModal, actions, landingPageSection, handleClose, landingPageID } = this.props;
    const NONE = -1;

    if (!FeedID) {
      this.showFeedIdError();
      return;
    }

    const payload = {
      landingPageID,
      header: Header,
      subtitle: Subtitle,
      feedType: FeedType,
      feedID: FeedID || NONE,
      id: landingPageSection.Id,
    };

    if (isEditModal) {
      actions.updateLandingPageSectionRequest(payload);
    } else {
      actions.createLandingPageSectionRequest(payload);
    }
    handleClose();
  };

  onModalClose = () => {
    this.clearFeedIdError();
    this.props.handleClose();
  };

  render() {
    const { isEditModal, isLoading, open, landingPageName, landingPageSection } = this.props;

    const { isDeleteConfirmationModalOpen, feedIdError } = this.state;

    return (
      <Dialog
        open={open}
        onRequestClose={this.onModalClose}
        maxWidth="550px"
        title={isEditModal ? 'Edit Video Feed' : 'Video Feed Setup'}
        titleColor={theme.headerColor}
      >
        <S.Overlay show={isLoading}>
          <S.Loader />
        </S.Overlay>
        <S.Header>
          <S.Title>Landing Page</S.Title>
          <S.Name>{landingPageName}</S.Name>
          <S.Paragraph>Setup a Video Feed Section</S.Paragraph>
        </S.Header>
        <Form
          onSubmit={this.onSubmit}
          validate={validator}
          initialValues={isEditModal ? landingPageSection : {}}
          render={({ handleSubmit, form }) => {
            const feedIdFieldState = form.getFieldState('FeedID');

            return (
              <S.FormContainer onSubmit={handleSubmit}>
                <S.TextareaContainer>
                  <Field component={TextareaField} floatingLabelText="Title Header" name="Header" />
                  <Field component={TextareaField} floatingLabelText="Subtitle" name="Subtitle" />
                </S.TextareaContainer>
                <S.Container>
                  <S.Label>Feed Parameters</S.Label>
                  <S.SecondaryBlockContainer>
                    <Field component={S.SelectField} floatingLabelText="Feed Type" name="FeedType">
                      {feedType.map(({ name, value }) => (
                        <MenuItem primaryText={name} value={value} />
                      ))}
                    </Field>
                    <Field
                      component={TextareaField}
                      floatingLabelText="Feed ID"
                      name="FeedID"
                      isDecimal
                      meta={{ ...feedIdFieldState, error: !!feedIdError }}
                      onBlur={this.onFeedIdFieldBlur}
                      onChangeControl={this.onFeedIdChange}
                    />
                  </S.SecondaryBlockContainer>
                </S.Container>
                {feedIdError && <S.ErrorText>{feedIdError}</S.ErrorText>}
                <ActionsContainer gapValue="10" paddingValue="0">
                  {isEditModal && (
                    <ActionButton label="Delete" type="button" onClick={this.openDeleteConfirmationModal} />
                  )}
                  <ActionButton label="Save" type="submit" isNext />
                </ActionsContainer>
              </S.FormContainer>
            );
          }}
        />
        <ConfirmationDialog
          label="Yes"
          maxWidth="80%"
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationWarning>
                This action cannot be undone,
                <br /> are you sure?
              </S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={isDeleteConfirmationModalOpen}
          onClick={this.onDelete}
          handleClose={this.closeDeleteConfirmationModal}
        />
      </Dialog>
    );
  }
}

VideoFeetSetup.propTypes = {
  isEditModal: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  landingPageID: PropTypes.string.isRequired,
  landingPageName: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    createLandingPageSectionRequest: PropTypes.func.isRequired,
    updateLandingPageSectionRequest: PropTypes.func.isRequired,
    deleteLandingPageSectionRequest: PropTypes.func.isRequired,
  }).isRequired,
  landingPageSection: LandingPageSectionType.isRequired,
};

const mapStateToProps = ({ tutorials: { landingPageSection, loading } }) => ({
  landingPageSection,
  isLoading: loading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...tutorialsActions,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoFeetSetup);
