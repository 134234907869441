import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 0 20px 20px;
`;

export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 10px 15px 0;

  button {
    display: flex !important;
    align-self: center;
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

export const TopInfo = styled.div``;

export const SubInfo = styled.div`
  margin-bottom: 6px;
`;
