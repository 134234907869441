import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper, Button, ReleaseButton, Centered, Label } from './styled';

const Footer = ({ onCenter, onNext, onPrev, period, label, visible, isCentralButtonVisible, isChecked, labelName }) => {
  let prev;
  let next;

  if (period === 'day') {
    prev = 'Previous Day';
    next = 'Next Day';
  }
  if (period === 'week') {
    prev = 'Previous Week';
    next = 'Next Week';
  }

  return visible ? (
    <Container>
      <Centered>
        {isCentralButtonVisible && (
          <ReleaseButton disabled={!isChecked} label={labelName} onClick={onCenter}>
            {!!label && <Label>{label}</Label>}
          </ReleaseButton>
        )}
      </Centered>
      <Wrapper>
        {prev && <Button label={prev} onClick={onPrev} />}
        {next && <Button label={next} onClick={onNext} />}
      </Wrapper>
    </Container>
  ) : null;
};

Footer.defaultProps = {
  isCentralButtonVisible: true,
  onCenter: () => {},
  visible: true,
  label: null,
};

Footer.propTypes = {
  onCenter: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  period: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  label: PropTypes.number,
  labelName: PropTypes.string.isRequired,
  isCentralButtonVisible: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
};

export default Footer;
