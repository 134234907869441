import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';

import http from '../../../../../../http';
import { UnitItemPictureType, UnitItemTaskTemplateType, UnitItemType } from 'configs/propTypes';
import { CopyIcon, ExportQRIcon, ImageIcon, MoreVerticalIcon, PdfIcon, SettingsIcon } from 'components/Layout/Icons';
import ImageSwiper from 'components/ImageSwiper';
import Information from './Information';
import Tasks from './Tasks';

import { ActionsContainer, CardContent, Container, Header, IconButton, MenuActions } from './styled';

const getImageSource = picture =>
  `${http.defaults.baseURL}` +
  `/MasterItemFile/Get/?parentId=${picture.ItemId}&id=${picture.Id}&width=200&height=200&inline=true`;

class Item extends PureComponent {
  static propTypes = {
    item: UnitItemType.isRequired,
    expanded: PropTypes.string.isRequired,
    onAddPdfClick: PropTypes.func.isRequired,
    onAddPhotosClick: PropTypes.func.isRequired,
    onToggleCollapse: PropTypes.func.isRequired,
    pictures: PropTypes.arrayOf(UnitItemPictureType).isRequired,
    itemTasksList: PropTypes.arrayOf(UnitItemTaskTemplateType).isRequired,
  };

  editItemPdfs = () => {
    this.props.onAddPdfClick(this.props.item.Id);
  };

  editItemPictures = () => {
    this.props.onAddPhotosClick(this.props.item.Id);
  };

  toggleCollapse = () => {
    this.props.onToggleCollapse(this.props.item.Id);
  };

  itemActions = (
    <div>
      <ActionsContainer>
        <IconButton disabled>
          <CopyIcon />
        </IconButton>
        <IconButton disabled>
          <SettingsIcon />
        </IconButton>
        <IconButton onClick={this.editItemPictures} tooltip="Photos">
          <ImageIcon />
        </IconButton>
        <IconButton onClick={this.editItemPdfs} tooltip="PDFs">
          <PdfIcon />
        </IconButton>
        <IconButton disabled>
          <ExportQRIcon />
        </IconButton>
      </ActionsContainer>
      <MenuActions
        iconButtonElement={
          <IconButton>
            <MoreVerticalIcon />
          </IconButton>
        }
      >
        <MenuItem primaryText="Duplicate" />
        <MenuItem primaryText="Edit" />
        <MenuItem onClick={this.editItemPictures} primaryText="Photos" />
        <MenuItem onClick={this.editItemPdfs} primaryText="PDFs" />
        <MenuItem primaryText="Export QR" />
      </MenuActions>
    </div>
  );

  render() {
    const { item, itemTasksList, pictures } = this.props;

    return (
      <Container expanded={this.props.expanded} onExpandChange={this.toggleCollapse}>
        <Header showExpandableButton subtitle={this.itemActions} title={`(${item.Sequence}) ${item.Name}`} />
        <CardContent expandable>
          <Information item={item} />
          <ImageSwiper
            getImageSource={getImageSource}
            noPicturesMessage="This item has no photos."
            pictures={pictures}
          />
        </CardContent>
        <CardContent expandable>
          <Tasks tasks={itemTasksList} />
        </CardContent>
      </Container>
    );
  }
}

const mapStateToProps = ({ exchange }) => ({
  itemTasksList: exchange.itemTasksList,
});

export default connect(mapStateToProps)(Item);
