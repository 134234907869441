import styled from 'styled-components';
import { BorderRadiusContainer, InputBlockLabel } from 'components/Layout/Containers';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import { ProgressCircular } from 'components/Layout/Progresses';

export const Label = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
  text-align: left;
  padding: 20px 15px 15px;
`;

export const Container = styled(BorderRadiusContainer)`
  margin: 0 0 20px 0;
`;

export const FormContainer = styled.form`
  padding: 20px;
`;

export const Header = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
  margin-top: 20px;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

export const Title = styled.div`
  font-size: 20px;
`;

export const Paragraph = styled.p`
  font-size: 20px;
  margin-block-end: 0;
`;

export const SelectField = styled(SelectFieldAdapter)`
  margin-bottom: 20px;
`;

export const TextareaContainer = styled.div`
  margin: 0 20px;
`;

export const SecondaryBlockContainer = styled.div`
  padding: 0 20px 20px 20px;
`;

export const ConfirmationContainer = styled.div``;

export const ConfirmationWarning = styled.div`
  font-size: 18px;
  margin: 15px 0;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Overlay = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none !important')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.overlay};
  z-index: 2000;
`;

export const Loader = styled(ProgressCircular).attrs({
  size: 90,
})`
  circle {
    stroke: ${({ theme }) => `${theme.primary} !important`};
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  text-align: center;
  padding: 10px;
`;
