import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

const generatePreviewResults = entity => (
  <S.PreviewItemContainer>
    {entity.map(({ Name }) => (
      <S.PreviewItem>
        <S.PreviewItemText>{Name}</S.PreviewItemText>
      </S.PreviewItem>
    ))}
  </S.PreviewItemContainer>
);

const TestrunDialog = ({ open, handleClose, data, name, loading }) => (
  <Dialog open={open} onRequestClose={handleClose} title="Testrun Shortcut" titleColor={theme.mainDarkBlue}>
    <S.Container>
      <S.Overlay show={loading}>
        <S.Loader />
      </S.Overlay>
      {!loading && (
        <>
          <S.PreviewTitle>{name}</S.PreviewTitle>
          {data.length > 1 ? (
            <S.PreviewRedText>
              <S.WarningIcon iconColor="mainRed" />
              Only 1 Shortcut is expected !
            </S.PreviewRedText>
          ) : (
            <S.WarningGrayText>
              <S.WarningIcon iconColor="textGray" />1 Shortcut is expected
            </S.WarningGrayText>
          )}
          <S.Section>
            <S.SectionHeader onHeader>
              Shortcut(s) Result
              <S.IconContainerPreview>
                <S.TestRunIconPreview />
              </S.IconContainerPreview>
            </S.SectionHeader>
            <S.SectionBody>
              <S.CounterContainer>
                <S.ItemsCount>
                  <S.BoltText>Shortcuts Found</S.BoltText>: {data.length}
                </S.ItemsCount>
              </S.CounterContainer>
              {generatePreviewResults(data)}
            </S.SectionBody>
          </S.Section>
          {data.length > 1 && (
            <S.PreviewRedText>To narrow the search result, please change the rule selections</S.PreviewRedText>
          )}
        </>
      )}
    </S.Container>
  </Dialog>
);

TestrunDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

TestrunDialog.defaultProps = {
  withHeader: true,
  CustomHeaderComponent: null,
  shortcutName: '',
};

export default TestrunDialog;
