import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DocumentItem } from 'configs/propTypes';
import { actions as documentsActions } from 'redux/documents';
import { AddIcon } from 'components/Layout/Icons';
import { ContentContainer } from 'components/Layout/Containers';
import Subheader from 'components/Subheader';
import Table from './Table';
import AddEditDocumentDialog from '../AddEditDocumentDialog';

class DocumentsList extends Component {
  static propTypes = {
    addDocument: PropTypes.func.isRequired,
    documentCreated: PropTypes.bool.isRequired,
    requestFetchDocumentsList: PropTypes.func.isRequired,
    documents: PropTypes.arrayOf(DocumentItem).isRequired,
  };

  state = {
    addDocumentDialogOpened: false,
  };

  componentDidMount() {
    this.props.requestFetchDocumentsList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.documentCreated) {
      this.closeAddDocumentDialog();
    }
  }

  closeAddDocumentDialog = () => {
    this.setState({ addDocumentDialogOpened: false });
  };

  openAddDocumentDialog = () => {
    this.setState({ addDocumentDialogOpened: true });
  };

  render() {
    const { addDocument, documents } = this.props;

    return (
      <ContentContainer>
        <Subheader
          title="Documents"
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openAddDocumentDialog,
              hint: 'Add',
            },
          ]}
        />
        <Table documents={documents} />
        <AddEditDocumentDialog
          handleClose={this.closeAddDocumentDialog}
          onSubmit={addDocument}
          open={this.state.addDocumentDialogOpened}
          title="Add Document"
        />
      </ContentContainer>
    );
  }
}

const mapStateToProps = ({ documents }) => ({
  documents: documents.list,
  documentCreated: documents.documentCreated,
});

const mapDispatchToProps = {
  addDocument: documentsActions.addDocumentRequest,
  requestFetchDocumentsList: documentsActions.documentsListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList);
