import { encodeData } from 'helpers';

export const formatAssigned = assignedUsers => assignedUsers.map(item => item.UserId);

// sequence of data in request matters
export const formatSite = site => {
  encodeData(site);
  encodeData(site.Address);
  return (
    `dto.Id=${site.Id}&dto.Name=${site.Name}&dto.Phone=${site.Phone}&dto.BeaconId=${site.BeaconId}&` +
    `dto.BeaconDistance=${site.BeaconDistance}&dto.BeaconDistanceItem=${site.BeaconDistanceItem}&` +
    `dto.BeaconDistanceUnit=${site.BeaconDistanceUnit}&dto.AdministratorName=${site.AdministratorName}&` +
    `dto.AdministratorTitle=${site.AdministratorTitle}&dto.AdministratorEmail=${site.AdministratorEmail}&` +
    `dto.AdministratorPhone=${site.AdministratorPhone}&dto.Address.Street=${site.Address.Street}&` +
    `dto.Address.Street2=${site.Address.Street2}&dto.Address.City=${site.Address.City}&` +
    `dto.Address.State=${site.Address.State}&dto.Address.ZipCode=${site.Address.ZipCode}&` +
    `dto.Address.Country=${site.Address.Country}&id=${site.Id}&` +
    `dto.AdditionalSiteID=${site.AdditionalSiteID}`
  );
};
