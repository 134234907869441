import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as authActions } from 'redux/auth';
import { openErrorDialog } from 'redux/errorHandler';

import * as CS from 'modules/auth/styled';

import SuccessDialog from './SuccessDialog';
import TextInput from './TextInput';
import { nothingAvailableText, signupText, signUpInputFields, entryExists, activeFieldsObject } from './constants';
import validate from './validator';
import * as S from './styled';

const tenantsCountFields = [
  { field: 'AvailableDemoTenantsCount', title: 'Available' },
  { field: 'DemoTenantMonthTerm', title: 'Months Term' },
];

class SignUpForm extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      openErrorDialog: PropTypes.func.isRequired,
      requestCreateDemoTenant: PropTypes.func.isRequired,
      requestDemoTenantName: PropTypes.func.isRequired,
      requestDemoUserName: PropTypes.func.isRequired,
    }).isRequired,
    authMode: PropTypes.oneOf(['signin', 'signup']).isRequired,
    isDemoUserNameExists: PropTypes.bool.isRequired,
    isDemoTenantNameExists: PropTypes.bool.isRequired,
    industryList: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string,
        Name: PropTypes.string,
      }),
    ).isRequired,
    tenantRequestsStatistics: PropTypes.shape({
      AvailableDemoTenantsCount: PropTypes.number,
      DemoTenantMonthTerm: PropTypes.number,
    }).isRequired,
    chooseSignInTab: PropTypes.func.isRequired,
  };

  state = {
    activeFields: activeFieldsObject,
    successDialogOpen: false,
  };

  openSuccessDialog = () => this.setState({ successDialogOpen: true });
  closeSuccessDialog = form => {
    form.reset();
    this.setState({ successDialogOpen: false, activeFields: activeFieldsObject });
    this.props.chooseSignInTab();
  };

  activateField = (fieldName, value) => {
    if (!value) {
      this.props.actions.openErrorDialog(entryExists, 'Error');
    }
    this.setState({ activeFields: { ...this.state.activeFields, [fieldName]: value } });
  };

  handleInputNext = (fieldToActivate, currentValue) => {
    const { fieldName, value } = currentValue;
    const { actions } = this.props;

    this.setState({ activeFields: { ...this.state.activeFields, [fieldToActivate]: true } });

    if (fieldName === 'userName') {
      actions.requestDemoUserName({ userName: value, cb: this.activateField });
    }

    if (fieldName === 'tenantName') {
      actions.requestDemoTenantName({ tenantName: value, cb: this.activateField });
    }
  };

  onFormSubmit = values =>
    this.props.actions.requestCreateDemoTenant({ values, openSuccessDialog: this.openSuccessDialog });

  render() {
    const { authMode, industryList, tenantRequestsStatistics } = this.props;
    const { activeFields, successDialogOpen } = this.state;

    return (
      <S.SignUpContainer authMode={authMode}>
        <S.SignUpTitle>Sign up for a free demonstration account.</S.SignUpTitle>
        <S.AccountsInfoContainer>
          <S.AccountsDataContainer>
            {tenantsCountFields.map(({ field, title }) => (
              <S.DataBlock key={field}>
                <S.AccountDataSquare>
                  {Number.isInteger(+tenantRequestsStatistics[field])
                    ? Math.round(tenantRequestsStatistics[field])
                    : tenantRequestsStatistics[field]}
                </S.AccountDataSquare>
                <S.AccountDataLabel>{title}</S.AccountDataLabel>
              </S.DataBlock>
            ))}
          </S.AccountsDataContainer>
          <S.AccountInfoTextContainer>
            {tenantRequestsStatistics.AvailableDemoTenantsCount === 0 && (
              <S.AccountInfoText>{nothingAvailableText}</S.AccountInfoText>
            )}
            <S.AccountInfoText>{signupText}</S.AccountInfoText>
          </S.AccountInfoTextContainer>
        </S.AccountsInfoContainer>
        <S.FormLabel>Start with a unique name, email or phone#</S.FormLabel>
        <Form
          onSubmit={this.onFormSubmit}
          validate={validate}
          render={({ handleSubmit, form, submitting, values }) => (
            <S.FormContainer onSubmit={handleSubmit} noValidate authMode={authMode}>
              {signUpInputFields.map(
                ({ fieldName, nextField, placeholder }) =>
                  (fieldName === 'userName' || activeFields[fieldName]) && (
                    <S.FieldContainer key={fieldName}>
                      <TextInput
                        name={fieldName}
                        placeholder={placeholder}
                        isFirst={fieldName === 'userName'}
                        isLast={!activeFields[nextField]}
                      />
                      {values[fieldName] && !activeFields[nextField] && (
                        <S.NextButtonContainer
                          type="button"
                          onClick={() => this.handleInputNext(nextField, { fieldName, value: values[fieldName] })}
                        >
                          &#10142;
                        </S.NextButtonContainer>
                      )}
                    </S.FieldContainer>
                  ),
              )}
              {activeFields.industry && !!industryList.length && (
                <Field name="industryId" placeholder="Select Closest Industry Type">
                  {({ input, ...rest }) => (
                    <CS.Select {...input} {...rest}>
                      <option value="" disabled hidden>
                        Select Closest Industry Type
                      </option>
                      {industryList.map(({ Id, Name }) => (
                        <option key={Id} value={Id}>
                          {Name}
                        </option>
                      ))}
                    </CS.Select>
                  )}
                </Field>
              )}
              {values.industryId && (
                <CS.SubmitButton type="submit" disabled={submitting}>
                  SUBMIT
                </CS.SubmitButton>
              )}
              <SuccessDialog open={successDialogOpen} onClose={() => this.closeSuccessDialog(form)} />
            </S.FormContainer>
          )}
        />
      </S.SignUpContainer>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isDemoUserNameExists: auth.isDemoUserNameExists,
  isTenantNameExists: auth.isDemoTenantNameExists,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openErrorDialog,
      requestCreateDemoTenant: authActions.createDemoTenantRequest,
      requestDemoTenantName: authActions.checkIsDemoTenantNameExistsRequest,
      requestDemoUserName: authActions.checkIsDemoUserNameExistsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
