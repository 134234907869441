import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { SensorTypeType } from 'configs/propTypes';
import { resizeWindow } from 'helpers';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as sensorsActions } from 'redux/sensors';
import Subheader from 'components/Subheader';
import SimpleList from 'components/SimpleList';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import { ButtonIcon } from 'components/Layout/Buttons';
import { AddIcon, BackIcon } from 'components/Layout/Icons';
import LevelSettingsDialog from 'components/Dialogs/LevelSettingsDialog';
import AddEditSensorTypeDialog from './AddEditSensorTypeDialog';
import Item from './Item';

import * as S from './styled';

class SensorTypes extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      deleteSensorTypeRequest: PropTypes.func.isRequired,
      getSensorLevelsSettingsRequest: PropTypes.func.isRequired,
      getSensorTypesRequest: PropTypes.func.isRequired,
      openConfirmationDialog: PropTypes.func.isRequired,
      openErrorDialog: PropTypes.func.isRequired,
      setSensorLevelsSettingsRequest: PropTypes.func.isRequired,
      setSensorTypeRequest: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    typesList: PropTypes.arrayOf(SensorTypeType).isRequired,
  };

  state = {
    levelSettingsDialogOpened: false,
    searchData: '',
    selectedSensorType: {},
    sensorTypeDialogOpened: false,
    withDeleteSensorType: false,
    isConfirmationDialogOpened: false,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.getSensorTypesRequest();
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;

    if (nextProps.error) {
      actions.openErrorDialog(nextProps.error, 'Error');
    }
  }

  backToSettings = () => {
    this.props.history.push('/settings');
  };

  closeSensorTypeDialog = () => {
    this.setState({ sensorTypeDialogOpened: false });
  };

  openSensorTypeDialog = item => {
    this.setState({
      selectedSensorType: item.Id ? item : {},
      sensorTypeDialogOpened: true,
      withDeleteSensorType: !!item.Id,
    });

    setTimeout(() => resizeWindow(), 0);
  };

  closeLevelSettingsDialog = () => {
    this.setState({ levelSettingsDialogOpened: false });
  };

  openConfirmationDialog = () => this.setState({ isConfirmationDialogOpened: true });

  closeConfirmationDialog = () => this.setState({ isConfirmationDialogOpened: false });

  showLevelSettingsDialog = item => {
    this.setState({
      levelSettingsDialogOpened: true,
      selectedSensorType: item,
    });
  };

  openLevelSettingsDialog = sensor => {
    const { actions } = this.props;

    actions.getSensorLevelsSettingsRequest({ sensorData: sensor, callback: this.showLevelSettingsDialog });
  };

  filterSensorTypes = ({ Type }) => Type.toLowerCase().includes(this.state.searchData.toLowerCase());

  onDeleteSensorType = () => {
    const { actions } = this.props;
    const {
      selectedSensorType: { Id },
    } = this.state;

    actions.openConfirmationDialog(
      'Are you sure you want to permanently delete this Sensor Type? This cannot be undone!',
      () => actions.deleteSensorTypeRequest(Id),
      'Delete?',
    );

    this.closeSensorTypeDialog();
  };

  searchInList = e => {
    this.setState({ searchData: e.target.value });
  };

  submitLevelSettingsDialog = values => {
    this.openConfirmationDialog();
    const { actions } = this.props;
    const newValues = [];

    Object.keys(values).forEach(val => {
      if (val.endsWith('StartData')) {
        const id = val.slice(0, -9);

        const level = {
          LevelName: values[`${id}LevelName`],
          MasterSensorTypeId: values.MasterSensorTypeId,
          OrderField: values[`${id}OrderField`],
          StartData: values[val],
          ShouldSendAlert: values[`${id}ShouldSendAlert`],
        };

        if (values.escalation === 'both' && level.LevelName === 'Normal') {
          level.StartData = null;
        }

        if (values[`${id}Original`]) {
          level.Id = id;
        }

        newValues.push(level);
      } else if (val.endsWith('Delete')) {
        newValues.push({ deleteId: values[val] });
      }
    });

    actions.setSensorLevelsSettingsRequest(newValues);
    this.closeLevelSettingsDialog();
  };

  submitSensorTypeDialog = values => {
    const { actions } = this.props;

    actions.setSensorTypeRequest(values);
    this.closeSensorTypeDialog();
  };

  renderRightControls = (item, openLevelSettingsDialog) => (
    <div>
      <ButtonIcon onClick={() => openLevelSettingsDialog(item)} tooltip="Level Settings">
        <S.IconSettingsWhites />
      </ButtonIcon>
    </div>
  );

  render() {
    const { typesList } = this.props;
    const {
      levelSettingsDialogOpened,
      searchData,
      selectedSensorType,
      sensorTypeDialogOpened,
      withDeleteSensorType,
      isConfirmationDialogOpened,
    } = this.state;

    return (
      <>
        <Subheader
          title="Sensor Templates"
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backToSettings,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: <AddIcon />,
              handler: this.openSensorTypeDialog,
              hint: 'Add',
            },
          ]}
          hintText="Filter Templates"
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
        />
        <SimpleList
          data={typesList.filter(this.filterSensorTypes)}
          onItemClick={item => this.openSensorTypeDialog(item)}
          emptyListMessage="There are no sensor types avaliable"
          renderItemContent={item => <Item {...item} />}
          renderRightControls={item => this.renderRightControls(item, this.openLevelSettingsDialog)}
        />
        <AddEditSensorTypeDialog
          handleClose={this.closeSensorTypeDialog}
          title={`${selectedSensorType.Id ? 'Edit' : 'New'} Sensor Template`}
          open={sensorTypeDialogOpened}
          onSubmit={this.submitSensorTypeDialog}
          selectedSensorType={selectedSensorType}
          handleDelete={this.onDeleteSensorType}
          withDelete={withDeleteSensorType}
          withoutTopBorder
        />
        <LevelSettingsDialog
          handleClose={this.closeLevelSettingsDialog}
          title="Level Settings"
          open={levelSettingsDialogOpened}
          onSubmit={this.submitLevelSettingsDialog}
          selectedSensorType={selectedSensorType}
          withoutTopBorder
          isSettings
          isNewSubmitButton
          hasPadding
          withSubmit={false}
        />
        <ConfirmationDialog
          label="OK"
          isNext
          isGreenLabel
          maxWidth="80%"
          text={
            <S.ConfirmationContainer>
              <b>Not updated:</b>
              <S.ConfirmationWarning>
                Existing sensor settings have not been updated.Only this template! <br />
                (Select Items in List Templates to
                <br /> update their sensor settings.)
              </S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={isConfirmationDialogOpened}
          onClick={this.closeConfirmationDialog}
          handleClose={this.closeConfirmationDialog}
        />
      </>
    );
  }
}

const mapStateToProps = ({ sensors: { error, typesList } }) => ({
  error,
  typesList,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...sensorsActions,
      openConfirmationDialog,
      openErrorDialog,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorTypes);
