import React from 'react';
import PropTypes from 'prop-types';

import { ToggleItem } from 'configs/propTypes';
import { ToolbarContainer } from 'components/Layout/Containers';
import Toggle from 'components/Toggle';
import { Title } from './styled';

const Toolbar = ({
  location,
  periodConfig,
  periodHandler,
  selectedPeriod,
  selectedType,
  typeConfig,
  typeHandler,
  withoutTypeConfig,
}) => (
  <ToolbarContainer>
    <Toggle config={periodConfig} selected={selectedPeriod} handler={periodHandler} />
    <Title>{location}</Title>
    {!withoutTypeConfig ? <Toggle config={typeConfig} selected={selectedType} handler={typeHandler} /> : <div />}
  </ToolbarContainer>
);

Toolbar.propTypes = {
  location: PropTypes.string.isRequired,
  periodConfig: PropTypes.arrayOf(ToggleItem).isRequired,
  periodHandler: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  selectedType: PropTypes.string.isRequired,
  typeConfig: PropTypes.arrayOf(ToggleItem).isRequired,
  typeHandler: PropTypes.func.isRequired,
  withoutTypeConfig: PropTypes.bool,
};

Toolbar.defaultProps = {
  withoutTypeConfig: false,
};

export default Toolbar;
