import { stringComparator } from 'helpers';

export const getTenantHubs = list =>
  list
    .map(
      ({
        Name: siteName,
        Id: siteId,
        City: siteCity,
        Hubs,
        HubsNumber,
        Tenant: { Name: tenantName, IndustryName, DefaultAdminName },
      }) => ({
        tenant: { name: tenantName, industry: IndustryName, adminName: DefaultAdminName },
        site: { name: siteName, id: siteId, city: siteCity },
        hubs: Hubs,
        hubsNumber: HubsNumber,
      }),
    )
    .sort((a, b) => stringComparator(a.site.name, b.site.name, true));

export const warningTexts = {
  notification: 'Deleting All Hubs will delete all Gateways and Sensors',
  confirmation: 'Are you sure you want to delete ALL the IoT Hubs for this Site?',
};
