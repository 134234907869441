export const constraints = {
  0: 'is',
  1: 'is not',
  2: 'begins with',
  4: 'ends with',
  8: 'contains',
  16: 'does not contain',
};

export const matchOperator = {
  0: 'All',
  1: 'Any of the following rules',
};
