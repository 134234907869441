import React from 'react';
import * as S from './styled';

export const optionalVendorInfo = [
  { name: 'VendorName', hintText: 'Vendor Name', maxLength: '255' },
  { name: 'VendorEmail', hintText: 'Vendor Email', maxLength: '255' },
  { name: 'VendorPhone', hintText: 'Vendor Phone', maxLength: '255' },
  { name: 'VendorNotes', hintText: 'Vendor Notes', maxLength: '255' },
];

export const options = [
  {
    name: (
      <S.Text>
        This is a demonstration Template <S.Loyalty />
      </S.Text>
    ),
    value: 'Demo',
  },
  { name: <S.Text>This is a regular Template</S.Text>, value: 'Regular' },
  {
    name: (
      <S.Text>
        This is a private Template <S.Lock />
      </S.Text>
    ),
    value: 'Private',
  },
];
