import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';

import theme from 'theme';

import * as S from './styled';

const ConfirmationDialog = ({
  open,
  handleClose,
  title,
  label,
  labelCancel,
  isNext,
  isGreenLabel,
  isCancelNext,
  titleColor,
  onClick,
  onCancelClick,
  text,
  width,
  isColumn,
  withBorder,
  maxWidth,
  headingText,
  zIndex,
  descriptionText,
}) => (
  <Dialog
    open={open}
    onRequestClose={handleClose}
    title={title}
    titleColor={titleColor}
    width={width}
    maxWidth={maxWidth}
    zIndex={zIndex}
  >
    <S.DialogContainer>
      {text}
      {headingText ? <S.ConfirmationText>{headingText}</S.ConfirmationText> : null}
      {descriptionText ? <S.ConfirmationWarning>{descriptionText}</S.ConfirmationWarning> : null}
    </S.DialogContainer>
    <ActionsContainer>
      <S.Container isColumn={isColumn}>
        {onCancelClick && (
          <ActionButton
            withBorder={withBorder}
            label={labelCancel}
            onClick={onCancelClick}
            isNext={isNext}
            isCancelNext={isCancelNext}
          />
        )}
        {onClick && <ActionButton label={label} onClick={onClick} isNext={isNext} isGreenLabel={isGreenLabel} />}
      </S.Container>
    </ActionsContainer>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  label: PropTypes.string,
  labelCancel: PropTypes.string,
  isNext: PropTypes.bool,
  titleColor: PropTypes.string,
  width: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  headingText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
  isColumn: PropTypes.bool,
  withBorder: PropTypes.bool,
  zIndex: PropTypes.number,
  maxWidth: PropTypes.string.isRequired,
  isCancelNext: PropTypes.bool.isRequired,
  isGreenLabel: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  titleColor: theme.mainRed,
  title: 'Alert',
  label: 'Yes',
  labelCancel: 'Previous',
  headingText: '',
  descriptionText: '',
  onCancelClick: null,
  zIndex: 1500,
  width: '30%',
  isColumn: false,
  withBorder: false,
  maxWidth: '350px',
  isCancelNext: false,
  isNext: false,
  open: false,
  onClick: null,
  isGreenLabel: false,
};

export default ConfirmationDialog;
