import React from 'react';

import { Container, Progress } from './styled';

const StartApp = () => (
  <Container>
    <Progress />
  </Container>
);

export default StartApp;
