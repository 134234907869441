import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';

import { SensorType, SensorTypeType, UnitItemType, UnitType } from 'configs/propTypes';
import { actions as unitActions } from 'redux/unit';
import withInputModal from 'components/Dialogs/withInputModal';

import { getListWithAnassigned, getSiteName } from './selectors';
import { getTypesList } from 'redux/sensors/selectors';
import validate from './validator';

import * as S from './styled';

class ConfigSensorDialogForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      reset: PropTypes.func.isRequired,
    }).isRequired,
    requestFetchUnitItems: PropTypes.func.isRequired,
    SiteID: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    typesList: PropTypes.arrayOf(SensorTypeType).isRequired,
    unitItemsList: PropTypes.arrayOf(UnitItemType).isRequired,
    unitsList: PropTypes.arrayOf(UnitType).isRequired,
    values: PropTypes.shape({
      ItemId: PropTypes.string,
      Type: PropTypes.string,
      UnitId: PropTypes.string,
      SensorTypeID: PropTypes.string,
    }).isRequired,
  };

  onChangeControl = unitId => {
    const { form, SiteID, values } = this.props;

    if (unitId && unitId !== values.UnitId) {
      this.props.requestFetchUnitItems({ unitId, SiteID });

      form.reset({
        ...values,
        ItemId: '',
      });
    }
  };

  render() {
    const { siteName, typesList, unitItemsList, unitsList, values } = this.props;

    const selectedSensorType = typesList.find(item => item.Id === values.SensorTypeID);

    return (
      <S.Container>
        <S.Sensors>
          <S.TypeLabel>Sensor Type</S.TypeLabel>
          <S.SelectContainer>
            <Field component={S.SelectField} name="SensorTypeID" floatingLabelText="Sensor Type">
              {typesList.map(({ Id, Type }) => (
                <MenuItem key={Id} primaryText={Type} value={Id} />
              ))}
            </Field>
            <div>
              Sensor Levels:
              <S.LevelsCount>
                {typesList.length > 0 && selectedSensorType ? selectedSensorType.LevelsCount : 0}
              </S.LevelsCount>
            </div>
          </S.SelectContainer>
        </S.Sensors>
        <S.Sensors>
          <S.LinkLabel>Optional: Link sensor with Item</S.LinkLabel>
          <S.SelectContainer>
            <TextField floatingLabelText="Site" defaultValue={siteName} disabled fullWidth />
            <Field
              component={S.SelectField}
              name="UnitId"
              floatingLabelText="UnitList Name"
              onChangeControl={this.onChangeControl}
            >
              {unitsList.map(({ Id, Name }) => (
                <S.Item key={Id} primaryText={Name} value={Id} />
              ))}
            </Field>
            <Field component={S.SelectField} name="ItemId" floatingLabelText="Item Name" disabled={!values.UnitId}>
              {unitItemsList.map(({ Id, Name }) => (
                <S.Item key={Id} primaryText={Name} value={Id} />
              ))}
            </Field>
          </S.SelectContainer>
        </S.Sensors>
      </S.Container>
    );
  }
}

const ConfigSensorDialogWithInput = withInputModal(ConfigSensorDialogForm);

const ConfigSensorDialog = props => (
  <ConfigSensorDialogWithInput initialValues={{ ...props.selectedSensor }} validate={validate} {...props} />
);

ConfigSensorDialog.propTypes = {
  selectedSensor: SensorType.isRequired,
};

const mapStateToProps = (
  {
    auth: {
      user: { sitesAvailableToUserFullInfo },
    },
    sensors: { typesList },
    unit: { unitItemsList },
    units: { unitsList },
  },
  { selectedSensor: { SiteId } },
) => ({
  siteName: getSiteName(sitesAvailableToUserFullInfo, SiteId),
  typesList: getTypesList(typesList),
  unitItemsList: getListWithAnassigned(unitItemsList, 'item'),
  unitsList: getListWithAnassigned(unitsList, 'unit'),
});

const mapDispatchToProps = {
  requestFetchUnitItems: unitActions.fetchUnitItemsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSensorDialog);
