export const getSitesList = sites =>
  sites
    ? sites.map(({ Id, AdditionalSiteID, Name, NotificationHour, NotificationMinute, NotificationPeriod }) => ({
        SiteId: Id,
        Name: `${AdditionalSiteID} ${Name}`,
        Hour: `${NotificationHour}`,
        Minute: `${NotificationMinute}`,
        Period: NotificationPeriod.toLowerCase(),
      }))
    : [];

export const blank = () => {};
