import React from 'react';
import { INSTRUCTIONS_MODULES } from 'configs/routes';

import InstructionBlock from '../InstructionBlock';
import TutorialBlock from '../TutorialBlock';

const SelectInstructionModule = (userType, module, onNext, videosCount, isLoading) => {
  if (module === 'units' || module === 'siteparts') {
    return (
      INSTRUCTIONS_MODULES[userType][module] && (
        <>
          <InstructionBlock data={INSTRUCTIONS_MODULES[userType].units} isHome={false} />
          {INSTRUCTIONS_MODULES[userType].siteparts && (
            <InstructionBlock data={INSTRUCTIONS_MODULES[userType].siteparts} isHome={false} />
          )}
          <TutorialBlock
            data={INSTRUCTIONS_MODULES[userType][module]}
            isHome={false}
            onNext={onNext}
            videosCount={videosCount}
            isLoading={isLoading}
          />
        </>
      )
    );
  }

  return (
    INSTRUCTIONS_MODULES[userType][module] && (
      <>
        <InstructionBlock data={INSTRUCTIONS_MODULES[userType][module]} isHome={false} />
        <TutorialBlock
          data={INSTRUCTIONS_MODULES[userType][module]}
          isHome={false}
          onNext={onNext}
          videosCount={videosCount}
          isLoading={isLoading}
        />
      </>
    )
  );
};

export default SelectInstructionModule;
