import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';

import { SettingsListType, SiteType } from 'configs/propTypes';
import { exportSitePdf } from 'http/exportPdf';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { openErrorDialog } from 'redux/errorHandler';
import { actions as settingsActions } from 'redux/settings';
import { actions as sitesActions } from 'redux/sites';
import { actions as exportPdfActions } from 'redux/exportPdf';

import { actions as peopleActions } from 'redux/people';
import { AddIcon, DeleteIcon, ImportIcon, ExportQRIcon, WifiIcon, BackIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import AddEditDialog from './AddEditDialog';
import AssignDialog from './AssignDialog';
import PDFDialog from 'components/Dialogs/PdfDialog';
import BeaconDialog from 'components/Dialogs/BeaconDialog';
import BeaconItem from 'components/Dialogs/BeaconDialog/BeaconItem';

import * as S from './styled';

class SitesList extends PureComponent {
  static propTypes = {
    addSite: PropTypes.func.isRequired,
    createdEditedSiteId: PropTypes.string.isRequired,
    deleteSite: PropTypes.func.isRequired,
    editSite: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    createPDFError: PropTypes.string.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    fetchSites: PropTypes.func.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    pdfList: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    sitesList: PropTypes.arrayOf(SiteType).isRequired,
    requestCreatePdf: PropTypes.func.isRequired,
    requestDeletePdf: PropTypes.func.isRequired,
    requestGetSettings: PropTypes.func.isRequired,
    requestPdfList: PropTypes.func.isRequired,
    requestRefreshPdf: PropTypes.func.isRequired,
    settings: SettingsListType.isRequired,
    userType: PropTypes.number.isRequired,
  };

  state = {
    addEditDialogOpened: false,
    assignDialogOpened: false,
    setBeaconSettingsDialogOpened: false,
    pdfDialogOpened: false,
    siteIdToAssign: '',
    siteToEdit: null,
    siteWithPDF: null,
    distance: 1,
    distanceItem: 1,
    distanceUnit: 1,
    searchData: '',
    qrCodeSize: 2.0,
  };

  componentDidMount() {
    const { userType } = this.props;

    this.props.fetchSites();
    this.props.fetchPeople();
    this.props.requestPdfList({ allSite: true });

    if (userType !== 16) {
      this.props.requestGetSettings();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error || nextProps.createPDFError) {
      if (nextProps.error !== this.props.error) {
        this.props.openErrorDialog(nextProps.error || nextProps.createPDFError, 'Error');
      }
    }

    if (nextProps.createdEditedSiteId) {
      this.closeAddEditDialog();
      this.closeSetBeaconSettingsDialog();
    }
  }

  componentWillUnmount() {
    this.props.requestRefreshPdf();
  }

  onDeleteApprove = () => {
    this.closeAddEditDialog();
    this.props.deleteSite(this.state.siteToEdit.Id);
  };

  onDeleteSite = () => {
    this.props.openConfirmationDialog(
      'Are you sure you want to permanently delete this Site? This cannot be undone!',
      this.onDeleteApprove,
      'Delete?',
    );
  };

  getPdf = pdf => {
    if (this.state.siteWithPDF) {
      exportSitePdf({
        siteId: this.state.siteWithPDF.Id,
        id: pdf.Id,
      });
    } else {
      exportSitePdf(pdf.Id);
    }
  };

  deletePdf = pdf => {
    if (this.state.siteWithPDF) {
      this.props.requestDeletePdf({
        siteId: this.state.siteWithPDF.Id,
        id: pdf.Id,
      });
    } else {
      this.props.requestDeletePdf({
        allSite: true,
        id: pdf.Id,
      });
    }
  };

  changeSize = (event, index, value) => {
    this.setState({ qrCodeSize: value });
  };

  closeAddEditDialog = () => {
    this.setState({ addEditDialogOpened: false });
  };

  closeAssignDialog = () => {
    this.setState({ assignDialogOpened: false });
  };

  closePdfDialog = () => {
    this.setState({ pdfDialogOpened: false, siteWithPDF: null });
  };

  openAddEditDialog = site => {
    if (site.Id) {
      this.setState({ addEditDialogOpened: true, siteToEdit: site });
    } else {
      this.setState({ addEditDialogOpened: true, siteToEdit: null });
    }
  };

  openSetBeaconSettingsDialog = site => {
    this.setState({
      setBeaconSettingsDialogOpened: true,
      siteToEdit: site,
    });
  };

  closeSetBeaconSettingsDialog = () => {
    this.setState({ setBeaconSettingsDialogOpened: false });
  };

  openAssignDialog = site => {
    this.setState({ assignDialogOpened: true, siteIdToAssign: site.Id });
  };

  openPdfDialog = site => {
    if (site && site.Id) {
      this.props.requestPdfList({ siteId: site.Id });
      this.setState({ siteWithPDF: site });
    } else {
      this.props.requestPdfList({ allSite: true });
    }
    this.setState({ pdfDialogOpened: true });
  };

  changeDistance = (event, index, value) => {
    this.setState({ distance: value });
  };

  changeDistanceItem = (event, index, value) => {
    this.setState({ distanceItem: value });
  };

  changeDistanceUnit = (event, index, value) => {
    this.setState({ distanceUnit: value });
  };

  changeDistanceItem = (event, index, value) => {
    this.setState({ distanceItem: value });
  };

  changeDistanceUnit = (event, index, value) => {
    this.setState({ distanceUnit: value });
  };

  searchInList = event => {
    this.setState({
      searchData: event.target.value,
    });
  };

  createQrCode = size => {
    if (this.state.siteWithPDF) {
      this.props.requestCreatePdf({
        siteId: this.state.siteWithPDF.Id,
        size,
      });
    } else {
      this.props.requestCreatePdf({
        allSite: true,
        size,
      });
    }
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  siteNameFormatted = ({ AdditionalSiteID, Name }) =>
    `${AdditionalSiteID && `${AdditionalSiteID.substring(0, 5)} `}${Name}`.toLowerCase();

  filterSites = item => this.siteNameFormatted(item).includes(this.state.searchData.toLowerCase());

  rightControls = item => (
    <div>
      <ButtonIcon onClick={() => this.deletePdf(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => this.getPdf(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  renderRightControls = item => {
    const { userType } = this.props;

    return (
      <S.ButtonsBlock>
        {userType !== 16 && (
          <ButtonIcon onClick={() => this.openSetBeaconSettingsDialog(item)} tooltip="Beacon Settings">
            <WifiIcon />
          </ButtonIcon>
        )}
        <ButtonIcon onClick={() => this.openPdfDialog(item)} tooltip="QR pdf's">
          <ExportQRIcon />
        </ButtonIcon>
        <ButtonIcon onClick={() => this.openAssignDialog(item)} tooltip="Assign">
          <S.IconPerson />
        </ButtonIcon>
      </S.ButtonsBlock>
    );
  };

  render() {
    const { addSite, editSite, pdfList, sitesList, settings } = this.props;
    const {
      addEditDialogOpened,
      assignDialogOpened,
      setBeaconSettingsDialogOpened,
      pdfDialogOpened,
      siteIdToAssign,
      siteToEdit,
      distance,
      distanceItem,
      distanceUnit,
      searchData,
      qrCodeSize,
    } = this.state;

    return [
      <Subheader
        key="Sites subheader"
        title="Site List"
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: this.backHome,
            hint: 'Back',
          },
        ]}
        rightButtons={[
          {
            icon: <ExportQRIcon />,
            handler: this.openPdfDialog,
            hint: "QR pdf's",
          },
          {
            icon: <AddIcon />,
            handler: this.openAddEditDialog,
            hint: 'Add',
          },
        ]}
        isSearch
        searchData={searchData}
        searchInList={this.searchInList}
      />,
      <SimpleList
        key="Sites List"
        data={sortBy(sitesList.filter(this.filterSites), [this.siteNameFormatted])}
        onItemClick={this.openAddEditDialog}
        renderItemContent={BeaconItem}
        renderRightControls={this.renderRightControls}
      />,
      <AddEditDialog
        key="Add edit dialog"
        handleClose={this.closeAddEditDialog}
        handleDelete={this.onDeleteSite}
        onSubmit={siteToEdit ? editSite : addSite}
        open={addEditDialogOpened}
        title={siteToEdit ? 'Edit Site' : 'Add Site'}
        site={siteToEdit || undefined}
        withDelete={!!siteToEdit}
        changeDistance={this.changeDistance}
        distance={distance}
      />,
      <BeaconDialog
        key="Beacon Settings"
        handleClose={this.closeSetBeaconSettingsDialog}
        open={setBeaconSettingsDialogOpened}
        title={siteToEdit ? `Beacon Settings ${siteToEdit.Name}` : null}
        site={siteToEdit || undefined}
        changeDistance={this.changeDistance}
        changeDistanceItem={this.changeDistanceItem}
        changeDistanceUnit={this.changeDistanceUnit}
        defaultSettings={settings}
        distance={distance}
        distanceItem={distanceItem}
        distanceUnit={distanceUnit}
        onSubmit={siteToEdit ? editSite : addSite}
        withoutTopBorder
      />,
      <AssignDialog
        key="Assign dialog"
        handleClose={this.closeAssignDialog}
        open={assignDialogOpened}
        siteIdToAssign={siteIdToAssign}
      />,
      <PDFDialog
        key="PDF"
        title="PDF"
        handleClose={this.closePdfDialog}
        pdfList={pdfList}
        qrCodeSize={qrCodeSize}
        changeSize={this.changeSize}
        isSearch={false}
        createQR
        handleClick={() => this.createQrCode(qrCodeSize)}
        open={pdfDialogOpened}
        rightControls={this.rightControls}
      />,
    ];
  }
}

const mapStateToProps = ({
  auth: {
    user: { userType },
  },
  exportPdf,
  settings,
  sites,
}) => ({
  createdEditedSiteId: sites.createdEditedSiteId,
  createdPDF: exportPdf.pdf,
  createPDFError: exportPdf.error,
  error: sites.error,
  pdfList: exportPdf.list,
  settings: settings.list,
  sitesList: sites.list,
  userType,
});

const mapDispatchToProps = {
  addSite: sitesActions.addSiteRequest,
  deleteSite: sitesActions.deleteSiteRequest,
  editSite: sitesActions.editSiteRequest,
  fetchPeople: peopleActions.peopleListRequest,
  fetchSites: sitesActions.sitesListRequest,
  openConfirmationDialog,
  openErrorDialog,
  requestCreatePdf: exportPdfActions.createPdfFileRequest,
  requestDeletePdf: exportPdfActions.deletePdfFileRequest,
  requestGetSettings: settingsActions.getSettingsRequest,
  requestPdfList: exportPdfActions.getPdfListRequest,
  requestRefreshPdf: exportPdfActions.refresh,
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesList);
