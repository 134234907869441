import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import * as S from './styled';

const checkIfLast = list => {
  let counter = 0;
  list.forEach(item => {
    if (item.IsScheduled === true) counter += 1;
  });
  if (counter + 1 === list.length) return true;
  return false;
};

const Confirmation = ({ onBackToSites, checklistsList, currentSite, list, onNext, onStep }) => {
  const backToSites = () => {
    onBackToSites();
    onStep(5);
  };

  return (
    <S.Container>
      <S.Text>Checklists have been created for:</S.Text>
      <S.PreviewItemContainer>
        <S.PreviewItem>
          <S.PreviewItemText>{currentSite.AdditionalSiteID.substring(0, 5)} &nbsp;</S.PreviewItemText>
          <S.PreviewItemText>{currentSite.Name}</S.PreviewItemText>
        </S.PreviewItem>
        {checklistsList[currentSite.Id] &&
          checklistsList[currentSite.Id].map(
            unit =>
              unit.TeamId === checklistsList[currentSite.Id][0].TeamId && (
                <S.PreviewItemSmall>{unit.UnitName}</S.PreviewItemSmall>
              ),
          )}
      </S.PreviewItemContainer>
      <ActionsContainer>
        <ActionButton label="Next" isNext onClick={checkIfLast(list) ? onNext : backToSites} />
      </ActionsContainer>
    </S.Container>
  );
};

Confirmation.propTypes = {
  currentSite: PropTypes.shape().isRequired,
  onBackToSites: PropTypes.func.isRequired,
  onStep: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  checklistsList: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ shortcuts }) => ({
  currentChecklist: shortcuts.currentChecklist,
  list: shortcuts.shortcutSitesInfo,
  checklistsList: shortcuts.checklistsList,
});

export default connect(mapStateToProps, null)(Confirmation);
