const initialState = {
  newSiteMember: {},
  error: '',
  isLoading: false,
  isNewSiteAvailable: false,
  optionsList: [],
  jobsList: [],
};

export const addSiteMemberRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addSiteMemberSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  newSiteMember: payload,
});

export const addSiteMemberFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getOptionsListRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getOptionsListSuccess = (state, { payload }) => ({
  ...state,
  optionsList: payload,
  isLoading: false,
});

export const getOptionsListFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getJobsListRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getJobsListSuccess = (state, { payload }) => ({
  ...state,
  jobsList: payload,
  isLoading: false,
});

export const getJobsListFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createSiteRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createSiteSuccess = state => ({
  ...state,
  isLoading: false,
});

export const createSiteFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const checkSiteRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const checkSiteSuccess = (state, { payload }) => ({
  ...state,
  isNewSiteAvailable: payload === 200 || false,
  isLoading: false,
});

export const checkSiteFailure = (state, { payload }) => ({
  ...state,
  isNewSiteAvailable: false,
  error: payload,
  isLoading: false,
});

export default initialState;
