import React from 'react';
import PropTypes from 'prop-types';
import { FieldRadio } from 'components/Layout/Inputs';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import SelectField from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';
import { Field } from 'react-final-form';
import { matchOperator, constraints, optionNames, ignoreRulesBySection } from './constants';

import * as S from './styled';
import theme from 'theme';

const SectionRules = ({ title, rules, addOption, removeOption, onChangeMatch, onChangeIgnoreRules }) => (
  <S.Section>
    <S.SectionHeader>{title}</S.SectionHeader>
    <S.SectionBody>
      <Field
        name={`${rules.name}-${ignoreRulesBySection[rules.name].name}`}
        label={`Ignore rules: Include all ${rules.name.charAt(0).toUpperCase() + rules.name.slice(1)}s`}
        component={S.Checkbox}
        onChangeControl={onChangeIgnoreRules(rules.name)}
      />
      <S.MatchSection>
        <span>Match</span>
        <Field name={`match-${rules.name}`} defaultValue={rules.match.toString()}>
          {({ input }) => (
            <S.RadioGroup
              name={input.name}
              defaultSelected={rules.match.toString()}
              onChange={(e, value) => {
                input.onChange(value);
                onChangeMatch(rules.name, value);
              }}
              selectedValue={rules.match.toString()}
            >
              {Object.keys(matchOperator).map(key => (
                <FieldRadio
                  key={key}
                  value={key}
                  label={matchOperator[key]}
                  style={{ width: 'auto', marginRight: '15px', flex: key }}
                  iconStyle={{ fill: input.value === key && theme.primaryScheduler }}
                  disabled={rules.isRulesIgnored}
                />
              ))}
            </S.RadioGroup>
          )}
        </Field>
      </S.MatchSection>
      {rules.rules.map((rule, index) => (
        <S.RuleContainer key={`${rule.name}${rule.id}`}>
          <Field
            name={`optionName-${rule.id}`}
            component={SelectField}
            hintText={optionNames[rule.name][0].name}
            disabled={rules.isRulesIgnored}
          >
            {optionNames[rule.name].map(({ name, value }) => (
              <SelectItem key={name} value={value} primaryText={name} />
            ))}
          </Field>
          <Field
            name={`constraint-${rule.id}`}
            component={SelectField}
            hintText={constraints[0]}
            disabled={rules.isRulesIgnored}
          >
            {Object.keys(constraints).map(key => (
              <SelectItem key={constraints[key]} value={key} primaryText={constraints[key]} />
            ))}
          </Field>
          <Field
            name={`optionValue-${rule.id}`}
            component={TextFieldAdapter}
            hintText="Enter Parameter"
            disabled={rules.isRulesIgnored}
          />
          {!rules.isRulesIgnored && (
            <S.RuleButtonsGroup>
              {index > 0 ? (
                <S.IconButton onClick={() => removeOption(rule.name, rule.id)}>
                  <S.RemoveIcon />
                </S.IconButton>
              ) : null}
              <S.IconButton onClick={() => addOption(rule.name)}>
                <S.AddIcon />
              </S.IconButton>
            </S.RuleButtonsGroup>
          )}
        </S.RuleContainer>
      ))}
    </S.SectionBody>
  </S.Section>
);

SectionRules.propTypes = {
  title: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    name: PropTypes.string,
    match: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isRulesIgnored: PropTypes.bool.isRequired,
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        optionName: PropTypes.string,
        optionConstraint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        optionValue: PropTypes.string,
        matchOperator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }).isRequired,
  addOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  onChangeMatch: PropTypes.func.isRequired,
  onChangeIgnoreRules: PropTypes.func.isRequired,
};

export default SectionRules;
