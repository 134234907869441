import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import RemoveGroupsAndHubsDialog from 'components/Dialogs/RemoveGroupsAndHubsDialog';
import { BackIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';

import { ResourceGroupType } from 'configs/propTypes';

import { actions as gatewaysActions } from 'redux/gateways';

import { IconForward } from '../styled';
import { groupHubsData } from '../helpers';

import Item from './Item';
import { getTenantHubs, warningTexts } from './helpers';

class IoTHubs extends Component {
  static propTypes = {
    action: PropTypes.shape({
      deleteHubs: PropTypes.func.isRequired,
      getResourceGroups: PropTypes.func.isRequired,
      resetTenantHubsList: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    list: PropTypes.arrayOf(PropTypes.shape(ResourceGroupType).isRequired).isRequired,
  };

  state = {
    searchData: '',
    removeHubsDialogOpen: false,
    hubsData: [],
    tenantHubsForDelete: '',
  };

  componentDidMount() {
    this.props.action.getResourceGroups();
    this.props.action.resetTenantHubsList({ fieldName: 'tenantHubsList', value: [] });
  }

  onBackButtonClick = () => this.props.history.push('/globalsettings');

  onSearch = e => this.setState({ searchData: e.target.value });

  filterHubs = ({ tenant }) => tenant.name.toLocaleLowerCase().includes(this.state.searchData.toLocaleLowerCase());

  openHubsDialog = item =>
    this.setState({
      removeHubsDialogOpen: true,
      hubsData: groupHubsData(item),
      tenantHubsForDelete: item.tenant.id,
    });
  closeRemoveHubsDialog = () => this.setState({ removeHubsDialogOpen: false });

  handleDeleteHubs = () => {
    this.props.action.deleteHubs({ tenantId: this.state.tenantHubsForDelete });
    this.closeRemoveHubsDialog();
  };

  renderRightControls = Id => (
    <div>
      <Link to={`/globalsettings/tenant/${Id}/hubs`}>
        <ButtonIcon tooltip="Tenant Hubs">
          <IconForward />
        </ButtonIcon>
      </Link>
    </div>
  );

  render() {
    const { searchData, removeHubsDialogOpen, hubsData } = this.state;
    const { list } = this.props;

    return (
      <>
        <Subheader
          title="IoT Hubs"
          hintText="Search"
          isSearch
          searchData={searchData}
          searchInList={this.onSearch}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onBackButtonClick,
              hint: 'Back',
            },
          ]}
        />
        <SimpleList
          data={list.filter(this.filterHubs)}
          onItemClick={this.openHubsDialog}
          emptyListMessage="There are no Hubs avaliable"
          renderItemContent={item => <Item item={item} />}
          renderRightControls={item => this.renderRightControls(item.tenant.id)}
        />
        <RemoveGroupsAndHubsDialog
          open={removeHubsDialogOpen}
          title="Remove Hubs"
          closeModal={this.closeRemoveHubsDialog}
          type="hubs"
          data={hubsData}
          alertText={warningTexts.notification}
          handleDelete={this.handleDeleteHubs}
          warningText={warningTexts.confirmation}
        />
      </>
    );
  }
}

const mapStateToProps = ({ gateways }) => ({
  list: getTenantHubs(gateways.resourceGroupsList),
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      getResourceGroups: gatewaysActions.getResourceGroupsRequest,
      deleteHubs: gatewaysActions.deleteAllHubsFromTenantRequest,
      resetTenantHubsList: gatewaysActions.resetField,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IoTHubs);
