import styled from 'styled-components';

export const InputContainer = styled.div`
  padding: 25px 20px 0 20px;
`;

export const InfoContainer = styled.div`
  padding: 25px 20px 0 20px;
`;

export const Label = styled.label`
  margin-left: 50px;
  color: red;
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    margin-bottom: 15px;
  }
`;

export const SelectContainer = styled.div`
  margin-bottom: 20px;
`;

export const SeparatorContainter = styled.div`
  margin-bottom: 10px;
`;
