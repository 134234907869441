import http from '../index';

export const fetchGroups = () => http.post('MeeteringGroup/ListMeeteringGroup');

export const fetchGroup = payload => http.post(`MeeteringGroup/GetMeeteringGroup?meeteringGroupId=${payload.payload}`);

export const createMeeteringGroup = (name, notes) =>
  http.post('MeeteringGroup/CreateMeeteringGroup', { meeteringGroupDto: { name, notes } });

export const editMeeteringGroup = (id, name, notes) =>
  http.post('MeeteringGroup/EditMeeteringGroup', { meeteringGroupDto: { id, name, notes } });

export const bindMeeteringAndIndustry = dto => http.post('MeeteringIndustryManagement/BindMeeteringAndIndustry', dto);

export const deleteMeeteringGroup = dto => http.post('MeeteringGroup/DeleteMeeteringGroup', dto);
