import React from 'react';
import PropTypes from 'prop-types';

import IOT_HUBS from 'configs/iotHubs';

import * as S from './styled';

const maxCapacity = { F1: 2, B1: 200, B2: 200, B3: 10, S1: 200, S2: 200, S3: 10 };

const Sku = ({ SKU: { SKUName, Capacity }, disabled, handleTierChange }) => (
  <S.TierContainer>
    <S.Select
      value={SKUName}
      onChange={(e, i, value) => handleTierChange('SKUName', value)}
      floatingLabelText="Pricing and Scale Tier"
      disabled={disabled}
    >
      {IOT_HUBS.map(({ skuName: nameSku, skuTier }) => (
        <S.IotHubItem key={nameSku} primaryText={`${nameSku}: ${skuTier} tier`} value={nameSku} />
      ))}
    </S.Select>
    <S.SliderContainer>
      <S.SliderLabel>Number of {SKUName} IoT Hub Units</S.SliderLabel>
      <S.SliderField
        min={1}
        max={maxCapacity[SKUName]}
        step={1}
        value={Capacity}
        onChange={(e, newValue) => handleTierChange('Capacity', newValue)}
        disabled={maxCapacity[SKUName] === 2 || disabled}
      />
    </S.SliderContainer>
    <S.Capacity>{Capacity}</S.Capacity>
  </S.TierContainer>
);

Sku.propTypes = {
  SKU: PropTypes.shape({
    SKUName: PropTypes.string,
    SKUTier: PropTypes.string,
    Capacity: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleTierChange: PropTypes.func.isRequired,
};

export default Sku;
