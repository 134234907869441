import { ForwardIcon, BackIcon } from 'components/Layout/Icons';
import styled from 'styled-components';

export const TableContainer = styled.div`
  overflow-x: auto;

  & thead {
    color: ${({ theme }) => theme.primaryGrey};
  }

  thead > tr > th {
    font-weight: 500;
  }

  th,
  td {
    padding: 0 25px !important;
  }

  & td:nth-child(-n + 2) {
    border-right: 1px solid ${({ theme }) => theme.archiveLineColor};
  }

  & td:nth-child(-n + 2) {
    background-color: ${({ theme }) => theme.lightBlue};
  }
`;

export const IconForward = styled(ForwardIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const Back = styled(BackIcon)`
  color: ${({ theme }) => theme.primaryGrey} !important;
`;

export const RedText = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.primaryRed};
`;

export const Title = styled.h2`
  margin: 0;
  margin-bottom: 5px;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;
