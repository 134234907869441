export const validator = values => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (!values.IndustryId) {
    errors.IndustryId = 'Required';
  }

  if (!values.TierId) {
    errors.TierId = 'Required';
  }

  if (!values.DefaultAdminName) {
    errors.DefaultAdminName = 'Required';
  }

  if (!values.DefaultAdminFirstName) {
    errors.DefaultAdminFirstName = 'Required';
  }

  if (!values.DefaultAdminLastName) {
    errors.DefaultAdminLastName = 'Required';
  }

  if (!values.Id && !values.DefaultAdminPassword) {
    errors.DefaultAdminPassword = 'Required';
  } else if (values.DefaultAdminPassword && !values.DefaultAdminPassword.match(/^[A-Za-z0-9!@#$%^&*()_+-=.?]*$/)) {
    errors.DefaultAdminPassword =
      'Password must contain only english alphabet characters, ' +
      'numbers or characters from this enum: "! @ # $ % ^ & * ( ) _ + - = . ?"';
  } else if (values.DefaultAdminPassword && values.DefaultAdminPassword.length < 8) {
    errors.DefaultAdminPassword = 'Password must be at least 8 characters long';
  }

  return errors;
};

export default validator;
