import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import SelectField from 'components/Forms/SelectFieldAdapter';
import { SelectItem } from 'components/Layout/Selects';
import theme from 'theme';

import { SelectContainer } from '../styled';
import { optionNames } from '../constants';

const styles = {
  radioButton: {
    padding: '5px 0px',
    display: 'flex',
  },
};

const RadioGroup = ({ input, meta, style, values, reportValues }) => (
  <>
    <RadioButtonGroup
      name="type"
      valueSelected={input.value}
      defaultSelected={0}
      onChange={(event, data) => input.onChange(data)}
      errorText={meta.visited || meta.touched ? meta.error : ''}
      style={style}
    >
      <RadioButton
        value={0}
        label="Don't Group or sub-group anything"
        iconStyle={{
          fill: input.value.length === 0 || input.value === 0 ? theme.primaryScheduler : theme.darkGrey,
          marginRight: '17px',
        }}
        labelStyle={{ textAlign: 'left' }}
        style={styles.radioButton}
      />
      <RadioButton
        value={1}
        label={
          <SelectContainer>
            <Field
              name={'group-name'}
              component={SelectField}
              style={{ fontSize: '18px' }}
              hintText={optionNames.group[0].name}
              disabled={!values['group-select']}
              initialValue={reportValues.GroupBy ? reportValues.GroupBy : optionNames.group[0].value}
            >
              {optionNames.group.map(({ name, value }) => (
                <SelectItem key={name} value={value} primaryText={name} />
              ))}
            </Field>
          </SelectContainer>
        }
        inputStyle={{ width: '24px' }}
        iconStyle={{ fill: input.value === 1 ? theme.primaryScheduler : theme.darkGrey, marginRight: '17px' }}
        labelStyle={{ textAlign: 'left' }}
        style={styles.radioButton}
      />
    </RadioButtonGroup>
  </>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  values: PropTypes.shape().isRequired,
  reportValues: PropTypes.shape().isRequired,
};

export default RadioGroup;
