import { createSelector } from 'reselect';

export const getUnitId = createSelector(
  state => state.unit.unitData,
  unitData => unitData.Id,
);

export const getItemsIds = createSelector(
  unit => unit.unitItemsList,
  list => list.map(item => item.Id),
);

export const getSiteId = createSelector(
  state => state.auth.selectedSite,
  selectedSite => selectedSite,
);
