import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RadioButton } from 'material-ui';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as exchangeActions } from 'redux/exchange';
import { actions as tenantsActions } from 'redux/tenants';
import { actions as unitsActions } from 'redux/units';
import { unitsToggle } from 'configs/toggles';
import { openConfirmationDialog } from 'redux/confirmationHandler';

import { BackIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import Toggle from 'components/Toggle';

import Footer from './Footer';
import List from './List';
import CopyToTenantDialog from './CopyToTennantDialog';
import SearchTenantDialog from './searchTennantDialog';
import { options } from './constants';
import * as S from './styled';

class UnitsList extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    openConfirmationDialog: PropTypes.func.isRequired,
    openErrorDialog: PropTypes.func.isRequired,
    requestExchangeList: PropTypes.func.isRequired,
    requestTenantsList: PropTypes.func.isRequired,
    requestUnitApprove: PropTypes.func.isRequired,
    requestSitesByTenantsList: PropTypes.func.isRequired,
    requestListToTenant: PropTypes.func.isRequired,
    unitList: PropTypes.array.isRequired,
    tenantsList: PropTypes.array.isRequired,
    sites: PropTypes.array.isRequired,
    copyError: PropTypes.object.isRequired,
    isImported: PropTypes.bool.isRequired,
  };

  state = {
    unitsStatus: 'new',
    isSelectAll: false,
    copyToTenantDialogOpened: false,
    searchTenantDialogOpened: false,
    checkedUnits: [],
    checkedTenant: null,
    checkedSite: null,
    searchForm: {},
    searchParameter: { Search: '', MasterUnitFilter: 'UnitName' },
    tenantsCollection: [],
    sitesCollection: [],
    searchTenantText: '',
    searchSiteText: '',
    filter: 'all',
  };

  componentDidMount() {
    this.props.requestExchangeList({ status: 'new' });
    this.props.requestTenantsList();
  }

  componentWillReceiveProps(nextProps) {
    this.closeSearchTenantDialog();

    if (nextProps.isImported) {
      this.setState({
        checkedUnits: [],
        isSelectAll: [],
        checkedTenant: null,
        checkedSite: null,
        searchTenantText: '',
        searchSiteText: '',
      });
    }

    if (nextProps.tenantsList.length) {
      this.setState({ tenantsCollection: nextProps.tenantsList });
    }

    if (nextProps.sites.length) {
      this.setState({ sitesCollection: nextProps.sites });
    }

    if (nextProps.copyError) {
      if (nextProps.copyError.split(' ').includes('limit')) {
        this.props.openErrorDialog(nextProps.copyError, 'Error');
      } else {
        setTimeout(() => {
          this.props.openConfirmationDialog(
            'One or more of the lists is already present and will overwrite an existing list.',
            () => this.importToTenant(true),
            'New Version!',
          );
        }, 1000);
      }
    }
  }

  onListClick = () => this.props.history.push('/home');

  onCheck = (item, value) => {
    const newChecked = [...this.state.checkedUnits];
    if (value) {
      newChecked.push(this.state.unitsStatus === 'new' ? item.Id : item.OriginalId);
    } else {
      newChecked.splice(newChecked.indexOf(this.state.unitsStatus === 'new' ? item.Id : item.OriginalId), 1);
    }

    this.setState({ checkedUnits: newChecked });
  };

  onItemClick = item => this.props.history.push(`/exchange/${item.OriginalId}/versions`);

  onFooterClick = () => {
    if (this.state.unitsStatus === 'new') {
      this.props.requestUnitApprove({ ids: this.state.checkedUnits, approve: true });
      this.setState({ checkedUnits: [] });
    } else {
      this.openCopyToTenantDialog();
    }
  };

  onImport = () => {
    if (this.state.checkedTenant && this.state.checkedSite) {
      this.props.openConfirmationDialog(
        'Are you sure you are about to add selection to a Tenant Site?',
        () => this.importToTenant(false),
        'Are you sure?',
      );
    } else {
      this.props.openErrorDialog('Please, choose Tenant and Site', '');
    }
  };

  getCurrentSearchValue = () => this.state.searchParameter;

  requestSites = searchData => {
    this.setState({ searchTenantText: searchData });
    const [selectedTenant] = this.state.tenantsCollection.filter(item => item.Name === searchData);
    if (selectedTenant) {
      this.props.requestSitesByTenantsList({ tenantId: selectedTenant.Id });
      this.setState({ checkedTenant: selectedTenant });
    } else {
      this.setState({ checkedTenant: null });
    }
  };

  loadSite = site => {
    this.setState({ searchSiteText: site });
    const [selectedSite] = this.state.sitesCollection.filter(item => item.Name === site);
    if (selectedSite) {
      this.setState({ checkedSite: selectedSite });
    } else {
      this.setState({ checkedSite: null });
    }
  };

  clearFieldsForCopy = () => this.setState({ searchSiteText: '', checkedSite: null });

  typeHandler = status => {
    this.setState({
      unitsStatus: status.key,
      checkedUnits: [],
      isSelectAll: false,
      searchParameter: {
        Search: '',
        MasterUnitFilter: 'UnitName',
      },
    });
    this.props.requestExchangeList({ status: status.key });
  };

  selectAll = list => {
    let itemIDs = [];
    if (this.state.isSelectAll) {
      itemIDs = [];
    } else {
      itemIDs = this.state.unitsStatus === 'new' ? list.map(item => item.Id) : list.map(item => item.OriginalId);
    }
    this.setState({ checkedUnits: itemIDs, isSelectAll: !this.state.isSelectAll });
  };

  importToTenant = updateExistingUnits => {
    this.props.requestListToTenant({
      originalUnitIds: this.state.checkedUnits,
      tenantId: this.state.checkedTenant.Id,
      siteId: this.state.checkedSite.Id,
      updateExistingUnits,
    });
    this.closeCopyToTenantDialog();
  };

  clearSearchField = () => this.props.requestExchangeList({ status: this.state.unitsStatus });

  submitForm = values => {
    const searchData = values.MasterUnitFilter
      ? {
          Search: values.Search,
          MasterUnitFilter: values.MasterUnitFilter,
        }
      : {
          Search: values.Search,
          MasterUnitFilter: 'UnitName',
        };

    this.setState({
      searchParameter: searchData,
    });
    this.props.requestExchangeList(searchData);
  };

  closeCopyToTenantDialog = () => {
    this.setState({
      copyToTenantDialogOpened: false,
      searchTenantText: '',
      searchSiteText: '',
    });
  };

  openCopyToTenantDialog = () => this.setState({ copyToTenantDialogOpened: true });

  closeSearchTenantDialog = () => this.setState({ searchTenantDialogOpened: false });

  openSearchTenantDialog = () => this.setState({ searchTenantDialogOpened: true });

  onFilterChange = (event, value) => this.setState({ filter: value });

  filterUnitList = item => {
    const { filter } = this.state;

    if (filter === 'restricted') {
      return item.UnitType === 'Demo' || item.UnitType === 'Private';
    }

    if (filter === 'nonRestricted') {
      return item.UnitType === 'Regular';
    }

    return item;
  };

  render() {
    const {
      unitsStatus,
      copyToTenantDialogOpened,
      searchTenantDialogOpened,
      checkedUnits,
      checkedTenant,
      checkedSite,
      isSelectAll,
      searchSiteText,
      searchTenantText,
      filter,
    } = this.state;
    const { unitList, tenantsList, sites } = this.props;

    return [
      <S.Container key="Exchange list">
        <Subheader
          title="List Templates"
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.onListClick,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              title: 'Search',
              icon: <S.SearchAction />,
              handler: this.openSearchTenantDialog,
              hint: 'Search',
              labelPosition: 'before',
            },
          ]}
        />
        <S.Toolbar>
          <Toggle config={unitsToggle} selected={unitsToggle[0].key} handler={this.typeHandler} />
          <S.RadioGroup onChange={this.onFilterChange} defaultSelected={filter}>
            {options.map(({ value, label, style, iconStyle }) => (
              <RadioButton value={value} style={style} label={label} iconStyle={filter === value ? iconStyle : {}} />
            ))}
          </S.RadioGroup>
          <div>
            <S.CheckBox
              data-isactive={isSelectAll}
              label="Select All"
              labelPosition="before"
              onCheck={() => this.selectAll(unitList)}
            />
          </div>
        </S.Toolbar>
        <List
          data={unitList.filter(this.filterUnitList)}
          onItemClick={this.onItemClick}
          onCheck={this.onCheck}
          checked={checkedUnits}
          status={unitsStatus}
        />
      </S.Container>,
      <Footer
        key="Exchange footer"
        status={unitsStatus}
        onCenter={this.onFooterClick}
        isChecked={!!checkedUnits.length}
        quantity={checkedUnits.length}
      />,
      <CopyToTenantDialog
        key="CopyToTenantDialog"
        handleClose={this.closeCopyToTenantDialog}
        clearFieldsForCopy={this.clearFieldsForCopy}
        searchSiteText={searchSiteText}
        searchTenantText={searchTenantText}
        requestSites={this.requestSites}
        loadSite={this.loadSite}
        title="Copy to a Tenant"
        open={copyToTenantDialogOpened}
        list={tenantsList.filter(item => !item.IsOEM).map(item => item.Name)}
        checkedTenant={checkedTenant}
        checkedSite={checkedSite}
        sites={sites.map(item => item.Name)}
        onSubmit={this.onImport}
        isSearch={false}
        isCopy
        withoutTopBorder
      />,
      <SearchTenantDialog
        key="SearchTenantDialog"
        handleClose={this.closeSearchTenantDialog}
        handleClick={this.clearSearchField}
        title="Search"
        open={searchTenantDialogOpened}
        onSubmit={this.submitForm}
        isSearch
        isSearchAll
        searchParameter={this.getCurrentSearchValue()}
      />,
    ];
  }
}

const mapStateToProps = ({ exchange, tenants }) => ({
  unitList: exchange.exchangeList,
  tenantsList: tenants.list,
  sites: exchange.sitesByTenant,
  copyError: exchange.error,
  isImported: exchange.isImported,
});

const mapDispatchToProps = {
  requestExchangeList: exchangeActions.exchangeListRequest,
  requestUnitApprove: exchangeActions.unitApproveRequest,
  requestSitesByTenantsList: exchangeActions.sitesByTenantsListRequest,
  requestListToTenant: exchangeActions.importListToTenantRequest,
  requestTenantsList: tenantsActions.tenantsListRequest,
  requestTenantToList: unitsActions.importTenantToListRequest,
  openConfirmationDialog,
  openErrorDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitsList);
