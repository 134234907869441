import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const envelopeIconStyles = {
  width: '40px',
  height: '40px',
};

export const SignInText = styled.span`
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.primaryDark};
  margin: 5px 0 0 31px;
  display: inline-block;
`;

export const SignInBookmark = styled.div`
  width: 131px;
  height: 75px;
  background-color: ${({ theme }) => theme.primaryWhite};
  position: relative;
  top: 15px;
  cursor: pointer;

  &::before {
    content: ' ';
    border: 0;
    position: absolute;
    right: 20px;
    top: -15px;
    width: 111px;
    height: 20px;
    border-radius: 15px 15px 0 0;
    background: white;
    z-index: 1;
  }

  &::after {
    content: ' ';
    border: 0;
    position: absolute;
    right: 0;
    bottom: 15px;
    width: 20px;
    height: 75px;
    border-radius: 0 0 0 30px;
    background: ${({ theme }) => theme.lightGrey};
  }
`;

export const SignUpBookmark = styled.div`
  width: 130px;
  height: 75px;
  background-color: ${({ theme }) => theme.signUpColor};
  position: relative;
  right: 1px;
  top: -5px;
  cursor: pointer;
  align-self: flex-end;

  &::before {
    content: ' ';
    border: 0;
    position: absolute;
    top: 13px;
    left: -1px;
    width: 20px;
    height: 75px;
    border-radius: 0 15px 0 0;
    background: ${({ theme }) => theme.lightGrey};
    z-index: 1;
  }

  &::after {
    content: ' ';
    border: 0;
    position: absolute;
    left: 19px;
    bottom: -19px;
    width: 111px;
    height: 20px;
    border-radius: 0 0 30px 30px;
    background: ${({ theme }) => theme.signUpColor};
  }
`;

export const SignUpText = styled(SignInText)`
  color: ${({ theme }) => theme.primaryWhite};
  margin: 52px 0 0 51px;
`;

export const ForgotPasswordContainer = styled.div`
  position: absolute;
  bottom: 35px;
  left: 40px;
`;

export const ForgotLink = styled(Link)`
  color: ${({ theme }) => theme.primaryBlack};
`;

export const UpgradeAccountContainer = styled(ForgotPasswordContainer)`
  cursor: pointer;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
`;

export const UpgradeAccountLink = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 300;
  margin-left: 7px;
  text-decoration: underline;
`;
