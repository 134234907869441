import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IndustryType, ShortCutType, SiteType, UnitType } from 'configs/propTypes';
import { USER_TYPES } from 'configs/enums';
import { actions as appActions } from 'redux/app';
import { actions as shortcutActions } from 'redux/shortcuts';
import { actions as newSitesActions } from 'redux/newSites';
import { actions as listTemplatesActions } from 'redux/listTemplates';
import { actions as settingsActions } from 'redux/settings';
import { actions as peopleActions } from 'redux/people';
import { actions as teamsActions } from 'redux/teams';
import { actions as sitesActions } from 'redux/sites';

import UpgradeInfoDialog from 'components/Dialogs/UpgradeInfoDialog';
import UpgradeInfoSuccessDialog from 'components/Dialogs/UpgradeInfoDialog/UpgradeInfoSuccessDialog';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import SearchField from 'components/Layout/Search';
import Card from 'components/Layout/Card';

import ShortcutSetup from '../dialogs/ShortcutSetup';
import NewSite from '../dialogs/NewSite';
import ListTemplate from '../dialogs/ListTemplate';
import { FirstTimeSetupDialog } from '../dialogs/FirstTimeSetup';
import AddingChecklistDialog from '../dialogs/AddingChecklist';

import { adminCards, managerCards } from './../helpers';
import * as S from './../styled';

import theme from 'theme';

class GeneralActivator extends Component {
  static propTypes = {
    userType: PropTypes.number.isRequired,
    shortcut: ShortCutType.isRequired,
    action: PropTypes.shape({
      updateCurrentModule: PropTypes.func.isRequired,
      deleteShortcutRequest: PropTypes.func.isRequired,
      checkSiteRequest: PropTypes.func.isRequired,
      requestUpgradeInfo: PropTypes.func.isRequired,
      getLimitsRequest: PropTypes.func.isRequired,
      requestGetSettings: PropTypes.func.isRequired,
      fetchPeople: PropTypes.func.isRequired,
      fetchTeams: PropTypes.func.isRequired,
      fetchSitesList: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      userId: PropTypes.string,
      sitesAvailableToUserFullInfo: PropTypes.arrayOf(SiteType),
      unitsAvailableToUser: PropTypes.arrayOf(UnitType),
    }).isRequired,
    peopleList: PropTypes.arrayOf(
      PropTypes.shape({
        UserType: PropTypes.number,
        Id: PropTypes.number,
      }),
    ).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isNewSiteAvailable: PropTypes.bool.isRequired,
    industriesList: PropTypes.arrayOf(IndustryType).isRequired,
    limit: PropTypes.number.isRequired,
    currentItemsNumber: PropTypes.number.isRequired,
    sitesList: PropTypes.bool.isRequired,
  };

  state = {
    dialogsOpened: {
      firstSetup: false,
      addChecklist: false,
      newSite: false,
      addListTemplate: false,
    },
    isDemoMode: false,
    firstTimeNewSite: true,
    isShortcutFromListTemplate: true,
    isConfirmationDialogOpened: false,
    isConfirmationSitesDialogOpened: false,
    isConfirmationTemplatesDialogOpened: false,
    isSiteLimitDialogOpened: false,
    isItemLimitDialogOpened: false,
    addAnotherSiteOpened: false,
    addAnotherItemOpened: false,
    addAnotherTemplateOpened: false,
    addTemplateAfterSiteOpened: false,
    importListTemplateOpened: false,
    infoListTemplateOpened: false,
    scheduleTaskOfferOpened: false,
    upgradeInfoOpened: false,
    successDialogOpen: false,
    searchValue: '',
    firstTimeTemplate: true,
    anotherUnitTemplate: false,
    listTemplateCurrentSite: null,
    listTemplateNames: {
      templateNames: [],
      checkDuplicate: true,
    },

    cards: this.props.userType === USER_TYPES.SystemAdministrator ? adminCards : managerCards,
  };

  setListTemplateCurrentSite = site => {
    this.setState({ listTemplateCurrentSite: site });
  };

  componentDidMount() {
    const { action } = this.props;
    action.fetchSitesList();
    action.updateCurrentModule('activator');
    action.checkSiteRequest();
    action.getLimitsRequest();
    action.requestGetSettings();
    action.fetchPeople();
    action.fetchTeams();
  }

  resetState = dialogToClose => {
    this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ [dialogToClose]: false } } });
  };

  onSearch = (cards, searchValue) => this.setState({ cards, searchValue });

  onStateChange = (key, value) => this.setState({ [key]: value });

  handleCardPress = type => {
    if (type === 'addShortcut') {
      this.setState({ isShortcutFromListTemplate: false });
    }
    if ((type === 'newSite' || type === 'firstSetup') && !this.props.isNewSiteAvailable) {
      this.openSitesLimitDialog();
    } else if (type === 'addListTemplate' && this.props.limit && this.props.currentItemsNumber === this.props.limit) {
      this.openItemLimitDialog();
    } else {
      this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ [type]: true } } });
    }
  };

  handleShortcutClose = () => {
    const { shortcut } = this.props;

    if (shortcut && shortcut.Id) {
      return this.setState({ isConfirmationDialogOpened: true });
    }
    this.setState({ isShortcutFromListTemplate: false });
    return this.resetState('addShortcut');
  };

  handleChecklistClose = props => {
    const { shortcut } = this.props;

    if (props === false) {
      return this.resetState('addChecklist');
    }
    if (shortcut && shortcut.Id) {
      return this.setState({ isConfirmationDialogOpened: true });
    }
    this.setState({ isShortcutFromListTemplate: false });
    return this.resetState('addChecklist');
  };

  handleShortcutExit = () => {
    this.setState({ isShortcutFromListTemplate: true, isDemoMode: false });
    this.resetState('addShortcut');
  };

  handleListExit = () => {
    this.resetState('addListTemplate');
  };

  handleListClose = () => {
    this.setState({ isConfirmationTemplatesDialogOpened: true, isDemoMode: false });
  };

  handleNewSiteClose = () => {
    this.setState({ isConfirmationSitesDialogOpened: true, isDemoMode: false });
  };

  handleNewSiteExit = () => {
    this.setState({ firstTimeNewSite: true });
    this.resetState('newSite');
  };

  handleDeleteShortcutApproval = () => {
    const { shortcut } = this.props;
    this.props.action.deleteShortcutRequest({ shortcutId: shortcut.Id });
    this.setState({ isShortcutFromListTemplate: false });
    if (this.state.dialogsOpened.addChecklist) {
      this.resetState('addChecklist');
    } else {
      this.resetState('addShortcut');
    }
    this.setState({ isConfirmationDialogOpened: false });
  };

  handleDeleteShortcutReject = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  confirmTemplatesExit = () => {
    this.resetState('addListTemplate');
    this.setState({ isConfirmationTemplatesDialogOpened: false });
    this.setState({ firstTimeTemplate: true, listTemplateCurrentSite: null });
  };

  closeConfirmationTemplatesDialog = () => {
    this.setState({ isConfirmationTemplatesDialogOpened: false });
  };

  cancelConfirmationTemplatesDialog = () => {
    if (this.state.dialogsOpened.addListTemplate) {
      this.setState({ isConfirmationTemplatesDialogOpened: false });
    } else {
      this.setState({ addAnotherTemplateOpened: true });
      this.setState({ isConfirmationTemplatesDialogOpened: false });
    }
  };

  confirmNewSitesExit = () => {
    this.resetState('newSite');
    this.setState({ firstTimeNewSite: true });
    this.setState({ isConfirmationSitesDialogOpened: false });
  };

  closeConfirmationSitesDialog = () => {
    this.setState({ isConfirmationSitesDialogOpened: false });
  };

  cancelConfirmationSitesDialog = () => {
    if (this.state.dialogsOpened.newSite) {
      this.setState({ isConfirmationSitesDialogOpened: false });
    } else {
      this.setState({ addAnotherSiteOpened: true });
      this.setState({ isConfirmationSitesDialogOpened: false });
    }
  };

  closeSitesLimitDialog = () => {
    this.setState({ isSiteLimitDialogOpened: false });
  };

  openSitesLimitDialog = () => {
    this.setState({ isSiteLimitDialogOpened: true });
  };

  closeItemLimitDialog = () => {
    this.setState({ isItemLimitDialogOpened: false });
  };

  openItemLimitDialog = () => {
    this.setState({ isItemLimitDialogOpened: true });
  };

  openAddAnotherItemDialog = () => this.setState({ addAnotherItemOpened: true });
  closeAddAnotherItemDialog = () => this.setState({ addAnotherItemOpened: false });

  openAddAnotherTemplateDialog = () => this.setState({ addAnotherTemplateOpened: true });
  closeAddAnotherTemplateDialog = () => this.setState({ addAnotherTemplateOpened: false });

  openScheduleTaskOfferDialog = () => this.setState({ scheduleTaskOfferOpened: true });
  closeScheduleTaskOfferDialog = () => this.setState({ scheduleTaskOfferOpened: false });

  openImportListTemplateDialog = () => this.setState({ importListTemplateOpened: true });
  closeImportListTemplateDialog = () => this.setState({ importListTemplateOpened: false });

  openInfoListTemplateDialog = () => this.setState({ infoListTemplateOpened: true });
  closeInfoListTemplateDialog = () => this.setState({ infoListTemplateOpened: false });

  openAddTemplateAfterSiteDialog = () => this.setState({ addTemplateAfterSiteOpened: true });
  closeAddTemplateAfterSiteDialog = () => this.setState({ addTemplateAfterSiteOpened: false });

  openAddAnotherSiteDialog = () => this.setState({ addAnotherSiteOpened: true });
  closeAddAnotherSiteDialog = () => {
    this.setState({ addAnotherSiteOpened: false });
  };

  openSuccessDialog = () => this.setState({ successDialogOpen: true });
  closeSuccessDialog = () => {
    this.setState({ successDialogOpen: false });
  };

  openUpgradeInfoDialog = () => this.setState({ upgradeInfoOpened: true });
  closeUpgradeInfoDialog = () => {
    this.resetState('addListTemplate');
    this.setState({ upgradeInfoOpened: false });
  };
  submitUpgradeInfoDialog = values => {
    this.props.action.requestUpgradeInfo({ values, openSuccessDialog: this.openSuccessDialog });
    this.closeUpgradeInfoDialog();
  };

  handleNextFirstTimeSetup = () =>
    this.setState({
      dialogsOpened: {
        ...this.state.dialogsOpened,
        firstSetup: false,
        newSite: true,
      },
      isDemoMode: true,
    });

  render() {
    const {
      cards,
      searchValue,
      dialogsOpened,
      isShortcutFromListTemplate,
      isConfirmationDialogOpened,
      isConfirmationSitesDialogOpened,
      isConfirmationTemplatesDialogOpened,
      isSiteLimitDialogOpened,
      isItemLimitDialogOpened,
      upgradeInfoOpened,
      successDialogOpen,
      addAnotherSiteOpened,
      firstTimeNewSite,
      addAnotherItemOpened,
      addAnotherTemplateOpened,
      importListTemplateOpened,
      infoListTemplateOpened,
      scheduleTaskOfferOpened,
      firstTimeTemplate,
      anotherUnitTemplate,
      addTemplateAfterSiteOpened,
      listTemplateCurrentSite,
      listTemplateNames,
      isDemoMode,
    } = this.state;

    const { userType, industriesList, user, peopleList, sitesList } = this.props;
    const allCards = userType === USER_TYPES.SystemAdministrator ? adminCards : managerCards;
    const isFirstTimeSetupVisible =
      user.sitesAvailableToUserFullInfo?.length === 0 ||
      sitesList ||
      (peopleList.length === 1 && peopleList[0].Id === user.userId && userType === USER_TYPES.SystemAdministrator);
    const activatorCards = isFirstTimeSetupVisible ? cards : cards.filter(item => item.type !== 'firstSetup');

    return (
      <>
        <S.PageContainer>
          <SearchField
            list={allCards}
            filterBy="title"
            searchData={searchValue}
            placeholder="Search"
            underlineShow
            inputStyle={S.searchInputStyles}
            hintStyle={S.hintInputStyles}
            setSearchedItems={this.onSearch}
          />
          <S.PageTitle>Activator</S.PageTitle>
          <S.ActivatorsContainer>
            {activatorCards.map(({ title, info, primaryIcon, secondaryIcon, type, color, tooltipText }) => (
              <Card
                key={title}
                info={info}
                onCardClick={() => this.handleCardPress(type)}
                PrimaryIcon={primaryIcon}
                SecondaryIcon={secondaryIcon}
                title={title}
                color={color}
                tooltip={tooltipText}
              />
            ))}
          </S.ActivatorsContainer>
        </S.PageContainer>
        <FirstTimeSetupDialog
          open={dialogsOpened.firstSetup}
          handleClose={() => this.resetState('firstSetup')}
          handleNext={this.handleNextFirstTimeSetup}
        />
        <AddingChecklistDialog
          open={dialogsOpened.addChecklist}
          handleClose={this.handleChecklistClose}
          handleExit={() => this.resetState('addChecklist')}
          openCreateShortcutDialog={() => {
            this.setState({ isShortcutFromListTemplate: false });
            this.setState({
              dialogsOpened: { ...this.state.dialogsOpened, ...{ addChecklist: false, addShortcut: true } },
            });
          }}
        />
        <ShortcutSetup
          open={dialogsOpened.addShortcut}
          handleClose={this.handleShortcutClose}
          handleExit={this.handleShortcutExit}
          isShortcutFromListTemplate={isShortcutFromListTemplate}
          isDemoMode={isDemoMode}
          history={this.props.history}
        />
        <NewSite
          open={dialogsOpened.newSite}
          handleClose={this.handleNewSiteClose}
          handleExit={this.handleNewSiteExit}
          addAnotherSite={this.openAddAnotherSiteDialog}
          firstTimeNewSite={firstTimeNewSite}
          isDemoMode={isDemoMode}
        />
        <ListTemplate
          open={dialogsOpened.addListTemplate}
          handleClose={this.handleListClose}
          handleExit={this.handleListExit}
          addAnotherItem={this.openAddAnotherItemDialog}
          openImportListTemplateDialog={this.openImportListTemplateDialog}
          openScheduleTaskOfferDialog={this.openScheduleTaskOfferDialog}
          openItemLimitDialog={this.openItemLimitDialog}
          firstTimeTemplate={firstTimeTemplate}
          anotherUnitTemplate={anotherUnitTemplate}
          currentSite={listTemplateCurrentSite}
          listTemplateNames={listTemplateNames}
          setCurrentSite={this.setListTemplateCurrentSite}
        />
        <UpgradeInfoDialog
          handleClose={this.closeUpgradeInfoDialog}
          open={upgradeInfoOpened}
          title="Upgrade Info"
          onSubmit={this.submitUpgradeInfoDialog}
          BigButtons
          industryList={industriesList}
          submitLabel="SEND REQUEST"
        />
        <UpgradeInfoSuccessDialog isActivator open={successDialogOpen} onClose={this.closeSuccessDialog} />
        <ConfirmationDialog
          label="Delete Shortcut"
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>
                Aborting the process will delete this shortcut and cannot be undone!
              </S.ConfirmationText>
              <S.ConfirmationWarning>Are you sure?</S.ConfirmationWarning>
            </S.ConfirmationContainer>
          }
          open={isConfirmationDialogOpened}
          onClick={this.handleDeleteShortcutApproval}
          handleClose={this.handleDeleteShortcutReject}
        />
        <ConfirmationDialog
          label="Yes"
          labelCancel="Return"
          isNext
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Are you sure you want to stop and cancel all other actions?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={isConfirmationSitesDialogOpened}
          onClick={this.confirmNewSitesExit}
          handleClose={this.closeConfirmationSitesDialog}
          onCancelClick={this.cancelConfirmationSitesDialog}
        />
        <ConfirmationDialog
          label="Yes"
          labelCancel="Return"
          isNext
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Are you sure you want to stop and cancel all other actions?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={isConfirmationTemplatesDialogOpened}
          onClick={() => {
            this.confirmTemplatesExit();
            this.setState({ listTemplateNames: { ...{ templateNames: [] }, ...{ checkDuplicate: true } } });
            this.setState({ anotherUnitTemplate: false });
          }}
          handleClose={this.closeConfirmationTemplatesDialog}
          onCancelClick={this.cancelConfirmationTemplatesDialog}
        />
        <ConfirmationDialog
          label="NO/NEXT"
          labelCancel="Request Upgrade"
          isNext
          isColumn
          withBorder
          width={'40%'}
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Sorry, you have reached your Site limit.</S.ConfirmationText>
              <S.ConfirmationText>Please contact us to upgrade.</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={isSiteLimitDialogOpened}
          onClick={this.closeSitesLimitDialog}
          handleClose={this.closeSitesLimitDialog}
          onCancelClick={() => {
            this.closeSitesLimitDialog();
            this.openUpgradeInfoDialog();
          }}
        />
        <ConfirmationDialog
          labelCancel="Request Upgrade"
          isNext
          isColumn
          withBorder
          width={'40%'}
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>
                Sorry, you have reached your <b>Item limit.</b>
              </S.ConfirmationText>
              <S.ConfirmationText>Please contact us to upgrade.</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={isItemLimitDialogOpened}
          handleClose={this.closeItemLimitDialog}
          onCancelClick={() => {
            this.closeItemLimitDialog();
            this.openUpgradeInfoDialog();
          }}
        />
        <ConfirmationDialog
          title="Confirmation"
          label="NO/NEXT"
          labelCancel="YES"
          titleColor={theme.mainGreen}
          isNext
          width={'40%'}
          isColumn
          withBorder
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Would you like to add another Site?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={addAnotherSiteOpened}
          onClick={() => {
            this.closeAddAnotherSiteDialog();
            this.openAddTemplateAfterSiteDialog();
          }}
          handleClose={() => {
            this.closeAddAnotherSiteDialog();
            this.setState({ isConfirmationSitesDialogOpened: true });
          }}
          onCancelClick={() => {
            if (!this.props.isNewSiteAvailable) {
              this.setState({ firstTimeNewSite: true });
              this.handleNewSiteExit();
              this.closeAddAnotherSiteDialog();
              this.openSitesLimitDialog();
            } else {
              this.setState({ firstTimeNewSite: false });
              this.closeAddAnotherSiteDialog();
              this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ newSite: true } } });
            }
          }}
        />
        <ConfirmationDialog
          title="Confirmation"
          label="NO/NEXT"
          labelCancel="YES"
          titleColor={theme.mainGreen}
          isNext
          width={'40%'}
          isColumn
          withBorder
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Would you like to add another Item?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={addAnotherItemOpened}
          onClick={() => {
            this.setState({ firstTimeTemplate: true });
            this.closeAddAnotherItemDialog();
            this.setState({ addAnotherTemplateOpened: true });
          }}
          handleClose={() => {
            this.setState({ firstTimeTemplate: true });
            this.closeAddAnotherItemDialog();
            this.setState({ addAnotherTemplateOpened: true });
          }}
          onCancelClick={() => {
            this.closeAddAnotherItemDialog();
            if (this.props.limit && this.props.currentItemsNumber === this.props.limit) {
              this.openItemLimitDialog();
            } else {
              this.setState(
                { firstTimeTemplate: false, anotherUnitTemplate: false },
                this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ addListTemplate: true } } }),
              );
            }
          }}
        />
        <ConfirmationDialog
          title="Confirmation"
          label="NO/NEXT"
          labelCancel="YES"
          titleColor={theme.mainGreen}
          isNext
          width={'40%'}
          isColumn
          withBorder
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Would you like to add another Template?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={addAnotherTemplateOpened}
          onClick={() => {
            this.setState({ anotherUnitTemplate: false });
            this.closeAddAnotherTemplateDialog();
            this.setState({ infoListTemplateOpened: true });
          }}
          handleClose={() => {
            this.setState({ anotherUnitTemplate: false });
            this.closeAddAnotherTemplateDialog();
            this.setState({ infoListTemplateOpened: true });
          }}
          onCancelClick={() => {
            this.closeAddAnotherTemplateDialog();
            this.setState({ listTemplateNames: { ...this.state.listTemplateNames, ...{ checkDuplicate: true } } });
            if (this.props.limit && this.props.currentItemsNumber === this.props.limit) {
              this.openItemLimitDialog();
            } else {
              this.setState(
                { anotherUnitTemplate: true },
                this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ addListTemplate: true } } }),
              );
            }
          }}
        />
        <ConfirmationDialog
          title="Confirmation"
          label="NO/NEXT"
          labelCancel="YES"
          titleColor={theme.mainGreen}
          isNext
          width={'40%'}
          isColumn
          withBorder
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Would you like to schedule Tasks for someone?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={scheduleTaskOfferOpened}
          onClick={() => {
            this.closeScheduleTaskOfferDialog();
            this.setState({ anotherUnitTemplate: false });
          }}
          handleClose={() => {
            this.closeScheduleTaskOfferDialog();
          }}
          onCancelClick={() => {
            this.setState({ anotherUnitTemplate: false });
            this.closeScheduleTaskOfferDialog();
            this.setState(
              { isShortcutFromListTemplate: true },
              this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ addShortcut: true } } }),
            );
          }}
        />
        <ConfirmationDialog
          title="Confirmation"
          label="NO/NEXT"
          labelCancel="YES"
          titleColor={theme.mainGreen}
          isNext
          width={'40%'}
          isColumn
          withBorder
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Would you like to import another List Template for this site?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={importListTemplateOpened}
          onClick={() => {
            this.closeImportListTemplateDialog();
            this.openInfoListTemplateDialog();
          }}
          handleClose={() => {
            this.closeImportListTemplateDialog();
            this.openInfoListTemplateDialog();
          }}
          onCancelClick={() => {
            this.closeImportListTemplateDialog();
            this.setState({ listTemplateNames: { ...this.state.listTemplateNames, ...{ checkDuplicate: true } } });
            this.setState(
              { anotherUnitTemplate: true },
              this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ addListTemplate: true } } }),
            );
          }}
        />
        <ConfirmationDialog
          title="Confirmation"
          label="NEXT"
          titleColor={theme.mainGreen}
          isNext
          width={'70%'}
          maxWidth={'550px'}
          isColumn
          withBorder
          text={
            <S.InfoConfirmationContainer>
              <S.ConfirmationTitle>List Template(s) have been added to</S.ConfirmationTitle>
              <S.ConfirmationTextContainer>
                <S.InfoPreviewText>
                  {listTemplateCurrentSite !== null
                    ? `${listTemplateCurrentSite?.additionalId.substring(0, 5)} 
                  ${listTemplateCurrentSite?.name}`
                    : null}
                </S.InfoPreviewText>
                {listTemplateNames.templateNames.length > 0
                  ? listTemplateNames.templateNames.map(item => <S.InfoConfirmationText>{item}</S.InfoConfirmationText>)
                  : null}
              </S.ConfirmationTextContainer>
            </S.InfoConfirmationContainer>
          }
          open={infoListTemplateOpened}
          onClick={() => {
            this.setState({ listTemplateNames: { ...{ templateNames: [] }, ...{ checkDuplicate: true } } });
            this.closeInfoListTemplateDialog();
            this.openScheduleTaskOfferDialog();
          }}
          handleClose={() => {
            this.closeInfoListTemplateDialog();
          }}
        />
        <ConfirmationDialog
          title="Confirmation"
          label="NO/NEXT"
          labelCancel="YES"
          titleColor={theme.mainGreen}
          isNext
          width={'40%'}
          isColumn
          withBorder
          text={
            <S.ConfirmationContainer>
              <S.ConfirmationText>Would you like to add List Template(s) to this or other Sites?</S.ConfirmationText>
            </S.ConfirmationContainer>
          }
          open={addTemplateAfterSiteOpened}
          onClick={() => {
            this.closeAddTemplateAfterSiteDialog();
          }}
          handleClose={() => {
            this.closeAddTemplateAfterSiteDialog();
          }}
          onCancelClick={() => {
            this.closeAddTemplateAfterSiteDialog();
            this.setState({ dialogsOpened: { ...this.state.dialogsOpened, ...{ addListTemplate: true } } });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user }, shortcuts, newSites, tenants, listTemplates, people, sites }) => ({
  userType: user.userType,
  shortcut: shortcuts.shortcut,
  isNewSiteAvailable: newSites.isNewSiteAvailable,
  industriesList: tenants.industryList,
  limit: listTemplates.limits.MaxItemsNumber,
  currentItemsNumber: listTemplates.limits.CurrentItemsNumber,
  user,
  peopleList: people.list,
  sitesList: sites.list.length === 0,
});

const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(
    {
      updateCurrentModule: appActions.updateCurrentModule,
      requestUpgradeInfo: settingsActions.upgradeInfoRequest,
      deleteShortcutRequest: shortcutActions.deleteShortcutRequest,
      checkSiteRequest: newSitesActions.checkSiteRequest,
      getLimitsRequest: listTemplatesActions.getLimitsRequest,
      requestGetSettings: settingsActions.getSettingsRequest,
      fetchSitesList: sitesActions.sitesListRequest,
      fetchPeople: peopleActions.peopleListRequest,
      fetchTeams: teamsActions.getTeamsRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralActivator);
