import http from '../index';

export const getShortcut = dto => http.post('Shortcut/Get', dto);

export const getShortcutList = filter => http.post('Shortcut/List', filter);

export const getShortcutPreview = dto => http.post('Shortcut/ShortcutPreview', { dto });

export const editSwitchAutorun = dto => http.post('Shortcut/SwitchAutorun', dto);

export const editRunNextPeriod = dto => http.post('Shortcut/RunNextPeriod', dto);

export const saveShortcutSchedule = dto => http.post('Shortcut/SaveSchedule', { dto });

export const getShortcutSchedule = dto => http.post('Shortcut/GetSchedule', dto);

export const editShortcutSchedule = dto => http.post('Shortcut/EditSchedule', { dto });

export const createShortcutChecklist = dto => http.post('Shortcut/CreateChecklist', dto);

export const editShortcutChecklist = dto => http.post('Shortcut/EditChecklist', dto);

export const createShortcut = dto => http.post('Shortcut/Post', { dto });

export const getScheduleList = dto => http.post(`Shortcut/ListSchedule?shortcutId=${dto}`);

export const putShortcutData = dto => http.post('Shortcut/Put', { dto });

export const getManagerPreview = dto => http.post('Shortcut/ManagerPreview', { dto });

export const getPersonPreview = dto => http.post('Shortcut/PersonPreview', { dto });

export const getTeamPreview = dto => http.post('Shortcut/TeamPreview', { dto });

export const getSitePreview = dto => http.post('Shortcut/SitePreview', { dto });

export const getUnitPreview = dto => http.post('Shortcut/UnitPreview', { dto });

export const getItemPreview = dto => http.post('Shortcut/ItemPreview', { dto });

export const getTeamListStatuses = dto => http.post('Team/ListStatuses', dto);

export const removeShortcut = dto => http.post('Shortcut/Delete', dto);
