export const HideOnTablets = () => `
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
`;

export const HideOnPhones = () => `
  @media only screen and (max-width: 480px) {
    display: none !important;
  }
`;

export const ShowOnTablets = () => `
  @media only screen and (min-width: 769px) {
    display: none !important;
  }
`;

export const ShowOnPhones = () => `
  @media only screen and (min-width: 481px) {
    display: none !important;
  }
`;
