import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Subheader from 'components/Subheader';
import TableList from 'components/TableList';
import { AddIcon, RunningWithErrors } from 'components/Layout/Icons';
import { actions as statusIndexActions } from 'redux/statusIndex/index';

import { sortTableColumns, tableColumns } from './configs';
import { searchItemByKeyword } from 'helpers';

import { options } from './ShortcutTriggerSetup/SelectionRules/IndexTypeSelection/constants';
import ShortcutTriggerSetup from './ShortcutTriggerSetup';
import * as S from './styled';

class IndexTriggers extends PureComponent {
  static propTypes = {
    fetchListIndexRuleRequest: PropTypes.func.isRequired,
    listIndexRule: PropTypes.array.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    searchData: '',
    isShortcutTriggerSetup: false,
    itemRuleToEdit: {},
  };

  componentDidMount() {
    const { fetchListIndexRuleRequest } = this.props;
    fetchListIndexRuleRequest();
  }
  goBack = () => this.props.history.push('/settings');

  searchInList = e => this.setState({ searchData: e.target.value });

  openShortcutTriggerSetup = () => {
    this.setState({ isShortcutTriggerSetup: true });
  };

  editReport = item => {
    this.setState({
      itemRuleToEdit: item,
      isShortcutTriggerSetup: true,
    });
  };

  exitShortcutTriggerSetup = () => {
    this.setState({ isShortcutTriggerSetup: false, itemRuleToEdit: {} });
  };

  getTimeLastRun = value => moment(value).format('DD-MMMM-YYYY');

  mapList = item => ({
    ...item,
    RuleName: (
      <>
        <S.BoldText>Trigger Name: </S.BoldText>
        {item.Name}
      </>
    ),
    RuleType: (
      <>
        <S.BoldText>Index Type: </S.BoldText>
        {options.find(option => option.value === item.Type).name}
      </>
    ),
    Created: (
      <>
        <S.BoldText>Created: </S.BoldText>
        {this.getTimeLastRun(item.CreatedOn)}
      </>
    ),
    Shortcut: (
      <>
        <S.BoldText>Shortcut: </S.BoldText>
        {item.ShortcutName}
      </>
    ),
    LastTime: (
      <>
        <S.BoldText>Last time triggered: </S.BoldText>Not ready yet
      </>
    ),
  });

  leftButtons = [
    {
      icon: <S.Back />,
      handler: this.goBack,
      hint: 'Back',
    },
  ];

  rightButtons = [
    {
      icon: <AddIcon />,
      handler: this.openShortcutTriggerSetup,
    },
  ];

  renderTitle = () => (
    <>
      <S.Title>Status Index Rules</S.Title>
      <S.RedText>Shortcut Task Triggers</S.RedText>
    </>
  );

  renderRightControllCell = item => <td>{item.IsSpoiled && <RunningWithErrors />}</td>;

  render() {
    const { searchData, isShortcutTriggerSetup, itemRuleToEdit } = this.state;
    const { listIndexRule } = this.props;

    return [
      <Subheader
        leftButtons={this.leftButtons}
        rightButtons={this.rightButtons}
        title={this.renderTitle()}
        isSearch
        searchData={searchData}
        searchInList={this.searchInList}
      />,
      <>
        <S.TableContainer>
          <TableList
            list={listIndexRule.filter(searchItemByKeyword(sortTableColumns, searchData)).map(this.mapList)}
            tableColumns={tableColumns}
            onRowClick={this.editReport}
            isPointer
            renderRightControllCell={this.renderRightControllCell}
          />
        </S.TableContainer>
      </>,
      <ShortcutTriggerSetup
        open={isShortcutTriggerSetup}
        listIndexRule={listIndexRule}
        itemRuleToEdit={itemRuleToEdit}
        handleExit={this.exitShortcutTriggerSetup}
      />,
    ];
  }
}

const mapStateToProps = ({ statusIndex }) => ({
  listIndexRule: statusIndex.listIndexRule,
});

export default connect(mapStateToProps, {
  fetchListIndexRuleRequest: statusIndexActions.fetchListIndexRuleRequest,
})(IndexTriggers);
