import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_PARTS_REQUEST: undefined,
  GET_PARTS_SUCCESS: undefined,
  GET_PARTS_FAILURE: undefined,

  GET_PART_BY_ID_REQUEST: undefined,
  GET_PART_BY_ID_SUCCESS: undefined,
  GET_PART_BY_ID_FAILURE: undefined,

  GET_PARTS_BY_GROUP_ID_REQUEST: undefined,
  GET_PARTS_BY_GROUP_ID_SUCCESS: undefined,
  GET_PARTS_BY_GROUP_ID_FAILURE: undefined,

  ADD_PART_REQUEST: undefined,
  ADD_PART_SUCCESS: undefined,
  ADD_PART_FAILURE: undefined,

  EDIT_PART_REQUEST: undefined,
  EDIT_PART_SUCCESS: undefined,
  EDIT_PART_FAILURE: undefined,

  GET_OEM_LIST_REQUEST: undefined,
  GET_OEM_LIST_SUCCESS: undefined,
  GET_OEM_LIST_FAILURE: undefined,

  ADD_OEM_REQUEST: undefined,
  ADD_OEM_SUCCESS: undefined,
  ADD_OEM_FAILURE: undefined,

  EDIT_OEM_REQUEST: undefined,
  EDIT_OEM_SUCCESS: undefined,
  EDIT_OEM_FAILURE: undefined,

  DELETE_OEM_REQUEST: undefined,
  DELETE_OEM_SUCCESS: undefined,
  DELETE_OEM_FAILURE: undefined,
  GET_SUPPLIERS_LIST_REQUEST: undefined,
  GET_SUPPLIERS_LIST_SUCCESS: undefined,
  GET_SUPPLIERS_LIST_FAILURE: undefined,
  ADD_SUPPLIER_REQUEST: undefined,
  ADD_SUPPLIER_SUCCESS: undefined,
  ADD_SUPPLIER_FAILURE: undefined,
  EDIT_SUPPLIER_REQUEST: undefined,
  EDIT_SUPPLIER_SUCCESS: undefined,
  EDIT_SUPPLIER_FAILURE: undefined,
  DELETE_SUPPLIER_REQUEST: undefined,
  DELETE_SUPPLIER_SUCCESS: undefined,
  DELETE_SUPPLIER_FAILURE: undefined,
  GET_PART_GROUPS_REQUEST: undefined,
  GET_PART_GROUPS_SUCCESS: undefined,
  GET_PART_GROUPS_FAILURE: undefined,
  ADD_PART_GROUP_REQUEST: undefined,
  ADD_PART_GROUP_SUCCESS: undefined,
  ADD_PART_GROUP_FAILURE: undefined,
  ADD_PART_GROUP_LINK_REQUEST: undefined,
  ADD_PART_GROUP_LINK_SUCCESS: undefined,
  ADD_PART_GROUP_LINK_FAILURE: undefined,
  DELETE_PARTS_GROUP_LINK_REQUEST: undefined,
  DELETE_PARTS_GROUP_LINK_SUCCESS: undefined,
  DELETE_PARTS_GROUP_LINK_FAILURE: undefined,
  GET_LIST_PARTS_BY_ITEM_REQUEST: undefined,
  GET_LIST_PARTS_BY_ITEM_SUCCESS: undefined,
  GET_LIST_PARTS_BY_ITEM_FAILURE: undefined,
  CREATE_PART_ITEM_LINK_REQUEST: undefined,
  CREATE_PART_ITEM_LINK_SUCCESS: undefined,
  CREATE_PART_ITEM_LINK_FAILURE: undefined,
  REMOVE_PART_FROM_ITEM_REQUEST: undefined,
  REMOVE_PART_FROM_ITEM_SUCCESS: undefined,
  REMOVE_PART_FROM_ITEM_FAILURE: undefined,
  GET_LIST_PART_GROUPS_BY_ITEM_REQUEST: undefined,
  GET_LIST_PART_GROUPS_BY_ITEM_SUCCESS: undefined,
  GET_LIST_PART_GROUPS_BY_ITEM_FAILURE: undefined,
  CREATE_PART_GROUP_ITEM_LINK_REQUEST: undefined,
  CREATE_PART_GROUP_ITEM_LINK_SUCCESS: undefined,
  CREATE_PART_GROUP_ITEM_LINK_FAILURE: undefined,
  REMOVE_PART_GROUP_FROM_ITEM_REQUEST: undefined,
  REMOVE_PART_GROUP_FROM_ITEM_SUCCESS: undefined,
  REMOVE_PART_GROUP_FROM_ITEM_FAILURE: undefined,

  DELETE_PART_REQUEST: undefined,
  DELETE_PART_SUCCESS: undefined,
  DELETE_PART_FAILURE: undefined,

  DELETE_PARTS_REQUEST: undefined,
  DELETE_PARTS_SUCCESS: undefined,
  DELETE_PARTS_FAILURE: undefined,

  DELETE_PART_GROUP_REQUEST: undefined,
  DELETE_PART_GROUP_SUCCESS: undefined,
  DELETE_PART_GROUP_FAILURE: undefined,

  DELETE_PART_GROUPS_REQUEST: undefined,
  DELETE_PART_GROUPS_SUCCESS: undefined,
  DELETE_PART_GROUPS_FAILURE: undefined,

  DELETE_PART_FROM_GROUP_REQUEST: undefined,
  DELETE_PART_FROM_GROUP_SUCCESS: undefined,
  DELETE_PART_FROM_GROUP_FAILURE: undefined,

  CREATE_PART_LINKS_REQUEST: undefined,
  CREATE_PART_LINKS_SUCCESS: undefined,
  CREATE_PART_LINKS_FAILURE: undefined,

  GET_LAST_EXECUTION_DATE_REQUEST: undefined,
  GET_LAST_EXECUTION_DATE_SUCCESS: undefined,
  GET_LAST_EXECUTION_DATE_FAILURE: undefined,

  CREATE_GROUP_LINKS_REQUEST: undefined,
  CREATE_GROUP_LINKS_SUCCESS: undefined,
  CREATE_GROUP_LINKS_FAILURE: undefined,

  GET_LAST_EXECUTION_GROUP_DATE_REQUEST: undefined,
  GET_LAST_EXECUTION_GROUP_DATE_SUCCESS: undefined,
  GET_LAST_EXECUTION_GROUP_DATE_FAILURE: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getPartsRequest, handlers.getPartsRequest],
    [actions.getPartsSuccess, handlers.getPartsSuccess],
    [actions.getPartsFailure, handlers.getPartsFailure],

    [actions.getPartByIdRequest, handlers.getPartByIdRequest],
    [actions.getPartByIdSuccess, handlers.getPartByIdSuccess],
    [actions.getPartByIdFailure, handlers.getPartByIdFailure],

    [actions.getPartsByGroupIdRequest, handlers.getPartsByGroupIdRequest],
    [actions.getPartsByGroupIdSuccess, handlers.getPartsByGroupIdSuccess],
    [actions.getPartsByGroupIdFailure, handlers.getPartsByGroupIdFailure],

    [actions.getPartGroupsRequest, handlers.getPartGroupsRequest],
    [actions.getPartGroupsSuccess, handlers.getPartGroupsSuccess],
    [actions.getPartGroupsFailure, handlers.getPartGroupsFailure],

    [actions.addPartRequest, handlers.addPartRequest],
    [actions.addPartSuccess, handlers.addPartSuccess],
    [actions.addPartFailure, handlers.addPartFailure],

    [actions.editPartRequest, handlers.editPartRequest],
    [actions.editPartSuccess, handlers.editPartSuccess],
    [actions.editPartFailure, handlers.editPartFailure],

    [actions.getOemListRequest, handlers.getOemListRequest],
    [actions.getOemListSuccess, handlers.getOemListSuccess],
    [actions.getOemListFailure, handlers.getOemListFailure],

    [actions.addOemRequest, handlers.addOemRequest],
    [actions.addOemSuccess, handlers.addOemSuccess],
    [actions.addOemFailure, handlers.addOemFailure],

    [actions.editOemRequest, handlers.editOemRequest],
    [actions.editOemSuccess, handlers.editOemSuccess],
    [actions.editOemFailure, handlers.editOemFailure],

    [actions.deleteOemRequest, handlers.deleteOemRequest],
    [actions.deleteOemSuccess, handlers.deleteOemSuccess],
    [actions.deleteOemFailure, handlers.deleteOemFailure],

    [actions.getSuppliersListRequest, handlers.getSuppliersListRequest],
    [actions.getSuppliersListSuccess, handlers.getSuppliersListSuccess],
    [actions.getSuppliersListFailure, handlers.getSuppliersListFailure],

    [actions.addSupplierRequest, handlers.addSupplierRequest],
    [actions.addSupplierSuccess, handlers.addSupplierSuccess],
    [actions.addSupplierFailure, handlers.addSupplierFailure],

    [actions.editSupplierRequest, handlers.editSupplierRequest],
    [actions.editSupplierSuccess, handlers.editSupplierSuccess],
    [actions.editSupplierFailure, handlers.editSupplierFailure],

    [actions.deleteSupplierRequest, handlers.deleteSupplierRequest],
    [actions.deleteSupplierSuccess, handlers.deleteSupplierSuccess],
    [actions.deleteSupplierFailure, handlers.deleteSupplierFailure],

    [actions.getPartGroupsRequest, handlers.getPartGroupsRequest],
    [actions.getPartGroupsSuccess, handlers.getPartGroupsSuccess],
    [actions.getPartGroupsFailure, handlers.getPartGroupsFailure],

    [actions.addPartGroupRequest, handlers.addPartGroupRequest],
    [actions.addPartGroupSuccess, handlers.addPartGroupSuccess],
    [actions.addPartGroupFailure, handlers.addPartGroupFailure],

    [actions.addPartGroupLinkRequest, handlers.addPartGroupLinkRequest],
    [actions.addPartGroupLinkSuccess, handlers.addPartGroupLinkSuccess],
    [actions.addPartGroupLinkFailure, handlers.addPartGroupLinkFailure],

    [actions.deletePartsGroupLinkRequest, handlers.deletePartsGroupLinkRequest],
    [actions.deletePartsGroupLinkSuccess, handlers.deletePartsGroupLinkSuccess],
    [actions.deletePartsGroupLinkFailure, handlers.deletePartsGroupLinkFailure],

    [actions.getListPartsByItemRequest, handlers.getListPartsByItemRequest],
    [actions.getListPartsByItemSuccess, handlers.getListPartsByItemSuccess],
    [actions.getListPartsByItemFailure, handlers.getListPartsByItemFailure],

    [actions.createPartItemLinkRequest, handlers.createPartItemLinkRequest],
    [actions.createPartItemLinkSuccess, handlers.createPartItemLinkSuccess],
    [actions.createPartItemLinkFailure, handlers.createPartItemLinkFailure],

    [actions.removePartFromItemRequest, handlers.removePartFromItemRequest],
    [actions.removePartFromItemSuccess, handlers.removePartFromItemSuccess],
    [actions.removePartFromItemFailure, handlers.removePartFromItemFailure],

    [actions.getListPartGroupsByItemRequest, handlers.getListPartGroupsByItemRequest],
    [actions.getListPartGroupsByItemSuccess, handlers.getListPartGroupsByItemSuccess],
    [actions.getListPartGroupsByItemFailure, handlers.getListPartGroupsByItemFailure],

    [actions.createPartGroupItemLinkRequest, handlers.createPartGroupItemLinkRequest],
    [actions.createPartGroupItemLinkSuccess, handlers.createPartGroupItemLinkSuccess],
    [actions.createPartGroupItemLinkFailure, handlers.createPartGroupItemLinkFailure],

    [actions.removePartGroupFromItemRequest, handlers.removePartGroupFromItemRequest],
    [actions.removePartGroupFromItemSuccess, handlers.removePartGroupFromItemSuccess],
    [actions.removePartGroupFromItemFailure, handlers.removePartGroupFromItemFailure],

    [actions.deletePartRequest, handlers.deletePartRequest],
    [actions.deletePartSuccess, handlers.deletePartSuccess],
    [actions.deletePartFailure, handlers.deletePartFailure],

    [actions.deletePartsRequest, handlers.deletePartsRequest],
    [actions.deletePartsSuccess, handlers.deletePartsSuccess],
    [actions.deletePartsFailure, handlers.deletePartsFailure],

    [actions.deletePartGroupRequest, handlers.deletePartGroupRequest],
    [actions.deletePartGroupSuccess, handlers.deletePartGroupSuccess],
    [actions.deletePartGroupFailure, handlers.deletePartGroupFailure],

    [actions.deletePartGroupsRequest, handlers.deletePartGroupsRequest],
    [actions.deletePartGroupsSuccess, handlers.deletePartGroupsSuccess],
    [actions.deletePartGroupsFailure, handlers.deletePartGroupsFailure],

    [actions.deletePartFromGroupRequest, handlers.deletePartFromGroupRequest],
    [actions.deletePartFromGroupSuccess, handlers.deletePartFromGroupSuccess],
    [actions.deletePartFromGroupFailure, handlers.deletePartFromGroupFailure],

    [actions.createPartLinksRequest, handlers.createPartLinksRequest],
    [actions.createPartLinksSuccess, handlers.createPartLinksSuccess],
    [actions.createPartLinksFailure, handlers.createPartLinksFailure],

    [actions.getLastExecutionDateRequest, handlers.getLastExecutionDateRequest],
    [actions.getLastExecutionDateSuccess, handlers.getLastExecutionDateSuccess],
    [actions.getLastExecutionDateFailure, handlers.getLastExecutionDateFailure],

    [actions.createGroupLinksRequest, handlers.createGroupLinksRequest],
    [actions.createGroupLinksSuccess, handlers.createGroupLinksSuccess],
    [actions.createGroupLinksFailure, handlers.createGroupLinksFailure],

    [actions.getLastExecutionGroupDateRequest, handlers.getLastExecutionGroupDateRequest],
    [actions.getLastExecutionGroupDateSuccess, handlers.getLastExecutionGroupDateSuccess],
    [actions.getLastExecutionGroupDateFailure, handlers.getLastExecutionGroupDateFailure],
  ]),
  initialState,
);

export default reducer;
