const initialState = {
  list: [],
  teamMembers: [],
  membersForAssigment: [],
  siteManagers: [],
  error: '',
  isLoading: false,
  teamList: [],
  filteredTeamMembers: [],
  team: {},
};

export const addTeamMembersRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const addTeamMembersSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  teamMembers: payload,
});

export const addTeamMembersFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getMembersForAssigmentRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getMembersForAssigmentSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  membersForAssigment: payload,
});

export const getMembersForAssigmentFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getTeamMembersRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getTeamMembersSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  teamMembers: payload,
});

export const getTeamMembersFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getTeamsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getTeamsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  list: payload,
});

export const getTeamsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getSiteManagersRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getSiteManagersSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  siteManagers: payload,
});

export const getSiteManagersFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createTeamRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createTeamSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  team: payload,
});

export const createTeamFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const updateTeamRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const updateTeamSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  team: payload,
});

export const updateTeamFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteTeamRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteTeamSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteTeamFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const deleteTeamMemberRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const deleteTeamMemberSuccess = state => ({
  ...state,
  isLoading: false,
});

export const deleteTeamMemberFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getTeamListBySiteRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getTeamListBySiteSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  teamList: payload,
});

export const getTeamListBySiteFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getFilteredTeamMembersRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getFilteredTeamMembersSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  filteredTeamMembers: payload,
});

export const getFilteredTeamMembersFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export default initialState;
