import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleList from 'components/SimpleList';
import Subheader from 'components/Subheader';
import { BackIcon, LinkIcon } from 'components/Layout/Icons';
import LinkItemsDialog from 'components/Dialogs/LinkItemsDialog';
import ItemPartsViewDialog from 'components/Dialogs/ItemPartsViewDialog';
import SelectDialog from './SelectDialog';
import ConfirmDialog from 'components/Dialogs/Confirm';
import { ButtonIcon } from 'components/Layout/Buttons';

import { actions as authActions } from 'redux/auth';
import { actions as unitsActions } from 'redux/units';
import { actions as partActions } from 'redux/parts';

const renderItemContent = item => `${item.Name} - (#${item.ModelNumber})`;

class NewParts extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    unitsList: PropTypes.array.isRequired,
    listUnitsWithItems: PropTypes.array.isRequired,
    isListUnitsWithItemsLoading: PropTypes.bool.isRequired,
    userType: PropTypes.number.isRequired,
    tenantIsOEM: PropTypes.bool.isRequired,
    selectedSite: PropTypes.string.isRequired,
    fetchUnits: PropTypes.func.isRequired,
    getUserType: PropTypes.func.isRequired,
    requestListUnitsWithItems: PropTypes.func.isRequired,
    createPartItemLinkRequest: PropTypes.func.isRequired,
    createPartGroupItemLinkRequest: PropTypes.func.isRequired,
  };

  state = {
    searchData: '',
    linkItemsDialogOpened: false,
    itemPartsViewDialogOpened: false,
    selectDialogOpened: false,
    confirmDialogOpened: false,
    confirmHandleApprove: () => {},
    confirmationMessage: '',
    isSelectGroup: false,
    unitToLink: null,
    itemToLink: null,
    currentUnitId: '',
  };

  componentDidMount() {
    const { fetchUnits, getUserType, selectedSite } = this.props;

    fetchUnits({ filter: { SiteID: selectedSite } });
    getUserType();
  }

  searchInList = e => this.setState({ searchData: e.target.value });

  filterList = item => item.Name.toLowerCase().includes(this.state.searchData.toLowerCase());

  openLinkItemsDialog = (siteId, unitId, isMulti) => {
    this.props.requestListUnitsWithItems(siteId);

    if (isMulti) {
      this.setState({ linkItemsDialogOpened: true, currentUnitId: '' });
      return;
    }

    if (unitId) {
      this.setState({ linkItemsDialogOpened: true, currentUnitId: unitId });
      return;
    }

    this.setState({ linkItemsDialogOpened: true });
  };

  closeLinkItemsDialog = () => this.setState({ linkItemsDialogOpened: false });

  openItemPartsViewDialog = (unit, item) => {
    this.setState({
      itemPartsViewDialogOpened: true,
      unitToLink: unit,
      itemToLink: item,
    });
  };

  closeItemPartsViewDialog = () => this.setState({ itemPartsViewDialogOpened: false });

  openSelectPartsDialog = () => this.setState({ selectDialogOpened: true, isSelectGroup: false });

  closeSelectDialog = () => this.setState({ selectDialogOpened: false });

  openSelectGroupsDialog = () => this.setState({ selectDialogOpened: true, isSelectGroup: true });

  handleLinkItemWithParts = parts => {
    const payload = {
      itemId: this.state.itemToLink.Id,
      siteId: this.state.unitToLink.SiteId,
      partIDs: parts,
    };
    this.props.createPartItemLinkRequest(payload);
    this.openLinkItemsDialog(this.props.selectedSite);
    this.setState({ selectDialogOpened: false });
  };

  backHome = () => {
    this.props.history.push('/home');
  };

  handleLinkItemWithGroups = groups => {
    const payload = {
      itemId: this.state.itemToLink.Id,
      siteId: this.state.unitToLink.SiteId,
      groupIDs: groups,
    };

    this.props.createPartGroupItemLinkRequest(payload);
    this.openLinkItemsDialog(this.props.selectedSite);
    this.setState({ selectDialogOpened: false });
  };

  openConfirmationDialog = (onApprove, message = '') => {
    const confirmHandleApprove = () => {
      onApprove();
      this.setState({ confirmDialogOpened: false });
    };

    this.setState({ confirmHandleApprove, confirmationMessage: message, confirmDialogOpened: true });
  };

  handleRemoveReject = () => {
    this.setState({ confirmHandleApprove: () => {}, confirmDialogOpened: false });
  };

  filterUnitList = list => {
    const { currentUnitId } = this.state;
    if (currentUnitId) {
      return list.filter(item => item.Id === this.state.currentUnitId);
    }

    return list;
  };

  renderRightControls = unit => (
    <ButtonIcon tooltip="Link items" style={{ marginRight: '20px' }}>
      <LinkIcon onClick={() => this.openLinkItemsDialog(this.props.selectedSite, unit.Id)} />
    </ButtonIcon>
  );

  render() {
    const { unitsList, userType, tenantIsOEM, selectedSite, listUnitsWithItems, isListUnitsWithItemsLoading } =
      this.props;
    const {
      searchData,
      linkItemsDialogOpened,
      unitToLink,
      itemToLink,
      itemPartsViewDialogOpened,
      selectDialogOpened,
      isSelectGroup,
    } = this.state;
    return (
      <>
        <Subheader
          title="List Template"
          isSiteOnLeftSide
          isSearch
          searchData={searchData}
          searchInList={this.searchInList}
          leftButtons={[
            {
              icon: <BackIcon />,
              handler: this.backHome,
              hint: 'Back',
            },
          ]}
          rightButtons={[
            {
              icon: <LinkIcon />,
              handler: () => this.openLinkItemsDialog(selectedSite, null, true),
              hint: 'Link items',
            },
          ]}
        />
        <SimpleList
          data={unitsList.filter(this.filterList)}
          emptyListMessage="There are no List Templates available"
          onItemClick={() => {}}
          renderItemContent={renderItemContent}
          userType={userType}
          tenantIsOEM={tenantIsOEM}
          renderRightControls={this.renderRightControls}
          withoutControls
        />
        <LinkItemsDialog
          handleClose={this.closeLinkItemsDialog}
          open={linkItemsDialogOpened}
          units={this.filterUnitList(listUnitsWithItems)}
          isLoading={isListUnitsWithItemsLoading}
          commonLockHide
          openItemPartsViewDialog={this.openItemPartsViewDialog}
        />
        <ItemPartsViewDialog
          open={itemPartsViewDialogOpened}
          onRequestClose={this.closeItemPartsViewDialog}
          title="Item Parts View"
          unitToLink={unitToLink}
          itemToLink={itemToLink}
          units={listUnitsWithItems}
          openSelectPartsDialog={this.openSelectPartsDialog}
          openSelectGroupsDialog={this.openSelectGroupsDialog}
          removeHandler={this.openConfirmationDialog}
        />
        <SelectDialog
          onRequestClose={this.closeSelectDialog}
          onSubmit={isSelectGroup ? this.handleLinkItemWithGroups : this.handleLinkItemWithParts}
          open={selectDialogOpened}
          title={`Select ${isSelectGroup ? 'Group' : 'Part'}`}
          isSelectGroup={isSelectGroup}
        />
        <ConfirmDialog
          open={this.state.confirmDialogOpened}
          title="Warning"
          text={this.state.confirmationMessage}
          confirmText="Delete"
          cancelText="Cancel"
          onApprove={this.state.confirmHandleApprove}
          onReject={this.handleRemoveReject}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  auth: {
    user: { userType, tenantIsOEM },
    selectedSite,
  },
  units: { unitsList, listUnitsWithItems, isListUnitsWithItemsLoading },
}) => ({
  unitsList,
  listUnitsWithItems,
  isListUnitsWithItemsLoading,
  userType,
  tenantIsOEM,
  selectedSite,
});

const mapDispatchToProps = {
  fetchUnits: unitsActions.unitsListRequest,
  getUserType: authActions.fetchUserSitesRequest,
  requestListUnitsWithItems: unitsActions.getListUnitsWithItemsRequest,
  createPartItemLinkRequest: partActions.createPartItemLinkRequest,
  createPartGroupItemLinkRequest: partActions.createPartGroupItemLinkRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewParts);
