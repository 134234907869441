import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const ConfirmGroupRemovalText = ({ linksCount }) => (
  <div>
    <div>Are you sure you want to delete this group?</div>
    {linksCount ? <S.AdditionalWarning>This group has parts!</S.AdditionalWarning> : null}
  </div>
);

ConfirmGroupRemovalText.propTypes = {
  linksCount: PropTypes.number.isRequired,
};

export default ConfirmGroupRemovalText;
