import theme from 'theme';

export const options = [
  {
    value: 'all',
    label: 'Show All',
    style: {
      maxWidth: '160px',
      marginLeft: '40px',
    },
    iconStyle: {
      fill: theme.primaryScheduler,
    },
  },
  {
    value: 'nonRestricted',
    label: 'Non restricted',
    style: {
      maxWidth: '200px',
    },
    iconStyle: {
      fill: theme.primaryScheduler,
    },
  },
  {
    value: 'restricted',
    label: 'Restricted',
    style: {
      maxWidth: '160px',
    },
    iconStyle: {
      fill: theme.primaryScheduler,
    },
  },
];
