import styled from 'styled-components';

export const HtmlForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
`;

export const Styled = styled.div``;
